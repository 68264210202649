import { Card, Image } from 'react-bootstrap'
import PropTypes from 'prop-types'
import { GetWordText } from '../project/WordCodings'
import { GetWordImageUrl } from '../project/ProjectResources'
import './WordCardComponent.css'

export function WordCardComponent({ wordKey, selectedWords }) {
    let wordCardImageClass = ' word-card-image'
    wordCardImageClass += selectedWords.includes(wordKey) ? ' word-card-image-active' : ' word-card-image-inactive'

    const wordCardComponent =
        <Card className='word-card bg-light' >
            <div className='word-card-image-div vstack mx-auto my-0'>
                <Image fluid className={wordCardImageClass} src={GetWordImageUrl(wordKey)} alt={GetWordText(wordKey)} />
            </div>
            <h5 className='word-card-text text-center my-0 p-0'>{GetWordText(wordKey)}</h5>
        </Card>

    return wordCardComponent
}

WordCardComponent.propTypes = {
    wordKey: PropTypes.string.isRequired,
    selectedWords: PropTypes.arrayOf(PropTypes.string).isRequired,
}
