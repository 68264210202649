import { useState, useEffect, useContext } from 'react'
import { Container, ButtonGroup, Dropdown } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import { LOGIN_SIGNUP_PATH, SUBSCRIPTION_PATH } from '../App'
import { SubscribeActionListener, GetStringifiedState, TIC_TAC_TOE_REDUCER, DEFAULT_STRINGIFIED_STATE } from '../state/ReduxStore'
import { TicTacToeBoardComponent } from '../components/TicTacToeComponents'
import { ExitControlIconComponent, RestartControlIconComponent, ActivityControlIconDropdownComponent } from '../components/ActivityControlComponents'
import { TicTacToeProvider, TicTacToeContext } from '../context/TicTacToeContext'
import {
    ResetTicTacToeGame,
    SetTicTacToeBoardSize,
    SetTicTacToeSourceWordList,
    SetTicTacToeFirstPlayer,
    GetTicTacToeGameWordList,
    AreAnyTicTacToeMovesUnplayed,
    TIC_TAC_TOE_PLAYER_X,
    TIC_TAC_TOE_PLAYER_O
} from '../state/TicTacToeState'
import { GetActivityWordList, GetActivityReturnPath } from '../state/ActivityState'
import { IsUserLoggedIn } from '../state/UserAuthState'
import { IsUserSubscribed } from '../state/SubscriptionState'
import { DisableScrollbar } from '../project/Display'
import '../components/TicTacToeComponents.css'

export function TicTacToePage() {
    return (
        <TicTacToeProvider>
            <TicTacToePageContent />
        </TicTacToeProvider>
    )
}

function TicTacToePageContent() {
    const [stringifiedState, SetStringifiedState] = useState(DEFAULT_STRINGIFIED_STATE)
    useEffect(() => { }, [stringifiedState])

    const { gameOver, SetGameOver, gameWon, ResetTicTacToeContext } = useContext(TicTacToeContext)

    const ResetTicTacToePage = () => {
        ResetTicTacToeGame()
        ResetTicTacToeContext()
    }

    const HandleActionNotification = () => {
        SetStringifiedState(GetStringifiedState(TIC_TAC_TOE_REDUCER))
    }

    const navigate = useNavigate()

    useEffect(() => {
        if (!IsUserLoggedIn()) {
            navigate(LOGIN_SIGNUP_PATH)
        }
        else if (!IsUserSubscribed()) {
            navigate(SUBSCRIPTION_PATH)
        }

        DisableScrollbar()
        SetStringifiedState(DEFAULT_STRINGIFIED_STATE)
        SubscribeActionListener(HandleActionNotification)
        SetTicTacToeSourceWordList(GetActivityWordList())
        ResetTicTacToePage()
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    if (!gameOver && (gameWon || !AreAnyTicTacToeMovesUnplayed())) {
        setTimeout(() => SetGameOver(true), 500)
    }

    return (
        <Container className='section-container bg-light'>
            <div className='activity-page-div'>
                <Container className='activity-control-container'></Container>
                <Container className='vstack w-75 mt-0 px-4 pt-3 pb-4'>
                    <div className='tic-tac-toe-title'>
                        Tic Tac Toe
                    </div >
                    <TicTacToeBoardComponent
                        words={GetTicTacToeGameWordList()}
                        RestartGame={ResetTicTacToePage}
                        ExitGame={() => {
                            ResetTicTacToePage()
                            navigate(GetActivityReturnPath())
                        }}
                    />
                </Container>
                <Container className='activity-control-container'>
                    <ButtonGroup className='vstack mt-0 mb-1'>
                        <ExitControlIconComponent CleanUpActivity={() => ResetTicTacToePage()} />
                        <RestartControlIconComponent
                            className='mt-1'
                            RestartActivity={() => ResetTicTacToePage()}
                        />
                        <ActivityControlIconDropdownComponent
                            imageUrl='grid-3x3-gap-fill.svg'
                            SelectAction={event => {
                                SetTicTacToeBoardSize(event)
                                ResetTicTacToePage()
                            }}
                        >
                            <Dropdown.ItemText style={{ color: 'var(--dark-orange)', fontWeight: 'bold' }}>Board Size</Dropdown.ItemText>
                            <Dropdown.Item eventKey={3}>3x3</Dropdown.Item>
                            <Dropdown.Item eventKey={4}>4x4</Dropdown.Item>
                        </ActivityControlIconDropdownComponent>
                        <ActivityControlIconDropdownComponent
                            imageUrl='first-square-fill-dark-blue.svg'
                            SelectAction={event => {
                                SetTicTacToeFirstPlayer(event)
                                ResetTicTacToePage()
                            }}
                        >
                            <Dropdown.ItemText style={{ color: 'var(--dark-orange)', fontWeight: 'bold' }}>First Move</Dropdown.ItemText>
                            <Dropdown.Item eventKey={TIC_TAC_TOE_PLAYER_X}>X goes first</Dropdown.Item>
                            <Dropdown.Item eventKey={TIC_TAC_TOE_PLAYER_O}>O goes first</Dropdown.Item>
                        </ActivityControlIconDropdownComponent>
                    </ButtonGroup>
                </Container>
            </div>
        </Container>
    )
}
