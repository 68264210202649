import { useState, useEffect } from 'react'
import { createReducer, createAction } from '@reduxjs/toolkit'
import PropTypes from 'prop-types'
import {
    GetReducerState,
    LISTS_REDUCER,
    DispatchAction,
    SubscribeActionListener,
    GetStringifiedState,
    DEFAULT_STRINGIFIED_STATE,
} from '../state/ReduxStore'
import { SortWords } from '../project/Utils'

// -------------------------------------------------------------------------------------------------
//  Internal state management
// -------------------------------------------------------------------------------------------------

const USER_LISTS = 'ListsState/UserLists'
const SELECTED_LIST_ID = 'ListsState/SelectedListId'

const defaultState = { [USER_LISTS]: [], [SELECTED_LIST_ID]: '' }

const STORED_STATE_KEY = 'LevelUpArtic/ListsState'

const StoreListsState = (state) => {
    sessionStorage.setItem(STORED_STATE_KEY, JSON.stringify(state))
}

const GetUserListsState = () => {
    return GetReducerState(LISTS_REDUCER)[USER_LISTS]
}

const GetSelectedListIdState = () => {
    return GetReducerState(LISTS_REDUCER)[SELECTED_LIST_ID]
}

// -------------------------------------------------------------------------------------------------
//  External state access
// -------------------------------------------------------------------------------------------------

export const GetInitialListsState = () => {
    const storedState = sessionStorage.getItem(STORED_STATE_KEY)
    return storedState ? JSON.parse(storedState) : defaultState
}

export function ListsStateChangeListener({ callback }) {
    const [stringifiedState, SetStringifiedState] = useState(DEFAULT_STRINGIFIED_STATE)

    useEffect(() => {
        callback(stringifiedState)
    }, [stringifiedState, callback])

    const HandleActionNotification = () => {
        SetStringifiedState(GetStringifiedState(LISTS_REDUCER))
    }

    useEffect(() => {
        SubscribeActionListener(HandleActionNotification)
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    return <></>
}

ListsStateChangeListener.propTypes = {
    callback: PropTypes.func.isRequired,
}

export const GetUserLists = () => {
    return GetUserListsState()
}

export const GetSelectedListId = () => {
    return GetSelectedListIdState()
}

export const GetSelectedList = () => {
    const selectedListId = GetSelectedListIdState()
    const userLists = GetUserListsState()

    if (selectedListId === '' || userLists.length === 0) {
        return {}
    }

    const list = userLists.find((userList) => userList['id'] === selectedListId)

    if (list) {
        return list
    } else {
        return {}
    }
}

export const GetSelectedListWords = () => {
    const selectedList = GetSelectedList()
    let words = []

    if (selectedList && Object.keys(selectedList).length > 0 && selectedList['words'] && Array.isArray(selectedList['words'])) {
        words = selectedList['words']
    }

    words = SortWords(words)
    return words
}

// -------------------------------------------------------------------------------------------------
//  Internal action management
// -------------------------------------------------------------------------------------------------

const SET_USER_LISTS = 'ListsAction/SetUserLists'
const SET_SELECTED_LIST_ID = 'ListsAction/SetSelectedListId'
const CLEAR_SELECTED_LIST_ID = 'ListsAction/ClearSelectedListId'

const setUserListsAction = createAction(SET_USER_LISTS)
const setSelectedListIdAction = createAction(SET_SELECTED_LIST_ID)
const clearSelectedListIdAction = createAction(CLEAR_SELECTED_LIST_ID)

// -------------------------------------------------------------------------------------------------
//  External action access
// -------------------------------------------------------------------------------------------------

export const SetUserLists = lists => {
    DispatchAction(setUserListsAction, lists)
}

export const SelectListId = listId => {
    DispatchAction(setSelectedListIdAction, listId)
}

export const ClearSelectedListId = () => {
    DispatchAction(clearSelectedListIdAction)
}

// -------------------------------------------------------------------------------------------------
//  Internal reducer management
// -------------------------------------------------------------------------------------------------

const HandleSetUserListsReq = (state, action) => {
    const userLists = action.payload
    state[USER_LISTS] = userLists
    StoreListsState(state)
}

const HandleSelectListIdReq = (state, action) => {
    state[SELECTED_LIST_ID] = action.payload
    StoreListsState(state)
}

const HandleClearSelectedListIdReq = (state) => {
    state[SELECTED_LIST_ID] = ''
    StoreListsState(state)
}

const listsReducer = createReducer(defaultState, (builder) => {
    builder.addCase(setUserListsAction, (state, action) => {
        HandleSetUserListsReq(state, action)
    })

    builder.addCase(setSelectedListIdAction, (state, action) => {
        HandleSelectListIdReq(state, action)
    })

    builder.addCase(clearSelectedListIdAction, (state, action) => {
        HandleClearSelectedListIdReq(state, action)
    })

    builder.addDefaultCase(() => { })
})

// -------------------------------------------------------------------------------------------------
//  External reducer access
// -------------------------------------------------------------------------------------------------

export const GetListsReducer = () => {
    return listsReducer
}
