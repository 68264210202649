import { useEffect } from 'react'
import { Container } from 'react-bootstrap'
import { Header } from '../components/Header'
import { Footer } from '../components/Footer'
import { EnableScrollbar } from '../project/Display'

export function AboutPage() {
    useEffect(() => {
        EnableScrollbar()
    }, [])

    return (
        <>
            <Header />
            <Container className='section-container bg-light text-dark text-start p-4'>
                <Container className='fluid w-50 mt-3'>
                    <div className='text-center'>
                        <h3>Why LevelUpArtic?</h3>
                    </div>
                    <div className='mt-3'>
                        <p>
                            Typically, stimulus words for speech therapy are selected on the basis of target sound and position. However, with these
                            as the only criteria, articulation lists are often uneven. Words with a broad range of phonetic complexity are considered
                            equal under the umbrella of a single sound.
                        </p>
                        <p>
                            We’ve used principles of clinical phonetics — and years of experience — to design and assign levels of difficulty to our
                            target words. These levels allow you to meet each student where they are and help them progress more quickly to mastery …
                            to level up, if you will!
                        </p>
                    </div>
                    <div className='text-center mt-5'>
                        <h3>How the levels work</h3>
                    </div>
                    <div className='mt-3'>
                        <h4>Words</h4>
                        <span>
                            For each target sound we have established a hierarchy of “complicators” - sounds or word characteristics that make
                            accurate productions more difficult. Based on these complicators, words have been sorted into three levels:
                            <ul>
                                <li>Level 1: no complicators</li>
                                <li>Level 2: minimal-moderate complicators</li>
                                <li>Level 3: most challenging complicators</li>
                            </ul>
                        </span>
                    </div>
                    <div className='mt-4'>
                        <h4>Phrases / sentences</h4>
                        <span>
                            Four levels of phrases / sentences are organized according to similar principles:
                            <ul>
                                <li>Level A: simple phrase, no complicators</li>
                                <li>Level B: sentence with single target word, no complicators</li>
                                <li>Level C: sentence with multiple target words, few complicators</li>
                                <li>Level D: sentence with multiple target words, multiple complicators</li>
                            </ul>
                        </span>
                    </div>
                    <div className='mt-4'>
                        <h4>Lists</h4>
                        <p>
                            Our selection criteria allow you to customize lists by including and excluding words as needed. With a LevelUpArtic
                            account, lists can be named and saved so you can easily access the words you want for each of your students.
                        </p>
                    </div>
                </Container>
            </Container>
            <Footer />
        </>
    )
}
