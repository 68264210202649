import axios from 'axios'
import { IsUserLoggedIn, GetUserAuthAccessToken } from '../state/UserAuthState'
import { IsUserSubscribed } from '../state/SubscriptionState'
import { BACKEND_STATUS_WORKING, BACKEND_STATUS_SUCCESS, BACKEND_STATUS_FAILURE } from './BackendResources'

export async function GetAllBackendLists(callback) {
    if (!IsUserLoggedIn() || !IsUserSubscribed()) {
        return
    }

    let config = { headers: { 'Content-type': 'application/json', Authorization: `Bearer ${GetUserAuthAccessToken()}` } }
    let axiosInstance = axios.create()

    try {
        const { data } = await axiosInstance.get('/api/lists/', config)
        let lists = []

        for (const backendList of data) {
            let list = { ...backendList }
            list.words = JSON.parse(list.words)
            lists.push(list)
        }

        callback(lists)
    } catch (exception) {
        let _response = null

        if (exception['response'] && exception['response']['data'] && exception['response']['data']['detail']) {
            _response = exception['response']['data']['detail']
        } else {
            _response = ''
        }
    }
}

export async function CreateBackendList(listName, wordKeys, callback) {
    if (!IsUserLoggedIn() || !IsUserSubscribed()) {
        return
    }

    let config = { headers: { 'Content-type': 'application/json', Authorization: `Bearer ${GetUserAuthAccessToken()}` } }
    let axiosInstance = axios.create()
    var response = null
    var status = BACKEND_STATUS_WORKING

    try {
        const { data } = await axiosInstance.post(
            '/api/lists/create/', { name: listName, words: JSON.stringify(wordKeys) }, config)
        status = BACKEND_STATUS_SUCCESS
        response = data
    } catch (exception) {
        status = BACKEND_STATUS_FAILURE

        if (exception['response'] && exception['response']['data'] && exception['response']['data']['detail']) {
            response = exception['response']['data']['detail']
        } else {
            response = ''
        }
    }

    callback(status, response)
}

export async function UpdateBackendList(listId, listName, wordKeys, callback) {
    if (!IsUserLoggedIn() || !IsUserSubscribed()) {
        return
    }

    let config = { headers: { 'Content-type': 'application/json', Authorization: `Bearer ${GetUserAuthAccessToken()}` } }
    let axiosInstance = axios.create()
    var response = null
    var status = BACKEND_STATUS_WORKING

    try {
        const { data } = await axiosInstance.put(
            `/api/lists/update/${listId}/`,
            { name: listName, words: JSON.stringify(wordKeys) },
            config
        )

        status = BACKEND_STATUS_SUCCESS
        response = data
    } catch (exception) {
        status = BACKEND_STATUS_FAILURE

        if (exception['response'] && exception['response']['data'] && exception['response']['data']['detail']) {
            response = exception['response']['data']['detail']
        } else {
            response = ''
        }
    }

    callback(status, response)
}

export async function DeleteBackendList(listId, callback) {
    if (!IsUserLoggedIn() || !IsUserSubscribed()) {
        return
    }

    let config = { headers: { 'Content-type': 'application/json', Authorization: `Bearer ${GetUserAuthAccessToken()}` } }
    let axiosInstance = axios.create()
    var response = null
    var status = BACKEND_STATUS_WORKING

    try {
        const { data } = await axiosInstance.put(`/api/lists/delete/${listId}/`, {}, config)

        status = BACKEND_STATUS_SUCCESS
        response = data
    } catch (exception) {
        status = BACKEND_STATUS_FAILURE

        if (exception['response'] && exception['response']['data'] && exception['response']['data']['detail']) {
            response = exception['response']['data']['detail']
        } else {
            response = ''
        }
    }

    callback(status, response)
}
