import { useState, useEffect, useCallback } from 'react'
import { Container, ButtonGroup, Form, Image, Modal } from 'react-bootstrap'
import { useNavigate, useLocation } from 'react-router-dom'
import PropTypes from 'prop-types'
import { LOGIN_SIGNUP_PATH, START_PATH, SUBSCRIPTION_PATH } from '../App'
import { GetSiteImageUrl } from '../project/ProjectResources'
import { BACKEND_STATUS_SUCCESS, BACKEND_STATUS_FAILURE } from '../project/BackendResources'
import { CreateBackendList } from '../project/BackendListOperations'
import { START_PAGE_SAVED_LIST_TAB, START_PAGE_STORED_SELECTED_TAB } from './StartPage'
import { Header } from '../components/Header'
import { Footer } from '../components/Footer'
import {
    ListOperationTabRowComponent,
    ListOperationTabComponent,
    ListOperationActionAreaComponent,
    ListOperationWordsAreaComponent,
    ListOperationWordsComponent
} from '../components/ListOperationComponents'
import { ActionButtonComponent } from '../components/ActionButtonComponents'
import { SuccessMessageComponent, ErrorMessageComponent } from '../components/MessageComponents'
import { UserAuthStateChangeListener, IsUserLoggedIn } from '../state/UserAuthState'
import { IsUserSubscribed } from '../state/SubscriptionState'
import { SelectListId } from '../state/ListsState'

export function ListCreatePage() {
    const { state } = useLocation()
    const [listName, SetListName] = useState('')
    const [listWords, SetListWords] = useState([])

    useEffect(() => {
        if (state) {
            if (state['listName']) {
                SetListName(state['listName'])
            }

            if (state['listWords']) {
                SetListWords(state['listWords'])
            }
        }
    }, [state])

    const navigate = useNavigate()

    const [userStateChangeString, SetUserStateChangeString] = useState('')

    useEffect(() => {
        if (!IsUserLoggedIn()) {
            navigate(LOGIN_SIGNUP_PATH)
        }
        else if (!IsUserSubscribed()) {
            navigate(SUBSCRIPTION_PATH)
        }
    }, [userStateChangeString, navigate])

    const HandleUserStateChange = useCallback((listenerString) => {
        SetUserStateChangeString(listenerString)
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const MESSAGE_TYPE_NONE = 'ListCreateMessageType/None'
    const MESSAGE_TYPE_SUCCESS = 'ListCreateMessageType/Success'
    const MESSAGE_TYPE_ERROR = 'ListCreateMessageType/Error'

    const [messageType, SetMessageType] = useState(MESSAGE_TYPE_NONE)
    const [message, SetMessage] = useState('')

    const COLOR_MY_LIST = 'dark-aqua'

    const tabRowContent =
        <>
            <ListOperationTabComponent
                color={COLOR_MY_LIST}
                text='My List'
                isActive={true}
                ClickAction={() => { }}
            />
        </>

    const HandleBackendListResponse = (status, response) => {
        if (status === BACKEND_STATUS_FAILURE) {
            SetMessageType(MESSAGE_TYPE_ERROR)
            SetMessage('A failure occurred. The list was not created.')
        } else if (status === BACKEND_STATUS_SUCCESS) {
            SelectListId(response['id'])
            sessionStorage.setItem(START_PAGE_STORED_SELECTED_TAB, START_PAGE_SAVED_LIST_TAB)
            navigate(START_PATH)
        }
    }

    let actionAreaContent = <></>

    const [instructionsModalVisibility, SetInstructionsModalVisibility] = useState(false)

    if (messageType === MESSAGE_TYPE_ERROR) {
        actionAreaContent =
            <div className='mt-0 mb-3'>
                <ErrorMessageComponent
                    DismissAlert={() => {
                        navigate(START_PATH)
                    }}
                    messageWidth='90%'
                >
                    {message}
                </ErrorMessageComponent>
            </div>
    }
    else if (messageType === MESSAGE_TYPE_SUCCESS) {
        actionAreaContent =
            <div className='mt-0 mb-3'>
                <SuccessMessageComponent
                    DismissAlert={() => {
                        SetMessageType(MESSAGE_TYPE_NONE)
                        SetMessage('')
                    }}
                    messageWidth='90%'
                >
                    {message}
                </SuccessMessageComponent>
            </div >
    }
    else {
        actionAreaContent =
            <>
                <CreateListNameActionComponent
                    SetListName={(name) => SetListName(name)}
                    GetListName={() => listName}
                    HandleCreateList={() => CreateBackendList(listName, listWords, HandleBackendListResponse)}
                    HandleCancel={() => navigate(START_PATH)}
                    showModal={instructionsModalVisibility}
                    HandleModalClose={() => SetInstructionsModalVisibility(false)}
                />
            </>
    }

    return (
        <>
            <UserAuthStateChangeListener callback={HandleUserStateChange} />
            <Header />
            <Container className='section-container bg-light'>
                <div className='list-operation-page-div'>
                    <Container className='section-container bg-light w-100 pt-4 pb-4'>
                        <ListOperationTabRowComponent>
                            {tabRowContent}
                        </ListOperationTabRowComponent>
                        <ListOperationActionAreaComponent props={{ color: COLOR_MY_LIST }} >
                            {actionAreaContent}
                        </ListOperationActionAreaComponent>
                    </Container>
                    <Container className='list-operation-control-container'>
                        <Container className='vstack list-operation-control-icon-container'>
                            <Image fluid className='list-operation-control-icon'
                                src={GetSiteImageUrl('question-square-fill-dark-blue.svg')}
                                onClick={() => SetInstructionsModalVisibility(true)} />
                        </Container>
                    </Container>
                </div>
                <ListOperationWordsAreaComponent>
                    <ListOperationWordsComponent words={listWords} />
                </ListOperationWordsAreaComponent>
            </Container >
            <Footer />
        </>
    )
}

function CreateListNameActionComponent({ SetListName, GetListName, HandleCreateList, HandleCancel,
    showModal = false, HandleModalClose = () => { } }) {
    return (
        <>
            <div className='d-flex justify-content-center align-items-center w-100 mb-4'>
                <div className='list-operation-name-container vstack'>
                    <Form.Group controlId='listName' className='w-100 mx-auto mt-2'>
                        <Form.Label className='list-operation-name-label'>Name</Form.Label>
                        <Form.Control
                            className='list-operation-name-box'
                            type='text'
                            value={GetListName()}
                            placeholder='Enter a list name'
                            autoComplete='off'
                            onChange={(event) => {
                                event.preventDefault()
                                SetListName(event.target.value)
                            }}
                        />
                    </Form.Group>
                    <ButtonGroup className='flex-wrap mx-1'>
                        <ActionButtonComponent
                            text='Create List'
                            width='8rem'
                            margins='1rem 0.5rem 0 1rem'
                            isActive={GetListName().length > 0}
                            DoAction={() => HandleCreateList()}
                        />
                        <ActionButtonComponent
                            text='Cancel'
                            width='6.5rem'
                            margins='1rem 0.5rem 0 1rem'
                            isActive={true}
                            DoAction={() => HandleCancel()}
                        />
                    </ButtonGroup>
                </div>
            </div >
            <CreateListNameInstructionsModalComponent showModal={showModal} HandleModalClose={HandleModalClose} />
        </>
    )
}

CreateListNameActionComponent.propTypes = {
    SetListName: PropTypes.func.isRequired,
    GetListName: PropTypes.func.isRequired,
    HandleCreateList: PropTypes.func.isRequired,
    HandleCancel: PropTypes.func.isRequired,
    showModal: PropTypes.bool,
    HandleModalClose: PropTypes.func,
}

function CreateListNameInstructionsModalComponent({ showModal, HandleModalClose }) {
    return (
        <Modal
            className='fade modal-lg'
            style={{ color: 'var(--dark-blue)' }}
            show={showModal}
            onHide={() => { HandleModalClose() }}
        >
            <Modal.Header className='bg-light py-3'>
                <Modal.Title className='text-center mx-auto'>
                    <h4 className='my-0'>My List</h4>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className='bg-light'>
                <div className='d-flex justify-content-center mx-auto'>
                    <div className='justify-content-left mx-5'>
                        <h5>Enter a unique name for your list and review the words. If everything looks good, click the Create List button to save your new list.</h5>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer className='bg-light py-1' style={{ border: 'none' }}>
            </Modal.Footer>
        </Modal >
    )
}

CreateListNameInstructionsModalComponent.propTypes = {
    showModal: PropTypes.bool.isRequired,
    HandleModalClose: PropTypes.func.isRequired,
}
