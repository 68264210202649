import { Container, Card, Button, Image } from 'react-bootstrap'
import PropTypes from 'prop-types'
import { GetWordText } from '../project/WordCodings'
import { GetWordImageUrl, GetSiteImageUrl } from '../project/ProjectResources'
import './FlashcardComponents.css'

export function FlashcardsLevelTabComponent({ color, text, isActive, DoAction }) {
    const tabStyle = isActive ? {
        opacity: 1.0,
    } : {
        opacity: 0.5,
    }

    return (
        <Container className='flashcards-level-tab' style={tabStyle} onClick={() => DoAction()}>
            <div className='flashcards-level-tab-image'>
                <Image fluid src={GetSiteImageUrl(`activity-tab-${color}.svg`)} />
            </div>
            <div className='flashcards-level-tab-text'>{text}</div>
        </Container>
    )
}

FlashcardsLevelTabComponent.propTypes = {
    color: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    isActive: PropTypes.bool.isRequired,
    DoAction: PropTypes.func.isRequired,
}

export function FlashcardCardComponent({ wordKey }) {
    return (
        <Container className='text-center w-100 my-auto px-1'>
            <Card className='flashcard bg-light mx-auto'>
                <div className='flashcard-image-div'>
                    <img className='flashcard-image img-fluid card-img-top' src={GetWordImageUrl(wordKey)} alt={GetWordText(wordKey)} />
                </div>
            </Card>
        </Container>
    )
}

FlashcardCardComponent.propTypes = {
    wordKey: PropTypes.string.isRequired,
}

export function FlashcardFwdRevComponent({ direction, color, showComponent, DoAction }) {
    var srcImage = ''
    var altText = ''
    var containerClass = 'my-auto w-25'

    if (direction === 'FWD') {
        srcImage = `caret-right-square-fill-${color}.svg`
        altText = 'FWD'
        containerClass += ' text-end'
    } else if (direction === 'REV') {
        srcImage = `caret-left-square-fill-${color}.svg`
        altText = 'REV'
        containerClass += ' text-start'
    } else {
        return <></>
    }

    return (
        <Container className={containerClass}>
            {showComponent && (
                <Image fluid className='flashcard-fwd-rev-image' src={GetSiteImageUrl(srcImage)} alt={altText} onClick={() => DoAction()} />
            )}
        </Container>
    )
}

FlashcardFwdRevComponent.propTypes = {
    direction: PropTypes.string.isRequired,
    color: PropTypes.string.isRequired,
    showComponent: PropTypes.bool.isRequired,
    DoAction: PropTypes.func.isRequired,
}

function GetPhraseHtml(phrase, color) {
    return { __html: '<h1>' + HighlightHtmlText(phrase, color) + '</h1>' }
}

function HighlightHtmlText(text, _color) {
    var unhighlightedText = text
    var highlightedText = ''

    while (unhighlightedText.length > 0) {
        var leftBracketPosition = unhighlightedText.search(/</)

        if (leftBracketPosition === -1) {
            highlightedText += unhighlightedText
            unhighlightedText = ''
        } else {
            var rightBracketPosition = unhighlightedText.search(/>/)
            highlightedText += unhighlightedText.substring(0, leftBracketPosition)
            highlightedText += '<strong>'
            highlightedText += unhighlightedText.substring(leftBracketPosition + 1, rightBracketPosition)
            highlightedText += '</strong>'
            unhighlightedText = unhighlightedText.substring(rightBracketPosition + 1)
        }
    }

    return highlightedText
}

export function FlashcardPhraseComponent({ phrase, color }) {
    return (
        <Container className='flashcard-phrase mt-3 mt-md-4'>
            <div dangerouslySetInnerHTML={GetPhraseHtml(phrase, color)} />
        </Container>
    )
}

FlashcardPhraseComponent.propTypes = {
    phrase: PropTypes.string.isRequired,
    color: PropTypes.string.isRequired,
}

export function FlashcardPhraseButtonComponent({ buttonText, isActive, DoAction }) {
    var buttonClass = 'btn mx-1 mt-1'
    buttonClass += isActive ? ' btn-primary' : ' btn-outline-primary'

    return (
        <Button className={buttonClass} onClick={() => DoAction()}>
            {buttonText}
        </Button>
    )
}

FlashcardPhraseButtonComponent.propTypes = {
    buttonText: PropTypes.string.isRequired,
    isActive: PropTypes.bool.isRequired,
    DoAction: PropTypes.func.isRequired,
}

export function FlashcardTargetSoundAndPositionTextComponent({ targetSoundAndPosition }) {
    let targetSoundAndPositionText = GetTargetSoundAndPositionButtonText(targetSoundAndPosition)

    return (
        <div className='d-flex justify-content-center mt-4'>
            <div className='my-auto'>
                <h2>{targetSoundAndPositionText}</h2>
            </div>
        </div >
    )
}

FlashcardTargetSoundAndPositionTextComponent.propTypes = {
    targetSoundAndPosition: PropTypes.arrayOf(PropTypes.string).isRequired,
}

function GetTargetSoundAndPositionButtonText(targetSoundAndPosition) {
    const positionText = {
        LocI: 'Initial',
        LocM: 'Medial',
        LocF: 'Final',
        Rec: 'Recurrent',
        BldBl: 'BL Blend',
        BldBr: 'BR Blend',
        BldDr: 'DR Blend',
        BldFl: 'FL Blend',
        BldFr: 'FR Blend',
        BldGl: 'GL Blend',
        BldGr: 'GR Blend',
        BldKl: 'KL Blend',
        BldKr: 'KR Blend',
        BldPl: 'PL Blend',
        BldPr: 'PR Blend',
        BldSk: 'SK Blend',
        BldSl: 'SL Blend',
        BldSm: 'SM Blend',
        BldSn: 'SN Blend',
        BldSp: 'SP Blend',
        BldSt: 'ST Blend',
        BldSw: 'SW Blend',
        BldTr: 'TR Blend',
        BldCx: 'Complex Blend',
        VocAir: 'AIR Vocalic',
        VocAr: 'AR Vocalic',
        VocEar: 'EAR Vocalic',
        VocEr: 'ER Vocalic',
        VocIre: 'IRE Vocalic',
        VocOr: 'OR Vocalic',
        VclI: 'Voiceless Initial',
        VclM: 'Voiceless Medial',
        VclF: 'Voiceless Final',
        VcdI: 'Voiced Initial',
        VcdM: 'Voiced Medial',
        VcdF: 'Voiced Final',
    }

    return targetSoundAndPosition[0] + ' (' + positionText[targetSoundAndPosition[1]] + ')'
}
