import { useState, useEffect, useCallback } from 'react'
import { Container, Form, Modal } from 'react-bootstrap'
import PropTypes from 'prop-types'
import { ErrorMessageComponent } from './MessageComponents'
import { LoaderComponent } from './LoaderComponents'
import { ActionButtonComponent } from './ActionButtonComponents'
import {
    SubscriptionStateChangeListener, IsSubscriptionValid, GetSubscriptionOperation, GetSubscriptionFailure, GetSubscriptionInfo,
    RefreshSubscription, SUBSCRIPTION_OPERATION_NONE, SUBSCRIPTION_FAILURE_NONE
} from '../state/SubscriptionState'
import { IsUserLoggedIn, GetUserAuthAccessToken, UserAuthStateChangeListener } from '../state/UserAuthState'
import { GetBackendSubscriptionPortal } from '../project/BackendSubscriptionOperations'
import { BACKEND_STATUS_SUCCESS, BACKEND_STATUS_FAILURE } from '../project/BackendResources'
import './SubscriptionComponents.css'

export const SUBSCRIPTION_TYPE_NONE = 'subscription-type-none'
export const SUBSCRIPTION_TYPE_MONTH = 'subscription-type-month'
export const SUBSCRIPTION_TYPE_YEAR = 'subscription-type-year'
export const SUBSCRIPTION_TYPE_FREE = 'subscription-type-free'

export const SUBSCRIPTION_STATUS_ACTIVE = 'subscription-status-active'
export const SUBSCRIPTION_STATUS_INACTIVE = 'subscription-status-inactive'
export const SUBSCRIPTION_STATUS_TRIAL = 'subscription-status-trial'
export const SUBSCRIPTION_STATUS_PENDING = 'subscription-status-pending'

export function SubscriptionComponent({ showModal = false, CloseModal = () => { } }) {
    const [subscriptionRetrieveActive, SetSubscriptionRetrieveActive] = useState(true)
    const [subscriptionRetrieveError, SetSubscriptionRetrieveError] = useState(false)
    const [subscriptionRetrieveInfoValid, SetSubscriptionRetrieveInfoValid] = useState(false)

    const [subscriptionPortalError, SetSubscriptionPortalError] = useState(false)

    const [subscriptionType, SetSubscriptionType] = useState('')
    const [subscriptionStatus, SetSubscriptionStatus] = useState('')
    const [subscriptionRenewalDate, SetSubscriptionRenewalDate] = useState('')
    const [subscriptionRenewalAmount, SetSubscriptionRenewalAmount] = useState('')

    useEffect(() => { },
        [subscriptionRetrieveActive, subscriptionRetrieveError, subscriptionRetrieveInfoValid,
            subscriptionType, subscriptionRenewalDate, subscriptionRenewalAmount])

    const UpdateSubscriptionRetrieveStatus = () => {
        const retrieveError =
            ((GetSubscriptionOperation() === SUBSCRIPTION_OPERATION_NONE) && !IsSubscriptionValid()) ||
            (GetSubscriptionFailure() !== SUBSCRIPTION_FAILURE_NONE)

        if (!retrieveError) {
            const subscriptionInfo = GetSubscriptionInfo()
            SetSubscriptionType(subscriptionInfo['subscriptionType'] || '')
            SetSubscriptionStatus(subscriptionInfo['subscriptionStatus'] || '')
            SetSubscriptionRenewalDate(subscriptionInfo['renewalDate'] || '')
            SetSubscriptionRenewalAmount(subscriptionInfo['renewalAmount'] || '')
        }

        SetSubscriptionRetrieveActive(GetSubscriptionOperation() !== SUBSCRIPTION_OPERATION_NONE)
        SetSubscriptionRetrieveError(retrieveError)
        SetSubscriptionRetrieveInfoValid((GetSubscriptionOperation() === SUBSCRIPTION_OPERATION_NONE) && !retrieveError)
    }

    const HandleSubscriptionStateChange = useCallback(() => {
        UpdateSubscriptionRetrieveStatus()
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const GetSubscriptionTypeDesc = () => {
        switch (subscriptionType) {
            case SUBSCRIPTION_TYPE_NONE:
                return 'No Subscription'

            case SUBSCRIPTION_TYPE_MONTH:
                return 'Monthly Subscription'

            case SUBSCRIPTION_TYPE_YEAR:
                return 'Annual Subscription'

            case SUBSCRIPTION_TYPE_FREE:
                return 'Free Subscription'

            default:
                return ''
        }
    }

    const GetSubscriptionStatusText = () => {
        switch (subscriptionStatus) {
            case SUBSCRIPTION_STATUS_ACTIVE:
                return 'Active'

            case SUBSCRIPTION_STATUS_TRIAL:
                return 'Trial'

            case SUBSCRIPTION_STATUS_PENDING:
                return 'Pending Payment'

            case SUBSCRIPTION_STATUS_INACTIVE:
            default:
                return 'Inactive'
        }
    }

    const GetSubscriptionDetails = () => {
        if (subscriptionRetrieveActive) {
            return <div className='text-center mt-5 ps-5'><LoaderComponent spinnerColor='var(--dark-aqua)' spinnerSize='150px' /></div>
        }
        else if (subscriptionRetrieveError) {
            return (
                <ErrorMessageComponent
                    isDismissible={false}
                    DismissAlert={() => { }}
                    messageWidth='90%'
                >
                    Sorry, we encountered an error accessing your account. Please try again later.
                </ErrorMessageComponent>
            )
        }
        else if (subscriptionPortalError) {
            return (
                <ErrorMessageComponent
                    isDismissible={true}
                    DismissAlert={() => {
                        SetSubscriptionPortalError(false)
                        SetIsManagementAreaActive(true)
                    }}
                    messageWidth='90%'
                >
                    Sorry, we could not connect to your Stripe account. Please try again later.
                </ErrorMessageComponent>
            )
        }

        return (
            <div className='vstack mt-1 ms-2'>
                <Form.Group controlId='subscriptionType' className='w-100'>
                    <Form.Label className='subscription-label'>Type</Form.Label>
                    <Form.Control
                        className='subscription-view-box'
                        type='text'
                        value={GetSubscriptionTypeDesc()}
                        placeholder=''
                        disabled={true}
                        autoComplete='off'
                        onChange={event => event.preventDefault()}
                    />
                </Form.Group>
                <Form.Group controlId='subscriptionStatus' className='w-100 mt-4'>
                    <Form.Label className='subscription-label'>Status</Form.Label>
                    <Form.Control
                        className='subscription-view-box'
                        type='text'
                        value={GetSubscriptionStatusText()}
                        placeholder=''
                        disabled={true}
                        autoComplete='off'
                        onChange={event => event.preventDefault()}
                    />
                </Form.Group>

                {((subscriptionType === SUBSCRIPTION_TYPE_MONTH || subscriptionType === SUBSCRIPTION_TYPE_YEAR) && subscriptionStatus !== SUBSCRIPTION_STATUS_INACTIVE) &&
                    <Form.Group controlId='subscriptionRenewal' className='w-100 mt-4'>
                        <Form.Label className='subscription-label'>
                            {(subscriptionStatus === SUBSCRIPTION_STATUS_TRIAL) ? 'First Payment' : 'Renewal Payment'}
                        </Form.Label>
                        <Form.Control
                            className='subscription-view-box'
                            type='text'
                            value={'$' + subscriptionRenewalAmount + ' on ' + subscriptionRenewalDate}
                            placeholder=''
                            disabled={true}
                            onChange={(event) => {
                                event.preventDefault()
                            }}
                        />
                    </Form.Group>
                }
            </div>
        )
    }

    const [isManagementAreaActive, SetIsManagementAreaActive] = useState(true)

    const HandleBackendCreateSessionResponse = (backendStatus, response) => {
        if (backendStatus === BACKEND_STATUS_SUCCESS) {
            const portalUrl = response['portalUrl']
            window.location.href = portalUrl
        }
        else if (backendStatus === BACKEND_STATUS_FAILURE) {
            SetSubscriptionPortalError(true)
        }
    }

    const GetSubscriptionManagementArea = () => {
        if (!subscriptionRetrieveInfoValid || subscriptionPortalError) {
            return <></>
        }

        return (
            <div className='vstack w-50'>
                <ActionButtonComponent
                    text='Manage Subscription'
                    width='13rem'
                    margins={'2.4rem 1rem 0 1.5rem'}
                    isActive={isManagementAreaActive}
                    DoAction={() => {
                        SetIsManagementAreaActive(false)
                        GetBackendSubscriptionPortal(HandleBackendCreateSessionResponse)
                    }}
                />
                <div className='mt-4 mx-3'>
                    <h5>You will be redirected to a secure Stripe session to review and update your subscription.</h5>
                    <div className='mt-3'>
                        <h5 style={{ display: 'inline' }}>Questions about your subscription?</h5>
                        &nbsp; &nbsp;
                        <a href="mailto:support@levelupartic.com?subject=My LevelUp Artic subscription"
                            style={{ color: 'var(--dark-blue)', textDecoration: 'none' }}>
                            <h5 style={{ display: 'inline' }}>Contact us</h5>
                        </a>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <>
            <SubscriptionStateChangeListener callback={HandleSubscriptionStateChange} />
            <Container fluid className='d-flex flex-wrap justify-content-start pt-2 pb-4 ps-5'>
                <div className='me-5'>
                    {GetSubscriptionDetails()}
                </div>
                {GetSubscriptionManagementArea()}
            </Container>
            <SubscriptionInstructionsModalComponent showModal={showModal} HandleModalClose={CloseModal} />
        </>
    )
}

SubscriptionComponent.propTypes = {
    showModal: PropTypes.bool,
    CloseModal: PropTypes.func,
}

function SubscriptionInstructionsModalComponent({ showModal, HandleModalClose }) {
    return (
        <Modal
            className='fade modal-lg'
            style={{ color: 'var(--dark-blue)' }}
            show={showModal}
            onHide={() => { HandleModalClose() }}
        >
            <Modal.Header className='bg-light py-3'>
                <Modal.Title className='text-center mx-auto'>
                    <h4 className='my-0'>Your Subscription</h4>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className='bg-light'>
                <div className='d-flex justify-content-center mx-auto'>
                    <div className='justify-content-left mx-5'>
                        <h5>Click Manage Subscription to update your subscription and payment information in your Stripe customer portal.</h5>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer className='bg-light py-1' style={{ border: 'none' }}>
            </Modal.Footer>
        </Modal >
    )
}

SubscriptionInstructionsModalComponent.propTypes = {
    showModal: PropTypes.bool.isRequired,
    HandleModalClose: PropTypes.func.isRequired,
}

export function SubscriptionRefreshComponent() {
    const [isLoggedIn, SetIsLoggedIn] = useState(false)
    const [accessToken, SetAccessToken] = useState(null)

    const REFRESH_DELAY_MSEC = 200

    useEffect(() => {
        if (!isLoggedIn || !accessToken) {
            return
        }

        let interval = setInterval(() => {
            RefreshSubscription(accessToken)
            clearInterval(interval)
        }, REFRESH_DELAY_MSEC)
    }, [isLoggedIn, accessToken]) // eslint-disable-line react-hooks/exhaustive-deps

    const HandleUserStateChange = (_listenerString) => {
        const newIsLoggedIn = IsUserLoggedIn()
        const newAccessToken = GetUserAuthAccessToken()

        if (newIsLoggedIn !== isLoggedIn) {
            SetIsLoggedIn(newIsLoggedIn)
        }

        if (newAccessToken !== accessToken) {
            SetAccessToken(newAccessToken)
        }
    }

    return (
        <>
            <UserAuthStateChangeListener callback={HandleUserStateChange} />
        </>
    )
}
