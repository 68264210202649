import { useState, useEffect } from 'react'
import { ButtonGroup, Form, Row, Col } from 'react-bootstrap'
import PropTypes from 'prop-types'
import { IsUserAdmin } from '../state/UserAuthState'
import { GetAdminBackendUserAccounts } from '../project/BackendUserOperations'
import { GetAdminBackendUserSubscriptions } from '../project/BackendSubscriptionOperations'
import {
    SUBSCRIPTION_TYPE_NONE, SUBSCRIPTION_TYPE_FREE, SUBSCRIPTION_TYPE_MONTH, SUBSCRIPTION_TYPE_YEAR,
    SUBSCRIPTION_STATUS_ACTIVE, SUBSCRIPTION_STATUS_INACTIVE, SUBSCRIPTION_STATUS_TRIAL, SUBSCRIPTION_STATUS_PENDING
} from './SubscriptionComponents'
import './ControlCenterComponents.css'

export function ControlCenterDataGroupSelectComponent({ dataGroups, selectedDataGroupId, SelectDataGroup }) {
    const HandleSelectChange = (event) => {
        event.preventDefault()
        SelectDataGroup(event.target.value)
    }

    return (
        <ButtonGroup className='vstack data-group-select-button-group'>
            <Form.Select
                className='data-group-select-select'
                value={selectedDataGroupId}
                onChange={(event) => HandleSelectChange(event)}
            >
                <option value='' disabled hidden>
                    Choose Data
                </option>
                {Object.keys(dataGroups).map((dataGroupId) => (
                    <option key={dataGroupId} value={dataGroupId}>
                        {dataGroups[dataGroupId]}
                    </option>
                ))}
            </Form.Select>
        </ButtonGroup>
    )
}

ControlCenterDataGroupSelectComponent.propTypes = {
    dataGroups: PropTypes.object.isRequired,
    selectedDataGroupId: PropTypes.string.isRequired,
    SelectDataGroup: PropTypes.func.isRequired,
}

export function ControlCenterUserProfileTableComponent() {
    const [accounts, SetAccounts] = useState([])

    const HandleAdminBackendUserAccountsResponse = (response) => {
        SetAccounts(response)
    }

    useEffect(() => {
        GetAdminBackendUserAccounts(HandleAdminBackendUserAccountsResponse)
    }, [])

    if (!IsUserAdmin()) {
        return <></>
    }

    return (
        <>
            <Row>
                <Col md={2} className='text-start'>Name</Col>
                <Col md={3} className='text-start'>Email</Col>
                <Col md={2} className='text-start'>Join Date</Col>
                <Col md={5} />
            </Row>
            <Row>
                <Col sm={12}><hr /></Col>
            </Row>
            <div className='text-center'>
                {SortUserAccounts(accounts).map((account, index) => (
                    <ControlCenterUserAccountRowComponent
                        accountInfo={account}
                        key={index}
                    />))}
            </div>
        </>
    )
}

function ControlCenterUserAccountRowComponent({ accountInfo }) {
    const name = accountInfo['name']
    const email = accountInfo['email']
    const joinDate = accountInfo['joinDate']

    return (
        <>
            <Row>
                <Col md={2} className='text-start'>{name}</Col>
                <Col md={3} className='text-start'>{email}</Col>
                <Col md={2} className='text-start'>{joinDate}</Col>
                <Col md={5} />
            </Row>
        </>
    )
}

function SortUserAccounts(accounts) {
    let sortedAccounts = [...accounts]

    if (accounts.length > 1) {
        sortedAccounts.sort((account1, account2) => {
            const accountUsername1 = account1.username.toLowerCase()
            const accountUsername2 = account2.username.toLowerCase()

            if (accountUsername1 < accountUsername2) {
                return -1
            } else if (accountUsername1 > accountUsername2) {
                return 1
            }

            return 0
        })
    }

    return sortedAccounts
}

export function ControlCenterUserSubscriptionTableComponent() {
    const [subscriptions, SetSubscriptions] = useState([])

    const HandleAdminBackendUserSubscriptionsResponse = (response) => {
        SetSubscriptions(response)
    }

    useEffect(() => {
        GetAdminBackendUserSubscriptions(HandleAdminBackendUserSubscriptionsResponse)
    }, [])

    if (!IsUserAdmin()) {
        return <></>
    }

    return (
        <>
            <Row>
                <Col md={3} className='text-start'>Username</Col>
                <Col md={2} className='text-start'>Subscription Type</Col>
                <Col md={2} className='text-start'>Subscription Status</Col>
                <Col md={2} className='text-start'>Renewal Date</Col>
                <Col md={3} />
            </Row>
            <Row>
                <Col sm={12}><hr /></Col>
            </Row>
            <div className='text-center'>
                {SortUserAccounts(subscriptions).map((subscription, index) => (
                    <ControlCenterUserSubscriptionRowComponent
                        subscriptionInfo={subscription}
                        key={index}
                    />))}
            </div>
        </>
    )
}

function ControlCenterUserSubscriptionRowComponent({ subscriptionInfo }) {
    const username = subscriptionInfo['username']
    const subscriptionType = subscriptionInfo['subscriptionType']
    const subscriptionStatus = subscriptionInfo['subscriptionStatus']
    const renewalDate = subscriptionInfo['renewalDate']

    let subscriptionTypeText = ''

    if (subscriptionType === SUBSCRIPTION_TYPE_NONE) subscriptionTypeText = 'None'
    else if (subscriptionType === SUBSCRIPTION_TYPE_FREE) subscriptionTypeText = 'Free'
    else if (subscriptionType === SUBSCRIPTION_TYPE_MONTH) subscriptionTypeText = 'Monthly'
    else if (subscriptionType === SUBSCRIPTION_TYPE_YEAR) subscriptionTypeText = 'Annual'

    let subscriptionStatusText = ''

    if (subscriptionStatus === SUBSCRIPTION_STATUS_ACTIVE) subscriptionStatusText = 'Active'
    else if (subscriptionStatus === SUBSCRIPTION_STATUS_INACTIVE) subscriptionStatusText = 'Inactive'
    else if (subscriptionStatus === SUBSCRIPTION_STATUS_TRIAL) subscriptionStatusText = 'Trial'
    else if (subscriptionStatus === SUBSCRIPTION_STATUS_PENDING) subscriptionStatusText = 'Pending'

    return (
        <>
            <Row>
                <Col md={3} className='text-start'>{username}</Col>
                <Col md={2} className='text-start'>{subscriptionTypeText}</Col>
                <Col md={2} className='text-start'>{subscriptionStatusText}</Col>
                <Col md={2} className='text-start'>{renewalDate}</Col>
                <Col md={3} />
            </Row>
        </>
    )
}
