import { Spinner } from 'react-bootstrap'
import PropTypes from 'prop-types'
import './LoaderComponents.css'

export function LoaderComponent({ spinnerColor = 'var(--light-aqua)', spinnerSize = '100px' }) {
    const spinnerStyle = {
        color: spinnerColor,
        height: spinnerSize,
        width: spinnerSize,
    }

    return (
        <Spinner animation='border' role='status' className='loader-spinner' style={spinnerStyle} />
    )
}

LoaderComponent.propTypes = {
    spinnerSize: PropTypes.string,
}
