import { useState, useEffect, useCallback, createContext, useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import { START_PATH } from '../App'

export const ListEditContext = createContext()

export const ListEditProvider = ({ children }) => {
    const [listId, SetListId] = useState('')
    const [listName, SetListName] = useState('')
    const [listWords, SetListWords] = useState([])

    const [slpSearchTargetSound, SetSlpSearchTargetSound] = useState('')
    const [slpSearchPositions, SetSlpSearchPositions] = useState([])
    const [slpSearchLevels, SetSlpSearchLevels] = useState([])
    const [textMatchSearchText, SetTextMatchSearchText] = useState('')

    const ClearSearchParams = useCallback(() => {
        SetSlpSearchTargetSound('')
        SetSlpSearchPositions([])
        SetSlpSearchLevels([])
        SetTextMatchSearchText('')
    }, [])

    const navigate = useNavigate()

    const HandleExit = useCallback(() => {
        navigate(START_PATH)
    }, [navigate])

    return (
        <ListEditContext.Provider
            value={{
                listId, SetListId,
                listName, SetListName,
                listWords, SetListWords,
                slpSearchTargetSound, SetSlpSearchTargetSound,
                slpSearchPositions, SetSlpSearchPositions,
                slpSearchLevels, SetSlpSearchLevels,
                textMatchSearchText, SetTextMatchSearchText,
                ClearSearchParams,
                HandleExit,
            }}
        >
            {children}
        </ListEditContext.Provider>
    )
}

export function ListEditListener({ SendContextChangeNotification }) {
    const context = useContext(ListEditContext)
    const [stringifiedContext, SetStringifiedContext] = useState('')

    useEffect(() => {
        SendContextChangeNotification(stringifiedContext)
    }, [stringifiedContext, SendContextChangeNotification])

    useEffect(() => {
        SetStringifiedContext(JSON.stringify(context))
    }, [context])

    return <></>
}
