import { useState, useEffect, useCallback } from 'react'
import { Container, Image } from 'react-bootstrap'
import PropTypes from 'prop-types'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { LOGIN_SIGNUP_PATH } from '../App'
import { Header } from '../components/Header'
import { Footer } from '../components/Footer'
import { UserProfileComponent } from '../components/UserProfileComponents'
import { SubscriptionComponent } from '../components/SubscriptionComponents'
import { UserAuthStateChangeListener, IsUserLoggedIn, GetUserAuthAccessToken } from '../state/UserAuthState'
import { RetrieveUserProfile } from '../state/UserProfileState'
import { RetrieveSubscription } from '../state/SubscriptionState'
import { DisableScrollbar } from '../project/Display'
import { GetSiteImageUrl } from '../project/ProjectResources'
import '../style/UserAccountPageStyle.css'

export function UserAccountPage() {
    useEffect(() => {
        DisableScrollbar()
    }, [])

    const navigate = useNavigate()
    const [searchParams, SetSearchParams] = useSearchParams();

    const HandleUserAuthStateChange = useCallback(() => {
        if (!IsUserLoggedIn()) {
            navigate(LOGIN_SIGNUP_PATH)
        }
    }, [navigate])

    useEffect(() => {
        HandleUserAuthStateChange()
        RetrieveUserProfile(GetUserAuthAccessToken())
        RetrieveSubscription(GetUserAuthAccessToken())
    }, [HandleUserAuthStateChange])

    const PROFILE_TAB = 'ProfileTab'
    const SUBSCRIPTION_TAB = 'SubscriptionTab'

    const [selectedTab, SetSelectedTab] = useState(PROFILE_TAB)

    const PROFILE_TAB_COLOR = 'dark-orange'
    const SUBSCRIPTION_TAB_COLOR = 'dark-aqua'

    const GetTabColor = () => {
        if (selectedTab === SUBSCRIPTION_TAB) {
            return SUBSCRIPTION_TAB_COLOR
        }

        return PROFILE_TAB_COLOR
    }

    useEffect(() => {
        const tabParam = searchParams.get('tab')
        SetSearchParams({})

        if (tabParam && tabParam === 'subscription') {
            SetSelectedTab(SUBSCRIPTION_TAB)
        }
    }, [])  // eslint-disable-line react-hooks/exhaustive-deps

    const [instructionsModalVisibility, SetInstructionsModalVisibility] = useState(false)

    return (
        <>
            <UserAuthStateChangeListener callback={HandleUserAuthStateChange} />
            <Header />
            <Container className='section-container bg-light'>
                <div className='user-account-page-div'>
                    <Container className='section-container bg-light w-100 pt-4 pb-4'>
                        <UserAccountPageTabRowComponent>
                            <UserAccountPageTabComponent
                                color={PROFILE_TAB_COLOR}
                                text='Profile'
                                isActive={selectedTab === PROFILE_TAB}
                                ClickAction={() => { SetSelectedTab(PROFILE_TAB) }}
                            />
                            <UserAccountPageTabComponent
                                color={SUBSCRIPTION_TAB_COLOR}
                                text='Subscription'
                                isActive={selectedTab === SUBSCRIPTION_TAB}
                                ClickAction={() => { SetSelectedTab(SUBSCRIPTION_TAB) }}
                            />
                        </UserAccountPageTabRowComponent>
                        <UserAccountPageContentAreaComponent color={GetTabColor()} >
                            {selectedTab === PROFILE_TAB &&
                                <UserProfileComponent
                                    showModal={instructionsModalVisibility}
                                    CloseModal={() => SetInstructionsModalVisibility(false)}
                                />
                            }
                            {selectedTab === SUBSCRIPTION_TAB &&
                                <SubscriptionComponent
                                    showModal={instructionsModalVisibility}
                                    CloseModal={() => SetInstructionsModalVisibility(false)}
                                />
                            }
                        </UserAccountPageContentAreaComponent>
                    </Container>
                    <Container className='user-account-page-control-container'>
                        <Container className='vstack user-account-page-control-icon-container'>
                            <Image fluid className='user-account-page-control-icon mt-5'
                                src={GetSiteImageUrl('question-square-fill-dark-blue.svg')}
                                onClick={() => { SetInstructionsModalVisibility(true) }} />
                        </Container>
                    </Container>
                </div>
            </Container>
            <Footer />
        </>
    )
}

function UserAccountPageTabRowComponent({ children }) {
    return <div className='user-account-page-tab-row'>{children}</div>
}

function UserAccountPageTabComponent({ color, text, isActive, ClickAction }) {
    const tabStyle = isActive ? {
        backgroundColor: `var(--${color})`,
        opacity: 1.0,
    } : {
        backgroundColor: `var(--${color})`,
        opacity: 0.5,
    }

    return (
        <div className='user-account-page-tab' style={tabStyle} onClick={() => ClickAction()}>
            {text}
        </div>
    )
}

UserAccountPageTabComponent.propTypes = {
    color: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    isActive: PropTypes.bool.isRequired,
    ClickAction: PropTypes.func.isRequired,
}

function UserAccountPageContentAreaComponent({ color, children }) {
    const setupAreaStyle = {
        borderColor: `var(--${color})`
    }

    return (
        <div className='user-account-page-content-container mx-auto mb-0' style={setupAreaStyle}>
            {children}
        </div>
    )
}

UserAccountPageContentAreaComponent.propTypes = {
    color: PropTypes.string.isRequired,
}
