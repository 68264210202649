import axios from 'axios'
import { encode } from 'base-64'
import {
    BACKEND_STATUS_WORKING,
    BACKEND_STATUS_SUCCESS,
    BACKEND_STATUS_FAILURE,
    HTTP_CODE_200_OK,
    HTTP_CODE_201_CREATED,
} from './BackendResources'
import { IsUserAdmin, GetUserAuthAccessToken } from '../state/UserAuthState'

export async function GetAdminBackendUserAccounts(callback) {
    if (!IsUserAdmin()) {
        return
    }

    let config = { headers: { 'Content-type': 'application/json', Authorization: `Bearer ${GetUserAuthAccessToken()}` } }
    let axiosInstance = axios.create()
    let response = null

    try {
        const { data } = await axiosInstance.get('/api/control/accounts/', config)
        response = data
    } catch (exception) {
        response = exception
    }

    callback(response)
}

export async function GetBackendUserAccount(username, callback) {
    let config = { headers: { 'Content-type': 'application/json' } }
    let axiosInstance = axios.create()
    let backendStatus = BACKEND_STATUS_WORKING
    let httpCode = ''
    let response = null

    try {
        const encodedUsername = encode(FormatEmailUsername(username))
        const { status, data } = await axiosInstance.get(`/api/account/${encodedUsername}/`, config)
        backendStatus = (status === HTTP_CODE_200_OK) ? BACKEND_STATUS_SUCCESS : BACKEND_STATUS_FAILURE
        httpCode = status
        response = data
    }
    catch (exception) {
        backendStatus = BACKEND_STATUS_FAILURE
        httpCode = exception['response']['status']
        response = exception
    }

    callback(backendStatus, httpCode, response)
}

export async function GetBackendTokenizedUserAccount({ userKey, token }, callback) {
    let config = { headers: { 'Content-type': 'application/json' } }
    let axiosInstance = axios.create()
    let backendStatus = BACKEND_STATUS_WORKING
    let httpCode = ''
    let response = null

    try {
        const { status, data } = await axiosInstance.get(`/api/account/${userKey}/${token}/`, config)
        backendStatus = (status === HTTP_CODE_200_OK) ? BACKEND_STATUS_SUCCESS : BACKEND_STATUS_FAILURE
        httpCode = status
        response = data
    }
    catch (exception) {
        backendStatus = BACKEND_STATUS_FAILURE
        httpCode = exception['response']['status']
        response = exception
    }

    callback(backendStatus, httpCode, response)
}

export async function CreateBackendUserAccount({ credentials }, callback) {
    let config = { headers: { 'Content-type': 'application/json' } }
    let axiosInstance = axios.create()
    let backendStatus = BACKEND_STATUS_WORKING
    let httpCode = ''
    let response = null

    try {
        const { status, data } = await axiosInstance.post(
            '/api/account/create/',
            {
                name: credentials['name'],
                username: FormatEmailUsername(credentials['username']),
                email: FormatEmailUsername(credentials['email']),
                password: credentials['password'],
            },
            config
        )

        backendStatus = (status === HTTP_CODE_200_OK || status === HTTP_CODE_201_CREATED) ? BACKEND_STATUS_SUCCESS : BACKEND_STATUS_FAILURE
        httpCode = status
        response = data
    }
    catch (exception) {
        backendStatus = BACKEND_STATUS_FAILURE
        httpCode = exception['response']['status']
        response = exception
    }

    callback(backendStatus, httpCode, response)
}

export async function UpdateBackendUserAccount({ username, fields }, callback) {
    let config = { headers: { 'Content-type': 'application/json' } }
    let axiosInstance = axios.create()
    let backendStatus = BACKEND_STATUS_WORKING
    let httpCode = ''
    let response = null

    try {
        const { status, data } = await axiosInstance.put(
            '/api/account/update/', { username: FormatEmailUsername(username), fields: fields }, config)

        backendStatus = (status === HTTP_CODE_200_OK) ? BACKEND_STATUS_SUCCESS : BACKEND_STATUS_FAILURE
        httpCode = status
        response = data
    }
    catch (exception) {
        backendStatus = BACKEND_STATUS_FAILURE
        httpCode = exception['response']['status']
        response = exception
    }

    callback(backendStatus, httpCode, response)
}

export async function ResetBackendUserAccountPassword({ username, password, token }, callback) {
    let config = { headers: { 'Content-type': 'application/json' } }
    let axiosInstance = axios.create()
    let backendStatus = BACKEND_STATUS_WORKING
    let httpCode = ''
    let response = null

    try {
        const { status, data } = await axiosInstance.put(
            `/api/account/reset-password/`, { username: FormatEmailUsername(username), password: password, token: token }, config)

        backendStatus = (status === HTTP_CODE_200_OK) ? BACKEND_STATUS_SUCCESS : BACKEND_STATUS_FAILURE
        httpCode = status
        response = data
    }
    catch (exception) {
        backendStatus = BACKEND_STATUS_FAILURE
        httpCode = exception['response']['status']
        response = exception
    }

    callback(backendStatus, httpCode, response)
}

export async function SendBackendUserAccountEmail({ username, subject, template, name, subscriptionType }, callback) {
    let config = { headers: { 'Content-type': 'application/json' } }
    let axiosInstance = axios.create()
    let backendStatus = BACKEND_STATUS_WORKING
    let httpCode = ''
    let response = null

    try {
        const { status, data } = await axiosInstance.post(
            '/api/account/email/',
            { username: FormatEmailUsername(username), subject: subject, template: template, name: name, subscriptionType: subscriptionType },
            config
        )

        backendStatus = (status === HTTP_CODE_200_OK || status === HTTP_CODE_201_CREATED) ? BACKEND_STATUS_SUCCESS : BACKEND_STATUS_FAILURE
        httpCode = status
        response = data
    }
    catch (exception) {
        backendStatus = BACKEND_STATUS_FAILURE
        httpCode = exception['response']['status']
        response = exception
    }

    callback(backendStatus, httpCode, response)
}

export async function RequestBackendUserLogin(credentials, callback) {
    let config = { headers: { 'Content-type': 'application/json' } }
    let axiosInstance = axios.create()
    let backendStatus = BACKEND_STATUS_WORKING
    let response = null

    try {
        const { data } = await axiosInstance.post(
            '/api/account/login/', { username: FormatEmailUsername(credentials['username']), password: credentials['password'] }, config)
        backendStatus = BACKEND_STATUS_SUCCESS
        response = data
    }
    catch (exception) {
        if (exception['response'] && exception['response']['data'] && exception['response']['data']['detail']) {
            response = exception['response']['data']['detail']
        }
        else {
            response = ''
        }

        backendStatus = BACKEND_STATUS_FAILURE
    }

    callback(backendStatus, response)
}

export async function RequestBackendUserTokenRefresh(refreshToken, callback) {
    if (!refreshToken) {
        return (BACKEND_STATUS_FAILURE, 'Invalid token')
    }

    let config = { headers: { 'Content-type': 'application/json' } }
    let axiosInstance = axios.create()
    let backendStatus = BACKEND_STATUS_WORKING
    let response = null

    try {
        const { data } = await axiosInstance.post('/api/account/refresh-tokens/', { refresh: refreshToken }, config)
        backendStatus = BACKEND_STATUS_SUCCESS
        response = data
    }
    catch (exception) {
        if (exception['response'] && exception['response']['data'] && exception['response']['data']['detail']) {
            response = exception['response']['data']['detail']
        }
        else {
            response = ''
        }

        backendStatus = BACKEND_STATUS_FAILURE
    }

    callback(backendStatus, response)
}

export async function GetBackendUserProfile(accessToken, callback) {
    let config = { headers: { 'Content-type': 'application/json', Authorization: `Bearer ${accessToken}` } }
    let axiosInstance = axios.create()
    let backendStatus = BACKEND_STATUS_WORKING
    let response = null

    try {
        const { data } = await axiosInstance.get('/api/profile/', config)
        backendStatus = BACKEND_STATUS_SUCCESS
        response = data
    }
    catch (exception) {
        if (exception['response'] && exception['response']['data'] && exception['response']['data']['detail']) {
            response = exception['response']['data']['detail']
        }
        else {
            response = ''
        }

        backendStatus = BACKEND_STATUS_FAILURE
    }

    callback(backendStatus, response)
}

export async function UpdateBackendUserProfile({ accessToken, fields }, callback) {
    let config = { headers: { 'Content-type': 'application/json', Authorization: `Bearer ${accessToken}` } }
    let axiosInstance = axios.create()
    let backendStatus = BACKEND_STATUS_WORKING
    var httpCode = ''
    let response = null

    try {
        const { status, data } = await axiosInstance.put('/api/profile/update/', { fields: fields }, config)
        backendStatus = (status === HTTP_CODE_200_OK) ? BACKEND_STATUS_SUCCESS : BACKEND_STATUS_FAILURE
        httpCode = status
        response = data
    }
    catch (exception) {
        backendStatus = BACKEND_STATUS_FAILURE
        httpCode = exception['response']['status']
        response = exception
    }

    callback(backendStatus, httpCode, response)
}

function FormatEmailUsername(emailUsername) {
    return emailUsername.trim().toLowerCase()
}
