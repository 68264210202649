import { useState, useEffect } from 'react'
import { createReducer, createAction } from '@reduxjs/toolkit'
import PropTypes from 'prop-types'
import {
    GetReducerState,
    QUICK_SEARCH_REDUCER,
    DispatchAction,
    SubscribeActionListener,
    GetStringifiedState,
    DEFAULT_STRINGIFIED_STATE,
} from '../state/ReduxStore'
import { AreTargetSoundAndPositionValid, WORD_SET_CORE } from '../project/SoundPositionLevelChoices'
import { SearchSoundLevelPosition } from '../project/WordSearch'

// -------------------------------------------------------------------------------------------------
//  Internal state management
// -------------------------------------------------------------------------------------------------

const SELECTED_TARGET_SOUND = 'QuickSearchState/selectedTargetSound'
const SELECTED_POSITIONS = 'QuickSearchState/selectedPositions'
const SELECTED_LEVELS = 'QuickSearchState/selectedLevels'
const SELECTED_WORD_SET = 'QuickSearchState/selectedWordSet'
const SEARCH_RESULTS = 'QuickSearchState/searchResults'
const SELECTED_WORDS = 'QuickSearchState/selectedWords'

const SELECTED_TARGET_SOUND_NONE = ''

const defaultState = {
    [SELECTED_TARGET_SOUND]: SELECTED_TARGET_SOUND_NONE,
    [SELECTED_POSITIONS]: [],
    [SELECTED_LEVELS]: [],
    [SELECTED_WORD_SET]: WORD_SET_CORE,
    [SEARCH_RESULTS]: [],
    [SELECTED_WORDS]: [],
}

const STORED_STATE_KEY = 'LevelUpArtic/quickSearchState'

const StoreQuickSearchState = (state) => {
    sessionStorage.setItem(STORED_STATE_KEY, JSON.stringify(state))
}

const GetSelectedTargetSound = () => {
    return GetReducerState(QUICK_SEARCH_REDUCER)[SELECTED_TARGET_SOUND]
}

const GetSelectedPositions = () => {
    return GetReducerState(QUICK_SEARCH_REDUCER)[SELECTED_POSITIONS]
}

const GetSelectedLevels = () => {
    return GetReducerState(QUICK_SEARCH_REDUCER)[SELECTED_LEVELS]
}

const GetSelectedWordSet = () => {
    return GetReducerState(QUICK_SEARCH_REDUCER)[SELECTED_WORD_SET]
}

const GetSearchResults = () => {
    return GetReducerState(QUICK_SEARCH_REDUCER)[SEARCH_RESULTS]
}

const GetSelectedWords = () => {
    return GetReducerState(QUICK_SEARCH_REDUCER)[SELECTED_WORDS]
}

// -------------------------------------------------------------------------------------------------
//  External state access
// -------------------------------------------------------------------------------------------------

export const GetInitialQuickSearchState = () => {
    const storedState = sessionStorage.getItem(STORED_STATE_KEY)
    return storedState ? JSON.parse(storedState) : defaultState
}

export function QuickSearchStateChangeListener({ callback }) {
    const [stringifiedState, SetStringifiedState] = useState(DEFAULT_STRINGIFIED_STATE)

    useEffect(() => {
        callback(stringifiedState)
    }, [stringifiedState, callback])

    const HandleActionNotification = () => {
        SetStringifiedState(GetStringifiedState(QUICK_SEARCH_REDUCER))
    }

    useEffect(() => {
        SubscribeActionListener(HandleActionNotification)
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    return <></>
}

QuickSearchStateChangeListener.propTypes = {
    callback: PropTypes.func.isRequired,
}

export const GetQuickSearchSelectedTargetSound = () => {
    return GetSelectedTargetSound()
}

export const IsQuickSearchTargetSoundSelected = () => {
    return GetSelectedTargetSound() !== SELECTED_TARGET_SOUND_NONE
}

export const GetQuickSearchSelectedPositions = () => {
    return GetSelectedPositions()
}

export const AreAnyQuickSearchPositionsSelected = () => {
    return GetSelectedPositions().length > 0
}

export const GetQuickSearchSelectedLevels = () => {
    return GetSelectedLevels()
}

export const AreAnyQuickSearchLevelsSelected = () => {
    return GetSelectedLevels().length > 0
}

export const GetQuickSearchSelectedWordSet = () => {
    return GetSelectedWordSet()
}

export const GetQuickSearchResults = () => {
    return GetSearchResults()
}

export const AreQuickSearchResultsEmpty = () => {
    return GetSearchResults().length === 0
}

export const GetQuickSearchSelectedWords = () => {
    return GetSelectedWords()
}

// -------------------------------------------------------------------------------------------------
//  Internal action management
// -------------------------------------------------------------------------------------------------

const SELECT_TARGET_SOUND = 'QuickSearchAction/selectTargetSound'
const SELECT_POSITION = 'QuickSearchAction/selectPosition'
const DESELECT_POSITION = 'QuickSearchAction/deelectPosition'
const SELECT_LEVEL = 'QuickSearchAction/selectLevel'
const DESELECT_LEVEL = 'QuickSearchAction/deselectLevel'
const SELECT_WORD_SET = 'QuickSearchAction/selectWordSet'
const RUN_SEARCH = 'QuickSearchAction/runSearch'
const CLEAR_CONFIG = 'QuickSearchAction/clearConfig'
const SELECT_WORD = 'QuickSearchAction/selectWord'
const DESELECT_WORD = 'QuickSearchAction/deselectWord'
const SELECT_ALL_WORDS = 'QuickSearchAction/selectAllWords'
const DESELECT_ALL_WORDS = 'QuickSearchAction/deselectAllWords'

const selectTargetSoundAction = createAction(SELECT_TARGET_SOUND)
const selectPositionAction = createAction(SELECT_POSITION)
const deselectPositionAction = createAction(DESELECT_POSITION)
const selectLevelAction = createAction(SELECT_LEVEL)
const deselectLevelAction = createAction(DESELECT_LEVEL)
const selectWordSetAction = createAction(SELECT_WORD_SET)
const runSearchAction = createAction(RUN_SEARCH)
const clearConfigAction = createAction(CLEAR_CONFIG)
const selectWordAction = createAction(SELECT_WORD)
const deselectWordAction = createAction(DESELECT_WORD)
const selectAllWordsAction = createAction(SELECT_ALL_WORDS)
const deselectAllWordsAction = createAction(DESELECT_ALL_WORDS)

// -------------------------------------------------------------------------------------------------
//  External action access
// -------------------------------------------------------------------------------------------------

export const SelectQuickSearchTargetSound = (targetSound) => {
    DispatchAction(selectTargetSoundAction, targetSound)
}

export const SelectQuickSearchPosition = (position) => {
    DispatchAction(selectPositionAction, position)
}

export const DeselectQuickSearchPosition = (position) => {
    DispatchAction(deselectPositionAction, position)
}

export const SelectQuickSearchLevel = (level) => {
    DispatchAction(selectLevelAction, level)
}

export const DeselectQuickSearchLevel = (level) => {
    DispatchAction(deselectLevelAction, level)
}

export const SelectQuickSearchWordSet = (wordSet) => {
    DispatchAction(selectWordSetAction, wordSet)
}

export const RunQuickSearch = () => {
    DispatchAction(runSearchAction)
}

export const ClearQuickSearchConfig = () => {
    DispatchAction(clearConfigAction)
}

export const SelectQuickSearchWord = (wordKey) => {
    DispatchAction(selectWordAction, wordKey)
}

export const DeselectQuickSearchWord = (wordKey) => {
    DispatchAction(deselectWordAction, wordKey)
}

export const SelectAllQuickSearchWords = () => {
    DispatchAction(selectAllWordsAction)
}

export const DeselectAllQuickSearchWords = () => {
    DispatchAction(deselectAllWordsAction)
}

// -------------------------------------------------------------------------------------------------
//  Internal reducer management
// -------------------------------------------------------------------------------------------------

const HandleSelectTargetSoundReq = (state, action) => {
    const selectedTargetSound = action.payload

    if (selectedTargetSound && selectedTargetSound !== state[SELECTED_TARGET_SOUND]) {
        state[SELECTED_TARGET_SOUND] = selectedTargetSound
        state[SELECTED_POSITIONS] = []
        state[SELECTED_LEVELS] = []
        StoreQuickSearchState(state)
    }
}

const HandleSelectPositionReq = (state, action) => {
    const selectedPosition = action.payload
    var newSelectedPositions = [...state[SELECTED_POSITIONS]]

    if (selectedPosition && AreTargetSoundAndPositionValid(state[SELECTED_TARGET_SOUND], selectedPosition)) {
        if (!newSelectedPositions.includes(selectedPosition)) {
            newSelectedPositions.push(selectedPosition)
            state[SELECTED_POSITIONS] = [...newSelectedPositions]
            StoreQuickSearchState(state)
        }
    }
}

const HandleDeselectPositionReq = (state, action) => {
    const deselectedPosition = action.payload
    var newSelectedPositions = [...state[SELECTED_POSITIONS]]

    if (deselectedPosition && AreTargetSoundAndPositionValid(state[SELECTED_TARGET_SOUND], deselectedPosition)) {
        if (newSelectedPositions.includes(deselectedPosition)) {
            const index = newSelectedPositions.indexOf(deselectedPosition)

            if (index !== -1) {
                newSelectedPositions.splice(index, 1)
            }

            state[SELECTED_POSITIONS] = [...newSelectedPositions]

            if (newSelectedPositions.length === 0) {
                state[SELECTED_LEVELS] = []
            }

            StoreQuickSearchState(state)
        }
    }
}

const HandleSelectLevelReq = (state, action) => {
    const selectedLevel = action.payload
    var newSelectedLevels = [...state[SELECTED_LEVELS]]

    if (selectedLevel && !newSelectedLevels.includes(selectedLevel)) {
        newSelectedLevels.push(selectedLevel)
        state[SELECTED_LEVELS] = [...newSelectedLevels]
        StoreQuickSearchState(state)
    }
}

const HandleDeselectLevelReq = (state, action) => {
    const deselectedLevel = action.payload
    var newSelectedLevels = [...state[SELECTED_LEVELS]]

    if (deselectedLevel && newSelectedLevels.includes(deselectedLevel)) {
        const index = newSelectedLevels.indexOf(deselectedLevel)

        if (index !== -1) {
            newSelectedLevels.splice(index, 1)
        }

        state[SELECTED_LEVELS] = [...newSelectedLevels]
        StoreQuickSearchState(state)
    }
}

const HandleSelectWordSetReq = (state, action) => {
    const selectedWordSet = action.payload

    if (selectedWordSet && selectedWordSet !== state[SELECTED_WORD_SET]) {
        state[SELECTED_WORD_SET] = selectedWordSet
        StoreQuickSearchState(state)
    }
}

const HandleRunSearchReq = (state) => {
    const prevSearchResults = [...state[SEARCH_RESULTS]]
    const prevSelectedWords = [...state[SELECTED_WORDS]]

    const newSearchResults = SearchSoundLevelPosition({
        sound: state[SELECTED_TARGET_SOUND], levels: state[SELECTED_LEVELS],
        positions: state[SELECTED_POSITIONS], set: state[SELECTED_WORD_SET]
    })

    let newSelectedWords = []

    for (let word of newSearchResults) {
        if (prevSelectedWords.includes(word) || !prevSearchResults.includes(word)) {
            newSelectedWords.push(word)
        }
    }

    state[SEARCH_RESULTS] = newSearchResults
    state[SELECTED_WORDS] = newSelectedWords
    StoreQuickSearchState(state)
}

const HandleClearConfigReq = (state) => {
    state[SELECTED_TARGET_SOUND] = SELECTED_TARGET_SOUND_NONE
    state[SELECTED_POSITIONS] = []
    state[SELECTED_LEVELS] = []
    state[SELECTED_WORD_SET] = WORD_SET_CORE
    state[SEARCH_RESULTS] = []
    state[SELECTED_WORDS] = []
    StoreQuickSearchState(state)
}

const HandleSelectWordReq = (state, action) => {
    const selectedWord = action.payload
    var newSelectedWords = [...state[SELECTED_WORDS]]

    if (selectedWord && !newSelectedWords.includes(selectedWord)) {
        newSelectedWords.push(selectedWord)
        state[SELECTED_WORDS] = [...newSelectedWords]
        StoreQuickSearchState(state)
    }
}

const HandleDeselectWordReq = (state, action) => {
    const deselectedWord = action.payload
    var newSelectedWords = [...state[SELECTED_WORDS]]

    if (deselectedWord && newSelectedWords.includes(deselectedWord)) {
        const index = newSelectedWords.indexOf(deselectedWord)

        if (index !== -1) {
            newSelectedWords.splice(index, 1)
        }

        state[SELECTED_WORDS] = [...newSelectedWords]
        StoreQuickSearchState(state)
    }
}

const HandleSelectAllWordsReq = (state) => {
    state[SELECTED_WORDS] = [...state[SEARCH_RESULTS]]
    StoreQuickSearchState(state)
}

const HandleDeselectAllWordsReq = (state) => {
    state[SELECTED_WORDS] = []
    StoreQuickSearchState(state)
}

const quickSearchReducer = createReducer(defaultState, (builder) => {
    builder.addCase(selectTargetSoundAction, (state, action) => {
        HandleSelectTargetSoundReq(state, action)
    })

    builder.addCase(selectPositionAction, (state, action) => {
        HandleSelectPositionReq(state, action)
    })

    builder.addCase(deselectPositionAction, (state, action) => {
        HandleDeselectPositionReq(state, action)
    })

    builder.addCase(selectLevelAction, (state, action) => {
        HandleSelectLevelReq(state, action)
    })

    builder.addCase(deselectLevelAction, (state, action) => {
        HandleDeselectLevelReq(state, action)
    })

    builder.addCase(selectWordSetAction, (state, action) => {
        HandleSelectWordSetReq(state, action)
    })

    builder.addCase(runSearchAction, (state, action) => {
        HandleRunSearchReq(state, action)
    })

    builder.addCase(clearConfigAction, (state, action) => {
        HandleClearConfigReq(state, action)
    })

    builder.addCase(selectWordAction, (state, action) => {
        HandleSelectWordReq(state, action)
    })

    builder.addCase(deselectWordAction, (state, action) => {
        HandleDeselectWordReq(state, action)
    })

    builder.addCase(selectAllWordsAction, (state, action) => {
        HandleSelectAllWordsReq(state, action)
    })

    builder.addCase(deselectAllWordsAction, (state, action) => {
        HandleDeselectAllWordsReq(state, action)
    })

    builder.addDefaultCase(() => { })
})

// -------------------------------------------------------------------------------------------------
//  External reducer access
// -------------------------------------------------------------------------------------------------

export const GetQuickSearchReducer = () => {
    return quickSearchReducer
}
