import { Form } from 'react-bootstrap'
import PropTypes from 'prop-types'
import './SelectAllCheckboxComponent.css'

export function SelectAllCheckboxComponent({ allWords, selectedWords, SelectAllWords, DeselectAllWords }) {
    const isChecked = DoSelectedWordsMatchAllWords(allWords, selectedWords)
    var selectAllCheckboxInputClass = 'select-all-checkbox-input'

    if (!isChecked) {
        selectAllCheckboxInputClass += selectedWords.length === 0 ? ' select-all-checkbox-input-matching' : ' select-all-checkbox-input-not-matching'
    }

    const HandleCheckboxChange = (event) => {
        if (event.target.checked) {
            SelectAllWords()
        } else {
            DeselectAllWords()
        }
    }

    return (
        <Form.Check type='checkbox' className='select-all-checkbox'>
            <Form.Check.Input
                type='checkbox'
                className={selectAllCheckboxInputClass}
                checked={isChecked}
                onChange={(event) => {
                    HandleCheckboxChange(event)
                }}></Form.Check.Input>
            <Form.Check.Label className='select-all-checkbox-label'>Select/Deselect All</Form.Check.Label>
        </Form.Check>
    )
}

function DoSelectedWordsMatchAllWords(allWords, selectedWords) {
    if (selectedWords.length !== allWords.length) {
        return false
    }

    for (const word of selectedWords) {
        if (!allWords.includes(word)) {
            return false
        }
    }

    return true
}

SelectAllCheckboxComponent.propTypes = {
    allWords: PropTypes.arrayOf(PropTypes.string).isRequired,
    selectedWords: PropTypes.arrayOf(PropTypes.string).isRequired,
    SelectAllWords: PropTypes.func.isRequired,
    DeselectAllWords: PropTypes.func.isRequired,
}
