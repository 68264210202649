import { useState, useEffect } from 'react'
import { createReducer, createAction } from '@reduxjs/toolkit'
import PropTypes from 'prop-types'
import {
    DispatchAction,
    GetReducerState,
    USER_PROFILE_REDUCER,
    SubscribeActionListener,
    GetStringifiedState,
    DEFAULT_STRINGIFIED_STATE,
} from './ReduxStore'
import { GetBackendUserProfile, UpdateBackendUserProfile } from '../project/BackendUserOperations'
import { BACKEND_STATUS_SUCCESS, BACKEND_STATUS_FAILURE } from '../project/BackendResources'

// -------------------------------------------------------------------------------------------------
//  Internal state management
// -------------------------------------------------------------------------------------------------

const PROFILE_STATUS = 'UserProfileState/ProfileStatus'
const PROFILE_INFO = 'UserProfileState/ProfileInfo'
const PROFILE_FAILURE = 'UserProfileState/ProfileFailure'
const PROFILE_OPERATION = 'UserProfileState/ProfileOperation'

const PROFILE_STATUS_INVALID = 'UserProfileState/ProfileStatus/Invalid'
const PROFILE_STATUS_VALID = 'UserProfileState/ProfileStatus/Valid'

const PROFILE_INFO_INVALID = ''

const PROFILE_FAILURE_NONE = 'UserProfileState/ProfileFailure/None'
const PROFILE_FAILURE_UNDIAGNOSED = 'UserProfileState/ProfileFailure/Undiagnosed'

const PROFILE_OPERATION_NONE = 'UserProfileState/Operation/None'
const PROFILE_OPERATION_RETRIEVE_PROFILE = 'UserProfileState/Operation/RetrieveProfile'
const PROFILE_OPERATION_UPDATE_PROFILE = 'UserProfileState/Operation/UpdateProfile'

const defaultState = {
    [PROFILE_STATUS]: PROFILE_STATUS_INVALID,
    [PROFILE_INFO]: PROFILE_INFO_INVALID,
    [PROFILE_FAILURE]: PROFILE_FAILURE_NONE,
    [PROFILE_OPERATION]: PROFILE_OPERATION_NONE,
}

const GetProfileStatus = () => {
    return GetReducerState(USER_PROFILE_REDUCER)[PROFILE_STATUS]
}

const GetProfileInfo = () => {
    return GetReducerState(USER_PROFILE_REDUCER)[PROFILE_INFO]
}

const GetProfileFailure = () => {
    return GetReducerState(USER_PROFILE_REDUCER)[PROFILE_FAILURE]
}

const GetProfileOperation = () => {
    return GetReducerState(USER_PROFILE_REDUCER)[PROFILE_OPERATION]
}

// -------------------------------------------------------------------------------------------------
//  External state access
// -------------------------------------------------------------------------------------------------

export const USER_PROFILE_FAILURE_NONE = PROFILE_FAILURE_NONE
export const USER_PROFILE_FAILURE_UNDIAGNOSED = PROFILE_FAILURE_UNDIAGNOSED

export const USER_PROFILE_OPERATION_NONE = PROFILE_OPERATION_NONE

export const GetInitialUserProfileState = () => {
    return defaultState
}

export function UserProfileStateChangeListener({ callback }) {
    const [stringifiedState, SetStringifiedState] = useState(DEFAULT_STRINGIFIED_STATE)

    useEffect(() => {
        callback(stringifiedState)
    }, [stringifiedState, callback])

    const HandleActionNotification = () => {
        SetStringifiedState(GetStringifiedState(USER_PROFILE_REDUCER))
    }

    useEffect(() => {
        SubscribeActionListener(HandleActionNotification)
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    return <></>
}

UserProfileStateChangeListener.propTypes = {
    callback: PropTypes.func.isRequired,
}

export const IsUserProfileValid = () => {
    return GetProfileStatus() === PROFILE_STATUS_VALID
}

export const GetUserProfileInfo = () => {
    return GetProfileInfo()
}

export const GetUserProfileFailure = () => {
    return GetProfileFailure()
}

export const GetUserProfileOperation = () => {
    return GetProfileOperation()
}

// -------------------------------------------------------------------------------------------------
//  Internal action management
// -------------------------------------------------------------------------------------------------

const RETRIEVE_PROFILE_REQUEST = 'UserProfileAction/RetrieveProfileRequest'
const RETRIEVE_PROFILE_SUCCESS = 'UserProfileAction/RetrieveProfileSuccess'
const RETRIEVE_PROFILE_FAILURE = 'UserProfileAction/RetrieveProfileFailure'
const UPDATE_PROFILE_REQUEST = 'UserProfileAction/UpdateProfileRequest'
const UPDATE_PROFILE_SUCCESS = 'UserProfileAction/UpdateProfileSuccess'
const UPDATE_PROFILE_FAILURE = 'UserProfileAction/UpdateProfileFailure'

const retrieveProfileRequestAction = createAction(RETRIEVE_PROFILE_REQUEST)
const retrieveProfileSuccessAction = createAction(RETRIEVE_PROFILE_SUCCESS)
const retrieveProfileFailureAction = createAction(RETRIEVE_PROFILE_FAILURE)
const updateProfileRequestAction = createAction(UPDATE_PROFILE_REQUEST)
const updateProfileSuccessAction = createAction(UPDATE_PROFILE_SUCCESS)
const updateProfileFailureAction = createAction(UPDATE_PROFILE_FAILURE)

// -------------------------------------------------------------------------------------------------
//  External action access
// -------------------------------------------------------------------------------------------------

export const RetrieveUserProfile = (accessToken) => {
    DispatchAction(retrieveProfileRequestAction, accessToken)
}

export const UpdateUserProfile = (accessToken, fields) => {
    DispatchAction(updateProfileRequestAction, { accessToken: accessToken, fields: fields })
}

// -------------------------------------------------------------------------------------------------
//  Internal reducer management
// -------------------------------------------------------------------------------------------------

const UpdateProfileState = (state, status, info, failure, operation) => {
    state[PROFILE_STATUS] = status
    state[PROFILE_INFO] = info
    state[PROFILE_FAILURE] = failure
    state[PROFILE_OPERATION] = operation
}

const HandleRetrieveProfileRequest = (state, action) => {
    UpdateProfileState(state, PROFILE_STATUS_INVALID, PROFILE_INFO_INVALID, PROFILE_FAILURE_NONE, PROFILE_OPERATION_RETRIEVE_PROFILE)
    GetBackendUserProfile(action.payload, HandleBackendGetUserProfileResponse)
}

const HandleBackendGetUserProfileResponse = (status, response) => {
    if (status === BACKEND_STATUS_SUCCESS) {
        DispatchAction(retrieveProfileSuccessAction, response)
    }
    else if (status === BACKEND_STATUS_FAILURE) {
        DispatchAction(retrieveProfileFailureAction, response)
    }
}

const HandleRetrieveProfileSuccess = (state, action) => {
    UpdateProfileState(state, PROFILE_STATUS_VALID, action.payload, PROFILE_FAILURE_NONE, PROFILE_OPERATION_NONE)
}

const HandleRetrieveProfileFailure = (state) => {
    // TODO: Use action.payload to set the PROFILE_FAILURE state. Just assume an undiagnosed error for now.
    UpdateProfileState(state, PROFILE_STATUS_INVALID, PROFILE_INFO_INVALID, PROFILE_FAILURE_UNDIAGNOSED, PROFILE_OPERATION_NONE)
}

const HandleUpdateProfileRequest = (state, action) => {
    UpdateProfileState(state, PROFILE_STATUS_INVALID, PROFILE_INFO_INVALID, PROFILE_FAILURE_NONE, PROFILE_OPERATION_UPDATE_PROFILE)
    UpdateBackendUserProfile(action.payload, HandleBackendUpdateProfileResponse)
}

const HandleBackendUpdateProfileResponse = (backendStatus, httpCode, response) => {
    if (backendStatus === BACKEND_STATUS_SUCCESS) {
        DispatchAction(updateProfileSuccessAction, response)
    }
    else if (backendStatus === BACKEND_STATUS_FAILURE) {
        DispatchAction(updateProfileFailureAction, httpCode)
    }
}

const HandleUpdateProfileSuccess = (state, action) => {
    UpdateProfileState(state, PROFILE_STATUS_VALID, action.payload, PROFILE_FAILURE_NONE, PROFILE_OPERATION_NONE)
}

const HandleUpdateProfileFailure = (state) => {
    // TODO: Use action.payload to set the PROFILE_FAILURE state. Just assume an undiagnosed error for now.
    UpdateProfileState(state, PROFILE_STATUS_INVALID, PROFILE_INFO_INVALID, PROFILE_FAILURE_UNDIAGNOSED, PROFILE_OPERATION_NONE)
}

const userProfileReducer = createReducer(defaultState, (builder) => {
    builder.addCase(retrieveProfileRequestAction, (state, action) => {
        HandleRetrieveProfileRequest(state, action)
    })

    builder.addCase(retrieveProfileSuccessAction, (state, action) => {
        HandleRetrieveProfileSuccess(state, action)
    })

    builder.addCase(retrieveProfileFailureAction, (state, action) => {
        HandleRetrieveProfileFailure(state, action)
    })

    builder.addCase(updateProfileRequestAction, (state, action) => {
        HandleUpdateProfileRequest(state, action)
    })

    builder.addCase(updateProfileSuccessAction, (state, action) => {
        HandleUpdateProfileSuccess(state, action)
    })

    builder.addCase(updateProfileFailureAction, (state, action) => {
        HandleUpdateProfileFailure(state, action)
    })

    builder.addDefaultCase(() => { })
})

// -------------------------------------------------------------------------------------------------
//  External reducer access
// -------------------------------------------------------------------------------------------------

export const GetUserProfileReducer = () => {
    return userProfileReducer
}
