import { useState, useEffect, useContext } from 'react'
import { Container, Image } from 'react-bootstrap'
import { useLocation, useSearchParams } from 'react-router-dom'
import PropTypes from 'prop-types'
import { Header } from '../components/Header'
import { Footer } from '../components/Footer'
import { LoginComponent, SignupComponent } from '../components/LoginSignupComponents'
import { SUBSCRIPTION_TYPE_YEAR } from '../components/SubscriptionComponents'
import { LoginSignupContext, LoginSignupProvider } from '../context/LoginSignupContext'
import { DisableScrollbar } from '../project/Display'
import { GetSiteImageUrl } from '../project/ProjectResources'
import '../style/LoginSignupPageStyle.css'

export function LoginSignupPage() {
    return (
        <LoginSignupProvider>
            <LoginSignupPageContent />
        </LoginSignupProvider>
    )
}

function LoginSignupPageContent() {
    const { pageFreeze, SetActivationMessageVisibility, SetInstructionsModalVisibility, SetInitialSubscriptionType } = useContext(LoginSignupContext)

    useEffect(() => {
        DisableScrollbar()
    }, [])

    const { state } = useLocation()
    const [searchParams, SetSearchParams] = useSearchParams()

    const LOGIN_TAB = 'LoginTab'
    const SIGNUP_TAB = 'SignupTab'

    const [selectedTab, SetSelectedTab] = useState(LOGIN_TAB)

    useEffect(() => {
        if (state) {
            if (state['tab'] && state['tab'] === 'signup') {
                SetSelectedTab(SIGNUP_TAB)
            }

            if (state['subscription'] && state['subscription'] === 'year') {
                SetInitialSubscriptionType(SUBSCRIPTION_TYPE_YEAR)
            }
        }

        const pathParam = searchParams.get('path')

        if (pathParam && pathParam === 'activation') {
            SetActivationMessageVisibility(true)
        }

        SetSearchParams({})
    }, [])  // eslint-disable-line react-hooks/exhaustive-deps

    const LOGIN_TAB_COLOR = 'dark-orange'
    const SIGNUP_TAB_COLOR = 'dark-aqua'

    const GetTabColor = () => {
        if (selectedTab === SIGNUP_TAB) {
            return SIGNUP_TAB_COLOR
        }

        return LOGIN_TAB_COLOR
    }

    return (
        <>
            <Header />
            <Container className='section-container bg-light'>
                <div className='login-signup-page-div'>
                    <Container className='section-container bg-light w-100 pt-4 pb-4'>
                        <LoginSignupPageTabRowComponent>
                            <LoginSignupPageTabComponent
                                color={LOGIN_TAB_COLOR}
                                text='Login'
                                isActive={selectedTab === LOGIN_TAB}
                                ClickAction={() => { !pageFreeze && SetSelectedTab(LOGIN_TAB) }}
                            />
                            <LoginSignupPageTabComponent
                                color={SIGNUP_TAB_COLOR}
                                text='Signup'
                                isActive={selectedTab === SIGNUP_TAB}
                                ClickAction={() => { !pageFreeze && SetSelectedTab(SIGNUP_TAB) }}
                            />
                        </LoginSignupPageTabRowComponent>
                        <LoginSignupPageContentAreaComponent color={GetTabColor()} >
                            {selectedTab === LOGIN_TAB &&
                                <LoginComponent />
                            }
                            {selectedTab === SIGNUP_TAB &&
                                <SignupComponent />
                            }
                        </LoginSignupPageContentAreaComponent>
                    </Container>
                    <Container className='login-signup-page-control-container'>
                        <Container className='vstack login-signup-page-control-icon-container'>
                            <Image fluid className='login-signup-page-control-icon mt-5'
                                src={GetSiteImageUrl('question-square-fill-dark-blue.svg')}
                                onClick={() => { SetInstructionsModalVisibility(true) }} />
                        </Container>
                    </Container>
                </div>
            </Container>
            <Footer />
        </>
    )
}

function LoginSignupPageTabRowComponent({ children }) {
    return <div className='login-signup-page-tab-row'>{children}</div>
}

function LoginSignupPageTabComponent({ color, text, isActive, ClickAction }) {
    const tabStyle = isActive ? {
        backgroundColor: `var(--${color})`,
        opacity: 1.0,
    } : {
        backgroundColor: `var(--${color})`,
        opacity: 0.5,
    }

    return (
        <div className='login-signup-page-tab' style={tabStyle} onClick={() => ClickAction()}>
            {text}
        </div>
    )
}

LoginSignupPageTabComponent.propTypes = {
    color: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    isActive: PropTypes.bool.isRequired,
    ClickAction: PropTypes.func.isRequired,
}

function LoginSignupPageContentAreaComponent({ color, children }) {
    const setupAreaStyle = {
        borderColor: `var(--${color})`
    }

    return (
        <div className='login-signup-page-content-container mx-auto mb-0' style={setupAreaStyle}>
            {children}
        </div>
    )
}

LoginSignupPageContentAreaComponent.propTypes = {
    color: PropTypes.string.isRequired,
}
