import { useState, useEffect, createContext, useContext } from 'react'
import { PICK_A_PATH_NODE_START } from '../project/PickAPathResources'

export const PickAPathContext = createContext()

export const PickAPathProvider = ({ children }) => {
    const [hasVocabBeenShown, SetHasVocabBeenShown] = useState(false)
    const [instructionsModalVisibility, SetInstructionsModalVisibility] = useState(false)
    const [story, SetStory] = useState('')
    const [currentNode, SetCurrentNode] = useState(PICK_A_PATH_NODE_START)
    const [previousNodes, SetPreviousNodes] = useState([])

    return (
        <PickAPathContext.Provider
            value={{
                hasVocabBeenShown, SetHasVocabBeenShown,
                instructionsModalVisibility, SetInstructionsModalVisibility,
                story, SetStory,
                currentNode, SetCurrentNode,
                previousNodes, SetPreviousNodes,
            }}
        >
            {children}
        </PickAPathContext.Provider>
    )
}

export function PickAPathListener({ SendContextChangeNotification }) {
    const context = useContext(PickAPathContext)
    const [stringifiedContext, SetStringifiedContext] = useState('')

    useEffect(() => {
        SendContextChangeNotification(stringifiedContext)
    }, [stringifiedContext, SendContextChangeNotification])

    useEffect(() => {
        SetStringifiedContext(JSON.stringify(context))
    }, [context])

    return <></>
}
