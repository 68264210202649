import { useState, useEffect, useContext } from 'react'
import { Container, Row, Col, Form, ButtonGroup, Modal } from 'react-bootstrap'
import PropTypes from 'prop-types'
import { GetTargetSoundChoices, GetPositionChoices, GetValidPositionChoices, GetLevelChoices } from '../project/SoundPositionLevelChoices'
import { SearchSoundLevelPosition, SearchTextMatch } from '../project/WordSearch'
import { AddWordToList, AddWordsToList, RemoveWordFromList, RemoveWordsFromList, SortWords } from '../project/Utils'
import { ListOperationActionAreaComponent, ListOperationWordsAreaComponent } from './ListOperationComponents'
import { SearchParamSelectComponent } from './SearchParamComponents'
import { ActionButtonComponent } from './ActionButtonComponents'
import { WordCardComponent } from './WordCardComponent'
import { WordCheckboxComponent } from './WordCheckboxComponent'
import { SelectAllCheckboxComponent } from './SelectAllCheckboxComponent'
import { ListEditContext } from '../context/ListEditContext'
import './ListOperationComponents.css'

const ADD_WORDS_SEARCH_OPTION_SLP = 'AddWords/SearchOption/SLP'
const ADD_WORDS_SEARCH_OPTION_TEXT_MATCH = 'AddWords/SearchOption/TextMatch'

export function ListOperationAddWordsComponent({ tabRowContent, controlAreaContent, UpdateListWords, actionAreaProps, modalProps }) {
    const { listName, listWords, ClearSearchParams, HandleExit } = useContext(ListEditContext)

    const [candidateWords, SetCandidateWords] = useState([])
    const [wordsToAdd, SetWordsToAdd] = useState([])
    const [searchOption, SetSearchOption] = useState(ADD_WORDS_SEARCH_OPTION_SLP)

    useEffect(() => {
        SetCandidateWords([])
        SetWordsToAdd([])
    }, [searchOption])

    const ProcessSearchResults = (searchResults) => {
        let newWordsToAdd = []

        for (let word of wordsToAdd) {
            if (searchResults.includes(word)) {
                newWordsToAdd = AddWordToList(newWordsToAdd, word)
            }
        }

        SetWordsToAdd(newWordsToAdd)

        let newCandidateWords = []

        for (let word of searchResults) {
            if (!listWords.includes(word)) {
                newCandidateWords.push(word)
            }
        }

        newCandidateWords = SortWords(newCandidateWords)
        SetCandidateWords(newCandidateWords)
    }

    const actionAreaContent =
        <ListOperationActionAreaComponent props={actionAreaProps} >
            <div className='d-flex justify-content-start align-items-center w-100 mb-4'>
                <div className='list-operation-add-words-container vstack mt-1 mx-auto'>
                    <h4 className='text-center mx-auto mt-0 mb-3'>{listName}</h4>
                    <AddWordsSearchOptionComponent
                        searchOption={searchOption}
                        UpdateSearchOption={option => SetSearchOption(option)}
                    />
                    <div className='ms-auto ms-lg-3 mt-0'>
                        <AddWordsSearchComponent
                            searchOption={searchOption}
                            UpdateSearchResults={searchResults => ProcessSearchResults(searchResults)}
                        />
                    </div>
                    <div className='d-flex justify-content-center mx-auto my-0'>
                        <ButtonGroup className='flex-wrap mx-auto'>
                            <ActionButtonComponent
                                text='Update Words'
                                width='10rem'
                                margins='1rem 0.5rem 0 1rem'
                                isActive={wordsToAdd.length > 0}
                                DoAction={() => {
                                    const newListWords = SortWords(AddWordsToList(listWords, wordsToAdd))
                                    const newCandidateWords = RemoveWordsFromList(candidateWords, wordsToAdd)
                                    SetCandidateWords(newCandidateWords)
                                    UpdateListWords(newListWords)
                                }}
                            />
                            <ActionButtonComponent
                                text='Clear'
                                width='5.5rem'
                                margins='1rem 0.5rem 0 1rem'
                                isActive={candidateWords.length > 0}
                                DoAction={() => ClearSearchParams()}
                            />
                            <ActionButtonComponent
                                text='Exit'
                                width='6.5rem'
                                margins='1rem 0.5rem 0 1rem'
                                isActive={true}
                                DoAction={() => HandleExit()}
                            />
                        </ButtonGroup>
                    </div>
                </div >
            </div >
        </ListOperationActionAreaComponent>

    return (
        <>
            <div className='list-operation-page-div'>
                <Container className='section-container bg-light w-100 pt-4 pb-4'>
                    {tabRowContent}
                    {actionAreaContent}
                </Container>
                {controlAreaContent}
            </div>
            <ListOperationWordsAreaComponent>
                <AddWordsSelectionComponent
                    candidateWords={candidateWords}
                    wordsToAdd={wordsToAdd}
                    UpdateWordsToAdd={(words) => SetWordsToAdd(words)}
                />
            </ListOperationWordsAreaComponent>
            <AddWordsModalComponent showModal={modalProps.showModal} HandleModalClose={modalProps.HandleModalClose} />
        </>
    )
}

ListOperationAddWordsComponent.propTypes = {
    tabRowContent: PropTypes.object.isRequired,
    controlAreaContent: PropTypes.object.isRequired,
    UpdateListWords: PropTypes.func.isRequired,
    actionAreaProps: PropTypes.object.isRequired,
    modalProps: PropTypes.object.isRequired,
}

function AddWordsSearchOptionComponent({ UpdateSearchOption }) {
    const { ClearSearchParams } = useContext(ListEditContext)

    return (
        <Form>
            <div
                key='addWordsSearchOptions'
                onChange={(event) => {
                    UpdateSearchOption(event.target.value)
                    ClearSearchParams()
                }}
            >
                <Form.Check
                    inline
                    label='Sound/Position/Level Search'
                    type='radio'
                    name='searchOptions'
                    value={ADD_WORDS_SEARCH_OPTION_SLP}
                    className='mx-3'
                    defaultChecked={true}
                />
                <Form.Check
                    inline
                    label='Text Match Search'
                    type='radio'
                    name='searchOptions'
                    value={ADD_WORDS_SEARCH_OPTION_TEXT_MATCH}
                    className='mx-3'
                />
            </div>
        </Form>
    )
}

AddWordsSearchOptionComponent.propTypes = {
    UpdateSearchOption: PropTypes.func.isRequired,
}

function AddWordsSearchComponent({ searchOption, UpdateSearchResults }) {
    switch (searchOption) {
        case ADD_WORDS_SEARCH_OPTION_SLP:
        default:
            return <AddWordsSLPSearchComponent UpdateSearchResults={UpdateSearchResults} />

        case ADD_WORDS_SEARCH_OPTION_TEXT_MATCH:
            return <AddWordsTextMatchSearchComponent UpdateSearchResults={UpdateSearchResults} />
    }
}

AddWordsSearchComponent.propTypes = {
    searchOption: PropTypes.string.isRequired,
    UpdateSearchResults: PropTypes.func.isRequired,
}

function AddWordsSLPSearchComponent({ UpdateSearchResults }) {
    const {
        slpSearchTargetSound, SetSlpSearchTargetSound,
        slpSearchPositions, SetSlpSearchPositions,
        slpSearchLevels, SetSlpSearchLevels
    } = useContext(ListEditContext)

    const GetTargetSoundParamChoices = () => {
        let targetSoundParamChoices = {}

        for (let choice of GetTargetSoundChoices()) {
            targetSoundParamChoices[choice] = choice
        }

        return targetSoundParamChoices
    }

    const GetPositionParamChoices = () => {
        if (!slpSearchTargetSound || slpSearchTargetSound === '') {
            return {}
        }

        const validPositionChoices = GetValidPositionChoices()

        if (!validPositionChoices[slpSearchTargetSound]) {
            return {}
        }

        const positionChoices = GetPositionChoices()
        let positionParamChoices = {}

        for (let choice of validPositionChoices[slpSearchTargetSound]) {
            positionParamChoices[choice] = positionChoices[choice]
        }

        return positionParamChoices
    }

    const GetLevelParamChoices = () => {
        if (!slpSearchTargetSound || slpSearchTargetSound === '') {
            return {}
        }

        let levelParamChoices = {}

        for (let choice of GetLevelChoices()) {
            levelParamChoices[choice] = choice
        }

        return levelParamChoices
    }

    const SelectPositionParam = (position) => {
        let newSelectedPositions = [...slpSearchPositions]

        if (!newSelectedPositions.includes(position)) {
            newSelectedPositions.push(position)
        }

        SetSlpSearchPositions(newSelectedPositions)
    }

    const DeselectPositionParam = (position) => {
        let newSelectedPositions = [...slpSearchPositions]

        if (newSelectedPositions.includes(position)) {
            const index = newSelectedPositions.indexOf(position)

            if (index !== -1) {
                newSelectedPositions.splice(index, 1)
            }
        }

        SetSlpSearchPositions(newSelectedPositions)
    }

    const SelectLevelParam = (level) => {
        let newSelectedLevels = [...slpSearchLevels]

        if (!newSelectedLevels.includes(level)) {
            newSelectedLevels.push(level)
        }

        SetSlpSearchLevels(newSelectedLevels)
    }

    const DeselectLevelParam = (level) => {
        let newSelectedLevels = [...slpSearchLevels]

        if (newSelectedLevels.includes(level)) {
            const index = newSelectedLevels.indexOf(level)

            if (index !== -1) {
                newSelectedLevels.splice(index, 1)
            }
        }

        SetSlpSearchLevels(newSelectedLevels)
    }

    const RunSearch = () => {
        const searchResults = SearchSoundLevelPosition({ sound: slpSearchTargetSound, levels: slpSearchLevels, positions: slpSearchPositions })
        UpdateSearchResults(searchResults)
    }

    useEffect(() => RunSearch(),
        [slpSearchTargetSound, slpSearchPositions, slpSearchLevels])  // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <Container className='mx-auto mt-4 mb-1'>
            <ButtonGroup className='flex-wrap mx-4'>
                <SearchParamSelectComponent
                    paramSelectLabel={'Target Sound'}
                    paramSelectChoices={GetTargetSoundParamChoices()}
                    selectedParams={[slpSearchTargetSound]}
                    SelectParam={targetSound => {
                        SetSlpSearchLevels([])
                        SetSlpSearchPositions([])
                        SetSlpSearchTargetSound(targetSound)
                    }}
                    DeselectParam={() => { }}
                    boxColor='var(--dark-orange)'
                />
                <SearchParamSelectComponent
                    paramSelectLabel={'Positions'}
                    paramSelectChoices={GetPositionParamChoices()}
                    selectedParams={slpSearchPositions}
                    SelectParam={position => SelectPositionParam(position)}
                    DeselectParam={position => DeselectPositionParam(position)}
                    boxWidth='12rem'
                    boxColor='var(--dark-orange)'
                />
                <SearchParamSelectComponent
                    paramSelectLabel={'Levels'}
                    paramSelectChoices={GetLevelParamChoices()}
                    selectedParams={slpSearchLevels}
                    SelectParam={level => SelectLevelParam(level)}
                    DeselectParam={level => DeselectLevelParam(level)}
                    boxColor='var(--dark-orange)'
                />
            </ButtonGroup>
        </Container>
    )
}

AddWordsSLPSearchComponent.propTypes = {
    UpdateSearchResults: PropTypes.func.isRequired,
}

function AddWordsTextMatchSearchComponent({ UpdateSearchResults }) {
    const { textMatchSearchText, SetTextMatchSearchText } = useContext(ListEditContext)

    const RunSearch = () => {
        let searchResults = []

        if (textMatchSearchText.length > 1) {
            searchResults = SearchTextMatch(textMatchSearchText)
        }

        UpdateSearchResults(searchResults)
    }

    useEffect(() => { RunSearch() },
        [textMatchSearchText])  // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <Container className='mx-auto mt-1 mb-2'>
            <Form.Group controlId='searchText' className='mt-3 mb-3 ms-4'>
                <Form.Label className='list-operation-add-words-search-text-label'>Text</Form.Label>
                <Form.Control
                    className='list-operation-add-words-search-text-box'
                    type='text'
                    value={textMatchSearchText}
                    autoComplete='off'
                    onChange={(event) => {
                        SetTextMatchSearchText(event.target.value)
                    }}
                />
            </Form.Group>
        </Container>
    )
}

AddWordsTextMatchSearchComponent.propTypes = {
    UpdateSearchResults: PropTypes.func.isRequired,
}

function AddWordsSelectionComponent({ candidateWords, wordsToAdd, UpdateWordsToAdd }) {
    const { listWords } = useContext(ListEditContext)

    const candidateWordCardsAndCheckboxes = candidateWords.map((wordKey, index) => (
        <Col xs={6} sm={4} md={3} lg={2} key={index}>
            <ButtonGroup className='vstack mt-0 mb-1 my-lg-1'>
                <WordCardComponent wordKey={wordKey} selectedWords={wordsToAdd}></WordCardComponent>
                <WordCheckboxComponent
                    wordKey={wordKey}
                    selectedWords={wordsToAdd}
                    SelectWord={(wordKey) => {
                        const newWordsToAdd = AddWordToList(wordsToAdd, wordKey)
                        UpdateWordsToAdd(newWordsToAdd)
                    }}
                    DeselectWord={(wordKey) => {
                        const newWordsToAdd = RemoveWordFromList(wordsToAdd, wordKey)
                        UpdateWordsToAdd(newWordsToAdd)
                    }}
                />
            </ButtonGroup>
        </Col>
    ))

    const listWordCards = listWords.map((wordKey, index) => (
        <Col xs={6} sm={4} md={3} lg={2} key={index}>
            <WordCardComponent wordKey={wordKey} selectedWords={listWords} />
        </Col>
    ))

    return (
        <>
            {candidateWords.length > 0 &&
                <>
                    <SelectAllCheckboxComponent
                        allWords={candidateWords}
                        selectedWords={wordsToAdd}
                        SelectAllWords={() => {
                            const newWordsToAdd = AddWordsToList(wordsToAdd, candidateWords)
                            UpdateWordsToAdd(newWordsToAdd)
                        }}
                        DeselectAllWords={() => {
                            const newWordsToAdd = []
                            UpdateWordsToAdd(newWordsToAdd)
                        }}
                    />
                    <Row className='mt-0 mb-4 g-2'>
                        {candidateWordCardsAndCheckboxes}
                    </Row>
                </>
            }
            <Row className='mt-0 g-2'>
                {listWordCards}
            </Row>
        </>
    )
}

AddWordsSelectionComponent.propTypes = {
    candidateWords: PropTypes.arrayOf(PropTypes.string).isRequired,
    wordsToAdd: PropTypes.arrayOf(PropTypes.string).isRequired,
    UpdateWordsToAdd: PropTypes.func.isRequired,
}

function AddWordsModalComponent({ showModal, HandleModalClose }) {
    return (
        <Modal
            className='fade modal-lg'
            style={{ color: 'var(--dark-blue)' }}
            show={showModal}
            onHide={() => { HandleModalClose() }}
        >
            <Modal.Header className='bg-light py-3'>
                <Modal.Title className='text-center mx-auto'>
                    <h4 className='my-0'>Add Words to the List</h4>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className='bg-light'>
                <div className='d-flex justify-content-center mx-auto'>
                    <div className='justify-content-left mx-5'>
                        <h5>Search for words to add using sound, position, and level or using text match. Words that are not already in the list will appear at the top of the card area.</h5>
                        <h5>Select the words you want to add. When you are done, click Update Words to save the changes to your list.</h5>
                        <h5>Click Exit from any tab to return to the Saved List page.</h5>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer className='bg-light py-1' style={{ border: 'none' }}>
            </Modal.Footer>
        </Modal >
    )
}

AddWordsModalComponent.propTypes = {
    showModal: PropTypes.bool.isRequired,
    HandleModalClose: PropTypes.func.isRequired,
}
