export const BACKEND_STATUS_WORKING = 'BackendStatus/Working'
export const BACKEND_STATUS_SUCCESS = 'BackendStatus/Success'
export const BACKEND_STATUS_FAILURE = 'BackendStatus/Failure'

export const HTTP_CODE_200_OK = 200
export const HTTP_CODE_201_CREATED = 201
export const HTTP_CODE_400_BAD_REQUEST = 400
export const HTTP_CODE_404_NOT_FOUND = 404
export const HTTP_CODE_409_CONFLICT = 409
export const HTTP_CODE_500_INTERNAL_SERVER_ERROR = 500
