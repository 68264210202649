import { useEffect, useContext } from 'react'
import { Container, ButtonGroup } from 'react-bootstrap'
import { useNavigate, useLocation } from 'react-router-dom'
import { LOGIN_SIGNUP_PATH, SUBSCRIPTION_PATH, START_PATH } from '../App'
import { WhimprovsTitleComponent, WhimprovsWordChoiceComponent, WhimprovsPassageComponent } from '../components/WhimprovsComponents'
import { ExitControlIconComponent } from '../components/ActivityControlComponents'
import { WhimprovsContext, WhimprovsProvider } from '../context/WhimprovsContext'
import { IsUserLoggedIn } from '../state/UserAuthState'
import { IsUserSubscribed } from '../state/SubscriptionState'
import { GetWhimprovsStories } from '../project/WhimprovsStories'
import { DisableScrollbar } from '../project/Display'

export function WhimprovsPage() {
    return (
        <WhimprovsProvider>
            <WhimprovsPageContent />
        </WhimprovsProvider>
    )
}

function WhimprovsPageContent() {
    const navigate = useNavigate()
    const { state } = useLocation()

    const { SetStory, SetStoryInfo, SetChoiceGroup, choiceKeys, SetChoiceKeys, choiceIndex } = useContext(WhimprovsContext)

    useEffect(() => {
        if (!IsUserLoggedIn()) {
            navigate(LOGIN_SIGNUP_PATH)
            return
        }
        else if (!IsUserSubscribed()) {
            navigate(SUBSCRIPTION_PATH)
            return
        }

        if (!state || !state['story'] || !state['choiceGroup']) {
            navigate(START_PATH)
            return
        }

        const story = state['story']
        const storyInfo = GetWhimprovsStories()[story]

        if (!storyInfo || !storyInfo['choiceGroups'] || !storyInfo['choiceGroups'][state['choiceGroup']]) {
            navigate(START_PATH)
            return
        }

        SetStory(story)
        SetStoryInfo(storyInfo)
        SetChoiceGroup(state['choiceGroup'])
        SetChoiceKeys(Object.keys(storyInfo['choiceGroups'][state['choiceGroup']]))
        DisableScrollbar()
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const GetBoardContainerContent = () => {
        if (choiceKeys.length === 0) {
            return <></>
        }

        if (choiceIndex < choiceKeys.length) {
            return <WhimprovsWordChoiceComponent />
        }

        return <WhimprovsPassageComponent />
    }

    return (
        <Container className='section-container bg-light'>
            <div className='activity-page-div'>
                <Container className='activity-control-container' />
                <Container className='vstack w-75 mt-1 px-4 py-4'>
                    <WhimprovsTitleComponent />
                    <Container className='whimprovs-board-container'>
                        {GetBoardContainerContent()}
                    </Container>
                </Container>
                <Container className='activity-control-container'>
                    <ButtonGroup className='vstack mt-0 mb-1'>
                        <ExitControlIconComponent
                            CleanUpActivity={() => { }}
                        />
                    </ButtonGroup>
                </Container>
            </div>
        </Container >
    )
}
