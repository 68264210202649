import { createReducer, createAction } from '@reduxjs/toolkit'
import { GetReducerState, FLASHCARDS_REDUCER, DispatchAction } from '../state/ReduxStore'
import { GetWordTargetSoundAndPosition } from '../project/WordCodings'
import {
    GetDefaultWordPhrases,
    ReadWordPhrases,
    PHRASE_LEVEL_WORD_ONLY,
    READ_WORD_PHRASES_STATUS_SUCCESS,
    READ_WORD_PHRASES_STATUS_FAILURE,
} from '../project/WordPhrases'
import { ShuffleArray, Delay } from '../project/Utils'

// -------------------------------------------------------------------------------------------------
//  Internal state management
// -------------------------------------------------------------------------------------------------

const WORD_LIST = 'FlashcardsState/WordList'
const WORD_LIST_INDEX = 'FlashcardsState/WordListIndex'
const ACTIVE_WORD_KEY = 'FlashcardsState/ActiveWordKey'
const ACTIVE_TARGET_SOUND_AND_POSITION = 'FlashcardsState/ActiveTargetSoundAndPosition'
const ACTIVE_PHRASES = 'FlashcardsState/ActivePhrases'
const PHRASE_LEVEL = 'FlashcardsState/PhraseLevel'

const defaultState = {
    [WORD_LIST]: [],
    [WORD_LIST_INDEX]: 0,
    [ACTIVE_WORD_KEY]: '',
    [ACTIVE_TARGET_SOUND_AND_POSITION]: ['', ''],
    [ACTIVE_PHRASES]: {},
    [PHRASE_LEVEL]: PHRASE_LEVEL_WORD_ONLY,
}

const GetWordList = () => {
    return GetReducerState(FLASHCARDS_REDUCER)[WORD_LIST]
}

const GetWordListIndex = () => {
    return GetReducerState(FLASHCARDS_REDUCER)[WORD_LIST_INDEX]
}

const GetActiveWordKey = () => {
    return GetReducerState(FLASHCARDS_REDUCER)[ACTIVE_WORD_KEY]
}

const GetActiveTargetSoundAndPosition = () => {
    return GetReducerState(FLASHCARDS_REDUCER)[ACTIVE_TARGET_SOUND_AND_POSITION]
}

const GetActivePhrases = () => {
    return GetReducerState(FLASHCARDS_REDUCER)[ACTIVE_PHRASES]
}

const GetPhraseLevel = () => {
    return GetReducerState(FLASHCARDS_REDUCER)[PHRASE_LEVEL]
}

// -------------------------------------------------------------------------------------------------
//  External state access
// -------------------------------------------------------------------------------------------------

export const GetInitialFlashcardsState = () => {
    return defaultState
}

export const IsFlashcardsWordListEmpty = () => {
    return GetWordList().length === 0
}

export const IsFlashcardsWordFirstInList = () => {
    return GetWordListIndex() === 0
}

export const IsFlashcardsWordLastInList = () => {
    return GetWordListIndex() === GetWordList().length - 1
}

export const GetFlashcardsActiveWordKey = () => {
    return GetActiveWordKey()
}

export const GetFlashcardsActiveTargetSoundAndPosition = () => {
    return GetActiveTargetSoundAndPosition()
}

export const GetFlashcardsActiveTargetSound = () => {
    return GetActiveTargetSoundAndPosition()[0]
}

export const GetFlashcardsActivePosition = () => {
    return GetActiveTargetSoundAndPosition()[1]
}

export const GetFlashcardsActivePhrases = () => {
    return GetActivePhrases()
}

export const GetFlashcardsPhraseLevel = () => {
    return GetPhraseLevel()
}

// -------------------------------------------------------------------------------------------------
//  Internal action management
// -------------------------------------------------------------------------------------------------

const SET_WORD_LIST = 'FlashcardsAction/SetWordList'
const INC_WORD_LIST_INDEX = 'FlashcardsAction/IncWordListIndex'
const DEC_WORD_LIST_INDEX = 'FlashcardsAction/DecWordListIndex'
const SET_ACTIVE_PHRASES = 'FlashcardsAction/SetActivePhrases'
const SET_PHRASE_LEVEL = 'FlashcardsAction/SetPhraseLevel'

const setWordListAction = createAction(SET_WORD_LIST)
const incWordListIndexAction = createAction(INC_WORD_LIST_INDEX)
const decWordListIndexAction = createAction(DEC_WORD_LIST_INDEX)
const setActivePhrasesAction = createAction(SET_ACTIVE_PHRASES)
const setPhraseLevelAction = createAction(SET_PHRASE_LEVEL)

// -------------------------------------------------------------------------------------------------
//  External action access
// -------------------------------------------------------------------------------------------------

export const SetFlashcardsWordList = (wordList) => {
    DispatchAction(setWordListAction, wordList)
}

export const ForwardFlashcardsWord = () => {
    DispatchAction(incWordListIndexAction)
}

export const ReverseFlashcardsWord = () => {
    DispatchAction(decWordListIndexAction)
}

export const SetFlashcardsPhraseLevel = (level) => {
    DispatchAction(setPhraseLevelAction, level)
}

// -------------------------------------------------------------------------------------------------
//  Internal reducer management
// -------------------------------------------------------------------------------------------------

const HandleSetWordListReq = (state, action) => {
    const wordList = [...action.payload]
    ShuffleArray(wordList)
    const wordKey = wordList[0]

    state[WORD_LIST] = wordList
    state[WORD_LIST_INDEX] = 0
    state[ACTIVE_WORD_KEY] = wordKey
    InitTargetSoundAndPosition(state)

    state[PHRASE_LEVEL] = PHRASE_LEVEL_WORD_ONLY
    Delay(100)
    state[ACTIVE_PHRASES] = GetDefaultWordPhrases(wordKey)
    ReadWordPhrases(wordKey, HandleWordPhrasesReadComplete)
}

const InitTargetSoundAndPosition = (state) => {
    state[ACTIVE_TARGET_SOUND_AND_POSITION] = GetWordTargetSoundAndPosition(state[ACTIVE_WORD_KEY])
}

const HandleWordPhrasesReadComplete = (status, wordKey, wordPhrases) => {
    if (status === READ_WORD_PHRASES_STATUS_SUCCESS) {
        DispatchAction(setActivePhrasesAction, wordPhrases)
    } else if (status === READ_WORD_PHRASES_STATUS_FAILURE) {
        console.log('***** Error: Failure reading word phrases for', wordKey)
    }
}

const HandleIncWordListIndexReq = (state) => {
    if (state[WORD_LIST_INDEX] < state[WORD_LIST].length - 1) {
        state[WORD_LIST_INDEX] += 1

        const wordKey = state[WORD_LIST][state[WORD_LIST_INDEX]]
        state[ACTIVE_WORD_KEY] = wordKey
        UpdateTargetSoundAndPositionForNewWord(state)

        state[ACTIVE_PHRASES] = GetDefaultWordPhrases(wordKey)
        ReadWordPhrases(wordKey, HandleWordPhrasesReadComplete)
    }
}

const HandleDecWordListIndexReq = (state) => {
    if (state[WORD_LIST_INDEX] > 0) {
        state[WORD_LIST_INDEX] -= 1

        const wordKey = state[WORD_LIST][state[WORD_LIST_INDEX]]
        state[ACTIVE_WORD_KEY] = wordKey
        UpdateTargetSoundAndPositionForNewWord(state)

        state[ACTIVE_PHRASES] = GetDefaultWordPhrases(wordKey)
        ReadWordPhrases(wordKey, HandleWordPhrasesReadComplete)
    }
}

const UpdateTargetSoundAndPositionForNewWord = (state) => {
    state[ACTIVE_TARGET_SOUND_AND_POSITION] = GetWordTargetSoundAndPosition(state[ACTIVE_WORD_KEY])
}

const HandleSetActivePhrasesReq = (state, action) => {
    var wordPhrases = action.payload
    state[ACTIVE_PHRASES] = wordPhrases
}

const HandleSetPhraseLevelReq = (state, action) => {
    var level = action.payload
    state[PHRASE_LEVEL] = level

    if (level !== PHRASE_LEVEL_WORD_ONLY && state[ACTIVE_PHRASES][level] === state[ACTIVE_PHRASES][PHRASE_LEVEL_WORD_ONLY]) {
        state[ACTIVE_PHRASES] = GetDefaultWordPhrases(state[ACTIVE_WORD_KEY])
        ReadWordPhrases(state[ACTIVE_WORD_KEY], HandleWordPhrasesReadComplete)
    }
}

const flashcardsReducer = createReducer(defaultState, (builder) => {
    builder.addCase(setWordListAction, (state, action) => {
        HandleSetWordListReq(state, action)
    })

    builder.addCase(incWordListIndexAction, (state, action) => {
        HandleIncWordListIndexReq(state, action)
    })

    builder.addCase(decWordListIndexAction, (state, action) => {
        HandleDecWordListIndexReq(state, action)
    })

    builder.addCase(setActivePhrasesAction, (state, action) => {
        HandleSetActivePhrasesReq(state, action)
    })

    builder.addCase(setPhraseLevelAction, (state, action) => {
        HandleSetPhraseLevelReq(state, action)
    })

    builder.addDefaultCase(() => { })
})

// -------------------------------------------------------------------------------------------------
//  External reducer access
// -------------------------------------------------------------------------------------------------

export const GetFlashcardsReducer = () => {
    return flashcardsReducer
}
