import { useState, useEffect, createContext, useContext } from 'react'

export const WhimprovsContext = createContext()

export const WhimprovsProvider = ({ children }) => {
    const [story, SetStory] = useState('')
    const [storyInfo, SetStoryInfo] = useState(null)
    const [choiceGroup, SetChoiceGroup] = useState('')
    const [choiceKeys, SetChoiceKeys] = useState([])
    const [choiceIndex, SetChoiceIndex] = useState(0)
    const [wordChoices, SetWordChoices] = useState({})
    const [passageIndex, SetPassageIndex] = useState(0)

    return (
        <WhimprovsContext.Provider
            value={{
                story, SetStory,
                storyInfo, SetStoryInfo,
                choiceGroup, SetChoiceGroup,
                choiceKeys, SetChoiceKeys,
                choiceIndex, SetChoiceIndex,
                wordChoices, SetWordChoices,
                passageIndex, SetPassageIndex,
            }}
        >
            {children}
        </WhimprovsContext.Provider>
    )
}

export function WhimprovsListener({ SendContextChangeNotification }) {
    const context = useContext(WhimprovsContext)
    const [stringifiedContext, SetStringifiedContext] = useState('')

    useEffect(() => {
        SendContextChangeNotification(stringifiedContext)
    }, [stringifiedContext, SendContextChangeNotification])

    useEffect(() => {
        SetStringifiedContext(JSON.stringify(context))
    }, [context])

    return <></>
}
