import { Container, Alert } from 'react-bootstrap'
import PropTypes from 'prop-types'
import './MessageComponents.css'

export function MessageComponent(
    { alertVariant = 'primary', isDismissible = true, DismissAlert = () => { }, messageWidth = '60%', children = '<></>' }) {

    return (
        <Container className='d-flex justify-content-center'>
            <Alert
                variant={alertVariant}
                className='alert-message'
                style={{ 'width': messageWidth }}
                dismissible={isDismissible}
                onClose={() => {
                    DismissAlert()
                }}
            >
                {children}
            </Alert>
        </Container >
    )
}

MessageComponent.propTypes = {
    alertVariant: PropTypes.string,
    isDismissible: PropTypes.bool,
    DismissAlert: PropTypes.func,
    messageWidth: PropTypes.string,
    children: PropTypes.node,
}

export function SuccessMessageComponent({ isDismissible, DismissAlert, messageWidth, children }) {
    return (
        <MessageComponent alertVariant='success' isDismissible={isDismissible} DismissAlert={DismissAlert} messageWidth={messageWidth}>
            {children}
        </MessageComponent>
    )
}

SuccessMessageComponent.propTypes = {
    isDismissible: PropTypes.bool,
    DismissAlert: PropTypes.func,
    messageWidth: PropTypes.string,
    children: PropTypes.node,
}

export function ErrorMessageComponent({ isDismissible, DismissAlert, messageWidth, children }) {
    return (
        <MessageComponent alertVariant='danger' isDismissible={isDismissible} DismissAlert={DismissAlert} messageWidth={messageWidth}>
            {children}
        </MessageComponent>
    )
}

ErrorMessageComponent.propTypes = {
    isDismissible: PropTypes.bool,
    DismissAlert: PropTypes.func,
    messageWidth: PropTypes.string,
    children: PropTypes.node,
}
