import axios from 'axios'
import { BACKEND_STATUS_WORKING, BACKEND_STATUS_SUCCESS, BACKEND_STATUS_FAILURE, HTTP_CODE_200_OK } from './BackendResources'
import { IsUserAdmin, GetUserAuthAccessToken } from '../state/UserAuthState'

export async function GetAdminBackendUserSubscriptions(callback) {
    if (!IsUserAdmin()) {
        return
    }

    let config = { headers: { 'Content-type': 'application/json', Authorization: `Bearer ${GetUserAuthAccessToken()}` } }
    let axiosInstance = axios.create()
    let response = null

    try {
        const { data } = await axiosInstance.get('/api/control/subscriptions/', config)
        response = data
    } catch (exception) {
        response = exception
    }

    callback(response)
}

export async function RetrieveBackendSubscription(authToken, callback) {
    let config = { headers: { 'Content-type': 'application/json', Authorization: `Bearer ${authToken}` } }
    let axiosInstance = axios.create()
    let backendStatus = BACKEND_STATUS_WORKING
    let response = null

    try {
        const { status, data } = await axiosInstance.get('/api/subscription/', config)
        backendStatus = (status === HTTP_CODE_200_OK) ? BACKEND_STATUS_SUCCESS : BACKEND_STATUS_FAILURE
        response = data
    }
    catch (exception) {
        if (exception['response'] && exception['response']['data']) {
            response = exception['response']['data']
        }
        else {
            response = ''
        }

        backendStatus = BACKEND_STATUS_FAILURE
    }

    callback(backendStatus, response)
}

export async function GetBackendSubscriptionPortal(callback) {
    let config = { headers: { 'Content-type': 'application/json', Authorization: `Bearer ${GetUserAuthAccessToken()}` } }
    let axiosInstance = axios.create()
    let backendStatus = BACKEND_STATUS_WORKING
    let response = null

    try {
        const { status, data } = await axiosInstance.get('/api/subscription/portal/', config)
        backendStatus = (status === HTTP_CODE_200_OK) ? BACKEND_STATUS_SUCCESS : BACKEND_STATUS_FAILURE
        response = data
    }
    catch (exception) {
        if (exception['response'] && exception['response']['data']) {
            response = exception['response']['data']
        }
        else {
            response = ''
        }

        backendStatus = BACKEND_STATUS_FAILURE
    }

    callback(backendStatus, response)
}
