import { PDFDocument, StandardFonts } from 'pdf-lib'

export async function CreateWordListPdf(listName, wordList) {
    const pdfDoc = await PDFDocument.create()
    pdfDoc.setTitle(listName, { showInWindowTitleBar: true })

    const Points_Per_Inch = 72
    const helveticaFont = await pdfDoc.embedFont(StandardFonts.Helvetica)

    const logoFile = '/static/site-images/pdf-logo.png'
    const logoImageBytes = await fetch(logoFile).then((response) => response.arrayBuffer())
    const logoImage = await pdfDoc.embedPng(logoImageBytes)
    const logoImageSize = logoImage.scale(0.7)

    var isNewPage = true
    var columnNumber = 1
    var rowNumber = 1
    var wordCount = 0
    var pdfPage = pdfDoc.addPage()

    for (var word of wordList) {
        if (isNewPage) {
            pdfPage.setSize(8.5 * Points_Per_Inch, 11 * Points_Per_Inch)

            pdfPage.drawImage(logoImage, {
                width: logoImageSize.width,
                height: logoImageSize.height,
                x: 0.5 * Points_Per_Inch,
                y: 9.85 * Points_Per_Inch,
            })

            pdfPage.drawText(listName, {
                font: helveticaFont,
                size: 18,
                x: 3.25 * Points_Per_Inch,
                y: 10.0 * Points_Per_Inch,
            })

            columnNumber = 1
            rowNumber = 1
            isNewPage = false
        }

        pdfPage.drawText(word, {
            font: helveticaFont,
            size: 15,
            x: (1.0 + (columnNumber - 1) * 2.5) * Points_Per_Inch,
            y: 9.25 * Points_Per_Inch - (rowNumber - 1) * 25,
        })

        ++wordCount

        if (++rowNumber === 25) {
            columnNumber += 1
            rowNumber = 1

            if (columnNumber === 4 && wordCount !== wordList.length) {
                pdfPage = pdfDoc.addPage()
                isNewPage = true
            }
        }
    }

    const pdfBytes = await pdfDoc.save()
    const u8Bytes = new Uint8Array(pdfBytes)
    const pdfBlob = new Blob([u8Bytes], { type: 'application/pdf' })
    const pdfUrl = URL.createObjectURL(pdfBlob)

    const newTab = window.open(pdfUrl, '_blank')

    if (!newTab) {
        window.location.assign(pdfUrl)
    }
}
