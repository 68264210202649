import { useState, useCallback, useContext, useEffect } from 'react'
import { Row, Col, Card, Image, Container, ButtonGroup } from 'react-bootstrap'
import PropTypes from 'prop-types'
import { PickAPathContext, PickAPathListener } from '../context/PickAPathContext'
import {
    PICK_A_PATH_READING_LEVEL_EASY, PICK_A_PATH_READING_LEVEL_PROFICIENT, PICK_A_PATH_NODE_END
} from '../project/PickAPathResources'
import { GetPickAPathStories, GetPickAPathReadingLevel } from '../project/PickAPathStories'
import { GetSiteImageUrl } from '../project/ProjectResources'
import { ActionButtonComponent } from './ActionButtonComponents'
import './PickAPathComponents.css'
import './WordCardComponent.css'

export function PickAPathTitleComponent() {
    const { story } = useContext(PickAPathContext)
    const [storyName, SetStoryName] = useState('')

    const HandlePickAPathContextChange = useCallback(
        (_listenerString) => {
            if (story) {
                const storyInfo = GetPickAPathStories()[story]

                if (storyInfo && storyInfo['name']) {
                    SetStoryName(storyInfo['name'])
                }
            }
        }, [story] // eslint-disable-line react-hooks/exhaustive-deps
    )

    return (
        <>
            <PickAPathListener SendContextChangeNotification={HandlePickAPathContextChange} />
            <div className='pick-a-path-title'>
                {storyName}
            </div >
        </>
    )
}

export function PickAPathVocabComponent() {
    const { SetHasVocabBeenShown } = useContext(PickAPathContext)
    const [stringifiedContext, SetStringifiedContext] = useState('')

    useEffect(() => { }, [stringifiedContext])

    const HandlePickAPathContextChange = useCallback(
        (listenerString) => {
            SetStringifiedContext(listenerString)
        }, [] // eslint-disable-line react-hooks/exhaustive-deps
    )

    const vocabCardGroupSize = 4 * 2
    const [vocabCardGroupNum, SetVocabCardGroupNum] = useState(0)

    const IsLastVocabCardGroup = () => ((vocabCardGroupNum + 1) * vocabCardGroupSize) >= GetPickAPathVocabWords().length

    const [vocabCardsInitialized, SetVocabCardsInitialized] = useState(false)
    setTimeout(() => SetVocabCardsInitialized(true), 500)

    const GetVocabCards = () => {
        const vocabWords = GetPickAPathVocabWords()
        const vocabCardGroupStartIndex = vocabCardGroupNum * vocabCardGroupSize
        const vocabCardGroupEndIndex = Math.min(vocabCardGroupStartIndex + vocabCardGroupSize, vocabWords.length)

        if (vocabCardGroupEndIndex <= vocabCardGroupStartIndex) {
            return []
        }

        const vocabCardGroupWords = vocabWords.slice(vocabCardGroupStartIndex, vocabCardGroupEndIndex)

        if (vocabCardGroupEndIndex === vocabWords.length) {
            for (let i = vocabCardGroupEndIndex; i < vocabCardGroupStartIndex + vocabCardGroupSize; ++i) {
                vocabCardGroupWords.push(vocabWords[0])
            }
        }

        const wordCards = vocabCardGroupWords.map((vocabEntry, index) => (
            <Col xs={3} key={index}>
                <div className={(index >= (vocabCardGroupEndIndex - vocabCardGroupStartIndex)) ? 'invisible' : ''}>
                    <ButtonGroup className='vstack mt-0 mb-1 my-lg-1'>
                        <PickAPathVocabCardComponent
                            vocabKey={vocabEntry[0]} vocabText={vocabEntry[1] || ''} />
                    </ButtonGroup>
                </div>
            </Col >
        ))

        return wordCards
    }

    return (
        <>
            <PickAPathListener SendContextChangeNotification={HandlePickAPathContextChange} />
            <Container className='pick-a-path-vocab-container'>
                <div className={vocabCardsInitialized ? '' : 'invisible'}>
                    <h3 className='mt-2'>Look for these words in the story:</h3>
                    <Container>
                        <div className='d-flex flex-wrap flex-nowrap flex-row mt-2 mb-1 g-2'>
                            <PickAPathVocabFwdRevComponent className='mx-0'
                                direction='REV'
                                showComponent={vocabCardGroupNum > 0}
                                DoAction={() => SetVocabCardGroupNum(vocabCardGroupNum - 1)}
                            />
                            <Row className='mx-4 mt-3 mb-4 g-2'>{GetVocabCards()}</Row>
                            <PickAPathVocabFwdRevComponent className='mx-0'
                                direction='FWD'
                                showComponent={!IsLastVocabCardGroup()}
                                DoAction={() => SetVocabCardGroupNum(vocabCardGroupNum + 1)}
                            />
                        </div>
                    </Container>
                    <ActionButtonComponent
                        text='Start'
                        width='6rem'
                        margins={'0 0.5rem 1rem 0.5rem'}
                        DoAction={() => SetHasVocabBeenShown(true)}
                    />
                </div>
            </Container>
        </>
    )
}

export function PickAPathVocabCardComponent({ vocabKey, vocabText = '' }) {
    const { story } = useContext(PickAPathContext)

    const [imageLoaded, SetImageLoaded] = useState(false)

    return (
        <div className={imageLoaded ? '' : 'invisible'}>
            <Card className='word-card bg-light' >
                <div className='word-card-image-div vstack mx-auto my-0'>
                    <Image fluid className='word-card-image word-card-image-active'
                        src={GetPickAPathWordImageUrl(story, vocabKey)} alt={vocabText || vocabKey}
                        onLoad={() => SetImageLoaded(true)} />
                </div>
                <h5 className='word-card-text text-center my-0 p-0'>{vocabText || vocabKey}</h5>
            </Card>
        </div>
    )
}

PickAPathVocabCardComponent.propTypes = {
    vocabKey: PropTypes.string.isRequired,
    vocabText: PropTypes.string
}

function PickAPathVocabFwdRevComponent({ direction, showComponent, DoAction }) {
    let srcImage = ''
    let altText = ''

    if (direction === 'FWD') {
        srcImage = `caret-right-square-fill-dark-orange.svg`
        altText = 'FWD'
    } else if (direction === 'REV') {
        srcImage = `caret-left-square-fill-dark-orange.svg`
        altText = 'REV'
    } else {
        return <></>
    }

    return (
        <Container className='my-auto' style={{ width: '20%' }}>
            {showComponent && (
                <Image fluid className='pick-a-path-vocab-fwd-rev-image'
                    src={GetSiteImageUrl(srcImage)} alt={altText}
                    onClick={() => DoAction()} />
            )}
        </Container>
    )
}

PickAPathVocabFwdRevComponent.propTypes = {
    direction: PropTypes.string.isRequired,
    showComponent: PropTypes.bool.isRequired,
    DoAction: PropTypes.func.isRequired,
}

export function PickAPathTextComponent() {
    const { story, currentNode } = useContext(PickAPathContext)
    const [stringifiedContext, SetStringifiedContext] = useState('')

    useEffect(() => { }, [stringifiedContext])

    const HandlePickAPathContextChange = useCallback(
        (listenerString) => {
            SetStringifiedContext(listenerString)
        }, [story, currentNode] // eslint-disable-line react-hooks/exhaustive-deps
    )

    return (
        <>
            <PickAPathListener SendContextChangeNotification={HandlePickAPathContextChange} />
            {HasNodeImages(story) ?
                <Container className='pick-a-path-text-container pick-a-path-proficient-reader-text-container'
                    style={IsLastNodeBeforeEnd(story, currentNode) ? { borderBottomStyle: 'none' } : {}}>
                    <Row>
                        <Col xs={12} lg={5}>
                            {GetNodeImage(story, currentNode)}
                        </Col>
                        <Col xs={12} lg={7}>
                            {GetNodeText(story, currentNode)}
                        </Col>
                    </Row>
                </Container>
                :
                <Container className='pick-a-path-text-container pick-a-path-easy-reader-text-container'
                    style={IsLastNodeBeforeEnd(story, currentNode) ? { borderBottomStyle: 'none' } : {}}>
                    <Row>
                        <Col xs={12}>
                            {GetNodeText(story, currentNode)}
                        </Col>
                    </Row>
                </Container>
            }
        </>
    )
}

export function PickAPathWordCardComponent({ story, wordKey, wordText }) {
    return (
        <>
            <Card className='pick-a-path-word-card bg-light'>
                <div className='pick-a-path-word-card-image-div vstack mx-auto my-0'>
                    <Image fluid className='pick-a-path-word-card-image' src={GetPickAPathWordImageUrl(story, wordKey)} alt={wordKey} />
                </div>
                <h5 className='pick-a-path-word-card-text text-center my-0 p-0'>{wordText}</h5>
            </Card>
        </>
    )
}

PickAPathWordCardComponent.propTypes = {
    story: PropTypes.string.isRequired,
    wordKey: PropTypes.string.isRequired,
    wordText: PropTypes.string.isRequired,
}

export function PickAPathChoicesHeaderComponent() {
    const { story, currentNode } = useContext(PickAPathContext)

    if (!DoesNodeHaveChoices(story, currentNode)) {
        return <></>
    }

    return (
        <div className='pick-a-path-choices-header'>
            Pick a Path
        </div >
    )
}

export function PickAPathChoicesComponent() {
    const { story, currentNode, SetCurrentNode, previousNodes, SetPreviousNodes } = useContext(PickAPathContext)
    const [stringifiedContext, SetStringifiedContext] = useState('')

    useEffect(() => { }, [stringifiedContext])

    const HandlePickAPathContextChange = useCallback(
        (listenerString) => {
            SetStringifiedContext(listenerString)
        }, [story, currentNode, previousNodes] // eslint-disable-line react-hooks/exhaustive-deps
    )

    const GetNodeChoices = () => {
        if (story) {
            const storyInfo = GetPickAPathStories()[story]

            if (storyInfo && storyInfo['nodes'] && storyInfo['nodes'][currentNode]) {
                const nodeChoices = storyInfo['nodes'][currentNode]['choices']

                if (nodeChoices && nodeChoices.length > 0) {
                    if (storyInfo['readingLevel'] === PICK_A_PATH_READING_LEVEL_EASY) {
                        return (
                            <Container className='pick-a-path-choices-container'>
                                <div className='pick-a-path-choices-content-div my-4'>
                                    <div className='d-flex flex-nowrap flex-row justify-content-center align-items mb-1'>
                                        {nodeChoices.map((choice, index) => (
                                            <PickAPathChoiceBoxComponent
                                                key={index}
                                                choice={choice}
                                            />
                                        ))}
                                    </div>
                                </div>
                            </Container >
                        )
                    }
                    else {
                        return (
                            <Container className='pick-a-path-choices-container'>
                                <div className='pick-a-path-choices-content-div'>
                                    {nodeChoices.map((choice, index) => (
                                        <div className='pick-a-path-choice-text-div mx-auto my-4'
                                            key={index} value={choice[1]}
                                            onClick={() => {
                                                let newPreviousNodes = previousNodes
                                                newPreviousNodes.push(currentNode)
                                                SetPreviousNodes(newPreviousNodes)
                                                SetCurrentNode(choice[1])
                                            }}
                                        >
                                            {choice[0]}
                                        </div>
                                    ))}
                                </div>
                            </Container>
                        )
                    }
                }
            }
        }

        return <></>
    }

    return (
        <>
            <PickAPathListener SendContextChangeNotification={HandlePickAPathContextChange} />
            {GetNodeChoices()}
        </>
    )
}

function PickAPathChoiceBoxComponent({ choice }) {
    const { story, currentNode, SetCurrentNode, previousNodes, SetPreviousNodes } = useContext(PickAPathContext)

    const [stringifiedContext, SetStringifiedContext] = useState('')

    useEffect(() => { }, [stringifiedContext])

    const HandlePickAPathContextChange = useCallback(
        (listenerString) => {
            SetStringifiedContext(listenerString)
        }, [] // eslint-disable-line react-hooks/exhaustive-deps
    )

    return (
        <>
            <PickAPathListener SendContextChangeNotification={HandlePickAPathContextChange} />
            <div
                className='pick-a-path-choice-box'
                onClick={() => {
                    let newPreviousNodes = previousNodes
                    newPreviousNodes.push(currentNode)
                    SetPreviousNodes(newPreviousNodes)
                    SetCurrentNode(choice[1])
                }}
            >
                <div className='d-flex justify-content-center mx-auto my-auto px-2'>
                    {ImagifyPickAPathChoice(story, choice[0])}
                </div>
            </div>
        </>
    )
}

export function PickAPathChoiceBoxCardComponent({ story, wordKey, wordText }) {
    return (
        <>
            <Card className='pick-a-path-choice-box-card bg-light'>
                <div className='pick-a-path-word-card-image-div vstack mx-auto my-0'>
                    <Image fluid className='pick-a-path-word-card-image' src={GetPickAPathWordImageUrl(story, wordKey)} alt={wordKey} />
                </div>
                <h5 className='pick-a-path-word-card-text text-center my-0 p-0'>{wordText}</h5>
            </Card>
        </>
    )
}

PickAPathChoiceBoxCardComponent.propTypes = {
    story: PropTypes.string.isRequired,
    wordKey: PropTypes.string.isRequired,
    wordText: PropTypes.string.isRequired
}

export function PickAPathEndComponent() {
    const { story, currentNode } = useContext(PickAPathContext)
    const [stringifiedContext, SetStringifiedContext] = useState('')

    useEffect(() => { }, [stringifiedContext])

    const HandlePickAPathContextChange = useCallback(
        (listenerString) => {
            SetStringifiedContext(listenerString)
        }, [story, currentNode] // eslint-disable-line react-hooks/exhaustive-deps
    )

    if (!IsLastNodeBeforeEnd(story, currentNode)) {
        return <></>
    }

    return (
        <>
            <PickAPathListener SendContextChangeNotification={HandlePickAPathContextChange} />
            <Container className='pick-a-path-text-container py-2'
                style={{ borderStyle: 'none solid none solid' }}>
                <div className='pick-a-path-end-text-divider' />
            </Container>
            {HasNodeImages(story) ?
                <Container className='pick-a-path-text-container pick-a-path-proficient-reader-text-container'
                    style={{ borderTopStyle: 'none' }}>
                    <Row>
                        <Col xs={12} lg={5}>
                            {GetNodeImage(story, PICK_A_PATH_NODE_END)}
                        </Col>
                        <Col xs={12} lg={7}>
                            {GetNodeText(story, PICK_A_PATH_NODE_END)}
                        </Col>
                    </Row>
                </Container >
                :
                <Container className='pick-a-path-text-container pick-a-path-easy-reader-text-container'
                    style={{ borderTopStyle: 'none' }}>
                    <Row>
                        <Col xs={12}>
                            {GetNodeText(story, PICK_A_PATH_NODE_END)}
                        </Col>
                    </Row>
                </Container>
            }
        </>
    )
}

export function PickAPathEndFooterComponent() {
    const { story, currentNode } = useContext(PickAPathContext)

    if (DoesNodeHaveChoices(story, currentNode)) {
        return <></>
    }

    return (
        <div className='pick-a-path-end-footer'>
            The End
        </div >
    )
}

function GetPickAPathVocabWords() {
    const { story } = useContext(PickAPathContext)
    const storyInfo = GetPickAPathStories()[story]

    if (storyInfo && storyInfo['vocab']) {
        return storyInfo['vocab']
    }

    return []
}

function DoesNodeHaveChoices(story, node) {
    const storyInfo = GetPickAPathStories()[story]

    if (storyInfo && storyInfo['nodes'] && storyInfo['nodes'][node]) {
        const nodeInfo = storyInfo['nodes'][node]

        if (nodeInfo['choices'] && nodeInfo['choices'].length > 0) {
            return true
        }
    }

    return false
}

function HasNodeImages(story) {
    if (story) {
        const storyInfo = GetPickAPathStories()[story]

        if (storyInfo && (storyInfo['readingLevel'] === PICK_A_PATH_READING_LEVEL_PROFICIENT)) {
            return true
        }
    }

    return false
}

function GetNodeImage(story, node) {
    const nodeImageUrl = 'https://leveluparticstorage.blob.core.windows.net/pick-a-path-node-images/' + story
        + '_node-' + node.substring('PickAPath/Node/'.length) + '.png'

    if (story) {
        const storyInfo = GetPickAPathStories()[story]

        if (storyInfo && storyInfo['nodes'] && storyInfo['nodes'][node]) {
            return (
                <div className='my-3'>
                    <Image fluid src={nodeImageUrl} />
                </div>
            )
        }
    }

    return <></>
}

function GetNodeText(story, node) {
    if (story) {
        const storyInfo = GetPickAPathStories()[story]

        if (storyInfo && storyInfo['nodes'] && storyInfo['nodes'][node]) {
            const nodeText = storyInfo['nodes'][node]['text']

            let nodeJsx = []
            let nodeJsxKey = 0

            for (let line of nodeText) {
                nodeJsx.push(
                    <div className='d-flex flex-wrap mt-2 mb-4' key={nodeJsxKey++}>
                        {ImagifyPickAPathText(story, line)}
                    </div>
                )
            }

            return <div key={nodeJsxKey++}>{nodeJsx}</div>
        }
    }

    return <></>
}

function ImagifyPickAPathText(story, text) {
    let unimagifiedText = text
    let imagifiedJsxElements = []
    let imagifiedJsxKey = 0

    let chunkClass = 'pick-a-path-text-chunk'

    if (GetPickAPathReadingLevel(story) === PICK_A_PATH_READING_LEVEL_PROFICIENT) {
        chunkClass += ' pick-a-path-proficient-reader-text-chunk'
    }
    else {
        chunkClass += ' pick-a-path-easy-reader-text-chunk'
    }

    while (unimagifiedText.length > 0) {
        let left_delimiter_position = unimagifiedText.search(/</)

        if (left_delimiter_position === -1) {
            const splitText = unimagifiedText.split(/\s+/)

            for (let chunk of splitText) {
                imagifiedJsxElements.push(
                    <p className={chunkClass} key={imagifiedJsxKey++}>
                        {chunk}
                    </p>
                )
            }

            unimagifiedText = ''
        } else {
            let pipe_position = unimagifiedText.search(/\|/)
            let right_delimiter_position = unimagifiedText.search(/>/)

            let wordKey = ''
            let wordText = ''

            if (pipe_position === -1 || pipe_position > right_delimiter_position) {
                wordKey = unimagifiedText.substring(left_delimiter_position + 1, right_delimiter_position)
                wordText = wordKey
            }
            else {
                wordKey = unimagifiedText.substring(left_delimiter_position + 1, pipe_position)
                wordText = unimagifiedText.substring(pipe_position + 1, right_delimiter_position)
            }

            if (left_delimiter_position > 0) {
                const splitText = unimagifiedText.substring(0, left_delimiter_position).split(/\s+/)

                for (let chunk of splitText) {
                    imagifiedJsxElements.push(
                        <p className={chunkClass} key={imagifiedJsxKey++}>
                            {chunk}
                        </p>
                    )
                }
            }

            imagifiedJsxElements.push(
                <PickAPathWordCardComponent story={story} wordKey={wordKey} wordText={wordText} key={imagifiedJsxKey++} />
            )

            unimagifiedText = unimagifiedText.substring(right_delimiter_position + 1)
        }
    }

    return <>{imagifiedJsxElements}</>
}

function ImagifyPickAPathChoice(story, text) {
    let unimagifiedText = text
    let imagifiedJsxElements = []
    let imagifiedJsxKey = 0

    while (unimagifiedText.length > 0) {
        let left_delimiter_position = unimagifiedText.search(/</)

        if (left_delimiter_position === -1) {
            imagifiedJsxElements.push(
                <p className='pick-a-path-choice-box-text-chunk' key={imagifiedJsxKey++}>
                    {unimagifiedText}
                </p>
            )

            unimagifiedText = ''
        }
        else {
            let pipe_position = unimagifiedText.search(/\|/)
            let right_delimiter_position = unimagifiedText.search(/>/)

            let wordKey = ''
            let wordText = ''

            if (pipe_position === -1 || pipe_position > right_delimiter_position) {
                wordKey = unimagifiedText.substring(left_delimiter_position + 1, right_delimiter_position)
                wordText = wordKey
            }
            else {
                wordKey = unimagifiedText.substring(left_delimiter_position + 1, pipe_position)
                wordText = unimagifiedText.substring(pipe_position + 1, right_delimiter_position)
            }

            if (left_delimiter_position > 0) {
                imagifiedJsxElements.push(
                    <p className='pick-a-path-choice-box-text-chunk' key={imagifiedJsxKey++}>
                        {unimagifiedText.substring(0, left_delimiter_position)}
                    </p>
                )
            }

            imagifiedJsxElements.push(
                <PickAPathChoiceBoxCardComponent story={story} wordKey={wordKey} wordText={wordText} key={imagifiedJsxKey++} />
            )

            unimagifiedText = unimagifiedText.substring(right_delimiter_position + 1)
        }
    }

    return <>{imagifiedJsxElements}</>
}

function GetPickAPathWordImageUrl(story, wordKey) {
    return 'https://leveluparticstorage.blob.core.windows.net/pick-a-path-word-images/' + story + '_' + wordKey + '.svg'
}

function IsLastNodeBeforeEnd(story, node) {
    if (node === PICK_A_PATH_NODE_END) {
        return false
    }

    if (DoesNodeHaveChoices(story, node)) {
        return false
    }

    const storyInfo = GetPickAPathStories()[story]

    if (storyInfo && storyInfo['nodes'] && storyInfo['nodes'][PICK_A_PATH_NODE_END]) {
        return true
    }

    return false
}
