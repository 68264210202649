import { useState, useEffect, createContext, useContext } from 'react'
import { Synth as ToneSynth } from 'tone'
import { ShuffleArray, GenerateRandomInt } from '../project/Utils'

export const COPYCAT_CARD_INDEX = 'Copycat/Card/Index'
export const COPYCAT_CARD_WORD = 'Copycat/Card/Word'
export const COPYCAT_CARD_STATE = 'Copycat/Card/State'

export const COPYCAT_CARD_STATE_NORMAL = 'Copycat/CardState/Normal'
export const COPYCAT_CARD_STATE_PLAYING = 'Copycat/CardState/Playing'
export const COPYCAT_CARD_STATE_CLICK_SUCCESS = 'Copycat/CardState/ClickSuccess'
export const COPYCAT_CARD_STATE_CLICK_FAILURE = 'Copycat/CardState/ClickFailure'

export const COPYCAT_GAME_STATE_START_GAME = 'Copycat/GameState/StartGame'
export const COPYCAT_GAME_STATE_CONT_GAME = 'Copycat/GameState/ContGame'
export const COPYCAT_GAME_STATE_END_GAME = 'Copycat/GameState/EndGame'
export const COPYCAT_GAME_STATE_START_PLAY = 'Copycat/GameState/StartPlay'
export const COPYCAT_GAME_STATE_CONT_PLAY = 'Copycat/GameState/ContPlay'
export const COPYCAT_GAME_STATE_START_COPY = 'Copycat/GameState/StartCopy'
export const COPYCAT_GAME_STATE_CONT_COPY = 'Copycat/GameState/ContCopy'

const copycatCardPlayTones = ['A4', 'B4', 'D4', 'E4', 'G4']
const copycatCardFailureTone = 'C3'

export const CopycatContext = createContext()

export const CopycatProvider = ({ children }) => {
    const [copycatSourceWords, SetCopycatSourceWords] = useState([])
    const [numCopycatCards, SetNumCopycatCards] = useState(0)
    const [copycatCards, SetCopycatCards] = useState([])
    const [copycatSequence, SetCopycatSequence] = useState([])
    const [copycatGameState, SetCopycatGameState] = useState(COPYCAT_GAME_STATE_START_GAME)
    const [copycatClickedCardIndex, SetCopycatClickedCardIndex] = useState(-1)
    const [copycatIsCardClickAllowed, SetCopycatIsCardClickAllowed] = useState(false)
    const [copycatIsSoundOn, SetCopycatIsSoundOn] = useState(true)

    const ClearCopycatContext = () => {
        SetCopycatSourceWords([])
        SetNumCopycatCards(0)
        SetCopycatCards([])
        SetCopycatSequence([])
        SetCopycatGameState(COPYCAT_GAME_STATE_START_GAME)
        SetCopycatClickedCardIndex(-1)
        SetCopycatIsCardClickAllowed(false)
        SetCopycatIsSoundOn(true)
    }

    const ResetCopycatContext = () => {
        ResetCopycatCards()
        ResetCopycatSequence()
        SetCopycatGameState(COPYCAT_GAME_STATE_START_GAME)
        SetCopycatClickedCardIndex(-1)
        SetCopycatIsCardClickAllowed(false)
    }

    const ResetCopycatCards = () => {
        if (numCopycatCards === 0) {
            SetCopycatCards([])
            return
        }

        let shuffledWords = [...copycatSourceWords]
        ShuffleArray(shuffledWords)
        shuffledWords = shuffledWords.slice(0, numCopycatCards)

        let cards = []

        for (let i = 0; i < numCopycatCards; ++i) {
            const card = {
                [COPYCAT_CARD_INDEX]: i,
                [COPYCAT_CARD_WORD]: shuffledWords[i],
                [COPYCAT_CARD_STATE]: COPYCAT_CARD_STATE_NORMAL,
            }

            cards.push(card)
        }

        SetCopycatCards(cards)
    }

    const ResetCopycatSequence = () => {
        if (numCopycatCards === 0) {
            SetCopycatSequence([])
        }

        SetCopycatSequence([GenerateRandomInt(0, numCopycatCards - 1)])
    }

    const ExtendCopycatSequence = () => {
        let newSequence = [...copycatSequence]
        let newIndex = newSequence[newSequence.length - 1]

        while (newIndex === newSequence[newSequence.length - 1]) {
            newIndex = GenerateRandomInt(0, numCopycatCards - 1)
        }

        newSequence.push(newIndex)
        SetCopycatSequence(newSequence)
    }

    return (
        <CopycatContext.Provider
            value={{
                copycatSourceWords, SetCopycatSourceWords,
                numCopycatCards, SetNumCopycatCards,
                copycatCards, SetCopycatCards,
                copycatSequence, SetCopycatSequence, ExtendCopycatSequence,
                copycatGameState, SetCopycatGameState,
                copycatClickedCardIndex, SetCopycatClickedCardIndex,
                copycatIsCardClickAllowed, SetCopycatIsCardClickAllowed,
                copycatIsSoundOn, SetCopycatIsSoundOn,
                ClearCopycatContext, ResetCopycatContext,
            }}
        >
            {children}
        </CopycatContext.Provider>
    )
}

export function CopycatStateMachine() {
    const { copycatCards, SetCopycatCards, copycatSequence, ExtendCopycatSequence,
            copycatClickedCardIndex, SetCopycatClickedCardIndex, SetCopycatIsCardClickAllowed,
            copycatIsSoundOn, copycatGameState, SetCopycatGameState } = useContext(CopycatContext)
    const [copycatPlayIndex, SetCopycatPlayIndex] = useState(0)
    const [copycatCopyIndex, SetCopycatCopyIndex] = useState(0)

    const PlayTone = (tone, duration) => {
        if (!copycatIsSoundOn) {
            return
        }

        const synth = new ToneSynth().toDestination()
        synth.triggerAttackRelease(tone, duration)
    }

    useEffect(() => {
        let newCopycatCards = [...copycatCards]

        switch (copycatGameState) {
            case COPYCAT_GAME_STATE_START_PLAY:
                newCopycatCards[copycatSequence[0]][COPYCAT_CARD_STATE] = COPYCAT_CARD_STATE_PLAYING
                SetCopycatCards(newCopycatCards)

                PlayTone(copycatCardPlayTones[copycatSequence[0]], 0.75)
                SetCopycatGameState(COPYCAT_GAME_STATE_CONT_PLAY)

                setTimeout(() => {
                    SetCopycatPlayIndex(copycatPlayIndex + 1)
                }, 1000)

                break

            case COPYCAT_GAME_STATE_END_GAME:
                for (let card of newCopycatCards) {
                    card[COPYCAT_CARD_STATE] = COPYCAT_CARD_STATE_NORMAL
                }

                break

            default:
                break
        }
    }, [copycatGameState])  // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (copycatGameState !== COPYCAT_GAME_STATE_CONT_PLAY) {
            return
        }

        if (copycatPlayIndex === 0) {
            return
        }

        let newCopycatCards = [...copycatCards]
        newCopycatCards[copycatSequence[copycatPlayIndex - 1]][COPYCAT_CARD_STATE] = COPYCAT_CARD_STATE_NORMAL

        if (copycatPlayIndex === copycatSequence.length) {
            for (let card of newCopycatCards) {
                card[COPYCAT_CARD_STATE] = COPYCAT_CARD_STATE_NORMAL
            }

            SetCopycatCards(newCopycatCards)
            SetCopycatPlayIndex(0)
            SetCopycatCopyIndex(0)
            SetCopycatGameState(COPYCAT_GAME_STATE_START_COPY)
            SetCopycatIsCardClickAllowed(true)
        }
        else {
            newCopycatCards[copycatSequence[copycatPlayIndex]][COPYCAT_CARD_STATE] = COPYCAT_CARD_STATE_PLAYING
            SetCopycatCards(newCopycatCards)

            PlayTone(copycatCardPlayTones[copycatSequence[copycatPlayIndex]], 0.75)

            setTimeout(() => {
                SetCopycatPlayIndex(copycatPlayIndex + 1)
            }, 1000)
        }
    }, [copycatPlayIndex])  // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        const cardIndex = copycatClickedCardIndex

        if (cardIndex === -1) {
            return
        }

        if ((copycatGameState !== COPYCAT_GAME_STATE_START_COPY) && (copycatGameState !== COPYCAT_GAME_STATE_CONT_COPY)) {
            return
        }

        if (copycatCards[cardIndex][COPYCAT_CARD_STATE] === COPYCAT_CARD_STATE_CLICK_SUCCESS) {
            return
        }

        if ((copycatCopyIndex > 0) && (cardIndex === copycatSequence[copycatCopyIndex - 1])) {
            return
        }

        SetCopycatIsCardClickAllowed(false)
        let newCopycatCards = [...copycatCards]

        if (cardIndex === copycatSequence[copycatCopyIndex]) {
            newCopycatCards[cardIndex][COPYCAT_CARD_STATE] = COPYCAT_CARD_STATE_CLICK_SUCCESS
            SetCopycatCards(newCopycatCards)

            PlayTone(copycatCardPlayTones[cardIndex], 0.5)

            setTimeout(() => {
                if (copycatCopyIndex === (copycatSequence.length - 1)) {
                    ExtendCopycatSequence(copycatSequence, copycatCards.length)
                    SetCopycatPlayIndex(0)
                    SetCopycatCopyIndex(0)
                    SetCopycatGameState(COPYCAT_GAME_STATE_CONT_GAME)
                }
                else {
                    if (copycatGameState === COPYCAT_GAME_STATE_START_COPY) {
                        SetCopycatGameState(COPYCAT_GAME_STATE_CONT_COPY)
                    }

                    SetCopycatCopyIndex(copycatCopyIndex + 1)
                    SetCopycatIsCardClickAllowed(true)
                }

                newCopycatCards[cardIndex][COPYCAT_CARD_STATE] = COPYCAT_CARD_STATE_NORMAL
                SetCopycatCards(newCopycatCards)
                SetCopycatClickedCardIndex(-1)
            }, 750)
        }
        else {
            newCopycatCards[cardIndex][COPYCAT_CARD_STATE] = COPYCAT_CARD_STATE_CLICK_FAILURE
            SetCopycatCards(newCopycatCards)

            PlayTone(copycatCardFailureTone, 0.75)

            setTimeout(() => {
                SetCopycatGameState(COPYCAT_GAME_STATE_END_GAME)
            }, 1000)
        }
    }, [copycatClickedCardIndex])  // eslint-disable-line react-hooks/exhaustive-deps

    return <></>
}

export function CopycatListener({ SendContextChangeNotification }) {
    const context = useContext(CopycatContext)
    const [stringifiedContext, SetStringifiedContext] = useState('')

    useEffect(() => {
        SendContextChangeNotification(stringifiedContext)
    }, [stringifiedContext, SendContextChangeNotification])

    useEffect(() => {
        SetStringifiedContext(JSON.stringify(context))
    }, [context])

    return <></>
}
