import { GetWordCodings, GetWordText } from './WordCodings'
import { WORD_SET_ALL } from './SoundPositionLevelChoices'
import { SortWords } from './Utils'

export function SearchSoundLevelPosition({ sound, levels, positions, set }) {
    const wordSet = set || WORD_SET_ALL
    const numericLevels = levels.map(Number)
    const wordCodings = GetWordCodings()
    var searchResults = []

    for (var wordKey of Object.keys(wordCodings)) {
        const wordCoding = wordCodings[wordKey]

        if (wordCoding && (sound === wordCoding['sound']) &&
            (numericLevels.includes(wordCoding['level'])) &&
            (positions.includes(wordCoding['position'])) &&
            ((wordSet === WORD_SET_ALL) || (wordSet === wordCoding['set']))) {
            searchResults.push(wordKey)
        }
    }

    searchResults = SortWords(searchResults)

    return searchResults
}

export function SearchTextMatch(text) {
    let searchResults = []

    if (text.length > 1) {
        for (const wordKey of Object.keys(GetWordCodings())) {
            const wordText = GetWordText(wordKey).toLowerCase()

            if (wordText.includes(text.toLowerCase())) {
                searchResults.push(wordKey)
            }
        }
    }

    return searchResults
}
