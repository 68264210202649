import { useState, useEffect } from 'react'
import { createReducer, createAction } from '@reduxjs/toolkit'
import PropTypes from 'prop-types'
import {
    DispatchAction,
    GetReducerState,
    SUBSCRIPTION_REDUCER,
    SubscribeActionListener,
    GetStringifiedState,
    DEFAULT_STRINGIFIED_STATE,
} from './ReduxStore'
import { IsUserLoggedIn } from './UserAuthState'
import { RetrieveBackendSubscription } from '../project/BackendSubscriptionOperations'
import { BACKEND_STATUS_SUCCESS, BACKEND_STATUS_FAILURE } from '../project/BackendResources'

// -------------------------------------------------------------------------------------------------
//  Internal state management
// -------------------------------------------------------------------------------------------------

const SUBSCRIPTION_STATUS = 'SubscriptionState/SubscriptionStatus'
const SUBSCRIPTION_INFO = 'SubscriptionState/SubscriptionInfo'
const SUBSCRIPTION_FAILURE = 'SubscriptionState/SubscriptionFailure'
const SUBSCRIPTION_OPERATION = 'SubscriptionState/SubscriptionOperation'

const SUBSCRIPTION_STATUS_INVALID = 'SubscriptionState/SubscriptionStatus/Invalid'
const SUBSCRIPTION_STATUS_VALID = 'SubscriptionState/SubscriptionStatus/Valid'

const SUBSCRIPTION_INFO_INVALID = ''

const SUBSCRIPTION_FAILURE_NONE = 'SubscriptionState/SubscriptionFailure/None'
const SUBSCRIPTION_FAILURE_UNDIAGNOSED = 'SubscriptionState/SubscriptionFailure/Undiagnosed'

const SUBSCRIPTION_OPERATION_NONE = 'SubscriptionState/Operation/None'
const SUBSCRIPTION_OPERATION_RETRIEVE_SUBSCRIPTION = 'SubscriptionState/Operation/RetrieveSubscription'

const defaultState = {
    [SUBSCRIPTION_STATUS]: SUBSCRIPTION_STATUS_VALID,
    [SUBSCRIPTION_INFO]: SUBSCRIPTION_INFO_INVALID,
    [SUBSCRIPTION_FAILURE]: SUBSCRIPTION_FAILURE_NONE,
    [SUBSCRIPTION_OPERATION]: SUBSCRIPTION_OPERATION_NONE,
}

const GetSubscriptionStatus = () => {
    return GetReducerState(SUBSCRIPTION_REDUCER)[SUBSCRIPTION_STATUS]
}

const GetSubscriptionInfo = () => {
    return GetReducerState(SUBSCRIPTION_REDUCER)[SUBSCRIPTION_INFO]
}

const GetSubscriptionFailure = () => {
    return GetReducerState(SUBSCRIPTION_REDUCER)[SUBSCRIPTION_FAILURE]
}

const GetSubscriptionOperation = () => {
    return GetReducerState(SUBSCRIPTION_REDUCER)[SUBSCRIPTION_OPERATION]
}

// -------------------------------------------------------------------------------------------------
//  External state access
// -------------------------------------------------------------------------------------------------

export { SUBSCRIPTION_FAILURE_NONE, SUBSCRIPTION_FAILURE_UNDIAGNOSED }
export { SUBSCRIPTION_OPERATION_NONE }

export const GetInitialSubscriptionState = () => {
    return defaultState
}

export function SubscriptionStateChangeListener({ callback }) {
    const [stringifiedState, SetStringifiedState] = useState(DEFAULT_STRINGIFIED_STATE)

    useEffect(() => {
        callback(stringifiedState)
    }, [stringifiedState, callback])

    const HandleActionNotification = () => {
        SetStringifiedState(GetStringifiedState(SUBSCRIPTION_REDUCER))
    }

    useEffect(() => {
        SubscribeActionListener(HandleActionNotification)
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    return <></>
}

SubscriptionStateChangeListener.propTypes = {
    callback: PropTypes.func.isRequired,
}

export const IsSubscriptionValid = () => {
    return GetSubscriptionStatus() === SUBSCRIPTION_STATUS_VALID
}

export const IsUserSubscribed = () => {
    if (!IsUserLoggedIn() || (GetSubscriptionStatus() === SUBSCRIPTION_STATUS_INVALID)) {
        return false
    }

    if (GetSubscriptionInfo().subscriptionStatus === 'subscription-status-inactive') {
        // Allow 2 days after subscription expires.
        const expirationTimestampMsec = GetSubscriptionInfo().expirationTimestamp * 1000
        const expirationAllowanceMsec = 2 * 24 * 60 * 60 * 1000
        return ((Date.now() - expirationTimestampMsec) < expirationAllowanceMsec)
    }

    return true
}

export { GetSubscriptionInfo, GetSubscriptionFailure, GetSubscriptionOperation }

// -------------------------------------------------------------------------------------------------
//  Internal action management
// -------------------------------------------------------------------------------------------------

const RETRIEVE_SUBSCRIPTION_REQUEST = 'SubscriptionAction/RetrieveSubscriptionRequest'
const RETRIEVE_SUBSCRIPTION_SUCCESS = 'SubscriptionAction/RetrieveSubscriptionSuccess'
const RETRIEVE_SUBSCRIPTION_FAILURE = 'SubscriptionAction/RetrieveSubscriptionFailure'
const REFRESH_SUBSCRIPTION_REQUEST = 'SubscriptionAction/RefreshSubscriptionRequest'
const INVALIDATE_SUBSCRIPTION_REQUEST = 'SubscriptionAction/InvalidateSubscriptionRequest'

const retrieveSubscriptionRequestAction = createAction(RETRIEVE_SUBSCRIPTION_REQUEST)
const retrieveSubscriptionSuccessAction = createAction(RETRIEVE_SUBSCRIPTION_SUCCESS)
const retrieveSubscriptionFailureAction = createAction(RETRIEVE_SUBSCRIPTION_FAILURE)
const refreshSubscriptionRequestAction = createAction(REFRESH_SUBSCRIPTION_REQUEST)
const invalidateSubscriptionRequestAction = createAction(INVALIDATE_SUBSCRIPTION_REQUEST)

// -------------------------------------------------------------------------------------------------
//  External action access
// -------------------------------------------------------------------------------------------------

export const RetrieveSubscription = (accessToken) => {
    DispatchAction(retrieveSubscriptionRequestAction, accessToken)
}

export const RefreshSubscription = (accessToken) => {
    DispatchAction(refreshSubscriptionRequestAction, accessToken)
}

export const InvalidateSubscription = () => {
    DispatchAction(invalidateSubscriptionRequestAction)
}

// -------------------------------------------------------------------------------------------------
//  Internal reducer management
// -------------------------------------------------------------------------------------------------

const UpdateSubscriptionState = (state, status, info, failure, operation) => {
    state[SUBSCRIPTION_STATUS] = status
    state[SUBSCRIPTION_INFO] = info
    state[SUBSCRIPTION_FAILURE] = failure
    state[SUBSCRIPTION_OPERATION] = operation
}

const HandleRetrieveSubscriptionRequest = (state, action) => {
    UpdateSubscriptionState(state, state[SUBSCRIPTION_STATUS], state[SUBSCRIPTION_INFO],
        SUBSCRIPTION_FAILURE_NONE, SUBSCRIPTION_OPERATION_RETRIEVE_SUBSCRIPTION)
    RetrieveBackendSubscription(action.payload, HandleBackendRetrieveSubscriptionResponse)
}

const HandleBackendRetrieveSubscriptionResponse = (status, response) => {
    if (status === BACKEND_STATUS_SUCCESS) {
        DispatchAction(retrieveSubscriptionSuccessAction, response)
    }
    else if (status === BACKEND_STATUS_FAILURE) {
        DispatchAction(retrieveSubscriptionFailureAction, response)
    }
}

const HandleRetrieveSubscriptionSuccess = (state, action) => {
    UpdateSubscriptionState(state, SUBSCRIPTION_STATUS_VALID, action.payload, SUBSCRIPTION_FAILURE_NONE, SUBSCRIPTION_OPERATION_NONE)
}

const HandleRetrieveSubscriptionFailure = (state) => {
    // TODO: Use action.payload to set the SUBSCRIPTION_FAILURE state. Just assume an undiagnosed error for now.
    UpdateSubscriptionState(state, SUBSCRIPTION_STATUS_INVALID, SUBSCRIPTION_INFO_INVALID, SUBSCRIPTION_FAILURE_UNDIAGNOSED, SUBSCRIPTION_OPERATION_NONE)
}

const HandleRefreshSubscriptionRequest = (state, action) => {
    if (state[SUBSCRIPTION_OPERATION] !== SUBSCRIPTION_OPERATION_NONE) {
        return
    }

    if ((state[SUBSCRIPTION_STATUS] !== SUBSCRIPTION_STATUS_VALID) || (state[SUBSCRIPTION_INFO].subscriptionStatus === 'subscription-status-inactive')) {
        UpdateSubscriptionState(state, state[SUBSCRIPTION_STATUS], state[SUBSCRIPTION_INFO],
            SUBSCRIPTION_FAILURE_NONE, SUBSCRIPTION_OPERATION_RETRIEVE_SUBSCRIPTION)
        RetrieveBackendSubscription(action.payload, HandleBackendRetrieveSubscriptionResponse)
    }
}

const HandleInvalidateSubscriptionRequest = (state) => {
    UpdateSubscriptionState(state, SUBSCRIPTION_STATUS_INVALID, SUBSCRIPTION_INFO_INVALID, SUBSCRIPTION_FAILURE_NONE, SUBSCRIPTION_OPERATION_NONE)
}

const subscriptionReducer = createReducer(defaultState, (builder) => {
    builder.addCase(retrieveSubscriptionRequestAction, (state, action) => {
        HandleRetrieveSubscriptionRequest(state, action)
    })

    builder.addCase(retrieveSubscriptionSuccessAction, (state, action) => {
        HandleRetrieveSubscriptionSuccess(state, action)
    })

    builder.addCase(retrieveSubscriptionFailureAction, (state, action) => {
        HandleRetrieveSubscriptionFailure(state, action)
    })

    builder.addCase(refreshSubscriptionRequestAction, (state, action) => {
        HandleRefreshSubscriptionRequest(state, action)
    })

    builder.addCase(invalidateSubscriptionRequestAction, (state, action) => {
        HandleInvalidateSubscriptionRequest(state, action)
    })

    builder.addDefaultCase(() => { })
})

// -------------------------------------------------------------------------------------------------
//  External reducer access
// -------------------------------------------------------------------------------------------------

export const GetSubscriptionReducer = () => {
    return subscriptionReducer
}
