import { useState, useContext } from 'react'
import { Container, Card, Row, Col, Image, ButtonGroup } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import ReactCardFlip from 'react-card-flip'
import PropTypes from 'prop-types'
import { MemoryMatchContext } from '../context/MemoryMatchContext'
import {
    GetNumSelectedMemoryMatchCards, GetSelectedMemoryMatchCards, GetNumRemainingMemoryMatchCards,
    MEMORY_MATCH_CARD_DATA_INDEX, MEMORY_MATCH_CARD_DATA_WORD, MEMORY_MATCH_CARD_DATA_IMAGE,
    MEMORY_MATCH_CARD_DATA_VISIBILITY, MEMORY_MATCH_CARD_DATA_IS_SELECTED, MEMORY_MATCH_CARD_DATA_IS_MATCHED,
    MEMORY_MATCH_CARD_VISIBILITY_IMAGE, MEMORY_MATCH_CARD_VISIBILITY_WORD, MEMORY_MATCH_CARD_VISIBILITY_REMOVED
} from '../state/MemoryMatchState'
import { GetActivityReturnPath } from '../state/ActivityState'
import { ActionButtonComponent } from './ActionButtonComponents'
import { GetWordText } from '../project/WordCodings'
import { GetWordImageUrl } from '../project/ProjectResources'
import './MemoryMatchComponents.css'

function GetMemoryMatchCardImageUrl(name) {
    return '/static/memory-match-images/card-image-' + name + '.svg'
}

export function MemoryMatchBoardComponent({ RestartGame }) {
    const { memoryMatchCards } = useContext(MemoryMatchContext)

    return (
        <>
            <Container className='memory-match-board-container'>
                <Row className='my-0 gx-2 gy-3'>
                    {memoryMatchCards.map(card => (
                        <Col xs={6} sm={3} md={2} className='mx-auto' key={card[MEMORY_MATCH_CARD_DATA_INDEX]}>
                            <MemoryMatchCardComponent
                                card={card}
                            ></MemoryMatchCardComponent>
                        </Col>
                    ))}
                </Row>
                <MemoryMatchResultComponent
                    RestartGame={RestartGame}
                />
            </Container>
        </>
    )
}

MemoryMatchBoardComponent.propTypes = {
    RestartGame: PropTypes.func.isRequired,
}

function MemoryMatchCardComponent({ card }) {
    const { memoryMatchCards, SetMemoryMatchCards } = useContext(MemoryMatchContext)

    const cardIndex = card[MEMORY_MATCH_CARD_DATA_INDEX]
    const cardWord = card[MEMORY_MATCH_CARD_DATA_WORD]
    const cardImage = card[MEMORY_MATCH_CARD_DATA_IMAGE]
    const cardVisibility = (GetNumRemainingMemoryMatchCards(memoryMatchCards) > 0) ? card[MEMORY_MATCH_CARD_DATA_VISIBILITY] : MEMORY_MATCH_CARD_VISIBILITY_IMAGE

    if ((cardVisibility !== MEMORY_MATCH_CARD_VISIBILITY_WORD) && (cardVisibility !== MEMORY_MATCH_CARD_VISIBILITY_IMAGE)) {
        return (
            <Card className='memory-match-card invisible'>
                <div className='memory-match-shape-card-image-div'>
                    <Image fluid className='memory-match-shape-card-image' />
                </div>
            </Card>
        )
    }

    const SelectMemoryMatchCard = cardIndex => {
        let copyOfCards = [...memoryMatchCards]

        if (GetNumSelectedMemoryMatchCards(copyOfCards) > 1) {
            return
        }

        if (copyOfCards[cardIndex][MEMORY_MATCH_CARD_DATA_IS_MATCHED]) {
            return
        }

        if (!copyOfCards[cardIndex][MEMORY_MATCH_CARD_DATA_IS_SELECTED]) {
            copyOfCards[cardIndex][MEMORY_MATCH_CARD_DATA_IS_SELECTED] = true
            copyOfCards[cardIndex][MEMORY_MATCH_CARD_DATA_VISIBILITY] = MEMORY_MATCH_CARD_VISIBILITY_IMAGE
            SetMemoryMatchCards(copyOfCards)
        }
    }

    return (
        <ReactCardFlip
            isFlipped={cardVisibility === MEMORY_MATCH_CARD_VISIBILITY_IMAGE}
            flipDirection='horizontal'
        >
            <Card
                className='memory-match-card bg-light'
                value={cardIndex}
                onClick={() => {
                    SelectMemoryMatchCard(cardIndex)
                }}
            >
                <div className='memory-match-word-card-image-div vstack mx-auto my-0'>
                    <Image fluid className='memory-match-word-card-image' src={GetWordImageUrl(cardWord)} alt={GetWordText(cardWord)} />
                </div>
                <h5 className='memory-match-word-card-text text-center my-0 p-0'>{GetWordText(cardWord)}</h5>
            </Card>
            <Card
                className='memory-match-card bg-light'
                value={cardIndex}
                onClick={() => {
                    SelectMemoryMatchCard(cardIndex)
                }}
            >
                <div className='memory-match-shape-card-image-div'>
                    <Image
                        fluid
                        className='memory-match-shape-card-image card-img-top'
                        src={GetMemoryMatchCardImageUrl(cardImage)}
                        alt={cardImage}
                    />
                </div>
            </Card>
        </ReactCardFlip>
    )
}

MemoryMatchCardComponent.propTypes = {
    card: PropTypes.object.isRequired,
}

function MemoryMatchResultComponent({ RestartGame }) {
    const {
        memoryMatchCards, SetMemoryMatchCards,
        showMemoryMatchCardMatchResult, SetShowMemoryMatchCardMatchResult,
        showMemoryMatchCardMismatchResult, SetShowMemoryMatchCardMismatchResult,
        showMemoryMatchEndOfGameResult, SetShowMemoryMatchEndOfGameResult,
        ClearShowMemoryMatchResults
    } = useContext(MemoryMatchContext)
    const selectedCards = GetSelectedMemoryMatchCards(memoryMatchCards)

    const ResetSelectedCards = () => {
        const index1 = selectedCards[0][MEMORY_MATCH_CARD_DATA_INDEX]
        const index2 = selectedCards[1][MEMORY_MATCH_CARD_DATA_INDEX]

        let copyOfCards = [...memoryMatchCards]
        copyOfCards[index1][MEMORY_MATCH_CARD_DATA_IS_SELECTED] = false
        copyOfCards[index2][MEMORY_MATCH_CARD_DATA_IS_SELECTED] = false
        copyOfCards[index1][MEMORY_MATCH_CARD_DATA_VISIBILITY] = MEMORY_MATCH_CARD_VISIBILITY_WORD
        copyOfCards[index2][MEMORY_MATCH_CARD_DATA_VISIBILITY] = MEMORY_MATCH_CARD_VISIBILITY_WORD

        SetMemoryMatchCards(copyOfCards)
    }

    const RemoveSelectedCards = () => {
        const index1 = selectedCards[0][MEMORY_MATCH_CARD_DATA_INDEX]
        const index2 = selectedCards[1][MEMORY_MATCH_CARD_DATA_INDEX]

        let copyOfCards = [...memoryMatchCards]
        copyOfCards[index1][MEMORY_MATCH_CARD_DATA_IS_SELECTED] = false
        copyOfCards[index2][MEMORY_MATCH_CARD_DATA_IS_SELECTED] = false
        copyOfCards[index1][MEMORY_MATCH_CARD_DATA_IS_MATCHED] = true
        copyOfCards[index2][MEMORY_MATCH_CARD_DATA_IS_MATCHED] = true
        copyOfCards[index1][MEMORY_MATCH_CARD_DATA_VISIBILITY] = MEMORY_MATCH_CARD_VISIBILITY_REMOVED
        copyOfCards[index2][MEMORY_MATCH_CARD_DATA_VISIBILITY] = MEMORY_MATCH_CARD_VISIBILITY_REMOVED

        SetMemoryMatchCards(copyOfCards)
    }

    const [showResultTimeoutActive, SetShowResultTimeoutActive] = useState(false)

    if ((memoryMatchCards.length > 0) && (memoryMatchCards.length % 2 === 0)) {  // consider an error message if card set is unplayable
        if (!showMemoryMatchCardMatchResult && !showMemoryMatchCardMismatchResult && !showMemoryMatchEndOfGameResult && !showResultTimeoutActive) {
            if (GetNumRemainingMemoryMatchCards(memoryMatchCards) === 0) {
                SetShowResultTimeoutActive(true)
                setTimeout(() => {
                    SetShowMemoryMatchEndOfGameResult(true)
                    SetShowResultTimeoutActive(false)
                }, 500)
            }
            else if (GetNumSelectedMemoryMatchCards(memoryMatchCards) > 1) {
                if (selectedCards[0][MEMORY_MATCH_CARD_DATA_IMAGE] === selectedCards[1][MEMORY_MATCH_CARD_DATA_IMAGE]) {
                    if (GetNumRemainingMemoryMatchCards(memoryMatchCards) === 2) {
                        SetShowResultTimeoutActive(true)
                        setTimeout(() => {
                            RemoveSelectedCards()
                            SetShowResultTimeoutActive(false)
                        }, 1000)
                    }
                    else {
                        SetShowResultTimeoutActive(true)
                        setTimeout(() => {
                            SetShowMemoryMatchCardMatchResult(true)
                            SetShowResultTimeoutActive(false)
                        }, 500)
                    }
                }
                else {
                    SetShowResultTimeoutActive(true)
                    setTimeout(() => {
                        SetShowMemoryMatchCardMismatchResult(true)
                        SetShowResultTimeoutActive(false)
                    }, 500)
                }
            }
        }
    }

    let content = <></>
    const navigate = useNavigate()

    if (showMemoryMatchEndOfGameResult) {
        content = (
            <div className='justify-content-center mx-auto'>
                <h3>You found all the matches. Well done!</h3>
                <ButtonGroup className='flex-wrap mx-auto'>
                    <ActionButtonComponent
                        text='Play Again'
                        width='8rem'
                        margins='0.5rem 1rem 0 0'
                        DoAction={() => {
                            ClearShowMemoryMatchResults()
                            RestartGame()
                        }}
                    />
                    <ActionButtonComponent
                        text='Exit'
                        width='5rem'
                        margins='0.5rem 0 0 1rem'
                        DoAction={() => {
                            ClearShowMemoryMatchResults()
                            navigate(GetActivityReturnPath())
                        }}
                    />
                </ButtonGroup>
            </div>
        )
    }
    else if (showMemoryMatchCardMatchResult) {
        content = (
            <div className='justify-content-center mx-auto'>
                <h3>You found a match. Nice job!</h3>
                <ActionButtonComponent
                    text='Find Another'
                    width='10rem'
                    margins='0.5rem 0 0 0'
                    DoAction={() => {
                        ClearShowMemoryMatchResults()
                        RemoveSelectedCards()
                    }}
                />
            </div>
        )
    }
    else if (showMemoryMatchCardMismatchResult) {
        content = (
            <div className='justify-content-center mx-auto'>
                <h3>Sorry, that's not a match.</h3>
                <ActionButtonComponent
                    text='Keep Trying'
                    width='8rem'
                    margins='0.5rem 0 0 0'
                    DoAction={() => {
                        ClearShowMemoryMatchResults()
                        ResetSelectedCards()
                    }}
                />
            </div>
        )
    }

    return (
        <div className='memory-match-result-div'>{content}</div>
    )
}

MemoryMatchResultComponent.propTypes = {
    RestartGame: PropTypes.func.isRequired,
}
