import { ButtonGroup, Form, FloatingLabel } from 'react-bootstrap'
import PropTypes from 'prop-types'
import './ListSelectComponent.css'

export function ListSelectComponent({ allLists, selectedList, SelectList }) {
    const HandleSelectChange = (event) => {
        event.preventDefault()
        SelectList(event.target.value)
    }

    return (
        <ButtonGroup className='vstack list-select-button-group'>
            <FloatingLabel className='list-select-label'>Select List</FloatingLabel>
            <Form.Select
                className='list-select-select'
                value={Object.keys(selectedList).length !== 0 ? selectedList.id : ''}
                onChange={event => HandleSelectChange(event)}
            >
                <option value='' disabled hidden>
                    Choose List
                </option>
                {allLists.map((list) => (
                    <option key={list.id} value={list.id}>
                        {list.name}
                    </option>
                ))}
            </Form.Select>
        </ButtonGroup>
    )
}

ListSelectComponent.propTypes = {
    allLists: PropTypes.array.isRequired,
    selectedList: PropTypes.object.isRequired,
    SelectList: PropTypes.func.isRequired,
}
