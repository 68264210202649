export const TARGET_SOUND_CH = 'CH'
export const TARGET_SOUND_F = 'F'
export const TARGET_SOUND_G = 'G'
export const TARGET_SOUND_J = 'J'
export const TARGET_SOUND_K = 'K'
export const TARGET_SOUND_L = 'L'
export const TARGET_SOUND_R = 'R'
export const TARGET_SOUND_S = 'S'
export const TARGET_SOUND_SH = 'SH'
export const TARGET_SOUND_TH = 'TH'
export const TARGET_SOUND_V = 'V'
export const TARGET_SOUND_Z = 'Z'

export function GetTargetSoundChoices() {
    return [TARGET_SOUND_CH, TARGET_SOUND_F, TARGET_SOUND_G, TARGET_SOUND_J, TARGET_SOUND_K, TARGET_SOUND_L,
            TARGET_SOUND_R, TARGET_SOUND_S, TARGET_SOUND_SH, TARGET_SOUND_TH, TARGET_SOUND_V, TARGET_SOUND_Z]
}

export const LEVEL_1 = 1
export const LEVEL_2 = 2
export const LEVEL_3 = 3

export function GetLevelChoices() {
    return [LEVEL_1, LEVEL_2, LEVEL_3]
}

export const POSITION_INITIAL = 'LocI'
export const POSITION_MEDIAL = 'LocM'
export const POSITION_FINAL = 'LocF'
export const POSITION_RECURRENT = 'Rec'
export const POSITION_BL_BLEND = 'BldBl'
export const POSITION_FL_BLEND = 'BldFl'
export const POSITION_GL_BLEND = 'BldGl'
export const POSITION_KL_BLEND = 'BldKl'
export const POSITION_PL_BLEND = 'BldPl'
export const POSITION_SK_BLEND = 'BldSk'
export const POSITION_SL_BLEND = 'BldSl'
export const POSITION_SM_BLEND = 'BldSm'
export const POSITION_SN_BLEND = 'BldSn'
export const POSITION_SP_BLEND = 'BldSp'
export const POSITION_ST_BLEND = 'BldSt'
export const POSITION_SW_BLEND = 'BldSw'
export const POSITION_COMPLEX_BLEND = 'BldCx'
export const POSITION_BR_BLEND = 'BldBr'
export const POSITION_DR_BLEND = 'BldDr'
export const POSITION_FR_BLEND = 'BldFr'
export const POSITION_GR_BLEND = 'BldGr'
export const POSITION_KR_BLEND = 'BldKr'
export const POSITION_PR_BLEND = 'BldPr'
export const POSITION_TR_BLEND = 'BldTr'
export const POSITION_VOCALIC_AIR = 'VocAir'
export const POSITION_VOCALIC_AR = 'VocAr'
export const POSITION_VOCALIC_EAR = 'VocEar'
export const POSITION_VOCALIC_ER = 'VocEr'
export const POSITION_VOCALIC_IRE = 'VocIre'
export const POSITION_VOCALIC_OR = 'VocOr'
export const POSITION_VOICELESS_INITIAL = 'VclI'
export const POSITION_VOICELESS_MEDIAL = 'VclM'
export const POSITION_VOICELESS_FINAL = 'VclF'
export const POSITION_VOICED_INITIAL = 'VcdI'
export const POSITION_VOICED_MEDIAL = 'VcdM'
export const POSITION_VOICED_FINAL = 'VcdF'

export function GetPositionChoices() {
    return {
        [POSITION_INITIAL]: 'Initial',
        [POSITION_MEDIAL]: 'Medial',
        [POSITION_FINAL]: 'Final',
        [POSITION_BL_BLEND]: 'BL Blends',
        [POSITION_FL_BLEND]: 'FL Blends',
        [POSITION_GL_BLEND]: 'GL Blends',
        [POSITION_KL_BLEND]: 'KL Blends',
        [POSITION_PL_BLEND]: 'PL Blends',
        [POSITION_SK_BLEND]: 'SK Blends',
        [POSITION_SL_BLEND]: 'SL Blends',
        [POSITION_SM_BLEND]: 'SM Blends',
        [POSITION_SN_BLEND]: 'SN Blends',
        [POSITION_SP_BLEND]: 'SP Blends',
        [POSITION_ST_BLEND]: 'ST Blends',
        [POSITION_SW_BLEND]: 'SW Blends',
        [POSITION_COMPLEX_BLEND]: 'Complex Blends',
        [POSITION_VOCALIC_AIR]: 'Vocalic AIR',
        [POSITION_VOCALIC_AR]: 'Vocalic AR',
        [POSITION_VOCALIC_EAR]: 'Vocalic EAR',
        [POSITION_VOCALIC_ER]: 'Vocalic ER',
        [POSITION_VOCALIC_IRE]: 'Vocalic IRE',
        [POSITION_VOCALIC_OR]: 'Vocalic OR',
        [POSITION_BR_BLEND]: 'BR Blends',
        [POSITION_DR_BLEND]: 'DR Blends',
        [POSITION_FR_BLEND]: 'FR Blends',
        [POSITION_GR_BLEND]: 'GR Blends',
        [POSITION_KR_BLEND]: 'KR Blends',
        [POSITION_PR_BLEND]: 'PR Blends',
        [POSITION_TR_BLEND]: 'TR Blends',
        [POSITION_RECURRENT]: 'Recurrent',
        [POSITION_VOICELESS_INITIAL]: 'Voiceless Initial',
        [POSITION_VOICELESS_MEDIAL]: 'Voiceless Medial',
        [POSITION_VOICELESS_FINAL]: 'Voiceless Final',
        [POSITION_VOICED_INITIAL]: 'Voiced Initial',
        [POSITION_VOICED_MEDIAL]: 'Voiced Medial',
        [POSITION_VOICED_FINAL]: 'Voiced Final',
    }
}

const validPositionChoices = {
    [TARGET_SOUND_CH]: [
        POSITION_INITIAL,
        POSITION_MEDIAL,
        POSITION_FINAL,
    ],
    [TARGET_SOUND_F]: [
        POSITION_INITIAL,
        POSITION_MEDIAL,
        POSITION_FINAL,
    ],
    [TARGET_SOUND_G]: [
        POSITION_INITIAL,
        POSITION_MEDIAL,
        POSITION_FINAL,
    ],
    [TARGET_SOUND_J]: [
        POSITION_INITIAL,
        POSITION_MEDIAL,
        POSITION_FINAL,
    ],
    [TARGET_SOUND_K]: [
        POSITION_INITIAL,
        POSITION_MEDIAL,
        POSITION_FINAL,
    ],
    [TARGET_SOUND_L]: [
        POSITION_INITIAL,
        POSITION_MEDIAL,
        POSITION_FINAL,
        POSITION_BL_BLEND,
        POSITION_FL_BLEND,
        POSITION_GL_BLEND,
        POSITION_KL_BLEND,
        POSITION_PL_BLEND,
        POSITION_SL_BLEND,
    ],
    [TARGET_SOUND_R]: [
        POSITION_INITIAL,
        POSITION_VOCALIC_AIR,
        POSITION_VOCALIC_AR,
        POSITION_VOCALIC_EAR,
        POSITION_VOCALIC_ER,
        POSITION_VOCALIC_IRE,
        POSITION_VOCALIC_OR,
        POSITION_BR_BLEND,
        POSITION_DR_BLEND,
        POSITION_FR_BLEND,
        POSITION_GR_BLEND,
        POSITION_KR_BLEND,
        POSITION_PR_BLEND,
        POSITION_TR_BLEND,
        POSITION_RECURRENT,
    ],
    [TARGET_SOUND_S]: [
        POSITION_INITIAL,
        POSITION_MEDIAL,
        POSITION_FINAL,
        POSITION_SK_BLEND,
        POSITION_SL_BLEND,
        POSITION_SM_BLEND,
        POSITION_SN_BLEND,
        POSITION_SP_BLEND,
        POSITION_ST_BLEND,
        POSITION_SW_BLEND,
        POSITION_COMPLEX_BLEND,
    ],
    [TARGET_SOUND_SH]: [
        POSITION_INITIAL,
        POSITION_MEDIAL,
        POSITION_FINAL,
    ],
    [TARGET_SOUND_TH]: [
        POSITION_VOICELESS_INITIAL,
        POSITION_VOICELESS_MEDIAL,
        POSITION_VOICELESS_FINAL,
        POSITION_VOICED_INITIAL,
        POSITION_VOICED_MEDIAL,
        POSITION_VOICED_FINAL,
    ],
    [TARGET_SOUND_V]: [
        POSITION_INITIAL,
        POSITION_MEDIAL,
        POSITION_FINAL,
    ],
    [TARGET_SOUND_Z]: [
        POSITION_INITIAL,
        POSITION_MEDIAL,
        POSITION_FINAL,
    ],
}

export function GetValidPositionChoices() {
    return validPositionChoices
}

export function AreTargetSoundAndPositionValid(targetSound, position) {
    return targetSound in validPositionChoices && validPositionChoices[targetSound].includes(position)
}

export function IsPositionABlend(position) {
    return ((position === POSITION_BL_BLEND) || (position === POSITION_FL_BLEND) || (position === POSITION_GL_BLEND) || (position === POSITION_KL_BLEND) || (position === POSITION_PL_BLEND) ||
        (position === POSITION_SK_BLEND) || (position === POSITION_SL_BLEND) || (position === POSITION_SM_BLEND) || (position === POSITION_SN_BLEND) || (position === POSITION_SP_BLEND) ||
        (position === POSITION_ST_BLEND) || (position === POSITION_SW_BLEND) || (position === POSITION_BR_BLEND) || (position === POSITION_DR_BLEND) || (position === POSITION_FR_BLEND) ||
        (position === POSITION_GR_BLEND) || (position === POSITION_KR_BLEND) || (position === POSITION_PR_BLEND) || (position === POSITION_TR_BLEND) || (position === POSITION_COMPLEX_BLEND))
}

export function IsPositionVocalic(position) {
    return ((position === POSITION_VOCALIC_AIR) || (position === POSITION_VOCALIC_AR) || (position === POSITION_VOCALIC_EAR) ||
        (position === POSITION_VOCALIC_ER) || (position === POSITION_VOCALIC_IRE) || (position === POSITION_VOCALIC_OR))
}

export const WORD_SET_CORE = 'WordSet/Core'
export const WORD_SET_EXTENDED = 'WordSet/Extended'
export const WORD_SET_ALL = 'WordSet/All'
