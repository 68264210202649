import { Container, Row, Col, Button, Card, Image } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import { LOGIN_SIGNUP_PATH } from '../App'
import { Header } from '../components/Header'
import { Footer } from '../components/Footer'
import { GetSiteImageUrl } from '../project/ProjectResources'
import '../style/LandingPageStyle.css'

export function LandingPage() {
    const navigate = useNavigate()

    return <>
        <Header />
        <Container className='section-container bg-light py-4'>
            <div className='h1 text-center w-75 mx-auto mt-2 mb-4'>
                Meet students where they are to provide easy, efficient, and effective articulation therapy.
            </div>
            <div className='text-center mx-auto mt-4 mb-5'>
                <Button
                    className='signup-button'
                    onClick={() => navigate(LOGIN_SIGNUP_PATH, { state: { tab: 'signup', subscription: 'month' } })}>
                    Sign me up!
                </Button>
            </div>
            <div className='mb-4'>
                <Row>
                    <Col xs={6} lg={3} className='panel-image-column'>
                        <Image fluid src={GetSiteImageUrl('landing-page-panel-1.svg')} style={{ width: '100%' }}></Image>
                    </Col>
                    <Col xs={6} lg={3} className='panel-image-column'>
                        <Image fluid src={GetSiteImageUrl('landing-page-panel-2.svg')} style={{ width: '100%' }}></Image>
                    </Col>
                    <Col xs={6} lg={3} className='panel-image-column'>
                        <Image fluid src={GetSiteImageUrl('landing-page-panel-3.svg')} style={{ width: '100%' }}></Image>
                    </Col>
                    <Col xs={6} lg={3} className='panel-image-column'>
                        <Image fluid src={GetSiteImageUrl('landing-page-panel-4.svg')} style={{ width: '100%' }}></Image>
                    </Col>
                </Row>
            </div>
            <div className='mt-4'>
                <h2 className='text-center'>Plan options:</h2>
                <div className='d-flex justify-content-center mt-3 mb-4'>
                    <PricingCardComponent color='var(--dark-blue)'>
                        <h5 className='text-center'>MONTHLY</h5>
                        <h1 className='text-center'>$6</h1>
                        <p className='text-center mb-0'>PER MONTH</p>
                        <div className='text-center mx-3 mt-3 mb-4'>
                            <Button
                                className='trial-plan-button monthly-trial-plan-button'
                                onClick={() => navigate(LOGIN_SIGNUP_PATH, { state: { tab: 'signup', subscription: 'month' } })}>
                                START MY FREE TRIAL
                            </Button>
                        </div>
                    </PricingCardComponent>
                    <PricingCardComponent color='var(--dark-orange)'>
                        <h5 className='text-center'>ANNUAL</h5>
                        <h1 className='text-center'>$39</h1>
                        <p className='text-center mb-0'>PER YEAR</p>
                        <div className='text-center mx-3 mt-3 mb-4'>
                            <Button
                                className='trial-plan-button annual-trial-plan-button'
                                onClick={() => navigate(LOGIN_SIGNUP_PATH, { state: { tab: 'signup', subscription: 'year' } })}>
                                START MY FREE TRIAL
                            </Button>
                        </div>
                    </PricingCardComponent>
                </div>
                <h5 className='text-center mt-4'>14 DAY FREE TRIAL</h5>
                <h5 className='text-center'>NO CREDIT CARD REQUIRED</h5>
                <h5 className='text-center'>CANCEL ANYTIME</h5>
            </div>
            <div className='mt-2 me-5 text-end'>
                <a href="mailto:support@levelupartic.com" style={{ color: 'var(--dark-blue)', textDecoration: 'none' }}>
                    <h5>Contact us</h5>
                </a>
            </div>
        </Container >
        <Footer />
    </>
}

function PricingCardComponent({ color, children }) {
    return (
        <Card className='pricing-card mx-4' style={{ borderColor: color }}>
            {children}
        </Card>
    )
}
