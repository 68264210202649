import { useState, useEffect, useCallback, useContext } from 'react'
import { Container, Image } from 'react-bootstrap'
import { useNavigate, useLocation } from 'react-router-dom'
import { LOGIN_SIGNUP_PATH, SUBSCRIPTION_PATH } from '../App'
import { GetSiteImageUrl } from '../project/ProjectResources'
import { BACKEND_STATUS_SUCCESS, BACKEND_STATUS_FAILURE } from '../project/BackendResources'
import { UpdateBackendList } from '../project/BackendListOperations'
import { START_PAGE_SAVED_LIST_TAB, START_PAGE_STORED_SELECTED_TAB } from './StartPage'
import { Header } from '../components/Header'
import { Footer } from '../components/Footer'
import {
    ListOperationTabRowComponent,
    ListOperationTabComponent,
    LIST_OPERATION_MESSAGE_TYPE_NONE,
    LIST_OPERATION_MESSAGE_TYPE_SUCCESS,
    LIST_OPERATION_MESSAGE_TYPE_ERROR,
} from '../components/ListOperationComponents'
import { ListOperationNameComponent } from '../components/ListOperationNameComponents'
import { ListOperationAddWordsComponent } from '../components/ListOperationAddWordsComponents'
import { ListOperationRemoveWordsComponent } from '../components/ListOperationRemoveWordsComponents'
import { UserAuthStateChangeListener, IsUserLoggedIn } from '../state/UserAuthState'
import { IsUserSubscribed } from '../state/SubscriptionState'
import { SelectListId } from '../state/ListsState'
import { ListEditContext, ListEditProvider } from '../context/ListEditContext'

const MESSAGE_TYPE_NONE = LIST_OPERATION_MESSAGE_TYPE_NONE
const MESSAGE_TYPE_SUCCESS = LIST_OPERATION_MESSAGE_TYPE_SUCCESS
const MESSAGE_TYPE_ERROR = LIST_OPERATION_MESSAGE_TYPE_ERROR

const STEP_MY_LIST = 'ListEditStep/MyList'
const STEP_ADD_WORDS = 'ListEditStep/AddWords'
const STEP_REMOVE_WORDS = 'ListEditStep/RemoveWords'

const COLOR_MY_LIST = 'dark-aqua'
const COLOR_ADD_WORDS = 'dark-orange'
const COLOR_REMOVE_WORDS = 'light-green'

export function ListEditPage() {
    return (
        <ListEditProvider>
            <ListEditPageContent />
        </ListEditProvider>
    )
}

export function ListEditPageContent() {
    const {
        listId, SetListId,
        listName, SetListName,
        listWords, SetListWords,
        ClearSearchParams,
    } = useContext(ListEditContext)

    const { state } = useLocation()

    useEffect(() => {
        if (state && state['list']) {
            const list = state['list']
            SetListId(list['id'])
            SetListName(list['name'])
            SetListWords(list['words'])
        }
    }, [state, SetListId, SetListName, SetListWords])

    const navigate = useNavigate()

    const [userStateChangeString, SetUserStateChangeString] = useState('')

    useEffect(() => {
        if (!IsUserLoggedIn()) {
            navigate(LOGIN_SIGNUP_PATH)
        }
        else if (!IsUserSubscribed()) {
            navigate(SUBSCRIPTION_PATH)
        }
    }, [userStateChangeString, navigate])

    const HandleUserStateChange = useCallback((listenerString) => {
        SetUserStateChangeString(listenerString)
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const [messageType, SetMessageType] = useState(MESSAGE_TYPE_NONE)
    const [message, SetMessage] = useState('')
    useEffect(() => { }, [messageType, message])

    const [step, SetStep] = useState(STEP_MY_LIST)

    useEffect(() => {
        if (step !== STEP_ADD_WORDS) {
            ClearSearchParams()
        }
    }, [step, ClearSearchParams])

    const tabRowContent =
        <ListOperationTabRowComponent>
            <ListOperationTabComponent
                color={COLOR_MY_LIST}
                text='My List'
                isActive={step === STEP_MY_LIST}
                ClickAction={() => SetStep(STEP_MY_LIST)}
            />
            <ListOperationTabComponent
                color={COLOR_ADD_WORDS}
                text='Add Words'
                isActive={step === STEP_ADD_WORDS}
                ClickAction={() => SetStep(STEP_ADD_WORDS)}
            />
            <ListOperationTabComponent
                color={COLOR_REMOVE_WORDS}
                text='Remove Words'
                isActive={step === STEP_REMOVE_WORDS}
                ClickAction={() => SetStep(STEP_REMOVE_WORDS)}
            />
        </ListOperationTabRowComponent>

    const HandleBackendListResponse = (status, response) => {
        if (status === BACKEND_STATUS_FAILURE) {
            SetMessageType(MESSAGE_TYPE_ERROR)
            SetMessage('A failure occurred. The list was not updated.')
        } else if (status === BACKEND_STATUS_SUCCESS) {
            SelectListId(response['id'])
            sessionStorage.setItem(START_PAGE_STORED_SELECTED_TAB, START_PAGE_SAVED_LIST_TAB)
            SetMessageType(MESSAGE_TYPE_SUCCESS)
            SetMessage('The list was updated successfully.')
        }
    }

    const [instructionsModalVisibility, SetInstructionsModalVisibility] = useState(false)

    const controlAreaContent =
        <Container className='list-operation-control-container'>
            <Container className='vstack list-operation-control-icon-container'>
                <Image fluid className='list-operation-control-icon'
                    src={GetSiteImageUrl('question-square-fill-dark-blue.svg')}
                    onClick={() => { SetInstructionsModalVisibility(true) }} />
            </Container>
        </Container>

    const modalProps = {
        showModal: instructionsModalVisibility,
        HandleModalClose: () => SetInstructionsModalVisibility(false)
    }

    let listOperationComponent = <></>

    switch (step) {
        case STEP_MY_LIST:
        default:
            {
                const actionAreaProps = {
                    color: COLOR_MY_LIST,
                }

                listOperationComponent =
                    <ListOperationNameComponent
                        tabRowContent={tabRowContent}
                        controlAreaContent={controlAreaContent}
                        UpdateListName={newListName => {
                            SetListName(newListName)
                            UpdateBackendList(listId, newListName, listWords, HandleBackendListResponse)
                        }}
                        actionAreaProps={actionAreaProps}
                        modalProps={modalProps}
                    />
            }
            break

        case STEP_ADD_WORDS:
            {
                const actionAreaProps = {
                    color: COLOR_ADD_WORDS,
                }

                listOperationComponent =
                    <ListOperationAddWordsComponent
                        tabRowContent={tabRowContent}
                        controlAreaContent={controlAreaContent}
                        actionAreaProps={actionAreaProps}
                        UpdateListWords={newListWords => {
                            SetListWords(newListWords)
                            UpdateBackendList(listId, listName, newListWords, HandleBackendListResponse)
                        }}
                        modalProps={modalProps}
                    />
            }
            break

        case STEP_REMOVE_WORDS:
            {
                const actionAreaProps = {
                    color: COLOR_REMOVE_WORDS,
                }

                listOperationComponent =
                    <ListOperationRemoveWordsComponent
                        tabRowContent={tabRowContent}
                        controlAreaContent={controlAreaContent}
                        actionAreaProps={actionAreaProps}
                        UpdateListWords={newListWords => {
                            SetListWords(newListWords)
                            UpdateBackendList(listId, listName, newListWords, HandleBackendListResponse)
                        }}
                        modalProps={modalProps}
                    />
            }
            break
    }

    return (
        <>
            <UserAuthStateChangeListener callback={HandleUserStateChange} />
            <Header />
            <Container className='section-container bg-light'>
                {listOperationComponent}
            </Container >
            <Footer />
        </>
    )
}
