import { useState, useEffect } from 'react'
import { Container } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import { LOGIN_SIGNUP_PATH, SUBSCRIPTION_PATH } from '../App'
import { SubscribeActionListener, GetStringifiedState, FLASHCARDS_REDUCER, DEFAULT_STRINGIFIED_STATE } from '../state/ReduxStore'
import {
    SetFlashcardsWordList,
    ForwardFlashcardsWord,
    ReverseFlashcardsWord,
    SetFlashcardsPhraseLevel,
    IsFlashcardsWordListEmpty,
    IsFlashcardsWordFirstInList,
    IsFlashcardsWordLastInList,
    GetFlashcardsActiveWordKey,
    GetFlashcardsActiveTargetSoundAndPosition,
    GetFlashcardsActiveTargetSound,
    GetFlashcardsActivePosition,
    GetFlashcardsActivePhrases,
    GetFlashcardsPhraseLevel,
} from '../state/FlashcardsState'
import { GetActivityWordList } from '../state/ActivityState'
import { IsUserLoggedIn } from '../state/UserAuthState'
import { IsUserSubscribed } from '../state/SubscriptionState'
import {
    FlashcardsLevelTabComponent,
    FlashcardCardComponent,
    FlashcardFwdRevComponent,
    FlashcardPhraseComponent,
    FlashcardTargetSoundAndPositionTextComponent,
} from '../components/FlashcardComponents'
import { ExitControlIconComponent } from '../components/ActivityControlComponents'
import { GetWordPhrase, PHRASE_LEVEL_WORD_ONLY, PHRASE_LEVEL_A, PHRASE_LEVEL_B, PHRASE_LEVEL_C, PHRASE_LEVEL_D } from '../project/WordPhrases'
import { DisableScrollbar } from '../project/Display'
import { GenerateRandomString } from '../project/Utils'
import '../style/FlashcardsStyle.css'

export function FlashcardsPage() {
    const [stringifiedState, SetStringifiedState] = useState(DEFAULT_STRINGIFIED_STATE)
    useEffect(() => { }, [stringifiedState])

    const HandleActionNotification = () => {
        SetStringifiedState(GetStringifiedState(FLASHCARDS_REDUCER))
    }

    const ForceRender = () => {
        SetStringifiedState(GenerateRandomString(10))
    }

    const navigate = useNavigate()

    useEffect(() => {
        if (!IsUserLoggedIn()) {
            navigate(LOGIN_SIGNUP_PATH)
        }
        else if (!IsUserSubscribed()) {
            navigate(SUBSCRIPTION_PATH)
        }

        DisableScrollbar()
        SetStringifiedState(DEFAULT_STRINGIFIED_STATE)
        SubscribeActionListener(HandleActionNotification)
        SetFlashcardsWordList(GetActivityWordList())
        ForceRender()
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    let boardContainerStyle = { borderColor: 'var(--light-aqua)' }

    switch (GetFlashcardsPhraseLevel()) {
        case PHRASE_LEVEL_WORD_ONLY:
        default:
            boardContainerStyle = { borderColor: 'var(--light-aqua)' }
            break

        case PHRASE_LEVEL_A:
            boardContainerStyle = { borderColor: 'var(--dark-aqua)' }
            break

        case PHRASE_LEVEL_B:
            boardContainerStyle = { borderColor: 'var(--dark-blue)' }
            break

        case PHRASE_LEVEL_C:
            boardContainerStyle = { borderColor: 'var(--dark-orange)' }
            break

        case PHRASE_LEVEL_D:
            boardContainerStyle = { borderColor: 'var(--light-green)' }
            break
    }

    const GetPhraseLevelColor = (level) => {
        switch (level) {
            case PHRASE_LEVEL_WORD_ONLY:
                return 'light-aqua'

            case PHRASE_LEVEL_A:
                return 'dark-aqua'

            case PHRASE_LEVEL_B:
                return 'dark-blue'

            case PHRASE_LEVEL_C:
                return 'dark-orange'

            case PHRASE_LEVEL_D:
                return 'light-green'

            default:
                return 'dark-blue'
        }
    }

    return (
        <Container className='section-container bg-light'>
            <div className='activity-page-div'>
                <Container className='flashcards-control-container'></Container>
                <Container className='vstack w-75 mt-1 px-4 py-4'>
                    <div className='flashcards-level-tab-row'>
                        <FlashcardsLevelTabComponent
                            color='light-aqua'
                            text='Word'
                            isActive={GetFlashcardsPhraseLevel() === PHRASE_LEVEL_WORD_ONLY}
                            DoAction={() => { SetFlashcardsPhraseLevel(PHRASE_LEVEL_WORD_ONLY) }} />
                        <FlashcardsLevelTabComponent
                            color='dark-aqua'
                            text='Level A'
                            isActive={GetFlashcardsPhraseLevel() === PHRASE_LEVEL_A}
                            DoAction={() => { SetFlashcardsPhraseLevel(PHRASE_LEVEL_A) }} />
                        <FlashcardsLevelTabComponent
                            color='dark-blue'
                            text='Level B'
                            isActive={GetFlashcardsPhraseLevel() === PHRASE_LEVEL_B}
                            DoAction={() => { SetFlashcardsPhraseLevel(PHRASE_LEVEL_B) }} />
                        <FlashcardsLevelTabComponent
                            color='dark-orange'
                            text='Level C'
                            isActive={GetFlashcardsPhraseLevel() === PHRASE_LEVEL_C}
                            DoAction={() => { SetFlashcardsPhraseLevel(PHRASE_LEVEL_C) }} />
                        <FlashcardsLevelTabComponent
                            color='light-green'
                            text='Level D'
                            isActive={GetFlashcardsPhraseLevel() === PHRASE_LEVEL_D}
                            DoAction={() => { SetFlashcardsPhraseLevel(PHRASE_LEVEL_D) }} />
                    </div>
                    {!IsFlashcardsWordListEmpty() && (
                        <>
                            <Container className='flashcards-board-container w-100' style={boardContainerStyle}>
                                <div className='d-flex flex-wrap flex-nowrap flex-row mt-3 mb-1 g-2'>
                                    <FlashcardFwdRevComponent
                                        direction='REV'
                                        color={GetPhraseLevelColor(GetFlashcardsPhraseLevel())}
                                        showComponent={!IsFlashcardsWordFirstInList()}
                                        DoAction={() => {
                                            ReverseFlashcardsWord()
                                        }}
                                    />
                                    <FlashcardCardComponent wordKey={GetFlashcardsActiveWordKey()} />
                                    <FlashcardFwdRevComponent
                                        direction='FWD'
                                        color={GetPhraseLevelColor(GetFlashcardsPhraseLevel())}
                                        showComponent={!IsFlashcardsWordLastInList()}
                                        DoAction={() => {
                                            ForwardFlashcardsWord()
                                        }}
                                    />
                                </div>
                                <FlashcardPhraseComponent
                                    phrase={GetWordPhrase(
                                        GetFlashcardsActivePhrases(),
                                        GetFlashcardsActiveWordKey(),
                                        GetFlashcardsActiveTargetSound(),
                                        GetFlashcardsActivePosition(),
                                        GetFlashcardsPhraseLevel()
                                    )}
                                    color={GetPhraseLevelColor(GetFlashcardsPhraseLevel())}
                                />
                            </Container>
                            <div>
                                <FlashcardTargetSoundAndPositionTextComponent
                                    targetSoundAndPosition={GetFlashcardsActiveTargetSoundAndPosition()}
                                />
                            </div>
                        </>
                    )}
                </Container>
                <Container className='flashcards-control-container'>
                    <Container className='vstack flashcards-exit-icon-container'>
                        <ExitControlIconComponent
                            imageSize='25%'
                            CleanUpActivity={() => {
                                SetFlashcardsPhraseLevel(PHRASE_LEVEL_WORD_ONLY)
                            }}
                        />
                    </Container>
                </Container>
            </div>
        </Container>
    )
}
