import { useEffect, useContext } from 'react'
import { Container, Card, Image, ButtonGroup } from 'react-bootstrap'
import ReactCardFlip from 'react-card-flip'
import PropTypes from 'prop-types'
import { TicTacToeContext } from '../context/TicTacToeContext'
import {
    GetTicTacToeBoardSize,
    GetTicTacToeGameMove,
    MakeTicTacToeGameMove,
    TIC_TAC_TOE_PLAYER_X,
    TIC_TAC_TOE_PLAYER_O,
    GetTicTacToeWinningMoves,
} from '../state/TicTacToeState'
import { ActionButtonComponent } from './ActionButtonComponents'
import { GetWordText } from '../project/WordCodings'
import { GetWordImageUrl, GetSiteImageUrl } from '../project/ProjectResources'
import { CreateIndexArray } from '../project/Utils'
import './TicTacToeComponents.css'

export function TicTacToeBoardComponent({ words, RestartGame, ExitGame }) {
    const { gameOver } = useContext(TicTacToeContext)

    const rowIndexArray = CreateIndexArray(GetTicTacToeBoardSize())
    const colIndexArray = CreateIndexArray(GetTicTacToeBoardSize())

    return (
        <>
            <Container className='tic-tac-toe-board-container'>
                {rowIndexArray.map((rowIndex) => (
                    <div className='d-flex flex-nowrap justify-content-center mx-auto' key={rowIndex}>
                        {colIndexArray.map((colIndex) => (
                            <TicTacToeSquareComponent rowIndex={rowIndex} colIndex={colIndex} key={colIndex}>
                                <ReactCardFlip
                                    isFlipped={GetTicTacToeGameMove(rowIndex, colIndex) !== ''}
                                    flipDirection='horizontal'
                                >
                                    <TicTacToeCardComponent rowIndex={rowIndex} colIndex={colIndex} words={words} />
                                    <TicTacToePlayerMoveComponent rowIndex={rowIndex} colIndex={colIndex} />
                                </ReactCardFlip>
                            </TicTacToeSquareComponent>
                        ))}
                    </div>
                ))}
                {gameOver
                    ?
                    <div className='d-flex justify-content-center mx-auto'>
                        <ButtonGroup className='flex-wrap mx-auto'>
                            <ActionButtonComponent
                                text='Play Again'
                                width='8rem'
                                margins='2rem 1rem 0.5rem 0'
                                DoAction={() => RestartGame()}
                            />
                            <ActionButtonComponent
                                text='Exit'
                                width='5rem'
                                margins='2rem 0 0.5rem 1rem'
                                DoAction={() => ExitGame()}
                            />
                        </ButtonGroup>
                    </div>
                    :
                    <div className='mb-2' />
                }
            </Container>
        </>
    )
}

TicTacToeBoardComponent.propTypes = {
    words: PropTypes.arrayOf(PropTypes.string).isRequired,
    RestartGame: PropTypes.func.isRequired,
    ExitGame: PropTypes.func.isRequired,
}

function TicTacToeSquareComponent({ rowIndex, colIndex, children }) {
    const maxIndex = GetTicTacToeBoardSize() - 1
    let containerClass = 'tic-tac-toe-square'

    if (rowIndex === 0) {
        containerClass += ' tic-tac-toe-square-top-row'
    } else if (rowIndex === maxIndex) {
        containerClass += ' tic-tac-toe-square-bottom-row'
    } else {
        containerClass += ' tic-tac-toe-square-interior-row'
    }

    if (colIndex === 0) {
        containerClass += ' tic-tac-toe-square-left-column'
    } else if (colIndex === maxIndex) {
        containerClass += ' tic-tac-toe-square-right-column'
    } else {
        containerClass += ' tic-tac-toe-square-interior-column'
    }

    return <Container className={containerClass}>{children}</Container>
}

TicTacToeSquareComponent.propTypes = {
    rowIndex: PropTypes.number.isRequired,
    colIndex: PropTypes.number.isRequired,
    children: PropTypes.node,
}

TicTacToeSquareComponent.defaultProps = { children: '' }

const GetWordIndex = (rowIndex, colIndex) => {
    return rowIndex * GetTicTacToeBoardSize() + colIndex
}

function TicTacToeCardComponent({ rowIndex, colIndex, words }) {
    const wordIndex = GetWordIndex(rowIndex, colIndex)

    if (!words || words.length <= wordIndex) {
        return <></>
    }

    const wordKey = words[wordIndex]

    return (
        <Card
            className='word-card tic-tac-toe-card bg-light'
            value={[rowIndex, colIndex]}
            onClick={() => {
                MakeTicTacToeGameMove(rowIndex, colIndex)
            }}
        >
            <div className='tic-tac-toe-card-image-div vstack mx-auto my-0'>
                <Image fluid className='tic-tac-toe-card-image' src={GetWordImageUrl(wordKey)} alt={GetWordText(wordKey)} />
            </div>
            <h5 className='tic-tac-toe-card-text text-center my-0 p-0'>{GetWordText(wordKey)}</h5>
        </Card>
    )
}

TicTacToeCardComponent.propTypes = {
    rowIndex: PropTypes.number.isRequired,
    colIndex: PropTypes.number.isRequired,
    words: PropTypes.arrayOf(PropTypes.string).isRequired,
}

const IsMoveInWinningMoves = (move, winningMoves) => {
    for (let winningMove of winningMoves) {
        if (move[0] === winningMove[0] && move[1] === winningMove[1]) {
            return true
        }
    }

    return false
}

function TicTacToePlayerMoveComponent({ rowIndex, colIndex }) {
    const { gameWon, SetGameWon } = useContext(TicTacToeContext)

    useEffect(() => { }, [gameWon])

    if (rowIndex >= GetTicTacToeBoardSize() || colIndex >= GetTicTacToeBoardSize()) {
        return
    }

    const playerMove = GetTicTacToeGameMove(rowIndex, colIndex)
    let imageNode = <></>

    if (playerMove !== '') {
        let imageFile = ''
        let imageAltText = ''

        if (playerMove === TIC_TAC_TOE_PLAYER_X) {
            imageAltText = 'X'

            if (IsMoveInWinningMoves([rowIndex, colIndex], GetTicTacToeWinningMoves()) && gameWon) {
                imageFile = 'white-x-in-blue-square.svg'
            } else {
                imageFile = 'x-lg-blue.svg'
            }
        } else if (playerMove === TIC_TAC_TOE_PLAYER_O) {
            imageAltText = 'O'

            if (IsMoveInWinningMoves([rowIndex, colIndex], GetTicTacToeWinningMoves()) && gameWon) {
                imageFile = 'white-circle-in-orange-square.svg'
            } else {
                imageFile = 'circle-orange.svg'
            }
        }

        if (IsMoveInWinningMoves([rowIndex, colIndex], GetTicTacToeWinningMoves())) {
            setTimeout(() => SetGameWon(true), 1000)
        }

        imageNode = <img className='img-fluid tic-tac-toe-xo-image' src={GetSiteImageUrl(imageFile)} alt={imageAltText} />
    }

    return <>{imageNode}</>
}

TicTacToePlayerMoveComponent.propTypes = {
    rowIndex: PropTypes.number.isRequired,
    colIndex: PropTypes.number.isRequired,
}
