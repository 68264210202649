import { ShuffleArray } from '../project/Utils'

export function GetMaxNumMemoryMatchCards(sourceWords) {
    return Math.min((sourceWords.length >> 1) << 1, 18)
}

const memoryMatchCardImages = [
    'Andrew', 'Artie', 'Charlie', 'Ella', 'Erin', 'Flo', 'Glen', 'Jake', 'Kate', 'Lucy', 'Luke', 'Ruby', 'Sam'
]

export const MEMORY_MATCH_CARD_DATA_INDEX = 'MemoryMatch/CardData/Index'
export const MEMORY_MATCH_CARD_DATA_WORD = 'MemoryMatch/CardData/Word'
export const MEMORY_MATCH_CARD_DATA_IMAGE = 'MemoryMatch/CardData/Image'
export const MEMORY_MATCH_CARD_DATA_VISIBILITY = 'MemoryMatch/CardData/Visibility'
export const MEMORY_MATCH_CARD_DATA_IS_SELECTED = 'MemoryMatch/CardData/IsSelected'
export const MEMORY_MATCH_CARD_DATA_IS_MATCHED = 'MemoryMatch/CardData/IsMatched'

export const MEMORY_MATCH_CARD_VISIBILITY_WORD = 'MemoryMatch/CardVisibility/Word'
export const MEMORY_MATCH_CARD_VISIBILITY_IMAGE = 'MemoryMatch/CardVisibility/Image'
export const MEMORY_MATCH_CARD_VISIBILITY_REMOVED = 'MemoryMatch/CardVisibility/Removed'

export function ResetMemoryMatchCards(sourceWords, numCards) {
    let shuffledWords = [...sourceWords]
    ShuffleArray(shuffledWords)
    shuffledWords = shuffledWords.slice(0, numCards)

    let shuffledImages = [...memoryMatchCardImages]
    ShuffleArray(shuffledImages)
    shuffledImages = shuffledImages.slice(0, numCards >> 1)
    shuffledImages = shuffledImages.concat(shuffledImages)
    ShuffleArray(shuffledImages)

    while (shuffledImages[0] === shuffledImages[1]) {
        ShuffleArray(shuffledImages)
    }

    let cards = []

    for (let i = 0; i < numCards; ++i) {
        const card = {
            [MEMORY_MATCH_CARD_DATA_INDEX]: i,
            [MEMORY_MATCH_CARD_DATA_WORD]: shuffledWords[i],
            [MEMORY_MATCH_CARD_DATA_IMAGE]: shuffledImages[i],
            [MEMORY_MATCH_CARD_DATA_VISIBILITY]: MEMORY_MATCH_CARD_VISIBILITY_WORD,
            [MEMORY_MATCH_CARD_DATA_IS_SELECTED]: false,
            [MEMORY_MATCH_CARD_DATA_IS_MATCHED]: false,
        }

        cards.push(card)
    }

    return cards
}

export function GetNumSelectedMemoryMatchCards(cards) {
    let numSelectedCards = 0

    for (let card of cards) {
        if (card[MEMORY_MATCH_CARD_DATA_IS_SELECTED]) {
            ++numSelectedCards
        }
    }

    return numSelectedCards
}

export function GetSelectedMemoryMatchCards(cards) {
    let selectedCards = []

    for (let card of cards) {
        if (card[MEMORY_MATCH_CARD_DATA_IS_SELECTED]) {
            selectedCards.push(card)
        }
    }

    return selectedCards
}

export function GetNumRemainingMemoryMatchCards(cards) {
    let numRemainingCards = 0

    for (let card of cards) {
        if (!card[MEMORY_MATCH_CARD_DATA_IS_MATCHED]) {
            ++numRemainingCards
        }
    }

    return numRemainingCards
}
