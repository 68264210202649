import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import { Provider } from 'react-redux'
import { reduxStore } from './state/ReduxStore'
import { HomePage } from './pages/HomePage'
import { LandingPage } from './pages/LandingPage'
import { StartPage } from './pages/StartPage'
import { LoginSignupPage } from './pages/LoginSignupPage'
import { PasswordResetRequestPage, PasswordResetEntryPage } from './pages/PasswordResetPages'
import { UserAccountPage } from './pages/UserAccountPage'
import { ListCreatePage } from './pages/ListCreatePage'
import { ListEditPage } from './pages/ListEditPage'
import { ListDeletePage } from './pages/ListDeletePage'
import { AboutPage } from './pages/AboutPage'
import { FlashcardsPage } from './pages/FlashcardsPage'
import { MemoryMatchPage } from './pages/MemoryMatchPage'
import { TicTacToePage } from './pages/TicTacToePage'
import { CopycatPage } from './pages/CopycatPage'
import { PickAPathPage } from './pages/PickAPathPage'
import { WhimprovsPage } from './pages/WhimprovsPage'
import { ControlCenterPage } from './pages/ControlCenterPages'
import { UserAuthTokenRefreshComponent } from './components/UserAuthComponents'
import { SubscriptionRefreshComponent } from './components/SubscriptionComponents'

export const HOME_PATH = '/'
export const LANDING_PATH = '/welcome'
export const START_PATH = '/start'
export const LOGIN_SIGNUP_PATH = '/login-signup'
export const PASSWORD_RESET_REQUEST_PATH = '/password-reset/request'
export const PASSWORD_RESET_ENTRY_PATH = '/password-reset/entry'
export const USER_ACCOUNT_PATH = '/user-account'
export const SUBSCRIPTION_PATH = '/user-account?tab=subscription'
export const LIST_CREATE_PATH = '/list/create'
export const LIST_EDIT_PATH = '/list/edit'
export const LIST_DELETE_PATH = '/list/delete'
export const FLASHCARDS_PATH = '/activities/flashcards'
export const MEMORY_MATCH_PATH = '/activities/memory-match'
export const TIC_TAC_TOE_PATH = '/activities/tic-tac-toe'
export const COPYCAT_PATH = '/activities/copycat'
export const PICK_A_PATH_PATH = '/activities/pick-a-path'
export const WHIMPROVS_PATH = '/activities/silly-stories'
export const ABOUT_PATH = '/about'
export const CONTROL_CENTER_PATH = '/control-center'

export function App() {
    return (
        <Provider store={reduxStore}>
            <UserAuthTokenRefreshComponent />
            <SubscriptionRefreshComponent />
            <Router>
                <Routes>
                    <Route exact path={HOME_PATH} element={<HomePage />} />
                    <Route exact path={LANDING_PATH} element={<LandingPage />} />
                    <Route exact path={START_PATH} element={<StartPage />} />
                    <Route exact path={LOGIN_SIGNUP_PATH} element={<LoginSignupPage />} />
                    <Route exact path={PASSWORD_RESET_REQUEST_PATH} element={<PasswordResetRequestPage />} />
                    <Route exact path={PASSWORD_RESET_ENTRY_PATH} element={<PasswordResetEntryPage />} />
                    <Route exact path={USER_ACCOUNT_PATH} element={<UserAccountPage />} />
                    <Route exact path={LIST_CREATE_PATH} element={<ListCreatePage />} />
                    <Route exact path={LIST_EDIT_PATH} element={<ListEditPage />} />
                    <Route exact path={LIST_DELETE_PATH} element={<ListDeletePage />} />
                    <Route exact path={FLASHCARDS_PATH} element={<FlashcardsPage />} />
                    <Route exact path={MEMORY_MATCH_PATH} element={<MemoryMatchPage />} />
                    <Route exact path={TIC_TAC_TOE_PATH} element={<TicTacToePage />} />
                    <Route exact path={COPYCAT_PATH} element={<CopycatPage />} />
                    <Route exact path={PICK_A_PATH_PATH} element={<PickAPathPage />} />
                    <Route exact path={WHIMPROVS_PATH} element={<WhimprovsPage />} />
                    <Route exact path={ABOUT_PATH} element={<AboutPage />} />
                    <Route exact path={CONTROL_CENTER_PATH} element={<ControlCenterPage />} />
                </Routes>
            </Router>
        </Provider>
    )
}
