import axios from 'axios'
import { AreTargetSoundAndPositionValid } from './SoundPositionLevelChoices'
import { GetWordPhrasesFileUrl } from './ProjectResources'
import { GetWordText } from './WordCodings'

export const PHRASE_LEVEL_WORD_ONLY = 'Word Only'
export const PHRASE_LEVEL_A = 'A'
export const PHRASE_LEVEL_B = 'B'
export const PHRASE_LEVEL_C = 'C'
export const PHRASE_LEVEL_D = 'D'

export const READ_WORD_PHRASES_STATUS_WORKING = 'working'
export const READ_WORD_PHRASES_STATUS_SUCCESS = 'success'
export const READ_WORD_PHRASES_STATUS_FAILURE = 'failure'

const GetAllPhraseLevels = () => {
    return [PHRASE_LEVEL_WORD_ONLY, PHRASE_LEVEL_A, PHRASE_LEVEL_B, PHRASE_LEVEL_C, PHRASE_LEVEL_D]
}

const GetWordOnlyPhrase = (wordKey) => {
    return '<' + GetWordText(wordKey) + '>'
}

export const GetDefaultWordPhrases = (wordKey) => {
    var wordPhrases = {}

    for (var phraseLevel of GetAllPhraseLevels()) {
        wordPhrases[phraseLevel] = GetWordOnlyPhrase(wordKey)
    }

    return wordPhrases
}

export async function ReadWordPhrases(wordKey, callback) {
    let axiosInstance = axios.create()
    let status = READ_WORD_PHRASES_STATUS_WORKING
    let wordPhrases = GetDefaultWordPhrases(wordKey)

    try {
        const fileContent = await axiosInstance.get(GetWordPhrasesFileUrl(wordKey))
        const lines = fileContent.data.split(/\r\n|\n/)

        for (let line of lines) {
            const fields = line.split('|')

            if (fields.length !== 5) {
                if (fields.length > 1) {
                    console.log('***** Error: Wrong number of pipes delimiters:', line)
                }

                continue
            }

            const wordKey = fields[0]
            const targetSound = fields[1]
            const position = fields[2]
            const phraseLevel = fields[3]
            const phraseText = fields[4]

            if (!AreTargetSoundAndPositionValid(targetSound, position)) {
                console.log('***** Error: Invalid target sound and position combination:', line)
                continue
            }

            if (!GetAllPhraseLevels().includes(phraseLevel)) {
                console.log('***** Error: Invalid phrase level:', line)
                continue
            }

            if (!(wordKey in wordPhrases)) {
                wordPhrases[wordKey] = {}
            }

            if (!(targetSound in wordPhrases[wordKey])) {
                wordPhrases[wordKey][targetSound] = {}
            }

            if (!(position in wordPhrases[wordKey][targetSound])) {
                wordPhrases[wordKey][targetSound][position] = {}
            }

            if (phraseLevel in wordPhrases[wordKey][targetSound][position]) {
                console.log(
                    '***** Error: Duplicate definition for wordKey, targetSound, position, phraseLevel:',
                    [wordKey, targetSound, position, phraseLevel].join('|')
                )

                continue
            }

            wordPhrases[wordKey][targetSound][position][phraseLevel] = phraseText
        }

        status = READ_WORD_PHRASES_STATUS_SUCCESS
    } catch (exception) {
        console.log('***** Error: Caught exception in ReadWordPhrases:', exception)
        status = READ_WORD_PHRASES_STATUS_FAILURE
    }

    callback(status, wordKey, wordPhrases)
}

export function IsWordPhraseDefined(wordPhrases, wordKey, targetSound, position, phraseLevel) {
    return (
        wordKey in wordPhrases &&
        targetSound in wordPhrases[wordKey] &&
        position in wordPhrases[wordKey][targetSound] &&
        phraseLevel in wordPhrases[wordKey][targetSound][position]
    )
}

export function GetWordPhrase(wordPhrases, wordKey, targetSound, position, phraseLevel) {
    if (!IsWordPhraseDefined(wordPhrases, wordKey, targetSound, position, phraseLevel)) {
        return GetWordOnlyPhrase(wordKey)
    }

    return wordPhrases[wordKey][targetSound][position][phraseLevel]
}
