import { createReducer, createAction } from '@reduxjs/toolkit'
import { GetReducerState, TIC_TAC_TOE_REDUCER, DispatchAction } from './ReduxStore'
import { ShuffleArray } from '../project/Utils'

// -------------------------------------------------------------------------------------------------
//  Internal state management
// -------------------------------------------------------------------------------------------------

const BOARD_SIZE = 'TicTacToeState/BoardSize'
const SOURCE_WORD_LIST = 'TicTacToeState/SourceWordList'
const GAME_WORD_LIST = 'TicTacToeState/GameWordList'
const FIRST_PLAYER = 'TicTacToeState/FirstPlayer'
const CURRENT_PLAYER = 'TicTacToeState/CurrentPlayer'
const GAME_MOVES = 'TicTacToeState/GameMoves'
const WINNING_PLAYER = 'TicTacToeState/WinningPlayer'
const WINNING_MOVES = 'TicTacToeState/WinningMoves'

const PLAYER_X = 'X'
const PLAYER_O = 'O'

const EmptyBoardGameMoves = (boardSize) => {
    let gameMoves = Array(boardSize)

    for (let i = 0; i < boardSize; ++i) {
        gameMoves[i] = Array(boardSize).fill('')
    }

    return gameMoves
}

const defaultState = {
    [BOARD_SIZE]: 3,
    [SOURCE_WORD_LIST]: [],
    [GAME_WORD_LIST]: [],
    [FIRST_PLAYER]: PLAYER_X,
    [CURRENT_PLAYER]: PLAYER_X,
    [GAME_MOVES]: EmptyBoardGameMoves(3),
    [WINNING_PLAYER]: '',
    [WINNING_MOVES]: [],
}

const GetBoardSize = () => {
    return GetReducerState(TIC_TAC_TOE_REDUCER)[BOARD_SIZE]
}

const GetGameWordList = () => {
    return GetReducerState(TIC_TAC_TOE_REDUCER)[GAME_WORD_LIST]
}

const GetFirstPlayer = () => {
    return GetReducerState(TIC_TAC_TOE_REDUCER)[FIRST_PLAYER]
}

const GetCurrentPlayer = () => {
    return GetReducerState(TIC_TAC_TOE_REDUCER)[CURRENT_PLAYER]
}

const GetGameMoves = () => {
    return GetReducerState(TIC_TAC_TOE_REDUCER)[GAME_MOVES]
}

const GetWinningPlayer = () => {
    return GetReducerState(TIC_TAC_TOE_REDUCER)[WINNING_PLAYER]
}

const GetWinningMoves = () => {
    return GetReducerState(TIC_TAC_TOE_REDUCER)[WINNING_MOVES]
}

// -------------------------------------------------------------------------------------------------
//  External state access
// -------------------------------------------------------------------------------------------------

export const TIC_TAC_TOE_PLAYER_X = PLAYER_X
export const TIC_TAC_TOE_PLAYER_O = PLAYER_O

export const GetInitialTicTacToeState = () => {
    return defaultState
}

export const GetTicTacToeBoardSize = () => {
    return GetBoardSize()
}

export const GetTicTacToeGameWordList = () => {
    return GetGameWordList()
}

export const GetTicTacToeGameMove = (rowIndex, colIndex) => {
    return GetGameMoves()[rowIndex][colIndex]
}

export const AreAnyTicTacToeMovesUnplayed = () => {
    const boardSize = GetBoardSize()
    const gameMoves = GetGameMoves()

    for (let i = 0; i < boardSize; ++i) {
        for (let j = 0; j < boardSize; ++j) {
            if (gameMoves[i][j] === '') {
                return true
            }
        }
    }

    return false
}

export const GetTicTacToeFirstPlayer = () => {
    return GetFirstPlayer()
}

export const GetTicTacToeCurrentPlayer = () => {
    return GetCurrentPlayer()
}

export const GetTicTacToeWinningPlayer = () => {
    return GetWinningPlayer()
}

export const IsTicTacToeGameOver = () => {
    return GetWinningPlayer() !== ''
}

export const GetTicTacToeWinningMoves = () => {
    return GetWinningMoves()
}

// -------------------------------------------------------------------------------------------------
//  Internal action management
// -------------------------------------------------------------------------------------------------

const RESET_GAME = 'TicTacToeAction/ResetGame'
const SET_BOARD_SIZE = 'TicTacToeAction/SetBoardSize'
const SET_SOURCE_WORD_LIST = 'TicTacToeAction/SetSourceWordList'
const SET_FIRST_PLAYER = 'TicTacToeAction/SetFirstPlayer'
const MAKE_GAME_MOVE = 'TicTacToeAction/MakeGameMove'

const resetGameAction = createAction(RESET_GAME)
const setBoardSizeAction = createAction(SET_BOARD_SIZE)
const setSourceWordListAction = createAction(SET_SOURCE_WORD_LIST)
const setFirstPlayerAction = createAction(SET_FIRST_PLAYER)
const makeGameMoveAction = createAction(MAKE_GAME_MOVE)

// -------------------------------------------------------------------------------------------------
//  External action access
// -------------------------------------------------------------------------------------------------

export const ResetTicTacToeGame = () => {
    DispatchAction(resetGameAction)
}

export const SetTicTacToeBoardSize = (boardSize) => {
    DispatchAction(setBoardSizeAction, boardSize)
}

export const SetTicTacToeSourceWordList = (wordList) => {
    DispatchAction(setSourceWordListAction, wordList)
}

export const SetTicTacToeFirstPlayer = (player) => {
    DispatchAction(setFirstPlayerAction, player)
}

export const MakeTicTacToeGameMove = (rowIndex, colIndex) => {
    DispatchAction(makeGameMoveAction, [rowIndex, colIndex])
}

// -------------------------------------------------------------------------------------------------
//  Internal reducer management
// -------------------------------------------------------------------------------------------------

const HandleResetGameReq = (state) => {
    const shuffledSourceWordList = [...state[SOURCE_WORD_LIST]]
    ShuffleArray(shuffledSourceWordList)

    const gameWordListLength = state[BOARD_SIZE] * state[BOARD_SIZE]
    let gameWordList = []

    while (gameWordList.length < gameWordListLength) {
        gameWordList.push(...shuffledSourceWordList)
    }

    gameWordList = gameWordList.slice(0, gameWordListLength)
    state[GAME_WORD_LIST] = gameWordList

    state[CURRENT_PLAYER] = state[FIRST_PLAYER]
    state[GAME_MOVES] = EmptyBoardGameMoves(state[BOARD_SIZE])
    state[WINNING_PLAYER] = ''
    state[WINNING_MOVES] = []
}

const HandleSetBoardSizeReq = (state, action) => {
    const boardSize = Number(action.payload)

    if (boardSize !== 3 && boardSize !== 4) {
        return
    }

    state[BOARD_SIZE] = boardSize
}

const HandleSetSourceWordListReq = (state, action) => {
    state[SOURCE_WORD_LIST] = [...action.payload]
}

const HandleSetFirstPlayerActionReq = (state, action) => {
    const player = action.payload

    if (player === PLAYER_O) {
        state[FIRST_PLAYER] = PLAYER_O
    }
    else {
        state[FIRST_PLAYER] = PLAYER_X
    }
}

const HandleMakeGameMoveReq = (state, action) => {
    const [rowIndex, colIndex] = action.payload
    const boardSize = state[BOARD_SIZE]

    if (state[WINNING_PLAYER] !== '') {
        return
    }

    if (rowIndex < 0 || rowIndex >= boardSize || colIndex < 0 || colIndex >= boardSize) {
        return
    }

    if (state[GAME_MOVES][rowIndex][colIndex] !== '') {
        return
    }

    if (state[CURRENT_PLAYER] === PLAYER_X) {
        state[GAME_MOVES][rowIndex][colIndex] = PLAYER_X
    } else {
        state[GAME_MOVES][rowIndex][colIndex] = PLAYER_O
    }

    const winningMoves = CheckForWinningMoves(boardSize, state[GAME_MOVES], state[CURRENT_PLAYER], [rowIndex, colIndex])

    if (winningMoves && winningMoves.length === boardSize) {
        state[WINNING_PLAYER] = state[CURRENT_PLAYER]
        state[WINNING_MOVES] = [...winningMoves]
    } else {
        state[CURRENT_PLAYER] = state[CURRENT_PLAYER] === PLAYER_X ? PLAYER_O : PLAYER_X
    }
}

const CheckForWinningMoves = (boardSize, playerMoves, player, playedSquare) => {
    const [rowIndex, colIndex] = playedSquare
    let winningMoves = []

    // Check row containing played square.
    for (let i = 0; i < boardSize; ++i) {
        if (playerMoves[rowIndex][i] === player) {
            winningMoves.push([rowIndex, i])
        } else {
            winningMoves = []
            break
        }
    }

    if (winningMoves.length === boardSize) {
        return winningMoves
    }

    // Check column containing played square.
    for (let i = 0; i < boardSize; ++i) {
        if (playerMoves[i][colIndex] === player) {
            winningMoves.push([i, colIndex])
        } else {
            winningMoves = []
            break
        }
    }

    if (winningMoves.length === boardSize) {
        return winningMoves
    }

    // Check forward diagonal containing played square.
    if (rowIndex === colIndex) {
        for (let i = 0; i < boardSize; ++i) {
            if (playerMoves[i][i] === player) {
                winningMoves.push([i, i])
            } else {
                winningMoves = []
                break
            }
        }

        if (winningMoves.length === boardSize) {
            return winningMoves
        }
    }

    // Check reverse diagonal containing played square.
    if (rowIndex === boardSize - colIndex - 1) {
        for (let i = 0; i < boardSize; ++i) {
            if (playerMoves[i][boardSize - i - 1] === player) {
                winningMoves.push([i, boardSize - i - 1])
            } else {
                winningMoves = []
                break
            }
        }

        if (winningMoves.length === boardSize) {
            return winningMoves
        }
    }

    // No winning squares.
    return null
}

const ticTacToeReducer = createReducer(defaultState, (builder) => {
    builder.addCase(resetGameAction, (state, action) => {
        HandleResetGameReq(state, action)
    })

    builder.addCase(setBoardSizeAction, (state, action) => {
        HandleSetBoardSizeReq(state, action)
    })

    builder.addCase(setSourceWordListAction, (state, action) => {
        HandleSetSourceWordListReq(state, action)
    })

    builder.addCase(setFirstPlayerAction, (state, action) => {
        HandleSetFirstPlayerActionReq(state, action)
    })

    builder.addCase(makeGameMoveAction, (state, action) => {
        HandleMakeGameMoveReq(state, action)
    })

    builder.addDefaultCase(() => { })
})

// -------------------------------------------------------------------------------------------------
//  External reducer access
// -------------------------------------------------------------------------------------------------

export const GetTicTacToeReducer = () => {
    return ticTacToeReducer
}
