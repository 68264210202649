import { useState, useEffect, useContext, useCallback } from 'react'
import { Container, ButtonGroup, Dropdown } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import { LOGIN_SIGNUP_PATH, SUBSCRIPTION_PATH } from '../App'
import { CopycatProvider, CopycatContext, CopycatListener } from '../context/CopycatContext'
import { CopycatBoardComponent } from '../components/CopycatComponents'
import { ExitControlIconComponent, RestartControlIconComponent, SoundControlIconComponent,
         HelpControlIconComponent, ActivityControlIconDropdownComponent } from '../components/ActivityControlComponents'
import { GetActivityWordList, GetActivityReturnPath } from '../state/ActivityState'
import { IsUserLoggedIn } from '../state/UserAuthState'
import { IsUserSubscribed } from '../state/SubscriptionState'
import { DisableScrollbar } from '../project/Display'
import { CreateIndexArray } from '../project/Utils'
import '../components/CopycatComponents.css'

export function CopycatPage() {
    return (
        <CopycatProvider>
            <CopycatPageContent />
        </CopycatProvider>
    )
}

export function CopycatPageContent() {
    const {
        copycatSourceWords, SetCopycatSourceWords,
        numCopycatCards, SetNumCopycatCards,
        copycatIsSoundOn, SetCopycatIsSoundOn,
        ResetCopycatContext
    } = useContext(CopycatContext)

    const DEFAULT_CONTEXT_STRING = ''
    const [contextString, SetContextString] = useState(DEFAULT_CONTEXT_STRING)

    useEffect(() => { }, [contextString])

    const HandleCopycatContextChange = useCallback(listenerString => {
        SetContextString(listenerString)
    }, [])

    const navigate = useNavigate()

    const ResetCopycatPage = () => {
        if ((numCopycatCards > 0) && (copycatSourceWords.length > 0)) {
            ResetCopycatContext()
        }
    }

    useEffect(() => {
        if (!IsUserLoggedIn()) {
            navigate(LOGIN_SIGNUP_PATH)
        }
        else if (!IsUserSubscribed()) {
            navigate(SUBSCRIPTION_PATH)
        }

        DisableScrollbar()
        SetContextString(DEFAULT_CONTEXT_STRING)

        const activityWords = [...GetActivityWordList()]

        if (activityWords.length < 3) {
            // TODO: Provide an error message.
            navigate(GetActivityReturnPath())
        }

        const numCardWords = Math.min(activityWords.length, 5)

        SetCopycatSourceWords(activityWords)
        SetNumCopycatCards(numCardWords)
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if ((numCopycatCards > 0) && (copycatSourceWords.length > 0)) {
            ResetCopycatContext()
        }
    }, [numCopycatCards, copycatSourceWords.length])  // eslint-disable-line react-hooks/exhaustive-deps

    const [instructionsModalVisibility, SetInstructionsModalVisibility] = useState(false)

    return (
        <>
            <CopycatListener SendContextChangeNotification={HandleCopycatContextChange} />
            <Container className='section-container bg-light'>
                <div className='activity-page-div'>
                    <Container className='activity-control-container'></Container>
                    <Container className='vstack w-75 mt-0 px-4 py-4'>
                        <div className='copycat-title'>
                            Copy Cat
                        </div >
                        <CopycatBoardComponent
                            showModal={instructionsModalVisibility}
                            CloseModal={() => SetInstructionsModalVisibility(false)}
                        />
                    </Container>
                    <Container className='activity-control-container'>
                        <ButtonGroup className='vstack mt-0 mb-1'>
                            <ExitControlIconComponent
                                CleanUpActivity={() => ResetCopycatPage()}
                            />
                            <RestartControlIconComponent
                                className='mt-1'
                                RestartActivity={() => ResetCopycatPage()}
                            />
                            <SoundControlIconComponent
                                className='mt-1'
                                isSoundOn={copycatIsSoundOn}
                                SoundActivity={() => SetCopycatIsSoundOn(!copycatIsSoundOn)}
                            />
                            <ActivityControlIconDropdownComponent
                                imageUrl='white-hash-in-blue-square.svg'
                                SelectAction={event => {
                                    const numCardWords = parseInt(event)
                                    SetNumCopycatCards(numCardWords)
                                }}
                            >
                                <Dropdown.ItemText style={{ color: 'var(--dark-orange)', fontWeight: 'bold' }}>Number of Cards</Dropdown.ItemText>
                                {CreateIndexArray(Math.min(copycatSourceWords.length, 5) - 2).map(value => (
                                    <Dropdown.Item eventKey={value+3} key={value+3}>
                                        {value+3}
                                    </Dropdown.Item>
                                ))}
                            </ActivityControlIconDropdownComponent>
                            <HelpControlIconComponent
                                className='mt-1'
                                HelpAction={() => SetInstructionsModalVisibility(true)}
                            />
                        </ButtonGroup>
                    </Container>
                </div>
            </Container>
        </>
    )
}
