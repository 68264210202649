import { useState, useEffect, useCallback } from 'react'
import { Container } from 'react-bootstrap'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { HOME_PATH } from '../App'
import { IsUserLoggedIn, } from '../state/UserAuthState'
import {
    UserAccountStateChangeListener,
    LoadUserAccount,
    LoadTokenizedUserAccount,
    GetUserAccountStatus,
    GetUserAccountInfo,
    GetUserAccountOperation,
    GetUserAccountEmailStatus,
    ResetUserAccountPassword,
    SendUserAccountEmail,
    USER_ACCOUNT_OPERATION_NONE,
    USER_ACCOUNT_STATUS_ACTIVE,
    USER_ACCOUNT_STATUS_INACTIVE,
    USER_ACCOUNT_EMAIL_STATUS_SUCCESS,
    USER_ACCOUNT_EMAIL_STATUS_FAILURE,
} from '../state/UserAccountState'
import {
    PasswordResetRequestComponent,
    PasswordResetEntryComponent,
    PASSWORD_RESET_MESSAGE_TYPE_NONE,
    PASSWORD_RESET_MESSAGE_TYPE_SUCCESS,
    PASSWORD_RESET_MESSAGE_TYPE_ERROR
} from '../components/PasswordResetComponents'
import { Header } from '../components/Header'
import { Footer } from '../components/Footer'
import { DisableScrollbar } from '../project/Display'

export function PasswordResetRequestPage() {
    const RESET_STEP_NONE = 'ResetStep/None'
    const RESET_STEP_LOAD_ACCOUNT = 'ResetStep/LoadAccount'
    const RESET_STEP_SEND_EMAIL = 'ResetStep/SendEmail'

    const [resetStep, SetResetStep] = useState(RESET_STEP_NONE)
    const [resetEmail, SetResetEmail] = useState('')
    const [resetMessageType, SetResetMessageType] = useState(PASSWORD_RESET_MESSAGE_TYPE_NONE)
    const [resetMessage, SetResetMessage] = useState('')
    const [isResetMessageDismissible, SetIsResetMessageDismissible] = useState(true)

    const ClearResetMessage = () => {
        SetResetMessageType(PASSWORD_RESET_MESSAGE_TYPE_NONE)
        SetResetMessage('')
    }

    const SetResetSuccessMessage = (message) => {
        SetResetMessageType(PASSWORD_RESET_MESSAGE_TYPE_SUCCESS)
        SetResetMessage(message)
    }

    const SetResetErrorMessage = (message) => {
        SetResetMessageType(PASSWORD_RESET_MESSAGE_TYPE_ERROR)
        SetResetMessage(message)
    }

    const navigate = useNavigate()

    useEffect(() => {
        DisableScrollbar()
        ClearResetMessage()

        if (IsUserLoggedIn()) {
            navigate(HOME_PATH)
        }
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => { }, [resetMessageType, resetMessage])

    const SubmitPasswordResetRequest = (accountEmail) => {
        ClearResetMessage()
        SetResetEmail(accountEmail)
        SetResetStep(RESET_STEP_LOAD_ACCOUNT)
        LoadUserAccount(accountEmail)
    }

    const HandleUserAccountStateChange = useCallback(
        (_listenerString) => {
            const accountStatus = GetUserAccountStatus()
            const accountInfo = GetUserAccountInfo()
            const accountOperation = GetUserAccountOperation()
            const accountEmailStatus = GetUserAccountEmailStatus()

            if (accountOperation !== USER_ACCOUNT_OPERATION_NONE) {
                return
            }

            switch (resetStep) {
                case RESET_STEP_LOAD_ACCOUNT:
                    if (accountStatus === USER_ACCOUNT_STATUS_ACTIVE) {
                        SetResetStep(RESET_STEP_SEND_EMAIL)
                        SendUserAccountEmail(resetEmail, 'LevelUp Artic Password Reset', 'users/password-reset-email', accountInfo['name'])
                    }
                    else {
                        SetResetStep(RESET_STEP_NONE)
                        SetResetErrorMessage("We couldn't find an active account for " + resetEmail + ". Please sign up for an account.")
                        SetIsResetMessageDismissible(true)
                    }

                    break

                case RESET_STEP_SEND_EMAIL:
                    if (accountEmailStatus === USER_ACCOUNT_EMAIL_STATUS_SUCCESS) {
                        SetResetStep(RESET_STEP_NONE)
                        SetResetSuccessMessage("Please check your email " + resetEmail + " for password reset instructions.")
                        SetIsResetMessageDismissible(false)
                    }
                    else if (accountEmailStatus === USER_ACCOUNT_EMAIL_STATUS_FAILURE) {
                        SetResetStep(RESET_STEP_NONE)
                        SetResetErrorMessage('Sorry, there was a problem sending the password reset email. Please try again later.')
                        SetIsResetMessageDismissible(true)
                    }
                    break

                case RESET_STEP_NONE:
                    break

                default:
                    SetResetStep(RESET_STEP_NONE)
                    SetResetErrorMessage("We encountered an error retrieving account information for " + resetEmail + ". Please try again later.")
                    SetIsResetMessageDismissible(true)
                    break
            }
        },
        [resetStep] // eslint-disable-line react-hooks/exhaustive-deps
    )

    return <>
        <UserAccountStateChangeListener callback={HandleUserAccountStateChange} />
        <Header />
        <Container className='section-container bg-light'>
            <PasswordResetRequestComponent
                HandleSubmit={SubmitPasswordResetRequest}
                IsBackendOperationActive={() => { return resetStep !== RESET_STEP_NONE }}
                messageType={resetMessageType}
                message={resetMessage}
                isMessageDismissable={isResetMessageDismissible}
                ClearMessage={ClearResetMessage}
            />
        </Container>
        <Footer />
    </>
}

export function PasswordResetEntryPage() {
    const RESET_STEP_NONE = 'ResetStep/None'
    const RESET_STEP_LOAD_ACCOUNT = 'ResetStep/LoadAccount'
    const RESET_STEP_ENTER_PASSWORD = 'ResetStep/EnterPassword'
    const RESET_STEP_CHANGE_PASSWORD = 'ResetStep/ChangePassword'

    const [searchParams, SetSearchParams] = useSearchParams();

    const [resetStep, SetResetStep] = useState(RESET_STEP_NONE)
    const [resetEmail, SetResetEmail] = useState('')
    const [resetPassword, SetResetPassword] = useState('')
    const [resetToken, SetResetToken] = useState('')
    const [resetMessageType, SetResetMessageType] = useState(PASSWORD_RESET_MESSAGE_TYPE_NONE)
    const [resetMessage, SetResetMessage] = useState('')
    const [isResetMessageDismissible, SetIsResetMessageDismissible] = useState(true)

    const navigate = useNavigate()

    const ClearResetMessage = () => {
        SetResetMessageType(PASSWORD_RESET_MESSAGE_TYPE_NONE)
        SetResetMessage('')
    }

    const SetResetSuccessMessage = (message) => {
        SetResetMessageType(PASSWORD_RESET_MESSAGE_TYPE_SUCCESS)
        SetResetMessage(message)
    }

    const SetResetErrorMessage = (message) => {
        SetResetMessageType(PASSWORD_RESET_MESSAGE_TYPE_ERROR)
        SetResetMessage(message)
    }

    useEffect(() => {
        DisableScrollbar()
        ClearResetMessage()

        if (IsUserLoggedIn()) {
            navigate(HOME_PATH)
        }
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => { }, [resetMessageType, resetMessage])

    useEffect(() => {
        const userKeyParam = searchParams.get('user-key')
        const tokenParam = searchParams.get('token')

        if (!userKeyParam || !tokenParam || tokenParam === 'invalid' || tokenParam === '') {
            SetResetErrorMessage('Sorry, the password reset link for this page is not valid. Please submit a new password reset request.')
            SetIsResetMessageDismissible(false)
        }

        SetResetToken(tokenParam)
        ClearResetMessage()
        SetResetStep(RESET_STEP_LOAD_ACCOUNT)
        LoadTokenizedUserAccount(userKeyParam, tokenParam)
    }, [searchParams, SetSearchParams])

    const HandleUserAccountStateChange = useCallback(
        (_listenerString) => {
            const accountStatus = GetUserAccountStatus()
            const accountInfo = GetUserAccountInfo()
            const accountOperation = GetUserAccountOperation()

            if (accountOperation !== USER_ACCOUNT_OPERATION_NONE) {
                return
            }

            switch (resetStep) {
                case RESET_STEP_LOAD_ACCOUNT:
                    if ((accountStatus === USER_ACCOUNT_STATUS_ACTIVE) || (accountStatus === USER_ACCOUNT_STATUS_INACTIVE)) {
                        SetResetEmail(accountInfo['email'])
                        SetResetStep(RESET_STEP_ENTER_PASSWORD)
                    }
                    else {
                        SetResetStep(RESET_STEP_NONE)
                        SetResetErrorMessage("The password reset link for this page is not valid. " +
                            "If you have an account, please submit a new password reset request.")
                        SetIsResetMessageDismissible(false)
                    }

                    break

                case RESET_STEP_ENTER_PASSWORD:
                    if (resetPassword !== '') {
                        ResetUserAccountPassword(resetEmail, resetPassword, resetToken)
                        SetResetStep(RESET_STEP_CHANGE_PASSWORD)
                    }
                    break

                case RESET_STEP_CHANGE_PASSWORD:
                    SetResetStep(RESET_STEP_NONE)
                    SetResetPassword('')

                    if (accountStatus === USER_ACCOUNT_STATUS_ACTIVE) {
                        SetResetSuccessMessage('Your password has been updated. You can log in to your account with your new password.')
                        SetIsResetMessageDismissible(false)
                    }
                    else {
                        SetResetErrorMessage("Sorry, we encountered an error and weren't able to reset your password. " +
                            "If you have an account, please submit a new password reset request.")
                        SetIsResetMessageDismissible(false)
                    }

                    break

                case RESET_STEP_NONE:
                    break

                default:
                    SetResetStep(RESET_STEP_NONE)
                    SetResetErrorMessage("Sorry, we encountered an error and weren't able to reset your password. " +
                        "If you have an account, please submit a new password reset request.")
                    SetIsResetMessageDismissible(true)
                    break
            }
        },
        [resetStep, resetPassword] // eslint-disable-line react-hooks/exhaustive-deps
    )

    const SubmitNewPassword = (accountPassword) => {
        if (resetStep !== RESET_STEP_ENTER_PASSWORD) {
            SetResetStep(RESET_STEP_NONE)
            SetResetErrorMessage("Sorry, we encountered an error with your password submission. Please try again later.")
            SetIsResetMessageDismissible(true)
            return
        }

        SetResetPassword(accountPassword)
    }

    return <>
        <UserAccountStateChangeListener callback={HandleUserAccountStateChange} />
        <Header />
        <Container className='section-container bg-light'>
            <PasswordResetEntryComponent
                HandleSubmit={SubmitNewPassword}
                IsBackendOperationActive={() => { return (resetStep === RESET_STEP_LOAD_ACCOUNT || resetStep === RESET_STEP_CHANGE_PASSWORD) }}
                accountEmail={resetEmail}
                messageType={resetMessageType}
                message={resetMessage}
                isMessageDismissable={isResetMessageDismissible}
                ClearMessage={ClearResetMessage}
            />
        </Container>
        <Footer />
    </>
}
