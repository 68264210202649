import { createReducer, createAction } from '@reduxjs/toolkit'
import { GetReducerState, ACTIVITY_REDUCER, DispatchAction } from '../state/ReduxStore'

// -------------------------------------------------------------------------------------------------
//  Internal state management
// -------------------------------------------------------------------------------------------------

const WORD_LIST = 'ActivityState/WordList'
const RETURN_PATH = 'ActivityState/ReturnPath'

const WORD_LIST_DEFAULT = []

const RETURN_PATH_DEFAULT = ''

const defaultState = { [WORD_LIST]: WORD_LIST_DEFAULT, [RETURN_PATH]: RETURN_PATH_DEFAULT }

const STORED_STATE_KEY = 'LevelUpArtic/ActivityState'

const StoreActivityState = (state) => {
    sessionStorage.setItem(STORED_STATE_KEY, JSON.stringify(state))
}

const GetWordList = () => {
    return GetReducerState(ACTIVITY_REDUCER)[WORD_LIST]
}

const GetReturnPath = () => {
    return GetReducerState(ACTIVITY_REDUCER)[RETURN_PATH]
}

// -------------------------------------------------------------------------------------------------
//  External state access
// -------------------------------------------------------------------------------------------------

export const GetInitialActivityState = () => {
    const storedState = sessionStorage.getItem(STORED_STATE_KEY)
    return storedState ? JSON.parse(storedState) : defaultState
}

export const GetActivityWordList = () => {
    return GetWordList()
}

export const GetActivityReturnPath = () => {
    return GetReturnPath()
}

// -------------------------------------------------------------------------------------------------
//  Internal action management
// -------------------------------------------------------------------------------------------------

const SET_WORD_LIST = 'ActivityAction/SetWordList'
const SET_RETURN_PATH = 'ActivityAction/SetReturnPath'

const setWordListAction = createAction(SET_WORD_LIST)
const setReturnPathAction = createAction(SET_RETURN_PATH)

// -------------------------------------------------------------------------------------------------
//  External action access
// -------------------------------------------------------------------------------------------------

export const SetActivityWordList = (wordList) => {
    DispatchAction(setWordListAction, wordList)
}

export const SetActivityReturnPath = (path) => {
    DispatchAction(setReturnPathAction, path)
}

// -------------------------------------------------------------------------------------------------
//  Internal reducer management
// -------------------------------------------------------------------------------------------------

const HandleSetWordListRequest = (state, action) => {
    state[WORD_LIST] = action.payload
    StoreActivityState(state)
}

const HandleSetReturnPathRequest = (state, action) => {
    state[RETURN_PATH] = action.payload
    StoreActivityState(state)
}

const activityReducer = createReducer(defaultState, (builder) => {
    builder.addCase(setWordListAction, (state, action) => {
        HandleSetWordListRequest(state, action)
    })

    builder.addCase(setReturnPathAction, (state, action) => {
        HandleSetReturnPathRequest(state, action)
    })

    builder.addDefaultCase(() => {})
})

// -------------------------------------------------------------------------------------------------
//  External reducer access
// -------------------------------------------------------------------------------------------------

export const GetActivityReducer = () => {
    return activityReducer
}
