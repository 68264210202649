import { useState, useEffect, useContext } from 'react'
import { Container, ButtonGroup } from 'react-bootstrap'
import { useNavigate, useLocation } from 'react-router-dom'
import { LOGIN_SIGNUP_PATH, SUBSCRIPTION_PATH, START_PATH } from '../App'
import { PickAPathContext, PickAPathProvider } from '../context/PickAPathContext'
import { IsUserLoggedIn } from '../state/UserAuthState'
import { IsUserSubscribed } from '../state/SubscriptionState'
import { ExitControlIconComponent, RestartControlIconComponent, BackControlIconComponent } from '../components/ActivityControlComponents'
import {
    PickAPathTitleComponent,
    PickAPathVocabComponent,
    PickAPathTextComponent,
    PickAPathChoicesHeaderComponent,
    PickAPathChoicesComponent,
    PickAPathEndComponent,
    PickAPathEndFooterComponent
} from '../components/PickAPathComponents'
import { DisableScrollbar } from '../project/Display'
import {
    PICK_A_PATH_READING_LEVEL_EASY, PICK_A_PATH_READING_LEVEL_INVALID, PICK_A_PATH_NODE_START
} from '../project/PickAPathResources'
import { GetPickAPathReadingLevel } from '../project/PickAPathStories'

export function PickAPathPage() {
    return (
        <PickAPathProvider>
            <PickAPathPageContent />
        </PickAPathProvider>
    )
}

function PickAPathPageContent() {
    const navigate = useNavigate()
    const { state } = useLocation()

    const {
        hasVocabBeenShown, story, SetStory,
        currentNode, SetCurrentNode,
        previousNodes, SetPreviousNodes } = useContext(PickAPathContext)

    const [orientationNode, SetOrientationNode] = useState(PICK_A_PATH_NODE_START)

    useEffect(() => {
        if (!IsUserLoggedIn()) {
            navigate(LOGIN_SIGNUP_PATH)
            return
        }
        else if (!IsUserSubscribed()) {
            navigate(SUBSCRIPTION_PATH)
            return
        }

        if (!state || !state['story']) {
            navigate(START_PATH)
            return
        }

        SetStory(state['story'])
        DisableScrollbar()
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (currentNode !== orientationNode) {
            SetOrientationNode(currentNode)
            window.scrollTo(0, 0)
        }
    }, [currentNode, orientationNode])

    const ResetStoryNodes = () => {
        SetCurrentNode(PICK_A_PATH_NODE_START)
        SetPreviousNodes([])
    }

    return (
        <Container className='section-container bg-light'>
            <div className='activity-page-div'>
                <Container className='activity-control-container' />
                <Container className='vstack w-75 mt-1 px-4 py-4'>
                    <PickAPathTitleComponent />
                    {GetPickAPathReadingLevel(story) === PICK_A_PATH_READING_LEVEL_EASY && !hasVocabBeenShown ?
                        <PickAPathVocabComponent />
                        :
                        GetPickAPathReadingLevel(story) !== PICK_A_PATH_READING_LEVEL_INVALID &&
                        <>
                            <PickAPathTextComponent />
                            <PickAPathChoicesHeaderComponent />
                            <PickAPathChoicesComponent />
                            <PickAPathEndComponent />
                        </>
                    }
                    <PickAPathEndFooterComponent />
                </Container>
                <Container className='activity-control-container'>
                    <ButtonGroup className='vstack mt-0 mb-1'>
                        <ExitControlIconComponent
                            CleanUpActivity={() => ResetStoryNodes()}
                        />
                        <RestartControlIconComponent
                            className='mt-1'
                            RestartActivity={() => ResetStoryNodes()}
                        />
                        <BackControlIconComponent
                            className='mt-1'
                            BackAction={() => {
                                if (previousNodes.length > 0) {
                                    SetCurrentNode(previousNodes.pop())
                                }
                            }}
                        />
                    </ButtonGroup>
                </Container>
            </div >
        </Container >
    )
}
