import { useState, useEffect, useCallback } from 'react'
import { Container, Row, Col, ButtonGroup, Dropdown, Modal } from 'react-bootstrap'
import PropTypes from 'prop-types'
import { FLASHCARDS_PATH, MEMORY_MATCH_PATH, TIC_TAC_TOE_PATH, COPYCAT_PATH } from '../App'
import { ListSelectComponent } from '../components/ListSelectComponent'
import { WordCardComponent } from '../components/WordCardComponent'
import { ActionButtonComponent, ActionDropdownComponent, DangerButtonComponent } from '../components/ActionButtonComponents'
import { SelectListId, GetSelectedList, GetSelectedListWords, SetUserLists, GetUserLists, ListsStateChangeListener } from '../state/ListsState'
import { GetAllBackendLists } from '../project/BackendListOperations'
import { SortUserLists } from '../project/Utils'
import './SavedListsComponents.css'

export function SavedListsSelectionComponent({ HandleSelectionUpdate, showModal = false, CloseModal = () => { } }) {
    const HandleGetListsResponse = useCallback(response => {
        const lists = [...response]
        SetUserLists(lists)
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        GetAllBackendLists(HandleGetListsResponse)
    }, [HandleGetListsResponse])

    const [listsStateChangeString, SetListsStateChangeString] = useState('')

    useEffect(() => {
        HandleSelectionUpdate(listsStateChangeString)
    }, [listsStateChangeString, HandleSelectionUpdate])

    const HandleListsStateChange = useCallback((listenerString) => {
        SetListsStateChangeString(listenerString)
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            <ListsStateChangeListener callback={HandleListsStateChange} />
            <Container className='mx-auto mt-3 mb-2 ps-5'>
                <ButtonGroup className='flex-wrap mx-2'>
                    <ListSelectComponent
                        allLists={SortUserLists(GetUserLists())}
                        selectedList={GetSelectedList()}
                        SelectList={(listId) => { SelectListId(listId) }}
                    />
                </ButtonGroup>
            </Container>
            <SavedListsSelectionInstructionsModalComponent
                showModal={showModal}
                HandleModalClose={() => CloseModal()}
            />
        </>
    )
}

SavedListsSelectionComponent.propTypes = {
    HandleSelectionUpdate: PropTypes.func.isRequired,
    showModal: PropTypes.bool,
    CloseModal: PropTypes.func,
}

function SavedListsSelectionInstructionsModalComponent({ showModal, HandleModalClose }) {
    return (
        <Modal
            className='fade'
            style={{ color: 'var(--dark-blue)' }}
            show={showModal}
            onHide={() => {
                HandleModalClose()
            }}
        >
            <Modal.Header className='bg-light py-3'>
                <Modal.Title className='text-center mx-auto'>
                    <h4 className='my-0'>Select a List</h4>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className='bg-light'>
                <h5 className='text-center mx-auto my-1' >
                    Choose one of your saved lists to view, edit, copy, or use in an activity.
                </h5>
            </Modal.Body>
            <Modal.Footer className='bg-light py-1' style={{ border: 'none' }}>
            </Modal.Footer>
        </Modal >
    )
}

SavedListsSelectionInstructionsModalComponent.propTypes = {
    showModal: PropTypes.bool.isRequired,
    HandleModalClose: PropTypes.func.isRequired,
}

export function SavedListsActionComponent({ StartActivity, EditList, CopyList, CreatePdf, DeleteList, showModal = false, CloseModal = () => { } }) {
    const buttonMargins = '0 0.5rem 1rem 0.5rem'

    return (
        <>
            <Container className='mx-auto mt-0 mb-0 ps-2'>
                <ButtonGroup className='flex-wrap ps-5 pt-4 pb-3'>
                    <ActionDropdownComponent
                        text='Start Activity'
                        margins={buttonMargins}
                        isActive={GetSelectedListWords().length > 0}
                        DoAction={(event) => {
                            const activityPath = event
                            StartActivity(activityPath, GetSelectedListWords())
                        }}
                    >
                        <Dropdown.Item eventKey={FLASHCARDS_PATH}>Flashcards</Dropdown.Item>
                        <Dropdown.Item eventKey={MEMORY_MATCH_PATH}>Memory Match</Dropdown.Item>
                        <Dropdown.Item eventKey={TIC_TAC_TOE_PATH}>Tic Tac Toe</Dropdown.Item>
                        <Dropdown.Item eventKey={COPYCAT_PATH}>Copy Cat</Dropdown.Item>
                    </ActionDropdownComponent>
                    <ActionButtonComponent
                        text='Edit List'
                        width='7.5rem'
                        margins={buttonMargins}
                        isActive={Object.keys(GetSelectedList()).length > 0}
                        DoAction={() => {
                            EditList(GetSelectedList(), GetSelectedListWords())
                        }}
                    />
                    <ActionButtonComponent
                        text='Copy List'
                        width='7.5rem'
                        margins={buttonMargins}
                        isActive={GetSelectedListWords().length > 0}
                        DoAction={() => {
                            CopyList(GetSelectedList(), GetSelectedListWords())
                        }}
                    />
                    <ActionButtonComponent
                        text='Create PDF'
                        width='8.5rem'
                        margins={buttonMargins}
                        isActive={GetSelectedListWords().length > 0}
                        DoAction={() => {
                            CreatePdf(GetSelectedList().name, GetSelectedListWords())
                        }}
                    />
                    <DangerButtonComponent
                        text='Delete List'
                        width='8.5rem'
                        margins={buttonMargins}
                        isActive={Object.keys(GetSelectedList()).length > 0}
                        DoAction={() => {
                            DeleteList(GetSelectedList())
                        }}
                    />
                </ButtonGroup>
            </Container>
            <SavedListsActionInstructionsModalComponent
                showModal={showModal}
                HandleModalClose={() => CloseModal()}
            />
        </>
    )
}

SavedListsActionComponent.propTypes = {
    StartActivity: PropTypes.func.isRequired,
    EditList: PropTypes.func.isRequired,
    CopyList: PropTypes.func.isRequired,
    CreatePdf: PropTypes.func.isRequired,
    DeleteList: PropTypes.func.isRequired,
    showModal: PropTypes.bool,
    CloseModal: PropTypes.func,
}

function SavedListsActionInstructionsModalComponent({ showModal, HandleModalClose }) {
    return (
        <Modal
            className='fade modal-lg'
            style={{ color: 'var(--dark-blue)' }}
            show={showModal}
            onHide={() => {
                HandleModalClose()
            }}
        >
            <Modal.Header className='bg-light py-3'>
                <Modal.Title className='text-center mx-auto'>
                    <h4 className='my-0'>What To Do With Your List</h4>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className='bg-light'>
                <div className='d-flex justify-content-center mx-auto'>
                    <div className='justify-content-left'>
                        <h5>&#x27A4; Use it in Flashcards or another activity.</h5>
                        <h5>&#x27A4; Edit the name and contents.</h5>
                        <h5>&#x27A4; Make a copy of it to create a new list.</h5>
                        <h5>&#x27A4; Create a printable copy of the words.</h5>
                        <h5>&#x27A4; Delete it from your saved lists.</h5>
                    </div>
                </div>
                <div className='justify-content-center mx-4 mt-3'>
                    <h5>
                        Regarding Create PDF: Some browsers, particularly on mobile devices, block new tabs.
                        The PDF document opens in the current tab instead.
                        Use the browser's back navigation to return to Saved Lists.
                        To change this behavior, configure the browser settings to enable pop-ups.
                    </h5>
                </div>
            </Modal.Body>
            <Modal.Footer className='bg-light py-1' style={{ border: 'none' }}>
            </Modal.Footer>
        </Modal >
    )
}

SavedListsActionInstructionsModalComponent.propTypes = {
    showModal: PropTypes.bool.isRequired,
    HandleModalClose: PropTypes.func.isRequired,
}

export function SavedListsWordsComponent() {
    const GetWordCards = () => {
        const wordCards = GetSelectedListWords().map((wordKey, index) => (
            <Col xs={6} sm={4} md={3} lg={2} key={index}>
                <ButtonGroup className='vstack mt-0 mb-1 my-lg-1'>
                    <WordCardComponent wordKey={wordKey} selectedWords={GetSelectedListWords()}></WordCardComponent>
                </ButtonGroup>
            </Col>
        ))

        return wordCards
    }

    return (
        <>
            <Row className='mt-0 g-2'>{GetWordCards()}</Row>
        </>
    )
}
