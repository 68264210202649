import { ButtonGroup, FloatingLabel } from 'react-bootstrap'
import PropTypes from 'prop-types'
import './SearchParamComponents.css'

export function SearchParamSelectComponent({ paramSelectLabel, paramSelectChoices, selectedParams, SelectParam, DeselectParam, boxWidth = '10rem', boxColor = 'var(--dark-blue)' }) {
    const buttonGroupStyle = {
        width: boxWidth,
        minWidth: boxWidth,
        maxWidth: boxWidth,
    }

    const boxStyle = {
        width: boxWidth,
        minWidth: boxWidth,
        borderColor: boxColor,
    }

    const labelStyle = {
        width: boxWidth,
        minWidth: boxWidth,
        fontSize: '105%',
        backgroundColor: boxColor,
        color: 'white'
    }

    const HandleParamClick = (paramValue) => {
        if (selectedParams.includes(paramValue)) {
            DeselectParam(paramValue)
        }
        else {
            SelectParam(paramValue)
        }
    }

    return (
        <ButtonGroup className='vstack search-param-select-button-group' style={buttonGroupStyle} >
            <FloatingLabel className='search-param-select-label' style={labelStyle}>{paramSelectLabel}</FloatingLabel>
            <div className='search-param-select-box' style={boxStyle}>
                {Object.keys(paramSelectChoices).map((paramValue, index) => (
                    <SearchParamComponent key={index} paramValue={paramValue} paramText={paramSelectChoices[paramValue]}
                        isSelected={selectedParams.includes(paramValue)}
                        ClickParam={(value) => HandleParamClick(value)} />
                ))}
            </div>
        </ButtonGroup >
    )
}

SearchParamSelectComponent.propTypes = {
    paramSelectLabel: PropTypes.string.isRequired,
    paramSelectChoices: PropTypes.object.isRequired,
    selectedParams: PropTypes.array.isRequired,
    SelectParam: PropTypes.func.isRequired,
    DeselectParam: PropTypes.func.isRequired,
    boxWidth: PropTypes.string,
}

function SearchParamComponent({ paramValue, paramText, isSelected, ClickParam }) {
    const searchParamClass = 'search-param ' + (isSelected ? 'search-param-selected' : 'search-param-deselected')

    return <div className={searchParamClass} onClick={() => ClickParam(paramValue)}>{paramText}</div>
}
