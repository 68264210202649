import { useState, useEffect, useContext, useCallback } from 'react'
import { Container, ButtonGroup, Dropdown } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import { LOGIN_SIGNUP_PATH, SUBSCRIPTION_PATH } from '../App'
import { MemoryMatchProvider, MemoryMatchContext, MemoryMatchListener } from '../context/MemoryMatchContext'
import { GetMaxNumMemoryMatchCards, ResetMemoryMatchCards } from '../state/MemoryMatchState'
import { GetActivityWordList, GetActivityReturnPath } from '../state/ActivityState'
import { IsUserLoggedIn } from '../state/UserAuthState'
import { IsUserSubscribed } from '../state/SubscriptionState'
import { MemoryMatchBoardComponent } from '../components/MemoryMatchComponents'
import { ExitControlIconComponent, RestartControlIconComponent, ActivityControlIconDropdownComponent } from '../components/ActivityControlComponents'
import { CreateIndexArray } from '../project/Utils'
import { DisableScrollbar } from '../project/Display'
import '../components/MemoryMatchComponents.css'

export function MemoryMatchPage() {
    return (
        <MemoryMatchProvider>
            <MemoryMatchPageContent />
        </MemoryMatchProvider>
    )
}

export function MemoryMatchPageContent() {
    const {
        memoryMatchSourceWords, SetMemoryMatchSourceWords,
        SetMemoryMatchCards, ClearShowMemoryMatchResults
    } = useContext(MemoryMatchContext)

    const DEFAULT_CONTEXT_STRING = ''
    const [contextString, SetContextString] = useState(DEFAULT_CONTEXT_STRING)

    useEffect(() => { }, [contextString])

    const HandleMemoryMatchContextChange = useCallback(listenerString => {
        SetContextString(listenerString)
    }, [])

    const navigate = useNavigate()

    const [numMemoryMatchCards, SetNumMemoryMatchCards] = useState(0)

    useEffect(() => {
        if (!IsUserLoggedIn()) {
            navigate(LOGIN_SIGNUP_PATH)
        }
        else if (!IsUserSubscribed()) {
            navigate(SUBSCRIPTION_PATH)
        }

        DisableScrollbar()
        SetContextString(DEFAULT_CONTEXT_STRING)

        const activityWords = [...GetActivityWordList()]

        if (activityWords.length < 2) {
            navigate(GetActivityReturnPath())
        }

        SetMemoryMatchSourceWords(activityWords)
        SetNumMemoryMatchCards(Math.min(GetMaxNumMemoryMatchCards(activityWords), 12))
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const RestartGame = () => {
        ClearShowMemoryMatchResults()

        if ((numMemoryMatchCards === 0) || (memoryMatchSourceWords.length === 0)) {
            return
        }

        SetMemoryMatchCards(ResetMemoryMatchCards(memoryMatchSourceWords, numMemoryMatchCards))
    }

    useEffect(() => {
        if ((numMemoryMatchCards > 0) && (memoryMatchSourceWords.length > 0)) {
            RestartGame()
        }
    }, [numMemoryMatchCards, memoryMatchSourceWords.length])  // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            <MemoryMatchListener SendContextChangeNotification={HandleMemoryMatchContextChange} />
            <Container className='section-container bg-light'>
                <div className='activity-page-div'>
                    <Container className='activity-control-container'></Container>
                    <Container className='vstack w-75 mt-0 px-4 py-4'>
                        <div className='memory-match-title'>
                            Memory Match
                        </div >
                        <MemoryMatchBoardComponent RestartGame={() => RestartGame()} />
                    </Container>
                    <Container className='activity-control-container'>
                        <ButtonGroup className='vstack mt-0 mb-1'>
                            <ExitControlIconComponent CleanUpActivity={() => { }} />
                            <RestartControlIconComponent
                                className='mt-1'
                                RestartActivity={() => {
                                    RestartGame()
                                }}
                            />
                            <ActivityControlIconDropdownComponent
                                imageUrl='grid-3x3-gap-fill.svg'
                                SelectAction={(event) => {
                                    const cardPairs = parseInt(event)

                                    if ((cardPairs <= (GetMaxNumMemoryMatchCards(memoryMatchSourceWords) >> 1)) && (cardPairs !== (numMemoryMatchCards >> 1))) {
                                        SetNumMemoryMatchCards(cardPairs << 1)
                                        RestartGame()
                                    }
                                }}
                            >
                                <Dropdown.ItemText style={{ color: 'var(--dark-orange)', fontWeight: 'bold' }}>Card Pairs</Dropdown.ItemText>
                                {CreateIndexArray((GetMaxNumMemoryMatchCards(memoryMatchSourceWords) >> 1) - 1).map((value) => (
                                    <Dropdown.Item eventKey={value + 2} key={value + 2}>
                                        {value + 2}
                                    </Dropdown.Item>
                                ))}
                            </ActivityControlIconDropdownComponent>
                        </ButtonGroup>
                    </Container>
                </div>
            </Container>
        </>
    )
}
