import { configureStore } from '@reduxjs/toolkit'
import { GetUserAuthReducer, GetInitialUserAuthState } from './UserAuthState'
import { GetUserAccountReducer, GetInitialUserAccountState } from './UserAccountState'
import { GetUserProfileReducer, GetInitialUserProfileState } from './UserProfileState'
import { GetSubscriptionReducer, GetInitialSubscriptionState } from './SubscriptionState'
import { GetQuickSearchReducer, GetInitialQuickSearchState } from './QuickSearchState'
import { GetListsReducer, GetInitialListsState } from './ListsState'
import { GetCarryoverReducer, GetInitialCarryoverState } from './CarryoverState'
import { GetActivityReducer, GetInitialActivityState } from './ActivityState'
import { GetFlashcardsReducer, GetInitialFlashcardsState } from './FlashcardsState'
import { GetTicTacToeReducer, GetInitialTicTacToeState } from './TicTacToeState'

export const USER_AUTH_REDUCER = 'ReduxStore/UserAuthReducer'
export const USER_ACCOUNT_REDUCER = 'ReduxStore/UserAccountReducer'
export const USER_PROFILE_REDUCER = 'ReduxStore/UserProfileReducer'
export const SUBSCRIPTION_REDUCER = 'ReduxStore/SubscriptionReducer'
export const QUICK_SEARCH_REDUCER = 'ReduxStore/QuickSearchReducer'
export const LISTS_REDUCER = 'ReduxStore/ListsReducer'
export const CARRYOVER_REDUCER = 'ReduxStore/CarryoverReducer'
export const ACTIVITY_REDUCER = 'ReduxStore/ActivityReducer'
export const FLASHCARDS_REDUCER = 'ReduxStore/FlashcardsReducer'
export const TIC_TAC_TOE_REDUCER = 'ReduxStore/TicTacToeReducer'

export const DEFAULT_STRINGIFIED_STATE = ''

const initialState = {
    [USER_AUTH_REDUCER]: GetInitialUserAuthState(),
    [USER_ACCOUNT_REDUCER]: GetInitialUserAccountState(),
    [USER_PROFILE_REDUCER]: GetInitialUserProfileState(),
    [SUBSCRIPTION_REDUCER]: GetInitialSubscriptionState(),
    [QUICK_SEARCH_REDUCER]: GetInitialQuickSearchState(),
    [LISTS_REDUCER]: GetInitialListsState(),
    [CARRYOVER_REDUCER]: GetInitialCarryoverState(),
    [ACTIVITY_REDUCER]: GetInitialActivityState(),
    [FLASHCARDS_REDUCER]: GetInitialFlashcardsState(),
    [TIC_TAC_TOE_REDUCER]: GetInitialTicTacToeState(),
}

// See https://redux-toolkit.js.org/api/configureStore for configureStore arguments and https://redux-toolkit.js.org/api/getDefaultMiddleware for
// instructions on adding to default middleware.
export const reduxStore = configureStore({
    reducer: {
        [USER_AUTH_REDUCER]: GetUserAuthReducer(),
        [USER_ACCOUNT_REDUCER]: GetUserAccountReducer(),
        [USER_PROFILE_REDUCER]: GetUserProfileReducer(),
        [SUBSCRIPTION_REDUCER]: GetSubscriptionReducer(),
        [QUICK_SEARCH_REDUCER]: GetQuickSearchReducer(),
        [LISTS_REDUCER]: GetListsReducer(),
        [CARRYOVER_REDUCER]: GetCarryoverReducer(),
        [ACTIVITY_REDUCER]: GetActivityReducer(),
        [FLASHCARDS_REDUCER]: GetFlashcardsReducer(),
        [TIC_TAC_TOE_REDUCER]: GetTicTacToeReducer(),
    },
    preloadedState: initialState,
    devTools: true,
})

export const GetReducerState = (reducer) => {
    return reduxStore.getState()[reducer]
}

export const DispatchAction = (action, payload) => {
    reduxStore.dispatch(action(payload))
}

export const GetStringifiedState = (reducer) => {
    var stringifiedState = DEFAULT_STRINGIFIED_STATE

    try {
        stringifiedState = JSON.stringify(reduxStore.getState()[reducer])
    } catch {
        stringifiedState = DEFAULT_STRINGIFIED_STATE
    }

    return stringifiedState
}

export const SubscribeActionListener = (listener) => {
    reduxStore.subscribe(listener)
}
