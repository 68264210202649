import { useState, useEffect, useCallback } from 'react'
import { Container, Row, Col, ButtonGroup } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import { HOME_PATH } from '../App'
import { Header } from '../components/Header'
import { Footer } from '../components/Footer'
import {
    ControlCenterDataGroupSelectComponent,
    ControlCenterUserProfileTableComponent,
    ControlCenterUserSubscriptionTableComponent
} from '../components/ControlCenterComponents'
import { IsUserAdmin, UserAuthStateChangeListener } from '../state/UserAuthState'

export function ControlCenterPage() {
    const navigate = useNavigate()
    const [userStateChangeString, SetUserStateChangeString] = useState('')

    useEffect(() => {
        if (!IsUserAdmin()) {
            navigate(HOME_PATH)
        }
    }, [navigate, userStateChangeString])

    const HandleUserStateChange = useCallback((listenerString) => {
        SetUserStateChangeString(listenerString)
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const dataGroups = {
        'user-profiles': 'User Profile Data',
        'user-subscriptions': 'User Subscription Data',
    }

    const [selectedDataGroupId, SetSelectedDataGroupId] = useState('')
    const [dataGroupComponent, SetDataGroupComponent] = useState(<></>)

    useEffect(() => { }, [dataGroupComponent])

    const SelectDataGroup = (dataGroupId) => {
        if (dataGroupId === selectedDataGroupId) {
            return
        }

        SetSelectedDataGroupId(dataGroupId)

        switch (dataGroupId) {
            case 'user-profiles':
                SetDataGroupComponent(<ControlCenterUserProfileTableComponent />)
                break

            case 'user-subscriptions':
                SetDataGroupComponent(<ControlCenterUserSubscriptionTableComponent />)
                break

            default:
                SetDataGroupComponent(<></>)
                break;
        }
    }

    return (
        <>
            <UserAuthStateChangeListener callback={HandleUserStateChange} />
            <Header />
            <Container className='section-container bg-light'>
                <div className='page-description-setup-div'>
                    <Row>
                        <Col sm={12} lg={5}>
                            <h3>Admin Control Center</h3>
                            <p>You are logged in with ... privileges. Select the data you want to ...</p>
                            <ButtonGroup className='flex-wrap'>
                                <ControlCenterDataGroupSelectComponent
                                    dataGroups={dataGroups}
                                    selectedDataGroupId={selectedDataGroupId}
                                    SelectDataGroup={SelectDataGroup}
                                />
                            </ButtonGroup>
                        </Col>
                        <Col className='vertical-line-column' lg={1}></Col>
                        <Col lg={1}></Col>
                        <Col sm={12} lg={5}></Col>
                    </Row>
                </div>
            </Container>
            <Container className='section-container bg-light pt-1 pb-5'>
                <Container fluid className='list-container'>
                    {dataGroupComponent}
                </Container>
            </Container>
            <Footer />
        </>
    )
}
