import { Form } from 'react-bootstrap'
import PropTypes from 'prop-types'
import './WordCheckboxComponent.css'

export function WordCheckboxComponent({ wordKey, selectedWords, SelectWord, DeselectWord }) {
    const isChecked = selectedWords.includes(wordKey)

    const HandleCheckboxChange = (event) => {
        if (event.target.checked) {
            SelectWord(wordKey)
        } else {
            DeselectWord(wordKey)
        }
    }

    return (
        <Form.Check type='checkbox' className='word-checkbox'>
            <Form.Check.Input
                type='checkbox'
                className='word-checkbox-input'
                checked={isChecked}
                onChange={(event) => {
                    HandleCheckboxChange(event)
                }}></Form.Check.Input>
        </Form.Check>
    )
}

WordCheckboxComponent.propTypes = {
    wordKey: PropTypes.string.isRequired,
    selectedWords: PropTypes.arrayOf(PropTypes.string).isRequired,
    SelectWord: PropTypes.func.isRequired,
    DeselectWord: PropTypes.func.isRequired,
}
