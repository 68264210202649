import { useCallback } from 'react'
import { Container, ButtonGroup, FloatingLabel } from 'react-bootstrap'
import { useNavigate, useLocation } from 'react-router-dom'
import PropTypes from 'prop-types'
import { PICK_A_PATH_PATH, WHIMPROVS_PATH } from '../App'
import { ActionButtonComponent } from '../components/ActionButtonComponents'
import {
    SelectCarryoverActivity, GetCarryoverSelectedActivity,
    SelectCarryoverTargetSound, GetCarryoverSelectedTargetSound,
    SelectCarryoverTargetSoundGroup, GetCarryoverSelectedTargetSoundGroup,
    SelectCarryoverReadingLevel, GetCarryoverSelectedReadingLevel,
    SelectCarryoverStory, GetCarryoverSelectedStory,
    CarryoverStateChangeListener
} from '../state/CarryoverState'
import { SetActivityReturnPath } from '../state/ActivityState'
import { GetTargetSoundChoices } from '../project/SoundPositionLevelChoices'
import { PICK_A_PATH_READING_LEVEL_EASY, PICK_A_PATH_READING_LEVEL_PROFICIENT } from '../project/PickAPathResources'
import { GetPickAPathStoriesFromSoundAndReadingLevel } from '../project/PickAPathStories'
import { GetWhimprovsStories, GetWhimprovsChoiceGroups, GetWhimprovsChoiceGroupText } from '../project/WhimprovsStories'
import './CarryoverComponents.css'

export function CarryoverComponent({ HandleSelectionUpdate }) {
    const HandleStateChange = useCallback(listenerString => {
        HandleSelectionUpdate(listenerString)
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const navigate = useNavigate()
    const location = useLocation()

    const GetActivityParamChoices = () => {
        return {
            'pick-a-path': 'Pick a Path',
            'whimprovs': 'Silly Stories'
        }
    }

    const GetTargetSoundParamChoices = () => {
        let choices = {}

        for (let sound of GetTargetSoundChoices()) {
            choices[sound] = sound
        }

        return choices
    }

    const GetReadingLevelParamChoices = () => {
        return {
            [PICK_A_PATH_READING_LEVEL_EASY]: 'Read-Together',
            [PICK_A_PATH_READING_LEVEL_PROFICIENT]: 'Proficient Reader'
        }
    }

    const GetTargetSoundGroupParamChoices = () => {
        let choiceGroups = GetWhimprovsChoiceGroups()
        let choices = {}

        for (let choiceGroup of choiceGroups) {
            choices[choiceGroup] = GetWhimprovsChoiceGroupText(choiceGroup)
        }

        return choices
    }

    const GetPickAPathStoryParamChoices = () => {
        let stories = GetPickAPathStoriesFromSoundAndReadingLevel(GetCarryoverSelectedTargetSound(),
                                                                  GetCarryoverSelectedReadingLevel())
        let choices = {}

        for (let storyKey of Object.keys(stories)) {
            choices[storyKey] = stories[storyKey]['dropdownName']
        }

        return choices
    }

    const GetWhimprovsStoryParamChoices = () => {
        let stories = GetWhimprovsStories()
        let choices = {}

        for (let storyKey of Object.keys(stories)) {
            choices[storyKey] = stories[storyKey]['name']
        }

        return choices
    }

    const IsGoButtonActive = () => {
        const selectedActivity = GetCarryoverSelectedActivity()
        const selectedTargetSoundGroup = GetCarryoverSelectedTargetSoundGroup()
        const selectedStory = GetCarryoverSelectedStory()

        if ((selectedActivity === 'pick-a-path') && (selectedStory !== '')) {
            return true
        }

        if ((selectedActivity === 'whimprovs') && (selectedTargetSoundGroup !== '') && (selectedStory !== '')) {
            return true
        }

        return false
    }

    return (
        <>
            <CarryoverStateChangeListener callback={HandleStateChange} />
            <Container className='mx-auto mt-3 mb-2 ps-4'>
                <ButtonGroup className='flex-wrap mx-4'>
                    <CarryoverParamSelectComponent
                        paramSelectLabel={'Activity'}
                        paramSelectChoices={GetActivityParamChoices()}
                        selectedParams={[GetCarryoverSelectedActivity()]}
                        SelectParam={activity => {
                            SelectCarryoverActivity(activity)
                        }}
                        DeselectParam={() => { }}
                    />
                    {GetCarryoverSelectedActivity() === 'pick-a-path' &&
                        <>
                            <CarryoverParamSelectComponent
                                paramSelectLabel={'Target Sound'}
                                paramSelectChoices={GetTargetSoundParamChoices()}
                                selectedParams={[GetCarryoverSelectedTargetSound()]}
                                SelectParam={sound => {
                                    SelectCarryoverTargetSound(sound)
                                }}
                                DeselectParam={() => { }}
                                boxWidth='11rem'
                            />
                            <CarryoverParamSelectComponent
                                paramSelectLabel={'Reading Level'}
                                paramSelectChoices={GetReadingLevelParamChoices()}
                                selectedParams={[GetCarryoverSelectedReadingLevel()]}
                                SelectParam={level => {
                                    SelectCarryoverReadingLevel(level)
                                }}
                                DeselectParam={() => { }}
                                boxWidth='12rem'
                            />
                            <CarryoverParamSelectComponent
                                paramSelectLabel={'Story'}
                                paramSelectChoices={GetPickAPathStoryParamChoices()}
                                selectedParams={[GetCarryoverSelectedStory()]}
                                SelectParam={story => {
                                    SelectCarryoverStory(story)
                                }}
                                DeselectParam={() => { }}
                                boxWidth='35rem'
                            />
                            <div className='vstack ms-3 mt-4' style={{ maxWidth: '10rem' }}>
                                <ActionButtonComponent
                                    text='Go'
                                    width='5.5rem'
                                    margins={'1rem 1rem 0 0'}
                                    isActive={IsGoButtonActive()}
                                    DoAction={() => {
                                        SetActivityReturnPath(location)
                                        navigate(PICK_A_PATH_PATH, { state: { story: GetCarryoverSelectedStory() } })
                                    }} />
                            </div>
                        </>
                    }
                    {GetCarryoverSelectedActivity() === 'whimprovs' &&
                        <>
                            <CarryoverParamSelectComponent
                                paramSelectLabel={'Target Sound'}
                                paramSelectChoices={GetTargetSoundGroupParamChoices()}
                                selectedParams={[GetCarryoverSelectedTargetSoundGroup()]}
                                SelectParam={group => {
                                    SelectCarryoverTargetSoundGroup(group)
                                }}
                                DeselectParam={() => { }}
                                boxWidth='12rem'
                            />
                            <CarryoverParamSelectComponent
                                paramSelectLabel={'Story'}
                                paramSelectChoices={GetWhimprovsStoryParamChoices()}
                                selectedParams={[GetCarryoverSelectedStory()]}
                                SelectParam={story => {
                                    SelectCarryoverStory(story)
                                }}
                                DeselectParam={() => { }}
                                boxWidth='15rem'
                            />
                            <div className='vstack ms-3 mt-4' style={{ maxWidth: '10rem' }}>
                                <ActionButtonComponent
                                    text='Go'
                                    width='5.5rem'
                                    margins={'1rem 1rem 0 0'}
                                    isActive={IsGoButtonActive()}
                                    DoAction={() => {
                                        SetActivityReturnPath(location)
                                        navigate(WHIMPROVS_PATH, { state: { story: GetCarryoverSelectedStory(),
                                                                   choiceGroup: GetCarryoverSelectedTargetSoundGroup() } })
                                    }} />
                            </div>
                        </>
                    }
                </ButtonGroup>
            </Container>
        </>
    )
}

CarryoverComponent.propTypes = {
    HandleSelectionUpdate: PropTypes.func.isRequired,
}

function CarryoverParamSelectComponent({ paramSelectLabel, paramSelectChoices, selectedParams, SelectParam, DeselectParam,
                                         boxWidth = '10rem', boxColor = 'var(--light-green)' }) {
    const buttonGroupStyle = {
        width: boxWidth,
        minWidth: boxWidth,
        maxWidth: boxWidth,
    }

    const boxStyle = {
        width: boxWidth,
        minWidth: boxWidth,
        minHeight: '5rem',
        borderColor: boxColor,
    }

    const labelStyle = {
        width: boxWidth,
        minWidth: boxWidth,
        fontSize: '105%',
        backgroundColor: boxColor,
        color: 'white'
    }

    const HandleParamClick = (paramValue) => {
        if (selectedParams.includes(paramValue)) {
            DeselectParam(paramValue)
        }
        else {
            SelectParam(paramValue)
        }
    }

    return (
        <ButtonGroup className='vstack search-param-select-button-group' style={buttonGroupStyle} >
            <FloatingLabel className='search-param-select-label' style={labelStyle}>{paramSelectLabel}</FloatingLabel>
            <div className='search-param-select-box' style={boxStyle}>
                {Object.keys(paramSelectChoices).map((paramValue, index) => (
                    <CarryoverParamComponent key={index} paramValue={paramValue} paramText={paramSelectChoices[paramValue]}
                        isSelected={selectedParams.includes(paramValue)}
                        ClickParam={value => HandleParamClick(value)} />
                ))}
            </div>
        </ButtonGroup >
    )
}

CarryoverParamSelectComponent.propTypes = {
    paramSelectLabel: PropTypes.string.isRequired,
    paramSelectChoices: PropTypes.object.isRequired,
    selectedParams: PropTypes.array.isRequired,
    SelectParam: PropTypes.func.isRequired,
    DeselectParam: PropTypes.func.isRequired,
    boxWidth: PropTypes.string,
}

function CarryoverParamComponent({ paramValue, paramText, isSelected, ClickParam }) {
    const searchParamClass = 'search-param ' + (isSelected ? 'search-param-selected' : 'search-param-deselected')

    return <div className={searchParamClass} onClick={() => ClickParam(paramValue)}>{paramText}</div>
}
