import { useContext } from 'react'
import { Container, Row, Col, Card, Image, ButtonGroup, Modal } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import PropTypes from 'prop-types'
import { start as ToneSynthStart } from 'tone'
import { ActionButtonComponent } from './ActionButtonComponents'
import {
    CopycatContext, CopycatStateMachine,
    COPYCAT_CARD_INDEX, COPYCAT_CARD_WORD, COPYCAT_CARD_STATE,
    COPYCAT_CARD_STATE_NORMAL, COPYCAT_CARD_STATE_PLAYING, COPYCAT_CARD_STATE_CLICK_SUCCESS, COPYCAT_CARD_STATE_CLICK_FAILURE,
    COPYCAT_GAME_STATE_START_GAME, COPYCAT_GAME_STATE_CONT_GAME, COPYCAT_GAME_STATE_END_GAME,
    COPYCAT_GAME_STATE_START_PLAY, COPYCAT_GAME_STATE_CONT_PLAY,
    COPYCAT_GAME_STATE_START_COPY, COPYCAT_GAME_STATE_CONT_COPY,
} from '../context/CopycatContext'
import { GetActivityReturnPath } from '../state/ActivityState'
import { GetWordText } from '../project/WordCodings'
import { GetWordImageUrl } from '../project/ProjectResources'
import './CopycatComponents.css'

export function CopycatBoardComponent({showModal = false, CloseModal = () => { }}) {
    const { copycatCards, copycatSequence, SetCopycatGameState, SetCopycatClickedCardIndex, copycatIsCardClickAllowed,
            ResetCopycatContext } = useContext(CopycatContext)

    const navigate = useNavigate()

    return (
        <>
            <Container className='copycat-board-container'>
                <Row className='my-0 gx-2 gy-3'>
                    {copycatCards.map(card => (
                        <Col xs={6} sm={3} md={2} className='mx-auto' key={card[COPYCAT_CARD_INDEX]}>
                            <CopycatCardComponent
                                card={card}
                                ClickAction={cardIndex => {
                                    if (copycatIsCardClickAllowed) {
                                        SetCopycatClickedCardIndex(cardIndex)
                                    }
                                }}
                            />
                        </Col>
                    ))}
                </Row>
                <CopycatControlComponent
                    PlayButtonAction={() => SetCopycatGameState(COPYCAT_GAME_STATE_START_PLAY)}
                    RestartButtonAction={() => ResetCopycatContext()}
                    ExitButtonAction={() => navigate(GetActivityReturnPath())}
                />
            </Container>
            <div className='mx-auto mt-3'>
                <h4>Sequence Length: {copycatSequence.length}</h4>
            </div>
            <CopycatInstructionsModalComponent
                showModal={showModal}
                HandleModalClose={() => CloseModal()}
            />
            <CopycatStateMachine />
        </>
    )
}

CopycatBoardComponent.propTypes = {
    showInstructionsModal: PropTypes.bool
}

async function InitCopycatToneSynth() {
    await ToneSynthStart()
}

function CopycatCardComponent({ card, ClickAction }) {
    const cardIndex = card[COPYCAT_CARD_INDEX]
    const cardWord = card[COPYCAT_CARD_WORD]

    let cardImageDivClass = 'copycat-card-image-div vstack mx-auto my-0'

    switch (card[COPYCAT_CARD_STATE]) {
        case COPYCAT_CARD_STATE_NORMAL:
        default:
            cardImageDivClass += ' copycat-card-image-normal-div'
            break

        case COPYCAT_CARD_STATE_PLAYING:
            cardImageDivClass += ' copycat-card-image-playing-div'
            break

        case COPYCAT_CARD_STATE_CLICK_SUCCESS:
            cardImageDivClass += ' copycat-card-image-click-success-div'
            break

        case COPYCAT_CARD_STATE_CLICK_FAILURE:
            cardImageDivClass += ' copycat-card-image-click-failure-div'
            break
    }

    return (
        <>
            <Card
                className='copycat-card bg-light'
                value={cardIndex}
                onClick={() => ClickAction(cardIndex)}
            >
                <div className={cardImageDivClass}>
                    <Image fluid className='copycat-card-image' src={GetWordImageUrl(cardWord)} alt={GetWordText(cardWord)} />
                </div>
                <h5 className='copycat-card-text text-center my-0 p-0'>{GetWordText(cardWord)}</h5>
            </Card>
        </>
    )
}

CopycatCardComponent.propTypes = {
    card: PropTypes.object.isRequired,
    ClickAction: PropTypes.func.isRequired
}

function CopycatControlComponent({ PlayButtonAction, RestartButtonAction, ExitButtonAction }) {
    const { copycatSequence, copycatGameState, copycatIsCardClickAllowed } = useContext(CopycatContext)

    let content = <></>

    switch (copycatGameState) {
        case COPYCAT_GAME_STATE_START_GAME:
            content = (
                <div className='justify-content-center mx-auto'>
                    <h3>Let's get started! Watch and remember the card that is played.</h3>
                    <ActionButtonComponent
                        text='Play'
                        width='5rem'
                        margins='0.5rem 0 0 0'
                        DoAction={() => {
                            InitCopycatToneSynth()
                            PlayButtonAction()
                        }}
                    />
                </div>
            )
            break

        case COPYCAT_GAME_STATE_START_PLAY:
        case COPYCAT_GAME_STATE_CONT_PLAY:
            content = (
                <div className='justify-content-center mx-auto'>
                    <h3>Watch and remember!</h3>
                </div>
            )
            break

        case COPYCAT_GAME_STATE_START_COPY:
            const message = (copycatSequence.length === 1)
                ? 'Click the card that was played.'
                : 'Click the cards in the order they were played.'

            content = (
                <div className='justify-content-center mx-auto'>
                    <h3>{message}</h3>
                    <ActionButtonComponent
                        text='Replay'
                        width='6rem'
                        margins='0.5rem 1rem 0 0'
                        DoAction={() => {
                            if (copycatIsCardClickAllowed) {
                                PlayButtonAction()
                            }
                        }}
                    />
                </div>
            )
            break

        case COPYCAT_GAME_STATE_CONT_COPY:
            content = (
                <div className='justify-content-center mx-auto'>
                    <h3>Click the cards in the order they were played.</h3>
                </div>
            )
            break

        case COPYCAT_GAME_STATE_CONT_GAME:
            content = (
                <div className='justify-content-center mx-auto'>
                    <h3>Let's keep going! Watch and remember the cards that are played.</h3>
                    <ActionButtonComponent
                        text='Play'
                        width='5rem'
                        margins='0.5rem 0 0 0'
                        DoAction={() => PlayButtonAction()}
                    />
                </div>
            )
            break

        case COPYCAT_GAME_STATE_END_GAME:
            content = (
                <div className='justify-content-center mx-auto'>
                    <h3>Sorry, that didn't match the correct order. Game over!</h3>
                    <ButtonGroup className='flex-wrap mx-auto'>
                        <ActionButtonComponent
                            text='Play Again'
                            width='8rem'
                            margins='0.5rem 1rem 0 0'
                            DoAction={() => RestartButtonAction()}
                        />
                        <ActionButtonComponent
                            text='Exit'
                            width='5rem'
                            margins='0.5rem 0 0 1rem'
                            DoAction={() => ExitButtonAction()}
                        />
                    </ButtonGroup>
                </div>
            )
            break

        default:
            break
    }

    return (
        <div className='copycat-control-div'>{content}</div>
    )
}

CopycatControlComponent.propTypes = {
    PlayButtonAction: PropTypes.func.isRequired,
    RestartButtonAction: PropTypes.func.isRequired,
    ExitButtonAction: PropTypes.func.isRequired,
}

function CopycatInstructionsModalComponent({ showModal, HandleModalClose }) {
    return (
        <Modal
            className='fade'
            size='lg'
            style={{ color: 'var(--dark-blue)' }}
            show={showModal}
            onHide={() => HandleModalClose()}
        >
            <Modal.Header className='bg-light py-3'>
                <Modal.Title className='text-center mx-auto'>
                    <h4 className='my-0'>How to Play Copy Cat</h4>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className='bg-light'>
                <div className='d-flex justify-content-center mx-4'>
                    <div className='justify-content-left'>
                        <h5>
                            Copy Cat is a memory game that gets more challenging the longer you play.
                            Your goal is to repeat the sequence of cards shown to you.
                            Copy the pattern by clicking on the cards in the same order.
                            If you match correctly, keep going with another card at the end of the sequence.
                        </h5>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer className='bg-light py-1' style={{ border: 'none' }}>
            </Modal.Footer>
        </Modal >
    )
}

CopycatInstructionsModalComponent.propTypes = {
    showModal: PropTypes.bool.isRequired,
    HandleModalClose: PropTypes.func.isRequired,
}
