import { useState, useEffect } from 'react'
import { createReducer, createAction } from '@reduxjs/toolkit'
import jwt_decode from 'jwt-decode'
import PropTypes from 'prop-types'
import {
    DispatchAction,
    GetReducerState,
    USER_AUTH_REDUCER,
    SubscribeActionListener,
    GetStringifiedState,
    DEFAULT_STRINGIFIED_STATE,
} from './ReduxStore'
import { RequestBackendUserLogin, RequestBackendUserTokenRefresh } from '../project/BackendUserOperations'
import { BACKEND_STATUS_SUCCESS, BACKEND_STATUS_FAILURE } from '../project/BackendResources'

// -------------------------------------------------------------------------------------------------
//  Internal state management
// -------------------------------------------------------------------------------------------------

const AUTH_STATUS = 'UserAuthState/AuthStatus'
const AUTH_TOKENS = 'UserAuthState/AuthTokens'
const AUTH_FAILURE = 'UserAuthState/AuthFailure'
const AUTH_OPERATION = 'UserAuthState/AuthOperation'

const AUTH_STATUS_LOGGED_OUT = 'UserAuthState/AuthStatus/LoggedOut'
const AUTH_STATUS_LOGGED_IN = 'UserAuthState/AuthStatus/LoggedIn'

const AUTH_TOKENS_INVALID = null

const AUTH_FAILURE_NONE = 'UserAuthState/AuthFailure/None'
const AUTH_FAILURE_BAD_CREDENTIALS = 'UserAuthState/AuthFailure/BadCredentials'
const AUTH_FAILURE_EXPIRED_TOKEN = 'UserAuthState/AuthFailure/ExpiredToken'

const AUTH_OPERATION_NONE = 'UserAuthState/AuthOperation/None'
const AUTH_OPERATION_LOGGING_IN = 'UserAuthState/AuthOperation/LoggingIn'
const AUTH_OPERATION_REFRESHING = 'UserAuthState/AuthOperation/Refreshing'

const defaultState = {
    [AUTH_STATUS]: AUTH_STATUS_LOGGED_OUT,
    [AUTH_TOKENS]: AUTH_TOKENS_INVALID,
    [AUTH_FAILURE]: AUTH_FAILURE_NONE,
    [AUTH_OPERATION]: AUTH_OPERATION_NONE
}

const STORED_STATE_KEY = 'LevelUpArtic/UserAuthState'

const StoreUserAuthState = (state) => {
    localStorage.setItem(STORED_STATE_KEY, JSON.stringify(state))
}

const GetAuthStatus = () => {
    return GetReducerState(USER_AUTH_REDUCER)[AUTH_STATUS]
}

const GetAuthTokens = () => {
    return GetReducerState(USER_AUTH_REDUCER)[AUTH_TOKENS]
}

const GetAccessToken = () => {
    const tokens = GetAuthTokens()

    if (!tokens || tokens === AUTH_TOKENS_INVALID) {
        return null
    }

    return tokens.access
}

const GetDecodedAccessToken = () => {
    const accessToken = GetAccessToken()
    return accessToken ? jwt_decode(accessToken) : null
}

const GetRefreshToken = () => {
    const tokens = GetAuthTokens()

    if (!tokens || tokens === AUTH_TOKENS_INVALID) {
        return null
    }

    return tokens.refresh
}

const GetAuthFailure = () => {
    return GetReducerState(USER_AUTH_REDUCER)[AUTH_FAILURE]
}

const GetAuthOperation = () => {
    return GetReducerState(USER_AUTH_REDUCER)[AUTH_OPERATION]
}

// -------------------------------------------------------------------------------------------------
//  External state access
// -------------------------------------------------------------------------------------------------

export const USER_AUTH_OPERATION_NONE = AUTH_OPERATION_NONE

export const GetInitialUserAuthState = () => {
    const storedState = localStorage.getItem(STORED_STATE_KEY)
    return storedState ? JSON.parse(storedState) : defaultState
}

export function UserAuthStateChangeListener({ callback }) {
    const [stringifiedState, SetStringifiedState] = useState(DEFAULT_STRINGIFIED_STATE)

    useEffect(() => {
        callback(stringifiedState)
    }, [stringifiedState, callback])

    const HandleActionNotification = () => {
        SetStringifiedState(GetStringifiedState(USER_AUTH_REDUCER))
    }

    useEffect(() => {
        SubscribeActionListener(HandleActionNotification)
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    return <></>
}

UserAuthStateChangeListener.propTypes = {
    callback: PropTypes.func.isRequired,
}

export const IsUserLoggedIn = () => {
    return GetAuthStatus() === AUTH_STATUS_LOGGED_IN
}

export const IsUserAdmin = () => {
    const decodedAccessToken = GetDecodedAccessToken()

    if (!IsUserLoggedIn() || !decodedAccessToken) {
        return false
    }

    return decodedAccessToken.isAdmin
}

export const GetUserAuthAccessToken = () => {
    return GetAccessToken()
}

export const GetUserAuthRefreshToken = () => {
    return GetRefreshToken()
}

export const GetUserAuthFailure = () => {
    return GetAuthFailure()
}

export const GetUserAuthOperation = () => {
    return GetAuthOperation()
}

// -------------------------------------------------------------------------------------------------
//  Internal action management
// -------------------------------------------------------------------------------------------------

const RESET_AUTH = 'UserAuthAction/ResetAuth'
const LOGIN_REQUEST = 'UserAuthAction/LoginRequest'
const LOGIN_SUCCESS = 'UserAuthAction/LoginSuccess'
const LOGIN_FAILURE = 'UserAuthAction/LoginFailure'
const REFRESH_REQUEST = 'UserAuthAction/RefreshRequest'
const REFRESH_SUCCESS = 'UserAuthAction/RefreshSuccess'
const REFRESH_FAILURE = 'UserAuthAction/RefreshFailure'
const LOGOUT_REQUEST = 'UserAuthAction/LogoutRequest'

const resetAuthAction = createAction(RESET_AUTH)
const loginRequestAction = createAction(LOGIN_REQUEST)
const loginSuccessAction = createAction(LOGIN_SUCCESS)
const loginFailureAction = createAction(LOGIN_FAILURE)
const refreshRequestAction = createAction(REFRESH_REQUEST)
const refreshSuccessAction = createAction(REFRESH_SUCCESS)
const refreshFailureAction = createAction(REFRESH_FAILURE)
const logoutRequestAction = createAction(LOGOUT_REQUEST)

// -------------------------------------------------------------------------------------------------
//  External action access
// -------------------------------------------------------------------------------------------------

export const ResetUserAuth = () => {
    DispatchAction(resetAuthAction)
}

export const LogInUser = (credentials) => {
    DispatchAction(loginRequestAction, credentials)
}

export const RefreshUserAccessToken = () => {
    DispatchAction(refreshRequestAction, GetRefreshToken())
}

export const LogOutUser = () => {
    DispatchAction(logoutRequestAction)
}

// -------------------------------------------------------------------------------------------------
//  Internal reducer management
// -------------------------------------------------------------------------------------------------

const UpdateAuthState = (state, status, tokens, failure, operation) => {
    state[AUTH_STATUS] = status
    state[AUTH_TOKENS] = tokens
    state[AUTH_FAILURE] = failure
    state[AUTH_OPERATION] = operation
}

const ResetAuth = (state) => {
    if (state[AUTH_STATUS] !== AUTH_STATUS_LOGGED_IN) {
        UpdateAuthState(state, AUTH_STATUS_LOGGED_OUT, AUTH_TOKENS_INVALID, AUTH_FAILURE_NONE, AUTH_OPERATION_NONE)
        StoreUserAuthState(state)
    }
}

const HandleLoginRequest = (state, action) => {
    UpdateAuthState(state, AUTH_STATUS_LOGGED_OUT, AUTH_TOKENS_INVALID, AUTH_FAILURE_NONE, AUTH_OPERATION_LOGGING_IN)
    RequestBackendUserLogin(action.payload, HandleBackendUserLoginResponse)
}

const HandleBackendUserLoginResponse = (status, response) => {
    if (status === BACKEND_STATUS_SUCCESS) {
        DispatchAction(loginSuccessAction, response)
    }
    else if (status === BACKEND_STATUS_FAILURE) {
        DispatchAction(loginFailureAction, response)
    }
}

const HandleLoginSuccess = (state, action) => {
    UpdateAuthState(state, AUTH_STATUS_LOGGED_IN, action.payload, AUTH_FAILURE_NONE, AUTH_OPERATION_NONE)
    StoreUserAuthState(state)
}

const HandleLoginFailure = (state) => {
    // TODO: Use action.payload to set the AUTH_FAILURE state. Just assume bad credentials for now.
    UpdateAuthState(state, AUTH_STATUS_LOGGED_OUT, AUTH_TOKENS_INVALID, AUTH_FAILURE_BAD_CREDENTIALS, AUTH_OPERATION_NONE)
    StoreUserAuthState(state)
}

const HandleRefreshRequest = (state, action) => {
    if (state[AUTH_OPERATION] !== AUTH_OPERATION_NONE) {
        return
    }

    state[AUTH_OPERATION] = AUTH_OPERATION_REFRESHING
    RequestBackendUserTokenRefresh(action.payload, HandleBackendRefreshResponse)
}

const HandleBackendRefreshResponse = (status, response) => {
    if (status === BACKEND_STATUS_SUCCESS) {
        DispatchAction(refreshSuccessAction, response)
    }
    else if (status === BACKEND_STATUS_FAILURE) {
        DispatchAction(refreshFailureAction, response)
    }
}

const HandleRefreshSuccess = (state, action) => {
    UpdateAuthState(state, AUTH_STATUS_LOGGED_IN, action.payload, AUTH_FAILURE_NONE, AUTH_OPERATION_NONE)
    StoreUserAuthState(state)
}

const HandleRefreshFailure = (state) => {
    // TODO: Use action.payload to set the AUTH_FAILURE state. Just assume an expired token for now.
    UpdateAuthState(state, AUTH_STATUS_LOGGED_OUT, AUTH_TOKENS_INVALID, AUTH_FAILURE_EXPIRED_TOKEN, AUTH_OPERATION_NONE)
    StoreUserAuthState(state)
}

const HandleLogoutRequest = (state) => {
    UpdateAuthState(state, AUTH_STATUS_LOGGED_OUT, AUTH_TOKENS_INVALID, AUTH_FAILURE_NONE, AUTH_OPERATION_NONE)
    StoreUserAuthState(state)
}

const userAuthReducer = createReducer(defaultState, (builder) => {
    builder.addCase(resetAuthAction, (state, action) => {
        ResetAuth(state, action)
    })

    builder.addCase(loginRequestAction, (state, action) => {
        HandleLoginRequest(state, action)
    })

    builder.addCase(loginSuccessAction, (state, action) => {
        HandleLoginSuccess(state, action)
    })

    builder.addCase(loginFailureAction, (state, action) => {
        HandleLoginFailure(state, action)
    })

    builder.addCase(refreshRequestAction, (state, action) => {
        HandleRefreshRequest(state, action)
    })

    builder.addCase(refreshSuccessAction, (state, action) => {
        HandleRefreshSuccess(state, action)
    })

    builder.addCase(refreshFailureAction, (state, action) => {
        HandleRefreshFailure(state, action)
    })

    builder.addCase(logoutRequestAction, (state, action) => {
        HandleLogoutRequest(state, action)
    })

    builder.addDefaultCase(() => { })
})

// -------------------------------------------------------------------------------------------------
//  External reducer access
// -------------------------------------------------------------------------------------------------

export const GetUserAuthReducer = () => {
    return userAuthReducer
}
