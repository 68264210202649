import { useState, useEffect, useCallback } from 'react'
import { Container, Row, Col, ButtonGroup, Dropdown, Modal } from 'react-bootstrap'
import PropTypes from 'prop-types'
import { FLASHCARDS_PATH, MEMORY_MATCH_PATH, TIC_TAC_TOE_PATH, COPYCAT_PATH } from '../App'
import {
    GetTargetSoundChoices, GetPositionChoices, GetValidPositionChoices, GetLevelChoices,
    WORD_SET_CORE, WORD_SET_ALL
} from '../project/SoundPositionLevelChoices'
import { ActionButtonComponent, ActionDropdownComponent } from '../components/ActionButtonComponents'
import { SearchParamSelectComponent } from '../components/SearchParamComponents'
import { SelectAllCheckboxComponent } from '../components/SelectAllCheckboxComponent'
import { WordCardComponent } from '../components/WordCardComponent'
import { WordCheckboxComponent } from '../components/WordCheckboxComponent'
import {
    SelectQuickSearchTargetSound,
    SelectQuickSearchPosition,
    DeselectQuickSearchPosition,
    SelectQuickSearchLevel,
    DeselectQuickSearchLevel,
    SelectQuickSearchWordSet,
    RunQuickSearch,
    ClearQuickSearchConfig,
    SelectQuickSearchWord,
    DeselectQuickSearchWord,
    SelectAllQuickSearchWords,
    DeselectAllQuickSearchWords,
    GetQuickSearchSelectedTargetSound,
    IsQuickSearchTargetSoundSelected,
    GetQuickSearchSelectedPositions,
    AreAnyQuickSearchPositionsSelected,
    GetQuickSearchSelectedLevels,
    AreAnyQuickSearchLevelsSelected,
    GetQuickSearchSelectedWordSet,
    GetQuickSearchResults,
    GetQuickSearchSelectedWords,
    QuickSearchStateChangeListener,
} from '../state/QuickSearchState'
import './QuickStartComponents.css'

export function QuickStartSetupComponent({ HandleSearchUpdate, showModal = false, CloseModal = () => { } }) {
    const [quickSearchStateChangeString, SetQuickSearchStateChangeString] = useState('')

    useEffect(() => {
        HandleSearchUpdate(quickSearchStateChangeString)
    }, [quickSearchStateChangeString, HandleSearchUpdate])

    const HandleQuickSearchStateChange = useCallback((listenerString) => {
        SetQuickSearchStateChangeString(listenerString)
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const GetTargetSoundParamChoices = () => {
        let targetSoundParamChoices = {}

        for (let choice of GetTargetSoundChoices()) {
            targetSoundParamChoices[choice] = choice
        }

        return targetSoundParamChoices
    }

    const GetPositionParamChoices = () => {
        const selectedTargetSound = GetQuickSearchSelectedTargetSound()

        if (!selectedTargetSound || selectedTargetSound === '') {
            return {}
        }

        const validPositionChoices = GetValidPositionChoices()

        if (!validPositionChoices[selectedTargetSound]) {
            return {}
        }

        const positionChoices = GetPositionChoices()
        let positionParamChoices = {}

        for (let choice of validPositionChoices[selectedTargetSound]) {
            positionParamChoices[choice] = positionChoices[choice]
        }

        return positionParamChoices
    }

    const GetLevelParamChoices = () => {
        if (GetQuickSearchSelectedPositions().length === 0) {
            return {}
        }

        let levelParamChoices = {}

        for (let choice of GetLevelChoices()) {
            levelParamChoices[choice] = choice
        }

        return levelParamChoices
    }

    const GetWordSetChoices = () => {
        if (GetQuickSearchSelectedLevels().length === 0) {
            return {}
        }

        const wordSetChoices = {
            [WORD_SET_CORE]: 'Core Words',
            [WORD_SET_ALL]: 'All Words'
        }

        return wordSetChoices
    }

    const IsGoButtonActive = () => {
        return IsQuickSearchTargetSoundSelected() && AreAnyQuickSearchPositionsSelected() && AreAnyQuickSearchLevelsSelected()
    }

    const IsClearButtonActive = () => {
        return IsQuickSearchTargetSoundSelected()
    }

    return (
        <>
            <QuickSearchStateChangeListener callback={HandleQuickSearchStateChange} />
            <Container className='mx-auto mt-3 mb-2 ps-4'>
                <ButtonGroup className='flex-wrap mx-4'>
                    <SearchParamSelectComponent
                        paramSelectLabel={'Target Sound'}
                        paramSelectChoices={GetTargetSoundParamChoices()}
                        selectedParams={[GetQuickSearchSelectedTargetSound()]}
                        SelectParam={targetSound => {
                            ClearQuickSearchConfig()
                            SelectQuickSearchTargetSound(targetSound)
                        }}
                        DeselectParam={() => { }}
                        boxColor='var(--dark-orange)'
                    />
                    <SearchParamSelectComponent
                        paramSelectLabel={'Positions'}
                        paramSelectChoices={GetPositionParamChoices()}
                        selectedParams={GetQuickSearchSelectedPositions()}
                        SelectParam={position => SelectQuickSearchPosition(position)}
                        DeselectParam={position => DeselectQuickSearchPosition(position)}
                        boxWidth='12rem'
                        boxColor='var(--dark-orange)'
                    />
                    <SearchParamSelectComponent
                        paramSelectLabel={'Levels'}
                        paramSelectChoices={GetLevelParamChoices()}
                        selectedParams={GetQuickSearchSelectedLevels()}
                        SelectParam={level => SelectQuickSearchLevel(level)}
                        DeselectParam={level => DeselectQuickSearchLevel(level)}
                        boxColor='var(--dark-orange)'
                    />
                    <SearchParamSelectComponent
                        paramSelectLabel={'Word Set'}
                        paramSelectChoices={GetWordSetChoices()}
                        selectedParams={[GetQuickSearchSelectedWordSet()]}
                        SelectParam={wordSet => SelectQuickSearchWordSet(wordSet)}
                        DeselectParam={() => { }}
                        boxColor='var(--dark-orange)'
                    />
                    <div className='vstack ms-3 mt-4' style={{ maxWidth: '10rem' }}>
                        <div className='w-50 my-2'>
                            <ActionButtonComponent
                                text='Go'
                                width='5.5rem'
                                margins={'0 1rem 0 0'}
                                isActive={IsGoButtonActive()}
                                DoAction={() => {
                                    RunQuickSearch()
                                }} />
                        </div>
                        <div className='w-50 my-1'>
                            <ActionButtonComponent
                                text='Clear'
                                width='5.5rem'
                                isActive={IsClearButtonActive()}
                                DoAction={() => {
                                    ClearQuickSearchConfig()
                                }} />
                        </div>
                    </div>
                </ButtonGroup>
            </Container>
            <QuickStartSetupInstructionsModalComponent
                showModal={showModal}
                HandleModalClose={() => CloseModal()}
            />
        </>
    )
}

QuickStartSetupComponent.propTypes = {
    HandleSearchUpdate: PropTypes.func.isRequired,
    showModal: PropTypes.bool,
    CloseModal: PropTypes.func,
}

function QuickStartSetupInstructionsModalComponent({ showModal, HandleModalClose }) {
    return (
        <Modal
            className='fade'
            style={{ color: 'var(--dark-blue)' }}
            show={showModal}
            onHide={() => {
                HandleModalClose()
            }}
        >
            <Modal.Header className='bg-light py-3'>
                <Modal.Title className='text-center mx-auto'>
                    <h4 className='my-0'>Create a New List in 3 Easy Steps</h4>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className='bg-light'>
                <div className='d-flex justify-content-center mx-auto'>
                    <div className='justify-content-left'>
                        <h5>1. Choose a target sound.</h5>
                        <h5>2. Choose one or more positions.</h5>
                        <h5>3. Choose one or more levels.</h5>
                    </div>
                </div>
                <h5 className='text-center mx-auto mt-2' >
                    Click Go, and you're ready to use your list!
                </h5>
            </Modal.Body>
            <Modal.Footer className='bg-light py-1' style={{ border: 'none' }}>
            </Modal.Footer>
        </Modal >
    )
}

QuickStartSetupInstructionsModalComponent.propTypes = {
    showModal: PropTypes.bool.isRequired,
    HandleModalClose: PropTypes.func.isRequired,
}

export function QuickStartActionComponent({ StartActivity, CreateList, CreatePdf, showModal = false, CloseModal = () => { } }) {
    return (
        <>
            <Container className='mx-auto mt-0 mb-0 ps-2'>
                <ButtonGroup className='flex-wrap ps-5 pt-4 pb-3'>
                    <ActionDropdownComponent
                        text='Start Activity'
                        margins={'0 0.5rem 1rem 0.5rem'}
                        DoAction={event => {
                            if (event.includes('|')) {
                                const eventFields = event.split('|')
                                const activityPath = eventFields[0]
                                const storyKey = eventFields[1]
                                StartActivity(activityPath, [], storyKey)
                            }
                            else {
                                const activityPath = event
                                StartActivity(activityPath, GetQuickSearchSelectedWords(), '')
                            }
                        }}
                    >
                        <Dropdown.Item eventKey={FLASHCARDS_PATH}>Flashcards</Dropdown.Item>
                        <Dropdown.Item eventKey={MEMORY_MATCH_PATH}>Memory Match</Dropdown.Item>
                        <Dropdown.Item eventKey={TIC_TAC_TOE_PATH}>Tic Tac Toe</Dropdown.Item>
                        <Dropdown.Item eventKey={COPYCAT_PATH}>Copy Cat</Dropdown.Item>
                    </ActionDropdownComponent>
                    <ActionButtonComponent
                        text='Save List'
                        width='7.5rem'
                        margins={'0 0.5rem 1rem 0.5rem'}
                        DoAction={() => CreateList(GetQuickSearchSelectedWords())}
                    />
                    <ActionButtonComponent
                        text='Create PDF'
                        width='8.5rem'
                        margins={'0 0.5rem 1rem 0.5rem'}
                        DoAction={() => {
                            CreatePdf(GetQuickSearchSelectedWords())
                        }}
                    />
                </ButtonGroup>
            </Container>
            <QuickStartActionInstructionsModalComponent
                showModal={showModal}
                HandleModalClose={() => CloseModal()}
            />
        </>
    )
}

QuickStartActionComponent.propTypes = {
    StartActivity: PropTypes.func.isRequired,
    CreateList: PropTypes.func.isRequired,
    CreatePdf: PropTypes.func.isRequired,
    showModal: PropTypes.bool,
    CloseModal: PropTypes.func,
}

function QuickStartActionInstructionsModalComponent({ showModal, HandleModalClose }) {
    return (
        <Modal
            className='fade modal-lg'
            style={{ color: 'var(--dark-blue)' }}
            show={showModal}
            onHide={() => {
                HandleModalClose()
            }}
        >
            <Modal.Header className='bg-light py-3'>
                <Modal.Title className='text-center mx-auto'>
                    <h4 className='my-0'>What To Do With Your List</h4>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className='bg-light'>
                <div className='d-flex justify-content-center mx-auto'>
                    <div className='justify-content-left'>
                        <h5>&#x27A4; Customize it by selecting and deselecting words.</h5>
                        <h5>&#x27A4; Use it in Flashcards or another activity.</h5>
                        <h5>&#x27A4; Save it to a permanent list to use later.</h5>
                        <h5>&#x27A4; Create a printable copy of the words.</h5>
                    </div>
                </div>
                <div className='justify-content-center mx-4 mt-3'>
                    <h5>
                        Regarding Create PDF: Some browsers, particularly on mobile devices, block new tabs.
                        The PDF document opens in the current tab instead.
                        Use the browser's back navigation to return to Quick Start.
                        To change this behavior, configure the browser settings to enable pop-ups.
                    </h5>
                </div>
            </Modal.Body>
            <Modal.Footer className='bg-light py-1' style={{ border: 'none' }}>
            </Modal.Footer>
        </Modal >
    )
}

QuickStartActionInstructionsModalComponent.propTypes = {
    showModal: PropTypes.bool.isRequired,
    HandleModalClose: PropTypes.func.isRequired,
}

export function QuickStartWordsComponent() {
    const [renderWordsComponentFlag, SetRenderWordsComponentFlag] = useState(false)

    useEffect(() => { }, [renderWordsComponentFlag])

    const wordCardsAndCheckboxes = GetQuickSearchResults().map((wordKey, index) => (
        <Col xs={6} sm={4} md={3} lg={2} key={index}>
            <ButtonGroup className='vstack mt-0 mb-1 my-lg-1'>
                <WordCardComponent wordKey={wordKey} selectedWords={GetQuickSearchSelectedWords()}></WordCardComponent>
                <WordCheckboxComponent
                    wordKey={wordKey}
                    selectedWords={GetQuickSearchSelectedWords()}
                    SelectWord={(wordKey) => {
                        SelectQuickSearchWord(wordKey)
                        SetRenderWordsComponentFlag(!renderWordsComponentFlag)
                    }}
                    DeselectWord={(wordKey) => {
                        DeselectQuickSearchWord(wordKey)
                        SetRenderWordsComponentFlag(!renderWordsComponentFlag)
                    }}
                />
            </ButtonGroup>
        </Col>
    ))

    return (
        <>
            <SelectAllCheckboxComponent
                allWords={GetQuickSearchResults()}
                selectedWords={GetQuickSearchSelectedWords()}
                SelectAllWords={() => {
                    SelectAllQuickSearchWords()
                    SetRenderWordsComponentFlag(!renderWordsComponentFlag)
                }}
                DeselectAllWords={() => {
                    DeselectAllQuickSearchWords()
                    SetRenderWordsComponentFlag(!renderWordsComponentFlag)
                }}
            />
            <Row className='mt-0 g-2'>{wordCardsAndCheckboxes}</Row>
        </>
    )
}
