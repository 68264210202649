import { useState, useEffect } from 'react'
import { createReducer, createAction } from '@reduxjs/toolkit'
import PropTypes from 'prop-types'
import { GetReducerState, CARRYOVER_REDUCER, DispatchAction, SubscribeActionListener,
         GetStringifiedState, DEFAULT_STRINGIFIED_STATE } from './ReduxStore'

// -------------------------------------------------------------------------------------------------
//  Internal state management
// -------------------------------------------------------------------------------------------------

const SELECTED_ACTIVITY = 'CarryoverState/selectedActivity'
const SELECTED_TARGET_SOUND = 'CarryoverState/SetSelectedTargetSound'
const SELECTED_READING_LEVEL = 'CarryoverState/SetSelectedReadingLevel'
const SELECTED_TARGET_SOUND_GROUP = 'CarryoverState/SetSelectedTargetSoundGroup'
const SELECTED_STORY = 'CarryoverState/SetSelectedStory'

const DEFAULT_STRING_PARAM = ''

const defaultState = {
    [SELECTED_ACTIVITY]: DEFAULT_STRING_PARAM,
    [SELECTED_TARGET_SOUND]: DEFAULT_STRING_PARAM,
    [SELECTED_READING_LEVEL]: DEFAULT_STRING_PARAM,
    [SELECTED_TARGET_SOUND_GROUP]: DEFAULT_STRING_PARAM,
    [SELECTED_STORY]: DEFAULT_STRING_PARAM,
}

const STORED_STATE_KEY = 'LevelUpArtic/carryoverState'

const StoreCarryoverState = state => {
    sessionStorage.setItem(STORED_STATE_KEY, JSON.stringify(state))
}

const GetSelectedActivity = () => {
    return GetReducerState(CARRYOVER_REDUCER)[SELECTED_ACTIVITY]
}

const GetSelectedTargetSound = () => {
    return GetReducerState(CARRYOVER_REDUCER)[SELECTED_TARGET_SOUND]
}

const GetSelectedReadingLevel = () => {
    return GetReducerState(CARRYOVER_REDUCER)[SELECTED_READING_LEVEL]
}

const GetSelectedTargetSoundGroup = () => {
    return GetReducerState(CARRYOVER_REDUCER)[SELECTED_TARGET_SOUND_GROUP]
}

const GetSelectedStory = () => {
    return GetReducerState(CARRYOVER_REDUCER)[SELECTED_STORY]
}

// -------------------------------------------------------------------------------------------------
//  External state access
// -------------------------------------------------------------------------------------------------

export const GetInitialCarryoverState = () => {
    const storedState = sessionStorage.getItem(STORED_STATE_KEY)
    return storedState ? JSON.parse(storedState) : defaultState
}

export function CarryoverStateChangeListener({ callback }) {
    const [stringifiedState, SetStringifiedState] = useState(DEFAULT_STRINGIFIED_STATE)

    useEffect(() => {
        callback(stringifiedState)
    }, [stringifiedState, callback])

    const HandleActionNotification = () => {
        SetStringifiedState(GetStringifiedState(CARRYOVER_REDUCER))
    }

    useEffect(() => {
        SubscribeActionListener(HandleActionNotification)
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    return <></>
}

CarryoverStateChangeListener.propTypes = {
    callback: PropTypes.func.isRequired,
}

export const GetCarryoverSelectedActivity = () => {
    return GetSelectedActivity()
}

export const GetCarryoverSelectedTargetSound = () => {
    return GetSelectedTargetSound()
}

export const GetCarryoverSelectedReadingLevel = () => {
    return GetSelectedReadingLevel()
}

export const GetCarryoverSelectedTargetSoundGroup = () => {
    return GetSelectedTargetSoundGroup()
}

export const GetCarryoverSelectedStory = () => {
    return GetSelectedStory()
}

// -------------------------------------------------------------------------------------------------
//  Internal action management
// -------------------------------------------------------------------------------------------------

const SELECT_ACTIVITY = 'CarryoverAction/selectActivity'
const SELECT_TARGET_SOUND = 'CarryoverAction/selectTargetSound'
const SELECT_READING_LEVEL = 'CarryoverAction/selectReadingLevel'
const SELECT_TARGET_SOUND_GROUP = 'CarryoverAction/selectTargetSoundGroup'
const SELECT_STORY = 'CarryoverAction/selectStory'

const selectActivityAction = createAction(SELECT_ACTIVITY)
const selectTargetSoundAction = createAction(SELECT_TARGET_SOUND)
const selectReadingLevelAction = createAction(SELECT_READING_LEVEL)
const selectTargetSoundGroupAction = createAction(SELECT_TARGET_SOUND_GROUP)
const selectStoryAction = createAction(SELECT_STORY)

// -------------------------------------------------------------------------------------------------
//  External action access
// -------------------------------------------------------------------------------------------------

export const SelectCarryoverActivity = activity => {
    DispatchAction(selectActivityAction, activity)
}

export const SelectCarryoverTargetSound = targetSound => {
    DispatchAction(selectTargetSoundAction, targetSound)
}

export const SelectCarryoverReadingLevel = readingLevel => {
    DispatchAction(selectReadingLevelAction, readingLevel)
}

export const SelectCarryoverTargetSoundGroup = targetSoundGroup => {
    DispatchAction(selectTargetSoundGroupAction, targetSoundGroup)
}

export const SelectCarryoverStory = story => {
    DispatchAction(selectStoryAction, story)
}

// -------------------------------------------------------------------------------------------------
//  Internal reducer management
// -------------------------------------------------------------------------------------------------

const ClearCarryoverState = state => {
    state[SELECTED_ACTIVITY] = DEFAULT_STRING_PARAM
    state[SELECTED_TARGET_SOUND] = DEFAULT_STRING_PARAM
    state[SELECTED_TARGET_SOUND_GROUP] = DEFAULT_STRING_PARAM
    state[SELECTED_READING_LEVEL] = DEFAULT_STRING_PARAM
    state[SELECTED_STORY] = DEFAULT_STRING_PARAM
}

const HandleSelectActivityReq = (state, action) => {
    const selectedActivity = action.payload

    if (selectedActivity && (selectedActivity !== state[SELECTED_ACTIVITY])) {
        ClearCarryoverState(state)
        state[SELECTED_ACTIVITY] = selectedActivity
        StoreCarryoverState(state)
    }
}

const HandleSelectTargetSoundReq = (state, action) => {
    const selectedTargetSound = action.payload

    if (selectedTargetSound && (selectedTargetSound !== state[SELECTED_TARGET_SOUND])) {
        if (state[SELECTED_ACTIVITY] === 'pick-a-path') {
            state[SELECTED_STORY] = DEFAULT_STRING_PARAM
        }
        else if (state[SELECTED_ACTIVITY] === 'whimprovs') {
            state[SELECTED_TARGET_SOUND_GROUP] = DEFAULT_STRING_PARAM
        }

        state[SELECTED_TARGET_SOUND] = selectedTargetSound
        StoreCarryoverState(state)
    }
}

const HandleSelectReadingLevelReq = (state, action) => {
    const selectedReadingLevel = action.payload

    if (selectedReadingLevel && (selectedReadingLevel !== state[SELECTED_READING_LEVEL])) {
        if (state[SELECTED_ACTIVITY] === 'pick-a-path') {
            state[SELECTED_STORY] = DEFAULT_STRING_PARAM
        }

        state[SELECTED_READING_LEVEL] = selectedReadingLevel
        StoreCarryoverState(state)
    }
}

const HandleSelectTargetSoundGroupReq = (state, action) => {
    const selectedTargetSoundGroup = action.payload

    if (selectedTargetSoundGroup && (selectedTargetSoundGroup !== state[SELECTED_TARGET_SOUND_GROUP])) {
        state[SELECTED_TARGET_SOUND_GROUP] = selectedTargetSoundGroup
        StoreCarryoverState(state)
    }
}

const HandleSelectStoryReq = (state, action) => {
    const selectedStory = action.payload

    if (selectedStory && (selectedStory !== state[SELECTED_STORY])) {
        state[SELECTED_STORY] = selectedStory
        StoreCarryoverState(state)
    }
}

const carryoverReducer = createReducer(defaultState, builder => {
    builder.addCase(selectActivityAction, (state, action) => {
        HandleSelectActivityReq(state, action)
    })

    builder.addCase(selectTargetSoundAction, (state, action) => {
        HandleSelectTargetSoundReq(state, action)
    })

    builder.addCase(selectReadingLevelAction, (state, action) => {
        HandleSelectReadingLevelReq(state, action)
    })

    builder.addCase(selectTargetSoundGroupAction, (state, action) => {
        HandleSelectTargetSoundGroupReq(state, action)
    })

    builder.addCase(selectStoryAction, (state, action) => {
        HandleSelectStoryReq(state, action)
    })

    builder.addDefaultCase(() => { })
})

// -------------------------------------------------------------------------------------------------
//  External reducer access
// -------------------------------------------------------------------------------------------------

export const GetCarryoverReducer = () => {
    return carryoverReducer
}
