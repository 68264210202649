import { useState, useEffect } from 'react'
import { IsUserLoggedIn, GetUserAuthAccessToken, RefreshUserAccessToken, UserAuthStateChangeListener } from '../state/UserAuthState'

export function UserAuthTokenRefreshComponent() {
    const [isLoggedIn, SetIsLoggedIn] = useState(false)
    const [accessToken, SetAccessToken] = useState(null)
    const [firstPass, SetFirstPass] = useState(true)

    const REFRESH_INTERVAL_MSEC = 60 * 60 * 1000

    useEffect(() => {
        if (!isLoggedIn) {
            SetFirstPass(true)
            return
        }

        if (firstPass) {
            SetFirstPass(false)
            RefreshUserAccessToken()
        }

        let interval = setInterval(() => {
            RefreshUserAccessToken()
            clearInterval(interval)
        }, REFRESH_INTERVAL_MSEC)
    }, [isLoggedIn, accessToken]) // eslint-disable-line react-hooks/exhaustive-deps

    const HandleUserStateChange = (_listenerString) => {
        const newIsLoggedIn = IsUserLoggedIn()
        const newAccessToken = GetUserAuthAccessToken()

        if (newIsLoggedIn !== isLoggedIn) {
            SetIsLoggedIn(newIsLoggedIn)
        }

        if (newAccessToken !== accessToken) {
            SetAccessToken(newAccessToken)
        }
    }

    return (
        <>
            <UserAuthStateChangeListener callback={HandleUserStateChange} />
        </>
    )
}
