import { useState, useEffect, useCallback, useContext } from 'react'
import { Container, Form, Modal } from 'react-bootstrap'
import { useNavigate, Link } from 'react-router-dom'
import { HOME_PATH, USER_ACCOUNT_PATH, PASSWORD_RESET_REQUEST_PATH } from '../App'
import { UserAuthStateChangeListener } from '../state/UserAuthState'
import {
    UserAccountStateChangeListener, LoadUserAccount, CreateUserAccount, SendUserAccountEmail,
    GetUserAccountStatus, GetUserAccountInfo, GetUserAccountOperation, GetUserAccountEmailStatus,
    USER_ACCOUNT_STATUS_ACTIVE, USER_ACCOUNT_STATUS_INACTIVE, USER_ACCOUNT_OPERATION_NONE, USER_ACCOUNT_EMAIL_STATUS_SUCCESS, USER_ACCOUNT_EMAIL_STATUS_FAILURE
} from '../state/UserAccountState'
import { ActionButtonComponent } from './ActionButtonComponents'
import { SuccessMessageComponent, ErrorMessageComponent } from './MessageComponents'
import { LoaderComponent } from './LoaderComponents'
import { SUBSCRIPTION_TYPE_MONTH, SUBSCRIPTION_TYPE_YEAR } from './SubscriptionComponents'
import { LoginSignupContext, LoginSignupListener } from '../context/LoginSignupContext'
import { LogInUser, IsUserLoggedIn, GetUserAuthOperation, USER_AUTH_OPERATION_NONE } from '../state/UserAuthState'
import { IsEmailValid, IsPasswordValid } from '../project/Utils'
import './LoginSignupComponents.css'

export function LoginComponent() {
    const { activationMessageVisibility, SetActivationMessageVisibility } = useContext(LoginSignupContext)

    const [loginEmail, SetLoginEmail] = useState('')
    const [loginPassword, SetLoginPassword] = useState('')

    const LOGIN_STEP_NONE = 'LoginStep/None'
    const LOGIN_STEP_SUBMIT_CREDENTIALS = 'LoginStep/SubmitCredentials'

    const [loginStep, SetLoginStep] = useState(LOGIN_STEP_NONE)

    const LOGIN_MESSAGE_TYPE_NONE = 'LoginMessageType/None'
    const LOGIN_MESSAGE_TYPE_SUCCESS = 'LoginMessageType/Success'
    const LOGIN_MESSAGE_TYPE_ERROR = 'LoginMessageType/Error'

    const [loginMessageType, SetLoginMessageType] = useState(LOGIN_MESSAGE_TYPE_NONE)
    const [loginMessage, SetLoginMessage] = useState('')
    const [isLoginMessageDismissible, SetIsLoginMessageDismissible] = useState(true)

    const ClearLoginMessage = () => {
        SetLoginMessageType(LOGIN_MESSAGE_TYPE_NONE)
        SetLoginMessage('')
        SetIsLoginMessageDismissible(true)
    }

    const SetLoginSuccessMessage = (message, isDismissible = true) => {
        SetLoginMessageType(LOGIN_MESSAGE_TYPE_SUCCESS)
        SetLoginMessage(message)
        SetIsLoginMessageDismissible(isDismissible)
    }

    const SetLoginErrorMessage = (message, isDismissible = true) => {
        SetLoginMessageType(LOGIN_MESSAGE_TYPE_ERROR)
        SetLoginMessage(message)
        SetIsLoginMessageDismissible(isDismissible)
    }

    const navigate = useNavigate()
    const [isUserLoggedIn, SetIsUserLoggedIn] = useState(false)

    useEffect(() => {
        if (isUserLoggedIn) {
            navigate(USER_ACCOUNT_PATH)
        }
    }, [isUserLoggedIn, navigate])

    useEffect(() => {
        ClearLoginMessage()
        SetIsUserLoggedIn(IsUserLoggedIn())
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => { }, [loginMessageType, loginMessage])

    const SubmitLoginRequest = () => {
        ClearLoginMessage()
        SetLoginStep(LOGIN_STEP_SUBMIT_CREDENTIALS)
        LogInUser({ username: loginEmail, password: loginPassword })
    }

    const HandleUserAuthStateChange = useCallback(
        (_listenerString) => {
            if (GetUserAuthOperation() !== USER_AUTH_OPERATION_NONE) {
                return
            }

            switch (loginStep) {
                case LOGIN_STEP_SUBMIT_CREDENTIALS:
                    SetLoginStep(LOGIN_STEP_NONE)

                    if (IsUserLoggedIn()) {
                        navigate(HOME_PATH)
                    }
                    else {
                        SetLoginErrorMessage("Sorry, we couldn't log you in. Please check the email and password, and try again.")
                    }

                    break

                default:
                    SetLoginStep(LOGIN_STEP_NONE)

                    if (!IsUserLoggedIn() && (loginMessageType === LOGIN_MESSAGE_TYPE_SUCCESS)) {
                        ClearLoginMessage()
                    }

                    break
            }
        },
        [loginStep] // eslint-disable-line react-hooks/exhaustive-deps
    )

    const HandleLoginSignupContextChange = useCallback(
        (_listenerString) => {
            if (activationMessageVisibility) {
                SetLoginSuccessMessage("You have successfully activated your account! " +
                    "Please log in to start using lists, activities, and other great LevelUp Artic features.")
                SetActivationMessageVisibility(false)
            }
        }, [activationMessageVisibility] // eslint-disable-line react-hooks/exhaustive-deps
    )

    const GetLoginContent = () => {
        if (loginMessageType === LOGIN_MESSAGE_TYPE_ERROR) {
            return (
                <ErrorMessageComponent
                    isDismissible={isLoginMessageDismissible}
                    DismissAlert={() => {
                        ClearLoginMessage()
                    }}
                    messageWidth='75%'
                >
                    {loginMessage}
                </ErrorMessageComponent>
            )
        }
        else if (loginMessageType === LOGIN_MESSAGE_TYPE_SUCCESS) {
            return (
                <SuccessMessageComponent
                    isDismissible={isLoginMessageDismissible}
                    DismissAlert={() => {
                        ClearLoginMessage()
                        SetIsUserLoggedIn(IsUserLoggedIn())
                    }}
                    messageWidth='75%'
                >
                    {loginMessage}
                </SuccessMessageComponent>
            )
        }
        else if (loginStep !== LOGIN_STEP_NONE) {
            return (
                <div className='text-center mt-4 mx-auto'>
                    <LoaderComponent spinnerColor='var(--dark-aqua)' spinnerSize='150px' />
                </div>
            )
        }

        return (
            <>
                <div className='me-5'>
                    <div className='vstack mt-1 ms-2'>
                        <Form>
                            <Form.Group controlId='loginEmail' className='w-100'>
                                <Form.Label className='login-signup-label'>Email</Form.Label>
                                <Form.Control
                                    className='login-signup-view-box'
                                    type='text'
                                    value={loginEmail}
                                    placeholder=''
                                    disabled={false}
                                    autoComplete='off'
                                    onChange={(event) => {
                                        SetLoginEmail(event.target.value)
                                    }}
                                />
                            </Form.Group>
                            <Form.Group controlId='loginPassword' className='w-100 mt-4'>
                                <Form.Label className='login-signup-label'>Password</Form.Label>
                                <Form.Control
                                    className='login-signup-view-box'
                                    type='password'
                                    value={loginPassword}
                                    placeholder=''
                                    disabled={false}
                                    autoComplete='off'
                                    onChange={(event) => {
                                        SetLoginPassword(event.target.value)
                                    }}
                                />
                            </Form.Group>
                        </Form>
                    </div>
                </div>
                <div className='vstack w-50'>
                    <ActionButtonComponent
                        text='Log In'
                        width='8rem'
                        margins={'2.5rem 1rem 0 1.5rem'}
                        isActive={IsEmailValid(loginEmail) && IsPasswordValid(loginPassword)}
                        DoAction={() => SubmitLoginRequest()}
                    />
                    <div className='mt-4 mx-3'>
                        <h5>
                            Forgot your password?{' '}
                            <Link to={PASSWORD_RESET_REQUEST_PATH} className='text-link'>
                                Reset
                            </Link>
                            {' '}it using an email link.
                        </h5>
                    </div>
                </div>
            </>
        )
    }

    return (
        <>
            <UserAuthStateChangeListener callback={HandleUserAuthStateChange} />
            <LoginSignupListener SendContextChangeNotification={HandleLoginSignupContextChange} />
            <Container fluid className='d-flex flex-wrap justify-content-start pt-2 pb-4 ps-5'>
                {GetLoginContent()}
            </Container>
            <LoginInstructionsModalComponent />
        </>
    )
}

function LoginInstructionsModalComponent() {
    const { instructionsModalVisibility, SetInstructionsModalVisibility } = useContext(LoginSignupContext)

    return (
        <Modal
            className='fade modal-lg'
            style={{ color: 'var(--dark-blue)' }}
            show={instructionsModalVisibility}
            onHide={() => SetInstructionsModalVisibility(false)}
        >
            <Modal.Header className='bg-light py-3'>
                <Modal.Title className='text-center mx-auto'>
                    <h4 className='my-0'>Account Login</h4>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className='bg-light'>
                <div className='d-flex justify-content-center mx-auto'>
                    <div className='justify-content-left mx-5'>
                        <h5>If you have an account, enter your email address and password, then click the Log In button.</h5>
                        <h5 className='mt-3'>If you don't have an account, use the Signup tab to create one.</h5>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer className='bg-light py-1' style={{ border: 'none' }}>
            </Modal.Footer>
        </Modal >
    )
}

export function SignupComponent() {
    const { SetPageFreeze, initialSubscriptionType } = useContext(LoginSignupContext)

    const [signupName, SetSignupName] = useState('')
    const [signupEmail, SetSignupEmail] = useState('')
    const [signupPassword, SetSignupPassword] = useState('')
    const [signupRepeatPassword, SetSignupRepeatPassword] = useState('')

    const SIGNUP_STEP_NONE = 'SignupStep/None'
    const SIGNUP_STEP_LOAD_ACCOUNT = 'SignupStep/LoadAccount'
    const SIGNUP_STEP_CREATE_ACCOUNT = 'SignupStep/CreateAccount'
    const SIGNUP_STEP_SEND_CONFIRM_EMAIL = 'SignupStep/SendConfirmEmail'

    const [signupStep, SetSignupStep] = useState(SIGNUP_STEP_NONE)

    const SIGNUP_MESSAGE_TYPE_NONE = 'SignupMessageType/None'
    const SIGNUP_MESSAGE_TYPE_SUCCESS = 'SignupMessageType/Success'
    const SIGNUP_MESSAGE_TYPE_ERROR = 'SignupMessageType/Error'

    const [signupMessageType, SetSignupMessageType] = useState(SIGNUP_MESSAGE_TYPE_NONE)
    const [signupMessage, SetSignupMessage] = useState('')
    const [isSignupMessageDismissible, SetIsSignupMessageDismissible] = useState(true)

    const [subscriptionType, SetSubscriptionType] = useState(initialSubscriptionType)

    const ClearSignupMessage = () => {
        SetSignupMessageType(SIGNUP_MESSAGE_TYPE_NONE)
        SetSignupMessage('')
        SetIsSignupMessageDismissible(true)
    }

    const SetSignupSuccessMessage = (message, isDismissible = true) => {
        SetSignupMessageType(SIGNUP_MESSAGE_TYPE_SUCCESS)
        SetSignupMessage(message)
        SetIsSignupMessageDismissible(isDismissible)
    }

    const SetSignupErrorMessage = (message, isDismissible = true) => {
        SetSignupMessageType(SIGNUP_MESSAGE_TYPE_ERROR)
        SetSignupMessage(message)
        SetIsSignupMessageDismissible(isDismissible)
    }

    const navigate = useNavigate()
    const [isUserLoggedIn, SetIsUserLoggedIn] = useState(false)

    useEffect(() => {
        if (isUserLoggedIn) {
            navigate(USER_ACCOUNT_PATH)
        }
    }, [isUserLoggedIn, navigate])

    useEffect(() => {
        if (IsUserLoggedIn()) {
            SetIsUserLoggedIn(true)
        }

        ClearSignupMessage()
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => { }, [signupMessageType, signupMessage])

    const SubmitSignupRequest = () => {
        ClearSignupMessage()
        SetSignupStep(SIGNUP_STEP_LOAD_ACCOUNT)
        LoadUserAccount(signupEmail)
    }

    const HandleUserAccountStateChange = useCallback(
        (_listenerString) => {
            const accountStatus = GetUserAccountStatus()
            const _accountInfo = GetUserAccountInfo()
            const accountOperation = GetUserAccountOperation()
            const accountEmailStatus = GetUserAccountEmailStatus()

            if (accountOperation !== USER_ACCOUNT_OPERATION_NONE) {
                return
            }

            switch (signupStep) {
                case SIGNUP_STEP_LOAD_ACCOUNT:
                    if (accountStatus === USER_ACCOUNT_STATUS_ACTIVE) {
                        SetSignupStep(SIGNUP_STEP_NONE)
                        SetSignupErrorMessage('An account already exists for ' + signupEmail + '. ' +
                            'You can log in if it belongs to you. Otherwise please sign up with a different email address.')
                    }
                    else {
                        SetSignupStep(SIGNUP_STEP_CREATE_ACCOUNT)
                        CreateUserAccount(signupName, signupEmail, signupPassword)
                    }

                    break

                case SIGNUP_STEP_CREATE_ACCOUNT:
                    if (accountStatus === USER_ACCOUNT_STATUS_INACTIVE) {
                        SetSignupStep(SIGNUP_STEP_SEND_CONFIRM_EMAIL)
                        SendUserAccountEmail(signupEmail, 'Welcome to LevelUp Artic!', 'users/signup-confirmation-email', signupName, subscriptionType)
                    }
                    else {
                        SetSignupStep(SIGNUP_STEP_NONE)
                        SetSignupErrorMessage("We ran into a problem creating your account and couldn't sign you up. Please try again later.")
                    }

                    break

                case SIGNUP_STEP_SEND_CONFIRM_EMAIL:
                    SetSignupStep(SIGNUP_STEP_NONE)

                    if (accountEmailStatus === USER_ACCOUNT_EMAIL_STATUS_SUCCESS) {
                        SetSignupSuccessMessage('Thanks for signing up! Please check for a confirmation email to complete the signup process.', false)
                        SetPageFreeze(true)
                    }
                    else if (accountEmailStatus === USER_ACCOUNT_EMAIL_STATUS_FAILURE) {
                        SetSignupErrorMessage('Sorry, there was a problem sending the confirmation email. Please try again later.')
                    }

                    break

                default:
                    SetSignupStep(SIGNUP_STEP_NONE)
                    break
            }
        },
        [signupStep] // eslint-disable-line react-hooks/exhaustive-deps
    )

    const HandleLoginSignupContextChange = useCallback(
        (_listenerString) => {
            SetSubscriptionType(initialSubscriptionType)
        }, [initialSubscriptionType] // eslint-disable-line react-hooks/exhaustive-deps
    )

    const GetSignupContent = () => {
        if (signupMessageType === SIGNUP_MESSAGE_TYPE_ERROR) {
            return (
                <ErrorMessageComponent
                    isDismissible={isSignupMessageDismissible}
                    DismissAlert={() => {
                        ClearSignupMessage()
                    }}
                    messageWidth='75%'
                >
                    {signupMessage}
                </ErrorMessageComponent>
            )
        }
        else if (signupMessageType === SIGNUP_MESSAGE_TYPE_SUCCESS) {
            return (
                <SuccessMessageComponent
                    isDismissible={isSignupMessageDismissible}
                    DismissAlert={() => {
                        ClearSignupMessage()
                        SetIsUserLoggedIn(true)
                    }}
                    messageWidth='75%'
                >
                    {signupMessage}
                </SuccessMessageComponent>
            )
        }
        else if (signupStep !== SIGNUP_STEP_NONE) {
            return (
                <div className='text-center mt-4 mx-auto'>
                    <LoaderComponent spinnerColor='var(--dark-aqua)' spinnerSize='150px' />
                </div>
            )
        }

        return (
            <>
                <div className='me-5'>
                    <div className='vstack mt-1 ms-2'>
                        <Form>
                            <Form.Group controlId='signupName' className='w-100'>
                                <Form.Label className='login-signup-label'>Name</Form.Label>
                                <Form.Control
                                    className='login-signup-view-box'
                                    type='text'
                                    value={signupName}
                                    placeholder=''
                                    disabled={false}
                                    autoComplete='off'
                                    onChange={(event) => {
                                        SetSignupName(event.target.value)
                                    }}
                                />
                            </Form.Group>
                            <Form.Group controlId='signupEmail' className='w-100 mt-4'>
                                <Form.Label className='login-signup-label'>Email</Form.Label>
                                <Form.Control
                                    className='login-signup-view-box'
                                    type='text'
                                    value={signupEmail}
                                    placeholder=''
                                    disabled={false}
                                    autoComplete='off'
                                    onChange={(event) => {
                                        SetSignupEmail(event.target.value)
                                    }}
                                />
                            </Form.Group>
                            <Form.Group controlId='signupPassword' className='w-100 mt-4'>
                                <Form.Label className='login-signup-label'>Password</Form.Label>
                                <Form.Control
                                    className='login-signup-view-box'
                                    type='password'
                                    value={signupPassword}
                                    placeholder=''
                                    disabled={false}
                                    autoComplete='off'
                                    onChange={(event) => {
                                        SetSignupPassword(event.target.value)
                                    }}
                                />
                            </Form.Group>
                            <Form.Group controlId='signupRepeatPassword' className='w-100 mt-4'>
                                <Form.Label className='login-signup-label'>Repeat Password</Form.Label>
                                <Form.Control
                                    className='login-signup-view-box'
                                    type='password'
                                    value={signupRepeatPassword}
                                    placeholder=''
                                    disabled={false}
                                    autoComplete='off'
                                    onChange={(event) => {
                                        SetSignupRepeatPassword(event.target.value)
                                    }}
                                />
                            </Form.Group>
                        </Form>
                    </div>
                </div>
                <div className='vstack w-50 mt-3'>
                    <div className='mt-4 mx-3'>
                        <h5>Your 14 trial will begin immediately. You don't need to provide payment information now, but it is required to continue after the trial period.</h5>
                    </div>
                    <Form>
                        <div className='mt-3'
                            key='subscriptionTypeSelection'
                            onChange={(event) => {
                                SetSubscriptionType(event.target.value)
                            }}
                        >
                            <Form.Check
                                label='Monthly subscription ($6/month)'
                                type='radio'
                                name='subscriptionType'
                                value={SUBSCRIPTION_TYPE_MONTH}
                                className='mx-3'
                                defaultChecked={initialSubscriptionType === SUBSCRIPTION_TYPE_MONTH}
                            />
                            <Form.Check
                                label='Annual subscription ($39/year)'
                                type='radio'
                                name='subscriptionType'
                                value={SUBSCRIPTION_TYPE_YEAR}
                                className='mx-3'
                                defaultChecked={initialSubscriptionType === SUBSCRIPTION_TYPE_YEAR}
                            />
                        </div>
                    </Form>
                    <ActionButtonComponent
                        text='Sign Up'
                        width='8rem'
                        margins={'1.5rem 1rem 0 1.5rem'}
                        isActive={
                            signupName !== '' && IsEmailValid(signupEmail) &&
                            IsPasswordValid(signupPassword) && signupRepeatPassword === signupPassword
                        }
                        DoAction={() => SubmitSignupRequest()}
                    />
                    <div className='mt-4 mx-3'>
                        <div className='mt-2'>
                            <h5 style={{ display: 'inline' }}>Questions about signing up?</h5>
                            &nbsp; &nbsp;
                            <a href="mailto:support@levelupartic.com?subject=LevelUp Artic signup"
                                style={{ color: 'var(--dark-blue)', textDecoration: 'none' }}>
                                <h5 style={{ display: 'inline' }}>Contact us</h5>
                            </a>
                        </div>
                    </div>
                </div>
            </>
        )
    }

    return (
        <>
            <UserAccountStateChangeListener callback={HandleUserAccountStateChange} />
            <LoginSignupListener SendContextChangeNotification={HandleLoginSignupContextChange} />
            <Container fluid className='d-flex flex-wrap justify-content-start pt-2 pb-4 ps-5'>
                {GetSignupContent()}
            </Container>
            <SignupInstructionsModalComponent />
        </>
    )
}

function SignupInstructionsModalComponent() {
    const { instructionsModalVisibility, SetInstructionsModalVisibility } = useContext(LoginSignupContext)

    return (
        <Modal
            className='fade modal-lg'
            style={{ color: 'var(--dark-blue)' }}
            show={instructionsModalVisibility}
            onHide={() => { SetInstructionsModalVisibility(false) }}
        >
            <Modal.Header className='bg-light py-3'>
                <Modal.Title className='text-center mx-auto'>
                    <h4 className='my-0'>Account Signup</h4>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className='bg-light'>
                <div className='d-flex justify-content-center mx-auto'>
                    <div className='justify-content-left mx-5'>
                        <h5>
                            If you don't have an account, enter your name, email address, and password; choose a subscription plan; then click the Sign Up button.
                            We will send you an email to complete the signup process.
                        </h5>
                        <h5 className='mt-3'>If you already have an account, use the Login tab to sign in.</h5>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer className='bg-light py-1' style={{ border: 'none' }}>
            </Modal.Footer>
        </Modal >
    )
}
