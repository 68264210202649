import { useState, useEffect, createContext, useContext } from 'react'

export const TicTacToeContext = createContext()

export const TicTacToeProvider = ({ children }) => {
    const [gameOver, SetGameOver] = useState(false)
    const [gameWon, SetGameWon] = useState(false)

    const ResetTicTacToeContext = () => {
        SetGameOver(false)
        SetGameWon(false)
    }

    return (
        <TicTacToeContext.Provider
            value={{
                gameOver, SetGameOver,
                gameWon, SetGameWon,
                ResetTicTacToeContext
            }}
        >
            {children}
        </TicTacToeContext.Provider>
    )
}

export function ResetTicTacToeContext() {
    const { SetGameWon } = useContext(TicTacToeContext)

    SetGameWon(false)
}

export function TicTacToeListener({ SendContextChangeNotification }) {
    const context = useContext(TicTacToeContext)
    const [stringifiedContext, SetStringifiedContext] = useState('')

    useEffect(() => {
        SendContextChangeNotification(stringifiedContext)
    }, [stringifiedContext, SendContextChangeNotification])

    useEffect(() => {
        SetStringifiedContext(JSON.stringify(context))
    }, [context])

    return <></>
}
