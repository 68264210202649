import { useState, useEffect, useCallback } from 'react'
import { Container, Nav, Navbar } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import {
    HOME_PATH,
    START_PATH,
    LOGIN_SIGNUP_PATH,
    USER_ACCOUNT_PATH,
    ABOUT_PATH,
    CONTROL_CENTER_PATH,
} from '../App'
import { UserAuthStateChangeListener, IsUserLoggedIn, IsUserAdmin, LogOutUser } from '../state/UserAuthState'
import { SubscriptionStateChangeListener, IsUserSubscribed } from '../state/SubscriptionState'
import { GetSiteImageUrl } from '../project/ProjectResources'

export function Header() {
    const [userStateChangeString, SetUserStateChangeString] = useState('')
    const [subscriptionStateChangeString, SetSubscriptionStateChangeString] = useState('')
    useEffect(() => { }, [userStateChangeString, subscriptionStateChangeString])

    const HandleUserStateChange = useCallback((listenerString) => {
        SetUserStateChangeString(listenerString)
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const HandleSubscriptionStateChange = useCallback((listenerString) => {
        SetSubscriptionStateChangeString(listenerString)
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <header>
            <UserAuthStateChangeListener callback={HandleUserStateChange} />
            <SubscriptionStateChangeListener callback={HandleSubscriptionStateChange} />
            <Navbar bg='light' expand='md' collapseOnSelect>
                <Container fluid className='navbar-container'>
                    <Navbar.Brand as={Link} to={HOME_PATH}>
                        <img src={GetSiteImageUrl('navbar-logo.png')} width='175px' alt='LevelUpArtic' />
                    </Navbar.Brand>
                    <Navbar.Toggle />
                    <Navbar.Collapse>
                        <Nav>
                            {IsUserSubscribed() && (
                                <Nav.Link as={Link} to={START_PATH}>
                                    Start
                                </Nav.Link>
                            )}
                            <Nav.Link as={Link} to={ABOUT_PATH}>
                                About
                            </Nav.Link>
                            {IsUserLoggedIn() && (
                                <Nav.Link as={Link} to={USER_ACCOUNT_PATH}>
                                    Account
                                </Nav.Link>
                            )}
                            {IsUserAdmin() && (
                                <><Nav.Link as={Link} to={CONTROL_CENTER_PATH}>
                                    Admin
                                </Nav.Link></>
                            )}
                            {IsUserLoggedIn() ? (
                                <Nav.Link
                                    onClick={() => {
                                        LogOutUser()
                                    }}
                                >
                                    Logout
                                </Nav.Link>
                            ) : (
                                <>
                                    <Nav.Link as={Link} to={LOGIN_SIGNUP_PATH}>
                                        Login/Signup
                                    </Nav.Link>
                                </>
                            )}
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </header>
    )
}
