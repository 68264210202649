import { Container, Col, Row, ButtonGroup } from 'react-bootstrap'
import PropTypes from 'prop-types'
import { WordCardComponent } from './WordCardComponent'
import './ListOperationComponents.css'

export const LIST_OPERATION_TYPE_CREATE = 'ListOperationType/Create'
export const LIST_OPERATION_TYPE_EDIT = 'ListOperationType/Edit'

export const LIST_OPERATION_MESSAGE_TYPE_NONE = 'ListOperationMessageType/None'
export const LIST_OPERATION_MESSAGE_TYPE_SUCCESS = 'ListOperationMessageType/Success'
export const LIST_OPERATION_MESSAGE_TYPE_ERROR = 'ListOperationMessageType/Error'

export function ListOperationTabRowComponent({ children }) {
    return <div className='list-operation-tab-row'>{children}</div>
}

export function ListOperationTabComponent({ color, text, isActive, ClickAction }) {
    const tabStyle = isActive ? {
        backgroundColor: `var(--${color})`,
        opacity: 1.0,
    } : {
        backgroundColor: `var(--${color})`,
        opacity: 0.5,
    }

    return (
        <div className='list-operation-tab' style={tabStyle} onClick={() => ClickAction()}>
            <div className='list-operation-tab-text'>
                {text}
            </div>
        </div>
    )
}

ListOperationTabComponent.propTypes = {
    color: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    isActive: PropTypes.bool.isRequired,
    ClickAction: PropTypes.func.isRequired,
}

export function ListOperationActionAreaComponent({ props, children }) {
    const actionAreaStyle = {
        borderColor: `var(--${props.color})`
    }

    return (
        <div className='list-operation-action-container mx-auto mt-0' style={actionAreaStyle}>
            <div className='align-items-center' style={{ width: '100%' }}>
                {children}
            </div>
        </div>
    )
}

ListOperationActionAreaComponent.propTypes = {
    props: PropTypes.object.isRequired,
}

export function ListOperationWordsAreaComponent({ children }) {
    return (
        <Container className='section-container bg-light mt-0'>
            <Container fluid className='list-operation-words-container'>
                {children}
            </Container>
        </Container>
    )
}

export function ListOperationWordsComponent({ words }) {
    const GetWordCards = () => {
        const wordCards = words.map((wordKey, index) => (
            <Col xs={6} sm={4} md={3} lg={2} key={index}>
                <ButtonGroup className='vstack mt-0 mb-1 my-lg-1'>
                    <WordCardComponent wordKey={wordKey} selectedWords={words}></WordCardComponent>
                </ButtonGroup>
            </Col>
        ))

        return wordCards
    }

    return (
        <>
            <Row className='mt-0 g-2'>{GetWordCards()}</Row>
        </>
    )
}
