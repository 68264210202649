import { useState, useEffect, useCallback } from 'react'
import { Container, Form, Modal } from 'react-bootstrap'
import PropTypes from 'prop-types'
import { ActionButtonComponent } from './ActionButtonComponents'
import { ErrorMessageComponent } from './MessageComponents'
import { LoaderComponent } from './LoaderComponents'
import {
    UserProfileStateChangeListener, IsUserProfileValid, GetUserProfileInfo, GetUserProfileFailure, GetUserProfileOperation, UpdateUserProfile,
    USER_PROFILE_FAILURE_NONE, USER_PROFILE_OPERATION_NONE
} from '../state/UserProfileState'
import { GetUserAuthAccessToken } from '../state/UserAuthState'
import './UserProfileComponents.css'

export function UserProfileComponent({ showModal = false, CloseModal = () => { } }) {
    const [profileRetrieveActive, SetProfileRetrieveActive] = useState(true)
    const [profileRetrieveError, SetProfileRetrieveError] = useState(false)
    const [profileRetrieveInfoValid, SetProfileRetrieveInfoValid] = useState(false)

    const [profileEmail, SetProfileEmail] = useState('')
    const [profileName, SetProfileName] = useState('')
    const [profileJoinDate, SetProfileJoinDate] = useState('')

    const [isProfileEditable, SetIsProfileEditable] = useState(false)

    useEffect(() => { }, [profileRetrieveActive, profileRetrieveError, profileEmail, profileName])

    const UpdateProfileRetrieveStatus = () => {
        const retrieveError =
            ((GetUserProfileOperation() === USER_PROFILE_OPERATION_NONE) && !IsUserProfileValid()) ||
            (GetUserProfileFailure() !== USER_PROFILE_FAILURE_NONE)

        if (!retrieveError) {
            const profileInfo = GetUserProfileInfo()
            SetProfileEmail(profileInfo['email'])
            SetProfileName(profileInfo['name'])
            SetProfileJoinDate(profileInfo['joinDate'])
        }

        SetProfileRetrieveActive(GetUserProfileOperation() !== USER_PROFILE_OPERATION_NONE)
        SetProfileRetrieveError(retrieveError)
        SetProfileRetrieveInfoValid((GetUserProfileOperation() === USER_PROFILE_OPERATION_NONE) && !retrieveError)
    }

    const HandleUserProfileStateChange = useCallback(() => {
        UpdateProfileRetrieveStatus()
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const GetErrorMessage = () => {
        return 'Sorry, we encountered an error accessing your user profile. Please try again later.'
    }

    const GetProfileContent = () => {
        if (profileRetrieveError) {
            return (
                <ErrorMessageComponent
                    isDismissible={false}
                    DismissAlert={() => { }}
                    messageWidth='90%'
                >
                    {GetErrorMessage()}
                </ErrorMessageComponent>
            )
        }

        if (profileRetrieveActive) {
            return <div className='text-center mt-5 ps-5'><LoaderComponent spinnerColor='var(--dark-aqua)' spinnerSize='150px' /></div>
        }

        return (
            <div className='vstack mt-1 ms-2'>
                <Form.Group controlId='profileName' className='w-100'>
                    <Form.Label className='profile-label'>Name</Form.Label>
                    <Form.Control
                        className='profile-view-box'
                        type='text'
                        value={profileName}
                        placeholder=''
                        disabled={!isProfileEditable}
                        autoComplete='off'
                        onChange={(event) => {
                            event.preventDefault()
                            SetProfileName(event.target.value)
                        }}
                    />
                </Form.Group>

                <Form.Group controlId='profileJoinDate' className='w-100 mt-4'>
                    <Form.Label className='profile-label'>Joined</Form.Label>
                    <Form.Control
                        className='profile-view-box'
                        type='text'
                        value={profileJoinDate}
                        placeholder=''
                        disabled={true}
                        onChange={(event) => {
                            event.preventDefault()
                        }}
                    />
                </Form.Group>
            </div>
        )
    }

    const GetProfileUpdateButtons = () => {
        if (isProfileEditable) {
            return (
                <div className='vstack'>
                    <ActionButtonComponent
                        text='Save Changes'
                        width='10rem'
                        margins={'2.5rem 1rem 0 1.5rem'}
                        isActive={profileName !== ''}
                        DoAction={() => {
                            UpdateUserProfile(GetUserAuthAccessToken(), { 'name': profileName })
                            SetIsProfileEditable(false)
                        }} />
                    <ActionButtonComponent
                        text='Cancel'
                        width='10rem'
                        margins={'1.5rem 1rem 0 1.5rem'}
                        isActive={true}
                        DoAction={() => {
                            UpdateProfileRetrieveStatus()
                            SetIsProfileEditable(false)
                        }} />
                </div>
            )
        }

        if (profileRetrieveInfoValid) {
            return (
                <ActionButtonComponent
                    text='Update Profile'
                    width='10rem'
                    margins={'2.4rem 1rem 0 1.5rem'}
                    isActive={true}
                    DoAction={() => { SetIsProfileEditable(true) }} />
            )
        }

        return <></>
    }

    return (
        <>
            <UserProfileStateChangeListener callback={HandleUserProfileStateChange} />
            <Container fluid className='d-flex flex-wrap justify-content-start pt-2 pb-4 ps-5'>
                <div className='me-5'>
                    {GetProfileContent()}
                </div>
                <div>
                    {GetProfileUpdateButtons()}
                </div>
            </Container>
            <UserProfileInstructionsModalComponent showModal={showModal} HandleModalClose={CloseModal} />
        </>
    )
}

UserProfileComponent.propTypes = {
    showModal: PropTypes.bool,
    CloseModal: PropTypes.func,
}

function UserProfileInstructionsModalComponent({ showModal, HandleModalClose }) {
    return (
        <Modal
            className='fade modal-lg'
            style={{ color: 'var(--dark-blue)' }}
            show={showModal}
            onHide={() => { HandleModalClose() }}
        >
            <Modal.Header className='bg-light py-3'>
                <Modal.Title className='text-center mx-auto'>
                    <h4 className='my-0'>Your Profile</h4>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className='bg-light'>
                <div className='d-flex justify-content-center mx-auto'>
                    <div className='justify-content-left mx-5'>
                        <h5>Click Update Profile to edit your profile information, then Save Changes to store your updates.</h5>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer className='bg-light py-1' style={{ border: 'none' }}>
            </Modal.Footer>
        </Modal >
    )
}

UserProfileInstructionsModalComponent.propTypes = {
    showModal: PropTypes.bool.isRequired,
    HandleModalClose: PropTypes.func.isRequired,
}
