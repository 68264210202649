// Search and replace: [’] [‘] [“] [”] [\"  ] [.  ] [?  ] [!  ] [,  ] [>  ]

import {
    TARGET_SOUND_G, TARGET_SOUND_K, TARGET_SOUND_L, TARGET_SOUND_R, TARGET_SOUND_S, TARGET_SOUND_SH, TARGET_SOUND_TH
} from "./SoundPositionLevelChoices"
import {
    POSITION_INITIAL,
    POSITION_MEDIAL,
    POSITION_FINAL,
    POSITION_BL_BLEND,
    POSITION_FL_BLEND,
    POSITION_GL_BLEND,
    POSITION_KL_BLEND,
    POSITION_PL_BLEND,
    POSITION_SK_BLEND,
    POSITION_SL_BLEND,
    POSITION_SM_BLEND,
    POSITION_SN_BLEND,
    POSITION_SP_BLEND,
    POSITION_ST_BLEND,
    POSITION_SW_BLEND,
    POSITION_COMPLEX_BLEND,
    POSITION_BR_BLEND,
    POSITION_DR_BLEND,
    POSITION_FR_BLEND,
    POSITION_GR_BLEND,
    POSITION_KR_BLEND,
    POSITION_PR_BLEND,
    POSITION_TR_BLEND,
    POSITION_VOCALIC_AR,
    POSITION_VOCALIC_AIR,
    POSITION_VOCALIC_EAR,
    POSITION_VOCALIC_ER,
    POSITION_VOCALIC_IRE,
    POSITION_VOCALIC_OR,
    POSITION_VOICELESS_INITIAL,
    POSITION_VOICELESS_MEDIAL,
    POSITION_VOICELESS_FINAL,
    POSITION_VOICED_MEDIAL,
} from './SoundPositionLevelChoices'
import {
    PICK_A_PATH_READING_LEVEL_EASY, PICK_A_PATH_READING_LEVEL_PROFICIENT,
    PICK_A_PATH_READING_LEVEL_ALL, PICK_A_PATH_READING_LEVEL_INVALID,
    PICK_A_PATH_NODE_START, PICK_A_PATH_NODE_END
} from "./PickAPathResources"

export const PICK_A_PATH_NODE_1 = 'PickAPath/Node/1'
export const PICK_A_PATH_NODE_2 = 'PickAPath/Node/2'
export const PICK_A_PATH_NODE_3 = 'PickAPath/Node/3'
export const PICK_A_PATH_NODE_1A = 'PickAPath/Node/1a'
export const PICK_A_PATH_NODE_1B = 'PickAPath/Node/1b'
export const PICK_A_PATH_NODE_1C = 'PickAPath/Node/1c'
export const PICK_A_PATH_NODE_2A = 'PickAPath/Node/2a'
export const PICK_A_PATH_NODE_2B = 'PickAPath/Node/2b'
export const PICK_A_PATH_NODE_2C = 'PickAPath/Node/2c'
export const PICK_A_PATH_NODE_3A = 'PickAPath/Node/3a'
export const PICK_A_PATH_NODE_3B = 'PickAPath/Node/3b'
export const PICK_A_PATH_NODE_3C = 'PickAPath/Node/3c'
export const PICK_A_PATH_NODE_1A1 = 'PickAPath/Node/1a1'
export const PICK_A_PATH_NODE_1A2 = 'PickAPath/Node/1a2'
export const PICK_A_PATH_NODE_1B1 = 'PickAPath/Node/1b1'
export const PICK_A_PATH_NODE_1B2 = 'PickAPath/Node/1b2'
export const PICK_A_PATH_NODE_2A1 = 'PickAPath/Node/2a1'
export const PICK_A_PATH_NODE_2A2 = 'PickAPath/Node/2a2'
export const PICK_A_PATH_NODE_2B1 = 'PickAPath/Node/2b1'
export const PICK_A_PATH_NODE_2B2 = 'PickAPath/Node/2b2'

const pickAPathStories = {
    // Target sound G/K
    'kates-cake': {  // Initial K - Read-Together
        'name': "A Cake for Kate",
        'dropdownName': "A Cake for Kate (Initial K)",
        'targetSounds': [TARGET_SOUND_K],
        'positions': [POSITION_INITIAL],
        'readingLevel': PICK_A_PATH_READING_LEVEL_EASY,
        'vocab': [
            ['cake'],
            ['camel'],
            ['Camille'],
            ['can'],
            ['candy'],
            ['candy_bar', 'candy bar'],
            ['canoe'],
            ['cant', "can't"],
            ['car'],
            ['carnival'],
            ['carrot'],
            ['carrot', 'carrots'],
            ['Casey'],
            ['catch'],
            ['cold'],
            ['cooking'],
            ['cool'],
            ['costume'],
            ['cough'],
            ['cousin', 'cousins'],
            ['cow'],
            ['cup'],
            ['cut'],
            ['cut', 'cutting'],
            ['kangaroo'],
            ['Kate'],
            ['ketchup'],
            ['kitchen'],
            ['kitten'],
        ],
        'nodes': {
            [PICK_A_PATH_NODE_START]: {
                'text': [
                    "<Kate> called her <cousin|cousins> <Camille> and <Casey> to tell them she wanted to cook a"
                ],
                'choices': [
                    ["<candy> <cake|cake.>", PICK_A_PATH_NODE_1],
                    ["<carrot> <cake|cake.>", PICK_A_PATH_NODE_2],
                ]
            },
            [PICK_A_PATH_NODE_1]: {
                'text': [
                    "<Camille> and <Casey> said, \"We can come over after our"
                ],
                'choices': [
                    ["<costume> party.", PICK_A_PATH_NODE_1A],
                    ["<cooking> contest.", PICK_A_PATH_NODE_1B],
                ]
            },
            [PICK_A_PATH_NODE_1A]: {
                'text': [
                    "<Camille> came from the <costume> party dressed up like a"
                ],
                'choices': [
                    ["<cow|cow.>", PICK_A_PATH_NODE_1A1],
                    ["<camel|camel.>", PICK_A_PATH_NODE_1A2],
                ]
            },
            [PICK_A_PATH_NODE_1A1]: {
                'text': [
                    "<Camille> was a cute <cow|cow!> She and <Kate> <cut> up a <candy_bar|candy bar> and added it to the <candy> <cake|cake.>"
                ],
            },
            [PICK_A_PATH_NODE_1A2]: {
                'text': [
                    "<Camille> was very careful with her <camel> <costume> so she could wear it to the <carnival|carnival!>"
                ],
            },
            [PICK_A_PATH_NODE_1B]: {
                'text': [
                    "<Casey> was tired after the <cooking> contest and wanted to cuddle with a cozy"
                ],
                'choices': [
                    ["<kangaroo|kangaroo.>", PICK_A_PATH_NODE_1B1],
                    ["<kitten|kitten.>", PICK_A_PATH_NODE_1B2],
                ]
            },
            [PICK_A_PATH_NODE_1B1]: {
                'text': [
                    "<Casey> cuddled with a <kangaroo> called Ken. Then he told <Camille> and <Kate|Kate,> \"I'm coming to help with the <cake|cake!\">"
                ],
            },
            [PICK_A_PATH_NODE_1B2]: {
                'text': [
                    "<Casey> cuddled with a <kitten> on the couch."
                ],
            },
            [PICK_A_PATH_NODE_2]: {
                'text': [
                    "<Casey> and <Camille> came over right away in their"
                ],
                'choices': [
                    ["<canoe|canoe.>", PICK_A_PATH_NODE_2A],
                    ["<car|car.>", PICK_A_PATH_NODE_2B],
                ]
            },
            [PICK_A_PATH_NODE_2A]: {
                'text': [
                    "<Camille> and <Casey> needed to <cool> off after their <canoe> ride. \"Could we please have a"
                ],
                'choices': [
                    ["<can> of soda?\"", PICK_A_PATH_NODE_2A1],
                    ["<cup> of water?\"", PICK_A_PATH_NODE_2A2],
                ]
            },
            [PICK_A_PATH_NODE_2A1]: {
                'text': [
                    "<Kate|\"Kate,> you are so kind,\" said <Camille> as she and <Casey> went to the <kitchen|kitchen.>"
                ],
            },
            [PICK_A_PATH_NODE_2A2]: {
                'text': [
                    "<Casey> started to <cough|cough.> <Kate> said, <Casey|\"Casey,> I hope you didn't <catch> a <cold|cold!\">"
                ],
            },
            [PICK_A_PATH_NODE_2B]: {
                'text': [
                    "<Kate|Kate,> <Camille|Camille,> and <Casey> shared a <candy_bar|candy bar,> then went into the <kitchen> to look for some"
                ],
                'choices': [
                    ["<carrot|carrots.>", PICK_A_PATH_NODE_2B1],
                    ["<ketchup|ketchup.>", PICK_A_PATH_NODE_2B2],
                ]
            },
            [PICK_A_PATH_NODE_2B1]: {
                'text': [
                    "<Camille> counted the <carrot|carrots.> <Kate|\"Kate,> I could use your help <cut|cutting> up these <carrot|carrots.\">",
                    "\"I'm coming!\" called <Kate|Kate.>"
                ],
            },
            [PICK_A_PATH_NODE_2B2]: {
                'text': [
                    "\"Come on,\" said <Casey|Casey,> \"you <cant|can't> use <ketchup> in a <carrot> <cake|cake!\">"
                ],
            },
        }
    },
    'king-carl': {  // Initial G/K - Proficient Reader
        'name': "King Carl's Sweet Contest",
        'dropdownName': "King Carl's Sweet Contest (Initial G/K)",
        'targetSounds': [TARGET_SOUND_G, TARGET_SOUND_K],
        'positions': [POSITION_INITIAL],
        'readingLevel': PICK_A_PATH_READING_LEVEL_PROFICIENT,
        'nodes': {
            [PICK_A_PATH_NODE_START]: {
                'text': [
                    "There once was a king named Carl who was kind and good. King Carl had such a sweet tooth!",
                    "Each year, King Carl would hold a contest to see who could create the yummiest concoction in the kingdom.",
                    "There were three categories in the competition:"
                ],
                'choices': [
                    ["Cookies", PICK_A_PATH_NODE_1],
                    ["Candy", PICK_A_PATH_NODE_2],
                    ["Cupcakes", PICK_A_PATH_NODE_3]
                ]
            },
            [PICK_A_PATH_NODE_1]: {
                'text': [
                    "King Carl couldn't wait to try the tastiest cookies in the kingdom. The cookie competition started with"
                ],
                'choices': [
                    ["a man named Calvin.", PICK_A_PATH_NODE_1A],
                    ["a woman named Carol.", PICK_A_PATH_NODE_1B],
                    ["a girl named Kate.", PICK_A_PATH_NODE_1C]
                ]
            },
            [PICK_A_PATH_NODE_1A]: {
                'text': [
                    "A man named Calvin carried a container of oatmeal cookies. King Carl grabbed one of Calvin's cookies and dunked it into his coffee.",
                    "\"Thanks for breakfast!\" he cackled."
                ],
            },
            [PICK_A_PATH_NODE_1B]: {
                'text': [
                    "A woman named Carol came with cute kitten-shaped cookies. King Carl commented that he couldn't wait to share one of Carol's cookies with his cat, Kevin."
                ],
            },
            [PICK_A_PATH_NODE_1C]: {
                'text': [
                    "A girl named Kate gave the king a cookie that tasted like cotton candy. King Carl took one bite and said, \"I will keep this cookie all to myself!\""
                ],
            },
            [PICK_A_PATH_NODE_2]: {
                'text': [
                    "The candy category always kept King Carl on his toes! First to come forward was"
                ],
                'choices': [
                    ["a guy named Casey.", PICK_A_PATH_NODE_2A],
                    ["a boy named Gabe.", PICK_A_PATH_NODE_2B],
                    ["a cute little boy.", PICK_A_PATH_NODE_2C]
                ]
            },
            [PICK_A_PATH_NODE_2A]: {
                'text': [
                    "A guy named Casey gave King Carl a goblet of colorful candy corn. There were so many colors!",
                    "\"You could call this Rainbow in a Cup,\" commented King Carl."
                ],
            },
            [PICK_A_PATH_NODE_2B]: {
                'text': [
                    "A boy named Gabe carried a gummy caterpillar wrapped in a cotton candy cocoon.",
                    "\"Can I eat this, or should I keep it until it becomes a butterfly?\" joked King Carl."
                ],
            },
            [PICK_A_PATH_NODE_2C]: {
                'text': [
                    "A cute little boy toddled up with a can of cola. King Carl was confused until he took a sip and realized that the cola tasted like birthday cake!",
                    "He patted the little guy on the head and kept on sipping."
                ],
            },
            [PICK_A_PATH_NODE_3]: {
                'text': [
                    "King Carl couldn't wait to get a taste of the kingdom's best cupcakes! He began by calling on"
                ],
                'choices': [
                    ["a woman named Kaya.", PICK_A_PATH_NODE_3A],
                    ["a girl named Gabby.", PICK_A_PATH_NODE_3B],
                    ["a person named Kai.", PICK_A_PATH_NODE_3C]
                ]
            },
            [PICK_A_PATH_NODE_3A]: {
                'text': [
                    "A woman named Kaya carried a tower of caramel cupcakes. King Carl loved caramel!",
                    "He asked Kaya if he could get a copy of her recipe."
                ],
            },
            [PICK_A_PATH_NODE_3B]: {
                'text': [
                    "A girl named Gabby carried a cupcake topped with a golden crown.",
                    "\"Be careful,\" she warned the king. \"The crown could turn your teeth to gold.\""
                ],
            },
            [PICK_A_PATH_NODE_3C]: {
                'text': [
                    "Kai's cupcake was topped with a curious birthday candle.",
                    "As King Carl blew out the candle, he realized that it was made of candy too! He couldn't wait to taste it!"
                ],
            },
            [PICK_A_PATH_NODE_END]: {
                'text': [
                    "When the competition was over, King Carl felt kind of sad. He couldn't wait for next year!"
                ],
            },
        }
    },
    'lucky-charms': {  // Medial K - Read-Together
        'name': "Lucky Charms",
        'dropdownName': "Lucky Charms (Medial K)",
        'targetSounds': [TARGET_SOUND_K],
        'positions': [POSITION_MEDIAL],
        'readingLevel': PICK_A_PATH_READING_LEVEL_EASY,
        'vocab': [
            ['acorn'],
            ['bacon'],
            ['bookbag'],
            ['hockey'],
            ['jacket'],
            ['Lakeisha'],
            ['licorice'],
            ['lucky'],
            ['marker'],
            ['Nicky'],
            ['pillowcase'],
            ['pocket'],
            ['popcorn'],
            ['Rebecca'],
            ['snack_bar', 'snack bar'],
            ['sneaker'],
            ['soccer'],
            ['soccer_ball', 'soccer ball'],
            ['suitcase'],
            ['talking'],
            ['thinking'],
            ['Zachary']
        ],
        'nodes': {
            [PICK_A_PATH_NODE_START]: {
                'text': [
                    "Some people have a <lucky|\"lucky> charm\" that they believe brings them luck. A <lucky> charm can be almost anything!",
                    "Some of our friends were <thinking> and <talking> about their <lucky> charms, and here's what they had to share."
                ],
                'choices': [
                    ["<Nicky> and <Lakeisha>", PICK_A_PATH_NODE_1],
                    ["<Rebecca> and <Zachary>", PICK_A_PATH_NODE_2],
                ]
            },
            [PICK_A_PATH_NODE_1]: {
                'text': [
                    "<Nicky> and <Lakeisha> have <lucky> charms that might fit in your"
                ],
                'choices': [
                    ["<pocket|pocket.>", PICK_A_PATH_NODE_1A],
                    ["<bookbag|bookbag.>", PICK_A_PATH_NODE_1B],
                ]
            },
            [PICK_A_PATH_NODE_1A]: {
                'text': [
                    "<Nicky> likes to carry his <lucky> charm in his <pocket|pocket.> It's a <lucky>"
                ],
                'choices': [
                    ["<acorn|acorn.>", PICK_A_PATH_NODE_1A1],
                    ["<marker|marker.>", PICK_A_PATH_NODE_1A2],
                ]
            },
            [PICK_A_PATH_NODE_1A1]: {
                'text': [
                    "<Nicky> puts his <lucky> <acorn> in his <pocket> every morning. He likes taking it to school with him."
                ],
            },
            [PICK_A_PATH_NODE_1A2]: {
                'text': [
                    "<Nicky|Nicky's> <lucky> <marker> is tiny and yellow. He says it helps him draw amazing pictures."
                ],
            },
            [PICK_A_PATH_NODE_1B]: {
                'text': [
                    "<Lakeisha> can fit her <lucky> charm in a <bookbag|bookbag.> It's her <lucky>"
                ],
                'choices': [
                    ["<pillowcase|pillowcase.>", PICK_A_PATH_NODE_1B1],
                    ["<soccer_ball|soccer ball.>", PICK_A_PATH_NODE_1B2],
                ]
            },
            [PICK_A_PATH_NODE_1B1]: {
                'text': [
                    "<Lakeisha|Lakeisha's> <lucky> charm is her <pillowcase|pillowcase.> She likes packing it in her <bookbag> when she goes on a trip."
                ],
            },
            [PICK_A_PATH_NODE_1B2]: {
                'text': [
                    "<Lakeisha|Lakeisha's> <soccer_ball|soccer ball> is her <lucky> charm. She likes taking it to school in her <bookbag|bookbag.>"
                ],
            },
            [PICK_A_PATH_NODE_2]: {
                'text': [
                    "<Rebecca> and <Zachary> have <lucky> charms that can be found in a"
                ],
                'choices': [
                    ["<snack_bar|snack bar.>", PICK_A_PATH_NODE_2A],
                    ["<suitcase|suitcase.>", PICK_A_PATH_NODE_2B],
                ]
            },
            [PICK_A_PATH_NODE_2A]: {
                'text': [
                    "If <Rebecca> needs her <lucky> charm, she just goes to the <snack_bar|snack bar!> Her <lucky> charm is"
                ],
                'choices': [
                    ["<popcorn|popcorn.>", PICK_A_PATH_NODE_2A1],
                    ["<licorice|licorice.>", PICK_A_PATH_NODE_2A2],
                ]
            },
            [PICK_A_PATH_NODE_2A1]: {
                'text': [
                    "<Rebecca> says that <popcorn> makes her feel <lucky|lucky -> she eats it before she plays <hockey|hockey.>"
                ],
            },
            [PICK_A_PATH_NODE_2A2]: {
                'text': [
                    "<Rebecca> loves <licorice> and says it helps her feel <lucky> in her <soccer> games."
                ],
            },
            [PICK_A_PATH_NODE_2B]: {
                'text': [
                    "<Zachary> wears his <lucky> charm every day. It's his <lucky>"
                ],
                'choices': [
                    ["<sneaker|sneaker.>", PICK_A_PATH_NODE_2B1],
                    ["<jacket|jacket.>", PICK_A_PATH_NODE_2B2],
                ]
            },
            [PICK_A_PATH_NODE_2B1]: {
                'text': [
                    "<Zachary> says his right <sneaker> is <lucky|lucky.> His left <sneaker> is pretty <lucky> too, because it gets to come along for the ride!"
                ],
            },
            [PICK_A_PATH_NODE_2B2]: {
                'text': [
                    "<Zachary> has a <jacket> with a special <pocket> where he keeps his <lucky> piece of <bacon|bacon.>"
                ],
            },
            [PICK_A_PATH_NODE_END]: {
                'text': [
                    "Do you have a <lucky> charm?"
                ],
            }
        }
    },
    'nickys-beagle': {  // Medial G/K - Proficient Reader
        'name': "Nicky's Beagle",
        'dropdownName': "Nicky's Beagle (Medial G/K)",
        'targetSounds': [TARGET_SOUND_G, TARGET_SOUND_K],
        'positions': [POSITION_MEDIAL],
        'readingLevel': PICK_A_PATH_READING_LEVEL_PROFICIENT,
        'nodes': {
            [PICK_A_PATH_NODE_START]: {
                'text': [
                    "My friend Nicky has a beagle puppy named Mickey. Mickey is adorable and naughty! He's always"
                ],
                'choices': [
                    ["barking.", PICK_A_PATH_NODE_1],
                    ["licking.", PICK_A_PATH_NODE_2],
                    ["digging.", PICK_A_PATH_NODE_3]
                ]
            },
            [PICK_A_PATH_NODE_1]: {
                'text': [
                    "Mickey sure loves barking! The other day I saw him barking at"
                ],
                'choices': [
                    ["a bucket.", PICK_A_PATH_NODE_1A],
                    ["a tiger.", PICK_A_PATH_NODE_1B],
                    ["an acorn.", PICK_A_PATH_NODE_1C]
                ]
            },
            [PICK_A_PATH_NODE_1A]: {
                'text': [
                    "Mickey was barking at a bucket full of popcorn. He must have been asking for some!"
                ],
            },
            [PICK_A_PATH_NODE_1B]: {
                'text': [
                    "Mickey wouldn't stop barking at Nicky's stuffed tiger. Nicky wiggled it around, which made Mickey bark louder!"
                ],
            },
            [PICK_A_PATH_NODE_1C]: {
                'text': [
                    "Mickey kept barking and growling at an acorn. Was he thinking of attacking it?"
                ],
            },
            [PICK_A_PATH_NODE_2]: {
                'text': [
                    "Mickey will taste anything! Yesterday I caught him licking"
                ],
                'choices': [
                    ["a pickle.", PICK_A_PATH_NODE_2A],
                    ["a chicken.", PICK_A_PATH_NODE_2B],
                    ["my ankle.", PICK_A_PATH_NODE_2C]
                ]
            },
            [PICK_A_PATH_NODE_2A]: {
                'text': [
                    "The pickle was sticking out of Nicky's lunchbox at an inviting angle. Lucky Mickey got to it before Nicky!"
                ],
            },
            [PICK_A_PATH_NODE_2B]: {
                'text': [
                    "The chicken was clucking and minding its own business. I don't know why Mickey thought licking it was a good idea!"
                ],
            },
            [PICK_A_PATH_NODE_2C]: {
                'text': [
                    "I was walking through Nicky's kitchen when I felt something tickle my ankle - it was Mickey licking me!"
                ],
            },
            [PICK_A_PATH_NODE_3]: {
                'text': [
                    "Mickey loves digging! Last weekend I found him digging up"
                ],
                'choices': [
                    ["a nickel.", PICK_A_PATH_NODE_3A],
                    ["some pumpkin seeds.", PICK_A_PATH_NODE_3B],
                    ["a sneaker.", PICK_A_PATH_NODE_3C]
                ]
            },
            [PICK_A_PATH_NODE_3A]: {
                'text': [
                    "I found an old nickel where Mickey was digging. I thought it looked lucky so I put it in my pocket."
                ],
            },
            [PICK_A_PATH_NODE_3B]: {
                'text': [
                    "\"No, Mickey!\" cried Nicky. \"You'll ruin my uncle's pumpkin patch!\""
                ],
            },
            [PICK_A_PATH_NODE_3C]: {
                'text': [
                    "Nicky couldn't believe it when I told him. \"I've been looking for that sneaker since August!\" he said."
                ],
            },
            [PICK_A_PATH_NODE_END]: {
                'text': [
                    "Mickey is a handful, but I still think Nicky is lucky to have him."
                ],
            },
        }
    },
    'doug-blake-mark': {  // Final G/K - Read-Together
        'name': "A Day with Doug, Blake, and Mark",
        'dropdownName': "A Day with Doug, Blake, and Mark (Final G/K)",
        'targetSounds': [TARGET_SOUND_G, TARGET_SOUND_K],
        'positions': [POSITION_FINAL],
        'readingLevel': PICK_A_PATH_READING_LEVEL_EASY,
        'vocab': [
            ['back'],
            ['bag'],
            ['bark'],
            ['big'],
            ['black'],
            ['Blake'],
            ['book'],
            ['bug'],
            ['chick'],
            ['dark'],
            ['dig'],
            ['dog'],
            ['Doug'],
            ['egg'],
            ['fantastic'],
            ['flag'],
            ['frog'],
            ['garlic'],
            ['jog'],
            ['kick'],
            ['ladybug'],
            ['lake'],
            ['lightning_bug', 'lightning bug'],
            ['like'],
            ['log'],
            ['look'],
            ['Mark'],
            ['mistake'],
            ['New_York', 'New York'],
            ['oclock', "o'clock"],
            ['park'],
            ['pig'],
            ['rock'],
            ['sidewalk'],
            ['snack'],
            ['snake'],
            ['speak'],
            ['steak'],
            ['stick'],
            ['stuck'],
        ],
        'nodes': {
            [PICK_A_PATH_NODE_START]: {
                'text': [
                    "<Doug|Doug,> <Blake|Blake,> and <Mark> had a talk and decided to"
                ],
                'choices': [
                    ["<jog> in <New_York|New York.>", PICK_A_PATH_NODE_1],
                    ["hike to the <lake|lake.>", PICK_A_PATH_NODE_2],
                ]
            },
            [PICK_A_PATH_NODE_1]: {
                'text': [
                    "<Doug|\"Doug,> did you pack a <snack|snack?\"> asked <Mark|Mark.> \"Sure,\" said <Doug|Doug,> \"I have some"
                ],
                'choices': [
                    ["<garlic> in a <bag|bag.>", PICK_A_PATH_NODE_1A],
                    ["<steak> and an <egg|egg.>", PICK_A_PATH_NODE_1B],
                ]
            },
            [PICK_A_PATH_NODE_1A]: {
                'text': [
                    "<Doug|Doug,> <Mark|Mark,> and <Blake> ate their <snack|snack,> then started to <jog> on the <sidewalk|sidewalk.> <Blake> asked <Mark> and <Doug|Doug,> \"Should we"
                ],
                'choices': [
                    ["go to the <dog> <park|park?\">", PICK_A_PATH_NODE_1A1],
                    ["look at the <flag> by the <lake|lake?\">", PICK_A_PATH_NODE_1A2],
                ]
            },
            [PICK_A_PATH_NODE_1A1]: {
                'text': [
                    "<Blake|Blake,> <Mark|Mark,> and <Doug> found the <park|park,> but it took them a week to get there! Do you think the <garlic> <snack> was a <mistake|mistake?>"
                ],
            },
            [PICK_A_PATH_NODE_1A2]: {
                'text': [
                    "<Doug|Doug,> <Blake|Blake,> and <Mark> took a <look> around the <lake|lake.> <Mark> stopped to <speak> to his friend Meg, who asked, \"Did you see that really <big> <bug|bug?\">"
                ],
            },
            [PICK_A_PATH_NODE_1B]: {
                'text': [
                    "After their <snack|snack,> <Blake|Blake,> <Doug|Doug,> and <Mark> started their <jog> on the <sidewalk|sidewalk.> <Doug> said, \"Stop! I want to go <back> to pick up that"
                ],
                'choices': [
                    ["<log|log.>", PICK_A_PATH_NODE_1B1],
                    ["<rock|rock.>", PICK_A_PATH_NODE_1B2],
                ]
            },
            [PICK_A_PATH_NODE_1B1]: {
                'text': [
                    "<Doug> took a close <look> at the <log|log.> The <log> was <stuck> to the <sidewalk|sidewalk.> <Doug> said \"I made a <mistake|mistake,\"> and decided to <look> at a <ladybug> instead."
                ],
            },
            [PICK_A_PATH_NODE_1B2]: {
                'text': [
                    "<Doug> went <back> to pick up the <rock|rock.> He wanted to <kick> the <rock> along the <sidewalk> during their <jog|jog.> <Blake> and <Mark> said that would be a <big> <mistake|mistake!>"
                ],
            },
            [PICK_A_PATH_NODE_2]: {
                'text': [
                    "<Doug> said, \"a hike to the <lake> would be <fantastic|fantastic!> Let's take my"
                ],
                'choices': [
                    ["<dog> Jack.", PICK_A_PATH_NODE_2A],
                    ["pet <pig|pig.>", PICK_A_PATH_NODE_2B],
                ]
            },
            [PICK_A_PATH_NODE_2A]: {
                'text': [
                    "<Doug|Doug,> <Blake|Blake,> <Mark|Mark,> and Jack left for their hike at two <oclock|o'clock.> Jack started to <bark> when he saw a"
                ],
                'choices': [
                    ["<chick|chick.>", PICK_A_PATH_NODE_2A1],
                    ["<frog|frog.>", PICK_A_PATH_NODE_2A2],
                ]
            },
            [PICK_A_PATH_NODE_2A1]: {
                'text': [
                    "The <chick> was <stuck> under a <log|log!> <Blake|Blake,> <Doug|Doug,> and <Mark> got a <stick> to <dig> out the <chick|chick.> The <chick> gave a cluck as a thank you."
                ],
            },
            [PICK_A_PATH_NODE_2A2]: {
                'text': [
                    "The <frog> was eating a <snack> with a <fork|fork!> <Doug> asked the <frog|frog,> \"Did you make that <cake|cake?\">"
                ],
            },
            [PICK_A_PATH_NODE_2B]: {
                'text': [
                    "<Blake> and <Mark> laughed! <Doug|\"Doug,> we <like> that joke! You don't have a pet <pig|pig.\"> <Mark> said, \"Ok, then I'd <like> to take our <book> about the"
                ],
                'choices': [
                    ["<black> <snake|snake.>", PICK_A_PATH_NODE_2B1],
                    ["<big> <ladybug|ladybug.>", PICK_A_PATH_NODE_2B2],
                ]
            },
            [PICK_A_PATH_NODE_2B1]: {
                'text': [
                    "<Mark> took a close <look> at the <black> <snake> in the <book|book.> He asked <Doug|Doug,> \"Would it be a <mistake> to poke a <snake> with a <big> <stick|stick?\">"
                ],
            },
            [PICK_A_PATH_NODE_2B2]: {
                'text': [
                    "<Doug|Doug,> <Blake|Blake,> and <Mark> read the <book> about the <big> <ladybug> until it got <dark> and they saw a <lightning_bug|lightning bug.>"
                ],
            },
        }
    },
    'big-book': {  // Final G/K - Proficient Reader
        'name': "The Big Book",
        'dropdownName': "The Big Book (Final G/K)",
        'targetSounds': [TARGET_SOUND_G, TARGET_SOUND_K],
        'positions': [POSITION_FINAL],
        'readingLevel': PICK_A_PATH_READING_LEVEL_PROFICIENT,
        'nodes': {
            [PICK_A_PATH_NODE_START]: {
                'text': [
                    "Luke and Blake found a big book on a desk at school. They decided to take a peek into the book to see what it was about.",
                    "The book was full of stories about a"
                ],
                'choices': [
                    ["pig.", PICK_A_PATH_NODE_1],
                    ["duck.", PICK_A_PATH_NODE_2],
                    ["bug.", PICK_A_PATH_NODE_3]
                ]
            },
            [PICK_A_PATH_NODE_1]: {
                'text': [
                    "The book was about a pig named Zeke who had a favorite"
                ],
                'choices': [
                    ["snack.", PICK_A_PATH_NODE_1A],
                    ["rug.", PICK_A_PATH_NODE_1B],
                    ["tote bag.", PICK_A_PATH_NODE_1C]
                ]
            },
            [PICK_A_PATH_NODE_1A]: {
                'text': [
                    "Zeke's favorite snack was egg and steak. I think I'd Iike that snack too!"
                ],
            },
            [PICK_A_PATH_NODE_1B]: {
                'text': [
                    "Zeke would sit on his rug, lie back with a book, and read all day."
                ],
            },
            [PICK_A_PATH_NODE_1C]: {
                'text': [
                    "Zeke always carried his favorite tote bag. It held a rock, a sock, a stick, and a brick."
                ],
            },
            [PICK_A_PATH_NODE_2]: {
                'text': [
                    "The book was about a duck named Mike who loved to"
                ],
                'choices': [
                    ["quack.", PICK_A_PATH_NODE_2A],
                    ["cook.", PICK_A_PATH_NODE_2B],
                    ["bake.", PICK_A_PATH_NODE_2C]
                ]
            },
            [PICK_A_PATH_NODE_2A]: {
                'text': [
                    "Mike believed he could quack like no other duck. His own voice was music to his ears."
                ],
            },
            [PICK_A_PATH_NODE_2B]: {
                'text': [
                    "Mike loved to cook with garlic. He would even put garlic on a pancake - yuck!"
                ],
            },
            [PICK_A_PATH_NODE_2C]: {
                'text': [
                    "Mike baked a beautiful birthday cake for his stork friend, Monique."
                ],
            },
            [PICK_A_PATH_NODE_3]: {
                'text': [
                    "The book was about a bug named Flick who liked to"
                ],
                'choices': [
                    ["picnic.", PICK_A_PATH_NODE_3A],
                    ["brag.", PICK_A_PATH_NODE_3B],
                    ["dig.", PICK_A_PATH_NODE_3C]
                ]
            },
            [PICK_A_PATH_NODE_3A]: {
                'text': [
                    "Flick would take a tiny rug and spread it on the ground. Then she'd unpack her snack and listen to music while she ate."
                ],
            },
            [PICK_A_PATH_NODE_3B]: {
                'text': [
                    "Flick would smirk as she spoke about her big feats."
                ],
            },
            [PICK_A_PATH_NODE_3C]: {
                'text': [
                    "Flick liked to dig under a big rock where it was cool and dark."
                ],
            },
            [PICK_A_PATH_NODE_END]: {
                'text': [
                    "Luke and Blake thought the big book was fantastic!"
                ],
            },
        }
    },

    // Target sound L
    'lukes-lost-lunch': {  // Initial L - Read-Together
        'name': "Luke's Lost Lunch",
        'dropdownName': "Luke's Lost Lunch (Initial L)",
        'targetSounds': [TARGET_SOUND_L],
        'positions': [POSITION_INITIAL],
        'readingLevel': PICK_A_PATH_READING_LEVEL_EASY,
        'vocab': [
            ['ladder'],
            ['ladybug'],
            ['lake'],
            ['lamb'],
            ['Larry'],
            ['laugh'],
            ['lawn'],
            ['lawnmower'],
            ['lemons'],
            ['lemonade'],
            ['letter'],
            ['library'],
            ['lifeguard'],
            ['lift'],
            ['light'],
            ['lighthouse'],
            ['lime'],
            ['lion'],
            ['lizard'],
            ['lock'],
            ['log'],
            ['lollipops'],
            ['look'],
            ['look', 'looking'],
            ['loud'],
            ['lucky'],
            ['Luke'],
            ['Luke', "Luke's"],
            ['lunch'],
        ],
        'nodes': {
            [PICK_A_PATH_NODE_START]: {
                'text': ["Oh no! <Luke> has lost his <lunch|lunch.> He asks <Larry|Larry,> \"Where should I <look|look?\"> <Larry> tells him to <look>"],
                'choices': [
                    ["by the <lake|lake.>", PICK_A_PATH_NODE_1],
                    ["on the <lawn|lawn.>", PICK_A_PATH_NODE_2],
                ]
            },
            [PICK_A_PATH_NODE_1]: {
                'text': ["<Luke> and <Larry> do not see a <lunch> box by the <lake|lake,> but they do see a"],
                'choices': [
                    ["<ladybug|ladybug.>", PICK_A_PATH_NODE_1A],
                    ["<lizard|lizard.>", PICK_A_PATH_NODE_1B],
                ]
            },
            [PICK_A_PATH_NODE_1A]: {
                'text': ["The <ladybug> tells them to <look> by the"],
                'choices': [
                    ["<lighthouse|lighthouse.>", PICK_A_PATH_NODE_1A1],
                    ["<library|library.>", PICK_A_PATH_NODE_1A2],
                ]
            },
            [PICK_A_PATH_NODE_1A1]: {
                'text': ["The <lighthouse> has a <lock> on the door. <Larry> and <Luke> <laugh> and say, \"Let's keep <look|looking!\">"],
            },
            [PICK_A_PATH_NODE_1A2]: {
                'text': ["<Luke> and <Larry> leave and go to the <library|library.> There is just a little <light> but they can see <Luke|Luke's> <lunch|lunch!>"],
            },
            [PICK_A_PATH_NODE_1B]: {
                'text': ["The <lizard> tells them to ask the"],
                'choices': [
                    ["<lifeguard|lifeguard.>", PICK_A_PATH_NODE_1B1],
                    ["little <lamb|lamb.>", PICK_A_PATH_NODE_1B2],
                ]
            },
            [PICK_A_PATH_NODE_1B1]: {
                'text': ["How <lucky|lucky!> The <lifeguard> has <Luke|Luke's> <lunch|lunch.>"],
            },
            [PICK_A_PATH_NODE_1B2]: {
                'text': ["The little <lamb> does not have <Luke|Luke's> <lunch> but asks, \"Would you like some <lemonade|lemonade?\">"],
            },
            [PICK_A_PATH_NODE_2]: {
                'text': ["<Luke> and <Larry> look on the <lawn> and see a"],
                'choices': [
                    ["<lion|lion.>", PICK_A_PATH_NODE_2A],
                    ["<letter|letter.>", PICK_A_PATH_NODE_2B],
                ]
            },
            [PICK_A_PATH_NODE_2A]: {
                'text': ["The <lion> tells <Larry> and <Luke> that he needs help"],
                'choices': [
                    ["making <lemonade|lemonade.>", PICK_A_PATH_NODE_2A1],
                    ["mowing the <lawn|lawn.>", PICK_A_PATH_NODE_2A2],
                ]
            },
            [PICK_A_PATH_NODE_2A1]: {
                'text': ["<Luke> and <Larry> help the <lion> make <lemonade|lemonade.> They use a lot of <lemons|lemons!>"],
            },
            [PICK_A_PATH_NODE_2A2]: {
                'text': ["<Luke> and <Larry> help the <lion> mow the <lawn|lawn.> The large <lawnmower> is so <loud|loud!>"],
            },
            [PICK_A_PATH_NODE_2B]: {
                'text': ["<Larry> and <Luke> read the <letter|letter.> The <letter> says they need to <look> under a"],
                'choices': [
                    ["<log|log.>", PICK_A_PATH_NODE_2B1],
                    ["<ladder|ladder.>", PICK_A_PATH_NODE_2B2],
                ]
            },
            [PICK_A_PATH_NODE_2B1]: {
                'text': ["<Luke> and <Larry> <lift> up a <log> and find a <lime|lime.> They <laugh> and say, \"Let's <look> later!\""],
            },
            [PICK_A_PATH_NODE_2B2]: {
                'text': ["<Larry> and <Luke> <look> under a <ladder> and find two <lollipops|lollipops!>"],
            },
        }
    },
    'litterbug-larry': {  // Initial L - Proficient Reader
        'name': "Larry the Litterbug",
        'dropdownName': "Larry the Litterbug (Initial L)",
        'targetSounds': [TARGET_SOUND_L],
        'positions': [POSITION_INITIAL],
        'readingLevel': PICK_A_PATH_READING_LEVEL_PROFICIENT,
        'nodes': {
            [PICK_A_PATH_NODE_START]: {
                'text': [
                    "Larry is a loveable guy, but he's a huge litterbug! Larry leaves little pieces of garbage everywhere he goes.",
                    "One day Leo, Laura, and Luke were walking to the park when they saw Larry up ahead. Larry took something from his pocket, looked at it, then let it drop to the sidewalk.",
                    "\"Oh, Larry!\" lamented Laura. She looked at her friends. \"Let's go see what he's left this time.\"",
                    "When they got to the spot where Larry left his litter, they found"
                ],
                'choices': [
                    ["a shopping list.", PICK_A_PATH_NODE_1],
                    ["a library card.", PICK_A_PATH_NODE_2],
                    ["a letter.", PICK_A_PATH_NODE_3]
                ]
            },
            [PICK_A_PATH_NODE_1]: {
                'text': [
                    "Larry's shopping list lay right there on the sidewalk."
                ],
                'choices': [
                    ["Laura read the list.", PICK_A_PATH_NODE_1A],
                    ["Leo looked at the list.", PICK_A_PATH_NODE_1B],
                    ["Luke lifted the list off the ground.", PICK_A_PATH_NODE_1C]
                ]
            },
            [PICK_A_PATH_NODE_1A]: {
                'text': [
                    "It listed lemons, long straws, and lots of sugar. Laura ran to catch up with Larry.",
                    "\"Larry,\" she asked, \"are you planning a lemonade stand?\"",
                    "\"Why yes I am,\" said Larry. \"I'd love for you to stop by!\""
                ],
            },
            [PICK_A_PATH_NODE_1B]: {
                'text': [
                    "Leo said, \"there's only one thing on this list - a loaf of bread!\"",
                    "With a little shiver, Leo thought of the liver and leek sandwiches Larry loved to pack in his lunches."
                ],
            },
            [PICK_A_PATH_NODE_1C]: {
                'text': [
                    "Luke laughed when he read the list: licorice, lollipops, lemon drops, and Laffy Taffy.",
                    "\"Wow,\" said Luke. \"Larry sure loves candy!\""],
            },
            [PICK_A_PATH_NODE_2]: {
                'text': [
                    "Larry had left his library card lying on the ground."
                ],
                'choices': [
                    ["Leo ran to return it.", PICK_A_PATH_NODE_2A],
                    ["Laura called to Larry.", PICK_A_PATH_NODE_2B],
                    ["Luke caught up with Larry.", PICK_A_PATH_NODE_2C]
                ]
            },
            [PICK_A_PATH_NODE_2A]: {
                'text': [
                    "After Leo gave Larry his library card, Larry showed him the book he had gotten on loan. It was about large cats.",
                    "Lions were Larry's favorite, but Leo decided he liked leopards best."
                ],
            },
            [PICK_A_PATH_NODE_2B]: {
                'text': [
                    "Larry heard Laura and looked up. He had been leaning against a tree and reading a library book about landmarks.",
                    "Larry told Laura that he had already learned about the Liberty Bell, London Bridge, and the Leaning Tower of Pisa."
                ],
            },
            [PICK_A_PATH_NODE_2C]: {
                'text': [
                    "Luke asked what he'd gotten at the library. Larry showed him a big book about lighthouses.",
                    "Larry told Luke that the Great Lakes have over 200 lighthouses. \"Wow! That's a lot!\" laughed Luke."
                ],
            },
            [PICK_A_PATH_NODE_3]: {
                'text': [
                    "The letter was from their friend Leah, who"
                ],
                'choices': [
                    ["was visiting her cousin.", PICK_A_PATH_NODE_3A],
                    ["had written a limerick.", PICK_A_PATH_NODE_3B],
                    ["said she was learning to line dance.", PICK_A_PATH_NODE_3C]
                ]
            },
            [PICK_A_PATH_NODE_3A]: {
                'text': [
                    "Leah was visiting her cousin Lindsay in Louisiana.",
                    "\"That's right,\" said Laura. \"While Leah is away, Larry is caring for her Labrador retrievers, Linus and Lucy!\""
                ],
            },
            [PICK_A_PATH_NODE_3B]: {
                'text': [
                    "Leah had written a limerick for Larry. It was about a lizard named Liza, and it made Luke and Larry laugh out loud."
                ],
            },
            [PICK_A_PATH_NODE_3C]: {
                'text': [
                    "Leah was learning to line dance in Lubbock, Texas.",
                    "Leo, who liked country music a lot, wondered if Leah would teach him the line dance when she got home."
                ],
            },
            [PICK_A_PATH_NODE_END]: {
                'text': [
                    "Laura, Luke, and Leo like Larry a lot and hope that he learns not to leave his litter where it doesn't belong!"
                ],
            },
        }
    },
    'ellas-day': {  // Medial L - Read-Together
        'name': "Ella's Silly Day",
        'dropdownName': "Ella's Silly Day (Medial L)",
        'targetSounds': [TARGET_SOUND_L],
        'positions': [POSITION_MEDIAL],
        'readingLevel': PICK_A_PATH_READING_LEVEL_EASY,
        'vocab': [
            ['Alaska'],
            ['Alex'],
            ['alligators'],
            ['ballet'],
            ['bowling'],
            ['broccoli'],
            ['buffalo'],
            ['chocolate'],
            ['colorful'],
            ['delicious'],
            ['elephant'],
            ['eleven'],
            ['Ella'],
            ['follow'],
            ['gorillas'],
            ['helicopter'],
            ['hello'],
            ['island'],
            ['jellybeans'],
            ['koala'],
            ['lollipop'],
            ['melon'],
            ['pilot'],
            ['polite'],
            ['really'],
            ['sailboat'],
            ['salad'],
            ['silly'],
            ['smelly'],
            ['trampoline'],
            ['valley'],
            ['yellow'],
        ],
        'nodes': {
            [PICK_A_PATH_NODE_START]: {
                'text': [
                    "<Ella> saw her friend <Alex> and said, <follow|\"Follow> me! Today we are going to"
                ],
                'choices': [
                    ["<lollipop|Lollipop> <island|Island!\">", PICK_A_PATH_NODE_1],
                    ["<buffalo|Buffalo> <valley|Valley!\">", PICK_A_PATH_NODE_2],
                ]
            },
            [PICK_A_PATH_NODE_1]: {
                'text': [
                    "<lollipop|\"Lollipop> <island|Island> is a good choice!\" said <Ella|Ella.> \"How will we get there?\" asked <Alex|Alex.> They decided to take a"
                ],
                'choices': [
                    ["<helicopter|helicopter.>", PICK_A_PATH_NODE_1A],
                    ["<sailboat|sailboat.>", PICK_A_PATH_NODE_1B],
                ]
            },
            [PICK_A_PATH_NODE_1A]: {
                'text': [
                    "A <helicopter> needs a <pilot|pilot,> so <Alex> and <Ella> called"
                ],
                'choices': [
                    ["Wally the <koala|Koala.>", PICK_A_PATH_NODE_1A1],
                    ["Hailey the <elephant|Elephant.>", PICK_A_PATH_NODE_1A2],
                ]
            },
            [PICK_A_PATH_NODE_1A1]: {
                'text': [
                    "Wally the <koala|Koala> was doing <ballet> in <Alaska> and could not take <Ella> and <Alex> to <lollipop|Lollipop> <island|Island.>"
                ],
            },
            [PICK_A_PATH_NODE_1A2]: {
                'text': [
                    "Hailey the <elephant|Elephant> said <hello> to <Ella> and <Alex|Alex,> and that she would love to take them to <lollipop|Lollipop> <island|Island!>"
                ],
            },
            [PICK_A_PATH_NODE_1B]: {
                'text': [
                    "The <sailboat> ride will take six hours. What snacks should <Alex> and <Ella> bring along?"
                ],
                'choices': [
                    ["<melon> and <broccoli>", PICK_A_PATH_NODE_1B1],
                    ["<chocolate> and <jellybeans>", PICK_A_PATH_NODE_1B2],
                ]
            },
            [PICK_A_PATH_NODE_1B1]: {
                'text': [
                    "The <melon> and <broccoli> made a <delicious> <salad|salad!>"
                ],
            },
            [PICK_A_PATH_NODE_1B2]: {
                'text': [
                    "<Alex> ate the <chocolate> and <Ella> ate the <jellybeans|jellybeans.> They were <delicious|delicious!>"
                ],
            },
            [PICK_A_PATH_NODE_2]: {
                'text': [
                    "<buffalo|\"Buffalo> <valley|Valley> is really fun!\" said <Ella|Ella.> She couldn't wait to show <Alex> the"
                ],
                'choices': [
                    ["<gorillas|gorillas.>", PICK_A_PATH_NODE_2A],
                    ["<alligators|alligators.>", PICK_A_PATH_NODE_2B],
                ]
            },
            [PICK_A_PATH_NODE_2A]: {
                'text': [
                    "The <gorillas> in <buffalo|Buffalo> <valley|Valley> can be <really>"
                ],
                'choices': [
                    ["<silly|silly.>", PICK_A_PATH_NODE_2A1],
                    ["<smelly|smelly.>", PICK_A_PATH_NODE_2A2],
                ]
            },
            [PICK_A_PATH_NODE_2A1]: {
                'text': [
                    "The <silly> <gorillas> like to jump on a <yellow> <trampoline|trampoline!>"
                ],
            },
            [PICK_A_PATH_NODE_2A2]: {
                'text': [
                    "The <smelly> <gorillas> only take a bath every <eleven> days!"
                ],
            },
            [PICK_A_PATH_NODE_2B]: {
                'text': [
                    "The <alligators> in <buffalo|Buffalo> <valley|Valley> can be <really>"
                ],
                'choices': [
                    ["<polite|polite.>", PICK_A_PATH_NODE_2B1],
                    ["<colorful|colorful.>", PICK_A_PATH_NODE_2B2],
                ]
            },
            [PICK_A_PATH_NODE_2B1]: {
                'text': [
                    "The <polite> <alligators> love to go <bowling|bowling!>"
                ],
            },
            [PICK_A_PATH_NODE_2B2]: {
                'text': [
                    "The <colorful> <alligators> love to eat <jellybeans|jellybeans!>"
                ],
            },
        }
    },
    'silly-billy': {  // Medial L - Proficient Reader
        'name': "Silly Billy",
        'dropdownName': "Silly Billy (Medial L)",
        'targetSounds': [TARGET_SOUND_L],
        'positions': [POSITION_MEDIAL],
        'readingLevel': PICK_A_PATH_READING_LEVEL_PROFICIENT,
        'nodes': {
            [PICK_A_PATH_NODE_START]: {
                'text': ["Billy is an awfully peculiar fellow. His family likes calling him \"Silly Billy\" because he wants to",],
                'choices': [
                    ["do some really foolish things.", PICK_A_PATH_NODE_1],
                    ["use objects in outlandish ways.", PICK_A_PATH_NODE_2],
                    ["eat some truly ghastly foods.", PICK_A_PATH_NODE_3]
                ]
            },
            [PICK_A_PATH_NODE_1]: {
                'text': ["Billy has some really foolish ideas! Just yesterday, he asked his family if he was allowed to"],
                'choices': [
                    ["relax with an armadillo.", PICK_A_PATH_NODE_1A],
                    ["make a necklace out of bowling pins.", PICK_A_PATH_NODE_1B],
                    ["deliver balloons to a walrus.", PICK_A_PATH_NODE_1C]
                ]
            },
            [PICK_A_PATH_NODE_1A]: {
                'text': ["Ridiculous! Armadillo shells are too solid for cuddling."],
            },
            [PICK_A_PATH_NODE_1B]: {
                'text': ["Hilarious! Imagine Billy strolling around with a necklace that heavy."],
            },
            [PICK_A_PATH_NODE_1C]: {
                'text': ["Pointless! A walrus doesn't have fingers to hold balloons."],
            },
            [PICK_A_PATH_NODE_2]: {
                'text': ["Billy does some truly outlandish things! He uses"],
                'choices': [
                    ["the toilet to hold tulips.", PICK_A_PATH_NODE_2A],
                    ["a lollipop for an umbrella.", PICK_A_PATH_NODE_2B],
                    ["a gallon of jelly as a pillow.", PICK_A_PATH_NODE_2C]
                ]
            },
            [PICK_A_PATH_NODE_2A]: {
                'text': ["Shameless! A vase would be more than tolerable for holding tulips."],
            },
            [PICK_A_PATH_NODE_2B]: {
                'text': ["Priceless! How could a lollipop shelter Billy in a deluge?"],
            },
            [PICK_A_PATH_NODE_2C]: {
                'text': ["Revolting! Billy is always rolling out of bed sticky and smelling of marmalade."],
            },
            [PICK_A_PATH_NODE_3]: {
                'text': ["Only Silly Billy would try to eat such ghastly things. Just yesterday he"],
                'choices': [
                    ["poured relish on his chocolate cake.", PICK_A_PATH_NODE_3A],
                    ["added jellybeans to his salad.", PICK_A_PATH_NODE_3B],
                    ["dipped olives in marshmallow cream.", PICK_A_PATH_NODE_3C]
                ]
            },
            [PICK_A_PATH_NODE_3A]: {
                'text': ["Scandalous! Billy is allergic to chocolate!"],
            },
            [PICK_A_PATH_NODE_3B]: {
                'text': ["Appalling! Cannellini are the most popular salad bean."],
            },
            [PICK_A_PATH_NODE_3C]: {
                'text': ["Outlandish! Obviously cantaloupe compliments marshmallows best."],
            },
            [PICK_A_PATH_NODE_END]: {
                'text': ["Billy sure is silly, but he's really funny too!"],
            },
        }
    },
    'camille-camel': {  // Final L - Read-Together
        'name': "Camille and the Camel",
        'dropdownName': "Camille and the Camel (Final L)",
        'targetSounds': [TARGET_SOUND_L],
        'positions': [POSITION_FINAL],
        'readingLevel': PICK_A_PATH_READING_LEVEL_EASY,
        'vocab': [
            ['baseball'],
            ['bicycle'],
            ['camel'],
            ['Camille'],
            ['cereal'],
            ['circle'],
            ['cool'],
            ['fairy_tale', 'fairy tale'],
            ['fall'],
            ['hall'],
            ['hill'],
            ['hotel'],
            ['meatball'],
            ['mile'],
            ['pail'],
            ['pencil'],
            ['pickle'],
            ['pile'],
            ['pool'],
            ['popsicle'],
            ['school'],
            ['seal'],
            ['sill'],
            ['small'],
            ['smell'],
            ['stool'],
            ['tadpole'],
            ['tall'],
            ['triangle'],
            ['unicycle'],
            ['whale'],
            ['windmill'],
        ],
        'nodes': {
            [PICK_A_PATH_NODE_START]: {
                'text': [
                    "One <fall> day, <Camille> went to <school> and saw a <camel> in the <hall|hall!> The <camel> said, \"Hi, my name is Bill. Bill the <camel> was so"
                ],
                'choices': [
                    ["<small|small.>", PICK_A_PATH_NODE_1],
                    ["<tall|tall.>", PICK_A_PATH_NODE_2],
                ]
            },
            [PICK_A_PATH_NODE_1]: {
                'text': [
                    "Bill the <camel> was so <small> that <Camille> could have put him in a <pail|pail!> Bill the <camel> asked <Camille|Camille,> \"Can you show me how to draw a"
                ],
                'choices': [
                    ["<circle|circle?\">", PICK_A_PATH_NODE_1A],
                    ["<triangle|triangle?\">", PICK_A_PATH_NODE_1B],
                ]
            },
            [PICK_A_PATH_NODE_1A]: {
                'text': [
                    "<Camille> used a <pencil> to make a <circle|circle.> Bill the <camel> made a <circle> too. \"That was so <cool|cool,\"> said Bill the <camel|camel.> \"Now I will sit on this <stool> and eat"
                ],
                'choices': [
                    ["some <cereal|cereal.\">", PICK_A_PATH_NODE_1A1],
                    ["a <popsicle|popsicle.\">", PICK_A_PATH_NODE_1A2],
                ]
            },
            [PICK_A_PATH_NODE_1A1]: {
                'text': [
                    "<Camille> and Bill the <camel> each ate a full bowl of <cereal|cereal,> then they played some <baseball|baseball.>"
                ],
            },
            [PICK_A_PATH_NODE_1A2]: {
                'text': [
                    "Bill the <camel> and <Camille> each ate a <popsicle|popsicle.> After that they still had time to read a <fairy_tale|fairy tale.>"
                ],
            },
            [PICK_A_PATH_NODE_1B]: {
                'text': [
                    "<Camille> used a <pencil> to make a <triangle|triangle.> Bill the <camel> made a <triangle> too. \"That went well!\" said <Camille|Camille.> \"Now would you like to see a"
                ],
                'choices': [
                    ["<whale|whale?\">", PICK_A_PATH_NODE_1B1],
                    ["<tadpole|tadpole?\">", PICK_A_PATH_NODE_1B2],
                ]
            },
            [PICK_A_PATH_NODE_1B1]: {
                'text': [
                    "Bill the <camel> and <Camille> rode a <bicycle> down the <hill> to the <pool> and saw a <whale|whale!> It was so <cool|cool!>"
                ],
            },
            [PICK_A_PATH_NODE_1B2]: {
                'text': [
                    "<Camille> and Bill the <camel> went down the <hall|hall.> They stood on a <stool> to see a <tadpole> swimming in a bowl. It was so <small|small!>"
                ],
            },
            [PICK_A_PATH_NODE_2]: {
                'text': [
                    "Bill the <camel> was as <tall> as a <windmill|windmill!> <Camille> asked Bill the <camel|camel,> \"Do you <smell> a"
                ],
                'choices': [
                    ["<pickle|pickle?\">", PICK_A_PATH_NODE_2A],
                    ["<meatball|meatball?\">", PICK_A_PATH_NODE_2B],
                ]
            },
            [PICK_A_PATH_NODE_2A]: {
                'text': [
                    "\"Yes, I <smell> a <pickle|pickle,\"> said Bill the <camel|camel.> \"There's a big <pile> on the <sill|sill.> Let's eat one, then we can ride a"
                ],
                'choices': [
                    ["<bicycle|bicycle.\">", PICK_A_PATH_NODE_2A1],
                    ["<unicycle|unicycle.\">", PICK_A_PATH_NODE_2A2],
                ]
            },
            [PICK_A_PATH_NODE_2A1]: {
                'text': [
                    "<Camille> and Bill the <camel> rode a <bicycle> up the <hill> for one <mile|mile.> They needed to stop in the middle and cool down with a <popsicle|popsicle!>"
                ],
            },
            [PICK_A_PATH_NODE_2A2]: {
                'text': [
                    "Bill the <camel> and <Camille> rode a <unicycle> in a <circle|circle.> They didn't even <fall|fall!>"
                ],
            },
            [PICK_A_PATH_NODE_2B]: {
                'text': [
                    "\"Yes, I smell a <meatball|meatball,\"> said Bill the <camel|camel.> \"We can leave it here and let it cool. Right now let's climb that <hill> to see the"
                ],
                'choices': [
                    ["<windmill|windmill.\">", PICK_A_PATH_NODE_2B1],
                    ["<hotel|hotel.\">", PICK_A_PATH_NODE_2B2],
                ]
            },
            [PICK_A_PATH_NODE_2B1]: {
                'text': [
                    "Bill the <camel> and <Camille> walked a <mile> up the <hill> to see the <windmill|windmill.> It was a long walk from <school|school!>"
                ],
            },
            [PICK_A_PATH_NODE_2B2]: {
                'text': [
                    "<Camille> and Bill the <camel> ran up the <hill> to the <hotel> <pool|pool,> where they jumped in and swam with a <seal|seal!>"
                ],
            },
        }
    },
    'runaway-meatball': {  // Final L - Proficient Reader
        'name': "The Runaway Meatball",
        'dropdownName': "The Runaway Meatball (Final L)",
        'targetSounds': [TARGET_SOUND_L],
        'positions': [POSITION_FINAL],
        'readingLevel': PICK_A_PATH_READING_LEVEL_PROFICIENT,
        'nodes': {
            [PICK_A_PATH_NODE_START]: {
                'text': [
                    "One fine April day, a boy named Will was eating spaghetti. All of a sudden, a meatball fell out of his bowl and began to roll across the kitchen.",
                    "Will tried to grab it, but he was too late - the meatball was on its way! He hopped up and"
                ],
                'choices': [
                    ["chased the meatball down the hall.", PICK_A_PATH_NODE_1],
                    ["chased the meatball through the backyard.", PICK_A_PATH_NODE_2],
                    ["chased the meatball down the street.", PICK_A_PATH_NODE_3]
                ]
            },
            [PICK_A_PATH_NODE_1]: {
                'text': ["Will ran as fast as he could down the hall. He almost caught the meatball, but he"],
                'choices': [
                    ["tripped over a step stool.", PICK_A_PATH_NODE_1A],
                    ["slipped on a banana peel.", PICK_A_PATH_NODE_1B],
                    ["knocked over a pile of mail.", PICK_A_PATH_NODE_1C]
                ]
            },
            [PICK_A_PATH_NODE_1A]: {
                'text': ["Will fell headfirst into the wall and spilled a can of ginger ale all over the hall."],
            },
            [PICK_A_PATH_NODE_1B]: {
                'text': ["Will's sister Jill must have dropped the peel while putting banana in her oatmeal."],
            },
            [PICK_A_PATH_NODE_1C]: {
                'text': ["\"At least I found the missing phone bill,\" thought Will as he stacked the mail back into a pile."],
            },
            [PICK_A_PATH_NODE_2]: {
                'text': ["Will chased the meatball across the whole backyard, but before he could reach it, he saw"],
                'choices': [
                    ["his meatball roll down a hill.", PICK_A_PATH_NODE_2A],
                    ["the meatball roll under the wheel of a unicycle.", PICK_A_PATH_NODE_2B],
                    ["a squirrel steal the meatball.", PICK_A_PATH_NODE_2C]
                ]
            },
            [PICK_A_PATH_NODE_2A]: {
                'text': ["The hill was tall and Will didn't want to fall. He stood still and waved farewell to his meatball."],
            },
            [PICK_A_PATH_NODE_2B]: {
                'text': ["Will's face fell. \"Now it's a meat circle, not a meatball!\""],
            },
            [PICK_A_PATH_NODE_2C]: {
                'text': ["With a smile, Will watched the squirrel dribble the meatball and shoot it into a small goal!"],
            },
            [PICK_A_PATH_NODE_3]: {
                'text': ["Will ran down the street with all his might, but he still watched his meatball roll"],
                'choices': [
                    ["onto a full mini golf course.", PICK_A_PATH_NODE_3A],
                    ["into a fashionable shopping mall.", PICK_A_PATH_NODE_3B],
                    ["to the edge of a hotel swimming pool.", PICK_A_PATH_NODE_3C]
                ]
            },
            [PICK_A_PATH_NODE_3A]: {
                'text': ["Will watched the meatball roll into the mouth of a big purple whale. \"HOLE IN ONE!\" shouted Will."],
            },
            [PICK_A_PATH_NODE_3B]: {
                'text': ["Will forgot all about his meatball when he saw his friend Mel buying a cool baseball hat."],
            },
            [PICK_A_PATH_NODE_3C]: {
                'text': ["Will was able to stop before he fell in. Meanwhile, his meatball sank to the bottom of the pool."],
            },
            [PICK_A_PATH_NODE_END]: {
                'text': ["Will went home and finished his meal. As he ate, he wondered if the meatball had been worth all of that trouble."],
            },
        }
    },
    'planting-playing': {  // L Blends - Read-Together
        'name': "Planting and Playing",
        'dropdownName': "Planting and Playing (L Blends)",
        'targetSounds': [TARGET_SOUND_L],
        'positions': [POSITION_BL_BLEND, POSITION_FL_BLEND, POSITION_GL_BLEND, POSITION_KL_BLEND, POSITION_PL_BLEND, POSITION_SL_BLEND],
        'readingLevel': PICK_A_PATH_READING_LEVEL_EASY,
        'vocab': [
            ['apple'],
            ['asleep'],
            ['blackbird'],
            ['Blake'],
            ['Blake', "Blake's"],
            ['blanket'],
            ['blocks'],
            ['bluebell'],
            ['blueberry'],
            ['butterfly'],
            ['clap', 'claps'],
            ['class'],
            ['clay'],
            ['clean'],
            ['clean', 'cleaned'],
            ['closet'],
            ['complete'],
            ['flapjack', 'flapjacks'],
            ['Flo'],
            ['Florida'],
            ['flower'],
            ['fly'],
            ['giggling'],
            ['glad'],
            ['Glen'],
            ['glider_plane', 'glider plane'],
            ['glob'],
            ['igloo'],
            ['necklace'],
            ['oclock', "o'clock"],
            ['plan'],
            ['plant'],
            ['plate'],
            ['play'],
            ['sled_dog', 'sled dogs'],
            ['sleeve'],
            ['sleigh'],
            ['slice', 'slices'],
            ['slide'],
            ['snowflakes'],
            ['sunflower'],
        ],
        'nodes': {
            [PICK_A_PATH_NODE_START]: {
                'text': [
                    "<Blake> and <Glen> are always <glad> to see each other! Today they <plan> to"
                ],
                'choices': [
                    ["<plant|plant.>", PICK_A_PATH_NODE_1],
                    ["<play|play.>", PICK_A_PATH_NODE_2],
                ]
            },
            [PICK_A_PATH_NODE_1]: {
                'text': [
                    "<Blake|Blake's> <plan> is to work in the garden. He wants to <plant> a"
                ],
                'choices': [
                    ["<flower|flower.>", PICK_A_PATH_NODE_1A],
                    ["<blueberry> bush.", PICK_A_PATH_NODE_1B],
                ]
            },
            [PICK_A_PATH_NODE_1A]: {
                'text': [
                    "<Blake> pulls a packet out of his <sleeve|sleeve.> He and <Glen> are going to <plant>"
                ],
                'choices': [
                    ["<sunflower> seeds.", PICK_A_PATH_NODE_1A1],
                    ["<bluebell> seeds.", PICK_A_PATH_NODE_1A2],
                ]
            },
            [PICK_A_PATH_NODE_1A1]: {
                'text': [
                    "<Blake> and <Glen> <plant> <sunflower> seeds until 2 <oclock|o'clock.> Then they sit on a <blanket> and eat some <apple> <slice|slices.>"
                ],
            },
            [PICK_A_PATH_NODE_1A2]: {
                'text': [
                    "A <blackbird> watches <Glen> and <Blake> <plant> <bluebell> seeds. When the job is <complete|complete,> they <clean> up and <play> on <Blake|Blake's> <slide|slide.>"
                ],
            },
            [PICK_A_PATH_NODE_1B]: {
                'text': [
                    "<Glen> <clap|claps> his hands when he sees <Blake|Blake's> <blueberry> seeds! He is already thinking about"
                ],
                'choices': [
                    ["<blueberry> pie.", PICK_A_PATH_NODE_1B1],
                    ["<blueberry> jam.", PICK_A_PATH_NODE_1B2],
                ]
            },
            [PICK_A_PATH_NODE_1B1]: {
                'text': [
                    "<Glen> can't wait for the <blueberry> bush to grow so he can give some to his Aunt Cleo. Her <blueberry> pie is first-class!"
                ],
            },
            [PICK_A_PATH_NODE_1B2]: {
                'text': [
                    "<Glen> starts <giggling> and dreaming about a <plate> of <flapjack|flapjacks> covered in <blueberry> jam!"
                ],
            },
            [PICK_A_PATH_NODE_2]: {
                'text': [
                    "<Glen> would be <glad> to stay inside and <play> with"
                ],
                'choices': [
                    ["<blocks|blocks.>", PICK_A_PATH_NODE_2A],
                    ["<clay|clay.>", PICK_A_PATH_NODE_2B],
                ]
            },
            [PICK_A_PATH_NODE_2A]: {
                'text': [
                    "<Glen> pulls his <blocks> out of the <closet|closet.> He can't wait to build a"
                ],
                'choices': [
                    ["<glider_plane|glider plane.>", PICK_A_PATH_NODE_2A1],
                    ["<sleigh|sleigh.>", PICK_A_PATH_NODE_2A2],
                ]
            },
            [PICK_A_PATH_NODE_2A1]: {
                'text': [
                    "<Glen> wants to <fly> his <glider_plane|glider plane> all the way to <Florida|Florida.> <Blake> says he would <fly> it to England!"
                ],
            },
            [PICK_A_PATH_NODE_2A2]: {
                'text': [
                    "<Glen> works on his <sleigh> while <Blake> builds an <igloo|igloo.> They think about <snowflakes> and <sled_dog|sled dogs.>"
                ],
            },
            [PICK_A_PATH_NODE_2B]: {
                'text': [
                    "<Blake> takes a <glob> of colorful <clay|clay.> He plans to make a"
                ],
                'choices': [
                    ["<butterfly|butterfly.>", PICK_A_PATH_NODE_2B1],
                    ["<necklace|necklace.>", PICK_A_PATH_NODE_2B2],
                ]
            },
            [PICK_A_PATH_NODE_2B1]: {
                'text': [
                    "<Blake> makes a <butterfly> out of <clay|clay.> He plans to take it to school and show it to his <class|class.>"
                ],
            },
            [PICK_A_PATH_NODE_2B2]: {
                'text': [
                    "<Blake> makes <clay> beads for a <necklace|necklace.> He plans to give the <necklace> to his sister <Flo|Flo.>"
                ],
            },
            [PICK_A_PATH_NODE_END]: {
                'text': [
                    "<Blake> and <Glen> <clean|cleaned> up and said goodbye at 5 <oclock|o'clock.> They were both <asleep> before dinner!"
                ],
            }
        }
    },
    'floras-blue-ribbon': {  // L Blends - Proficient Reader
        'name': "Flora's Blue Ribbon",
        'dropdownName': "Flora's Blue Ribbon (L Blends)",
        'targetSounds': [TARGET_SOUND_L],
        'positions': [POSITION_BL_BLEND, POSITION_FL_BLEND, POSITION_GL_BLEND, POSITION_KL_BLEND, POSITION_PL_BLEND, POSITION_SL_BLEND],
        'readingLevel': PICK_A_PATH_READING_LEVEL_PROFICIENT,
        'nodes': {
            [PICK_A_PATH_NODE_START]: {
                'text': [
                    "Flora's alarm rang at six o'clock, but she was already awake. She glanced towards the window and caught a glimpse of the cloudless blue sky. Flora smiled. Today was the county fair!",
                    "Flora arrived at the fairgrounds and followed signs for the"
                ],
                'choices': [
                    ["PLANT TENT", PICK_A_PATH_NODE_1],
                    ["ANIMAL ENCLOSURE", PICK_A_PATH_NODE_2],
                    ["FOOD TABLES", PICK_A_PATH_NODE_3]
                ]
            },
            [PICK_A_PATH_NODE_1]: {
                'text': ["This tent was where people displayed plants that they'd carefully grown from seeds. Flora found the area marked for"],
                'choices': [
                    ["flowers.", PICK_A_PATH_NODE_1A],
                    ["vegetables.", PICK_A_PATH_NODE_1B],
                    ["fruit.", PICK_A_PATH_NODE_1C]
                ]
            },
            [PICK_A_PATH_NODE_1A]: {
                'text': [
                    "Flora had entered her bluebells in the plant competition. Their purplish-blue blossoms were shaped like tiny bells.",
                    "They were glorious!"
                ],
            },
            [PICK_A_PATH_NODE_1B]: {
                'text': [
                    "Flora's vegetable garden was legendary. Today she'd brought a purple eggplant and multiple heads of cauliflower.",
                    "The only thing better than their appearance was their flavor!"
                ],
            },
            [PICK_A_PATH_NODE_1C]: {
                'text': ["Flora had brought her best blueberry bush. Its plump, purple berries dangled in clusters from every branch."],
            },
            [PICK_A_PATH_NODE_2]: {
                'text': ["The animal show was Flora's favorite part of the fair. This year would be her first to enter an animal of her own, and she had brought a splendid"],
                'choices': [
                    ["black sheep.", PICK_A_PATH_NODE_2A],
                    ["beagle puppy.", PICK_A_PATH_NODE_2B],
                    ["little piglet.", PICK_A_PATH_NODE_2C]
                ]
            },
            [PICK_A_PATH_NODE_2A]: {
                'text': ["Her sheep was named Clara, and she had the fluffiest black fleece that Flora had ever seen. Clara blinked her sleepy eyes and nibbled a bit of hay."],
            },
            [PICK_A_PATH_NODE_2B]: {
                'text': ["Flora's baby beagle was named Maple. Everyone clapped and chuckled at Maple's floppy ears and adorable howling barks."],
            },
            [PICK_A_PATH_NODE_2C]: {
                'text': ["Of all of her piglets, Blossom was Flora's favorite. Blossom's squiggly tail and wiggly snout were irresistible!"],
            },
            [PICK_A_PATH_NODE_3]: {
                'text': [
                    "Flora saw people milling around and inspecting the food displays.",
                    "She found her table, put down a placemat, then set out her best"
                ],
                'choices': [
                    ["blackberry cobbler.", PICK_A_PATH_NODE_3A],
                    ["plum crumble.", PICK_A_PATH_NODE_3B],
                    ["maple syrup.", PICK_A_PATH_NODE_3C]
                ]
            },
            [PICK_A_PATH_NODE_3A]: {
                'text': ["Sugar glittered on the crust of Flora's cobbler. She took a cloth and wiped away a bit of blackberry syrup before it dribbled onto the placemat."],
            },
            [PICK_A_PATH_NODE_3B]: {
                'text': ["Flora's plum crumble glistened with an apple blossom honey glaze. She couldn't wait for the judge to have a spoonful!"],
            },
            [PICK_A_PATH_NODE_3C]: {
                'text': ["Flora drizzled some of her maple syrup over a plate containing flapjacks and waffles. She tasted a bit on her finger - simply delectable!"],
            },
            [PICK_A_PATH_NODE_END]: {
                'text': ["At the end of the day, Flora was gleeful. She had won first place and was taking home a blue ribbon!"],
            },
        }
    },

    // Target sound R
    'run-rex': {  // Initial R - Read-Together
        'name': "Run, Rex, Run!",
        'dropdownName': "Run, Rex, Run! (Initial R)",
        'targetSounds': [TARGET_SOUND_R],
        'positions': [POSITION_INITIAL],
        'readingLevel': PICK_A_PATH_READING_LEVEL_EASY,
        'vocab': [
            ['rabbit'],
            ['race'],
            ['race', 'raced'],
            ['raccoon'],
            ['radishes'],
            ['rag'],
            ['rain'],
            ['ram'],
            ['ran'],
            ['ranch'],
            ['rattlesnake'],
            ['really'],
            ['red'],
            ['rest'],
            ['rest', 'rested'],
            ['rice'],
            ['ride'],
            ['rind'],
            ['risk'],
            ['road'],
            ['rock'],
            ['rodeo'],
            ['room'],
            ['rope'],
            ['rooster'],
            ['round'],
            ['rug'],
            ['run'],
        ],
        'nodes': {
            [PICK_A_PATH_NODE_START]: {
                'text': [
                    "My dog Rex is <really> fast. He can <run> faster than a"
                ],
                'choices': [
                    ["<rabbit|rabbit.>", PICK_A_PATH_NODE_1],
                    ["<race> horse.", PICK_A_PATH_NODE_2],
                ]
            },
            [PICK_A_PATH_NODE_1]: {
                'text': [
                    "Last week I watched Rex <race> a <rabbit> down the <road|road.> Rex won. After they raced, Rex and the <rabbit> sat down on a"
                ],
                'choices': [
                    ["<rock|rock.>", PICK_A_PATH_NODE_1A],
                    ["<rug|rug.>", PICK_A_PATH_NODE_1B],
                ]
            },
            [PICK_A_PATH_NODE_1A]: {
                'text': [
                    "Rex and the <rabbit> sat on a <rock> and shared a bowl of"
                ],
                'choices': [
                    ["<radishes|radishes.>", PICK_A_PATH_NODE_1A1],
                    ["<rice|rice.>", PICK_A_PATH_NODE_1A2],
                ]
            },
            [PICK_A_PATH_NODE_1A1]: {
                'text': [
                    "Rex and the <rabbit> ate crunchy <red> <radishes> until their bellies were full and <round|round.>"
                ],
            },
            [PICK_A_PATH_NODE_1A2]: {
                'text': [
                    "By the time they were finished, Rex and the <rabbit> were covered in sticky <rice|rice!>"
                ],
            },
            [PICK_A_PATH_NODE_1B]: {
                'text': [
                    "Rex and the <rabbit> <rest|rested> on a <rug> and watched a"
                ],
                'choices': [
                    ["<raccoon|raccoon.>", PICK_A_PATH_NODE_1B1],
                    ["<rattlesnake|rattlesnake.>", PICK_A_PATH_NODE_1B2],
                ]
            },
            [PICK_A_PATH_NODE_1B1]: {
                'text': [
                    "The <raccoon> was eating the <rind> of a ripe melon. He seemed <really> happy."
                ],
            },
            [PICK_A_PATH_NODE_1B2]: {
                'text': [
                    "The <rattlesnake> was sleeping on a <rock|rock.> Rex and the <rabbit> didn't <risk> getting close."
                ],
            },
            [PICK_A_PATH_NODE_2]: {
                'text': [
                    "One day Rex raced a <race> horse around a racetrack. Rex won. After the <race|race,> the horse gave Rex a <ride> to a"
                ],
                'choices': [
                    ["<ranch|ranch.>", PICK_A_PATH_NODE_2A],
                    ["<rodeo|rodeo.>", PICK_A_PATH_NODE_2B],
                ]
            },
            [PICK_A_PATH_NODE_2A]: {
                'text': [
                    "Rex loved the <ranch|ranch!> He <ran> around all day. He even saw a"
                ],
                'choices': [
                    ["<rooster|rooster.>", PICK_A_PATH_NODE_2A1],
                    ["<ram|ram.>", PICK_A_PATH_NODE_2A2],
                ]
            },
            [PICK_A_PATH_NODE_2A1]: {
                'text': [
                    "The <rooster> had a <red> comb on its head and sat on top of a <red> tractor. The <rooster> had a <really> loud voice!"
                ],
            },
            [PICK_A_PATH_NODE_2A2]: {
                'text': [
                    "The <ram> climbed on top of a <really> big <rock> and watched the <rain> roll in."
                ],
            },
            [PICK_A_PATH_NODE_2B]: {
                'text': [
                    "Rex had so much fun at the <rodeo|rodeo.> He raced around the ring with a"
                ],
                'choices': [
                    ["<rope|rope.>", PICK_A_PATH_NODE_2B1],
                    ["<rodeo> clown.", PICK_A_PATH_NODE_2B2],
                ]
            },
            [PICK_A_PATH_NODE_2B1]: {
                'text': [
                    "Rex took a <rope> and <race|raced> around the <room|room.> The <rodeo> riders couldn't catch him!"
                ],
            },
            [PICK_A_PATH_NODE_2B2]: {
                'text': [
                    "Rex took a <rag> from the <rodeo> clown and <race|raced> around the ring. It was <really> funny!"
                ],
            },
            [PICK_A_PATH_NODE_END]: {
                'text': [
                    "After running around all day, Rex likes to <rest> on the <rug> in my <room|room.> Good boy, Rex!"
                ],
            }
        }
    },
    'racing-rudy': {  // Initial R - Proficient Reader
        'name': "Racing Rudy",
        'dropdownName': "Racing Rudy (Initial R)",
        'targetSounds': [TARGET_SOUND_R],
        'positions': [POSITION_INITIAL],
        'readingLevel': PICK_A_PATH_READING_LEVEL_PROFICIENT,
        'nodes': {
            [PICK_A_PATH_NODE_START]: {
                'text': [
                    "Rudy loved to race. It didn't really matter what he was doing; he was always ready to see if he could be the fastest.",
                    "One day Rudy was at"
                ],
                'choices': [
                    ["the river.", PICK_A_PATH_NODE_1],
                    ["a restaurant.", PICK_A_PATH_NODE_2],
                    ["recess.", PICK_A_PATH_NODE_3]
                ]
            },
            [PICK_A_PATH_NODE_1]: {
                'text': [
                    "Rudy's friend Raina invited him to a relaxing day at the river. Right after they got there, Rudy saw a"
                ],
                'choices': [
                    ["raft.", PICK_A_PATH_NODE_1A],
                    ["rowboat.", PICK_A_PATH_NODE_1B],
                    ["rattlesnake.", PICK_A_PATH_NODE_1C]
                ]
            },
            [PICK_A_PATH_NODE_1A]: {
                'text': [
                    "Rudy saw a raft floating right in the middle of the river.",
                    "\"I'll race you to that raft!\" said Rudy.",
                    "\"No way,\" replied Raina. \"Swimming through the rapids would be too risky.\""
                ],
            },
            [PICK_A_PATH_NODE_1B]: {
                'text': [
                    "Rudy saw a rowboat tied to a tree with some rope.",
                    "\"I bet I can swim faster than you can row!\" said Rudy.",
                    "\"Get ready,\" said Raina, flexing her muscles. \"I'm a ridiculously strong rower.\""
                ],
            },
            [PICK_A_PATH_NODE_1C]: {
                'text': [
                    "Rudy saw a rattlesnake resting on a rock.",
                    "As much as he liked to race, Rudy was reasonable. He left the rattlesnake alone and made a hasty retreat."
                ],
            },
            [PICK_A_PATH_NODE_2]: {
                'text': [
                    "Rudy and his family went to dinner at a nice restaurant. While they were there, Rudy wanted to"
                ],
                'choices': [
                    ["read the menu aloud.", PICK_A_PATH_NODE_2A],
                    ["grab the receipt.", PICK_A_PATH_NODE_2B],
                    ["drink ranch dressing.", PICK_A_PATH_NODE_2C]
                ]
            },
            [PICK_A_PATH_NODE_2A]: {
                'text': [
                    "Rudy told his sister Reba that he could read the menu faster than she could. But Reba was faster. By the time Rudy read all the way to the end, Reba was enjoying some rhubarb pie."
                ],
            },
            [PICK_A_PATH_NODE_2B]: {
                'text': [
                    "Rudy wanted to race his uncle Rob to grab the receipt.",
                    "\"If you win,\" said Rob, \"you're required to pay for the meal.\"",
                    "Rudy rapidly rethought his plan."
                ],
            },
            [PICK_A_PATH_NODE_2C]: {
                'text': [
                    "Rudy challenged his cousin Randy to see who would be first to finish a bowl of ranch dressing.",
                    "\"That would be too rude,\" replied Randy, who was always polite and respectful."
                ],
            },
            [PICK_A_PATH_NODE_3]: {
                'text': [
                    "Recess was Rudy's favorite part of the day. There were so many ways to race! He regarded the"
                ],
                'choices': [
                    ["playhouse roof.", PICK_A_PATH_NODE_3A],
                    ["rock pile.", PICK_A_PATH_NODE_3B],
                    ["rope swing.", PICK_A_PATH_NODE_3C]
                ]
            },
            [PICK_A_PATH_NODE_3A]: {
                'text': [
                    "Rudy challenged his friend Reggie to race to the playhouse roof. Reggie was ready!",
                    "They were both really fast, but Reggie reached the roof first.",
                    "\"Nice race,\" said Rudy, who was always a really good sport."
                ],
            },
            [PICK_A_PATH_NODE_3B]: {
                'text': [
                    "Rudy thought it would be fun to see who could run 10 rocks from one end of the playground to the other. His friends Rebecca and Ryan were ready to join the race.",
                    "When the dust settled, Rebecca had finished first, with Rudy and Ryan close behind."
                ],
            },
            [PICK_A_PATH_NODE_3C]: {
                'text': [
                    "\"WHO WANTS TO RACE ME TO THE ROPE SWING?\" shouted Rudy.",
                    "He was delighted to recruit ten rivals! He and his fellow racers remained busy for the rest of recess."
                ],
            },
            [PICK_A_PATH_NODE_END]: {
                'text': [
                    "At the end of the day, Rudy was rather exhausted. He raced himself right into bed!"
                ],
            },
        }
    },
    'arties-birthday': {  // Vocalic R - Read-Together
        'name': "Artie's Birthday",
        'dropdownName': "Artie's Birthday (Vocalic R)",
        'targetSounds': [TARGET_SOUND_R],
        'positions': [POSITION_VOCALIC_AIR, POSITION_VOCALIC_AR, POSITION_VOCALIC_EAR, POSITION_VOCALIC_ER, POSITION_VOCALIC_IRE, POSITION_VOCALIC_OR],
        'readingLevel': PICK_A_PATH_READING_LEVEL_EASY,
        'vocab': [
            ['Artie'],
            ['Artie', "Artie's"],
            ['birthday'],
            ['Charlie'],
            ['Charlie', "Charlie's"],
            ['cheer', 'cheered'],
            ['cheery'],
            ['ear', 'ears'],
            ['Erin'],
            ['four'],
            ['garden'],
            ['guitar'],
            ['hairy'],
            ['hear'],
            ['horn'],
            ['merry'],
            ['near'],
            ['park'],
            ['party'],
            ['scare', 'scared'],
            ['scary'],
            ['soccer'],
            ['soccer_ball', 'soccer ball'],
            ['start'],
            ['teddy_bear', 'teddy bear'],
            ['there'],
            ['thirsty'],
            ['very'],
            ['whiskers'],
        ],
        'nodes': {
            [PICK_A_PATH_NODE_START]: {
                'text': [
                    "It was time for <Artie|Artie's> <birthday> <party|party!> He was waiting for his friends to arrive. The first one to get <there> was"
                ],
                'choices': [
                    ["<Charlie|Charlie.>", PICK_A_PATH_NODE_1],
                    ["<Erin|Erin.>", PICK_A_PATH_NODE_2],
                ]
            },
            [PICK_A_PATH_NODE_1]: {
                'text': [
                    "<Charlie> wanted to <start> the <party> with a <birthday> song for <Artie|Artie.> He played the song on a"
                ],
                'choices': [
                    ["<guitar|guitar.>", PICK_A_PATH_NODE_1A],
                    ["<horn|horn.>", PICK_A_PATH_NODE_1B],
                ]
            },
            [PICK_A_PATH_NODE_1A]: {
                'text': [
                    "<Charlie> played the <guitar> and sang a funny song about <Artie|Artie's>"
                ],
                'choices': [
                    ["<whiskers|whiskers.>", PICK_A_PATH_NODE_1A1],
                    ["<ear|ears.>", PICK_A_PATH_NODE_1A2],
                ]
            },
            [PICK_A_PATH_NODE_1A1]: {
                'text': [
                    "<Charlie> sang a song about <Artie|Artie's> <whiskers|whiskers,> which were <very> long. <Artie> laughed and laughed!"
                ],
            },
            [PICK_A_PATH_NODE_1A2]: {
                'text': [
                    "<Charlie> sang a song about <Artie|Artie's> floppy <ear|ears.> When <Artie> heard it, he clapped and <cheer|cheered.>"
                ],
            },
            [PICK_A_PATH_NODE_1B]: {
                'text': [
                    "<Charlie> played the song on a funny little <horn|horn.> The song was"
                ],
                'choices': [
                    ["<cheery|cheery.>", PICK_A_PATH_NODE_1B1],
                    ["<merry|merry.>", PICK_A_PATH_NODE_1B2],
                ]
            },
            [PICK_A_PATH_NODE_1B1]: {
                'text': [
                    "<Charlie|Charlie's> <cheery> song made all of the <party> guests draw <near> to <hear> it."
                ],
            },
            [PICK_A_PATH_NODE_1B2]: {
                'text': [
                    "<Charlie|Charlie's> song was a <merry> little tune. <Artie> loved it <very> much!"
                ],
            },
            [PICK_A_PATH_NODE_2]: {
                'text': [
                    "<Erin> said, \"Happy <birthday|Birthday,> <Artie|Artie!> I have a gift for you. It was a"
                ],
                'choices': [
                    ["<soccer_ball|soccer ball.>", PICK_A_PATH_NODE_2A],
                    ["<teddy_bear|teddy bear.>", PICK_A_PATH_NODE_2B],
                ]
            },
            [PICK_A_PATH_NODE_2A]: {
                'text': [
                    "<Artie> was excited about his new <soccer_ball|soccer ball!> \"Come on,\" he said, \"let's go to the"
                ],
                'choices': [
                    ["<park|park.>", PICK_A_PATH_NODE_2A1],
                    ["<garden|garden.>", PICK_A_PATH_NODE_2A2],
                ]
            },
            [PICK_A_PATH_NODE_2A1]: {
                'text': [
                    "<Artie> moved his <party> to the <park|park,> where he and his friends played <soccer> for <four> hours"
                ],
            },
            [PICK_A_PATH_NODE_2A2]: {
                'text': [
                    "<Artie> and his friends went out to the <garden> and kicked the <soccer_ball|soccer ball.> They got <very> <thirsty> and had to stop for water!"
                ],
            },
            [PICK_A_PATH_NODE_2B]: {
                'text': [
                    "<Artie> thought his new <teddy_bear|teddy bear> looked <very>"
                ],
                'choices': [
                    ["<scary|scary.>", PICK_A_PATH_NODE_2B1],
                    ["<hairy|hairy.>", PICK_A_PATH_NODE_2B2],
                ]
            },
            [PICK_A_PATH_NODE_2B1]: {
                'text': [
                    "<Artie|\"Artie,> don't be <scare|scared!\"> said <Erin|Erin.> \"This <teddy_bear|teddy bear> is <very> sweet!\""
                ],
            },
            [PICK_A_PATH_NODE_2B2]: {
                'text': [
                    "<Artie> had never seen such a <hairy> <teddy_bear|teddy bear!> He liked it <very> much."
                ],
            },
            [PICK_A_PATH_NODE_END]: {
                'text': [
                    "<Artie> was <very> happy. He'd had a great <birthday|birthday!>"
                ],
            }
        }
    },
    'is-it-scary': {  // Vocalic AIR - Proficient Reader
        'name': "Is it Scary?",
        'dropdownName': "Is it Scary? (Vocalic AIR)",
        'targetSounds': [TARGET_SOUND_R],
        'positions': [POSITION_VOCALIC_AIR],
        'readingLevel': PICK_A_PATH_READING_LEVEL_PROFICIENT,
        'nodes': {
            [PICK_A_PATH_NODE_START]: {
                'text': [
                    "People have very different ideas about what is scary and what isn't. We asked some friends to share their thoughts."
                ],
                'choices': [
                    ["Are these things scary?", PICK_A_PATH_NODE_1],
                    ["How about these things?", PICK_A_PATH_NODE_2],
                    ["And these?", PICK_A_PATH_NODE_3]
                ]
            },
            [PICK_A_PATH_NODE_1]: {
                'text': [
                    "Would you be scared of"
                ],
                'choices': [
                    ["a grizzly bear in its lair?", PICK_A_PATH_NODE_1A],
                    ["a parrot with hair?", PICK_A_PATH_NODE_1B],
                    ["a carrot wearing sunglasses?", PICK_A_PATH_NODE_1C]
                ]
            },
            [PICK_A_PATH_NODE_1A]: {
                'text': [
                    "Mary says a grizzly bear would be very scary, but Terrace says only barely.",
                    "Do you agree with Mary or with Terrance?"
                ],
            },
            [PICK_A_PATH_NODE_1B]: {
                'text': [
                    "Harrison wouldn't care if he saw a parrot with hair, but Carrie says she'd get out of there!",
                    "Do you agree with Harrison or with Carrie?"
                ],
            },
            [PICK_A_PATH_NODE_1C]: {
                'text': [
                    "Darius thinks this would be hilarious, not scary.",
                    "Do you agree with Darius?"
                ],
            },
            [PICK_A_PATH_NODE_2]: {
                'text': [
                    "Would you be scared to spot"
                ],
                'choices': [
                    ["a very dark stairwell?", PICK_A_PATH_NODE_2A],
                    ["two fairies having a staring contest?", PICK_A_PATH_NODE_2B],
                    ["a heron on its way to get married?", PICK_A_PATH_NODE_2C]
                ]
            },
            [PICK_A_PATH_NODE_2A]: {
                'text': [
                    "Aaron would be careful venturing close to those stairs, but Caroline is more daring in the dark.",
                    "Are you more like Aaron or Caroline?"
                ],
            },
            [PICK_A_PATH_NODE_2B]: {
                'text': [
                    "Terrance thinks the staring contest would be a funny affair, especially if the fairies were floating in midair!",
                    "Do you think it would be scary?"
                ],
            },
            [PICK_A_PATH_NODE_2C]: {
                'text': [
                    "Karen would be very happy to spot a heron flying to its wedding with flair. Darrell says he just wouldn't care.",
                    "Are you more like Karen or Darrell?"
                ],
            },
            [PICK_A_PATH_NODE_3]: {
                'text': [
                    "Would it be scary to happen upon"
                ],
                'choices': [
                    ["a sign that says BEWARE?", PICK_A_PATH_NODE_3A],
                    ["a mare on a ferris wheel?", PICK_A_PATH_NODE_3B],
                    ["a hare running a marathon?", PICK_A_PATH_NODE_3C]
                ]
            },
            [PICK_A_PATH_NODE_3A]: {
                'text': [
                    "Ariel says it depends on where the sign is posted. Larry is sure the sign is warning of a snare.",
                    "How about you? Would you go there?"
                ],
            },
            [PICK_A_PATH_NODE_3B]: {
                'text': [
                    "Erin and Perry both want to know how a mare would have gotten on a ferris wheel.",
                    "Do you have any ideas of how it might get up there?"
                ],
            },
            [PICK_A_PATH_NODE_3C]: {
                'text': [
                    "Harry thinks this would be terrifying and unfair, because the hare would tear up the competition.",
                    "Do you think it would be fair for the hare to be there?"
                ],
            },
            [PICK_A_PATH_NODE_END]: {
                'text': [
                    "Which of these do you think is the scariest?"
                ],
            },
        }
    },
    'farm-day': {  // Vocalic AR - Proficient Reader
        'name': "A Day at the Farm",
        'dropdownName': "A Day at the Farm (Vocalic AR)",
        'targetSounds': [TARGET_SOUND_R],
        'positions': [POSITION_VOCALIC_AR],
        'readingLevel': PICK_A_PATH_READING_LEVEL_PROFICIENT,
        'nodes': {
            [PICK_A_PATH_NODE_START]: {
                'text': [
                    "Marcus and Omar had the best time at their family's farm last week! They loved helping"
                ],
                'choices': [
                    ["in the barnyard.", PICK_A_PATH_NODE_1],
                    ["in the garden.", PICK_A_PATH_NODE_2],
                    ["with cousin Martha.", PICK_A_PATH_NODE_3]
                ]
            },
            [PICK_A_PATH_NODE_1]: {
                'text': [
                    "The barnyard was Omar's favorite part of the farm. Together, he and Marcus"
                ],
                'choices': [
                    ["harnessed a donkey.", PICK_A_PATH_NODE_1A],
                    ["fed the large sheepdog.", PICK_A_PATH_NODE_1B],
                    ["watched a goat climb the barn.", PICK_A_PATH_NODE_1C]
                ]
            },
            [PICK_A_PATH_NODE_1A]: {
                'text': [
                    "The donkey, named Charlie, would be pulling a cart to the farmers' market."
                ],
            },
            [PICK_A_PATH_NODE_1B]: {
                'text': [
                    "The smart sheepdog's name was Marty. He helped keep the farm's sheep in line with his short, sharp barks."
                ],
            },
            [PICK_A_PATH_NODE_1C]: {
                'text': [
                    "Arthur the goat liked to climb onto the roof of the barn. \"He looks like he's standing guard,\" laughed Marcus."
                ],
            },
            [PICK_A_PATH_NODE_2]: {
                'text': [
                    "There were so many vegetables in the garden! Marcus and Omar helped harvest"
                ],
                'choices': [
                    ["parsnips.", PICK_A_PATH_NODE_2A],
                    ["garlic.", PICK_A_PATH_NODE_2B],
                    ["chard.", PICK_A_PATH_NODE_2C]
                ]
            },
            [PICK_A_PATH_NODE_2A]: {
                'text': [
                    "As Omar pulled parsnips from the garden soil, he dreamed of putting them in a delicious, hearty stew."
                ],
            },
            [PICK_A_PATH_NODE_2B]: {
                'text': [
                    "Marcus liked digging for garlic. As he pulled the heads from the ground, he dropped them into a large basket."
                ],
            },
            [PICK_A_PATH_NODE_2C]: {
                'text': [
                    "Marcus and Omar cut enough chard to take to the farmers' market."
                ],
            },
            [PICK_A_PATH_NODE_3]: {
                'text': [
                    "Marcus and Omar loved helping Martha in the kitchen. Together, they prepared"
                ],
                'choices': [
                    ["some pickled artichoke hearts.", PICK_A_PATH_NODE_3A],
                    ["parsnip tart.", PICK_A_PATH_NODE_3B],
                    ["rhubarb pie.", PICK_A_PATH_NODE_3C]
                ]
            },
            [PICK_A_PATH_NODE_3A]: {
                'text': [
                    "Marcus helped Martha pickle the artichoke hearts and seal them in jars. They would be sold at the farmers' market."
                ],
            },
            [PICK_A_PATH_NODE_3B]: {
                'text': [
                    "The delicious tart was made with the fresh parsnips that Omar had brought in from the garden."
                ],
            },
            [PICK_A_PATH_NODE_3C]: {
                'text': [
                    "The family would eat the rhubarb pie tonight at Omar's birthday party!"
                ],
            },
            [PICK_A_PATH_NODE_END]: {
                'text': [
                    "Marcus and Omar worked hard, but they loved every part of their marvelous day on the farm."
                ],
            },
        }
    },
    'tearful-cheerful': {  // Vocalic EAR - Proficient Reader
        'name': "Tearful and Cheerful",
        'dropdownName': "Tearful and Cheerful (Vocalic EAR)",
        'targetSounds': [TARGET_SOUND_R],
        'positions': [POSITION_VOCALIC_EAR],
        'readingLevel': PICK_A_PATH_READING_LEVEL_PROFICIENT,
        'nodes': {
            [PICK_A_PATH_NODE_START]: {
                'text': [
                    "Teddy and Chuck have been friends for years. They clearly enjoy being together, but something is weird: Teddy is always crying and Chuck is always smiling. Their friends call them Tearful Teddy and Cheerful Chuck, or Tearful and Cheerful for short.",
                    "One day Tearful and Cheerful decided to meet in the cafeteria before school. When they got there they saw"
                ],
                'choices': [
                    ["their pal Amir.", PICK_A_PATH_NODE_1],
                    ["their buddy Pierce.", PICK_A_PATH_NODE_2],
                    ["their friend Kira.", PICK_A_PATH_NODE_3]
                ]
            },
            [PICK_A_PATH_NODE_1]: {
                'text': [
                    "Amir looked serious.",
                    "\"What's up, Amir?\" Cheerful asked with a smile.",
                    "Amir gave them an earful about some"
                ],
                'choices': [
                    ["cereal.", PICK_A_PATH_NODE_1A],
                    ["syrup.", PICK_A_PATH_NODE_1B],
                    ["pickle spears.", PICK_A_PATH_NODE_1C]
                ]
            },
            [PICK_A_PATH_NODE_1A]: {
                'text': [
                    "The cafeteria was out of Amir's favorite cereal.",
                    "\"Oh, dear!\" cried Tearful. \"Clearly you are disappointed.\"",
                    "Cheerful smiled and said, \"Try some Cheerios instead. They're delicious!\""
                ],
            },
            [PICK_A_PATH_NODE_1B]: {
                'text': [
                    "As Amir was carrying his tray of pancakes, he had veered into a table and spilled maple syrup everywhere.",
                    "Tearful sobbed when he saw the syrup smears on Amir's shirt.",
                    "Cheerful howled with laughter and said, \"Here's a napkin - wipe that syrup out of your ear!\""
                ],
            },
            [PICK_A_PATH_NODE_1C]: {
                'text': [
                    "Amir complained that the cafeteria had accidentally put pickle spears in the fruit salad.",
                    "Tearful burst into tears. \"I guess we can't eat here any more.\"",
                    "Cheerful giggled. \"That's so funny and weird! You'll be telling this story for years!\""
                ],
            },
            [PICK_A_PATH_NODE_2]: {
                'text': [
                    "Pierce seemed weary.",
                    "\"Oh, no,\" moaned Tearful, with tears welling in his eyes. \"What's wrong, Pierce?\"",
                    "Pierce explained that he was worried about his"
                ],
                'choices': [
                    ["earbuds.", PICK_A_PATH_NODE_2A],
                    ["left ear.", PICK_A_PATH_NODE_2B],
                    ["souvenir.", PICK_A_PATH_NODE_2C]
                ]
            },
            [PICK_A_PATH_NODE_2A]: {
                'text': [
                    "Pierce had forgotten his earbuds. \"I need them to listen to the lyrics to this year's school song!\"",
                    "Giant tears rolled down Tearful's face. \"Oh, this is a serious problem.\"",
                    "Cheerful grinned and held out his own earbuds. \"Here, you can borrow mine!\""
                ],
            },
            [PICK_A_PATH_NODE_2B]: {
                'text': [
                    "Pierce had an earache that had kept him awake all night.",
                    "\"Oh dear,\" sniffed Tearful. \"I hope it's not severe.\"",
                    "Cheerful winked and said, \"At least you can still hear!\""
                ],
            },
            [PICK_A_PATH_NODE_2C]: {
                'text': [
                    "Pierce had broken his favorite souvenir, the one he'd gotten last year at Lake Erie.",
                    "\"Oh, that's so sad,\" sobbed Tearful. \"I am sincerely sorry.\"",
                    "Cheerful laughed and said, \"I guess that means you can go back to Lake Erie next year!\""
                ],
            },
            [PICK_A_PATH_NODE_3]: {
                'text': [
                    "Kira appeared irritated.",
                    "Tearful and Cheerful asked Kira why she looked so severe. She told them about"
                ],
                'choices': [
                    ["the cheerleaders.", PICK_A_PATH_NODE_3A],
                    ["a yearbook picture.", PICK_A_PATH_NODE_3B],
                    ["some earrings.", PICK_A_PATH_NODE_3C]
                ]
            },
            [PICK_A_PATH_NODE_3A]: {
                'text': [
                    "The school cheerleaders were building a human pyramid in the middle of the cafeteria. Kira said, \"I fear this is unsafe.\"",
                    "Tearful nodded in fearful agreement and said that danger was near.",
                    "Cheerful smiled and suggested that the cheerleaders wait until the next class period, when the coast would be clear."
                ],
            },
            [PICK_A_PATH_NODE_3B]: {
                'text': [
                    "Kira opened the yearbook and pointed to a picture of the principal. \"Someone drew a beard and pointy ears on Mr. McNeer's picture!\"",
                    "Tearful's eyes filled with tears. \"Oh dear, this is serious.\"",
                    "Cheerful grinned and said, \"I think Mr. McNeer looks cool with a beard!\""
                ],
            },
            [PICK_A_PATH_NODE_3C]: {
                'text': [
                    "Kira had misplaced her favorite earrings. \"My cousin Miriam gave them to me last year,\" she moaned.",
                    "Tearful put his arms around Kira and cried with her.",
                    "\"I bet they'll reappear\" Cheerful said encouragingly, and he began to search the cafeteria."
                ],
            },
            [PICK_A_PATH_NODE_END]: {
                'text': [
                    "Don't you think Tearful and Cheerful make a good team?"
                ],
            },
        }
    },
    'aunt-myrtles-purse': {  // Vocalic ER - Proficient Reader
        'name': "Aunt Myrtle's Purse",
        'dropdownName': "Aunt Myrtle's Purse (Vocalic ER)",
        'targetSounds': [TARGET_SOUND_R],
        'positions': [POSITION_VOCALIC_ER],
        'readingLevel': PICK_A_PATH_READING_LEVEL_PROFICIENT,
        'nodes': {
            [PICK_A_PATH_NODE_START]: {
                'text': [
                    "Aunt Myrtle is my grandmother's sister. She has wild purple curls and a giant purse. My brother, my sister, and I always wondered what was in her purse.",
                    "One day we finally worked up the courage to solve the mystery. We lurked in the shadows while Aunt Myrtle and Grandmother conversed in the foyer. When they weren't looking, we snatched the purse and took off! You won't believe what we discovered!",
                    "The first to look in the purse was"
                ],
                'choices': [
                    ["my sister.", PICK_A_PATH_NODE_1],
                    ["my brother.", PICK_A_PATH_NODE_2],
                    ["me.", PICK_A_PATH_NODE_3]
                ]
            },
            [PICK_A_PATH_NODE_1]: {
                'text': [
                    "My sister was the first to take a peek inside the purse. We heard her squeal with glee!",
                    "She stuck her hand in the purse and pulled out"],
                'choices': [
                    ["a gerbil.", PICK_A_PATH_NODE_1A],
                    ["a turtle.", PICK_A_PATH_NODE_1B]
                ]
            },
            [PICK_A_PATH_NODE_1A]: {
                'text': ["She pulled out a furry little gerbil! It licked her right on the nose."],
            },
            [PICK_A_PATH_NODE_1B]: {
                'text': ["She pulled out a snapping turtle! My sister went to kiss it and it bit her on the nose!"],
            },
            [PICK_A_PATH_NODE_2]: {
                'text': [
                    "My brother took the purse so that he could learn what was inside. He lifted the purse close to his face and began to gag. He turned the purse upside down and emptied it onto the kitchen counter.",
                    "Out fell"
                ],
                'choices': [
                    ["worms.", PICK_A_PATH_NODE_2A],
                    ["butter.", PICK_A_PATH_NODE_2B]
                ]
            },
            [PICK_A_PATH_NODE_2A]: {
                'text': ["Out fell an open can of worms. Yuck! They squirmed and slithered all over the place."],
            },
            [PICK_A_PATH_NODE_2B]: {
                'text': ["Out fell an old stick of butter. It had gotten furry and smelled the worst!"],
            },
            [PICK_A_PATH_NODE_3]: {
                'text': [
                    "I took the purse and turned my back to the others so that I could be the first to find out what was inside.",
                    "I stuck in my hand and pulled out"
                ],
                'choices': [
                    ["a wig.", PICK_A_PATH_NODE_3A],
                    ["a picture.", PICK_A_PATH_NODE_3B]
                ]
            },
            [PICK_A_PATH_NODE_3A]: {
                'text': ["I pulled out a purple wig! I never knew that Aunt Myrtle's curls weren't all hers!"],
            },
            [PICK_A_PATH_NODE_3B]: {
                'text': ["I pulled out a big picture of me. I always knew I was Aunt Myrtle's favorite!"],
            },
        }
    },
    'vampire-and-pirate': {  // Vocalic IRE - Proficient Reader
        'name': "The Vampire and the Pirate",
        'dropdownName': "The Vampire and the Pirate (Vocalic IRE)",
        'targetSounds': [TARGET_SOUND_R],
        'positions': [POSITION_VOCALIC_IRE],
        'readingLevel': PICK_A_PATH_READING_LEVEL_PROFICIENT,
        'nodes': {
            [PICK_A_PATH_NODE_START]: {
                'text': [
                    "A vampire and a pirate were best friends. Even though they admired each other, they were always bragging and trying to outdo one another.",
                    "Early one morning they met at the fire station. They had plans to"
                ],
                'choices': [
                    ["go to choir practice.", PICK_A_PATH_NODE_1],
                    ["hang some flyers.", PICK_A_PATH_NODE_2],
                    ["inquire about jobs.", PICK_A_PATH_NODE_3]
                ]
            },
            [PICK_A_PATH_NODE_1]: {
                'text': [
                    "The pirate and the vampire always walked to choir practice together. As they walked, they"
                ],
                'choices': [
                    ["argued over who could sing higher.", PICK_A_PATH_NODE_1A],
                    ["talked about singing in the choir.", PICK_A_PATH_NODE_1B],
                    ["discussed concert attire.", PICK_A_PATH_NODE_1C]
                ]
            },
            [PICK_A_PATH_NODE_1A]: {
                'text': [
                    "\"I can hit a higher note than you,\" boasted the vampire.",
                    "\"That may be true,\" said the pirate, \"but my voice is as loud as a siren.\""],
            },
            [PICK_A_PATH_NODE_1B]: {
                'text': [
                    "The pirate boasted that she was always asked to sing a solo.",
                    "\"I would too, but I'm just shyer than you,\" said the vampire."
                ],
            },
            [PICK_A_PATH_NODE_1C]: {
                'text': [
                    "The vampire reminded the pirate that he had the nicest attire in the entire choir.",
                    "\"That's only because I iron your cape for you,\" grumbled the pirate."
                ],
            },
            [PICK_A_PATH_NODE_2]: {
                'text': [
                    "The vampire and the pirate had promised to hang flyers around town. The flyers were inviting people to the town bonfire. The vampire bragged that he would"
                ],
                'choices': [
                    ["hang the highest flyer.", PICK_A_PATH_NODE_2A],
                    ["hang the most flyers.", PICK_A_PATH_NODE_2B],
                    ["never get tired.", PICK_A_PATH_NODE_2C]
                ]
            },
            [PICK_A_PATH_NODE_2A]: {
                'text': [
                    "The vampire said he could hang a flyer on a tall flagpole without requiring a ladder.",
                    "The pirate laughed and claimed she could hang a flyer on the town's tallest spire!"
                ],
            },
            [PICK_A_PATH_NODE_2B]: {
                'text': [
                    "The vampire covered two fire trucks with flyers. This inspired the pirate to cover the entire fire station with flyers!"
                ],
            },
            [PICK_A_PATH_NODE_2C]: {
                'text': [
                    "In fact, both the vampire and the pirate were quite tired, but neither would admit that it was time to retire."
                ],
            },
            [PICK_A_PATH_NODE_3]: {
                'text': [
                    "The vampire and the pirate really admired the firefighters in their town. They wanted to be firefighters too.",
                    "The pirate boasted about"
                ],
                'choices': [
                    ["getting hired.", PICK_A_PATH_NODE_3A],
                    ["putting out fires.", PICK_A_PATH_NODE_3B],
                    ["using the siren.", PICK_A_PATH_NODE_3C]
                ]
            },
            [PICK_A_PATH_NODE_3A]: {
                'text': [
                    "\"I bet I'll be the one to get hired,\" said the pirate.",
                    "\"I think you'll be the one to get TIRED,\" joked the vampire."
                ],
            },
            [PICK_A_PATH_NODE_3B]: {
                'text': [
                    "\"I bet I could put out a fire all by myself,\" bragged the pirate.",
                    "\"That's not something I would desire to try,\" thought the vampire."
                ],
            },
            [PICK_A_PATH_NODE_3C]: {
                'text': [
                    "\"I already know how to work the siren,\" boasted the pirate.",
                    "\"Big deal,\" said the vampire. \"I'm strong enough to use the fire hose all by myself!\""
                ],
            },
            [PICK_A_PATH_NODE_END]: {
                'text': [
                    "At the end of the long day, the vampire and the pirate retired to their favorite tire swing and took a nap."
                ],
            },
        }
    },
    'when-bored': {  // Vocalic OR - Proficient Reader
        'name': "Things to Do When You're Bored",
        'dropdownName': "Things to Do When You're Bored (Vocalic OR)",
        'targetSounds': [TARGET_SOUND_R],
        'positions': [POSITION_VOCALIC_OR],
        'readingLevel': PICK_A_PATH_READING_LEVEL_PROFICIENT,
        'nodes': {
            [PICK_A_PATH_NODE_START]: {
                'text': [
                    "Have you ever been bored? There are plenty of ways to keep yourself busy! You can"
                ],
                'choices': [
                    ["stay indoors.", PICK_A_PATH_NODE_1],
                    ["play outdoors.", PICK_A_PATH_NODE_2],
                    ["do some chores.", PICK_A_PATH_NODE_3]
                ]
            },
            [PICK_A_PATH_NODE_1]: {
                'text': [
                    "There are more than enough ways to entertain yourself indoors. You can"
                ],
                'choices': [
                    ["enjoy a story.", PICK_A_PATH_NODE_1A],
                    ["decorate your space.", PICK_A_PATH_NODE_1B],
                    ["count doorknobs.", PICK_A_PATH_NODE_1C]
                ]
            },
            [PICK_A_PATH_NODE_1A]: {
                'text': [
                    "Read or listen to a story or write one for your friends!"
                ],
            },
            [PICK_A_PATH_NODE_1B]: {
                'text': [
                    "You could decorate a corkboard and hang it on your door!"
                ],
            },
            [PICK_A_PATH_NODE_1C]: {
                'text': [
                    "How many doorknobs are in your home? Four? Fourteen? Forty-four?"
                ],
            },
            [PICK_A_PATH_NODE_2]: {
                'text': [
                    "There are options galore for things to do outdoors! You might like to"
                ],
                'choices': [
                    ["practice a sport.", PICK_A_PATH_NODE_2A],
                    ["explore.", PICK_A_PATH_NODE_2B],
                    ["build a fort.", PICK_A_PATH_NODE_2C]
                ]
            },
            [PICK_A_PATH_NODE_2A]: {
                'text': [
                    "Is there a tennis court or a basketball court near your home? You can practice your skills for an hour or more."
                ],
            },
            [PICK_A_PATH_NODE_2B]: {
                'text': [
                    "Take a tour of your neighborhood! There are details galore that you might not notice in your day-to-day life."
                ],
            },
            [PICK_A_PATH_NODE_2C]: {
                'text': [
                    "Build a fort and grab a tasty morsel of your favorite food. Enjoy some peace and quiet with your favorite stories, or let your imagination run wild."
                ],
            },
            [PICK_A_PATH_NODE_3]: {
                'text': [
                    "If you're bored enough, you can always do some chores. Your family will thank you! Maybe you could"
                ],
                'choices': [
                    ["clean the floor.", PICK_A_PATH_NODE_3A],
                    ["make your bed.", PICK_A_PATH_NODE_3B],
                    ["sort your socks.", PICK_A_PATH_NODE_3C]
                ]
            },
            [PICK_A_PATH_NODE_3A]: {
                'text': [
                    "Vacuum your rug or sweep the floor. Don't forget the dust bunnies in the corners!"
                ],
            },
            [PICK_A_PATH_NODE_3B]: {
                'text': [
                    "You will thank yourself at bedtime for getting your bed ready now. Don't forget to fluff your pillow!"
                ],
            },
            [PICK_A_PATH_NODE_3C]: {
                'text': [
                    "Take all of your socks out of their drawer and sort them into pairs. It will make getting dressed in the mornings much easier!"
                ],
            },
            [PICK_A_PATH_NODE_END]: {
                'text': [
                    "Do you have more boredom-busting ideas?"
                ],
            },
        }
    },
    'andrews-treat': {  // R Blends - Read-Together
        'name': "Andrew's Treat",
        'dropdownName': "Andrew's Treat (R Blends)",
        'targetSounds': [TARGET_SOUND_R],
        'positions': [POSITION_BR_BLEND, POSITION_DR_BLEND, POSITION_FR_BLEND, POSITION_GR_BLEND, POSITION_KR_BLEND, POSITION_PR_BLEND, POSITION_TR_BLEND],
        'readingLevel': PICK_A_PATH_READING_LEVEL_EASY,
        'vocab': [
            ['Andrew'],
            ['Andrew', "Andrew's"],
            ['bravo'],
            ['bring'],
            ['crab'],
            ['crackers'],
            ['cream_cheese', 'cream cheese'],
            ['cream_soda', 'cream soda'],
            ['crunchy'],
            ['drool'],
            ['Frank'],
            ['fresh'],
            ['Friday'],
            ['friend'],
            ['friend', 'friends'],
            ['frisbee'],
            ['frosting'],
            ['fruit'],
            ['grape_juice', 'grape juice'],
            ['grapes'],
            ['great'],
            ['green'],
            ['grin', 'grinned'],
            ['present'],
            ['pretzel'],
            ['pretzels'],
            ['prize'],
            ['strawberries'],
            ['three'],
            ['tray'],
            ['treat'],
            ['treat', 'treats'],
            ['whipped_cream', 'whipped cream'],
        ],
        'nodes': {
            [PICK_A_PATH_NODE_START]: {
                'text': [
                    "<Andrew> was excited. <Friday> was his day to <bring> a <treat> to school. He tried to think of something <great|great,> and he finally chose"
                ],
                'choices': [
                    ["<fruit|fruit.>", PICK_A_PATH_NODE_1],
                    ["<crunchy> snacks.", PICK_A_PATH_NODE_2],
                ]
            },
            [PICK_A_PATH_NODE_1]: {
                'text': [
                    "<Andrew> thought some <fresh> <fruit> would be a <great> <treat> for his <friend|friends.> He would take some"
                ],
                'choices': [
                    ["<strawberries|strawberries.>", PICK_A_PATH_NODE_1A],
                    ["<grapes|grapes.>", PICK_A_PATH_NODE_1B],
                ]
            },
            [PICK_A_PATH_NODE_1A]: {
                'text': [
                    "<Andrew> would try to find fresh <strawberries|strawberries.> His <friend|friends> could dip them in"
                ],
                'choices': [
                    ["<frosting|frosting.>", PICK_A_PATH_NODE_1A1],
                    ["<whipped_cream|whipped cream.>", PICK_A_PATH_NODE_1A2],
                ]
            },
            [PICK_A_PATH_NODE_1A1]: {
                'text': [
                    "<Andrew> began to <drool> just thinking about frosted <strawberries|strawberries!>"
                ],
            },
            [PICK_A_PATH_NODE_1A2]: {
                'text': [
                    "<fresh|Fresh> <strawberries> with <whipped_cream|whipped cream> might win a <prize> for the best <treat> ever!"
                ],
            },
            [PICK_A_PATH_NODE_1B]: {
                'text': [
                    "<Andrew> wanted to buy lots of <green> <grapes> and serve them on a"
                ],
                'choices': [
                    ["<tray|tray.>", PICK_A_PATH_NODE_1B1],
                    ["<frisbee|frisbee.>", PICK_A_PATH_NODE_1B2],
                ]
            },
            [PICK_A_PATH_NODE_1B1]: {
                'text': [
                    "<Andrew> had a pretty <tray> that was a <present> from his <friend> Bruno. Bruno would be so proud if <Andrew> used it on <treat> day!"
                ],
            },
            [PICK_A_PATH_NODE_1B2]: {
                'text': [
                    "<Andrew> thought it would be pretty funny to bring the <grapes> on a <frisbee|frisbee.> What would his <friend|friends> say?"
                ],
            },
            [PICK_A_PATH_NODE_2]: {
                'text': [
                    "<Andrew> and his <friend|friends> loved to crunch on salty snacks, so he would probably choose"
                ],
                'choices': [
                    ["<crackers|crackers.>", PICK_A_PATH_NODE_2A],
                    ["<pretzels|pretzels.>", PICK_A_PATH_NODE_2B],
                ]
            },
            [PICK_A_PATH_NODE_2A]: {
                'text': [
                    "<Andrew> would buy <three> kinds of <crackers> and spread them with"
                ],
                'choices': [
                    ["<cream_cheese|cream cheese.>", PICK_A_PATH_NODE_2A1],
                    ["<crab> dip.", PICK_A_PATH_NODE_2A2],
                ]
            },
            [PICK_A_PATH_NODE_2A1]: {
                'text': [
                    "<cream_cheese|Cream cheese> on <crackers> would be a <great> <treat> for all of the <friend|friends> in <Andrew|Andrew's> class!"
                ],
            },
            [PICK_A_PATH_NODE_2A2]: {
                'text': [
                    "<Andrew> <grin|grinned> just thinking about his <friend> <Frank|Frank.> <Frank> loved <crab> dip!"
                ],
            },
            [PICK_A_PATH_NODE_2B]: {
                'text': [
                    "<Andrew> thought he would <bring> <pretzel> braids and serve them with"
                ],
                'choices': [
                    ["<grape_juice|grape juice.>", PICK_A_PATH_NODE_2B1],
                    ["<cream_soda|cream soda.>", PICK_A_PATH_NODE_2B2],
                ]
            },
            [PICK_A_PATH_NODE_2B1]: {
                'text': [
                    "<Andrew|Andrew's> <friend> Gray drank <grape_juice|grape juice> every day. <Andrew> knew that this <treat> would make Gray say <bravo|\"Bravo!\">"
                ],
            },
            [PICK_A_PATH_NODE_2B2]: {
                'text': [
                    "A <pretzel> and <cream_soda|cream soda> break would earn extra praise from <Andrew|Andrew's> <friend|friends!>"
                ],
            },
            [PICK_A_PATH_NODE_END]: {
                'text': [
                    "On <Friday|Friday,> Andrew took his <treat|treats> to school. Do you think his <friend|friends> were impressed?"
                ],
            }
        }
    },
    'gracie-loves-gravy': {  // R Blends - Proficient Reader
        'name': "Gracie Loves Gravy",
        'dropdownName': "Gracie Loves Gravy (R Blends)",
        'targetSounds': [TARGET_SOUND_R],
        'positions': [POSITION_BR_BLEND, POSITION_DR_BLEND, POSITION_FR_BLEND, POSITION_GR_BLEND, POSITION_KR_BLEND, POSITION_PR_BLEND, POSITION_TR_BLEND],
        'readingLevel': PICK_A_PATH_READING_LEVEL_PROFICIENT,
        'nodes': {
            [PICK_A_PATH_NODE_START]: {
                'text': [
                    "My friend Gracie LOVES gravy! She'll try any food as long as it's drenched in gravy, and she turns her nose up at anything that isn't.",
                    "Gracie supplies her own gravy at every meal. She brings it to"
                ],
                'choices': [
                    ["breakfast.", PICK_A_PATH_NODE_1],
                    ["lunch.", PICK_A_PATH_NODE_2],
                    ["dinner.", PICK_A_PATH_NODE_3]
                ]
            },
            [PICK_A_PATH_NODE_1]: {
                'text': [
                    "Gracie says gravy is a brilliant addition to any breakfast food. She eats it on"
                ],
                'choices': [
                    ["scrambled eggs.", PICK_A_PATH_NODE_1A],
                    ["French toast.", PICK_A_PATH_NODE_1B],
                    ["fresh fruit.", PICK_A_PATH_NODE_1C]
                ]
            },
            [PICK_A_PATH_NODE_1A]: {
                'text': [
                    "Gracie loves her scrambled eggs dripping with gravy. She thinks gravy makes fried eggs taste great too."
                ],
            },
            [PICK_A_PATH_NODE_1B]: {
                'text': [
                    "Forget the syrup! Gracie proudly drizzles gravy across her French toast and drinks the extra with a straw."
                ],
            },
            [PICK_A_PATH_NODE_1C]: {
                'text': [
                    "Grapes, strawberries, dragonfruit - without gravy, Gracie thinks even the freshest fruit tastes drab."
                ],
            },
            [PICK_A_PATH_NODE_2]: {
                'text': [
                    "Gracie always brings a jar of gravy to lunch because she loves it with her"
                ],
                'choices': [
                    ["grilled cheese sandwich.", PICK_A_PATH_NODE_2A],
                    ["pretzels and crackers.", PICK_A_PATH_NODE_2B],
                    ["brownie.", PICK_A_PATH_NODE_2C]
                ]
            },
            [PICK_A_PATH_NODE_2A]: {
                'text': [
                    "Gracie uses gravy as a sandwich spread. And no need to throw the crusts away - Gracie dips them in the extra drippings on her tray."
                ],
            },
            [PICK_A_PATH_NODE_2B]: {
                'text': [
                    "When it comes to crunchy snacks like pretzels and crackers, Gracie crushes them, drenches them in gravy, and eats them like breakfast cereal."
                ],
            },
            [PICK_A_PATH_NODE_2C]: {
                'text': [
                    "Don't forget the sweet treats! Gracie dunks brownies and cookies in gravy without regret."
                ],
            },
            [PICK_A_PATH_NODE_3]: {
                'text': [
                    "A tasty dinner only earns Gracie's approval when it's presented with a side of gravy. She craves it on"
                ],
                'choices': [
                    ["french fries.", PICK_A_PATH_NODE_3A],
                    ["frankfurters.", PICK_A_PATH_NODE_3B],
                    ["broccoli salad.", PICK_A_PATH_NODE_3C]
                ]
            },
            [PICK_A_PATH_NODE_3A]: {
                'text': [
                    "Whether they are greasy or dry, when she has a tray of french fries, Gracie always trades ketchup for gravy."
                ],
            },
            [PICK_A_PATH_NODE_3B]: {
                'text': [
                    "Gracie wouldn't pretend to eat a plain frankfurter, but a hot dog dripping with gravy makes her drool."
                ],
            },
            [PICK_A_PATH_NODE_3C]: {
                'text': [
                    "While she doesn't prefer veggies, Gracie eats broccoli salad without protest if its dressing is fresh brown gravy."
                ],
            },
            [PICK_A_PATH_NODE_END]: {
                'text': [
                    "Does all of this gravy sound kind of gross? Gracie says \"Don't knock it 'til you try it!\""
                ],
            },
        }
    },

    // Target sound S
    'sunny-summer-day': {  // Initial S - Read-Together
        'name': "A Sunny Summer Day",
        'dropdownName': "A Sunny Summer Day (Initial S)",
        'targetSounds': [TARGET_SOUND_S],
        'positions': [POSITION_INITIAL],
        'readingLevel': PICK_A_PATH_READING_LEVEL_EASY,
        'vocab': [
            ['city'],
            ['sad'],
            ['salty'],
            ['Sam'],
            ['sand'],
            ['sandal'],
            ['sing', 'sang'],
            ['Sarah'],
            ['Saturday'],
            ['sea_turtle', 'sea turtle'],
            ['seashell'],
            ['see'],
            ['seven'],
            ['sidewalk'],
            ['sigh'],
            ['sign'],
            ['silly'],
            ['sing'],
            ['sing', 'singing'],
            ['sister'],
            ['six'],
            ['so'],
            ['soap'],
            ['soccer'],
            ['sock'],
            ['soda'],
            ['somewhere'],
            ['soup'],
            ['summer'],
            ['Sunday'],
            ['super'],
        ],
        'nodes': {
            [PICK_A_PATH_NODE_START]: {
                'text': [
                    "One <summer> day <Sam> told his <sister> <Sarah|Sarah,> \"Today is going to be <super|super!> Let's go <somewhere> we can"
                ],
                'choices': [
                    ["<sing|sing.\">", PICK_A_PATH_NODE_1],
                    ["play <soccer|soccer.\">", PICK_A_PATH_NODE_2],
                ]
            },
            [PICK_A_PATH_NODE_1]: {
                'text': [
                    "<Sarah> and <Sam> went into the <city> and <sing|sang>"
                ],
                'choices': [
                    ["<six> songs.", PICK_A_PATH_NODE_1A],
                    ["<seven> songs.", PICK_A_PATH_NODE_1B],
                ]
            },
            [PICK_A_PATH_NODE_1A]: {
                'text': [
                    "<Sam> and <Sarah> had <so> much fun <sing|singing> <six> songs. After they <sing|sang> they had a sip of"
                ],
                'choices': [
                    ["<soup|soup.>", PICK_A_PATH_NODE_1A1],
                    ["<soda|soda.>", PICK_A_PATH_NODE_1A2],
                ]
            },
            [PICK_A_PATH_NODE_1A1]: {
                'text': [
                    "The <soup> was <so> <salty|salty!> <Sarah> looked at <Sam> and said \"yum!\""
                ],
            },
            [PICK_A_PATH_NODE_1A2]: {
                'text': [
                    "The sip of <soda> was <so> cold and tasty! <Sam> gave a <sigh> of joy."
                ],
            },
            [PICK_A_PATH_NODE_1B]: {
                'text': [
                    "<Sarah> and <Sam> <sing|sang> <seven> songs. They said, \"We'll be back to <sing> again on"
                ],
                'choices': [
                    ["<Saturday|Saturday.\">", PICK_A_PATH_NODE_1B1],
                    ["<Sunday|Sunday.\">", PICK_A_PATH_NODE_1B2],
                ]
            },
            [PICK_A_PATH_NODE_1B1]: {
                'text': [
                    "<Sam> and <Sarah> went back on <Saturday> and <sing|sang> the same <seven> songs."
                ],
            },
            [PICK_A_PATH_NODE_1B2]: {
                'text': [
                    "<Sarah> and <Sam> went back on <Sunday|Sunday.> Some of their songs were the same, and some were new."
                ],
            },
            [PICK_A_PATH_NODE_2]: {
                'text': [
                    "<Sam> and <Sarah> played <soccer> on the"
                ],
                'choices': [
                    ["<sidewalk|sidewalk.>", PICK_A_PATH_NODE_2A],
                    ["<sand|sand.>", PICK_A_PATH_NODE_2B],
                ]
            },
            [PICK_A_PATH_NODE_2A]: {
                'text': [
                    "A <sidewalk> is a <silly> place to play <soccer|soccer!> <Sam> even lost a"
                ],
                'choices': [
                    ["<sock|sock.>", PICK_A_PATH_NODE_2A1],
                    ["<sandal|sandal.>", PICK_A_PATH_NODE_2A2],
                ]
            },
            [PICK_A_PATH_NODE_2A1]: {
                'text': [
                    "<Sam> searched but didn't <see> his <sock> near the <sidewalk|sidewalk.> He was <sad|sad.>"
                ],
            },
            [PICK_A_PATH_NODE_2A2]: {
                'text': [
                    "<Sarah> kicked the ball <so> hard that her <sandal> flew into a <sign|sign!>"
                ],
            },
            [PICK_A_PATH_NODE_2B]: {
                'text': [
                    "<Sarah> and <Sam> played <soccer> on the <sand|sand.> <Sam> looked up and said, \"Hey, is that a"
                ],
                'choices': [
                    ["<seashell|seashell?>", PICK_A_PATH_NODE_2B1],
                    ["<sea_turtle|sea turtle?>", PICK_A_PATH_NODE_2B2],
                ]
            },
            [PICK_A_PATH_NODE_2B1]: {
                'text': [
                    "The <seashell> was <so> sandy that <Sarah> and <Sam> washed it with some <soap|soap.>"
                ],
            },
            [PICK_A_PATH_NODE_2B2]: {
                'text': [
                    "<Sarah> and <Sam> were surprised to <see> a <sea_turtle|sea turtle!> They <sing|sang> a song about it."
                ],
            },
            [PICK_A_PATH_NODE_END]: {
                'text': [
                    "<Sam> and <Sarah> had such a good day!"
                ],
            }
        }
    },
    'seashore-sally': {  // Initial S - Proficient Reader
        'name': "Sally at the Seashore",
        'dropdownName': "Sally at the Seashore (Initial S)",
        'targetSounds': [TARGET_SOUND_S],
        'positions': [POSITION_INITIAL],
        'readingLevel': PICK_A_PATH_READING_LEVEL_PROFICIENT,
        'nodes': {
            [PICK_A_PATH_NODE_START]: {
                'text': [
                    "Sally awoke one sunny summer day and set her sights on the seashore. She thought some sunshine and sandy air would be sensational. Sally packed some food, put on her bathing suit, and grabbed her sunscreen.",
                    "From the moment she arrived, Sally sensed it would be a super day. There was so much to see and do.",
                    "When Sally arrived, she immediately seized her"
                ],
                'choices': [
                    ["sun umbrella.", PICK_A_PATH_NODE_1],
                    ["beach satchel.", PICK_A_PATH_NODE_2],
                    ["surfboard.", PICK_A_PATH_NODE_3]
                ]
            },
            [PICK_A_PATH_NODE_1]: {
                'text': [
                    "Sally didn't want to get a sunburn! She set up her sun umbrella, then"
                ],
                'choices': [
                    ["settled in to build a sandcastle.", PICK_A_PATH_NODE_1A],
                    ["supposed she would take a siesta.", PICK_A_PATH_NODE_1B],
                    ["sat down on her soft towel.", PICK_A_PATH_NODE_1C]
                ]
            },
            [PICK_A_PATH_NODE_1A]: {
                'text': [
                    "The sizeable sandcastle had six sides and a sidewalk made of seashells."
                ],
            },
            [PICK_A_PATH_NODE_1B]: {
                'text': [
                    "When Sally awoke, a seagull was sipping her citrus soda!"
                ],
            },
            [PICK_A_PATH_NODE_1C]: {
                'text': [
                    "She was surprised to see a silly boy covering his sister with sand!"
                ],
            },
            [PICK_A_PATH_NODE_2]: {
                'text': [
                    "Sally snatched her beach satchel and began searching for seashells. She found"
                ],
                'choices': [
                    ["seven sand dollars.", PICK_A_PATH_NODE_2A],
                    ["several superb seashells.", PICK_A_PATH_NODE_2B],
                    ["so much seaweed.", PICK_A_PATH_NODE_2C]
                ]
            },
            [PICK_A_PATH_NODE_2A]: {
                'text': [
                    "Sally was surprised to find so many sand dollars! She wrapped them in something soft and put them in her satchel for safekeeping."
                ],
            },
            [PICK_A_PATH_NODE_2B]: {
                'text': [
                    "Sally found a satisfying selection of shells. A sandcrab surveyed the scene as she stashed the shells securely in her satchel."
                ],
            },
            [PICK_A_PATH_NODE_2C]: {
                'text': [
                    "Sally saw a sufficient sample of seaweed, but had no success finding shells. She had to settle for a sunburn, sandy feet, and an empty satchel."
                ],
            },
            [PICK_A_PATH_NODE_3]: {
                'text': [
                    "Sally snagged her surfboard and ran into the sea, where she"
                ],
                'choices': [
                    ["surveyed the waves.", PICK_A_PATH_NODE_3A],
                    ["stayed seated.", PICK_A_PATH_NODE_3B],
                    ["saw her friend Sofia.", PICK_A_PATH_NODE_3C]
                ]
            },
            [PICK_A_PATH_NODE_3A]: {
                'text': [
                    "Sally sized up a sixteen-foot wave and decided it would be sufficient. She succeeded in standing up, but was submerged as the wave surged past her."
                ],
            },
            [PICK_A_PATH_NODE_3B]: {
                'text': [
                    "Sally felt safest sitting on her surfboard and soaking up some sun. She was a little sad not to surf, but she got to say hello to a sea turtle and some seals."
                ],
            },
            [PICK_A_PATH_NODE_3C]: {
                'text': [
                    "Sally saw Sofia on a sailboat and set off to say hello. Sofia and her sisters offered Sally an ice cream sundae. So sublime!"
                ],
            },
            [PICK_A_PATH_NODE_END]: {
                'text': [
                    "Sally was so sorry to leave at sunset that she decided to return the next Saturday."
                ],
            },
        }
    },
    'inside-outside': {  // Medial S - Read-Together
        'name': "Inside or Outside?",
        'dropdownName': "Inside or Outside? (Medial S)",
        'targetSounds': [TARGET_SOUND_S],
        'positions': [POSITION_MEDIAL],
        'readingLevel': PICK_A_PATH_READING_LEVEL_EASY,
        'vocab': [
            ['awesome'],
            ['baseball'],
            ['base', 'bases'],
            ['bracelet'],
            ['Casey'],
            ['Casey', "Casey's"],
            ['castle'],
            ['chasing'],
            ['dance', 'danced'],
            ['dance', 'dancing'],
            ['dinosaur'],
            ['dress_up', 'dress up'],
            ['fancy'],
            ['ice_cream', 'ice cream'],
            ['Icee'],
            ['Icees'],
            ['inside'],
            ['Jason'],
            ['listen'],
            ['listen', 'listened'],
            ['Lucy'],
            ['Lucy', "Lucy's"],
            ['messy'],
            ['missing'],
            ['monster'],
            ['muscle', 'muscles'],
            ['outside'],
            ['popsicle'],
            ['popsicles'],
            ['possum'],
            ['princess'],
            ['race_car', 'race car'],
            ['relaxing'],
            ['rhinoceros'],
            ['sister'],
            ['themselves'],
            ['whistled'],
        ],
        'nodes': {
            [PICK_A_PATH_NODE_START]: {
                'text': [
                    "<Lucy> and <Casey> were excited to spend the day together, but they didn't know what to do with <themselves|themselves.> One of their first choices had to be where they should play. They decided to go"
                ],
                'choices': [
                    ["<inside|inside.>", PICK_A_PATH_NODE_1],
                    ["<outside|outside.>", PICK_A_PATH_NODE_2],
                ]
            },
            [PICK_A_PATH_NODE_1]: {
                'text': [
                    "<Lucy> and <Casey> felt like <relaxing> <inside|inside.> They thought it would fun to"
                ],
                'choices': [
                    ["<listen> to a story.", PICK_A_PATH_NODE_1A],
                    ["play <dress_up|dress up.>", PICK_A_PATH_NODE_1B],
                ]
            },
            [PICK_A_PATH_NODE_1A]: {
                'text': [
                    "<Lucy> and <Casey> <listen|listened> to a story about a"
                ],
                'choices': [
                    ["<princess|princess.>", PICK_A_PATH_NODE_1A1],
                    ["<rhinoceros|rhinoceros.>", PICK_A_PATH_NODE_1A2],
                ]
            },
            [PICK_A_PATH_NODE_1A1]: {
                'text': [
                    "<Casey|Casey's> <sister> read them a story about a <princess> and her <missing> <bracelet|bracelet.>"
                ],
            },
            [PICK_A_PATH_NODE_1A2]: {
                'text': [
                    "<Lucy|Lucy's> <sister> told them a funny story about a <possum> that lived in a <castle|castle.>"
                ],
            },
            [PICK_A_PATH_NODE_1B]: {
                'text': [
                    "<Lucy> wanted to <dress_up|dress up> as a"
                ],
                'choices': [
                    ["<dinosaur|dinosaur.>", PICK_A_PATH_NODE_1B1],
                    ["<princess|princess.>", PICK_A_PATH_NODE_1B2],
                ]
            },
            [PICK_A_PATH_NODE_1B1]: {
                'text': [
                    "<Lucy> put on her <dinosaur> costume and <Casey> dressed up as a <race_car|race car> driver. They looked <awesome|awesome!>"
                ],
            },
            [PICK_A_PATH_NODE_1B2]: {
                'text': [
                    "<Lucy> wore her <princess> costume, and <Casey> dressed up as a <monster|monster.> <Casey> kept <chasing> <Lucy> around the room!"
                ],
            },
            [PICK_A_PATH_NODE_2]: {
                'text': [
                    "<Lucy> and <Casey> thought it was an <awesome> day to be <outside|outside.> They headed to the"
                ],
                'choices': [
                    ["<baseball> field.", PICK_A_PATH_NODE_2A],
                    ["<ice_cream|ice cream> stand.", PICK_A_PATH_NODE_2B],
                ]
            },
            [PICK_A_PATH_NODE_2A]: {
                'text': [
                    "<Casey> and <Lucy> got to the <baseball> field just in time to see their friend <Jason> hit a home run! They"
                ],
                'choices': [
                    ["<whistled|whistled.>", PICK_A_PATH_NODE_2A1],
                    ["<dance|danced.>", PICK_A_PATH_NODE_2A2],
                ]
            },
            [PICK_A_PATH_NODE_2A1]: {
                'text': [
                    "<Lucy> <whistled> loudly enough for <Jason> to hear her as he ran the <base|bases.>"
                ],
            },
            [PICK_A_PATH_NODE_2A2]: {
                'text': [
                    "<Casey> started <dance|dancing> a <fancy> jig while <Lucy> flexed her <muscle|muscles.>"
                ],
            },
            [PICK_A_PATH_NODE_2B]: {
                'text': [
                    "<Lucy> decided to have an <ice_cream|ice cream> cone, while <Casey> ordered two"
                ],
                'choices': [
                    ["<Icees|Icees.>", PICK_A_PATH_NODE_2B1],
                    ["<popsicles|popsicles.>", PICK_A_PATH_NODE_2B2],
                ]
            },
            [PICK_A_PATH_NODE_2B1]: {
                'text': [
                    "<Casey> ate one <Icee> and gave the other to <Lucy|Lucy's> <sister|sister.>"
                ],
            },
            [PICK_A_PATH_NODE_2B2]: {
                'text': [
                    "<Casey> ate one <popsicle|popsicle,> but the other one melted - how <messy|messy!>"
                ],
            },
        }
    },
    'messy-jesse': {  // Medial S - Proficient Reader
        'name': "Messy Jesse",
        'dropdownName': "Messy Jesse (Medial S)",
        'targetSounds': [TARGET_SOUND_S],
        'positions': [POSITION_MEDIAL],
        'readingLevel': PICK_A_PATH_READING_LEVEL_PROFICIENT,
        'nodes': {
            [PICK_A_PATH_NODE_START]: {
                'text': [
                    "Jesse was a very messy kid. His mom was constantly fussing at him about it. \"We all need to be responsible for our personal spaces,\" she would say.",
                    "One day Jesse finally received the message and decided to spruce up his room. First, he",
                ],
                'choices': [
                    ["considered the area under his bed.", PICK_A_PATH_NODE_1],
                    ["inspected his closet.", PICK_A_PATH_NODE_2],
                    ["concentrated on his desk.", PICK_A_PATH_NODE_3]
                ]
            },
            [PICK_A_PATH_NODE_1]: {
                'text': ["Jesse decided to start under his bed. He took a deep breath, stuck out his hand, and"],
                'choices': [
                    ["dragged out his old bassoon.", PICK_A_PATH_NODE_1A],
                    ["pulled out a plate of brussels sprouts.", PICK_A_PATH_NODE_1B],
                    ["yanked out a boa constrictor.", PICK_A_PATH_NODE_1C]
                ]
            },
            [PICK_A_PATH_NODE_1A]: {
                'text': ["\"Awesome!\" thought Jesse to himself. \"Now I can restart my bassoon lessons!\""],
            },
            [PICK_A_PATH_NODE_1B]: {
                'text': ["The brussels sprouts were rotten. Jesse gagged at the rancid odor and tossed them over the windowsill."],
            },
            [PICK_A_PATH_NODE_1C]: {
                'text': ["\"Melissa!\" Jesse exclaimed, kissing the hissing snake. \"Oh, how I've been missing you!\""],
            },
            [PICK_A_PATH_NODE_2]: {
                'text': ["Jesse decided to begin with the closet. There was a massive pile of clothing on the floor. He"],
                'choices': [
                    ["extracted his baseball uniform.", PICK_A_PATH_NODE_2A],
                    ["discovered a fancy dress.", PICK_A_PATH_NODE_2B],
                    ["pursued a nasty odor.", PICK_A_PATH_NODE_2C]
                ]
            },
            [PICK_A_PATH_NODE_2A]: {
                'text': ["Jesse was ecstatic to find his missing uniform! He would finally be able to participate in baseball games again."],
            },
            [PICK_A_PATH_NODE_2B]: {
                'text': ["It was his sister Lucy's nicest dress. Lucy had been so upset when it disappeared - how it got into Jesse's closet was a mystery."],
            },
            [PICK_A_PATH_NODE_2C]: {
                'text': ["The odor was coming from a moldy bathing suit. Disgusting! Jesse immersed the bathing suit in a basin with some disinfectant."],
            },
            [PICK_A_PATH_NODE_3]: {
                'text': ["Jesse's desk was in absolute disarray. He began to sort through the disorder and"],
                'choices': [
                    ["discovered a book.", PICK_A_PATH_NODE_3A],
                    ["uncovered his missing glasses.", PICK_A_PATH_NODE_3B],
                    ["gathered twenty-six pencils.", PICK_A_PATH_NODE_3C]
                ]
            },
            [PICK_A_PATH_NODE_3A]: {
                'text': ["Jesse had misplaced his book about insects months ago! He sat down and started reading about grasshoppers."],
            },
            [PICK_A_PATH_NODE_3B]: {
                'text': ["What a relief! Jesse's eyesight was poor and he needed them to ride his bicycle responsibly."],
            },
            [PICK_A_PATH_NODE_3C]: {
                'text': ["Jesse decided to put them in a mason jar and bestow them on his teacher, Ms. Wilson, at the end of the semester."],
            },
            [PICK_A_PATH_NODE_END]: {
                'text': ["When he was finished, Jesse considered his room with satisfaction. He would never let it get that messy again!"],
            },
        }
    },
    'mouse-mess': {  // Final S - Read-Together
        'name': "Mouse Makes a Mess",
        'dropdownName': "Mouse Makes a Mess (Final S)",
        'targetSounds': [TARGET_SOUND_S],
        'positions': [POSITION_FINAL],
        'readingLevel': PICK_A_PATH_READING_LEVEL_EASY,
        'vocab': [
            ['across'],
            ['birdhouse'],
            ['bookcase'],
            ['books'],
            ['cactus'],
            ['chase'],
            ['chess'],
            ['close'],
            ['dress'],
            ['face'],
            ['fleece'],
            ['glass'],
            ['hats'],
            ['house'],
            ['hurt', 'hurts'],
            ['ice'],
            ['kiss'],
            ['lips'],
            ['loose'],
            ['magnifying_glass', 'magnifying glass'],
            ['mess'],
            ['miss'],
            ['mouse'],
            ['nice'],
            ['office'],
            ['piece'],
            ['place'],
            ['plants'],
            ['suitcase'],
            ['this'],
            ['tulips'],
            ['twice'],
            ['vase'],
        ],
        'nodes': {
            [PICK_A_PATH_NODE_START]: {
                'text': [
                    "There once was a little <mouse> who lived in a tiny <house|house.> This <mouse> liked to keep her <house> <nice> and neat.",
                    "On the day of <this> story, the <mouse> was very busy in her <house|house.> She began to"
                ],
                'choices': [
                    ["sweep her <office|office.>", PICK_A_PATH_NODE_1],
                    ["water her <plants|plants.>", PICK_A_PATH_NODE_2],
                ]
            },
            [PICK_A_PATH_NODE_1]: {
                'text': [
                    "The <mouse> was sweeping her <office> when she tripped and bumped into a"
                ],
                'choices': [
                    ["<vase|vase.>", PICK_A_PATH_NODE_1A],
                    ["<bookcase|bookcase.>", PICK_A_PATH_NODE_1B],
                ]
            },
            [PICK_A_PATH_NODE_1A]: {
                'text': [
                    "The <mouse> knocked over a <vase> of <tulips|tulips.> Water spread across the floor. \"Oh, rats!\" she cried, and reached for a"
                ],
                'choices': [
                    ["<glass|glass.>", PICK_A_PATH_NODE_1A1],
                    ["<dress|dress.>", PICK_A_PATH_NODE_1A2],
                ]
            },
            [PICK_A_PATH_NODE_1A1]: {
                'text': [
                    "The <mouse> grabbed a <glass> and began to <chase> the water <across> the floor. She only caught a few drops, while the rest made a puddle under the <bookcase|bookcase.>"
                ],
            },
            [PICK_A_PATH_NODE_1A2]: {
                'text': [
                    "A pretty lace <dress> was hanging nearby. The <mouse> grabbed it and began to wipe up the spill. \"I guess I'll have to clean this <dress> <twice|twice,\"> she said."
                ],
            },
            [PICK_A_PATH_NODE_1B]: {
                'text': [
                    "When the <mouse> bumped into the <bookcase|bookcase,> <books> flew <across> the room! The <mouse> collected the <loose> <books> in a"
                ],
                'choices': [
                    ["<suitcase|suitcase.>", PICK_A_PATH_NODE_1B1],
                    ["pair of <hats|hats.>", PICK_A_PATH_NODE_1B2],
                ]
            },
            [PICK_A_PATH_NODE_1B1]: {
                'text': [
                    "The <mouse> filled the <suitcase> with <books|books,> then took them back over to the <bookcase|bookcase.> She had fun putting the <books> back in <place|place.>"
                ],
            },
            [PICK_A_PATH_NODE_1B2]: {
                'text': [
                    "The <mouse> took a pair of floppy <hats> and used them to carry the <books> back to the <bookcase|bookcase.> \"It could have been worse,\" she told herself."
                ],
            },
            [PICK_A_PATH_NODE_2]: {
                'text': [
                    "The <mouse> watered her <plants> once a week. She tried to reach a <cactus|cactus,> but she slipped and fell on a"
                ],
                'choices': [
                    ["<birdhouse|birdhouse.>", PICK_A_PATH_NODE_2A],
                    ["<chess> <piece|piece.>", PICK_A_PATH_NODE_2B],
                ]
            },
            [PICK_A_PATH_NODE_2A]: {
                'text': [
                    "Ouch! The <mouse> landed right on a <birdhouse|birdhouse.> \"That <hurt|hurts,\"> she said, and ran to get a <piece> of"
                ],
                'choices': [
                    ["<ice|ice.>", PICK_A_PATH_NODE_2A1],
                    ["<fleece|fleece.>", PICK_A_PATH_NODE_2A2],
                ]
            },
            [PICK_A_PATH_NODE_2A1]: {
                'text': [
                    "The <mouse> held the <piece> of <ice> to a <place> on her head. \"That feels <nice|nice,\"> she sighed."
                ],
            },
            [PICK_A_PATH_NODE_2A2]: {
                'text': [
                    "The <mouse> took the <piece> of <fleece> and held it up to her <face|face.> The <fleece> felt <nice> and soft."
                ],
            },
            [PICK_A_PATH_NODE_2B]: {
                'text': [
                    "The <mouse> landed on a <chess> <piece|piece.> She took the <piece> and held it up to her"
                ],
                'choices': [
                    ["<lips|lips.>", PICK_A_PATH_NODE_2B1],
                    ["<face|face.>", PICK_A_PATH_NODE_2B2],
                ]
            },
            [PICK_A_PATH_NODE_2B1]: {
                'text': [
                    "\"How <nice|nice!\"> she said, as she gave the <chess> <piece> a <kiss|kiss.> \"I had started to <miss> this one!\""
                ],
            },
            [PICK_A_PATH_NODE_2B2]: {
                'text': [
                    "The <mouse> put the <chess> <piece> near her <face> because she wanted to see it up <close|close.> \"I think I need my <magnifying_glass|magnifying glass,\"> she said."
                ],
            },
            [PICK_A_PATH_NODE_END]: {
                'text': [
                    "The <mouse> made quite a <mess> that day, but she had a very <nice> time cleaning up after herself!"
                ],
            }
        }
    },
    'whats-in-box': {  // Final S - Proficient Reader
        'name': "What's in That Box?",
        'dropdownName': "What's in That Box? (Final S)",
        'targetSounds': [TARGET_SOUND_S],
        'positions': [POSITION_FINAL],
        'readingLevel': PICK_A_PATH_READING_LEVEL_PROFICIENT,
        'nodes': {
            [PICK_A_PATH_NODE_START]: {
                'text': [
                    "Once during recess, Max, Bess, and Gus found a strange box in the place where they often liked to race. They were curious!"
                ],
                'choices': [
                    ["\"What's in that box?\" asked Max.", PICK_A_PATH_NODE_1],
                    ["\"I'll take a glance!\" offered Bess.", PICK_A_PATH_NODE_2],
                    ["\"Give me a chance!\" hollered Gus.", PICK_A_PATH_NODE_3]
                ]
            },
            [PICK_A_PATH_NODE_1]: {
                'text': [
                    "Max opened the lid and stuck his face into the box. It contained"
                ],
                'choices': [
                    ["six curious cats.", PICK_A_PATH_NODE_1A],
                    ["a mess of furious ants.", PICK_A_PATH_NODE_1B],
                    ["twenty-six restless mice.", PICK_A_PATH_NODE_1C]
                ]
            },
            [PICK_A_PATH_NODE_1A]: {
                'text': [
                    "The cats leapt out of the box and over the fence."
                ],
            },
            [PICK_A_PATH_NODE_1B]: {
                'text': [
                    "The ants swarmed out of the box and all over Max's face!"
                ],
            },
            [PICK_A_PATH_NODE_1C]: {
                'text': [
                    "The mice scurried out of the box and across the grass."
                ],
            },
            [PICK_A_PATH_NODE_2]: {
                'text': [
                    "Bess lifted the lid and peeked over the side of the box. She found"
                ],
                'choices': [
                    ["a magnifying glass.", PICK_A_PATH_NODE_2A],
                    ["a mass of dental floss.", PICK_A_PATH_NODE_2B],
                    ["a necklace of precious rocks.", PICK_A_PATH_NODE_2C]
                ]
            },
            [PICK_A_PATH_NODE_2A]: {
                'text': [
                    "Bess used the magnifying glass to assess some ants."
                ],
            },
            [PICK_A_PATH_NODE_2B]: {
                'text': [
                    "Bess used a bit of the floss to get a piece of rice out of her teeth."
                ],
            },
            [PICK_A_PATH_NODE_2C]: {
                'text': [
                    "Bess said the necklace would look nice with her new dress."
                ],
            },
            [PICK_A_PATH_NODE_3]: {
                'text': [
                    "Gus grabbed the lid and gave it a toss. Inside the box were"
                ],
                'choices': [
                    ["heaps of loose rice.", PICK_A_PATH_NODE_3A],
                    ["lots of mixed nuts.", PICK_A_PATH_NODE_3B],
                    ["an enormous glass of juice.", PICK_A_PATH_NODE_3C]
                ]
            },
            [PICK_A_PATH_NODE_3A]: {
                'text': [
                    "Gus spilled the rice all over the grass. \"I guess we can leave it for the ants,\" said Bess."
                ],
            },
            [PICK_A_PATH_NODE_3B]: {
                'text': [
                    "Gus gave the nuts to a passing mouse."
                ],
            },
            [PICK_A_PATH_NODE_3C]: {
                'text': [
                    "The juice spilled all over Gus, Bess, AND Max! What a mess!"
                ],
            },
            [PICK_A_PATH_NODE_END]: {
                'text': [
                    "After class, Max, Bess, and Gus told their class about the box. No one could guess what they'd found inside!"
                ],
            },
        }
    },
    'that-smell': {  // S Blends - Read-Together
        'name': "What's that Smell?",
        'dropdownName': "What's that Smell? (S Blends)",
        'targetSounds': [TARGET_SOUND_S],
        'positions': [POSITION_SK_BLEND, POSITION_SL_BLEND, POSITION_SM_BLEND, POSITION_SN_BLEND, POSITION_SP_BLEND, POSITION_ST_BLEND, POSITION_SW_BLEND, POSITION_COMPLEX_BLEND],
        'readingLevel': PICK_A_PATH_READING_LEVEL_EASY,
        'vocab': [
            ['ask', 'asked'],
            ['asleep'],
            ['ice_cream', 'ice cream'],
            ['rooster'],
            ['scone'],
            ['Scout'],
            ['skip', 'skipping'],
            ['sleeping'],
            ['slice'],
            ['sloth'],
            ['smear', 'smeared'],
            ['smell'],
            ['smell', 'smelled'],
            ['smelly'],
            ['smoothie'],
            ['smore', "s'more"],
            ['snack_bar', 'snack bar'],
            ['snack', 'snacking'],
            ['snap_pea', 'snap peas'],
            ['sneaky'],
            ['sniff', 'sniffed'],
            ['snorkel'],
            ['spaghetti'],
            ['spider_monkey', 'spider monkey'],
            ['spilled'],
            ['start', 'started'],
            ['Stella'],
            ['sticky'],
            ['stop', 'stopped'],
            ['stork'],
            ['strange'],
            ['strawberry'],
            ['street'],
            ['strong'],
            ['stumped'],
            ['swim_mask', 'swim mask'],
            ['swimming'],
            ['swimming_pool', 'swimming pool'],
            ['swing'],
            ['swinging'],
            ['Swiss_cheese', 'Swiss cheese'],
        ],
        'nodes': {
            [PICK_A_PATH_NODE_START]: {
                'text': [
                    "<Stella> and <Scout> were <skip|skipping> down the <street> one day when they <stop|stopped> in their tracks.",
                    "\"Ew! What is that <smell|smell?\"> <ask|asked> <Stella|Stella.>",
                    "\"I don't know,\" answered <Scout|Scout.> \"But something really stinks!\"",
                    "<Stella> and <Scout> <sniff|sniffed> the air and <start|started> to walk toward the"
                ],
                'choices': [
                    ["<swimming_pool|swimming pool.>", PICK_A_PATH_NODE_1],
                    ["<snack_bar|snack bar.>", PICK_A_PATH_NODE_2],
                ]
            },
            [PICK_A_PATH_NODE_1]: {
                'text': [
                    "<Stella> and <Scout> followed the <smell> to the <swimming_pool|swimming pool,> where they found a"
                ],
                'choices': [
                    ["<stork|stork.>", PICK_A_PATH_NODE_1A],
                    ["<spider_monkey|spider monkey.>", PICK_A_PATH_NODE_1B],
                ]
            },
            [PICK_A_PATH_NODE_1A]: {
                'text': [
                    "The <stork> was <swimming> in the pool and carrying a"
                ],
                'choices': [
                    ["<snorkel|snorkel.>", PICK_A_PATH_NODE_1A1],
                    ["<swim_mask|swim mask.>", PICK_A_PATH_NODE_1A2],
                ]
            },
            [PICK_A_PATH_NODE_1A1]: {
                'text': [
                    "The <stork> was slurping <ice_cream|ice cream> through a <snorkel|snorkel.> But the <strange> <smell> wasn't <ice_cream|ice cream.>"
                ],
            },
            [PICK_A_PATH_NODE_1A2]: {
                'text': [
                    "The <stork> was eating <spaghetti> out of a <swim_mask|swim mask.> But the <spaghetti> wasn't <smelly> enough for <Scout> and <Stella|Stella.>"
                ],
            },
            [PICK_A_PATH_NODE_1B]: {
                'text': [
                    "The <spider_monkey|spider monkey> was <swinging> and eating"
                ],
                'choices': [
                    ["<Swiss_cheese|Swiss cheese.>", PICK_A_PATH_NODE_1B1],
                    ["<snap_pea|snap peas.>", PICK_A_PATH_NODE_1B2],
                ]
            },
            [PICK_A_PATH_NODE_1B1]: {
                'text': [
                    "The <spider_monkey|spider monkey> was <snack|snacking> on a thick <slice> of <Swiss_cheese|Swiss cheese.> But the <Swiss_cheese|Swiss cheese> wasn't the source of the stink."
                ],
            },
            [PICK_A_PATH_NODE_1B2]: {
                'text': [
                    "The <spider_monkey|spider monkey> had <spilled> some <snap_pea|snap peas> under the <swing|swing.> But <snap_pea|snap peas> don't have a <strong> <smell|smell.>"
                ],
            },
            [PICK_A_PATH_NODE_2]: {
                'text': [
                    "<Stella> and <Scout> <sniff|sniffed> their way to the <snack_bar|snack bar.> When they got there they saw a"
                ],
                'choices': [
                    ["<rooster|rooster.>", PICK_A_PATH_NODE_2A],
                    ["<sloth|sloth.>", PICK_A_PATH_NODE_2B],
                ]
            },
            [PICK_A_PATH_NODE_2A]: {
                'text': [
                    "The <sneaky> <rooster> must have stolen a"
                ],
                'choices': [
                    ["<scone|scone.>", PICK_A_PATH_NODE_2A1],
                    ["<smoothie|smoothie.>", PICK_A_PATH_NODE_2A2],
                ]
            },
            [PICK_A_PATH_NODE_2A1]: {
                'text': [
                    "<Stella> and <Scout> saw the <rooster> scatter <scone> crumbs around the <snack_bar|snack bar.> But the <scone> didn't have the <smell> they were after."
                ],
            },
            [PICK_A_PATH_NODE_2A2]: {
                'text': [
                    "The <rooster> <spilled> <smoothie> all over the <snack_bar|snack bar!> But its <strawberry> <smell> wasn't stinky enough for <Stella> and <Scout|Scout.>"
                ],
            },
            [PICK_A_PATH_NODE_2B]: {
                'text': [
                    "The <sloth> was <sleeping> in the middle of the <snack_bar|snack bar.> In its hand was a"
                ],
                'choices': [
                    ["<slice> of bread.", PICK_A_PATH_NODE_2B1],
                    ["<smore|s'more.>", PICK_A_PATH_NODE_2B2],
                ]
            },
            [PICK_A_PATH_NODE_2B1]: {
                'text': [
                    "The <slice> of bread was <smear|smeared> with <strawberry> jam. It <smell|smelled> good, so it wasn't the <smell> they were seeking."
                ],
            },
            [PICK_A_PATH_NODE_2B2]: {
                'text': [
                    "The <sloth> had fallen <asleep> while <snack|snacking> on a <smore|s'more.> The <smore|s'more> was <sticky|sticky,> but it was not stinky."
                ],
            },
            [PICK_A_PATH_NODE_END]: {
                'text': [
                    "<Scout> and <Stella> were <stumped|stumped!> They never found out what was so <smelly|smelly.> Do you have any guesses?"
                ],
            }
        }
    },
    'stanleys-stroll': {  // S Blends - Proficient Reader
        'name': "Stanley's Stroll",
        'dropdownName': "Stanley's Stroll (S Blends)",
        'targetSounds': [TARGET_SOUND_S],
        'positions': [POSITION_SK_BLEND, POSITION_SL_BLEND, POSITION_SM_BLEND, POSITION_SN_BLEND, POSITION_SP_BLEND, POSITION_ST_BLEND, POSITION_SW_BLEND, POSITION_COMPLEX_BLEND],
        'readingLevel': PICK_A_PATH_READING_LEVEL_PROFICIENT,
        'nodes': {
            [PICK_A_PATH_NODE_START]: {
                'text': ["Stanley was strolling home from school when he spotted something in the street.",
                    "He stood still for a moment, then scooted closer for a look."],
                'choices': [
                    ["He was scared to see it was a snake.", PICK_A_PATH_NODE_1],
                    ["He was startled to discover it was a skunk.", PICK_A_PATH_NODE_2],
                    ["He was stunned to realize it was a sloth.", PICK_A_PATH_NODE_3]
                ]
            },
            [PICK_A_PATH_NODE_1]: {
                'text': ["The speckled snake slithered slowly across the street. Stanley"],
                'choices': [
                    ["stepped over the snake.", PICK_A_PATH_NODE_1A],
                    ["stepped on the snake.", PICK_A_PATH_NODE_1B],
                    ["scampered back down the street, away from the snake.", PICK_A_PATH_NODE_1C]
                ]
            },
            [PICK_A_PATH_NODE_1A]: {
                'text': ["The snake was too spaced out to notice. It never stopped moving."],
            },
            [PICK_A_PATH_NODE_1B]: {
                'text': ["The snake spun around to strike! Stanley skedaddled."],
            },
            [PICK_A_PATH_NODE_1C]: {
                'text': ["The snake slid away, speculating about why Stanley was so scared."],
            },
            [PICK_A_PATH_NODE_2]: {
                'text': ["The striped skunk slinked slyly across the street. Stanley"],
                'choices': [
                    ["screamed and ran away!", PICK_A_PATH_NODE_2A],
                    ["stood and stared at the skunk.", PICK_A_PATH_NODE_2B],
                    ["got sprayed by the skunk.", PICK_A_PATH_NODE_2C]
                ]
            },
            [PICK_A_PATH_NODE_2A]: {
                'text': ["The skunk smiled and sniffed a snapdragon."],
            },
            [PICK_A_PATH_NODE_2B]: {
                'text': ["The skunk snarled, so Stanley decided to split!"],
            },
            [PICK_A_PATH_NODE_2C]: {
                'text': ["The skunk smirked and scampered away. Stinky Stanley stumbled home."],
            },
            [PICK_A_PATH_NODE_3]: {
                'text': ["The smiling sloth slept in the street. Stanley"],
                'choices': [
                    ["awakened the sloth and steered him out of the street.", PICK_A_PATH_NODE_3A],
                    ["lay down in the street and snuggled with the sloth.", PICK_A_PATH_NODE_3B],
                    ["scooped up the sloth and took it home.", PICK_A_PATH_NODE_3C]
                ]
            },
            [PICK_A_PATH_NODE_3A]: {
                'text': ["The sloth found a snug spot under a tree and went back to sleep."],
            },
            [PICK_A_PATH_NODE_3B]: {
                'text': ["They snoozed sweetly until a small child on skates told them to scram."],
            },
            [PICK_A_PATH_NODE_3C]: {
                'text': ["The sloth snored as Stanley constructed a snug little nest."],
            },
            [PICK_A_PATH_NODE_END]: {
                'text': ["Maybe Stanley should stick with the school bus from now on!"],
            },
        }
    },

    // Target sound SH
    'show-and-tell': {  // Initial SH - Read-Together
        'name': "Show and Tell",
        'dropdownName': "Show and Tell (Initial SH)",
        'targetSounds': [TARGET_SOUND_SH],
        'positions': [POSITION_INITIAL],
        'readingLevel': PICK_A_PATH_READING_LEVEL_EASY,
        'vocab': [
            ['shade'],
            ['shake'],
            ['shampoo'],
            ['Shane'],
            ['Shane', "Shane's"],
            ['share'],
            ['share', 'shared'],
            ['shark'],
            ['shaving_cream', 'shaving cream'],
            ['she'],
            ['sheepdog'],
            ['Shelby'],
            ['Shelby', "Shelby's"],
            ['shelf'],
            ['shell'],
            ['sherbet'],
            ['shiny'],
            ['ship'],
            ['shirt'],
            ['shoelace'],
            ['shoelace', 'shoelaces'],
            ['shoes'],
            ['shopping_bag', 'shopping bag'],
            ['shovel'],
            ['show'],
            ['shower'],
            ['sugar'],
        ],
        'nodes': {
            [PICK_A_PATH_NODE_START]: {
                'text': [
                    "Mr. Shaw's class was ready for <show> and tell! The first student to <share> was"
                ],
                'choices': [
                    ["<Shelby|Shelby.>", PICK_A_PATH_NODE_1],
                    ["<Shane|Shane.>", PICK_A_PATH_NODE_2],
                ]
            },
            [PICK_A_PATH_NODE_1]: {
                'text': [
                    "<Shelby> had thought hard about what <she> should <show> her friends. <she|She> chose a picture of a"
                ],
                'choices': [
                    ["<sheepdog|sheepdog.>", PICK_A_PATH_NODE_1A],
                    ["<shark|shark.>", PICK_A_PATH_NODE_1B],
                ]
            },
            [PICK_A_PATH_NODE_1A]: {
                'text': [
                    "<Shelby> had a shaggy <sheepdog> named Shep. <Shelby> <share|shared> a picture of Shep"
                ],
                'choices': [
                    ["getting a <shampoo|shampoo.>", PICK_A_PATH_NODE_1A1],
                    ["eating a <shoelace|shoelace.>", PICK_A_PATH_NODE_1A2],
                ]
            },
            [PICK_A_PATH_NODE_1A1]: {
                'text': [
                    "<Shelby> used <shampoo> made for <sheepdog|sheepdogs> when <she> gave Shep a <shower|shower.>"
                ],
            },
            [PICK_A_PATH_NODE_1A2]: {
                'text': [
                    "<Shelby|Shelby's> <sheepdog> Shep loved to pull <shoelace|shoelaces> out of <Shelby|Shelby's> <shoes|shoes!>"
                ],
            },
            [PICK_A_PATH_NODE_1B]: {
                'text': [
                    "<Shelby> had painted a picture of a <shark> and a"
                ],
                'choices': [
                    ["<ship|ship.>", PICK_A_PATH_NODE_1B1],
                    ["<shell|shell.>", PICK_A_PATH_NODE_1B2],
                ]
            },
            [PICK_A_PATH_NODE_1B1]: {
                'text': [
                    "In <Shelby|Shelby's> picture, the <shark> was swimming in the <shade> of a big <ship|ship.>"
                ],
            },
            [PICK_A_PATH_NODE_1B2]: {
                'text': [
                    "<Shelby> had painted a <shark> with a <shiny> <shell> in its mouth!"
                ],
            },
            [PICK_A_PATH_NODE_2]: {
                'text': [
                    "<Shane> wanted to <shake> things up, so he brought a bowl of"
                ],
                'choices': [
                    ["<shaving_cream|shaving cream.>", PICK_A_PATH_NODE_2A],
                    ["<sherbet|sherbet.>", PICK_A_PATH_NODE_2B],
                ]
            },
            [PICK_A_PATH_NODE_2A]: {
                'text': [
                    "Mr. Shaw asked <Shane> where he got the <shaving_cream|shaving cream.> <Shane> said he found it in"
                ],
                'choices': [
                    ["a <shopping_bag|shopping bag.>", PICK_A_PATH_NODE_2A1],
                    ["the <shower|shower.>", PICK_A_PATH_NODE_2A2],
                ]
            },
            [PICK_A_PATH_NODE_2A1]: {
                'text': [
                    "<Shane> had found the <shaving_cream|shaving cream> in a <shopping_bag|shopping bag,> along with some <sugar> and a <shovel|shovel.>"
                ],
            },
            [PICK_A_PATH_NODE_2A2]: {
                'text': [
                    "<Shane> had found the <shaving_cream|shaving cream> in the <shower|shower,> right next to some <shampoo|shampoo.>"
                ],
            },
            [PICK_A_PATH_NODE_2B]: {
                'text': [
                    "By the time <Shane> got to school, the <sherbet> had melted all over his"
                ],
                'choices': [
                    ["<shoes|shoes.>", PICK_A_PATH_NODE_2B1],
                    ["<shirt|shirt.>", PICK_A_PATH_NODE_2B2],
                ]
            },
            [PICK_A_PATH_NODE_2B1]: {
                'text': [
                    "<Shane|Shane's> <shoes> were so sticky! Mr. Shaw told him to put them on a <shelf> in the hall."
                ],
            },
            [PICK_A_PATH_NODE_2B2]: {
                'text': [
                    "<Shane|Shane's> <shirt> was so messy! He was lucky that his friend Shawn had an extra <shirt> to <share|share.>"
                ],
            },
            [PICK_A_PATH_NODE_END]: {
                'text': [
                    "What would you <share> with your friends during <show> and tell?"
                ],
            }
        }
    },
    'sheepdog-shelia': {  // Initial SH - Proficient Reader
        'name': "Sheila the Sheepdog",
        'dropdownName': "Sheila the Sheepdog (Initial SH)",
        'targetSounds': [TARGET_SOUND_SH],
        'positions': [POSITION_INITIAL],
        'readingLevel': PICK_A_PATH_READING_LEVEL_PROFICIENT,
        'nodes': {
            [PICK_A_PATH_NODE_START]: {
                'text': [
                    "I have a sheepdog named Sheila. She's big and shaggy, and she's fun to play with.",
                    "Only one thing is a shame about Sheila: she loves to steal things. She really loves to take"
                ],
                'choices': [
                    ["shovels.", PICK_A_PATH_NODE_1],
                    ["shoes.", PICK_A_PATH_NODE_2],
                    ["sheets of paper.", PICK_A_PATH_NODE_3]
                ]
            },
            [PICK_A_PATH_NODE_1]: {
                'text': [
                    "Silly Sheila likes to steal shovels even though she digs best with her big paws! Just yesterday she dug up a"
                ],
                'choices': [
                    ["shoelace.", PICK_A_PATH_NODE_1A],
                    ["shell.", PICK_A_PATH_NODE_1B],
                    ["shingle.", PICK_A_PATH_NODE_1C]
                ]
            },
            [PICK_A_PATH_NODE_1A]: {
                'text': [
                    "Sheila was digging and found a shoelace buried in the yard. She pulled and pulled and out popped a muddy old shoe!"
                ],
            },
            [PICK_A_PATH_NODE_1B]: {
                'text': [
                    "Sheila was digging in the flower bed and found a tiny, shiny shell. I think it once belonged to a little snail."
                ],
            },
            [PICK_A_PATH_NODE_1C]: {
                'text': [
                    "Sheila was in the garden and dug up an old shingle. It must have flown off the shed during our last storm."
                ],
            },
            [PICK_A_PATH_NODE_2]: {
                'text': [
                    "Shoes are Sheila's favorite treasures. She's taken shoes from my"
                ],
                'choices': [
                    ["brother Shane.", PICK_A_PATH_NODE_2A],
                    ["sister Cheyenne.", PICK_A_PATH_NODE_2B],
                    ["uncle Shep.", PICK_A_PATH_NODE_2C]
                ]
            },
            [PICK_A_PATH_NODE_2A]: {
                'text': [
                    "Shane was getting dressed when Sheila shuffled into his room and swiped his shoe. Shane had to chase Sheila around the house with his shirt over his head!"
                ],
            },
            [PICK_A_PATH_NODE_2B]: {
                'text': [
                    "Cheyenne had just bought some new shoes. Sheila lurked in the shadows and snatched a shoe out of Cheyenne's shopping bag!"
                ],
            },
            [PICK_A_PATH_NODE_2C]: {
                'text': [
                    "Uncle Shep was in the middle of shaving when Sheila took off with his shoe. He chased Sheila around with shaving cream on his face!"
                ],
            },
            [PICK_A_PATH_NODE_3]: {
                'text': [
                    "Sheila loves paper! She will snag a sheet whenever she sees her chance. She's taken my"
                ],
                'choices': [
                    ["shopping list.", PICK_A_PATH_NODE_3A],
                    ["drawing of a sheep.", PICK_A_PATH_NODE_3B],
                    ["sugar cookie recipe.", PICK_A_PATH_NODE_3C]
                ]
            },
            [PICK_A_PATH_NODE_3A]: {
                'text': [
                    "One day Sheila took a shopping list off of the kitchen shelf. The only things that I could remember to buy were sherbet and shampoo."
                ],
            },
            [PICK_A_PATH_NODE_3B]: {
                'text': [
                    "Sheila sneaked off with my favorite drawing of a sheep waiting to be sheared. I had to draw it again - what a shame."
                ],
            },
            [PICK_A_PATH_NODE_3C]: {
                'text': [
                    "Sheila ate the only copy of my sugar cookie recipe! I'm not really sure how to make sugar cookies without it, so I've had to settle for shortcake ever since."
                ],
            },
            [PICK_A_PATH_NODE_END]: {
                'text': [
                    "I know Sheila shouldn't steal things. She's naughty, but she sure is sweet. When she shimmies her tail and shakes her ears, I can't help but share her joy!"
                ],
            },
        }
    },
    'wishing-machine': {  // Medial SH - Read-Together
        'name': "The Wishing Machine",
        'dropdownName': "The Wishing Machine (Medial SH)",
        'targetSounds': [TARGET_SOUND_SH],
        'positions': [POSITION_MEDIAL],
        'readingLevel': PICK_A_PATH_READING_LEVEL_EASY,
        'vocab': [
            ['Asher'],
            ['celebration'],
            ['delicious'],
            ['dishes'],
            ['fashion'],
            ['fishbowl'],
            ['flashlight'],
            ['flashy'],
            ['machine'],
            ['marshmallow'],
            ['ocean'],
            ['rushing'],
            ['seashell'],
            ['special'],
            ['sunshine'],
            ['sushi'],
            ['sweatshirt'],
            ['t-shirt'],
            ['Tisha'],
            ['Tisha', "Tisha's"],
            ['vacation'],
            ['wishing'],
        ],
        'nodes': {
            [PICK_A_PATH_NODE_START]: {
                'text': [
                    "<Asher> and <Tisha> were <rushing> to school when they saw something strange on the sidewalk. It was a box with a sign that read WISHING MACHINE.",
                    "\"Oh, wow!\" said <Tisha> and <Asher|Asher.> \"Let's do some <wishing|wishing!\"> The first to make a wish was"
                ],
                'choices': [
                    ["<Asher|Asher.>", PICK_A_PATH_NODE_1],
                    ["<Tisha|Tisha.>", PICK_A_PATH_NODE_2],
                ]
            },
            [PICK_A_PATH_NODE_1]: {
                'text': [
                    "<Asher> was really into <fashion|fashion,> so he wished for a <flashy> new"
                ],
                'choices': [
                    ["<t-shirt|t-shirt.>", PICK_A_PATH_NODE_1A],
                    ["<sweatshirt|sweatshirt.>", PICK_A_PATH_NODE_1B],
                ]
            },
            [PICK_A_PATH_NODE_1A]: {
                'text': [
                    "The top of the <wishing> <machine> opened and out popped a <t-shirt|t-shirt!> It was the color of"
                ],
                'choices': [
                    ["<sunshine|sunshine.>", PICK_A_PATH_NODE_1A1],
                    ["the <ocean|ocean.>", PICK_A_PATH_NODE_1A2],
                ]
            },
            [PICK_A_PATH_NODE_1A1]: {
                'text': [
                    "The <t-shirt> was the brightest yellow that <Asher> had ever seen! He couldn't wait to wear it to his birthday <celebration|celebration.>"
                ],
            },
            [PICK_A_PATH_NODE_1A2]: {
                'text': [
                    "The <t-shirt> was an awesome <ocean> blue. <Asher> said he was going to wear it on his next <vacation|vacation.>"
                ],
            },
            [PICK_A_PATH_NODE_1B]: {
                'text': [
                    "Out of the <wishing> <machine> came a sparkly <sweatshirt|sweatshirt.> It was brighter than a"
                ],
                'choices': [
                    ["<flashlight|flashlight.>", PICK_A_PATH_NODE_1B1],
                    ["ray of <sunshine|sunshine.>", PICK_A_PATH_NODE_1B2],
                ]
            },
            [PICK_A_PATH_NODE_1B1]: {
                'text': [
                    "<Asher> thought his <sweatshirt> was bright enough to light up the night."
                ],
            },
            [PICK_A_PATH_NODE_1B2]: {
                'text': [
                    "<Asher> said his <sweatshirt> was so bright that his friends would need sunglasses just to look at him."
                ],
            },
            [PICK_A_PATH_NODE_2]: {
                'text': [
                    "<Tisha> said she was hungry, so she wished for"
                ],
                'choices': [
                    ["a <marshmallow|marshmallow.>", PICK_A_PATH_NODE_2A],
                    ["some <sushi|sushi.>", PICK_A_PATH_NODE_2B],
                ]
            },
            [PICK_A_PATH_NODE_2A]: {
                'text': [
                    "A hole in the <wishing> <machine> opened, and out came a <marshmallow|marshmallow!> It was the size of a"
                ],
                'choices': [
                    ["<fishbowl|fishbowl.>", PICK_A_PATH_NODE_2A1],
                    ["<seashell|seashell.>", PICK_A_PATH_NODE_2A2],
                ]
            },
            [PICK_A_PATH_NODE_2A1]: {
                'text': [
                    "The huge <marshmallow> was the size of the <fishbowl> in <Tisha|Tisha's> bedroom. She wouldn't be hungry for long!"
                ],
            },
            [PICK_A_PATH_NODE_2A2]: {
                'text': [
                    "The <marshmallow> was as big as the <seashell> that <Tisha> had found on her summer <vacation|vacation.> It was bigger than her hand!"
                ],
            },
            [PICK_A_PATH_NODE_2B]: {
                'text': [
                    "A tray slid out of the <wishing> <machine|machine.> It held two <dishes> of <sushi|sushi.> <Tisha> said the <sushi> was"
                ],
                'choices': [
                    ["<delicious|delicious.>", PICK_A_PATH_NODE_2B1],
                    ["<special|special.>", PICK_A_PATH_NODE_2B2],
                ]
            },
            [PICK_A_PATH_NODE_2B1]: {
                'text': [
                    "<Tisha> told <Asher> that this was the most <delicious> <sushi> she'd ever had. Yum!"
                ],
            },
            [PICK_A_PATH_NODE_2B2]: {
                'text': [
                    "<Tisha> said that she'd never tasted <sushi> like this before. It was such a <special> treat!"
                ],
            },
            [PICK_A_PATH_NODE_END]: {
                'text': [
                    "<Asher> and <Tisha> wanted to keep <wishing|wishing,> but they were running late. They thanked the <wishing> <machine> for the <special> treats and hurried off."
                ],
            }
        }
    },
    'aishas-mission': {  // Medial SH - Proficient Reader
        'name': "Aisha's Special Mission",
        'dropdownName': "Aisha's Special Mission (Medial SH)",
        'targetSounds': [TARGET_SOUND_SH],
        'positions': [POSITION_MEDIAL],
        'readingLevel': PICK_A_PATH_READING_LEVEL_PROFICIENT,
        'nodes': {
            [PICK_A_PATH_NODE_START]: {
                'text': [
                    "One day, Aisha found an invitation in the mail. It was asking her to take part in a special mission! The invitation gave directions to a"
                ],
                'choices': [
                    ["mansion.", PICK_A_PATH_NODE_1],
                    ["sweet shop.", PICK_A_PATH_NODE_2],
                    ["wishing well.", PICK_A_PATH_NODE_3]
                ]
            },
            [PICK_A_PATH_NODE_1]: {
                'text': [
                    "Aisha's mission at the mansion was to find the location of a"
                ],
                'choices': [
                    ["missing t-shirt.", PICK_A_PATH_NODE_1A],
                    ["work of fiction.", PICK_A_PATH_NODE_1B],
                    ["little seashell.", PICK_A_PATH_NODE_1C]
                ]
            },
            [PICK_A_PATH_NODE_1A]: {
                'text': [
                    "Aisha found the t-shirt in the washing machine. It was her favorite color! She put it on and moved to the next location."
                ],
            },
            [PICK_A_PATH_NODE_1B]: {
                'text': [
                    "Aisha found a book of fiction about a space mission. Aisha dreamed of going to space in a rocket ship! She put the book in her bag and continued to the next location."
                ],
            },
            [PICK_A_PATH_NODE_1C]: {
                'text': [
                    "Aisha found a small seashell on a bookshelf. It was precious! Aisha tucked it in her pocket and proceeded to the next location."
                ],
            },
            [PICK_A_PATH_NODE_2]: {
                'text': [
                    "The mission instructions told Aisha to go to her favorite sweet shop and ask for a"
                ],
                'choices': [
                    ["milkshake.", PICK_A_PATH_NODE_2A],
                    ["marshmallow pie.", PICK_A_PATH_NODE_2B],
                    ["mushroom cookie.", PICK_A_PATH_NODE_2C]
                ]
            },
            [PICK_A_PATH_NODE_2A]: {
                'text': [
                    "Aisha asked for a milkshake, and the cashier handed her a creamy, delicious concoction in a tall glass. Yum!"
                ],
            },
            [PICK_A_PATH_NODE_2B]: {
                'text': [
                    "Aisha took a bite and decided that chocolate and marshmallow made a perfect combination."
                ],
            },
            [PICK_A_PATH_NODE_2C]: {
                'text': [
                    "Aisha's expression changed. A mushroom cookie? Luckily the cashier handed her a sugar cookie decorated to look like a mushroom. Just precious!"
                ],
            },
            [PICK_A_PATH_NODE_3]: {
                'text': [
                    "Aisha's instructions told her to make a wish, so she wished to go"
                ],
                'choices': [
                    ["on a vacation.", PICK_A_PATH_NODE_3A],
                    ["on a spaceship.", PICK_A_PATH_NODE_3B],
                    ["to a fashion show.", PICK_A_PATH_NODE_3C]
                ]
            },
            [PICK_A_PATH_NODE_3A]: {
                'text': [
                    "Aisha thought a vacation to the ocean would be especially fun. She'd love to go surf fishing!"
                ],
            },
            [PICK_A_PATH_NODE_3B]: {
                'text': [
                    "How fun it would be to go into space, thought Aisha. Maybe she would meet a Martian!"
                ],
            },
            [PICK_A_PATH_NODE_3C]: {
                'text': [
                    "Aisha paid a lot of attention to fashion trends. She thought a fashion show would be a special treat!"
                ],
            },
            [PICK_A_PATH_NODE_END]: {
                'text': [
                    "Aisha continued to her next location, where she saw a sign that read CONGRATULATIONS! She had completed her mission - time for a celebration!"
                ],
            },
        }
    },
    'silly-fish': {  // Final SH - Read-Together
        'name': "Silly Fish",
        'dropdownName': "Silly Fish (Final SH)",
        'targetSounds': [TARGET_SOUND_SH],
        'positions': [POSITION_FINAL],
        'readingLevel': PICK_A_PATH_READING_LEVEL_EASY,
        'vocab': [
            ['Ash'],
            ['brush~1', 'brush'],
            ['brush~2', 'brush'],
            ['dish'],
            ['eyelash'],
            ['fish'],
            ['goldfish'],
            ['mesh'],
            ['mustache'],
            ['nail_polish', 'nail polish'],
            ['paintbrush'],
            ['radish'],
            ['Swedish_Fish', 'Swedish Fish'],
            ['trash'],
            ['Trish'],
            ['wish'],
        ],
        'nodes': {
            [PICK_A_PATH_NODE_START]: {
                'text': [
                    "Mr. Nash asked each of his students to bring a silly <fish> to school. Some students"
                ],
                'choices': [
                    ["made a <fish|fish.>", PICK_A_PATH_NODE_1],
                    ["found a <fish|fish.>.", PICK_A_PATH_NODE_2],
                ]
            },
            [PICK_A_PATH_NODE_1]: {
                'text': [
                    "<Ash> made a silly <fish|fish.> She made it"
                ],
                'choices': [
                    ["with a <paintbrush|paintbrush.>", PICK_A_PATH_NODE_1A],
                    ["out of <nail_polish|nail polish.>", PICK_A_PATH_NODE_1B],
                ]
            },
            [PICK_A_PATH_NODE_1A]: {
                'text': [
                    "<Ash> used a <paintbrush> and paper to make a <fish|fish.> It had a silly"
                ],
                'choices': [
                    ["<mustache|mustache.>", PICK_A_PATH_NODE_1A1],
                    ["<eyelash|eyelash.>", PICK_A_PATH_NODE_1A2],
                ]
            },
            [PICK_A_PATH_NODE_1A1]: {
                'text': [
                    "<Ash> painted a <fish> with a big fluffy <mustache|mustache!> How silly!"
                ],
            },
            [PICK_A_PATH_NODE_1A2]: {
                'text': [
                    "<Ash> painted a <fish> with only one long <eyelash|eyelash.> It looked very silly!"
                ],
            },
            [PICK_A_PATH_NODE_1B]: {
                'text': [
                    "<Ash> made her silly <fish> using <nail_polish|nail polish> and"
                ],
                'choices': [
                    ["a <brush~1|brush.>", PICK_A_PATH_NODE_1B1],
                    ["<mesh|mesh.>", PICK_A_PATH_NODE_1B2],
                ]
            },
            [PICK_A_PATH_NODE_1B1]: {
                'text': [
                    "<Ash> used the <nail_polish|nail polish> <brush~1|brush> to paint a <fish> on her hand. It was tiny and silly!"
                ],
            },
            [PICK_A_PATH_NODE_1B2]: {
                'text': [
                    "<Ash> poured <nail_polish|nail polish> into a <dish|dish.> She dipped some <mesh> into the <nail_polish|nail polish> and used it to make a <fish|fish.> What a silly looking <fish|fish!>"
                ],
            },
            [PICK_A_PATH_NODE_2]: {
                'text': [
                    "<Trish> found a silly <fish|fish.> She found it"
                ],
                'choices': [
                    ["in a <dish|dish.>", PICK_A_PATH_NODE_2A],
                    ["in the <trash|trash.>", PICK_A_PATH_NODE_2B],
                ]
            },
            [PICK_A_PATH_NODE_2A]: {
                'text': [
                    "<Trish> looked around and found a <dish> of"
                ],
                'choices': [
                    ["<goldfish> crackers.", PICK_A_PATH_NODE_2A1],
                    ["<Swedish_Fish|Swedish Fish.>", PICK_A_PATH_NODE_2A2],
                ]
            },
            [PICK_A_PATH_NODE_2A1]: {
                'text': [
                    "<Trish> put the <dish> of <goldfish> crackers in her bookbag. \"It's a school of silly <fish|fish!\"> she said."
                ],
            },
            [PICK_A_PATH_NODE_2A2]: {
                'text': [
                    "<Trish> found a <dish> of <Swedish_Fish|Swedish Fish.> She took one to school and ate one. \"I <wish> I could eat the rest,\" she said."
                ],
            },
            [PICK_A_PATH_NODE_2B]: {
                'text': [
                    "<Trish> peeked in the <trash> and found a"
                ],
                'choices': [
                    ["<radish|radish.>", PICK_A_PATH_NODE_2B1],
                    ["<brush~2|brush.>", PICK_A_PATH_NODE_2B2],
                ]
            },
            [PICK_A_PATH_NODE_2B1]: {
                'text': [
                    "<Trish> thought the <radish> was shaped just like a <fish|fish.> How silly!"
                ],
            },
            [PICK_A_PATH_NODE_2B2]: {
                'text': [
                    "<Trish> drew a silly eye on the <brush~2|brush.> It looked just like a <fish|fish!>"
                ],
            },
            [PICK_A_PATH_NODE_END]: {
                'text': [
                    "Don't you <wish> you could have seen all of the silly <fish|fish?>"
                ],
            }
        }
    },
    'why-rush-trish': {  // Final SH - Proficient Reader
        'name': "Why the Rush, Trish?",
        'dropdownName': "Why the Rush, Trish? (Final SH)",
        'targetSounds': [TARGET_SOUND_SH],
        'positions': [POSITION_FINAL],
        'readingLevel': PICK_A_PATH_READING_LEVEL_PROFICIENT,
        'nodes': {
            [PICK_A_PATH_NODE_START]: {
                'text': [
                    "I ran into my friend Trish yesterday. She was running full speed down the sidewalk!",
                    "\"Why the rush, Trish?\" I asked. She said she was in a rush to get a"
                ],
                'choices': [
                    ["dish.", PICK_A_PATH_NODE_1],
                    ["brush.", PICK_A_PATH_NODE_2],
                    ["fish.", PICK_A_PATH_NODE_3]
                ]
            },
            [PICK_A_PATH_NODE_1]: {
                'text': [
                    "Trish said she had to rush to buy a new dish for her"
                ],
                'choices': [
                    ["licorice.", PICK_A_PATH_NODE_1A],
                    ["squash soup.", PICK_A_PATH_NODE_1B],
                    ["radish salad.", PICK_A_PATH_NODE_1C]
                ]
            },
            [PICK_A_PATH_NODE_1A]: {
                'text': [
                    "Trish loved licorice. She always kept a dish nearby, but her usual one had fallen to the floor with a smash."
                ],
            },
            [PICK_A_PATH_NODE_1B]: {
                'text': [
                    "Trish was planning to make squash soup for her friend Ash, and she wanted to serve it in a pretty new dish."
                ],
            },
            [PICK_A_PATH_NODE_1C]: {
                'text': [
                    "Trish and her sister Tish loved to make radish salad, but they had lost their favorite serving dish. \"I wish we could find it,\" sighed Trish."
                ],
            },
            [PICK_A_PATH_NODE_2]: {
                'text': [
                    "Trish was on her way to get a new"
                ],
                'choices': [
                    ["scrub brus.", PICK_A_PATH_NODE_2A],
                    ["toothbrush.", PICK_A_PATH_NODE_2B],
                    ["hairbrush.", PICK_A_PATH_NODE_2C]
                ]
            },
            [PICK_A_PATH_NODE_2A]: {
                'text': [
                    "Trish needed soap and a scrub brush to wash the trash can at her house. It smelled like stinky shellfish!"
                ],
            },
            [PICK_A_PATH_NODE_2B]: {
                'text': [
                    "Trish was getting ready for her birthday bash and couldn't find her toothbrush. She was in a rush to get a new one before the party."
                ],
            },
            [PICK_A_PATH_NODE_2C]: {
                'text': [
                    "With a blush, Trish told me that she'd accidentally dropped her hairbrush in the trash. It was covered in rotten squash!"
                ],
            },
            [PICK_A_PATH_NODE_3]: {
                'text': [
                    "Trish was in search of a fish. When I asked her what kind, she said"
                ],
                'choices': [
                    ["a plush fish.", PICK_A_PATH_NODE_3A],
                    ["a goldfish.", PICK_A_PATH_NODE_3B],
                    ["some Swedish Fish.", PICK_A_PATH_NODE_3C]
                ]
            },
            [PICK_A_PATH_NODE_3A]: {
                'text': [
                    "Trish was going to a birthday bash for her friend Nash, and she wanted to give him a plush fish as a gift."
                ],
            },
            [PICK_A_PATH_NODE_3B]: {
                'text': [
                    "Trish had some cash from her job at the car wash, and she wanted to spend it on a new goldfish! She couldn't wait to watch it swim and splash."
                ],
            },
            [PICK_A_PATH_NODE_3C]: {
                'text': [
                    "Trish had overheard her brother Cash wish for some Swedish Fish. She wanted to surprise him with a bag."
                ],
            },
            [PICK_A_PATH_NODE_END]: {
                'text': [
                    "I wish Trish hadn't been in such a rush. She was gone in a flash."
                ],
            },
        }
    },

    // Target sound TH
    'thirty-things': {  // Initial TH, Voiceless - Read-Together
        'name': "Thirty Things",
        'dropdownName': "Thirty Things (Initial TH, Voiceless)",
        'targetSounds': [TARGET_SOUND_TH],
        'positions': [POSITION_VOICELESS_INITIAL],
        'readingLevel': PICK_A_PATH_READING_LEVEL_EASY,
        'vocab': [
            ['Thad'],
            ['thank_you', 'thank you'],
            ['thank_you_note', 'thank you notes'],
            ['Thea'],
            ['theater'],
            ['Theo'],
            ['thermometers'],
            ['thermos'],
            ['thick'],
            ['thief'],
            ['thin'],
            ['third'],
            ['thirty'],
            ['thistle'],
            ['Thor'],
            ['Thora'],
            ['thought'],
            ['thousand'],
            ['thread'],
            ['three'],
            ['threw'],
            ['throat'],
            ['throw'],
            ['throw', 'throwing'],
            ['thumbtacks'],
            ['thunder'],
            ['Thursday'],
        ],
        'nodes': {
            [PICK_A_PATH_NODE_START]: {
                'text': [
                    "A <third> grade class was learning about the number <thirty|thirty.> Their teacher asked everyone to bring <thirty> things to show the class. The first person to share was"
                ],
                'choices': [
                    ["<Theo|Theo.>", PICK_A_PATH_NODE_1],
                    ["<Thora|Thora.>", PICK_A_PATH_NODE_2],
                ]
            },
            [PICK_A_PATH_NODE_1]: {
                'text': [
                    "<Theo> was so excited to show the class <thirty>"
                ],
                'choices': [
                    ["<thumbtacks|thumbtacks.>", PICK_A_PATH_NODE_1A],
                    ["<theater> tickets.", PICK_A_PATH_NODE_1B],
                ]
            },
            [PICK_A_PATH_NODE_1A]: {
                'text': [
                    "<Theo> brought <thirty> <thumbtacks> to share. He <thought> the class could use them to hang"
                ],
                'choices': [
                    ["<thistle> leaves.", PICK_A_PATH_NODE_1A1],
                    ["<thank_you_note|thank you> cards.", PICK_A_PATH_NODE_1A2],
                ]
            },
            [PICK_A_PATH_NODE_1A1]: {
                'text': [
                    "<Theo> <thought> the <thistle> leaves would be pretty for Thanksgiving."
                ],
            },
            [PICK_A_PATH_NODE_1A2]: {
                'text': [
                    "<Theo> loved all of the ways his friends said <thank_you|\"thank you\"> to each other."
                ],
            },
            [PICK_A_PATH_NODE_1B]: {
                'text': [
                    "<Theo> brought <thirty> <theater> tickets. They were for a play about"
                ],
                'choices': [
                    ["a <thief|thief.>", PICK_A_PATH_NODE_1B1],
                    ["<Thor|Thor.>", PICK_A_PATH_NODE_1B2],
                ]
            },
            [PICK_A_PATH_NODE_1B1]: {
                'text': [
                    "The <thief> in the play stole <three> <thousand> <thermometers|thermometers!>"
                ],
            },
            [PICK_A_PATH_NODE_1B2]: {
                'text': [
                    "<Thor> was a hero who <threw> his mighty hammer to make <thunder|thunder.>"
                ],
            },
            [PICK_A_PATH_NODE_2]: {
                'text': [
                    "<Thora> couldn't wait to show the class <thirty>"
                ],
                'choices': [
                    ["<thank_you_note|thank you> notes.", PICK_A_PATH_NODE_2A],
                    ["things to <throw|throw.>", PICK_A_PATH_NODE_2B],
                ]
            },
            [PICK_A_PATH_NODE_2A]: {
                'text': [
                    "<Thora> brought <thirty> <thank_you_note|thank you notes.> One of the notes was for her friend"
                ],
                'choices': [
                    ["<Thad|Thad.>", PICK_A_PATH_NODE_2A1],
                    ["<Thea|Thea.>", PICK_A_PATH_NODE_2A2],
                ]
            },
            [PICK_A_PATH_NODE_2A1]: {
                'text': [
                    "<Thad> had invited <Thora> to his house for a Thanksgiving meal. How thoughtful!"
                ],
            },
            [PICK_A_PATH_NODE_2A2]: {
                'text': [
                    "Last <Thursday|Thursday,> <Thea> gave <Thora> a <thermos> of <thistle> tea for her sore <throat|throat.>"
                ],
            },
            [PICK_A_PATH_NODE_2B]: {
                'text': [
                    "<Thora> brought <thirty> things to <throw|throw.> She <thought> the class could <throw> them onto a"
                ],
                'choices': [
                    ["<thin> pillow.", PICK_A_PATH_NODE_2B1],
                    ["<thick> blanket.", PICK_A_PATH_NODE_2B2],
                ]
            },
            [PICK_A_PATH_NODE_2B1]: {
                'text': [
                    "The class had fun <throw|throwing> <thirty> <third|third-> place ribbons onto the <thin> pillow."
                ],
            },
            [PICK_A_PATH_NODE_2B2]: {
                'text': [
                    "The class <thought> it was funny to <throw> <thirty> spools of <thread> onto a <thick> blanket!"
                ],
            },
            [PICK_A_PATH_NODE_END]: {
                'text': [
                    "Can you <think> of <thirty> things you'd like to share?"
                ],
            }
        }
    },
    'theas-thursday': {  // Initial TH, Voiceless - Proficient Reader
        'name': "Thea's Fun Thursday",
        'dropdownName': "Thea's Fun Thursday (Initial TH, Voiceless)",
        'targetSounds': [TARGET_SOUND_TH],
        'positions': [POSITION_VOICELESS_INITIAL],
        'readingLevel': PICK_A_PATH_READING_LEVEL_PROFICIENT,
        'nodes': {
            [PICK_A_PATH_NODE_START]: {
                'text': [
                    "One Thursday, thirteen-year-old Thea was trying to figure out what to do. She thought for a while and finally decided to"
                ],
                'choices': [
                    ["go to the theater.", PICK_A_PATH_NODE_1],
                    ["throw something.", PICK_A_PATH_NODE_2],
                    ["write a thank-you note.", PICK_A_PATH_NODE_3]
                ]
            },
            [PICK_A_PATH_NODE_1]: {
                'text': [
                    "At the theater, Thea watched a movie about"
                ],
                'choices': [
                    ["a thief.", PICK_A_PATH_NODE_1A],
                    ["three friends.", PICK_A_PATH_NODE_1B],
                    ["a hero named Thor.", PICK_A_PATH_NODE_1C]
                ]
            },
            [PICK_A_PATH_NODE_1A]: {
                'text': [
                    "The thief in the movie stole thirty things including a thermos, a thimble, and some thumbtacks."
                ],
            },
            [PICK_A_PATH_NODE_1B]: {
                'text': [
                    "The three friends did some funny things. Thea's favorite part was when they threw a throw rug into the ocean like a raft."
                ],
            },
            [PICK_A_PATH_NODE_1C]: {
                'text': [
                    "Thea thought the best part of the movie was when Thor threw his hammer and made thunder rumble."
                ],
            },
            [PICK_A_PATH_NODE_2]: {
                'text': [
                    "Thea thought it would be fun to throw a"
                ],
                'choices': [
                    ["ball with her friend Thad.", PICK_A_PATH_NODE_2A],
                    ["frisbee to her dog, Thunder.", PICK_A_PATH_NODE_2B],
                    ["party for her cousin Theo.", PICK_A_PATH_NODE_2C]
                ]
            },
            [PICK_A_PATH_NODE_2A]: {
                'text': [
                    "Thea went to Thad's house and asked him to throw ball with her. \"Sure,\" said Thad. \"I'll meet you at the park at three-thirty.\""
                ],
            },
            [PICK_A_PATH_NODE_2B]: {
                'text': [
                    "Thunder was very good at catching things. He jumped thirteen feet in the air to catch the frisbee that Thea threw!"
                ],
            },
            [PICK_A_PATH_NODE_2C]: {
                'text': [
                    "Theo would be turning three soon. Thea sat down and thought about all of the fun things she could plan for his birthday party."
                ],
            },
            [PICK_A_PATH_NODE_3]: {
                'text': [
                    "Thea found her nicest paper and wrote a thank-you note to"
                ],
                'choices': [
                    ["her Aunt Thora.", PICK_A_PATH_NODE_3A],
                    ["her friend Thatcher.", PICK_A_PATH_NODE_3B],
                    ["her third grade teacher.", PICK_A_PATH_NODE_3C]
                ]
            },
            [PICK_A_PATH_NODE_3A]: {
                'text': [
                    "Thea wrote a letter thanking her Aunt Thora for staying with her during last week's thunderstorm."
                ],
            },
            [PICK_A_PATH_NODE_3B]: {
                'text': [
                    "Thea's note thanked Thatcher for sharing his thermos of water when she was thirsty."
                ],
            },
            [PICK_A_PATH_NODE_3C]: {
                'text': [
                    "Thea wrote a note thanking her third grade teacher for teaching her to count to a thousand."
                ],
            },
            [PICK_A_PATH_NODE_END]: {
                'text': [
                    "Thea thought she made a good choice. It was a fun Thursday!"
                ],
            },
        }
    },
    'arthurs-trip': {  // Medial TH, Voiced and Voiceless - Read-Together
        'name': "What is Arthur Missing?",
        'dropdownName': "What is Arthur Missing? (Medial TH, Voiced and Voiceless)",
        'targetSounds': [TARGET_SOUND_TH],
        'positions': [POSITION_VOICELESS_MEDIAL, POSITION_VOICED_MEDIAL],
        'readingLevel': PICK_A_PATH_READING_LEVEL_EASY,
        'vocab': [
            ['Arthur'],
            ['Arthur', "Arthur's"],
            ['athlete'],
            ['author'],
            ['bathing_suit', 'bathing suit'],
            ['bathrobe'],
            ['bathroom'],
            ['birthday'],
            ['brother'],
            ['clothing'],
            ['feathers'],
            ['filthy'],
            ['grandmother'],
            ['grandmother', "grandmother's"],
            ['healthy'],
            ['leather'],
            ['marathon'],
            ['mouthwash'],
            ['North_Dakota', 'North Dakota'],
            ['other'],
            ['panther'],
            ['python'],
            ['pythons'],
            ['smoothie'],
            ['smoothies'],
            ['something'],
            ['South_Carolina', 'South Carolina'],
            ['toothbrush'],
            ['toothpaste'],
            ['without'],
        ],
        'nodes': {
            [PICK_A_PATH_NODE_START]: {
                'text': [
                    "<Arthur> stood in his <bathroom> and scratched his head. He knew he was missing <something|something,> but he didn't know what. It was <something> he needed for his monthly trip to"
                ],
                'choices': [
                    ["<North_Dakota|North Dakota.>", PICK_A_PATH_NODE_1],
                    ["<South_Carolina|South Carolina.>", PICK_A_PATH_NODE_2],
                ]
            },
            [PICK_A_PATH_NODE_1]: {
                'text': [
                    "<Arthur> was going to <North_Dakota|North Dakota> to visit his <brother|brother.> He knew he would need a"
                ],
                'choices': [
                    ["<toothbrush|toothbrush.>", PICK_A_PATH_NODE_1A],
                    ["<birthday> gift.", PICK_A_PATH_NODE_1B],
                ]
            },
            [PICK_A_PATH_NODE_1A]: {
                'text': [
                    "The last time <Arthur> visited his <brother|brother,> he'd forgotten his <toothbrush|toothbrush.> He had to use"
                ],
                'choices': [
                    ["<mouthwash|mouthwash.>", PICK_A_PATH_NODE_1A1],
                    ["<toothpaste|toothpaste.>", PICK_A_PATH_NODE_1A2],
                ]
            },
            [PICK_A_PATH_NODE_1A1]: {
                'text': [
                    "<Arthur> had used <mouthwash> after every meal, but he really missed his <toothbrush|toothbrush!> He made sure to pack it this time."
                ],
            },
            [PICK_A_PATH_NODE_1A2]: {
                'text': [
                    "<Arthur> had rubbed his teeth with <toothpaste|toothpaste,> but they still felt <filthy|filthy.> He wouldn't forget his <toothbrush> again."
                ],
            },
            [PICK_A_PATH_NODE_1B]: {
                'text': [
                    "<Arthur|Arthur's> <brother> would be celebrating his twenty-third <birthday|birthday.> <Arthur> had gotten him"
                ],
                'choices': [
                    ["some <clothing|clothing.>", PICK_A_PATH_NODE_1B1],
                    ["a <leather> notebook.", PICK_A_PATH_NODE_1B2],
                ]
            },
            [PICK_A_PATH_NODE_1B1]: {
                'text': [
                    "<Arthur|Arthur's> <brother> is an <athlete|athlete.> <Arthur> got him some <clothing> that he could wear to run a <marathon|marathon.>"
                ],
            },
            [PICK_A_PATH_NODE_1B2]: {
                'text': [
                    "<Arthur|Arthur's> <brother> wants to be an <author|author.> <Arthur> got him a nice <leather> notebook and a pen."
                ],
            },
            [PICK_A_PATH_NODE_2]: {
                'text': [
                    "<Arthur> loved visiting his <grandmother> in <South_Carolina|South Carolina!> He needed to pack his"
                ],
                'choices': [
                    ["<bathing_suit|bathing suit.>", PICK_A_PATH_NODE_2A],
                    ["<bathrobe|bathrobe.>", PICK_A_PATH_NODE_2B],
                ]
            },
            [PICK_A_PATH_NODE_2A]: {
                'text': [
                    "<Arthur> liked swimming in his <grandmother|grandmother's> pool. His <bathing_suit|bathing suit> was decorated with"
                ],
                'choices': [
                    ["<feathers|feathers.>", PICK_A_PATH_NODE_2A1],
                    ["<pythons|pythons.>", PICK_A_PATH_NODE_2A2],
                ]
            },
            [PICK_A_PATH_NODE_2A1]: {
                'text': [
                    "<Arthur|Arthur's> <bathing_suit|bathing suit> had forty three <feathers> on it. He loved the bright colors!"
                ],
            },
            [PICK_A_PATH_NODE_2A2]: {
                'text': [
                    "<Arthur> liked his <python> <bathing_suit|bathing suit> so much, he wouldn't wear any <other|other.>"
                ],
            },
            [PICK_A_PATH_NODE_2B]: {
                'text': [
                    "After swimming with his <grandmother|grandmother,> <Arthur> wanted to put on his cozy <bathrobe> and have"
                ],
                'choices': [
                    ["a <smoothie|smoothie.>", PICK_A_PATH_NODE_2B1],
                    ["some <birthday> cake.", PICK_A_PATH_NODE_2B2],
                ]
            },
            [PICK_A_PATH_NODE_2B1]: {
                'text': [
                    "<Arthur|Arthur's> <grandmother> made the best <smoothies> - <healthy> and yummy too!"
                ],
            },
            [PICK_A_PATH_NODE_2B2]: {
                'text': [
                    "<Arthur> was going to <South_Carolina|South Carolina> for his <grandmother|grandmother's> <birthday|birthday.> He knew the cake would be delicious!"
                ],
            },
            [PICK_A_PATH_NODE_END]: {
                'text': [
                    "<Arthur> thought and thought, and at last he remembered - he was missing his stuffed <panther|panther!> He never went anywhere <without> it!"
                ],
            }
        }
    },
    'author-ethel': {  // Medial TH, Voiceless - Proficient Reader
        'name': "Ethel the Author",
        'dropdownName': "Ethel the Author (Medial TH, Voiceless)",
        'targetSounds': [TARGET_SOUND_TH],
        'positions': [POSITION_VOICELESS_MEDIAL],
        'readingLevel': PICK_A_PATH_READING_LEVEL_PROFICIENT,
        'nodes': {
            [PICK_A_PATH_NODE_START]: {
                'text': [
                    "Ethel is an author with a funny method for finding ideas. If she has nothing to write about, she takes a nap in her empty bathtub. When she wakes up, she writes a story about everything she's dreamed.",
                    "One of her favorite dream-stories was about"
                ],
                'choices': [
                    ["a silly birthday party.", PICK_A_PATH_NODE_1],
                    ["a famous athlete.", PICK_A_PATH_NODE_2],
                    ["a little earthworm.", PICK_A_PATH_NODE_3]
                ]
            },
            [PICK_A_PATH_NODE_1]: {
                'text': [
                    "This birthday party was in a bathroom! Ethel wrote about the"
                ],
                'choices': [
                    ["birthday party guests.", PICK_A_PATH_NODE_1A],
                    ["birthday cake.", PICK_A_PATH_NODE_1B],
                    ["birthday presents.", PICK_A_PATH_NODE_1C]
                ]
            },
            [PICK_A_PATH_NODE_1A]: {
                'text': [
                    "The birthday party guests used toothbrushes to eat cake and drank mouthwash from a punchbowl!"
                ],
            },
            [PICK_A_PATH_NODE_1B]: {
                'text': [
                    "The birthday cake was made out of toothpaste and served on top of the toilet!"
                ],
            },
            [PICK_A_PATH_NODE_1C]: {
                'text': [
                    "The birthday presents included a box of toothpicks, a filthy bathmat, and a pet panther!"
                ],
            },
            [PICK_A_PATH_NODE_2]: {
                'text': [
                    "Ethel wrote this story about a famous athlete named Ethan who"
                ],
                'choices': [
                    ["ran a silly marathon.", PICK_A_PATH_NODE_2A],
                    ["could lift anything.", PICK_A_PATH_NODE_2B],
                    ["swam a mile without breathing.", PICK_A_PATH_NODE_2C]
                ]
            },
            [PICK_A_PATH_NODE_2A]: {
                'text': [
                    "Ethan ran the marathon without shoes while wearing a fluffy bathrobe!"
                ],
            },
            [PICK_A_PATH_NODE_2B]: {
                'text': [
                    "Ethan carried an entire fifth-grade class on his shoulders without help!"
                ],
            },
            [PICK_A_PATH_NODE_2C]: {
                'text': [
                    "Ethan had been taking monthly swim lessons for over twenty-three years!"
                ],
            },
            [PICK_A_PATH_NODE_3]: {
                'text': [
                    "The earthworm was named Matthew, and he"
                ],
                'choices': [
                    ["dreamed of being a python.", PICK_A_PATH_NODE_3A],
                    ["collected toothpicks.", PICK_A_PATH_NODE_3B],
                    ["lived at the North Pole.", PICK_A_PATH_NODE_3C]
                ]
            },
            [PICK_A_PATH_NODE_3A]: {
                'text': [
                    "Matthew tried everything he could to become as big as a python. He ate the healthiest soil and crawled for thirty-three minutes each day."
                ],
            },
            [PICK_A_PATH_NODE_3B]: {
                'text': [
                    "So far Matthew had sixty-three toothpicks in his collection. He kept them in his bathroom closet."
                ],
            },
            [PICK_A_PATH_NODE_3C]: {
                'text': [
                    "Matthew had to buy pre-thawed soil at the North Pole Market, and he never left home without his warmest hat!"
                ],
            },
            [PICK_A_PATH_NODE_END]: {
                'text': [
                    "What do you think about Ethel's writing method?"
                ],
            },
        }
    },
    'beths-tooth': {  // Final TH, Voiceless - Read-Together
        'name': "Beth Loses a Tooth",
        'dropdownName': "Beth Loses a Tooth (Final TH, Voiceless)",
        'targetSounds': [TARGET_SOUND_TH],
        'positions': [POSITION_VOICELESS_FINAL],
        'readingLevel': PICK_A_PATH_READING_LEVEL_EASY,
        'vocab': [
            ['bath'],
            ['birdbath'],
            ['both'],
            ['broth'],
            ['cloth'],
            ['earth'],
            ['math'],
            ['moth'],
            ['mouth'],
            ['path'],
            ['sleuth'],
            ['tablecloth'],
            ['tooth'],
            ['underneath'],
        ],
        'nodes': {
            [PICK_A_PATH_NODE_START]: {
                'text': [
                    "Beth had a wiggly <tooth|tooth.> She liked to wiggle it back and forth with her tongue. One day she started to wiggle the <tooth|tooth,> and it"
                ],
                'choices': [
                    ["flew out of her <mouth|mouth.>", PICK_A_PATH_NODE_1],
                    ["wasn't in her <mouth|mouth.>", PICK_A_PATH_NODE_2],
                ]
            },
            [PICK_A_PATH_NODE_1]: {
                'text': [
                    "The <tooth> flew right out of her <mouth|mouth!> \"What on <earth|earth?\"> thought Beth. She wondered where her <tooth> had landed. She looked <underneath> the"
                ],
                'choices': [
                    ["<tablecloth|tablecloth.>", PICK_A_PATH_NODE_1A],
                    ["<birdbath|birdbath.>", PICK_A_PATH_NODE_1B],
                ]
            },
            [PICK_A_PATH_NODE_1A]: {
                'text': [
                    "Beth lifted the <tablecloth> and peeked <underneath|underneath.> She found"
                ],
                'choices': [
                    ["a <tooth|tooth.>", PICK_A_PATH_NODE_1A1],
                    ["no <tooth|tooth.>", PICK_A_PATH_NODE_1A2],
                ]
            },
            [PICK_A_PATH_NODE_1A1]: {
                'text': [
                    "Beth shouted with joy. \"My <tooth|tooth!\">",
                    "She wrapped the <tooth> in a piece of <cloth> and put it under her pillow with care."
                ],
            },
            [PICK_A_PATH_NODE_1A2]: {
                'text': [
                    "Beth didn't see a <tooth> <underneath> the <tablecloth|tablecloth.> She felt the empty space in her <mouth> and sighed with sadness."
                ],
            },
            [PICK_A_PATH_NODE_1B]: {
                'text': [
                    "Beth walked down the <path> and looked <underneath> the <birdbath|birdbath.> She found"
                ],
                'choices': [
                    ["a <tooth|tooth.>", PICK_A_PATH_NODE_1B1],
                    ["no <tooth|tooth.>", PICK_A_PATH_NODE_1B2],
                ]
            },
            [PICK_A_PATH_NODE_1B1]: {
                'text': [
                    "Beth couldn't believe her <tooth> had flown such a far length! She picked up her <tooth> and followed the <path> home."
                ],
            },
            [PICK_A_PATH_NODE_1B2]: {
                'text': [
                    "Beth saw a <moth> <underneath> the <birdbath|birdbath,> but no <tooth|tooth.> With a tear in her eye, she walked back up the <path|path.>"
                ],
            },
            [PICK_A_PATH_NODE_2]: {
                'text': [
                    "The <tooth> was missing from her <mouth|mouth!> \"When did I lose my <tooth|tooth?\"> asked Beth. She thought about the things she'd done earlier that day. Maybe the <tooth> had fallen into her"
                ],
                'choices': [
                    ["bubble <bath|bath.>", PICK_A_PATH_NODE_2A],
                    ["chicken <broth|broth.>", PICK_A_PATH_NODE_2B],
                ]
            },
            [PICK_A_PATH_NODE_2A]: {
                'text': [
                    "Beth had been wiggling her <tooth> during her bubble <bath|bath.> She went back to look and found"
                ],
                'choices': [
                    ["a <tooth|tooth.>", PICK_A_PATH_NODE_2A1],
                    ["no <tooth|tooth.>", PICK_A_PATH_NODE_2A2],
                ]
            },
            [PICK_A_PATH_NODE_2A1]: {
                'text': [
                    "Beth ran to the <bath|bath.> She looked around and found her <tooth> <underneath> a washcloth!"
                ],
            },
            [PICK_A_PATH_NODE_2A2]: {
                'text': [
                    "Beth used <both> hands to search the <bath|bath,> but she didn't find her <tooth|tooth.> She sat on the floor with a frown."
                ],
            },
            [PICK_A_PATH_NODE_2B]: {
                'text': [
                    "Beth had eaten a bowl of chicken <broth> while working on her <math> homework. She checked the bowl and saw"
                ],
                'choices': [
                    ["a <tooth|tooth.>", PICK_A_PATH_NODE_2B1],
                    ["no <tooth|tooth.>", PICK_A_PATH_NODE_2B2],
                ]
            },
            [PICK_A_PATH_NODE_2B1]: {
                'text': [
                    "Beth checked the leftover <broth> and shouted with glee. Her <tooth> was at the bottom of the bowl."
                ],
            },
            [PICK_A_PATH_NODE_2B2]: {
                'text': [
                    "Beth didn't find her <tooth> in her <broth> bowl. She sat down and finished her <broth> with a slurp and a burp."
                ],
            },
            [PICK_A_PATH_NODE_END]: {
                'text': [
                    "Don't you think Beth would make a great <tooth> <sleuth|sleuth?>"
                ],
            }
        }
    },
    'mammoth-gwyneth': {  // Final TH, Voiceless - Proficient Reader
        'name': "A Mammoth Named Gwyneth",
        'dropdownName': "A Mammoth Named Gwyneth (Final TH, Voiceless)",
        'targetSounds': [TARGET_SOUND_TH],
        'positions': [POSITION_VOICELESS_FINAL],
        'readingLevel': PICK_A_PATH_READING_LEVEL_PROFICIENT,
        'nodes': {
            [PICK_A_PATH_NODE_START]: {
                'text': [
                    "Gwyneth was a woolly mammoth who liked filling her days with lots of activity. One particular day she decided it would be fun to"
                ],
                'choices': [
                    ["make some broth.", PICK_A_PATH_NODE_1],
                    ["learn some math.", PICK_A_PATH_NODE_2],
                    ["be a sleuth.", PICK_A_PATH_NODE_3]
                ]
            },
            [PICK_A_PATH_NODE_1]: {
                'text': [
                    "Gwyneth loved homemade veggie broth. Her mouth watered just thinking about it! She started by"
                ],
                'choices': [
                    ["following a path.", PICK_A_PATH_NODE_1A],
                    ["spreading a tablecloth.", PICK_A_PATH_NODE_1B],
                    ["calling her friend Seth.", PICK_A_PATH_NODE_1C]
                ]
            },
            [PICK_A_PATH_NODE_1A]: {
                'text': [
                    "The path led Gwyneth past a birdbath and into the garden, where she dug in the earth for carrots, onions, and turnips."
                ],
            },
            [PICK_A_PATH_NODE_1B]: {
                'text': [
                    "Both making and eating the broth would be messy, and Gwyneth wanted to keep the table underneath nice and safe."
                ],
            },
            [PICK_A_PATH_NODE_1C]: {
                'text': [
                    "Seth was a sloth who loved vegetable broth as much as Gwyneth. And he could use his sharp claws to help with potato peeling!"
                ],
            },
            [PICK_A_PATH_NODE_2]: {
                'text': [
                    "Gwyneth always had fun with numbers. She thought it would be nice to learn how to work some math problems. So she"
                ],
                'choices': [
                    ["called her friend Beth.", PICK_A_PATH_NODE_2A],
                    ["sat down with a math book.", PICK_A_PATH_NODE_2B],
                    ["went outside with her ruler.", PICK_A_PATH_NODE_2C]
                ]
            },
            [PICK_A_PATH_NODE_2A]: {
                'text': [
                    "Beth was great at math. Gwyneth offered to read Beth her favorite myth in return for some help with math."
                ],
            },
            [PICK_A_PATH_NODE_2B]: {
                'text': [
                    "Gwyneth had found an old math book inside a phone booth. She took a deep breath, opened the math book, and started to study."
                ],
            },
            [PICK_A_PATH_NODE_2C]: {
                'text': [
                    "Gwyneth measured both the length and width of the garden path and added those numbers together."
                ],
            },
            [PICK_A_PATH_NODE_3]: {
                'text': [
                    "Gwyneth was a curious mammoth and had a few mysteries to solve. On this day she wanted to find"
                ],
                'choices': [
                    ["a hidden path.", PICK_A_PATH_NODE_3A],
                    ["her missing baby tooth.", PICK_A_PATH_NODE_3B],
                    ["a length of time.", PICK_A_PATH_NODE_3C]
                ]
            },
            [PICK_A_PATH_NODE_3A]: {
                'text': [
                    "Gwyneth had heard a myth about a secret path. Everyone said it was a shortcut to the North Pole!"
                ],
            },
            [PICK_A_PATH_NODE_3B]: {
                'text': [
                    "Gwyneth couldn't remember what she'd done with her tooth - had the Tusk Fairy been to visit?"
                ],
            },
            [PICK_A_PATH_NODE_3C]: {
                'text': [
                    "Gwyneth wanted to know the length of time it took Seth the sloth to get to the garden and back. He was much slower than Gwyneth!"
                ],
            },
            [PICK_A_PATH_NODE_END]: {
                'text': [
                    "At the end of her day, Gwyneth was exhausted but happy. \"My hard work was worth it,\" she said. She spent the rest of the evening curled up with a book and a cup of broth."
                ],
            },
        }
    },
}

export function GetPickAPathStories() {
    return pickAPathStories
}

export function GetPickAPathReadingLevel(story) {
    const storyInfo = pickAPathStories[story]

    if (storyInfo) {
        return storyInfo['readingLevel']
    }

    return PICK_A_PATH_READING_LEVEL_INVALID
}

export function GetPickAPathStoriesFromSoundAndReadingLevel(sound, readingLevel) {
    let matchingStories = {}

    for (let storyKey of Object.keys(pickAPathStories)) {
        const storyInfo = pickAPathStories[storyKey]

        if ((readingLevel === storyInfo['readingLevel']) || (readingLevel === PICK_A_PATH_READING_LEVEL_ALL)) {
            for (let storyTargetSound of storyInfo['targetSounds']) {
                if (storyTargetSound === sound) {
                    matchingStories[storyKey] = storyInfo
                    break
                }
            }
        }
    }

    return matchingStories
}

export function GetPickAPathStoriesFromSoundAndPositions(sound, positions) {
    let matchingStories = {}

    for (let storyKey of Object.keys(pickAPathStories)) {
        const storyInfo = pickAPathStories[storyKey]

        if (storyInfo['targetSounds'].includes(sound)) {
            for (let position of positions) {
                if (storyInfo['positions'].includes(position)) {
                    matchingStories[storyKey] = storyInfo
                    break
                }
            }
        }
    }

    return matchingStories
}

export function DoSoundAndPositionsHavePickAPathStories(sound, positions) {
    return (Object.keys(GetPickAPathStoriesFromSoundAndPositions(sound, positions)).length > 0)
}

const _pickAPathStoryTemplates = {
    'easy-reader-template': {
        'name': "",
        'dropdownName': "",
        'targetSounds': [],
        'positions': [],
        'readingLevel': PICK_A_PATH_READING_LEVEL_EASY,
        'vocab': [
            [''],
        ],
        'nodes': {
            [PICK_A_PATH_NODE_START]: {
                'text': [
                    ""
                ],
                'choices': [
                    ["", PICK_A_PATH_NODE_1],
                    ["", PICK_A_PATH_NODE_2],
                ]
            },
            [PICK_A_PATH_NODE_1]: {
                'text': [
                    ""
                ],
                'choices': [
                    ["", PICK_A_PATH_NODE_1A],
                    ["", PICK_A_PATH_NODE_1B],
                ]
            },
            [PICK_A_PATH_NODE_1A]: {
                'text': [
                    ""
                ],
                'choices': [
                    ["", PICK_A_PATH_NODE_1A1],
                    ["", PICK_A_PATH_NODE_1A2],
                ]
            },
            [PICK_A_PATH_NODE_1A1]: {
                'text': [
                    ""
                ],
            },
            [PICK_A_PATH_NODE_1A2]: {
                'text': [
                    ""
                ],
            },
            [PICK_A_PATH_NODE_1B]: {
                'text': [
                    ""
                ],
                'choices': [
                    ["", PICK_A_PATH_NODE_1B1],
                    ["", PICK_A_PATH_NODE_1B2],
                ]
            },
            [PICK_A_PATH_NODE_1B1]: {
                'text': [
                    ""
                ],
            },
            [PICK_A_PATH_NODE_1B2]: {
                'text': [
                    ""
                ],
            },
            [PICK_A_PATH_NODE_2]: {
                'text': [
                    ""
                ],
                'choices': [
                    ["", PICK_A_PATH_NODE_2A],
                    ["", PICK_A_PATH_NODE_2B],
                ]
            },
            [PICK_A_PATH_NODE_2A]: {
                'text': [
                    ""
                ],
                'choices': [
                    ["", PICK_A_PATH_NODE_2A1],
                    ["", PICK_A_PATH_NODE_2A2],
                ]
            },
            [PICK_A_PATH_NODE_2A1]: {
                'text': [
                    ""
                ],
            },
            [PICK_A_PATH_NODE_2A2]: {
                'text': [
                    ""
                ],
            },
            [PICK_A_PATH_NODE_2B]: {
                'text': [
                    ""
                ],
                'choices': [
                    ["", PICK_A_PATH_NODE_2B1],
                    ["", PICK_A_PATH_NODE_2B2],
                ]
            },
            [PICK_A_PATH_NODE_2B1]: {
                'text': [
                    ""
                ],
            },
            [PICK_A_PATH_NODE_2B2]: {
                'text': [
                    ""
                ],
            },
            [PICK_A_PATH_NODE_END]: {
                'text': [
                    ""
                ],
            }
        }
    },
    'proficient-reader-template': {
        'name': "",
        'dropdownName': "",
        'targetSounds': [],
        'positions': [],
        'readingLevel': PICK_A_PATH_READING_LEVEL_PROFICIENT,
        'nodes': {
            [PICK_A_PATH_NODE_START]: {
                'text': [
                    ""
                ],
                'choices': [
                    ["", PICK_A_PATH_NODE_1],
                    ["", PICK_A_PATH_NODE_2],
                    ["", PICK_A_PATH_NODE_3]
                ]
            },
            [PICK_A_PATH_NODE_1]: {
                'text': [
                    ""
                ],
                'choices': [
                    ["", PICK_A_PATH_NODE_1A],
                    ["", PICK_A_PATH_NODE_1B],
                    ["", PICK_A_PATH_NODE_1C]
                ]
            },
            [PICK_A_PATH_NODE_1A]: {
                'text': [
                    ""
                ],
            },
            [PICK_A_PATH_NODE_1B]: {
                'text': [
                    ""
                ],
            },
            [PICK_A_PATH_NODE_1C]: {
                'text': [
                    ""
                ],
            },
            [PICK_A_PATH_NODE_2]: {
                'text': [
                    ""
                ],
                'choices': [
                    ["", PICK_A_PATH_NODE_2A],
                    ["", PICK_A_PATH_NODE_2B],
                    ["", PICK_A_PATH_NODE_2C]
                ]
            },
            [PICK_A_PATH_NODE_2A]: {
                'text': [
                    ""
                ],
            },
            [PICK_A_PATH_NODE_2B]: {
                'text': [
                    ""
                ],
            },
            [PICK_A_PATH_NODE_2C]: {
                'text': [
                    ""
                ],
            },
            [PICK_A_PATH_NODE_3]: {
                'text': [
                    ""
                ],
                'choices': [
                    ["", PICK_A_PATH_NODE_3A],
                    ["", PICK_A_PATH_NODE_3B],
                    ["", PICK_A_PATH_NODE_3C]
                ]
            },
            [PICK_A_PATH_NODE_3A]: {
                'text': [
                    ""
                ],
            },
            [PICK_A_PATH_NODE_3B]: {
                'text': [
                    ""
                ],
            },
            [PICK_A_PATH_NODE_3C]: {
                'text': [
                    ""
                ],
            },
            [PICK_A_PATH_NODE_END]: {
                'text': [
                    ""
                ],
            },
        }
    },
}
