import { useState, useCallback, useContext, useEffect } from 'react'
import { Card, Image, Container } from 'react-bootstrap'
import PropTypes from 'prop-types'
import { WhimprovsContext, WhimprovsListener } from '../context/WhimprovsContext'
import { GetSiteImageUrl } from '../project/ProjectResources'
import './WhimprovsComponents.css'
import './WordCardComponent.css'

export function WhimprovsTitleComponent() {
    const { storyInfo } = useContext(WhimprovsContext)
    const [storyName, SetStoryName] = useState('')

    const HandleWhimprovsContextChange = useCallback(
        _listenerString => {
            if (storyInfo) {
                SetStoryName(storyInfo['name'])
            }
        }, [storyInfo] // eslint-disable-line react-hooks/exhaustive-deps
    )

    return (
        <>
            <WhimprovsListener SendContextChangeNotification={HandleWhimprovsContextChange} />
            <div className='whimprovs-title'>
                {storyName}
            </div >
        </>
    )
}

export function WhimprovsWordChoiceComponent() {
    const [stringifiedContext, SetStringifiedContext] = useState('')

    useEffect(() => { }, [stringifiedContext])

    const HandleContextChange = useCallback(
        listenerString => {
            SetStringifiedContext(listenerString)
        }, [] // eslint-disable-line react-hooks/exhaustive-deps
    )

    const { storyInfo, choiceGroup, choiceKeys, choiceIndex } = useContext(WhimprovsContext)

    const GetWordChoiceCards = () => {
        if (!storyInfo || (choiceIndex >= choiceKeys.length)) {
            return <></>
        }

        const choiceGroupInfo = storyInfo['choiceGroups'][choiceGroup]
        const choiceWords = choiceGroupInfo[choiceKeys[choiceIndex]]

        const wordCards = choiceWords.map(wordChoice => (
            <WhimprovsWordChoiceCardComponent key={wordChoice[0]} wordChoice={wordChoice} />
        ))

        return wordCards
    }

    return (
        <>
            <WhimprovsListener SendContextChangeNotification={HandleContextChange} />
            <h3 className='mt-2'>Choose one of these words:</h3>
            <Container>
                <div className='whimprovs-choice-card-div'>
                    {GetWordChoiceCards()}
                </div>
            </Container>
        </>
    )
}

export function WhimprovsWordChoiceCardComponent({ wordChoice }) {
    const { choiceKeys, choiceIndex, SetChoiceIndex, wordChoices, SetWordChoices } = useContext(WhimprovsContext)

    const wordKey = wordChoice[0]
    const wordText = wordChoice[1] || wordKey
    const newWordChoice = [wordKey, wordText]

    return (
        <Card className='whimprovs-choice-word-card word-card bg-light'
            onClick={() => {
                if (choiceIndex >= choiceKeys.length) {
                    return
                }

                let newWordChoices = wordChoices
                newWordChoices[choiceKeys[choiceIndex]] = newWordChoice
                SetWordChoices(newWordChoices)
                SetChoiceIndex(choiceIndex + 1)
            }}>
            <div className='word-card-image-div vstack mx-auto my-0'>
                <Image fluid className='word-card-image word-card-image-active'
                    src={GetWhimprovsWordImageUrl(wordKey)} alt={wordText} />
            </div>
            <h5 className='word-card-text text-center my-0 p-0'>{wordText}</h5>
        </Card>
    )
}

WhimprovsWordChoiceCardComponent.propTypes = {
    wordChoice: PropTypes.object.isRequired
}

function GetWhimprovsWordImageUrl(wordKey) {
    let fileName = wordKey.replace(/ /g, '_')

    if (fileName.search(/~/) === -1) {
        fileName += ('~' + wordKey.length.toString())
    }

    fileName += '.svg'

    return 'https://leveluparticstorage.blob.core.windows.net/silly-stories-word-images/' + fileName
}

export function WhimprovsPassageComponent() {
    const [stringifiedContext, SetStringifiedContext] = useState('')

    useEffect(() => { }, [stringifiedContext])

    const HandleContextChange = useCallback(
        listenerString => {
            SetStringifiedContext(listenerString)
        }, [] // eslint-disable-line react-hooks/exhaustive-deps
    )

    const { storyInfo, passageIndex, SetPassageIndex, wordChoices } = useContext(WhimprovsContext)

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [passageIndex])

    return (
        <>
            <WhimprovsListener SendContextChangeNotification={HandleContextChange} />
            <Container className='whimprovs-passage-container'>
                {storyInfo['passages'][passageIndex].map((passageText, index) => (
                    <div className='d-flex flex-wrap mt-2 mb-4' key={index}>
                        {ImagifyWhimprovsPassageText(passageText, wordChoices)}
                    </div>
                ))}
            </Container>
            <Container className='whimprovs-fwd-rev-buttons-container'>
                <div className='d-flex flex-wrap mt-2 mb-4'>
                    <WhimprovsFwdRevComponent className='mx-0'
                        direction='REV'
                        showComponent={passageIndex > 0}
                        DoAction={() => SetPassageIndex(passageIndex - 1)}
                    />
                    <WhimprovsFwdRevComponent className='mx-0'
                        direction='FWD'
                        showComponent={passageIndex < (storyInfo['passages'].length - 1)}
                        DoAction={() => SetPassageIndex(passageIndex + 1)}
                    />
                </div>
            </Container>
        </>
    )
}

function ImagifyWhimprovsPassageText(passageText, wordChoices) {
    let unimagifiedText = passageText
    let imagifiedJsxElements = []
    let imagifiedJsxKey = 0

    while (unimagifiedText.length > 0) {
        let left_delimiter_position = unimagifiedText.search(/</)

        if (left_delimiter_position === -1) {
            const splitText = unimagifiedText.split(/\s+/)

            for (let chunk of splitText) {
                imagifiedJsxElements.push(
                    <p className='whimprovs-text-chunk' key={imagifiedJsxKey++}>
                        {chunk}
                    </p>
                )
            }

            unimagifiedText = ''
        } else {
            let pipe_position = unimagifiedText.search(/\|/)
            let right_delimiter_position = unimagifiedText.search(/>/)

            let choiceCode = ''
            let wordKey = ''
            let wordText = ''

            if (pipe_position === -1 || pipe_position > right_delimiter_position) {
                choiceCode = unimagifiedText.substring(left_delimiter_position + 1, right_delimiter_position)
                wordKey = wordChoices[choiceCode][0]
                wordText = wordChoices[choiceCode][1]
            }
            else {
                choiceCode = unimagifiedText.substring(left_delimiter_position + 1, pipe_position)
                wordKey = wordChoices[choiceCode][0]
                wordText = unimagifiedText.substring(pipe_position + 1, right_delimiter_position)
                wordText = wordText.replace(choiceCode, wordChoices[choiceCode][1])
            }

            if (left_delimiter_position > 0) {
                const splitText = unimagifiedText.substring(0, left_delimiter_position).split(/\s+/)

                for (let chunk of splitText) {
                    imagifiedJsxElements.push(
                        <p className='whimprovs-text-chunk' key={imagifiedJsxKey++}>
                            {chunk}
                        </p>
                    )
                }
            }

            imagifiedJsxElements.push(
                <WhimprovsWordCardComponent wordKey={wordKey} wordText={wordText} key={imagifiedJsxKey++} />
            )

            unimagifiedText = unimagifiedText.substring(right_delimiter_position + 1)
        }
    }

    return <>{imagifiedJsxElements}</>
}

export function WhimprovsWordCardComponent({ wordKey, wordText }) {
    return (
        <>
            <Card className='whimprovs-word-card bg-light'>
                <div className='whimprovs-word-card-image-div vstack mx-auto my-0'>
                    <Image fluid className='whimprovs-word-card-image' src={GetWhimprovsWordImageUrl(wordKey)} alt={wordKey} />
                </div>
                <h5 className='whimprovs-word-card-text text-center my-0 p-0'>{wordText}</h5>
            </Card>
        </>
    )
}

WhimprovsWordCardComponent.propTypes = {
    wordKey: PropTypes.string.isRequired,
    wordText: PropTypes.string.isRequired,
}

function WhimprovsFwdRevComponent({ direction, showComponent, DoAction }) {
    let srcImage = ''
    let altText = ''

    if (direction === 'FWD') {
        srcImage = `caret-right-square-fill-dark-orange.svg`
        altText = 'FWD'
    } else if (direction === 'REV') {
        srcImage = `caret-left-square-fill-dark-orange.svg`
        altText = 'REV'
    } else {
        return <></>
    }

    let imageClass = 'whimprovs-fwd-rev-image'

    if (!showComponent) {
        imageClass += ' invisible'
    }

    return (
        <Image fluid className={imageClass}
            src={GetSiteImageUrl(srcImage)} alt={altText}
            onClick={() => DoAction()} />
    )
}

WhimprovsFwdRevComponent.propTypes = {
    direction: PropTypes.string.isRequired,
    showComponent: PropTypes.bool.isRequired,
    DoAction: PropTypes.func.isRequired,
}
