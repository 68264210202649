import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { LANDING_PATH, START_PATH, SUBSCRIPTION_PATH } from '../App'
import { IsUserLoggedIn } from '../state/UserAuthState'
import { IsUserSubscribed } from '../state/SubscriptionState'

export function HomePage() {
    const navigate = useNavigate()

    useEffect(() => {
        if (IsUserSubscribed()) {
            navigate(START_PATH)
        }
        else if (IsUserLoggedIn()) {
            navigate(SUBSCRIPTION_PATH)
        }
        else {
            navigate(LANDING_PATH)
        }
    }, [navigate])

    return <></>
}
