import { useState, useEffect, createContext, useContext } from 'react'
import { SUBSCRIPTION_TYPE_MONTH } from '../components/SubscriptionComponents'

export const LoginSignupContext = createContext()

export const LoginSignupProvider = ({ children }) => {
    const [pageFreeze, SetPageFreeze] = useState(false)
    const [activationMessageVisibility, SetActivationMessageVisibility] = useState(false)
    const [instructionsModalVisibility, SetInstructionsModalVisibility] = useState(false)
    const [initialSubscriptionType, SetInitialSubscriptionType] = useState(SUBSCRIPTION_TYPE_MONTH)

    return (
        <LoginSignupContext.Provider
            value={{
                pageFreeze, SetPageFreeze,
                activationMessageVisibility, SetActivationMessageVisibility,
                instructionsModalVisibility, SetInstructionsModalVisibility,
                initialSubscriptionType, SetInitialSubscriptionType,
            }}
        >
            {children}
        </LoginSignupContext.Provider>
    )
}

export function LoginSignupListener({ SendContextChangeNotification }) {
    const context = useContext(LoginSignupContext)
    const [stringifiedContext, SetStringifiedContext] = useState('')

    useEffect(() => {
        SendContextChangeNotification(stringifiedContext)
    }, [stringifiedContext, SendContextChangeNotification])

    useEffect(() => {
        SetStringifiedContext(JSON.stringify(context))
    }, [context])

    return <></>
}
