// Search and replace: [’] [‘] [“] [”] [\"  ] [.  ] [?  ] [!  ] [,  ] [>  ]

const WHIMPROVS_CHOICE_GROUP_CH = 'Whimprovs/ChoiceGroup/CH'
const WHIMPROVS_CHOICE_GROUP_F = 'Whimprovs/ChoiceGroup/F'
const WHIMPROVS_CHOICE_GROUP_G = 'Whimprovs/ChoiceGroup/G'
const WHIMPROVS_CHOICE_GROUP_J = 'Whimprovs/ChoiceGroup/J'
const WHIMPROVS_CHOICE_GROUP_K = 'Whimprovs/ChoiceGroup/K'
const WHIMPROVS_CHOICE_GROUP_L = 'Whimprovs/ChoiceGroup/L'
const WHIMPROVS_CHOICE_GROUP_L_BLEND = 'Whimprovs/ChoiceGroup/LBlend'
const WHIMPROVS_CHOICE_GROUP_R_PREVOCALIC = 'Whimprovs/ChoiceGroup/RPrevocalic'
const WHIMPROVS_CHOICE_GROUP_R_VOCALIC = 'Whimprovs/ChoiceGroup/RVocalic'
const WHIMPROVS_CHOICE_GROUP_R_BLEND = 'Whimprovs/ChoiceGroup/RBlend'
const WHIMPROVS_CHOICE_GROUP_S = 'Whimprovs/ChoiceGroup/S'
const WHIMPROVS_CHOICE_GROUP_S_BLEND = 'Whimprovs/ChoiceGroup/SBlend'
const WHIMPROVS_CHOICE_GROUP_SH = 'Whimprovs/ChoiceGroup/SH'
const WHIMPROVS_CHOICE_GROUP_TH = 'Whimprovs/ChoiceGroup/TH'
const WHIMPROVS_CHOICE_GROUP_V = 'Whimprovs/ChoiceGroup/V'
const WHIMPROVS_CHOICE_GROUP_Z = 'Whimprovs/ChoiceGroup/Z'

export function GetWhimprovsChoiceGroups() {
    return [ WHIMPROVS_CHOICE_GROUP_CH, WHIMPROVS_CHOICE_GROUP_F, WHIMPROVS_CHOICE_GROUP_G, WHIMPROVS_CHOICE_GROUP_J,
             WHIMPROVS_CHOICE_GROUP_K, WHIMPROVS_CHOICE_GROUP_L, WHIMPROVS_CHOICE_GROUP_L_BLEND,
             WHIMPROVS_CHOICE_GROUP_R_PREVOCALIC, WHIMPROVS_CHOICE_GROUP_R_VOCALIC, WHIMPROVS_CHOICE_GROUP_R_BLEND,
             WHIMPROVS_CHOICE_GROUP_S, WHIMPROVS_CHOICE_GROUP_S_BLEND, WHIMPROVS_CHOICE_GROUP_SH,
             WHIMPROVS_CHOICE_GROUP_TH, WHIMPROVS_CHOICE_GROUP_V, WHIMPROVS_CHOICE_GROUP_Z ]
}

export function GetWhimprovsChoiceGroupText(choiceGroup) {
    switch (choiceGroup) {
        case WHIMPROVS_CHOICE_GROUP_CH:
            return 'CH'

        case WHIMPROVS_CHOICE_GROUP_F:
            return 'F'

        case WHIMPROVS_CHOICE_GROUP_G:
            return 'G'

        case WHIMPROVS_CHOICE_GROUP_J:
            return 'J'

        case WHIMPROVS_CHOICE_GROUP_K:
            return 'K'

        case WHIMPROVS_CHOICE_GROUP_L:
            return 'L'

        case WHIMPROVS_CHOICE_GROUP_L_BLEND:
            return 'L Blends'

        case WHIMPROVS_CHOICE_GROUP_R_PREVOCALIC:
            return 'R Prevocalic'

        case WHIMPROVS_CHOICE_GROUP_R_VOCALIC:
            return 'R Vocalic'

        case WHIMPROVS_CHOICE_GROUP_R_BLEND:
            return 'R Blends'

        case WHIMPROVS_CHOICE_GROUP_S:
            return 'S'

        case WHIMPROVS_CHOICE_GROUP_S_BLEND:
            return 'S Blends'

        case WHIMPROVS_CHOICE_GROUP_SH:
            return 'SH'

        case WHIMPROVS_CHOICE_GROUP_TH:
            return 'TH'

        case WHIMPROVS_CHOICE_GROUP_V:
            return 'V'

        case WHIMPROVS_CHOICE_GROUP_Z:
            return 'Z'

        default:
            return ''
    }
}

const whimprovsStories = {
    'cookies': {
        'name': 'Cookies',
        'choiceGroups': {
            [WHIMPROVS_CHOICE_GROUP_CH]: {
                'A': [['chalk'], ['adventure'], ['itch']],
                'B': [['lunch'], ['spinach'], ['beach']],
                'C': [['archery'], ['furniture'], ['cheeseburger']],
                'D': [['checkers'], ['chopsticks'], ['potato chips']],
                'E': [['cheetah'], ['children'], ['cheddar cheese']],
                'F': [['cheerful'], ['chilly']],
                'G': [['chasing'], ['chuckle', 'chuckling'], ['hatching']],
                'H': [['teach'], ['smooch'], ['scratch']],
                'I': [['crunchy'], ['cheery']],
                'J': [['crutches'], ['roaches'], ['branches']],
                'K': [['ketchup'], ['peaches'], ['sandwiches']],
                'L': [['chicken'], ['cherry pie'], ['french fries']],
            },
            [WHIMPROVS_CHOICE_GROUP_F]: {
                'A': [['fish'], ['fireworks'], ['safari']],
                'B': [['fire'], ['fever'], ['football']],
                'C': [['coffee'], ['snowfall'], ['campfire']],
                'D': [['Swedish Fish'], ['swim fins'], ['headphones']],
                'E': [['coral reef'], ['giraffe'], ['stuff']],
                'F': [['fabulous'], ['colorful'], ['rough']],
                'G': [['fishing'], ['laughing']],
                'H': [['surf'], ['sniff'], ['laugh']],
                'I': [['full'], ['filthy'], ['funny']],
                'J': [['starfish'], ['faces'], ['furniture']],
                'K': [['goldfish'], ['seafood'], ['breakfast']],
                'L': [['ant farm'], ['rainfall'], ['elephant']],
            },
            [WHIMPROVS_CHOICE_GROUP_G]: {
                'A': [['golf'], ['gossip'], ['eggplant']],
                'B': [['asparagus'], ['gas'], ['Doug']],
                'C': [['guinea pig'], ['goblin'], ['price tag']],
                'D': [['geese'], ['gumdrops']],
                'E': [['goat'], ['igloo'], ['egg']],
                'F': [['good'], ['snug'], ['gigantic']],
                'G': [['giggling'], ['jogging'], ['hugging']],
                'H': [['snuggle'], ['wag'], ['dig']],
                'I': [['regular'], ['goofy']],
                'J': [['goldfish'], ['spaghetti']],
                'K': [['garbage'], ['gum'], ['giggling']],
                'L': [['bug'], ['iceberg'], ['sled dog']],
            },
            [WHIMPROVS_CHOICE_GROUP_J]: {
                'A': [['giraffe'], ['juice'], ['magic']],
                'B': [['cabbage'], ['sausage'], ['sponge']],
                'C': [['banjo'], ['flapjack'], ['apple juice']],
                'D': [['edges'], ['pages'], ['vegetables']],
                'E': [['jackrabbit'], ['hedgehog'], ['pigeon']],
                'F': [['jealous'], ['joyous'], ['orange']],
                'G': [['merge', 'merging'], ['jogging'], ['juggling']],
                'H': [['imagine'], ['jump'], ['plunge']],
                'I': [['gigantic'], ['fragile'], ['dangerous']],
                'J': [['germs'], ['gymnastics'], ['luggage']],
                'K': [['garbage'], ['jeans'], ['jellybeans']],
                'L': [['courage'], ['jelly'], ['cottage cheese']],
            },
            [WHIMPROVS_CHOICE_GROUP_K]: {
                'A': [['candy'], ['camp'], ['soccer']],
                'B': [['cake'], ['smoke'], ['circus']],
                'C': [['acorn'], ['pumpkin'], ['kitten']],
                'D': [['freckles'], ['sneakers']],
                'E': [['karate'], ['coffee'], ['New York']],
                'F': [['cool'], ['icky'], ['lucky']],
                'G': [['raking'], ['sneaking'], ['hiking']],
                'H': [['cough'], ['smirk'], ['bark']],
                'I': [['dark'], ['spooky'], ['sick']],
                'J': [['keys'], ['camels']],
                'K': [['caterpillar'], ['glue stick'], ['carrot']],
                'L': [['taco'], ['popcorn'], ['ketchup']],
            },
            [WHIMPROVS_CHOICE_GROUP_L]: {
                'A': [['lettuce'], ['light'], ['land']],
                'B': [['licorice'], ['pickle'], ['elbow']],
                'C': [['toilet'], ['eyelash'], ['caterpillar']],
                'D': [['jellybeans'], ['lips'], ['long sleeves']],
                'E': [['doll'], ['fossil'], ['lawnmower']],
                'F': [['loud'], ['polite'], ['silly']],
                'G': [['sailing'], ['bowling'], ['smiling']],
                'H': [['sizzle'], ['recycle'], ['whistle']],
                'I': [['loose'], ['ill'], ['lucky']],
                'J': [['school'], ['cereal'], ['rainfall']],
                'K': [['lollipop'], ['seashell'], ['meatball']],
                'L': [['salad'], ['melon'], ['helicopter']],
            },
            [WHIMPROVS_CHOICE_GROUP_L_BLEND]: {
                'A': [['applause'], ['kleenex'], ['blue']],
                'B': [['Florida'], ['home plate'], ['playground']],
                'C': [['tablet'], ['blimp'], ['blob']],
                'D': [['flip-flops'], ['sunglasses'], ['blades']],
                'E': [['globe'], ['igloo'], ['glove']],
                'F': [['flashy'], ['floppy'], ['playful']],
                'G': [['nibbling'], ['flapping'], ['giggling']],
                'H': [['glow'], ['clap'], ['float']],
                'I': [['plump'], ['ugly'], ['flat']],
                'J': [['blocks'], ['glasses'], ['cleats']],
                'K': [['flute'], ['flapjack'], ['floor lamp']],
                'L': [['applause'], ['kleenex'], ['floss']],
            },
            [WHIMPROVS_CHOICE_GROUP_R_PREVOCALIC]: {
                'A': [['rainfall'], ['rest'], ['rain']],
                'B': [['rocket'], ['rhino'], ['raisin']],
                'C': [['rag'], ['racquet'], ['robot']],
                'D': [['radishes'], ['ringlets'], ['rocks']],
                'E': [['reef'], ['rabbit'], ['rowboat']],
                'F': [['rocky'], ['rough'], ['ridiculous']],
                'G': [['reading'], ['relaxing'], ['racing']],
                'H': [['repeat'], ['reach'], ['ride']],
                'I': [['red'], ['rainy'], ['ready']],
                'J': [['rope'], ['ribbon'], ['recess']],
                'K': [['road'], ['raccoon'], ['rat']],
                'L': [['runway'], ['reptile'], ['rainbow']],
            },
            [WHIMPROVS_CHOICE_GROUP_R_VOCALIC]: {
                'A': [['licorice'], ['spider'], ['sea turtle']],
                'B': [['horse'], ['cork'], ['story']],
                'C': [['earlobe'], ['beard'], ['cereal']],
                'D': [['flippers'], ['stairs'], ['starfish']],
                'E': [['fireball'], ['hammer'], ['falling star']],
                'F': [['unfair'], ['boring'], ['sparkly']],
                'G': [['smearing'], ['snoring'], ['coloring']],
                'H': [['carve'], ['fear'], ['irritate']],
                'I': [['important'], ['cheery'], ['serious']],
                'J': [['germs'], ['fireworks'], ['ear buds']],
                'K': [['carrot'], ['fairy'], ['nightmare']],
                'L': [['bonfire'], ['choir'], ['parrot']],
            },
            [WHIMPROVS_CHOICE_GROUP_R_BLEND]: {
                'A': [['brick'], ['umbrella'], ['toothbrush']],
                'B': [['dragon'], ['drum'], ['laundry basket']],
                'C': [['frog'], ['frisbee'], ['frying pan']],
                'D': [['gumdrops'], ['braces'], ['crumbs']],
                'E': [['eyebrow'], ['trumpet'], ['crayon']],
                'F': [['creepy'], ['crispy'], ['prickly']],
                'G': [['dribbling'], ['growing'], ['drawing']],
                'H': [['dream'], ['fry'], ['growl']],
                'I': [['crunchy'], ['greasy'], ['fruity']],
                'J': [['cough drops'], ['spacecraft'], ['trash']],
                'K': [['fruit salad'], ['tricycle'], ['trophy']],
                'L': [['grape'], ['grandpa'], ['grass snake']],
            },
            [WHIMPROVS_CHOICE_GROUP_S]: {
                'A': [['summertime'], ['baseball'], ['Paris']],
                'B': [['castle'], ['fossil'], ['pencil']],
                'C': [['salad'], ['seashell'], ['surfboard']],
                'D': [['cats'], ['grapes'], ['lips']],
                'E': [['receipt'], ['iceberg'], ['dinosaur']],
                'F': [['silly'], ['sorry'], ['fancy']],
                'G': [['guessing'], ['chasing'], ['hissing']],
                'H': [['sizzle'], ['whistle'], ['recycle']],
                'I': [['salty'], ['bossy'], ['jealous']],
                'J': [['dice'], ['raindrops'], ['hats']],
                'K': [['soap'], ['sofa'], ['sandal']],
                'L': [['moose'], ['lighthouse'], ['rhinoceros']],
            },
            [WHIMPROVS_CHOICE_GROUP_S_BLEND]: {
                'A': [['sled'], ['slipper'], ['sleeping bag']],
                'B': [['slug'], ['snowball'], ['spoon']],
                'C': [['swamp'], ['sweatshirt'], ['Swiss cheese']],
                'D': [['sleeves'], ['swim fins'], ['chopsticks']],
                'E': [['scooter'], ['smoothie'], ['snorkel']],
                'F': [['smart'], ['scary'], ['smoky']],
                'G': [['sledding'], ['snooping'], ['swimming']],
                'H': [['swallow'], ['disturb'], ['snore']],
                'I': [['smooth'], ['slow'], ['sloppy']],
                'J': [['whiskers'], ['classmates'], ['snow']],
                'K': [['spud'], ['spinner'], ['spider']],
                'L': [['snail'], ['snapping turtle'], ['snow globe']],
            },
            [WHIMPROVS_CHOICE_GROUP_SH]: {
                'A': [['sheep'], ['shower'], ['shovel']],
                'B': [['shoelace'], ['tissue'], ['radish']],
                'C': [['shin'], ['shampoo'], ['shoulder']],
                'D': [['shakers'], ['shellfish'], ['snowshoes']],
                'E': [['shamrock'], ['milkshake'], ['eyelash']],
                'F': [['fresh'], ['shy'], ['shiny']],
                'G': [['brushing'], ['fishing'], ['shopping']],
                'H': [['splash'], ['smash'], ['flush']],
                'I': [['British'], ['shallow'], ['mushy']],
                'J': [['snowshoes'], ['shampoo'], ['nail polish']],
                'K': [['dish'], ['mustache'], ['toothbrush']],
                'L': [['shark'], ['rocket ship'], ['fish']],
            },
            [WHIMPROVS_CHOICE_GROUP_TH]: {
                'A': [['thread'], ['toothpaste'], ['thief']],
                'B': [['tablecloth'], ['birdbath'], ['throat']],
                'C': [['baby tooth'], ['bubble bath'], ['sloth']],
                'D': [['teeth'], ['locksmiths'], ['things']],
                'E': [['thermos'], ['throw rug'], ['theater']],
                'F': [['thirteen'], ['thankful'], ['filthy']],
                'G': [['breathing'], ['bathing'], ['thinking']],
                'H': [['slither'], ['gather'], ['throw']],
                'I': [['healthy'], ['thirsty'], ['thirty']],
                'J': [['anything'], ['something'], ['nothing']],
                'K': [['python'], ['birthday party'], ['toothbrush']],
                'L': [['thumbtack'], ['bathtub'], ['earthworm']],
            },
            [WHIMPROVS_CHOICE_GROUP_V]: {
                'A': [['violin'], ['shovel'], ['beehive']],
                'B': [['vine'], ['vampire'], ['vase']],
                'C': [['Valentines Day', "Valentine's Day"], ['violet'], ['video']],
                'D': [['voices'], ['sleeves'], ['work gloves']],
                'E': [['olive'], ['stove'], ['dove']],
                'F': [['vanilla'], ['nervous'], ['brave']],
                'G': [['give', 'giving'], ['shave', 'shaving'], ['visit', 'visiting']],
                'H': [['skydive'], ['drive'], ['wave']],
                'I': [['heavy'], ['expensive'], ['venomous']],
                'J': [['adventure'], ['lava'], ['travel']],
                'K': [['river'], ['driveway'], ['silver']],
                'L': [['victory'], ['vitamin'], ['vacation']],
            },
            [WHIMPROVS_CHOICE_GROUP_Z]: {
                'A': [['zipper'], ['zombie'], ['xylophone']],
                'B': [['puzzle'], ['blizzard'], ['freezer']],
                'C': [['zoo'], ['zip line'], ['zebra']],
                'D': [['bees'], ['keys'], ['shoes']],
                'E': [['cousin'], ['closet'], ['wizard']],
                'F': [['zany'], ['cozy'], ['fuzzy']],
                'G': [['visit', 'visiting'], ['squeeze', 'squeezing']],
                'H': [['amaze'], ['sneeze'], ['snooze']],
                'I': [['zesty'], ['dizzy'], ['fizzy']],
                'J': [['toes'], ['glasses'], ['knees']],
                'K': [['kazoo'], ['buzzer'], ['lizard']],
                'L': [['maze'], ['nose'], ['bruise']],
            },
        },
        'passages': [
            ["Grandma's <A> cookies are my favorite, and they're so easy to make. Here's the recipe:",
                "     \u2022 1 cup of <A|A,>", "     \u2022 2 teaspoons of <B|B,>", "     \u2022 a dash of <C|C,>", "     \u2022 3 <D|D,>", "     \u2022 and 1 stick of <E|E.>"],
            ["Melt the <E> in a bowl. Mix this with the <A> until it is <F> and fluffy. Add the <B> and <C|C.> Then add the <D|D,> one at a time, <G> well in between. Finally, add the flour."],
            ["Once the dough is mixed, <H> it in the refrigerator for 30 minutes. Preheat your oven to 350 degrees."],
            ["Roll out the chilled dough until it is nice and <I|I,> then cut the dough into shapes (I like mine in the shape of hearts or <J|J),> then put them on a cookie sheet. Bake for 10 minutes."],
            ["These cookies are best enjoyed with a mug of <K> or a glass of cold <L|L.>"]
        ]
    },
    'my-new-pet': {
        'name': 'My New Pet',
        'choiceGroups': {
            [WHIMPROVS_CHOICE_GROUP_CH]: {
                'A': [['chess'], ['archery'], ['hopscotch']],
                'B': [['cheeseburger'], ['lunchbox'], ['sandwich']],
                'C': [['cheerful'], ['chilly']],
                'D': [['chasing'], ['reaching'], ['itching']],
                'E': [['chuckling'], ['hatching'], ['cheering']],
                'F': [['cherry pie'], ['marching band'], ['grouch']],
                'G': [['checkers'], ['chopsticks'], ['potato chips']],
                'H': [['beachball'], ['french fries'], ['statue']],
                'I': [['checked'], ['chewed'], ['chose']],
                'J': [['ostrich'], ['lunch'], ['spinach']],
                'K': [['sketch'], ['crunch'], ['scratch']],
                'L': [['treasure chest'], ['checkerboard'], ['chimney']],
                'M': [['cheery'], ['crunchy']],
                'N': [['cockroach'], ['peach'], ['stopwatch']],
                'O': [['keychain'], ['screech owl'], ['chocolate chip']],
                'P': [['switch'], ['snatch'], ['smooch']],
                'Q': [['teach'], ['reach'], ['fetch']],
            },
            [WHIMPROVS_CHOICE_GROUP_F]: {
                'A': [['traffic'], ['breakfast'], ['golf']],
                'B': [['ferry'], ['ferris wheel'], ['foot']],
                'C': [['fabulous'], ['colorful'], ['fluffy']],
                'D': [['fishing'], ['laughing']],
                'E': [['full'], ['fast']],
                'F': [['daffodil'], ['lifeguard'], ['campfire']],
                'G': [['hoof'], ['leaf'], ['knife']],
                'H': [['fire truck'], ['face'], ['fossil']],
                'I': [['check off', 'checked off'], ['cough', 'coughed']],
                'J': [['goldfish'], ['muffin'], ['sofa']],
                'K': [['farm'], ['laugh']],
                'L': [['coral reef'], ['scarf'], ['bookshelf']],
                'M': [['terrific'], ['difficult'], ['cheerful']],
                'N': [['phone'], ['faucet'], ['fishbowl']],
                'O': [['waffle'], ['microphone'], ['starfish']],
                'P': [['slip', 'fall'], ['surf']],
                'Q': [['find'], ['sniff']],
            },
            [WHIMPROVS_CHOICE_GROUP_G]: {
                'A': [['golf'], ['Oregon'], ['Flag Day']],
                'B': [['goose'], ['guitar'], ['garden']],
                'C': [['good'], ['regular'], ['snug']],
                'D': [['guessing'], ['beginning'], ['going']],
                'E': [['giggling'], ['jogging'], ['hugging']],
                'F': [['hamburger'], ['goblin'], ['Lego']],
                'G': [['geese'], ['gumdrops'], ['goldfish']],
                'H': [['pig'], ['bookbag'], ['price tag']],
                'I': [['goofed'], ['wiggled'], ['gossipped']],
                'J': [['goat'], ['igloo'], ['egg']],
                'K': [['give'], ['ignore'], ['brag']],
                'L': [['goal'], ['gas station'], ['goblet']],
                'M': [['bigger'], ['gigantic'], ['goofy']],
                'N': [['beagle'], ['triangle'], ['lifeguard']],
                'O': [['ladybug'], ['slug'], ['log']],
                'P': [['drag'], ['go'], ['snuggle']],
                'Q': [['dig'], ['hug'], ['wag']],
            },
            [WHIMPROVS_CHOICE_GROUP_J]: {
                'A': [['Japan'], ['Georgia'], ['Egypt']],
                'B': [['jug'], ['jacket'], ['gingersnap']],
                'C': [['jealous'], ['joyous'], ['orange']],
                'D': [['juggling'], ['urge', 'urging']],
                'E': [['merge', 'merging'], ['jogging']],
                'F': [['cabbage'], ['sausage'], ['sponge']],
                'G': [['germs'], ['jeans'], ['jellybeans']],
                'H': [['banjo'], ['flapjack'], ['apple juice']],
                'I': [['injure', 'injured'], ['apologize', 'apologized']],
                'J': [['bridge'], ['bandage'], ['message']],
                'K': [['jump'], ['damage'], ['imagine']],
                'L': [['gym'], ['jet'], ['jungle']],
                'M': [['gigantic'], ['fragile'], ['dangerous']],
                'N': [['pickle jar'], ['jack o lantern', "jack o'lantern"], ['genius']],
                'O': [['ninja'], ['fire engine'], ['cottage']],
                'P': [['arrange'], ['plunge']],
                'Q': [['challenge'], ['charge']],
            },
            [WHIMPROVS_CHOICE_GROUP_K]: {
                'A': [['camp'], ['Kansas'], ['karate']],
                'B': [['caboose'], ['cookie jar'], ['comb']],
                'C': [['cool'], ['icky'], ['dark']],
                'D': [['hiking'], ['raking'], ['looking']],
                'E': [['looking'], ['sneaking'], ['smirking']],
                'F': [['acorn'], ['pumpkin'], ['taco']],
                'G': [['pocket'], ['coin'], ['brick']],
                'H': [['sock'], ['bookmark'], ['glue stick']],
                'I': [['carried'], ['kissed'], ['kicked']],
                'J': [['coffee'], ['circus'], ['earache']],
                'K': [['leak'], ['lick'], ['like']],
                'L': [['cone'], ['castle'], ['kitchen']],
                'M': [['lucky'], ['spooky'], ['sick']],
                'N': [['hurricane'], ['helicopter'], ['turkey']],
                'O': [['earache'], ['fork'], ['flapjack']],
                'P': [['smirk'], ['speak'], ['snack']],
                'Q': [['cough'], ['smoke'], ['bark']],
            },
            [WHIMPROVS_CHOICE_GROUP_L]: {
                'A': [['lunch'], ['Alaska'], ['downhill']],
                'B': [['lump'], ['lollipop'], ['lime']],
                'C': [['loose'], ['loud'], ['lucky']],
                'D': [['sailing'], ['bowling'], ['smiling']],
                'E': [['listening'], ['smelling'], ['yelling']],
                'F': [['toilet'], ['eyelash'], ['caterpillar']],
                'G': [['jellybeans'], ['umbrella'], ['marshmallow']],
                'H': [['lettuce'], ['lizard'], ['lawnmower']],
                'I': [['licked'], ['laughed'], ['relaxed']],
                'J': [['ladder'], ['elbow'], ['seashell']],
                'K': [['sizzle'], ['recycle'], ['whistle']],
                'L': [['salad'], ['melon'], ['helicopter']],
                'M': [['silly'], ['delicious'], ['polite']],
                'N': [['walrus'], ['canteloupe'], ['belly']],
                'O': [['fossil'], ['doll'], ['pickle']],
                'P': [['deliver'], ['belong'], ['allow']],
                'Q': [['lock'], ['listen'], ['leak']],
            },
            [WHIMPROVS_CHOICE_GROUP_L_BLEND]: {
                'A': [['Florida'], ['hang gliding'], ['home plate']],
                'B': [['tablet'], ['blimp'], ['blob']],
                'C': [['navy blue'], ['flashy'], ['ugly']],
                'D': [['nibbling'], ['babbling'], ['giggling']],
                'E': [['floating'], ['flapping'], ['climbing']],
                'F': [['flute'], ['flapjack'], ['floor lamp']],
                'G': [['flip-flops'], ['sunglasses'], ['cleats']],
                'H': [['globe'], ['igloo'], ['glove']],
                'I': [['cleaned'], ['clapped'], ['bloomed']],
                'J': [['snowblower'], ['playground'], ['paper clip']],
                'K': [['glide'], ['glow'], ['clap']],
                'L': [['clock'], ['club'], ['cloud']],
                'M': [['plump'], ['floppy'], ['playful']],
                'N': [['plate'], ['plant'], ['plum']],
                'O': [['blue jay'], ['flagpole'], ['glacier']],
                'P': [['floss'], ['float'], ['flicker']],
                'Q': [['bleat'], ['blurt'], ['complain']],
            },
            [WHIMPROVS_CHOICE_GROUP_R_PREVOCALIC]: {
                'A': [['recess'], ['rest'], ['racing']],
                'B': [['rake'], ['ring'], ['rock']],
                'C': [['rocky'], ['rough'], ['ridiculous']],
                'D': [['reading'], ['relaxing'], ['racing']],
                'E': [['running'], ['rhyming'], ['writing']],
                'F': [['rag'], ['racquet'], ['robot']],
                'G': [['rectangle'], ['wrench'], ['radishes']],
                'H': [['rocket'], ['rhino'], ['raisin']],
                'I': [['rescued'], ['ran'], ['rang']],
                'J': [['rice'], ['rabbit'], ['rope']],
                'K': [['repeat'], ['reach'], ['ride']],
                'L': [['rodeo'], ['reef'], ['ranch']],
                'M': [['red'], ['rainy'], ['ready']],
                'N': [['rattlesnake'], ['rainbow'], ['rolling pin']],
                'O': [['rowboat'], ['room'], ['reptile']],
                'P': [['race'], ['rain'], ['rush']],
                'Q': [['run'], ['recycle'], ['rest']],
            },
            [WHIMPROVS_CHOICE_GROUP_R_VOCALIC]: {
                'A': [['downstairs'], ['nowhere'], ['Mars']],
                'B': [['carrot'], ['fairy'], ['nightmare']],
                'C': [['unfair'], ['boring'], ['sparkly']],
                'D': [['sharing'], ['smearing'], ['snoring']],
                'E': [['caring'], ['coloring'], ['buttering']],
                'F': [['jar'], ['candy bar'], ['starfish']],
                'G': [['earlobe'], ['beard'], ['cereal']],
                'H': [['licorice'], ['spider'], ['sea turtle']],
                'I': [['snorted'], ['fired'], ['carried']],
                'J': [['parrot'], ['fork'], ['flippers']],
                'K': [['scare'], ['parachute'], ['march']],
                'L': [['bonfire'], ['choir'], ['Roman Empire']],
                'M': [['important'], ['cheery'], ['serious']],
                'N': [['horse'], ['cork'], ['story']],
                'O': [['fireball'], ['hammer'], ['falling star']],
                'P': [['carve'], ['sparkle'], ['fear']],
                'Q': [['irritate'], ['color'], ['snort']],
            },
            [WHIMPROVS_CHOICE_GROUP_R_BLEND]: {
                'A': [['Brazil'], ['France'], ['preschool']],
                'B': [['brick'], ['umbrella'], ['toothbrush']],
                'C': [['creepy'], ['crispy'], ['prickly']],
                'D': [['crumbling'], ['growing'], ['drawing']],
                'E': [['dribbling'], ['crossing'], ['crackling']],
                'F': [['dragon'], ['drum'], ['laundry basket']],
                'G': [['gumdrops'], ['braces'], ['crumbs']],
                'H': [['frog'], ['frisbee'], ['frying pan']],
                'I': [['drank'], ['grew'], ['dreamed']],
                'J': [['eyebrow'], ['trumpet'], ['fruit salad']],
                'K': [['drool'], ['break'], ['grin']],
                'L': [['crayon'], ['spacecraft'], ['moose crossing']],
                'M': [['crunchy'], ['greasy'], ['fruity']],
                'N': [['grape'], ['grandpa'], ['grass snake']],
                'O': [['tricycle'], ['trophy'], ['pretzel']],
                'P': [['dream'], ['fry'], ['growl']],
                'Q': [['croak'], ['print'], ['trip']],
            },
            [WHIMPROVS_CHOICE_GROUP_S]: {
                'A': [['summertime'], ['baseball'], ['Paris']],
                'B': [['castle'], ['fossil'], ['pencil']],
                'C': [['silly'], ['sorry'], ['fancy']],
                'D': [['guessing'], ['missing'], ['hissing']],
                'E': [['pacing'], ['flossing'], ['chasing']],
                'F': [['salad'], ['seashell'], ['surfboard']],
                'G': [['dice'], ['raindrops'], ['hats']],
                'H': [['soap'], ['sofa'], ['sandal']],
                'I': [['sat'], ['sang'], ['said']],
                'J': [['seaweed'], ['ice cream'], ['voice']],
                'K': [['sizzle'], ['whistle'], ['recycle']],
                'L': [['receipt'], ['iceberg'], ['dinosaur']],
                'M': [['salty'], ['bossy'], ['jealous']],
                'N': [['moose'], ['bookcase'], ['lighthouse']],
                'O': [['mason jar'], ['popsicle'], ['rhinoceros']],
                'P': [['toss'], ['erase'], ['practice']],
                'Q': [['misplace'], ['purchase'], ['salute']],
            },
            [WHIMPROVS_CHOICE_GROUP_S_BLEND]: {
                'A': [['snack'], ['outer space'], ['gymnastics']],
                'B': [['sled'], ['slipper'], ['sleeping bag']],
                'C': [['smart'], ['scary'], ['smoky']],
                'D': [['sledding'], ['snooping'], ['swimming']],
                'E': [['sleeting'], ['sleeping'], ['swinging']],
                'F': [['slug'], ['snowball'], ['spoon']],
                'G': [['sleeves'], ['swim fins'], ['chopsticks']],
                'H': [['swamp'], ['sweatshirt'], ['Swiss cheese']],
                'I': [['spelled'], ['spilled'], ['sneaked']],
                'J': [['scooter'], ['smoothie'], ['snorkel']],
                'K': [['sweep'], ['spin'], ['smile']],
                'L': [['stage'], ['bus stop'], ['mistake']],
                'M': [['smooth'], ['slow'], ['sloppy']],
                'N': [['spud'], ['spinner'], ['spider']],
                'O': [['snail'], ['snapping turtle'], ['snow globe']],
                'P': [['swipe'], ['misspell'], ['sneeze']],
                'Q': [['swallow'], ['disturb'], ['snore']],
            },
            [WHIMPROVS_CHOICE_GROUP_SH]: {
                'A': [['shearing'], ['vacation'], ['Washington']],
                'B': [['sheep'], ['shower'], ['shovel']],
                'C': [['fresh'], ['shy'], ['shiny']],
                'D': [['gushing'], ['fishing'], ['shopping']],
                'E': [['shaking'], ['wishing'], ['brushing']],
                'F': [['shoelace'], ['tissue'], ['radish']],
                'G': [['shakers'], ['shellfish'], ['snowshoes']],
                'H': [['shin'], ['shampoo'], ['shoulder']],
                'I': [['shopped'], ['shook'], ['shut']],
                'J': [['shamrock'], ['milkshake'], ['eyelash']],
                'K': [['shout'], ['shine'], ['shave']],
                'L': [['mushroom'], ['parachute'], ['marshmallow']],
                'M': [['British'], ['shallow'], ['mushy']],
                'N': [['dish'], ['mustache'], ['toothbrush']],
                'O': [['shark'], ['rocket ship'], ['fish']],
                'P': [['share'], ['wash'], ['blush']],
                'Q': [['splash'], ['smash'], ['flush']],
            },
            [WHIMPROVS_CHOICE_GROUP_TH]: {
                'A': [['third base'], ['Athens'], ['fifth grade']],
                'B': [['thread'], ['throat'], ['thief']],
                'C': [['thirteen'], ['thankful'], ['filthy']],
                'D': [['breathing'], ['bathing'], ['thinking']],
                'E': [['thanking'], ['thawing']],
                'F': [['thumbtack'], ['thing'], ['earthworm']],
                'G': [['toothpick'], ['math homework'], ['teeth']],
                'H': [['baby tooth'], ['bubble bath'], ['sloth']],
                'I': [['thought'], ['threw']],
                'J': [['thermos'], ['throw rug'], ['theater']],
                'K': [['teethe'], ['bathe'], ['soothe']],
                'L': [['mouth'], ['bathtub'], ['throne']],
                'M': [['healthy'], ['thirsty'], ['thirty']],
                'N': [['tablecloth'], ['birdbath'], ['locksmith']],
                'O': [['python'], ['birthday party'], ['toothbrush']],
                'P': [['thunder'], ['throw'], ['breathe']],
                'Q': [['gather'], ['slither'], ['rethink']],
            },
            [WHIMPROVS_CHOICE_GROUP_V]: {
                'A': [['Venus'], ['Virginia'], ['Venice']],
                'B': [['volcano'], ['valentine'], ['violin']],
                'C': [['vanilla'], ['nervous'], ['brave']],
                'D': [['give', 'giving'], ['shave', 'shaving']],
                'E': [['swerve', 'swerving'], ['visit', 'visiting']],
                'F': [['vine'], ['vampire'], ['vase']],
                'G': [['four leaf clover'], ['sleeves'], ['work gloves']],
                'H': [['olive'], ['beehive'], ['stove']],
                'I': [['vote', 'voted'], ['veering', 'veered']],
                'J': [['river'], ['oven'], ['driveway']],
                'K': [['wave'], ['skydive']],
                'L': [['valley'], ['vitamin'], ['volleyball']],
                'M': [['heavy'], ['expensive'], ['venomous']],
                'N': [['TV'], ['driver'], ['carnival']],
                'O': [['cave'], ['boxing glove'], ['relative']],
                'P': [['carve'], ['drive']],
                'Q': [['give'], ['misbehave']],
            },
            [WHIMPROVS_CHOICE_GROUP_Z]: {
                'A': [['Zambia'], ['Zimbabwe'], ['Tuesday']],
                'B': [['zipper'], ['zombie'], ['xylophone']],
                'C': [['zany'], ['cozy'], ['fuzzy']],
                'D': [['visit', 'visiting'], ['squeeze', 'squeezing']],
                'E': [['buzz', 'buzzing'], ['gaze', 'gazing']],
                'F': [['raisin'], ['pretzel'], ['daisy']],
                'G': [['bees'], ['keys'], ['shoes']],
                'H': [['kazoo'], ['buzzer'], ['lizard']],
                'I': [['chose'], ['doze', 'dozed']],
                'J': [['zoo'], ['zip line'], ['zebra']],
                'K': [['zoom'], ['amaze'], ['raise']],
                'L': [['puzzle'], ['blizzard'], ['freezer']],
                'M': [['zesty'], ['dizzy'], ['fizzy']],
                'N': [['maze'], ['nose'], ['bruise']],
                'O': [['cousin'], ['closet'], ['wizard']],
                'P': [['zigzag'], ['sneeze'], ['snooze']],
                'Q': [['sizzle'], ['freeze'], ['surprise']],
            },
        },
        'passages': [
            ["Today started off like any other day, but when I got home from <A> there was a big <B> sitting in the middle of the kitchen. It had <C> holes in the top and it was rattling and <D|D.>"],
            ["Suddenly I heard a <E> sound coming from inside the <B|B.> I ran to it and pulled off the lid, and there was an adorable <F> looking up at me!"],
            ["\"Hi!\" I said, and reached in to scratch its <G|G.> It wagged its <H> and <I> in a circle. I picked the <F> up and gave it a big hug. It licked me all over my <J|J!>"],
            ["My new <F> isn't allowed to <K> in my bed, so I used soft blankets and pillows to make a <L> in the corner of my room. It will be nice and <M> there."],
            ["But before bedtime I want to take my <F> outside to play. I can't wait to teach it to fetch a <N> and catch a <O|O!>"],
            ["I can't believe I finally have a <F> of my own. I'm so happy, I want to <P> and <Q> with joy!"]
        ]
    },
    'seasons': {
        'name': 'Seasons',
        'choiceGroups': {
            [WHIMPROVS_CHOICE_GROUP_CH]: {
                'A': [['cheerful'], ['chilly'], ['crunchy']],
                'B': [['checkers'], ['chopsticks'], ['potato chips']],
                'C': [['cheetah'], ['chain'], ['chimney']],
                'D': [['peaches'], ['roaches'], ['branches']],
                'E': [['chicken'], ['furniture'], ['cheeseburger']],
                'F': [['children'], ['french fries'], ['sandwiches']],
                'G': [['ketchup'], ['chipmunk'], ['cheddar cheese']],
                'H': [['chasing'], ['reaching'], ['itching']],
                'I': [['chuckling'], ['hatching'], ['cheering']],
                'J': [['spinach'], ['stopwatch'], ['lunch']],
                'K': [['match'], ['grouch'], ['ostrich']],
                'L': [['teacher'], ['kitchen'], ['question']],
                'M': [['corn chips'], ['cherries'], ['Swiss cheese']],
            },
            [WHIMPROVS_CHOICE_GROUP_F]: {
                'A': [['filthy'], ['fluffy'], ['funny']],
                'B': [['swim fins'], ['headphones'], ['goldfish']],
                'C': [['daffodil'], ['lifeguard'], ['campfire']],
                'D': [['starfish'], ['fireworks'], ['coffee']],
                'E': [['golf'], ['waffle'], ['fossil']],
                'F': [['finger'], ['sofa'], ['bookshelf']],
                'G': [['loaf'], ['seafood'], ['farm']],
                'H': [['fishing'], ['slip', 'falling']],
                'I': [['surf', 'surfing'], ['laughing']],
                'J': [['fork'], ['traffic'], ['stuff']],
                'K': [['foot'], ['trophy'], ['elf']],
                'L': [['fairy'], ['elephant'], ['chef']],
                'M': [['furniture'], ['Swedish Fish'], ['fire chief']],
            },
            [WHIMPROVS_CHOICE_GROUP_G]: {
                'A': [['gigantic'], ['snug'], ['goofy']],
                'B': [['spaghetti'], ['gum']],
                'C': [['egg'], ['guinea pig'], ['price tag']],
                'D': [['geese'], ['gossip']],
                'E': [['hamburger'], ['guitar'], ['Lego']],
                'F': [['garbage'], ['mug'], ['finger']],
                'G': [['golf'], ['asparagus'], ['gas']],
                'H': [['snuggling'], ['jogging']],
                'I': [['hugging'], ['giggling']],
                'J': [['garage'], ['wagon'], ['bookbag']],
                'K': [['dragon'], ['pig'], ['ghost']],
                'L': [['dog'], ['kangaroo'], ['goblin']],
                'M': [['gumdrops'], ['goldfish']],
            },
            [WHIMPROVS_CHOICE_GROUP_J]: {
                'A': [['jealous'], ['fragile'], ['dangerous']],
                'B': [['bridge'], ['bandage'], ['message']],
                'C': [['jackrabbit'], ['hedgehog'], ['pigeon']],
                'D': [['germs'], ['gymnastics'], ['luggage']],
                'E': [['cabbage'], ['sausage'], ['sponge']],
                'F': [['edges'], ['pages'], ['vegetables']],
                'G': [['courage'], ['jelly'], ['cottage cheese']],
                'H': [['juggling'], ['urge', 'urging']],
                'I': [['merge', 'merging'], ['jogging']],
                'J': [['gym'], ['jet'], ['jungle']],
                'K': [['banjo'], ['flapjack'], ['apple juice']],
                'L': [['ninja'], ['fire engine'], ['cottage']],
                'M': [['pickle jar'], ['jack o lantern', "jack o'lantern"], ['genius']],
            },
            [WHIMPROVS_CHOICE_GROUP_K]: {
                'A': [['icky'], ['spooky'], ['sick']],
                'B': [['keys'], ['coffee']],
                'C': [['acorn'], ['pumpkin'], ['taco']],
                'D': [['cabbage'], ['camels']],
                'E': [['sock'], ['bookmark'], ['glue stick']],
                'F': [['freckles'], ['sneakers']],
                'G': [['coal'], ['broccoli'], ['plastic']],
                'H': [['looking'], ['hiking']],
                'I': [['raking'], ['sneaking'], ['smirking']],
                'J': [['caboose'], ['bacon'], ['truck']],
                'K': [['cobra'], ['soccer'], ['sidewalk']],
                'L': [['kitten'], ['pumpkin'], ['flapjack']],
                'M': [['corn'], ['macaroni'], ['lipstick']],
            },
            [WHIMPROVS_CHOICE_GROUP_L]: {
                'A': [['lazy'], ['delicious'], ['lucky']],
                'B': [['jellybeans'], ['lips'], ['lemon juice']],
                'C': [['walrus'], ['toilet'], ['salad']],
                'D': [['lettuce'], ['cereal'], ['chocolate']],
                'E': [['doll'], ['lollipop'], ['lawnmower']],
                'F': [['canteloupe'], ['mail'], ['skeleton']],
                'G': [['licorice'], ['pickle'], ['elbow']],
                'H': [['sailing'], ['bowling'], ['smiling']],
                'I': [['listening'], ['smelling'], ['yelling']],
                'J': [['lion'], ['calendar'], ['meatball']],
                'K': [['lump'], ['umbrella'], ['snail']],
                'L': [['ladder'], ['elephant'], ['baseball']],
                'M': [['lightning'], ['long sleeves'], ['love']],
            },
            [WHIMPROVS_CHOICE_GROUP_L_BLEND]: {
                'A': [['ugly'], ['flashy'], ['floppy']],
                'B': [['flip-flops'], ['sunglasses'], ['cleats']],
                'C': [['tablet'], ['blimp'], ['plum']],
                'D': [['blocks'], ['glasses'], ['pliers']],
                'E': [['snorkel'], ['nosebleed'], ['applause']],
                'F': [['blades'], ['floss'], ['clothes']],
                'G': [['clover'], ['flamingo'], ['glue']],
                'H': [['nibbling'], ['flapping'], ['giggling']],
                'I': [['floating'], ['babbling'], ['climbing']],
                'J': [['blob'], ['cloud'], ['waffle']],
                'K': [['problem'], ['flu'], ['necklace']],
                'L': [['kleenex'], ['goblin'], ['flute']],
                'M': [['black sheep'], ['people'], ['alarm clock']],
            },
            [WHIMPROVS_CHOICE_GROUP_R_PREVOCALIC]: {
                'A': [['ridiculous'], ['red'], ['rainy']],
                'B': [['rice'], ['rain']],
                'C': [['rocket'], ['rhino'], ['raisin']],
                'D': [['ribbon'], ['rainfall']],
                'E': [['rag'], ['racquet'], ['robot']],
                'F': [['ringlets'], ['rocks']],
                'G': [['rest'], ['recess'], ['road']],
                'H': [['reading'], ['relaxing'], ['racing']],
                'I': [['running'], ['rhyming'], ['writing']],
                'J': [['rake'], ['ring'], ['ranch']],
                'K': [['raccoon'], ['rope'], ['rattlesnake']],
                'L': [['rooster'], ['rainbow'], ['reef']],
                'M': [['radishes'], ['rodeo']],
            },
            [WHIMPROVS_CHOICE_GROUP_R_VOCALIC]: {
                'A': [['important'], ['sparkly'], ['serious']],
                'B': [['flippers'], ['stairs'], ['starfish']],
                'C': [['scorpion'], ['iron'], ['beard']],
                'D': [['corn'], ['germs'], ['fireworks']],
                'E': [['fireball'], ['sea horse'], ['skateboard']],
                'F': [['ear buds'], ['stairs'], ['hair']],
                'G': [['chicken wire'], ['stories']],
                'H': [['sharing'], ['smearing'], ['snoring']],
                'I': [['caring'], ['coloring'], ['buttering']],
                'J': [['forest'], ['firehouse'], ['cafeteria']],
                'K': [['butter'], ['acorn'], ['vampire']],
                'L': [['dancer'], ['dinosaur'], ['hero']],
                'M': [['asparagus'], ['garbage'], ['dirt']],
            },
            [WHIMPROVS_CHOICE_GROUP_R_BLEND]: {
                'A': [['creepy'], ['crispy'], ['prickly']],
                'B': [['trash'], ['crumbs'], ['grapes']],
                'C': [['brain'], ['drum'], ['spacecraft']],
                'D': [['cough drops'], ['fruit'], ['greetings']],
                'E': [['breakfast'], ['drama'], ['frisbee']],
                'F': [['gumdrops'], ['braces'], ['footprints']],
                'G': [['drool'], ['frog'], ['grass']],
                'H': [['crumbling'], ['growing'], ['drawing']],
                'I': [['dribbling'], ['crossing'], ['crackling']],
                'J': [['frosting'], ['salad dressing'], ['Grand Canyon']],
                'K': [['troll'], ['cockroach'], ['zebra']],
                'L': [['pretzel'], ['crocodile'], ['freckle']],
                'M': [['fries'], ['grandpa'], ['bread']],
            },
            [WHIMPROVS_CHOICE_GROUP_S]: {
                'A': [['salty'], ['bossy'], ['jealous']],
                'B': [['cats'], ['grapes'], ['lips']],
                'C': [['pencil'], ['moose'], ['sandal']],
                'D': [['geese'], ['sushi'], ['glasses']],
                'E': [['mason jar'], ['popsicle'], ['rhinoceros']],
                'F': [['soap'], ['raindrops'], ['hats']],
                'G': [['bats'], ['gossip'], ['sunshine']],
                'H': [['flossing'], ['guessing'], ['hissing']],
                'I': [['pacing'], ['missing'], ['chasing']],
                'J': [['cereal'], ['medicine'], ['glass']],
                'K': [['soccer'], ['fossil'], ['circus']],
                'L': [['octopus'], ['castle'], ['sister']],
                'M': [['seaweed'], ['bison'], ['dice']],
            },
            [WHIMPROVS_CHOICE_GROUP_S_BLEND]: {
                'A': [['smooth'], ['slow'], ['sloppy']],
                'B': [['sleeves'], ['swim fins'], ['chopsticks']],
                'C': [['slug'], ['snorkel'], ['spoon']],
                'D': [['whiskers'], ['classmates'], ['spaghetti']],
                'E': [['sled'], ['slipper'], ['sleeping bag']],
                'F': [['snow boots'], ['sneakers'], ['suspenders']],
                'G': [['swamp'], ['spinach'], ['starfish']],
                'H': [['sledding'], ['snooping'], ['swinging']],
                'I': [['sleeting'], ['sleeping'], ['swimming']],
                'J': [['scooter'], ['smoothie'], ['storybook']],
                'K': [['spud'], ['spinner'], ['spider']],
                'L': [['statue'], ['scuba'], ['slime']],
                'M': [['Swiss cheese'], ['stairs'], ['sports']],
            },
            [WHIMPROVS_CHOICE_GROUP_SH]: {
                'A': [['fresh'], ['shy'], ['mushy']],
                'B': [['sheep'], ['dishes'], ['trash']],
                'C': [['shoelace'], ['tissue'], ['radish']],
                'D': [['shakers'], ['shellfish'], ['shutters']],
                'E': [['eyelash'], ['fashion'], ['shell']],
                'F': [['licorice'], ['mustache'], ['toothbrush']],
                'G': [['shampoo'], ['sushi'], ['mouthwash']],
                'H': [['wishing'], ['brushing']],
                'I': [['fishing'], ['shopping'], ['gushing']],
                'J': [['nail polish'], ['car wash'], ['shopping cart']],
                'K': [['shaving cream'], ['tissue'], ['rash']],
                'L': [['shovel'], ['lotion'], ['wish']],
                'M': [['sherbet'], ['rocket ship'], ['goldfish']],
            },
            [WHIMPROVS_CHOICE_GROUP_TH]: {
                'A': [['healthy'], ['thirsty'], ['filthy']],
                'B': [['thread'], ['teeth'], ['thank you note']],
                'C': [['thermos'], ['thorn'], ['thief']],
                'D': [['toothpaste'], ['thunder'], ['thanks']],
                'E': [['thumb'], ['thermometer'], ['toothpick']],
                'F': [['math homework'], ['cloth'], ['birthday']],
                'G': [['mouthwash'], ['thread'], ['nothing']],
                'H': [['breathing'], ['bathing'], ['thinking']],
                'I': [['thanking'], ['thawing']],
                'J': [['broth'], ['bathroom'], ['theater']],
                'K': [['thumbtack'], ['earthworm'], ['earth']],
                'L': [['sleuth'], ['sloth'], ['baby tooth']],
                'M': [['breath'], ['sixth grade'], ['throw rug']],
            },
            [WHIMPROVS_CHOICE_GROUP_V]: {
                'A': [['vanilla'], ['nervous'], ['brave']],
                'B': [['vegetable'], ['vacuum'], ['video game']],
                'C': [['violin'], ['shovel'], ['beehive']],
                'D': [['voices'], ['sleeves'], ['work gloves']],
                'E': [['olive'], ['stove'], ['dove']],
                'F': [['elevator'], ['baseball glove'], ['volleyball']],
                'G': [['shaving cream'], ['Valentines Day', "Valentine's Day"], ['cave']],
                'H': [['give', 'giving'], ['visit', 'visiting']],
                'I': [['swerve', 'swerving'], ['shave', 'shaving']],
                'J': [['oven'], ['fever'], ['screwdriver']],
                'K': [['river'], ['driveway'], ['silver']],
                'L': [['vine'], ['vampire'], ['vase']],
                'M': [['adventure'], ['lava'], ['silverware']],
            },
            [WHIMPROVS_CHOICE_GROUP_Z]: {
                'A': [['zesty'], ['dizzy'], ['fizzy']],
                'B': [['bees'], ['toes'], ['shoes']],
                'C': [['raisin'], ['pretzel'], ['daisy']],
                'D': [['keys'], ['glasses'], ['knees']],
                'E': [['kazoo'], ['buzzer'], ['lizard']],
                'F': [['days'], ['woods'], ['french fries']],
                'G': [['nose'], ['breeze'], ['mayonnaise']],
                'H': [['visit', 'visiting'], ['squeeze', 'squeezing']],
                'I': [['buzz', 'buzzing'], ['gaze', 'gazing']],
                'J': [['puzzle'], ['blizzard'], ['freezer']],
                'K': [['zipper'], ['zombie'], ['xylophone']],
                'L': [['zoo'], ['zip line'], ['zebra']],
                'M': [['tools'], ['cheese'], ['clothes']],
            },
        },
        'passages': [
            ["My favorite season is winter. I love cold weather!",
                "I like to bundle up in a <A> coat, warm <B|B,> and a fuzzy <C> and go outside. I could spend all day making snow <D> and having <E> fights. I love the way the chilly air makes my <F> rosy.",
                "At the end of the day, I like going back inside and enjoying a nice cup of hot <G|G.> Don't worry - I won't forget the marshmallows!"],
            ["My best friend says summer is the best season.",
                "She likes <H> off her shoes and <I> barefoot in the grass. She loves to splash in the <J> and eat <K|K-sicles> outside.",
                "Her favorite part of the day is when the sun goes down, because she chases <L> bugs and listens to the <M> chirp.",
                "What's your favorite season?"],
        ],
    },
    'dream': {
        'name': 'A Strange Dream',
        'choiceGroups': {
            [WHIMPROVS_CHOICE_GROUP_CH]: {
                'A': [['checkers'], ['chopsticks'], ['potato chips']],
                'B': [['children'], ['peaches'], ['sandwiches']],
                'C': [['smooch'], ['crunch'], ['fetch']],
                'D': [['cheeseburger'], ['lunchbox'], ['sandwich']],
                'E': [['cherry pie'], ['marching band'], ['grouch']],
                'F': [['Archie'], ['Charlie']],
                'G': [['cheerful'], ['chilly']],
                'H': [['cockroach'], ['watch'], ['torch']],
                'I': [['teach'], ['scratch'], ['snatch']],
                'J': [['cheery'], ['crunchy']],
                'K': [['corn chips'], ['french fries'], ['cherries']],
                'L': [['reaching'], ['itching'], ['chuckling']],
                'M': [['checked'], ['chewed'], ['chose']],
                'N': [['chess'], ['archery'], ['hopscotch']],
            },
            [WHIMPROVS_CHOICE_GROUP_F]: {
                'A': [['Swedish Fish'], ['swim fins'], ['headphones']],
                'B': [['starfish'], ['faces'], ['furniture']],
                'C': [['surf'], ['laugh'], ['slip', 'fall']],
                'D': [['ferris wheel'], ['muffin'], ['coral reef']],
                'E': [['grandfather'], ['elf'], ['four leaf clover']],
                'F': [['Sophie'], ['Flo']],
                'G': [['filthy'], ['fluffy'], ['funny']],
                'H': [['fairy tale'], ['campfire'], ['leaf']],
                'I': [['find'], ['sniff'], ['blindfold']],
                'J': [['terrific'], ['difficult'], ['cheerful']],
                'K': [['goldfish'], ['seafood'], ['breakfast']],
                'L': [['fishing'], ['laughing']],
                'M': [['check off', 'checked off'], ['cough', 'coughed']],
                'N': [['fire'], ['traffic'], ['stuff']],
            },
            [WHIMPROVS_CHOICE_GROUP_G]: {
                'A': [['geese'], ['gumdrops']],
                'B': [['asparagus'], ['gas']],
                'C': [['dig'], ['hug'], ['wag']],
                'D': [['hamburger'], ['goblin'], ['Lego']],
                'E': [['pig'], ['bookbag'], ['price tag']],
                'F': [['Gus'], ['Doug']],
                'G': [['good'], ['gigantic'], ['goofy']],
                'H': [['goal'], ['gas station'], ['guitar']],
                'I': [['drag'], ['ignore'], ['snuggle']],
                'J': [['regular'], ['snug']],
                'K': [['goldfish'], ['spaghetti']],
                'L': [['giggling'], ['jogging'], ['hugging']],
                'M': [['goofed'], ['wiggled'], ['gossipped']],
                'N': [['golf'], ['igloo'], ['Flag Day']],
            },
            [WHIMPROVS_CHOICE_GROUP_J]: {
                'A': [['edges'], ['pages'], ['vegetables']],
                'B': [['gymnastics'], ['luggage'], ['magic']],
                'C': [['imagine'], ['jump'], ['plunge']],
                'D': [['cabbage'], ['sausage'], ['sponge']],
                'E': [['pickle jar'], ['jack o lantern', "jack o'lantern"], ['genius']],
                'F': [['Jason'], ['George']],
                'G': [['jealous'], ['joyous'], ['orange']],
                'H': [['bridge'], ['bandage'], ['message']],
                'I': [['challenge'], ['damage'], ['charge']],
                'J': [['gigantic'], ['fragile'], ['dangerous']],
                'K': [['germs'], ['jeans'], ['jellybeans']],
                'L': [['merge', 'merging'], ['jogging'], ['juggling']],
                'M': [['injure', 'injured'], ['apologize', 'apologized']],
                'N': [['courage'], ['language'], ['storage']],
            },
            [WHIMPROVS_CHOICE_GROUP_K]: {
                'A': [['cabbage'], ['karate'], ['coffee']],
                'B': [['cake'], ['smoke'], ['New York']],
                'C': [['cough'], ['smirk'], ['bark']],
                'D': [['acorn'], ['pumpkin'], ['taco']],
                'E': [['sock'], ['bookmark'], ['glue stick']],
                'F': [['Kate'], ['Mark']],
                'G': [['cool'], ['icky'], ['lucky']],
                'H': [['circus'], ['earache'], ['brick']],
                'I': [['like'], ['lick'], ['speak']],
                'J': [['dark'], ['spooky'], ['sick']],
                'K': [['popcorn'], ['music'], ['lipstick']],
                'L': [['hiking'], ['raking'], ['sneaking']],
                'M': [['carried'], ['kissed'], ['kicked']],
                'N': [['bacon'], ['camp'], ['Kansas']],
            },
            [WHIMPROVS_CHOICE_GROUP_L]: {
                'A': [['jellybeans'], ['lightning'], ['Alaska']],
                'B': [['lettuce'], ['light'], ['lips']],
                'C': [['sizzle'], ['recycle'], ['whistle']],
                'D': [['ladder'], ['elbow'], ['seashell']],
                'E': [['fossil'], ['lump'], ['pickle']],
                'F': [['Larry'], ['Hailey']],
                'G': [['loud'], ['polite'], ['silly']],
                'H': [['doll'], ['lollipop'], ['lawnmower']],
                'I': [['deliver'], ['leak'], ['lock']],
                'J': [['loose'], ['delicious'], ['lucky']],
                'K': [['canteloupe'], ['love'], ['long sleeves']],
                'L': [['sailing'], ['bowling'], ['smiling']],
                'M': [['licked'], ['laughed'], ['relaxed']],
                'N': [['walrus'], ['toilet'], ['salad']],
            },
            [WHIMPROVS_CHOICE_GROUP_L_BLEND]: {
                'A': [['flip-flops'], ['sunglasses'], ['cleats']],
                'B': [['blocks'], ['glasses'], ['maple syrup']],
                'C': [['clap'], ['blurt'], ['complain']],
                'D': [['flute'], ['flapjack'], ['floor lamp']],
                'E': [['clock'], ['club'], ['cloud']],
                'F': [['Flo'], ['Glen']],
                'G': [['flashy'], ['floppy'], ['playful']],
                'H': [['globe'], ['igloo'], ['glove']],
                'I': [['glide'], ['float'], ['glow']],
                'J': [['plump'], ['ugly'], ['flat']],
                'K': [['blades'], ['floss'], ['eggplant']],
                'L': [['nibbling'], ['flapping'], ['giggling']],
                'M': [['cleaned'], ['clapped'], ['bloomed']],
                'N': [['applause'], ['kleenex'], ['blue']],
            },
            [WHIMPROVS_CHOICE_GROUP_R_PREVOCALIC]: {
                'A': [['radishes'], ['rice'], ['rain']],
                'B': [['rope'], ['ribbon'], ['recess']],
                'C': [['ride'], ['reach'], ['recycle']],
                'D': [['rocket'], ['rhino'], ['raisin']],
                'E': [['rattlesnake'], ['rainbow'], ['rolling pin']],
                'F': [['Ruby'], ['Reese']],
                'G': [['ridiculous'], ['red'], ['ready']],
                'H': [['rag'], ['racquet'], ['robot']],
                'I': [['repeat'], ['race'], ['rush']],
                'J': [['rocky'], ['rainy'], ['rough']],
                'K': [['rest'], ['rainfall'], ['road']],
                'L': [['reading'], ['relaxing'], ['rhyming']],
                'M': [['rescued'], ['ran'], ['rang']],
                'N': [['rest'], ['racing'], ['room']],
            },
            [WHIMPROVS_CHOICE_GROUP_R_VOCALIC]: {
                'A': [['flippers'], ['licorice'], ['air']],
                'B': [['cereal'], ['fire'], ['stairs']],
                'C': [['carve'], ['sparkle'], ['color']],
                'D': [['jar'], ['candy bar'], ['starfish']],
                'E': [['carrot'], ['fairy'], ['nightmare']],
                'F': [['Dorothy'], ['Harrison']],
                'G': [['important'], ['sparkly'], ['serious']],
                'H': [['horse'], ['cork'], ['story']],
                'I': [['scare'], ['parachute'], ['march']],
                'J': [['unfair'], ['boring'], ['cheery']],
                'K': [['syrup'], ['ear buds'], ['wire']],
                'L': [['sharing'], ['smearing'], ['snoring']],
                'M': [['snorted'], ['fired'], ['carried']],
                'N': [['corn'], ['germs'], ['fireworks']],
            },
            [WHIMPROVS_CHOICE_GROUP_R_BLEND]: {
                'A': [['gumdrops'], ['braces'], ['crumbs']],
                'B': [['fries'], ['fruit'], ['grass']],
                'C': [['croak'], ['print'], ['trip']],
                'D': [['brick'], ['umbrella'], ['toothbrush']],
                'E': [['frog'], ['frisbee'], ['frying pan']],
                'F': [['Brooks'], ['Audrey']],
                'G': [['creepy'], ['crispy'], ['prickly']],
                'H': [['tricycle'], ['trophy'], ['pretzel']],
                'I': [['dream'], ['fry'], ['growl']],
                'J': [['crunchy'], ['greasy'], ['fruity']],
                'K': [['cough drops'], ['frosting'], ['trash']],
                'L': [['crumbling'], ['growing'], ['drawing']],
                'M': [['drank'], ['grew'], ['dreamed']],
                'N': [['preschool'], ['poetry'], ['cream']],
            },
            [WHIMPROVS_CHOICE_GROUP_S]: {
                'A': [['dice'], ['raindrops'], ['hats']],
                'B': [['cats'], ['grapes'], ['lips']],
                'C': [['sizzle'], ['whistle'], ['recycle']],
                'D': [['receipt'], ['iceberg'], ['dinosaur']],
                'E': [['mason jar'], ['popsicle'], ['rhinoceros']],
                'F': [['Casey'], ['Sarah']],
                'G': [['salty'], ['bossy'], ['jealous']],
                'H': [['pencil'], ['moose'], ['sandal']],
                'I': [['erase'], ['salute'], ['toss']],
                'J': [['silly'], ['sorry'], ['fancy']],
                'K': [['soap'], ['sushi'], ['gossip']],
                'L': [['flossing'], ['guessing'], ['hissing']],
                'M': [['sat'], ['sang'], ['said']],
                'N': [['summertime'], ['seaweed'], ['eyesight']],
            },
            [WHIMPROVS_CHOICE_GROUP_S_BLEND]: {
                'A': [['sleeves'], ['swim fins'], ['chopsticks']],
                'B': [['whiskers'], ['classmates'], ['snow']],
                'C': [['sweep'], ['spin'], ['smile']],
                'D': [['slug'], ['snowball'], ['spoon']],
                'E': [['sled'], ['slipper'], ['sleeping bag']],
                'F': [['Stella'], ['Spike']],
                'G': [['smooth'], ['slow'], ['sloppy']],
                'H': [['snail'], ['snapping turtle'], ['snow globe']],
                'I': [['swallow'], ['disturb'], ['swipe']],
                'J': [['smart'], ['scary'], ['smoky']],
                'K': [['snowshoes'], ['snot'], ['spaghetti']],
                'L': [['sledding'], ['snooping'], ['swimming']],
                'M': [['spelled'], ['spilled'], ['sneaked']],
                'N': [['outer space'], ['Swiss cheese'], ['slime']],
            },
            [WHIMPROVS_CHOICE_GROUP_SH]: {
                'A': [['shakers'], ['shellfish'], ['snowshoes']],
                'B': [['sheep'], ['dishes'], ['trash']],
                'C': [['shout'], ['shine'], ['shave']],
                'D': [['shoelace'], ['tissue'], ['radish']],
                'E': [['dish'], ['mustache'], ['toothbrush']],
                'F': [['Shane'], ['Tisha']],
                'G': [['British'], ['shy'], ['shiny']],
                'H': [['shark'], ['rocket ship'], ['shadow']],
                'I': [['splash'], ['smash'], ['flush']],
                'J': [['fresh'], ['shallow'], ['mushy']],
                'K': [['shampoo'], ['nail polish'], ['fish']],
                'L': [['gushing'], ['wishing'], ['brushing']],
                'M': [['shopped'], ['shook'], ['shut']],
                'N': [['vacation'], ['mouthwash'], ['licorice']],
            },
            [WHIMPROVS_CHOICE_GROUP_TH]: {
                'A': [['thread'], ['anything'], ['teeth']],
                'B': [['mouthwash'], ['something'], ['cloth']],
                'C': [['thunder'], ['slither'], ['breathe']],
                'D': [['mouth'], ['bathtub'], ['throne']],
                'E': [['python'], ['birthday party'], ['toothbrush']],
                'F': [['Thor'], ['Arthur']],
                'G': [['healthy'], ['thirsty'], ['filthy']],
                'H': [['thermos'], ['throw rug'], ['theater']],
                'I': [['gather'], ['throw'], ['rethink']],
                'J': [['thankful'], ['thirteen'], ['thick']],
                'K': [['toothpaste'], ['chicken broth'], ['math homework']],
                'L': [['breathing'], ['bathing'], ['thinking']],
                'M': [['thought'], ['threw']],
                'N': [['sixth grade'], ['strength'], ['three']],
            },
            [WHIMPROVS_CHOICE_GROUP_V]: {
                'A': [['voices'], ['sleeves'], ['vanilla']],
                'B': [['adventure'], ['lava'], ['travel']],
                'C': [['skydive'], ['drive'], ['wave']],
                'D': [['vegetable'], ['vacuum'], ['video game']],
                'E': [['olive'], ['stove'], ['dove']],
                'F': [['Victoria'], ['Olivia']],
                'G': [['vanilla'], ['nervous'], ['brave']],
                'H': [['vine'], ['vampire'], ['vase']],
                'I': [['swivel'], ['carve']],
                'J': [['heavy'], ['expensive'], ['venomous']],
                'K': [['flavor'], ['silverware'], ['shaving cream']],
                'L': [['give', 'giving'], ['shave', 'shaving'], ['visit', 'visiting']],
                'M': [['vote', 'voted'], ['veering', 'veered']],
                'N': [['victory'], ['vacation'], ['fever']],
            },
            [WHIMPROVS_CHOICE_GROUP_Z]: {
                'A': [['bees'], ['toes'], ['shoes']],
                'B': [['keys'], ['glasses'], ['knees']],
                'C': [['zigzag'], ['sneeze'], ['snooze']],
                'D': [['maze'], ['nose'], ['bruise']],
                'E': [['cousin'], ['closet'], ['wizard']],
                'F': [['Isaac'], ['Elizabeth']],
                'G': [['zany'], ['cozy'], ['fuzzy']],
                'H': [['kazoo'], ['buzzer'], ['lizard']],
                'I': [['sizzle'], ['freeze'], ['surprise']],
                'J': [['zesty'], ['dizzy'], ['fizzy']],
                'K': [['tools'], ['cheese'], ['clothes']],
                'L': [['squeeze', 'squeezing'], ['buzz', 'buzzing'], ['gaze', 'gazing']],
                'M': [['chose'], ['doze', 'dozed']],
                'N': [['raisin'], ['pretzel'], ['daisy']],
            },
        },
        'passages': [
            ["Last night I had the strangest dream!",
                "I was twenty feet tall, with <A> for hands and <B> for feet. I had a cool set of wings so I could <C> instead of walking.",
                "I lived in a giant <D> with a friendly <E> as a pet. I had a friend named <F> who was really <G> and always packed a <H> for a snack."],
            ["In my dream, I had to <I> a bad guy named Doctor Ick. He smelled <J> and shot <K> out of his nose when he was angry. He was so scary!",
                "Luckily <F> and I were able to defeat him with a combination of magic and <L|L.>"],
            ["When I <M> up this morning, I was exhausted. I looked at my hands and my feet and breathed a sigh of <N|N.>",
                "Thank goodness it was only a dream!"]
        ],
    },
    'unlucky-day': {
        'name': 'An Unlucky Day',
        'choiceGroups': {
            [WHIMPROVS_CHOICE_GROUP_CH]: {
                'A': [['cheetah'], ['chain'], ['chimney']],
                'B': [['teach'], ['smooch'], ['scratch']],
                'C': [['chuckle'], ['adventure'], ['itch']],
                'D': [['lunch'], ['spinach'], ['beach']],
                'E': [['watch'], ['furniture'], ['cheeseburger']],
                'F': [['archery'], ['grouch'], ['cheerleader']],
                'G': [['chicken'], ['beachball'], ['peach']],
                'H': [['cheerful'], ['chilly'], ['crunchy']],
                'I': [['potato chips'], ['cherry pie'], ['french fries']],
                'J': [['chalk'], ['screech owl'], ['bleach']],
                'K': [['couch'], ['treasure chest'], ['chipmunk']],
                'L': [['checked'], ['chewed'], ['chose']],
                'M': [['cheetah'], ['children'], ['cheddar cheese']],
                'N': [['chasing'], ['chuckling'], ['hatching']],
            },
            [WHIMPROVS_CHOICE_GROUP_F]: {
                'A': [['fire truck'], ['face'], ['fossil']],
                'B': [['sniff'], ['surf'], ['find']],
                'C': [['hoof'], ['leaf'], ['knife']],
                'D': [['daffodil'], ['lifeguard'], ['campfire']],
                'E': [['goldfish'], ['muffin'], ['sofa']],
                'F': [['ferry'], ['ferris wheel'], ['foot']],
                'G': [['phone'], ['faucet'], ['fishbowl']],
                'H': [['filthy'], ['fluffy'], ['funny']],
                'I': [['coral reef'], ['scarf'], ['bookshelf']],
                'J': [['waffle'], ['microphone'], ['starfish']],
                'K': [['four leaf clover'], ['elephant'], ['thief']],
                'L': [['check off', 'checked off'], ['cough', 'coughed']],
                'M': [['traffic'], ['breakfast'], ['loaf']],
                'N': [['fishing'], ['laughing']],
            },
            [WHIMPROVS_CHOICE_GROUP_G]: {
                'A': [['guinea pig'], ['goblin'], ['price tag']],
                'B': [['snuggle'], ['wag'], ['dig']],
                'C': [['garbage'], ['gum'], ['beagle']],
                'D': [['goat'], ['igloo'], ['egg']],
                'E': [['hamburger'], ['guitar'], ['Lego']],
                'F': [['hot dog'], ['lifeguard'], ['wiggle']],
                'G': [['triangle'], ['gorilla'], ['slug']],
                'H': [['good'], ['regular'], ['snug']],
                'I': [['pig'], ['bookbag'], ['ear plug']],
                'J': [['golf'], ['asparagus'], ['ladybug']],
                'K': [['frog'], ['flagpole'], ['gas station']],
                'L': [['goofed'], ['wiggled'], ['gossipped']],
                'M': [['ghost'], ['ogre'], ['mug']],
                'N': [['guessing'], ['beginning'], ['going']],
            },
            [WHIMPROVS_CHOICE_GROUP_J]: {
                'A': [['jackrabbit'], ['hedgehog'], ['pigeon']],
                'B': [['jump'], ['damage'], ['imagine']],
                'C': [['gym'], ['jet'], ['jungle']],
                'D': [['banjo'], ['flapjack'], ['apple juice']],
                'E': [['ninja'], ['fire engine'], ['carriage']],
                'F': [['courage'], ['jelly'], ['cottage cheese']],
                'G': [['bridge'], ['bandage'], ['message']],
                'H': [['orange'], ['fragile'], ['dangerous']],
                'I': [['pickle jar'], ['jack o lantern', "jack o'lantern"], ['genius']],
                'J': [['cabbage'], ['sausage'], ['sponge']],
                'K': [['giraffe'], ['juice'], ['magic']],
                'L': [['injure', 'injured'], ['apologize', 'apologized']],
                'M': [['germs'], ['gymnastics'], ['luggage']],
                'N': [['merge', 'merging'], ['jogging'], ['juggling']],
            },
            [WHIMPROVS_CHOICE_GROUP_K]: {
                'A': [['camel'], ['bumper car'], ['snake']],
                'B': [['cough'], ['smirk'], ['bark']],
                'C': [['karate'], ['raccoon'], ['block']],
                'D': [['candy'], ['chicken'], ['bookmark']],
                'E': [['caterpillar'], ['freckle'], ['rock']],
                'F': [['corn'], ['circus'], ['snowflake']],
                'G': [['caboose'], ['sneaker'], ['sidewalk']],
                'H': [['cool'], ['icky'], ['sick']],
                'I': [['car'], ['blanket'], ['railroad track']],
                'J': [['camel'], ['broccoli'], ['truck']],
                'K': [['cobra'], ['taco'], ['mistake']],
                'L': [['carried'], ['kissed'], ['kicked']],
                'M': [['coffee'], ['bucket'], ['flapjack']],
                'N': [['hiking'], ['raking'], ['sneaking']],
            },
            [WHIMPROVS_CHOICE_GROUP_L]: {
                'A': [['licorice'], ['pickle'], ['elbow']],
                'B': [['sizzle'], ['recycle'], ['whistle']],
                'C': [['lettuce'], ['light'], ['land']],
                'D': [['toilet'], ['eyelash'], ['caterpillar']],
                'E': [['jellybeans'], ['lips'], ['long sleeves']],
                'F': [['doll'], ['fossil'], ['lawnmower']],
                'G': [['lollipop'], ['seashell'], ['meatball']],
                'H': [['loud'], ['polite'], ['silly']],
                'I': [['salad'], ['melon'], ['helicopter']],
                'J': [['lipstick'], ['leg'], ['lizard']],
                'K': [['armadillo'], ['umbrella'], ['trampoline']],
                'L': [['licked'], ['laughed'], ['relaxed']],
                'M': [['school'], ['cereal'], ['rainfall']],
                'N': [['sailing'], ['bowling'], ['smiling']],
            },
            [WHIMPROVS_CHOICE_GROUP_L_BLEND]: {
                'A': [['tablet'], ['blimp'], ['blob']],
                'B': [['clap'], ['blurt'], ['float']],
                'C': [['bloodhound'], ['camouflage'], ['juggling']],
                'D': [['houseplant'], ['blue ribbon'], ['flashlight']],
                'E': [['cleats'], ['airplane'], ['butterfly']],
                'F': [['flapjack'], ['hang gliding'], ['cycling']],
                'G': [['sprinkler'], ['fireplace'], ['snowflake']],
                'H': [['flashy'], ['floppy'], ['playful']],
                'I': [['flashcard'], ['blender'], ['piglet']],
                'J': [['glue'], ['kleenex'], ['plankton']],
                'K': [['snorkel'], ['placemat'], ['sibling']],
                'L': [['cleaned'], ['clapped'], ['bloomed']],
                'M': [['blanket'], ['sunflower'], ['ringlet']],
                'N': [['nibbling'], ['flapping'], ['giggling']],
            },
            [WHIMPROVS_CHOICE_GROUP_R_PREVOCALIC]: {
                'A': [['rocket'], ['rhino'], ['raisin']],
                'B': [['recycle'], ['repeat'], ['race']],
                'C': [['rag'], ['racquet'], ['robot']],
                'D': [['road'], ['raccoon'], ['rat']],
                'E': [['runway'], ['reptile'], ['rainbow']],
                'F': [['rainfall'], ['rest'], ['rain']],
                'G': [['reef'], ['rabbit'], ['rowboat']],
                'H': [['rocky'], ['rough'], ['ridiculous']],
                'I': [['rope'], ['ribbon'], ['recess']],
                'J': [['rodeo'], ['rose'], ['rattlesnake']],
                'K': [['ringlet'], ['raincoat'], ['wrench']],
                'L': [['rescued'], ['ran'], ['rang']],
                'M': [['rice'], ['wrist'], ['raven']],
                'N': [['reading'], ['relaxing'], ['racing']],
            },
            [WHIMPROVS_CHOICE_GROUP_R_VOCALIC]: {
                'A': [['jar'], ['candy bar'], ['starfish']],
                'B': [['carve'], ['fear'], ['irritate']],
                'C': [['carrot'], ['fairy'], ['nightmare']],
                'D': [['earlobe'], ['beard'], ['cereal']],
                'E': [['parrot'], ['fork'], ['flippers']],
                'F': [['horse'], ['cork'], ['story']],
                'G': [['licorice'], ['spider'], ['sea turtle']],
                'H': [['cheery'], ['boring'], ['sparkly']],
                'I': [['fireball'], ['hammer'], ['falling star']],
                'J': [['hair'], ['scarf'], ['ear']],
                'K': [['glow worm'], ['campfire'], ['tornado']],
                'L': [['snorted'], ['fired'], ['carried']],
                'M': [['smore', "s'more"], ['pyramid'], ['farm']],
                'N': [['buttering'], ['snoring'], ['coloring']],
            },
            [WHIMPROVS_CHOICE_GROUP_R_BLEND]: {
                'A': [['brick'], ['umbrella'], ['toothbrush']],
                'B': [['croak'], ['print'], ['trip']],
                'C': [['tricycle'], ['trophy'], ['pretzel']],
                'D': [['dragon'], ['drum'], ['laundry basket']],
                'E': [['gumdrops'], ['braces'], ['crumbs']],
                'F': [['eyebrow'], ['trumpet'], ['fruit salad']],
                'G': [['grape'], ['grandpa'], ['grass snake']],
                'H': [['creepy'], ['fruity'], ['prickly']],
                'I': [['frog'], ['frisbee'], ['frying pan']],
                'J': [['broom'], ['drool'], ['grass']],
                'K': [['princess'], ['apron'], ['trapeze']],
                'L': [['drank'], ['grew'], ['dreamed']],
                'M': [['crocodile'], ['spacecraft'], ['moose crossing']],
                'N': [['crumbling'], ['growing'], ['dribbling']],
            },
            [WHIMPROVS_CHOICE_GROUP_S]: {
                'A': [['castle'], ['fossil'], ['pencil']],
                'B': [['sizzle'], ['whistle'], ['recycle']],
                'C': [['soap'], ['sofa'], ['sandal']],
                'D': [['salad'], ['seashell'], ['surfboard']],
                'E': [['seaweed'], ['ice cream'], ['voice']],
                'F': [['mason jar'], ['popsicle'], ['rhinoceros']],
                'G': [['receipt'], ['iceberg'], ['dinosaur']],
                'H': [['silly'], ['jealous'], ['fancy']],
                'I': [['moose'], ['bookcase'], ['lighthouse']],
                'J': [['possum'], ['whistle'], ['baseball']],
                'K': [['mouse'], ['octopus'], ['doghouse']],
                'L': [['sat'], ['sang'], ['said']],
                'M': [['lips'], ['asparagus'], ['fence']],
                'N': [['guessing'], ['flossing'], ['hissing']],
            },
            [WHIMPROVS_CHOICE_GROUP_S_BLEND]: {
                'A': [['swamp'], ['sweatshirt'], ['Swiss cheese']],
                'B': [['sweep'], ['spin'], ['smile']],
                'C': [['slug'], ['snowball'], ['spoon']],
                'D': [['sled'], ['slipper'], ['sleeping bag']],
                'E': [['sleeves'], ['swim fins'], ['chopsticks']],
                'F': [['snack'], ['outer space'], ['gymnastics']],
                'G': [['scooter'], ['smoothie'], ['snorkel']],
                'H': [['smooth'], ['slow'], ['sloppy']],
                'I': [['stage'], ['bus stop'], ['mistake']],
                'J': [['spud'], ['spinner'], ['spider']],
                'K': [['skunk'], ['skull'], ['mask']],
                'L': [['spelled'], ['spilled'], ['sneaked']],
                'M': [['snail'], ['snapping turtle'], ['snow globe']],
                'N': [['sledding'], ['snooping'], ['swimming']],
            },
            [WHIMPROVS_CHOICE_GROUP_SH]: {
                'A': [['sheep'], ['shower'], ['shovel']],
                'B': [['shout'], ['shine'], ['shave']],
                'C': [['shoelace'], ['tissue'], ['radish']],
                'D': [['shin'], ['shampoo'], ['shoulder']],
                'E': [['shakers'], ['shellfish'], ['snowshoes']],
                'F': [['shamrock'], ['milkshake'], ['eyelash']],
                'G': [['mushroom'], ['parachute'], ['marshmallow']],
                'H': [['fresh'], ['shy'], ['shiny']],
                'I': [['dish'], ['mustache'], ['toothbrush']],
                'J': [['licorice'], ['nail polish'], ['trash']],
                'K': [['shark'], ['rocket ship'], ['fish']],
                'L': [['shopped'], ['shook'], ['shut']],
                'M': [['mansion'], ['vacation'], ['ocean']],
                'N': [['gushing'], ['fishing'], ['shopping']],
            },
            [WHIMPROVS_CHOICE_GROUP_TH]: {
                'A': [['thread'], ['throat'], ['thief']],
                'B': [['thunder'], ['throw'], ['breathe']],
                'C': [['thumbtack'], ['thing'], ['earthworm']],
                'D': [['toothpick'], ['math homework'], ['tooth']],
                'E': [['thermos'], ['throw rug'], ['theater']],
                'F': [['tablecloth'], ['birdbath'], ['locksmith']],
                'G': [['python'], ['birthday party'], ['toothbrush']],
                'H': [['healthy'], ['thirsty'], ['thankful']],
                'I': [['mouth'], ['bathtub'], ['throne']],
                'J': [['baby tooth'], ['bubble bath'], ['sloth']],
                'K': [['panther'], ['bathmat'], ['thumb']],
                'L': [['thought'], ['threw']],
                'M': [['earthquake'], ['athlete'], ['author']],
                'N': [['breathing'], ['bathing'], ['thinking']],
            },
            [WHIMPROVS_CHOICE_GROUP_V]: {
                'A': [['elevator'], ['baseball glove'], ['volleyball']],
                'B': [['skydive'], ['drive'], ['wave']],
                'C': [['volcano'], ['valentine'], ['violin']],
                'D': [['vine'], ['vampire'], ['vase']],
                'E': [['four leaf clover'], ['sleeves'], ['work gloves']],
                'F': [['olive'], ['beehive'], ['stove']],
                'G': [['river'], ['oven'], ['driveway']],
                'H': [['expensive'], ['nervous'], ['brave']],
                'I': [['TV'], ['driver'], ['carnival']],
                'J': [['valley'], ['vitamin'], ['volleyball']],
                'K': [['cave'], ['boxing glove'], ['relative']],
                'L': [['vote', 'voted'], ['veering', 'veered']],
                'M': [['shaving cream'], ['Valentines Day', "Valentine's Day"], ['screwdriver']],
                'N': [['swerve', 'swerving'], ['shave', 'shaving']],
            },
            [WHIMPROVS_CHOICE_GROUP_Z]: {
                'A': [['bees'], ['toes'], ['shoes']],
                'B': [['zoom'], ['raise'], ['surprise']],
                'C': [['raisin'], ['pretzel'], ['daisy']],
                'D': [['nose'], ['breeze'], ['mayonnaise']],
                'E': [['kazoo'], ['buzzer'], ['lizard']],
                'F': [['puzzle'], ['blizzard'], ['freezer']],
                'G': [['zipper'], ['zombie'], ['xylophone']],
                'H': [['zesty'], ['dizzy'], ['fizzy']],
                'I': [['zoo'], ['zip line'], ['zebra']],
                'J': [['rose'], ['quiz'], ['bruise']],
                'K': [['buzzard'], ['nozzle'], ['chimpanzee']],
                'L': [['chose'], ['doze', 'dozed']],
                'M': [['tools'], ['cheese'], ['clothes']],
                'N': [['squeeze', 'squeezing'], ['buzz', 'buzzing'], ['gaze', 'gazing']],
            },
        },
        'passages': [
            ["Have you ever heard someone say that it's their lucky day? Well, today is my UNlucky day.",
                "I forgot to set my <A> last night, so I woke up late. I didn't have time to <B> breakfast because I had to rush to catch the <C> bus.",
                "When I got to school, I realized that my <D> was on backwards and I'd forgotten to brush my <E|E!>"],
            ["When I got to <F> class, I realized that I'd left my homework on the kitchen <G|G.> My teacher was so <H|H!>",
                "I couldn't wait for lunch, but when I opened my <I|I,> I discovered that I'd grabbed my sister's lunch instead. It was her favorite food, a <J> and sardine sandwich. I was hungry enough to eat it, but I gagged a few times."],
            ["The rest of the day has been just as unlucky: I broke my favorite <K|K,> I <L> water on my desk, and someone stepped on my <M|M.> I even got in trouble for <N> in class.",
                "I'm going to go to bed early tonight so that this unlucky day will be over!"],
        ],
    },
}

export function GetWhimprovsStories() {
    return whimprovsStories
}

const _whimprovsStoryTemplate = {
    '': {
        'name': '',
        'choiceGroups': {
            [WHIMPROVS_CHOICE_GROUP_CH]: {
                'A': [],
                'B': [],
                'C': [],
                'D': [],
                'E': [],
                'F': [],
                'G': [],
                'H': [],
                'I': [],
                'J': [],
                'K': [],
                'L': [],
                'M': [],
                'N': [],
            },
            [WHIMPROVS_CHOICE_GROUP_F]: {
                'A': [],
                'B': [],
                'C': [],
                'D': [],
                'E': [],
                'F': [],
                'G': [],
                'H': [],
                'I': [],
                'J': [],
                'K': [],
                'L': [],
                'M': [],
                'N': [],
            },
            [WHIMPROVS_CHOICE_GROUP_G]: {
                'A': [],
                'B': [],
                'C': [],
                'D': [],
                'E': [],
                'F': [],
                'G': [],
                'H': [],
                'I': [],
                'J': [],
                'K': [],
                'L': [],
                'M': [],
                'N': [],
            },
            [WHIMPROVS_CHOICE_GROUP_J]: {
                'A': [],
                'B': [],
                'C': [],
                'D': [],
                'E': [],
                'F': [],
                'G': [],
                'H': [],
                'I': [],
                'J': [],
                'K': [],
                'L': [],
                'M': [],
                'N': [],
            },
            [WHIMPROVS_CHOICE_GROUP_K]: {
                'A': [],
                'B': [],
                'C': [],
                'D': [],
                'E': [],
                'F': [],
                'G': [],
                'H': [],
                'I': [],
                'J': [],
                'K': [],
                'L': [],
                'M': [],
                'N': [],
            },
            [WHIMPROVS_CHOICE_GROUP_L]: {
                'A': [],
                'B': [],
                'C': [],
                'D': [],
                'E': [],
                'F': [],
                'G': [],
                'H': [],
                'I': [],
                'J': [],
                'K': [],
                'L': [],
                'M': [],
                'N': [],
            },
            [WHIMPROVS_CHOICE_GROUP_L_BLEND]: {
                'A': [],
                'B': [],
                'C': [],
                'D': [],
                'E': [],
                'F': [],
                'G': [],
                'H': [],
                'I': [],
                'J': [],
                'K': [],
                'L': [],
                'M': [],
                'N': [],
            },
            [WHIMPROVS_CHOICE_GROUP_R_PREVOCALIC]: {
                'A': [],
                'B': [],
                'C': [],
                'D': [],
                'E': [],
                'F': [],
                'G': [],
                'H': [],
                'I': [],
                'J': [],
                'K': [],
                'L': [],
                'M': [],
                'N': [],
            },
            [WHIMPROVS_CHOICE_GROUP_R_VOCALIC]: {
                'A': [],
                'B': [],
                'C': [],
                'D': [],
                'E': [],
                'F': [],
                'G': [],
                'H': [],
                'I': [],
                'J': [],
                'K': [],
                'L': [],
                'M': [],
                'N': [],
            },
            [WHIMPROVS_CHOICE_GROUP_R_BLEND]: {
                'A': [],
                'B': [],
                'C': [],
                'D': [],
                'E': [],
                'F': [],
                'G': [],
                'H': [],
                'I': [],
                'J': [],
                'K': [],
                'L': [],
                'M': [],
                'N': [],
            },
            [WHIMPROVS_CHOICE_GROUP_S]: {
                'A': [],
                'B': [],
                'C': [],
                'D': [],
                'E': [],
                'F': [],
                'G': [],
                'H': [],
                'I': [],
                'J': [],
                'K': [],
                'L': [],
                'M': [],
                'N': [],
            },
            [WHIMPROVS_CHOICE_GROUP_S_BLEND]: {
                'A': [],
                'B': [],
                'C': [],
                'D': [],
                'E': [],
                'F': [],
                'G': [],
                'H': [],
                'I': [],
                'J': [],
                'K': [],
                'L': [],
                'M': [],
                'N': [],
            },
            [WHIMPROVS_CHOICE_GROUP_SH]: {
                'A': [],
                'B': [],
                'C': [],
                'D': [],
                'E': [],
                'F': [],
                'G': [],
                'H': [],
                'I': [],
                'J': [],
                'K': [],
                'L': [],
                'M': [],
                'N': [],
            },
            [WHIMPROVS_CHOICE_GROUP_TH]: {
                'A': [],
                'B': [],
                'C': [],
                'D': [],
                'E': [],
                'F': [],
                'G': [],
                'H': [],
                'I': [],
                'J': [],
                'K': [],
                'L': [],
                'M': [],
                'N': [],
            },
            [WHIMPROVS_CHOICE_GROUP_V]: {
                'A': [],
                'B': [],
                'C': [],
                'D': [],
                'E': [],
                'F': [],
                'G': [],
                'H': [],
                'I': [],
                'J': [],
                'K': [],
                'L': [],
                'M': [],
                'N': [],
            },
            [WHIMPROVS_CHOICE_GROUP_Z]: {
                'A': [['']],
                'B': [['']],
                'C': [['']],
                'D': [['']],
                'E': [['']],
                'F': [['']],
                'G': [['']],
                'H': [['']],
                'I': [['']],
                'J': [['']],
                'K': [['']],
                'L': [['']],
                'M': [['']],
                'N': [['']],
            },
        },
        'passages': [
            [""],
            [""],
        ],
    },
}
