import { useState, useEffect, useCallback } from 'react'
import { Container, Image } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import PropTypes from 'prop-types'
import { START_PATH, LOGIN_SIGNUP_PATH, SUBSCRIPTION_PATH, LIST_CREATE_PATH, LIST_EDIT_PATH, LIST_DELETE_PATH, PICK_A_PATH_PATH } from '../App'
import { Header } from '../components/Header'
import { Footer } from '../components/Footer'
import { QuickStartSetupComponent, QuickStartActionComponent, QuickStartWordsComponent } from '../components/QuickStartComponents'
import { SavedListsSelectionComponent, SavedListsActionComponent, SavedListsWordsComponent } from '../components/SavedListsComponents'
import { CarryoverComponent } from '../components/CarryoverComponents'
import { GetWordText } from '../project/WordCodings'
import { CreateWordListPdf } from '../project/PdfOutput'
import { EnableScrollbar } from '../project/Display'
import { GetSiteImageUrl } from '../project/ProjectResources'
import { UserAuthStateChangeListener, IsUserLoggedIn } from '../state/UserAuthState'
import { IsUserSubscribed } from '../state/SubscriptionState'
import { SetActivityWordList, SetActivityReturnPath } from '../state/ActivityState'
import { GetSelectedListId } from '../state/ListsState'
import { AreQuickSearchResultsEmpty } from '../state/QuickSearchState'
import '../style/StartPageStyle.css'

export const START_PAGE_QUICK_START_TAB = 'StartPage/QuickStartTab'
export const START_PAGE_SAVED_LIST_TAB = 'StartPage/SavedListsTab'
export const START_PAGE_CARRYOVER_TAB = 'StartPage/CarryoverTab'
export const START_PAGE_STORED_SELECTED_TAB = 'StartPage/StoredSelectedTab'

export function StartPage() {
    useEffect(() => {
        EnableScrollbar()
    }, [])

    const navigate = useNavigate()

    const [userStateChangeString, SetUserStateChangeString] = useState('')

    useEffect(() => {
        if (!IsUserLoggedIn()) {
            navigate(LOGIN_SIGNUP_PATH)
        }
        else if (!IsUserSubscribed()) {
            navigate(SUBSCRIPTION_PATH)
        }
    }, [userStateChangeString, navigate])

    const HandleUserStateChange = useCallback((listenerString) => {
        SetUserStateChangeString(listenerString)
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const QUICK_START_TAB = START_PAGE_QUICK_START_TAB
    const SAVED_LIST_TAB = START_PAGE_SAVED_LIST_TAB
    const CARRYOVER_TAB = START_PAGE_CARRYOVER_TAB
    const STORED_SELECTED_TAB = START_PAGE_STORED_SELECTED_TAB

    const GetStoredSelectedTab = () => {
        const storedSelectedTab = sessionStorage.getItem(STORED_SELECTED_TAB)

        if ((storedSelectedTab === SAVED_LIST_TAB) || (storedSelectedTab === CARRYOVER_TAB)) {
            return storedSelectedTab
        }

        return QUICK_START_TAB
    }

    const [selectedTab, SetSelectedTab] = useState(GetStoredSelectedTab())

    const QUICK_START_TAB_COLOR = 'dark-orange'
    const SAVED_LIST_TAB_COLOR = 'dark-aqua'
    const CARRYOVER_TAB_COLOR = 'light-green'

    const GetTabColor = () => {
        switch (selectedTab) {
            case QUICK_START_TAB:
            default:
                return QUICK_START_TAB_COLOR

            case SAVED_LIST_TAB:
                return SAVED_LIST_TAB_COLOR

            case CARRYOVER_TAB:
                return CARRYOVER_TAB_COLOR
        }
    }

    const [quickStartResultsChangeString, SetQuickStartResultsChangeString] = useState('')
    const [savedListsChangeString, SetSavedListsChangeString] = useState('')
    const [carryoverChangeString, SetCarryoverChangeString] = useState('')

    useEffect(() => { }, [quickStartResultsChangeString, savedListsChangeString, carryoverChangeString])

    const ShowActionArea = () => {
        return (
            (selectedTab === QUICK_START_TAB && !AreQuickSearchResultsEmpty()) ||
            (selectedTab === SAVED_LIST_TAB && GetSelectedListId() !== '')
        )
    }

    const ShowWordsArea = () => {
        return (
            (selectedTab === QUICK_START_TAB && !AreQuickSearchResultsEmpty()) ||
            (selectedTab === SAVED_LIST_TAB && GetSelectedListId() !== '')
        )
    }

    const [instructionsModalVisibility, SetInstructionsModalVisibility] = useState(false)

    return <>
        <UserAuthStateChangeListener callback={HandleUserStateChange} />
        <Header />
        <Container className='section-container bg-light'>
            <div className='start-page-div'>
                <Container className='section-container bg-light w-100 pt-4 pb-4'>
                    <StartPageTabRowComponent>
                        <StartPageTabComponent
                            color={QUICK_START_TAB_COLOR}
                            text='Quick Start'
                            isActive={selectedTab === QUICK_START_TAB}
                            ClickAction={() => {
                                SetSelectedTab(QUICK_START_TAB)
                                sessionStorage.setItem(STORED_SELECTED_TAB, QUICK_START_TAB)
                            }}
                        />
                        <StartPageTabComponent
                            color={SAVED_LIST_TAB_COLOR}
                            text='Saved Lists'
                            isActive={selectedTab === SAVED_LIST_TAB}
                            ClickAction={() => {
                                SetSelectedTab(SAVED_LIST_TAB)
                                sessionStorage.setItem(STORED_SELECTED_TAB, SAVED_LIST_TAB)
                            }}
                        />
                        <StartPageTabComponent
                            color={CARRYOVER_TAB_COLOR}
                            text='Carryover'
                            isActive={selectedTab === CARRYOVER_TAB}
                            ClickAction={() => {
                                SetSelectedTab(CARRYOVER_TAB)
                                sessionStorage.setItem(STORED_SELECTED_TAB, CARRYOVER_TAB)
                            }}
                        />
                    </StartPageTabRowComponent>
                    <StartPageSetupAreaComponent color={GetTabColor()} >
                        {selectedTab === QUICK_START_TAB &&
                            <QuickStartSetupComponent
                                HandleSearchUpdate={changeString => {
                                    SetQuickStartResultsChangeString(changeString)
                                }}
                                showModal={instructionsModalVisibility && !ShowActionArea()}
                                CloseModal={() => SetInstructionsModalVisibility(false)}
                            />
                        }
                        {selectedTab === SAVED_LIST_TAB &&
                            <SavedListsSelectionComponent
                                HandleSelectionUpdate={changeString => {
                                    SetSavedListsChangeString(changeString)
                                }}
                                showModal={instructionsModalVisibility && !ShowActionArea()}
                                CloseModal={() => SetInstructionsModalVisibility(false)}
                            />
                        }
                        {selectedTab === CARRYOVER_TAB &&
                            <CarryoverComponent
                                HandleSelectionUpdate={changeString => {
                                    SetCarryoverChangeString(changeString)
                                }}
                            />
                        }
                    </StartPageSetupAreaComponent>
                    {ShowActionArea() &&
                        <StartPageActionAreaComponent color={GetTabColor()}>
                            {selectedTab === QUICK_START_TAB &&
                                <QuickStartActionComponent
                                    StartActivity={(activityPath, words, story) => {
                                        SetActivityReturnPath(START_PATH)

                                        if (activityPath === PICK_A_PATH_PATH) {
                                            navigate(PICK_A_PATH_PATH, { state: { 'story': story } })
                                        }
                                        else {
                                            SetActivityWordList(words)
                                            navigate(activityPath)
                                        }
                                    }}
                                    CreateList={(words) => {
                                        navigate(LIST_CREATE_PATH, {
                                            state: {
                                                'listName': '',
                                                'listWords': words,
                                            },
                                        })
                                    }}
                                    CreatePdf={(words) => {
                                        CreateWordListPdf('Quick Search Words', words.map((wordKey) => GetWordText(wordKey)))
                                    }}
                                    showModal={instructionsModalVisibility}
                                    CloseModal={() => SetInstructionsModalVisibility(false)}
                                />
                            }
                            {selectedTab === SAVED_LIST_TAB &&
                                <SavedListsActionComponent
                                    StartActivity={(activityPath, words) => {
                                        SetActivityWordList(words)
                                        SetActivityReturnPath(START_PATH)
                                        navigate(activityPath)
                                    }}
                                    EditList={(list) => {
                                        navigate(LIST_EDIT_PATH, {
                                            state: {
                                                list: list
                                            },
                                        })
                                    }}
                                    CopyList={(list, words) => {
                                        navigate(LIST_CREATE_PATH, {
                                            state: {
                                                'listName': 'Copy of ' + list['name'],
                                                'listWords': words,
                                            },
                                        })
                                    }}
                                    CreatePdf={(name, words) => {
                                        CreateWordListPdf(name, words.map((wordKey) => GetWordText(wordKey)))
                                    }}
                                    DeleteList={(list) => {
                                        navigate(LIST_DELETE_PATH, {
                                            state: {
                                                'listId': list['id'],
                                                'listName': list['name'],
                                            },
                                        })
                                    }}
                                    showModal={instructionsModalVisibility}
                                    CloseModal={() => SetInstructionsModalVisibility(false)}
                                />
                            }
                        </StartPageActionAreaComponent>
                    }
                </Container>
                <Container className='start-page-control-container'>
                    <Container className='vstack start-page-control-icon-container'>
                        <Image fluid className='start-page-control-icon mt-5'
                            src={GetSiteImageUrl('question-square-fill-dark-blue.svg')}
                            onClick={() => { SetInstructionsModalVisibility(true) }} />
                    </Container>
                </Container>
            </div>
            {ShowWordsArea() &&
                <StartPageWordsAreaComponent>
                    {selectedTab === QUICK_START_TAB &&
                        <QuickStartWordsComponent />
                    }
                    {selectedTab === SAVED_LIST_TAB &&
                        <SavedListsWordsComponent />
                    }
                </StartPageWordsAreaComponent>
            }
        </Container>
        <Footer />
    </>
}

function StartPageTabRowComponent({ children }) {
    return <div className='start-page-tab-row'>{children}</div>
}

function StartPageTabComponent({ color, text, isActive, ClickAction }) {
    const tabStyle = isActive ? {
        backgroundColor: `var(--${color})`,
        opacity: 1.0,
    } : {
        backgroundColor: `var(--${color})`,
        opacity: 0.5,
    }

    return (
        <div className='start-page-tab' style={tabStyle} onClick={() => ClickAction()}>
            {text}
        </div>
    )
}

StartPageTabComponent.propTypes = {
    color: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    isActive: PropTypes.bool.isRequired,
    ClickAction: PropTypes.func.isRequired,
}

function StartPageSetupAreaComponent({ color, children }) {
    const setupAreaStyle = {
        borderColor: `var(--${color})`
    }

    return (
        <div className='start-page-setup-container mx-auto mb-0' style={setupAreaStyle}>
            {children}
        </div>
    )
}

StartPageSetupAreaComponent.propTypes = {
    color: PropTypes.string.isRequired,
}

function StartPageActionAreaComponent({ color, children }) {
    const actionAreaStyle = {
        borderColor: `var(--${color})`
    }

    return (
        <div className='start-page-action-container mx-auto mt-0' style={actionAreaStyle}>
            {children}
        </div>
    )
}

StartPageActionAreaComponent.propTypes = {
    color: PropTypes.string.isRequired,
}

function StartPageWordsAreaComponent({ children }) {
    return (
        <Container className='section-container bg-light mt-4'>
            <Container fluid className='start-page-words-container'>
                {children}
            </Container>
        </Container>
    )
}
