import { useState } from 'react'
import { Container, Row, Col, Form, Button } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import { LOGIN_SIGNUP_PATH } from '../App'
import { SuccessMessageComponent, ErrorMessageComponent } from './MessageComponents'
import { LoaderComponent } from './LoaderComponents'
import { IsEmailValid } from '../project/Utils'
import './LoginSignupComponents.css'

export const PASSWORD_RESET_MESSAGE_TYPE_NONE = 'PasswordResetMessageType/None'
export const PASSWORD_RESET_MESSAGE_TYPE_SUCCESS = 'PasswordResetMessageType/Success'
export const PASSWORD_RESET_MESSAGE_TYPE_ERROR = 'PasswordResetMessageType/Error'

const SUBMIT_AREA_TYPE_REQUEST = 'SubmitAreaType/Request'
const SUBMIT_AREA_TYPE_ENTRY = 'SubmitAreaType/Entry'

export function PasswordResetRequestComponent({
    HandleSubmit, IsBackendOperationActive,
    messageType, message, isMessageDismissable = true, ClearMessage
}) {
    const [accountEmail, SetAccountEmail] = useState('')

    const disableForm = (messageType !== PASSWORD_RESET_MESSAGE_TYPE_NONE) || IsBackendOperationActive()

    return (
        <Container fluid className='py-4'>
            <Row className='g-1'>
                <Col md={2} xl={3}></Col>
                <Col xs={12} md={8} xl={6} className='login-signup-area'>
                    <div className='text-center'>
                        <h4>Password Reset</h4>
                    </div>
                    <div className='text-start mt-3 mb-4 mx-5'>
                        <p>Forget your password? Enter your email address, and we'll send you a secure link for resetting it.</p>
                    </div>
                    <Form
                        onSubmit={(event) => {
                            event.preventDefault()
                            HandleSubmit(accountEmail)
                        }}
                    >
                        <div style={{ 'paddingLeft': '15%', 'paddingRight': '15%' }}>
                            <Form.Group controlId='email' className='mt-3'>
                                <Form.Label className='login-signup-label'>Email:</Form.Label>
                                <Form.Control
                                    className='login-signup-input-box'
                                    type='text'
                                    value={accountEmail}
                                    disabled={disableForm}
                                    onChange={(event) => {
                                        event.preventDefault()
                                        SetAccountEmail(event.target.value)
                                    }}
                                />
                            </Form.Group>
                        </div>
                        <div className='text-center my-2'>
                            <PasswordResetSubmitAreaComponent
                                submitAreaType={SUBMIT_AREA_TYPE_REQUEST}
                                isSubmitButtonActive={!disableForm && IsEmailValid(accountEmail)}
                                messageType={messageType}
                                message={message}
                                isMessageDismissable={isMessageDismissable}
                                ClearMessage={ClearMessage}
                                IsBackendOperationActive={IsBackendOperationActive}
                            />
                        </div>
                    </Form>
                </Col>
                <Col md={2} xl={3}></Col>
            </Row>
        </Container>
    )
}

PasswordResetRequestComponent.propTypes = {
    HandleSubmit: PropTypes.func.isRequired,
    IsBackendOperationActive: PropTypes.func.isRequired,
    messageType: PropTypes.string.isRequired,
    message: PropTypes.string.isRequired,
    isMessageDismissable: PropTypes.bool,
    ClearMessage: PropTypes.func.isRequired,
}

export function PasswordResetEntryComponent({
    HandleSubmit, IsBackendOperationActive, accountEmail,
    messageType, message, isMessageDismissable = true, ClearMessage
}) {
    const [accountPassword, SetAccountPassword] = useState('')
    const [accountRepeatPassword, SetAccountRepeatPassword] = useState('')

    const disableForm = (messageType !== PASSWORD_RESET_MESSAGE_TYPE_NONE) || IsBackendOperationActive()

    return (
        <Container fluid className='py-4'>
            <Row className='g-1'>
                <Col md={2} xl={3}></Col>
                <Col xs={12} md={8} xl={6} className='login-signup-area'>
                    <div className='text-center'>
                        <h4>Password Reset</h4>
                    </div>
                    <div className='text-start mt-3 mb-4 mx-5'>
                        <p>Please enter and confirm your new password.</p>
                    </div>
                    <Form
                        onSubmit={(event) => {
                            event.preventDefault()
                            HandleSubmit(accountPassword)
                        }}
                    >
                        <div style={{ 'paddingLeft': '15%', 'paddingRight': '15%' }}>
                            <Form.Group controlId='email' className='mt-3'>
                                <Form.Label className='login-signup-label'>Email:</Form.Label>
                                <Form.Control
                                    className='login-signup-input-box'
                                    type='text'
                                    value={accountEmail}
                                    disabled={true}
                                />
                            </Form.Group>
                            <Form.Group controlId='password' className='mt-3'>
                                <Form.Label className='login-signup-label'>Password:</Form.Label>
                                <Form.Control
                                    className='login-signup-input-box'
                                    type='password'
                                    value={accountPassword}
                                    disabled={disableForm}
                                    onChange={(event) => {
                                        event.preventDefault()
                                        SetAccountPassword(event.target.value)
                                    }}
                                />
                            </Form.Group>
                            <Form.Group controlId='repeatPassword' className='mt-3'>
                                <Form.Label className='login-signup-label'>Repeat Password:</Form.Label>
                                <Form.Control
                                    className='login-signup-input-box'
                                    type='password'
                                    value={accountRepeatPassword}
                                    disabled={disableForm}
                                    onChange={(event) => {
                                        event.preventDefault()
                                        SetAccountRepeatPassword(event.target.value)
                                    }}
                                />
                            </Form.Group>
                        </div>
                        <div className='text-center my-2'>
                            <PasswordResetSubmitAreaComponent
                                submitAreaType={SUBMIT_AREA_TYPE_ENTRY}
                                isSubmitButtonActive={!disableForm && (accountPassword !== '') && (accountPassword === accountRepeatPassword)}
                                messageType={messageType}
                                message={message}
                                isMessageDismissable={isMessageDismissable}
                                ClearMessage={ClearMessage}
                                IsBackendOperationActive={IsBackendOperationActive}
                            />
                        </div>
                    </Form>
                </Col>
                <Col md={2} xl={3}></Col>
            </Row>
        </Container>
    )
}

PasswordResetEntryComponent.propTypes = {
    HandleSubmit: PropTypes.func.isRequired,
    IsBackendOperationActive: PropTypes.func.isRequired,
    accountEmail: PropTypes.string.isRequired,
    messageType: PropTypes.string.isRequired,
    message: PropTypes.string.isRequired,
    isMessageDismissable: PropTypes.bool,
    ClearMessage: PropTypes.func.isRequired,
}

function PasswordResetSubmitAreaComponent({
    submitAreaType, isSubmitButtonActive, IsBackendOperationActive,
    messageType, message, isMessageDismissable, ClearMessage
}) {
    let component = <></>

    if (messageType === PASSWORD_RESET_MESSAGE_TYPE_ERROR) {
        component =
            <ErrorMessageComponent
                isDismissible={isMessageDismissable}
                DismissAlert={() => {
                    ClearMessage()
                }}
                messageWidth='90%'
            >
                {message}
            </ErrorMessageComponent>
    }
    else if (messageType === PASSWORD_RESET_MESSAGE_TYPE_SUCCESS) {
        component =
            <SuccessMessageComponent
                isDismissible={isMessageDismissable}
                DismissAlert={() => {
                    ClearMessage()
                }}
                messageWidth='90%'
            >
                {message}
            </SuccessMessageComponent>
    }
    else if (IsBackendOperationActive()) {
        component =
            <div className='mt-3'>
                <LoaderComponent spinnerColor='var(--dark-aqua)' spinnerSize='90px' />
            </div>
    }
    else {
        const submitButtonClass =
            'login-signup-submit-button ' + (isSubmitButtonActive ? 'login-signup-submit-button-active' : 'login-signup-submit-button-inactive')

        component =
            <>
                <div className='text-center mt-4'>
                    <Button type='submit' className={submitButtonClass} disabled={!isSubmitButtonActive}>
                        Reset Password
                    </Button>
                </div>
                {submitAreaType === SUBMIT_AREA_TYPE_REQUEST &&
                    <div className='mt-4 text-center'>
                        <p>
                            Remember your password?{' '}
                            <Link to={LOGIN_SIGNUP_PATH} className='text-link'>
                                Login
                            </Link>
                        </p>
                    </div>
                }
            </>
    }

    return component
}

PasswordResetSubmitAreaComponent.propTypes = {
    submitAreaType: PropTypes.string.isRequired,
    isSubmitButtonActive: PropTypes.bool.isRequired,
    IsBackendOperationActive: PropTypes.func.isRequired,
    messageType: PropTypes.string.isRequired,
    message: PropTypes.string.isRequired,
    isMessageDismissable: PropTypes.bool.isRequired,
    ClearMessage: PropTypes.func.isRequired,
}
