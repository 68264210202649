import { TARGET_SOUND_CH, TARGET_SOUND_F, TARGET_SOUND_G, TARGET_SOUND_J, TARGET_SOUND_K, TARGET_SOUND_L,
         TARGET_SOUND_R, TARGET_SOUND_S, TARGET_SOUND_SH, TARGET_SOUND_TH, TARGET_SOUND_V, TARGET_SOUND_Z
} from './SoundPositionLevelChoices'
import { LEVEL_1, LEVEL_2, LEVEL_3 } from './SoundPositionLevelChoices'
import {
    POSITION_INITIAL,
    POSITION_MEDIAL,
    POSITION_FINAL,
    POSITION_RECURRENT,
    POSITION_BL_BLEND,
    POSITION_FL_BLEND,
    POSITION_GL_BLEND,
    POSITION_KL_BLEND,
    POSITION_PL_BLEND,
    POSITION_SK_BLEND,
    POSITION_SL_BLEND,
    POSITION_SM_BLEND,
    POSITION_SN_BLEND,
    POSITION_SP_BLEND,
    POSITION_ST_BLEND,
    POSITION_SW_BLEND,
    POSITION_COMPLEX_BLEND,
    POSITION_BR_BLEND,
    POSITION_DR_BLEND,
    POSITION_FR_BLEND,
    POSITION_GR_BLEND,
    POSITION_KR_BLEND,
    POSITION_PR_BLEND,
    POSITION_TR_BLEND,
    POSITION_VOCALIC_AR,
    POSITION_VOCALIC_AIR,
    POSITION_VOCALIC_EAR,
    POSITION_VOCALIC_ER,
    POSITION_VOCALIC_IRE,
    POSITION_VOCALIC_OR,
    POSITION_VOICELESS_INITIAL,
    POSITION_VOICELESS_MEDIAL,
    POSITION_VOICELESS_FINAL,
    POSITION_VOICED_INITIAL,
    POSITION_VOICED_MEDIAL,
    POSITION_VOICED_FINAL,
} from './SoundPositionLevelChoices'
import { WORD_SET_CORE, WORD_SET_EXTENDED } from './SoundPositionLevelChoices'

var wordCodings = {}

function AddWordCoding(word, coding) {
    if (!('sound' in coding) || !('level' in coding) || !('position' in coding)) {
        console.log(`***** Error: Coding for ${word} is missing a required field.`)
        return
    }

    const sound = coding['sound']
    const level = coding['level']
    const position = coding['position']
    const variant = coding['variant'] || 1
    const text = coding['text'] || word
    const set = coding['set'] || WORD_SET_EXTENDED

    const wordKey = word.replace(/ /g, '_') + '~' + sound + '_' + position + '_' + variant

    if (wordKey in wordCodings) {
        console.log(`***** Error: ${wordKey} has multiple definitions.`)
        return
    }

    wordCodings[wordKey] = {}
    wordCodings[wordKey]['sound'] = sound
    wordCodings[wordKey]['level'] = level
    wordCodings[wordKey]['position'] = position
    wordCodings[wordKey]['text'] = text
    wordCodings[wordKey]['set'] = set
}

export function GetWordCodings() {
    return wordCodings
}

export function GetWordTargetSoundAndPosition(wordKey) {
    const wordCoding = wordCodings[wordKey]

    if (!wordCoding) {
        return []
    }

    return [wordCoding['sound'], wordCoding['position']]
}

export function GetWordText(wordKey) {
    if (!(wordKey in wordCodings)) {
        return ''
    }

    return wordCodings[wordKey]['text']
}

export function InitWordCodings() {
    // --------------------------------------------------------------------------------------------------
    //   Target sound CH
    // --------------------------------------------------------------------------------------------------

    // Target sound CH: Initial
    AddWordCoding('chain', { sound: TARGET_SOUND_CH, level: LEVEL_1, position: POSITION_INITIAL })
    AddWordCoding('chair', { sound: TARGET_SOUND_CH, level: LEVEL_1, position: POSITION_INITIAL, set: WORD_SET_CORE })
    AddWordCoding('chalk', { sound: TARGET_SOUND_CH, level: LEVEL_1, position: POSITION_INITIAL, set: WORD_SET_CORE })
    AddWordCoding('chart', { sound: TARGET_SOUND_CH, level: LEVEL_1, position: POSITION_INITIAL })
    AddWordCoding('check', { sound: TARGET_SOUND_CH, level: LEVEL_1, position: POSITION_INITIAL, set: WORD_SET_CORE })
    AddWordCoding('cheek', { sound: TARGET_SOUND_CH, level: LEVEL_1, position: POSITION_INITIAL, set: WORD_SET_CORE })
    AddWordCoding('cheep', { sound: TARGET_SOUND_CH, level: LEVEL_1, position: POSITION_INITIAL })
    AddWordCoding('cheer', { sound: TARGET_SOUND_CH, level: LEVEL_1, position: POSITION_INITIAL, set: WORD_SET_CORE })
    AddWordCoding('cheery', { sound: TARGET_SOUND_CH, level: LEVEL_1, position: POSITION_INITIAL })
    AddWordCoding('chew', { sound: TARGET_SOUND_CH, level: LEVEL_1, position: POSITION_INITIAL, set: WORD_SET_CORE })
    AddWordCoding('chick', { sound: TARGET_SOUND_CH, level: LEVEL_1, position: POSITION_INITIAL, set: WORD_SET_CORE })
    AddWordCoding('child', { sound: TARGET_SOUND_CH, level: LEVEL_1, position: POSITION_INITIAL, set: WORD_SET_CORE })
    AddWordCoding('chilly', { sound: TARGET_SOUND_CH, level: LEVEL_1, position: POSITION_INITIAL, set: WORD_SET_CORE })
    AddWordCoding('chin', { sound: TARGET_SOUND_CH, level: LEVEL_1, position: POSITION_INITIAL, set: WORD_SET_CORE })
    AddWordCoding('China', { sound: TARGET_SOUND_CH, level: LEVEL_1, position: POSITION_INITIAL, set: WORD_SET_CORE })
    AddWordCoding('chore', { sound: TARGET_SOUND_CH, level: LEVEL_1, position: POSITION_INITIAL, set: WORD_SET_CORE })

    AddWordCoding('champion', { sound: TARGET_SOUND_CH, level: LEVEL_2, position: POSITION_INITIAL, set: WORD_SET_CORE })
    AddWordCoding('chapter', { sound: TARGET_SOUND_CH, level: LEVEL_2, position: POSITION_INITIAL, set: WORD_SET_CORE })
    AddWordCoding('Charlie', { sound: TARGET_SOUND_CH, level: LEVEL_2, position: POSITION_INITIAL, set: WORD_SET_CORE })
    AddWordCoding('check mark', { sound: TARGET_SOUND_CH, level: LEVEL_2, position: POSITION_INITIAL, set: WORD_SET_CORE })
    AddWordCoding('checkerboard', { sound: TARGET_SOUND_CH, level: LEVEL_2, position: POSITION_INITIAL, set: WORD_SET_CORE })
    AddWordCoding('checkup', { sound: TARGET_SOUND_CH, level: LEVEL_2, position: POSITION_INITIAL, set: WORD_SET_CORE })
    AddWordCoding('cheerleader', { sound: TARGET_SOUND_CH, level: LEVEL_2, position: POSITION_INITIAL })
    AddWordCoding('cheetah', { sound: TARGET_SOUND_CH, level: LEVEL_2, position: POSITION_INITIAL, set: WORD_SET_CORE })
    AddWordCoding('cherry pie', { sound: TARGET_SOUND_CH, level: LEVEL_2, position: POSITION_INITIAL })
    AddWordCoding('chewing gum', { sound: TARGET_SOUND_CH, level: LEVEL_2, position: POSITION_INITIAL, set: WORD_SET_CORE })
    AddWordCoding('chicken', { sound: TARGET_SOUND_CH, level: LEVEL_2, position: POSITION_INITIAL, set: WORD_SET_CORE })
    AddWordCoding('chimney', { sound: TARGET_SOUND_CH, level: LEVEL_2, position: POSITION_INITIAL, set: WORD_SET_CORE })
    AddWordCoding('chipmunk', { sound: TARGET_SOUND_CH, level: LEVEL_2, position: POSITION_INITIAL, set: WORD_SET_CORE })
    AddWordCoding('chocolate', { sound: TARGET_SOUND_CH, level: LEVEL_2, position: POSITION_INITIAL, set: WORD_SET_CORE })
    AddWordCoding('chuckle', { sound: TARGET_SOUND_CH, level: LEVEL_2, position: POSITION_INITIAL })

    AddWordCoding('chasing', { sound: TARGET_SOUND_CH, level: LEVEL_3, position: POSITION_INITIAL, set: WORD_SET_CORE })
    AddWordCoding('checkers', { sound: TARGET_SOUND_CH, level: LEVEL_3, position: POSITION_INITIAL, set: WORD_SET_CORE })
    AddWordCoding('checklist', { sound: TARGET_SOUND_CH, level: LEVEL_3, position: POSITION_INITIAL, set: WORD_SET_CORE })
    AddWordCoding('cheddar cheese', { sound: TARGET_SOUND_CH, level: LEVEL_3, position: POSITION_INITIAL })
    AddWordCoding('cheerful', { sound: TARGET_SOUND_CH, level: LEVEL_3, position: POSITION_INITIAL, set: WORD_SET_CORE })
    AddWordCoding('cheese', { sound: TARGET_SOUND_CH, level: LEVEL_3, position: POSITION_INITIAL, set: WORD_SET_CORE })
    AddWordCoding('cheeseburger', { sound: TARGET_SOUND_CH, level: LEVEL_3, position: POSITION_INITIAL, set: WORD_SET_CORE })
    AddWordCoding('cherries', { sound: TARGET_SOUND_CH, level: LEVEL_3, position: POSITION_INITIAL, set: WORD_SET_CORE })
    AddWordCoding('chess', { sound: TARGET_SOUND_CH, level: LEVEL_3, position: POSITION_INITIAL, set: WORD_SET_CORE })
    AddWordCoding('chicken wire', { sound: TARGET_SOUND_CH, level: LEVEL_3, position: POSITION_INITIAL })
    AddWordCoding('children', { sound: TARGET_SOUND_CH, level: LEVEL_3, position: POSITION_INITIAL, set: WORD_SET_CORE })
    AddWordCoding('chimpanzee', { sound: TARGET_SOUND_CH, level: LEVEL_3, position: POSITION_INITIAL })
    AddWordCoding('chocolate chip', { sound: TARGET_SOUND_CH, level: LEVEL_3, position: POSITION_INITIAL, set: WORD_SET_CORE })
    AddWordCoding('chocolate chip cookie', { sound: TARGET_SOUND_CH, level: LEVEL_3, position: POSITION_INITIAL })
    AddWordCoding('choice', { sound: TARGET_SOUND_CH, level: LEVEL_3, position: POSITION_INITIAL, set: WORD_SET_CORE })
    AddWordCoding('choose', { sound: TARGET_SOUND_CH, level: LEVEL_3, position: POSITION_INITIAL })
    AddWordCoding('chopsticks', { sound: TARGET_SOUND_CH, level: LEVEL_3, position: POSITION_INITIAL, set: WORD_SET_CORE })

    // Target sound CH: Medial
    AddWordCoding('archery', { sound: TARGET_SOUND_CH, level: LEVEL_1, position: POSITION_MEDIAL })
    AddWordCoding('Archie', { sound: TARGET_SOUND_CH, level: LEVEL_1, position: POSITION_MEDIAL, set: WORD_SET_CORE })
    AddWordCoding('catcher', { sound: TARGET_SOUND_CH, level: LEVEL_1, position: POSITION_MEDIAL })
    AddWordCoding('crunchy', { sound: TARGET_SOUND_CH, level: LEVEL_1, position: POSITION_MEDIAL, set: WORD_SET_CORE })
    AddWordCoding('hatching', { sound: TARGET_SOUND_CH, level: LEVEL_1, position: POSITION_MEDIAL, set: WORD_SET_CORE })
    AddWordCoding('itching', { sound: TARGET_SOUND_CH, level: LEVEL_1, position: POSITION_MEDIAL, set: WORD_SET_CORE })
    AddWordCoding('ketchup', { sound: TARGET_SOUND_CH, level: LEVEL_1, position: POSITION_MEDIAL, set: WORD_SET_CORE })
    AddWordCoding('keychain', { sound: TARGET_SOUND_CH, level: LEVEL_1, position: POSITION_MEDIAL, set: WORD_SET_CORE })
    AddWordCoding('kitchen', { sound: TARGET_SOUND_CH, level: LEVEL_1, position: POSITION_MEDIAL, set: WORD_SET_CORE })
    AddWordCoding('orchard', { sound: TARGET_SOUND_CH, level: LEVEL_1, position: POSITION_MEDIAL })
    AddWordCoding('pitcher', { sound: TARGET_SOUND_CH, level: LEVEL_1, position: POSITION_MEDIAL, set: WORD_SET_CORE })
    AddWordCoding('reaching', { sound: TARGET_SOUND_CH, level: LEVEL_1, position: POSITION_MEDIAL, set: WORD_SET_CORE })
    AddWordCoding('teacher', { sound: TARGET_SOUND_CH, level: LEVEL_1, position: POSITION_MEDIAL, set: WORD_SET_CORE })
    AddWordCoding('touchdown', { sound: TARGET_SOUND_CH, level: LEVEL_1, position: POSITION_MEDIAL })

    AddWordCoding('adventure', { sound: TARGET_SOUND_CH, level: LEVEL_2, position: POSITION_MEDIAL, set: WORD_SET_CORE })
    AddWordCoding('armchair', { sound: TARGET_SOUND_CH, level: LEVEL_2, position: POSITION_MEDIAL })
    AddWordCoding('beachball', { sound: TARGET_SOUND_CH, level: LEVEL_2, position: POSITION_MEDIAL, set: WORD_SET_CORE })
    AddWordCoding('chocolate chip cookie', { sound: TARGET_SOUND_CH, level: LEVEL_2, position: POSITION_MEDIAL, set: WORD_SET_CORE })
    AddWordCoding('French bulldog', { sound: TARGET_SOUND_CH, level: LEVEL_2, position: POSITION_MEDIAL })
    AddWordCoding('furniture', { sound: TARGET_SOUND_CH, level: LEVEL_2, position: POSITION_MEDIAL, set: WORD_SET_CORE })
    AddWordCoding('grandchild', { sound: TARGET_SOUND_CH, level: LEVEL_2, position: POSITION_MEDIAL })
    AddWordCoding('lunchbox', { sound: TARGET_SOUND_CH, level: LEVEL_2, position: POSITION_MEDIAL, set: WORD_SET_CORE })
    AddWordCoding('marching band', { sound: TARGET_SOUND_CH, level: LEVEL_2, position: POSITION_MEDIAL, set: WORD_SET_CORE })
    AddWordCoding('picture', { sound: TARGET_SOUND_CH, level: LEVEL_2, position: POSITION_MEDIAL, set: WORD_SET_CORE })
    AddWordCoding('pitchfork', { sound: TARGET_SOUND_CH, level: LEVEL_2, position: POSITION_MEDIAL, set: WORD_SET_CORE })
    AddWordCoding('potato chips', { sound: TARGET_SOUND_CH, level: LEVEL_2, position: POSITION_MEDIAL })
    AddWordCoding('purchase', { sound: TARGET_SOUND_CH, level: LEVEL_2, position: POSITION_MEDIAL, set: WORD_SET_CORE })
    AddWordCoding('temperature', { sound: TARGET_SOUND_CH, level: LEVEL_2, position: POSITION_MEDIAL, set: WORD_SET_CORE })

    AddWordCoding('bleachers', { sound: TARGET_SOUND_CH, level: LEVEL_3, position: POSITION_MEDIAL })
    AddWordCoding('branches', { sound: TARGET_SOUND_CH, level: LEVEL_3, position: POSITION_MEDIAL, set: WORD_SET_CORE })
    AddWordCoding('corn chips', { sound: TARGET_SOUND_CH, level: LEVEL_3, position: POSITION_MEDIAL, set: WORD_SET_CORE })
    AddWordCoding('crutches', { sound: TARGET_SOUND_CH, level: LEVEL_3, position: POSITION_MEDIAL, set: WORD_SET_CORE })
    AddWordCoding('french fries', { sound: TARGET_SOUND_CH, level: LEVEL_3, position: POSITION_MEDIAL, set: WORD_SET_CORE })
    AddWordCoding('inches', { sound: TARGET_SOUND_CH, level: LEVEL_3, position: POSITION_MEDIAL, set: WORD_SET_CORE })
    AddWordCoding('peaches', { sound: TARGET_SOUND_CH, level: LEVEL_3, position: POSITION_MEDIAL, set: WORD_SET_CORE })
    AddWordCoding('question', { sound: TARGET_SOUND_CH, level: LEVEL_3, position: POSITION_MEDIAL, set: WORD_SET_CORE })
    AddWordCoding('roaches', { sound: TARGET_SOUND_CH, level: LEVEL_3, position: POSITION_MEDIAL })
    AddWordCoding('sandwiches', { sound: TARGET_SOUND_CH, level: LEVEL_3, position: POSITION_MEDIAL, set: WORD_SET_CORE })
    AddWordCoding('screech owl', { sound: TARGET_SOUND_CH, level: LEVEL_3, position: POSITION_MEDIAL })
    AddWordCoding('signature', { sound: TARGET_SOUND_CH, level: LEVEL_3, position: POSITION_MEDIAL })
    AddWordCoding('statue', { sound: TARGET_SOUND_CH, level: LEVEL_3, position: POSITION_MEDIAL, set: WORD_SET_CORE })
    AddWordCoding('Swiss cheese', { sound: TARGET_SOUND_CH, level: LEVEL_3, position: POSITION_MEDIAL })
    AddWordCoding('treasure chest', { sound: TARGET_SOUND_CH, level: LEVEL_3, position: POSITION_MEDIAL, set: WORD_SET_CORE })

    // Target sound CH: Final
    AddWordCoding('beach', { sound: TARGET_SOUND_CH, level: LEVEL_1, position: POSITION_FINAL, set: WORD_SET_CORE })
    AddWordCoding('catch', { sound: TARGET_SOUND_CH, level: LEVEL_1, position: POSITION_FINAL, set: WORD_SET_CORE })
    AddWordCoding('coach', { sound: TARGET_SOUND_CH, level: LEVEL_1, position: POSITION_FINAL })
    AddWordCoding('couch', { sound: TARGET_SOUND_CH, level: LEVEL_1, position: POSITION_FINAL, set: WORD_SET_CORE })
    AddWordCoding('itch', { sound: TARGET_SOUND_CH, level: LEVEL_1, position: POSITION_FINAL, set: WORD_SET_CORE })
    AddWordCoding('latch', { sound: TARGET_SOUND_CH, level: LEVEL_1, position: POSITION_FINAL })
    AddWordCoding('match', { sound: TARGET_SOUND_CH, level: LEVEL_1, position: POSITION_FINAL, set: WORD_SET_CORE })
    AddWordCoding('ouch', { sound: TARGET_SOUND_CH, level: LEVEL_1, position: POSITION_FINAL, set: WORD_SET_CORE })
    AddWordCoding('patch', { sound: TARGET_SOUND_CH, level: LEVEL_1, position: POSITION_FINAL })
    AddWordCoding('peach', { sound: TARGET_SOUND_CH, level: LEVEL_1, position: POSITION_FINAL, set: WORD_SET_CORE })
    AddWordCoding('reach', { sound: TARGET_SOUND_CH, level: LEVEL_1, position: POSITION_FINAL, set: WORD_SET_CORE })
    AddWordCoding('teach', { sound: TARGET_SOUND_CH, level: LEVEL_1, position: POSITION_FINAL, set: WORD_SET_CORE })
    AddWordCoding('touch', { sound: TARGET_SOUND_CH, level: LEVEL_1, position: POSITION_FINAL })
    AddWordCoding('watch', { sound: TARGET_SOUND_CH, level: LEVEL_1, position: POSITION_FINAL, set: WORD_SET_CORE })
    AddWordCoding('witch', { sound: TARGET_SOUND_CH, level: LEVEL_1, position: POSITION_FINAL })

    AddWordCoding('arch', { sound: TARGET_SOUND_CH, level: LEVEL_2, position: POSITION_FINAL })
    AddWordCoding('bench', { sound: TARGET_SOUND_CH, level: LEVEL_2, position: POSITION_FINAL, set: WORD_SET_CORE })
    AddWordCoding('bleach', { sound: TARGET_SOUND_CH, level: LEVEL_2, position: POSITION_FINAL })
    AddWordCoding('blowtorch', { sound: TARGET_SOUND_CH, level: LEVEL_2, position: POSITION_FINAL })
    AddWordCoding('cockroach', { sound: TARGET_SOUND_CH, level: LEVEL_2, position: POSITION_FINAL })
    AddWordCoding('crunch', { sound: TARGET_SOUND_CH, level: LEVEL_2, position: POSITION_FINAL, set: WORD_SET_CORE })
    AddWordCoding('fetch', { sound: TARGET_SOUND_CH, level: LEVEL_2, position: POSITION_FINAL, set: WORD_SET_CORE })
    AddWordCoding('grouch', { sound: TARGET_SOUND_CH, level: LEVEL_2, position: POSITION_FINAL, set: WORD_SET_CORE })
    AddWordCoding('lunch', { sound: TARGET_SOUND_CH, level: LEVEL_2, position: POSITION_FINAL, set: WORD_SET_CORE })
    AddWordCoding('March', { sound: TARGET_SOUND_CH, level: LEVEL_2, position: POSITION_FINAL, set: WORD_SET_CORE })
    AddWordCoding('porch', { sound: TARGET_SOUND_CH, level: LEVEL_2, position: POSITION_FINAL, set: WORD_SET_CORE })
    AddWordCoding('ranch', { sound: TARGET_SOUND_CH, level: LEVEL_2, position: POSITION_FINAL, set: WORD_SET_CORE })
    AddWordCoding('torch', { sound: TARGET_SOUND_CH, level: LEVEL_2, position: POSITION_FINAL, set: WORD_SET_CORE })
    AddWordCoding('wrench', { sound: TARGET_SOUND_CH, level: LEVEL_2, position: POSITION_FINAL, set: WORD_SET_CORE })

    AddWordCoding('hopscotch', { sound: TARGET_SOUND_CH, level: LEVEL_3, position: POSITION_FINAL, set: WORD_SET_CORE })
    AddWordCoding('ostrich', { sound: TARGET_SOUND_CH, level: LEVEL_3, position: POSITION_FINAL, set: WORD_SET_CORE })
    AddWordCoding('sandwich', { sound: TARGET_SOUND_CH, level: LEVEL_3, position: POSITION_FINAL, set: WORD_SET_CORE })
    AddWordCoding('scratch', { sound: TARGET_SOUND_CH, level: LEVEL_3, position: POSITION_FINAL, set: WORD_SET_CORE })
    AddWordCoding('sketch', { sound: TARGET_SOUND_CH, level: LEVEL_3, position: POSITION_FINAL })
    AddWordCoding('smooch', { sound: TARGET_SOUND_CH, level: LEVEL_3, position: POSITION_FINAL, set: WORD_SET_CORE })
    AddWordCoding('snatch', { sound: TARGET_SOUND_CH, level: LEVEL_3, position: POSITION_FINAL, set: WORD_SET_CORE })
    AddWordCoding('snitch', { sound: TARGET_SOUND_CH, level: LEVEL_3, position: POSITION_FINAL })
    AddWordCoding('speech', { sound: TARGET_SOUND_CH, level: LEVEL_3, position: POSITION_FINAL, set: WORD_SET_CORE })
    AddWordCoding('spinach', { sound: TARGET_SOUND_CH, level: LEVEL_3, position: POSITION_FINAL })
    AddWordCoding('splotch', { sound: TARGET_SOUND_CH, level: LEVEL_3, position: POSITION_FINAL })
    AddWordCoding('stopwatch', { sound: TARGET_SOUND_CH, level: LEVEL_3, position: POSITION_FINAL, set: WORD_SET_CORE })
    AddWordCoding('such', { sound: TARGET_SOUND_CH, level: LEVEL_3, position: POSITION_FINAL, set: WORD_SET_CORE })
    AddWordCoding('switch', { sound: TARGET_SOUND_CH, level: LEVEL_3, position: POSITION_FINAL, set: WORD_SET_CORE })

    // --------------------------------------------------------------------------------------------------
    //   Target sound F
    // --------------------------------------------------------------------------------------------------

    // Target sound F: Initial
    AddWordCoding('fair', { sound: TARGET_SOUND_F, level: LEVEL_1, position: POSITION_INITIAL })
    AddWordCoding('fairy', { sound: TARGET_SOUND_F, level: LEVEL_1, position: POSITION_INITIAL })
    AddWordCoding('fairy tale', { sound: TARGET_SOUND_F, level: LEVEL_1, position: POSITION_INITIAL, set: WORD_SET_CORE })
    AddWordCoding('fall', { sound: TARGET_SOUND_F, level: LEVEL_1, position: POSITION_INITIAL, set: WORD_SET_CORE })
    AddWordCoding('far', { sound: TARGET_SOUND_F, level: LEVEL_1, position: POSITION_INITIAL })
    AddWordCoding('ferry', { sound: TARGET_SOUND_F, level: LEVEL_1, position: POSITION_INITIAL })
    AddWordCoding('find', { sound: TARGET_SOUND_F, level: LEVEL_1, position: POSITION_INITIAL, set: WORD_SET_CORE })
    AddWordCoding('finger', { sound: TARGET_SOUND_F, level: LEVEL_1, position: POSITION_INITIAL })
    AddWordCoding('fire', { sound: TARGET_SOUND_F, level: LEVEL_1, position: POSITION_INITIAL, set: WORD_SET_CORE })
    AddWordCoding('foot', { sound: TARGET_SOUND_F, level: LEVEL_1, position: POSITION_INITIAL, set: WORD_SET_CORE })
    AddWordCoding('fork', { sound: TARGET_SOUND_F, level: LEVEL_1, position: POSITION_INITIAL, set: WORD_SET_CORE })
    AddWordCoding('four', { sound: TARGET_SOUND_F, level: LEVEL_1, position: POSITION_INITIAL, set: WORD_SET_CORE })
    AddWordCoding('fourteen', { sound: TARGET_SOUND_F, level: LEVEL_1, position: POSITION_INITIAL, set: WORD_SET_CORE })
    AddWordCoding('full', { sound: TARGET_SOUND_F, level: LEVEL_1, position: POSITION_INITIAL, set: WORD_SET_CORE })
    AddWordCoding('funny', { sound: TARGET_SOUND_F, level: LEVEL_1, position: POSITION_INITIAL, set: WORD_SET_CORE })

    AddWordCoding('face', { sound: TARGET_SOUND_F, level: LEVEL_2, position: POSITION_INITIAL, set: WORD_SET_CORE })
    AddWordCoding('farm', { sound: TARGET_SOUND_F, level: LEVEL_2, position: POSITION_INITIAL, set: WORD_SET_CORE })
    AddWordCoding('fast', { sound: TARGET_SOUND_F, level: LEVEL_2, position: POSITION_INITIAL, set: WORD_SET_CORE })
    AddWordCoding('father', { sound: TARGET_SOUND_F, level: LEVEL_2, position: POSITION_INITIAL, set: WORD_SET_CORE })
    AddWordCoding('fence', { sound: TARGET_SOUND_F, level: LEVEL_2, position: POSITION_INITIAL })
    AddWordCoding('fever', { sound: TARGET_SOUND_F, level: LEVEL_2, position: POSITION_INITIAL })
    AddWordCoding('fifth', { sound: TARGET_SOUND_F, level: LEVEL_2, position: POSITION_INITIAL, set: WORD_SET_CORE })
    AddWordCoding('fire truck', { sound: TARGET_SOUND_F, level: LEVEL_2, position: POSITION_INITIAL })
    AddWordCoding('first', { sound: TARGET_SOUND_F, level: LEVEL_2, position: POSITION_INITIAL, set: WORD_SET_CORE })
    AddWordCoding('first grade', { sound: TARGET_SOUND_F, level: LEVEL_2, position: POSITION_INITIAL })
    AddWordCoding('fish', { sound: TARGET_SOUND_F, level: LEVEL_2, position: POSITION_INITIAL, set: WORD_SET_CORE })
    AddWordCoding('fishing', { sound: TARGET_SOUND_F, level: LEVEL_2, position: POSITION_INITIAL })
    AddWordCoding('football', { sound: TARGET_SOUND_F, level: LEVEL_2, position: POSITION_INITIAL, set: WORD_SET_CORE })
    AddWordCoding('fossil', { sound: TARGET_SOUND_F, level: LEVEL_2, position: POSITION_INITIAL })
    AddWordCoding('fourth', { sound: TARGET_SOUND_F, level: LEVEL_2, position: POSITION_INITIAL, set: WORD_SET_CORE })
    AddWordCoding('phone', { sound: TARGET_SOUND_F, level: LEVEL_2, position: POSITION_INITIAL, set: WORD_SET_CORE })

    AddWordCoding('fabulous', { sound: TARGET_SOUND_F, level: LEVEL_3, position: POSITION_INITIAL })
    AddWordCoding('faces', { sound: TARGET_SOUND_F, level: LEVEL_3, position: POSITION_INITIAL, set: WORD_SET_CORE })
    AddWordCoding('faucet', { sound: TARGET_SOUND_F, level: LEVEL_3, position: POSITION_INITIAL, set: WORD_SET_CORE })
    AddWordCoding('February', { sound: TARGET_SOUND_F, level: LEVEL_3, position: POSITION_INITIAL })
    AddWordCoding('ferris wheel', { sound: TARGET_SOUND_F, level: LEVEL_3, position: POSITION_INITIAL })
    AddWordCoding('fiftieth', { sound: TARGET_SOUND_F, level: LEVEL_3, position: POSITION_INITIAL })
    AddWordCoding('filthy', { sound: TARGET_SOUND_F, level: LEVEL_3, position: POSITION_INITIAL })
    AddWordCoding('fire engine', { sound: TARGET_SOUND_F, level: LEVEL_3, position: POSITION_INITIAL, set: WORD_SET_CORE })
    AddWordCoding('fire fighter', { sound: TARGET_SOUND_F, level: LEVEL_3, position: POSITION_INITIAL, set: WORD_SET_CORE })
    AddWordCoding('fireplace', { sound: TARGET_SOUND_F, level: LEVEL_3, position: POSITION_INITIAL, set: WORD_SET_CORE })
    AddWordCoding('fireworks', { sound: TARGET_SOUND_F, level: LEVEL_3, position: POSITION_INITIAL, set: WORD_SET_CORE })
    AddWordCoding('fishbowl', { sound: TARGET_SOUND_F, level: LEVEL_3, position: POSITION_INITIAL, set: WORD_SET_CORE })
    AddWordCoding('four leaf clover', { sound: TARGET_SOUND_F, level: LEVEL_3, position: POSITION_INITIAL })
    AddWordCoding('fox', { sound: TARGET_SOUND_F, level: LEVEL_3, position: POSITION_INITIAL, set: WORD_SET_CORE })
    AddWordCoding('furniture', { sound: TARGET_SOUND_F, level: LEVEL_3, position: POSITION_INITIAL, set: WORD_SET_CORE })
    AddWordCoding('photographer', { sound: TARGET_SOUND_F, level: LEVEL_3, position: POSITION_INITIAL, set: WORD_SET_CORE })

    // Target sound F: Medial
    AddWordCoding('coffee', { sound: TARGET_SOUND_F, level: LEVEL_1, position: POSITION_MEDIAL, set: WORD_SET_CORE })
    AddWordCoding('colorful', { sound: TARGET_SOUND_F, level: LEVEL_1, position: POSITION_MEDIAL, set: WORD_SET_CORE })
    AddWordCoding('daffodil', { sound: TARGET_SOUND_F, level: LEVEL_1, position: POSITION_MEDIAL })
    AddWordCoding('different', { sound: TARGET_SOUND_F, level: LEVEL_1, position: POSITION_MEDIAL, set: WORD_SET_CORE })
    AddWordCoding('elephant', { sound: TARGET_SOUND_F, level: LEVEL_1, position: POSITION_MEDIAL, set: WORD_SET_CORE })
    AddWordCoding('gopher', { sound: TARGET_SOUND_F, level: LEVEL_1, position: POSITION_MEDIAL, set: WORD_SET_CORE })
    AddWordCoding('grandfather', { sound: TARGET_SOUND_F, level: LEVEL_1, position: POSITION_MEDIAL, set: WORD_SET_CORE })
    AddWordCoding('headphones', { sound: TARGET_SOUND_F, level: LEVEL_1, position: POSITION_MEDIAL, set: WORD_SET_CORE })
    AddWordCoding('laughing', { sound: TARGET_SOUND_F, level: LEVEL_1, position: POSITION_MEDIAL, set: WORD_SET_CORE })
    AddWordCoding('lifeguard', { sound: TARGET_SOUND_F, level: LEVEL_1, position: POSITION_MEDIAL, set: WORD_SET_CORE })
    AddWordCoding('rainfall', { sound: TARGET_SOUND_F, level: LEVEL_1, position: POSITION_MEDIAL })
    AddWordCoding('referee', { sound: TARGET_SOUND_F, level: LEVEL_1, position: POSITION_MEDIAL })
    AddWordCoding('terrific', { sound: TARGET_SOUND_F, level: LEVEL_1, position: POSITION_MEDIAL })
    AddWordCoding('traffic', { sound: TARGET_SOUND_F, level: LEVEL_1, position: POSITION_MEDIAL, set: WORD_SET_CORE })
    AddWordCoding('unfair', { sound: TARGET_SOUND_F, level: LEVEL_1, position: POSITION_MEDIAL })

    AddWordCoding('ant farm', { sound: TARGET_SOUND_F, level: LEVEL_2, position: POSITION_MEDIAL })
    AddWordCoding('before', { sound: TARGET_SOUND_F, level: LEVEL_2, position: POSITION_MEDIAL, set: WORD_SET_CORE })
    AddWordCoding('campfire', { sound: TARGET_SOUND_F, level: LEVEL_2, position: POSITION_MEDIAL, set: WORD_SET_CORE })
    AddWordCoding('difficult', { sound: TARGET_SOUND_F, level: LEVEL_2, position: POSITION_MEDIAL })
    AddWordCoding('goldfish', { sound: TARGET_SOUND_F, level: LEVEL_2, position: POSITION_MEDIAL, set: WORD_SET_CORE })
    AddWordCoding('infant', { sound: TARGET_SOUND_F, level: LEVEL_2, position: POSITION_MEDIAL, set: WORD_SET_CORE })
    AddWordCoding('muffin', { sound: TARGET_SOUND_F, level: LEVEL_2, position: POSITION_MEDIAL, set: WORD_SET_CORE })
    AddWordCoding('playful', { sound: TARGET_SOUND_F, level: LEVEL_2, position: POSITION_MEDIAL })
    AddWordCoding('safari', { sound: TARGET_SOUND_F, level: LEVEL_2, position: POSITION_MEDIAL, set: WORD_SET_CORE })
    AddWordCoding('seafood', { sound: TARGET_SOUND_F, level: LEVEL_2, position: POSITION_MEDIAL })
    AddWordCoding('snowfall', { sound: TARGET_SOUND_F, level: LEVEL_2, position: POSITION_MEDIAL })
    AddWordCoding('sofa', { sound: TARGET_SOUND_F, level: LEVEL_2, position: POSITION_MEDIAL, set: WORD_SET_CORE })
    AddWordCoding('Sophie', { sound: TARGET_SOUND_F, level: LEVEL_2, position: POSITION_MEDIAL, set: WORD_SET_CORE })
    AddWordCoding('trophy', { sound: TARGET_SOUND_F, level: LEVEL_2, position: POSITION_MEDIAL, set: WORD_SET_CORE })
    AddWordCoding('waffle', { sound: TARGET_SOUND_F, level: LEVEL_2, position: POSITION_MEDIAL, set: WORD_SET_CORE })

    AddWordCoding('blindfold', { sound: TARGET_SOUND_F, level: LEVEL_3, position: POSITION_MEDIAL })
    AddWordCoding('breakfast', { sound: TARGET_SOUND_F, level: LEVEL_3, position: POSITION_MEDIAL, set: WORD_SET_CORE })
    AddWordCoding('buffalo', { sound: TARGET_SOUND_F, level: LEVEL_3, position: POSITION_MEDIAL, set: WORD_SET_CORE })
    AddWordCoding('cafeteria', { sound: TARGET_SOUND_F, level: LEVEL_3, position: POSITION_MEDIAL, set: WORD_SET_CORE })
    AddWordCoding('cheerful', { sound: TARGET_SOUND_F, level: LEVEL_3, position: POSITION_MEDIAL, set: WORD_SET_CORE })
    AddWordCoding('coffeehouse', { sound: TARGET_SOUND_F, level: LEVEL_3, position: POSITION_MEDIAL })
    AddWordCoding('ear infection', { sound: TARGET_SOUND_F, level: LEVEL_3, position: POSITION_MEDIAL })
    AddWordCoding('fluffy', { sound: TARGET_SOUND_F, level: LEVEL_3, position: POSITION_MEDIAL })
    AddWordCoding('magnifying glass', { sound: TARGET_SOUND_F, level: LEVEL_3, position: POSITION_MEDIAL, set: WORD_SET_CORE })
    AddWordCoding('microphone', { sound: TARGET_SOUND_F, level: LEVEL_3, position: POSITION_MEDIAL, set: WORD_SET_CORE })
    AddWordCoding('Pacific Ocean', { sound: TARGET_SOUND_F, level: LEVEL_3, position: POSITION_MEDIAL, set: WORD_SET_CORE })
    AddWordCoding('pitchfork', { sound: TARGET_SOUND_F, level: LEVEL_3, position: POSITION_MEDIAL })
    AddWordCoding('spoonful', { sound: TARGET_SOUND_F, level: LEVEL_3, position: POSITION_MEDIAL, set: WORD_SET_CORE })
    AddWordCoding('starfish', { sound: TARGET_SOUND_F, level: LEVEL_3, position: POSITION_MEDIAL, set: WORD_SET_CORE })
    AddWordCoding('Swedish Fish', { sound: TARGET_SOUND_F, level: LEVEL_3, position: POSITION_MEDIAL, set: WORD_SET_CORE })
    AddWordCoding('swim fins', { sound: TARGET_SOUND_F, level: LEVEL_3, position: POSITION_MEDIAL })

    // Target sound F: Final
    AddWordCoding('arf', { sound: TARGET_SOUND_F, level: LEVEL_1, position: POSITION_FINAL })
    AddWordCoding('calf', { sound: TARGET_SOUND_F, level: LEVEL_1, position: POSITION_FINAL, variant: 1, set: WORD_SET_CORE })  // cow
    AddWordCoding('calf', { sound: TARGET_SOUND_F, level: LEVEL_1, position: POSITION_FINAL, variant: 2, set: WORD_SET_CORE })  // leg
    AddWordCoding('cliff', { sound: TARGET_SOUND_F, level: LEVEL_1, position: POSITION_FINAL })
    AddWordCoding('cough', { sound: TARGET_SOUND_F, level: LEVEL_1, position: POSITION_FINAL, set: WORD_SET_CORE })
    AddWordCoding('cuff', { sound: TARGET_SOUND_F, level: LEVEL_1, position: POSITION_FINAL })
    AddWordCoding('half', { sound: TARGET_SOUND_F, level: LEVEL_1, position: POSITION_FINAL, set: WORD_SET_CORE })
    AddWordCoding('hoof', { sound: TARGET_SOUND_F, level: LEVEL_1, position: POSITION_FINAL })
    AddWordCoding('if', { sound: TARGET_SOUND_F, level: LEVEL_1, position: POSITION_FINAL, set: WORD_SET_CORE })
    AddWordCoding('knife', { sound: TARGET_SOUND_F, level: LEVEL_1, position: POSITION_FINAL, set: WORD_SET_CORE })
    AddWordCoding('laugh', { sound: TARGET_SOUND_F, level: LEVEL_1, position: POSITION_FINAL, set: WORD_SET_CORE })
    AddWordCoding('leaf', { sound: TARGET_SOUND_F, level: LEVEL_1, position: POSITION_FINAL, set: WORD_SET_CORE })
    AddWordCoding('off', { sound: TARGET_SOUND_F, level: LEVEL_1, position: POSITION_FINAL, set: WORD_SET_CORE })
    AddWordCoding('ref', { sound: TARGET_SOUND_F, level: LEVEL_1, position: POSITION_FINAL })
    AddWordCoding('rough', { sound: TARGET_SOUND_F, level: LEVEL_1, position: POSITION_FINAL, set: WORD_SET_CORE })

    AddWordCoding('bluff', { sound: TARGET_SOUND_F, level: LEVEL_2, position: POSITION_FINAL })
    AddWordCoding('chef', { sound: TARGET_SOUND_F, level: LEVEL_2, position: POSITION_FINAL, set: WORD_SET_CORE })
    AddWordCoding('elf', { sound: TARGET_SOUND_F, level: LEVEL_2, position: POSITION_FINAL, set: WORD_SET_CORE })
    AddWordCoding('golf', { sound: TARGET_SOUND_F, level: LEVEL_2, position: POSITION_FINAL, set: WORD_SET_CORE })
    AddWordCoding('goof', { sound: TARGET_SOUND_F, level: LEVEL_2, position: POSITION_FINAL })
    AddWordCoding('loaf', { sound: TARGET_SOUND_F, level: LEVEL_2, position: POSITION_FINAL, set: WORD_SET_CORE })
    AddWordCoding('roof', { sound: TARGET_SOUND_F, level: LEVEL_2, position: POSITION_FINAL, set: WORD_SET_CORE })
    AddWordCoding('safe', { sound: TARGET_SOUND_F, level: LEVEL_2, position: POSITION_FINAL })
    AddWordCoding('scarf', { sound: TARGET_SOUND_F, level: LEVEL_2, position: POSITION_FINAL, set: WORD_SET_CORE })
    AddWordCoding('shelf', { sound: TARGET_SOUND_F, level: LEVEL_2, position: POSITION_FINAL, set: WORD_SET_CORE })
    AddWordCoding('sniff', { sound: TARGET_SOUND_F, level: LEVEL_2, position: POSITION_FINAL, set: WORD_SET_CORE })
    AddWordCoding('stuff', { sound: TARGET_SOUND_F, level: LEVEL_2, position: POSITION_FINAL })
    AddWordCoding('surf', { sound: TARGET_SOUND_F, level: LEVEL_2, position: POSITION_FINAL })
    AddWordCoding('wolf', { sound: TARGET_SOUND_F, level: LEVEL_2, position: POSITION_FINAL, set: WORD_SET_CORE })

    AddWordCoding('autograph', { sound: TARGET_SOUND_F, level: LEVEL_3, position: POSITION_FINAL })
    AddWordCoding('blastoff', { sound: TARGET_SOUND_F, level: LEVEL_3, position: POSITION_FINAL, set: WORD_SET_CORE })
    AddWordCoding('bookshelf', { sound: TARGET_SOUND_F, level: LEVEL_3, position: POSITION_FINAL, set: WORD_SET_CORE })
    AddWordCoding('check off', { sound: TARGET_SOUND_F, level: LEVEL_3, position: POSITION_FINAL })
    AddWordCoding('cloverleaf', { sound: TARGET_SOUND_F, level: LEVEL_3, position: POSITION_FINAL })
    AddWordCoding('coral reef', { sound: TARGET_SOUND_F, level: LEVEL_3, position: POSITION_FINAL, set: WORD_SET_CORE })
    AddWordCoding('earmuff', { sound: TARGET_SOUND_F, level: LEVEL_3, position: POSITION_FINAL, set: WORD_SET_CORE })
    AddWordCoding('fire chief', { sound: TARGET_SOUND_F, level: LEVEL_3, position: POSITION_FINAL, set: WORD_SET_CORE })
    AddWordCoding('giraffe', { sound: TARGET_SOUND_F, level: LEVEL_3, position: POSITION_FINAL, set: WORD_SET_CORE })
    AddWordCoding('herself', { sound: TARGET_SOUND_F, level: LEVEL_3, position: POSITION_FINAL, set: WORD_SET_CORE })
    AddWordCoding('himself', { sound: TARGET_SOUND_F, level: LEVEL_3, position: POSITION_FINAL, set: WORD_SET_CORE })
    AddWordCoding('paragraph', { sound: TARGET_SOUND_F, level: LEVEL_3, position: POSITION_FINAL })
    AddWordCoding('photograph', { sound: TARGET_SOUND_F, level: LEVEL_3, position: POSITION_FINAL, set: WORD_SET_CORE })
    AddWordCoding('takeoff', { sound: TARGET_SOUND_F, level: LEVEL_3, position: POSITION_FINAL })
    AddWordCoding('thief', { sound: TARGET_SOUND_F, level: LEVEL_3, position: POSITION_FINAL, set: WORD_SET_CORE })

    // --------------------------------------------------------------------------------------------------
    //   Target sound G
    // --------------------------------------------------------------------------------------------------

    // Target sound G: Initial
    AddWordCoding('game', { sound: TARGET_SOUND_G, level: LEVEL_1, position: POSITION_INITIAL, set: WORD_SET_CORE })
    AddWordCoding('gas', { sound: TARGET_SOUND_G, level: LEVEL_1, position: POSITION_INITIAL, set: WORD_SET_CORE })
    AddWordCoding('geese', { sound: TARGET_SOUND_G, level: LEVEL_1, position: POSITION_INITIAL, set: WORD_SET_CORE })
    AddWordCoding('give', { sound: TARGET_SOUND_G, level: LEVEL_1, position: POSITION_INITIAL, set: WORD_SET_CORE })
    AddWordCoding('go', { sound: TARGET_SOUND_G, level: LEVEL_1, position: POSITION_INITIAL, set: WORD_SET_CORE })
    AddWordCoding('gum', { sound: TARGET_SOUND_G, level: LEVEL_1, position: POSITION_INITIAL, set: WORD_SET_CORE })
    AddWordCoding('goal', { sound: TARGET_SOUND_G, level: LEVEL_1, position: POSITION_INITIAL, set: WORD_SET_CORE })
    AddWordCoding('golf', { sound: TARGET_SOUND_G, level: LEVEL_1, position: POSITION_INITIAL, set: WORD_SET_CORE })
    AddWordCoding('guess', { sound: TARGET_SOUND_G, level: LEVEL_1, position: POSITION_INITIAL, set: WORD_SET_CORE })
    AddWordCoding('goof', { sound: TARGET_SOUND_G, level: LEVEL_1, position: POSITION_INITIAL, set: WORD_SET_CORE })
    AddWordCoding('goose', { sound: TARGET_SOUND_G, level: LEVEL_1, position: POSITION_INITIAL, set: WORD_SET_CORE })
    AddWordCoding('Gus', { sound: TARGET_SOUND_G, level: LEVEL_1, position: POSITION_INITIAL, set: WORD_SET_CORE })

    AddWordCoding('gallon', { sound: TARGET_SOUND_G, level: LEVEL_2, position: POSITION_INITIAL, set: WORD_SET_CORE })
    AddWordCoding('gate', { sound: TARGET_SOUND_G, level: LEVEL_2, position: POSITION_INITIAL, set: WORD_SET_CORE })
    AddWordCoding('gift', { sound: TARGET_SOUND_G, level: LEVEL_2, position: POSITION_INITIAL, set: WORD_SET_CORE })
    AddWordCoding('ghost', { sound: TARGET_SOUND_G, level: LEVEL_2, position: POSITION_INITIAL, set: WORD_SET_CORE })
    AddWordCoding('gobbler', { sound: TARGET_SOUND_G, level: LEVEL_2, position: POSITION_INITIAL, set: WORD_SET_CORE })
    AddWordCoding('goblin', { sound: TARGET_SOUND_G, level: LEVEL_2, position: POSITION_INITIAL, set: WORD_SET_CORE })
    AddWordCoding('going', { sound: TARGET_SOUND_G, level: LEVEL_2, position: POSITION_INITIAL, set: WORD_SET_CORE })
    AddWordCoding('gossip', { sound: TARGET_SOUND_G, level: LEVEL_2, position: POSITION_INITIAL, set: WORD_SET_CORE })
    AddWordCoding('guessing', { sound: TARGET_SOUND_G, level: LEVEL_2, position: POSITION_INITIAL, set: WORD_SET_CORE })
    AddWordCoding('guitar', { sound: TARGET_SOUND_G, level: LEVEL_2, position: POSITION_INITIAL, set: WORD_SET_CORE })
    AddWordCoding('goat', { sound: TARGET_SOUND_G, level: LEVEL_2, position: POSITION_INITIAL, set: WORD_SET_CORE })
    AddWordCoding('goldfish', { sound: TARGET_SOUND_G, level: LEVEL_2, position: POSITION_INITIAL, set: WORD_SET_CORE })

    AddWordCoding('garage', { sound: TARGET_SOUND_G, level: LEVEL_3, position: POSITION_INITIAL, set: WORD_SET_CORE })
    AddWordCoding('garbage', { sound: TARGET_SOUND_G, level: LEVEL_3, position: POSITION_INITIAL, set: WORD_SET_CORE })
    AddWordCoding('garden', { sound: TARGET_SOUND_G, level: LEVEL_3, position: POSITION_INITIAL, set: WORD_SET_CORE })
    AddWordCoding('gas station', { sound: TARGET_SOUND_G, level: LEVEL_3, position: POSITION_INITIAL, set: WORD_SET_CORE })
    AddWordCoding('giggling', { sound: TARGET_SOUND_G, level: LEVEL_3, position: POSITION_INITIAL, set: WORD_SET_CORE })
    AddWordCoding('goblet', { sound: TARGET_SOUND_G, level: LEVEL_3, position: POSITION_INITIAL, set: WORD_SET_CORE })
    AddWordCoding('Golden Gate Bridge', { sound: TARGET_SOUND_G, level: LEVEL_3, position: POSITION_INITIAL, set: WORD_SET_CORE })
    AddWordCoding('gondolier', { sound: TARGET_SOUND_G, level: LEVEL_3, position: POSITION_INITIAL, set: WORD_SET_CORE })
    AddWordCoding('good', { sound: TARGET_SOUND_G, level: LEVEL_3, position: POSITION_INITIAL, set: WORD_SET_CORE })
    AddWordCoding('goodbye', { sound: TARGET_SOUND_G, level: LEVEL_3, position: POSITION_INITIAL, set: WORD_SET_CORE })
    AddWordCoding('gorilla', { sound: TARGET_SOUND_G, level: LEVEL_3, position: POSITION_INITIAL, set: WORD_SET_CORE })
    AddWordCoding('gumdrops', { sound: TARGET_SOUND_G, level: LEVEL_3, position: POSITION_INITIAL, set: WORD_SET_CORE })

    // Target sound G: Medial
    AddWordCoding('again', { sound: TARGET_SOUND_G, level: LEVEL_1, position: POSITION_MEDIAL, set: WORD_SET_CORE })
    AddWordCoding('beagle', { sound: TARGET_SOUND_G, level: LEVEL_1, position: POSITION_MEDIAL, set: WORD_SET_CORE })
    AddWordCoding('begin', { sound: TARGET_SOUND_G, level: LEVEL_1, position: POSITION_MEDIAL, set: WORD_SET_CORE })
    AddWordCoding('eagle', { sound: TARGET_SOUND_G, level: LEVEL_1, position: POSITION_MEDIAL, set: WORD_SET_CORE })
    AddWordCoding('slogan', { sound: TARGET_SOUND_G, level: LEVEL_1, position: POSITION_MEDIAL, set: WORD_SET_CORE })
    AddWordCoding('snuggle', { sound: TARGET_SOUND_G, level: LEVEL_1, position: POSITION_MEDIAL, set: WORD_SET_CORE })
    AddWordCoding('tiger', { sound: TARGET_SOUND_G, level: LEVEL_1, position: POSITION_MEDIAL, set: WORD_SET_CORE })
    AddWordCoding('wagon', { sound: TARGET_SOUND_G, level: LEVEL_1, position: POSITION_MEDIAL, set: WORD_SET_CORE })
    AddWordCoding('wiggle', { sound: TARGET_SOUND_G, level: LEVEL_1, position: POSITION_MEDIAL, set: WORD_SET_CORE })
    AddWordCoding('Lego', { sound: TARGET_SOUND_G, level: LEVEL_1, position: POSITION_MEDIAL, set: WORD_SET_CORE })
    AddWordCoding('ogre', { sound: TARGET_SOUND_G, level: LEVEL_1, position: POSITION_MEDIAL, set: WORD_SET_CORE })
    AddWordCoding('bigger', { sound: TARGET_SOUND_G, level: LEVEL_1, position: POSITION_MEDIAL, set: WORD_SET_CORE })

    AddWordCoding('August', { sound: TARGET_SOUND_G, level: LEVEL_2, position: POSITION_MEDIAL, set: WORD_SET_CORE })
    AddWordCoding('beginning', { sound: TARGET_SOUND_G, level: LEVEL_2, position: POSITION_MEDIAL, set: WORD_SET_CORE })
    AddWordCoding('dragon', { sound: TARGET_SOUND_G, level: LEVEL_2, position: POSITION_MEDIAL, set: WORD_SET_CORE })
    AddWordCoding('ignore', { sound: TARGET_SOUND_G, level: LEVEL_2, position: POSITION_MEDIAL, set: WORD_SET_CORE })
    AddWordCoding('Oregon', { sound: TARGET_SOUND_G, level: LEVEL_2, position: POSITION_MEDIAL, set: WORD_SET_CORE })
    AddWordCoding('regular', { sound: TARGET_SOUND_G, level: LEVEL_2, position: POSITION_MEDIAL, set: WORD_SET_CORE })
    AddWordCoding('together', { sound: TARGET_SOUND_G, level: LEVEL_2, position: POSITION_MEDIAL, set: WORD_SET_CORE })
    AddWordCoding('triangle', { sound: TARGET_SOUND_G, level: LEVEL_2, position: POSITION_MEDIAL, set: WORD_SET_CORE })
    AddWordCoding('igloo', { sound: TARGET_SOUND_G, level: LEVEL_2, position: POSITION_MEDIAL, set: WORD_SET_CORE })
    AddWordCoding('magnet', { sound: TARGET_SOUND_G, level: LEVEL_2, position: POSITION_MEDIAL, set: WORD_SET_CORE })
    AddWordCoding('finger', { sound: TARGET_SOUND_G, level: LEVEL_2, position: POSITION_MEDIAL, set: WORD_SET_CORE })
    AddWordCoding('jogger', { sound: TARGET_SOUND_G, level: LEVEL_2, position: POSITION_MEDIAL, set: WORD_SET_CORE })

    AddWordCoding('alligator', { sound: TARGET_SOUND_G, level: LEVEL_3, position: POSITION_MEDIAL, set: WORD_SET_CORE })
    AddWordCoding('asparagus', { sound: TARGET_SOUND_G, level: LEVEL_3, position: POSITION_MEDIAL, set: WORD_SET_CORE })
    AddWordCoding('dragonfly', { sound: TARGET_SOUND_G, level: LEVEL_3, position: POSITION_MEDIAL, set: WORD_SET_CORE })
    AddWordCoding('eggplant', { sound: TARGET_SOUND_G, level: LEVEL_3, position: POSITION_MEDIAL, set: WORD_SET_CORE })
    AddWordCoding('Flag Day', { sound: TARGET_SOUND_G, level: LEVEL_3, position: POSITION_MEDIAL })
    AddWordCoding('flagpole', { sound: TARGET_SOUND_G, level: LEVEL_3, position: POSITION_MEDIAL, set: WORD_SET_CORE })
    AddWordCoding('flamingo', { sound: TARGET_SOUND_G, level: LEVEL_3, position: POSITION_MEDIAL, set: WORD_SET_CORE })
    AddWordCoding('gigantic', { sound: TARGET_SOUND_G, level: LEVEL_3, position: POSITION_MEDIAL })
    AddWordCoding('hamburger', { sound: TARGET_SOUND_G, level: LEVEL_3, position: POSITION_MEDIAL, set: WORD_SET_CORE })
    AddWordCoding('kangaroo', { sound: TARGET_SOUND_G, level: LEVEL_3, position: POSITION_MEDIAL, set: WORD_SET_CORE })
    AddWordCoding('lifeguard', { sound: TARGET_SOUND_G, level: LEVEL_3, position: POSITION_MEDIAL, set: WORD_SET_CORE })
    AddWordCoding('orangutan', { sound: TARGET_SOUND_G, level: LEVEL_3, position: POSITION_MEDIAL, set: WORD_SET_CORE })
    AddWordCoding('pigtail', { sound: TARGET_SOUND_G, level: LEVEL_3, position: POSITION_MEDIAL })
    AddWordCoding('Singapore', { sound: TARGET_SOUND_G, level: LEVEL_3, position: POSITION_MEDIAL })
    AddWordCoding('spaghetti', { sound: TARGET_SOUND_G, level: LEVEL_3, position: POSITION_MEDIAL, set: WORD_SET_CORE })
    AddWordCoding('target', { sound: TARGET_SOUND_G, level: LEVEL_3, position: POSITION_MEDIAL, set: WORD_SET_CORE })

    // Target sound G: Final
    AddWordCoding('bag', { sound: TARGET_SOUND_G, level: LEVEL_1, position: POSITION_FINAL, set: WORD_SET_CORE })
    AddWordCoding('big', { sound: TARGET_SOUND_G, level: LEVEL_1, position: POSITION_FINAL, set: WORD_SET_CORE })
    AddWordCoding('bug', { sound: TARGET_SOUND_G, level: LEVEL_1, position: POSITION_FINAL, set: WORD_SET_CORE })
    AddWordCoding('egg', { sound: TARGET_SOUND_G, level: LEVEL_1, position: POSITION_FINAL, set: WORD_SET_CORE })
    AddWordCoding('hug', { sound: TARGET_SOUND_G, level: LEVEL_1, position: POSITION_FINAL, set: WORD_SET_CORE })
    AddWordCoding('leg', { sound: TARGET_SOUND_G, level: LEVEL_1, position: POSITION_FINAL, set: WORD_SET_CORE })
    AddWordCoding('log', { sound: TARGET_SOUND_G, level: LEVEL_1, position: POSITION_FINAL, set: WORD_SET_CORE })
    AddWordCoding('pig', { sound: TARGET_SOUND_G, level: LEVEL_1, position: POSITION_FINAL, set: WORD_SET_CORE })
    AddWordCoding('rug', { sound: TARGET_SOUND_G, level: LEVEL_1, position: POSITION_FINAL, set: WORD_SET_CORE })
    AddWordCoding('rag', { sound: TARGET_SOUND_G, level: LEVEL_1, position: POSITION_FINAL, set: WORD_SET_CORE })
    AddWordCoding('mug', { sound: TARGET_SOUND_G, level: LEVEL_1, position: POSITION_FINAL, set: WORD_SET_CORE })
    AddWordCoding('wag', { sound: TARGET_SOUND_G, level: LEVEL_1, position: POSITION_FINAL, set: WORD_SET_CORE })

    AddWordCoding('flag', { sound: TARGET_SOUND_G, level: LEVEL_2, position: POSITION_FINAL, set: WORD_SET_CORE })
    AddWordCoding('frog', { sound: TARGET_SOUND_G, level: LEVEL_2, position: POSITION_FINAL, set: WORD_SET_CORE })
    AddWordCoding('iceberg', { sound: TARGET_SOUND_G, level: LEVEL_2, position: POSITION_FINAL, set: WORD_SET_CORE })
    AddWordCoding('plug', { sound: TARGET_SOUND_G, level: LEVEL_2, position: POSITION_FINAL, set: WORD_SET_CORE })
    AddWordCoding('slug', { sound: TARGET_SOUND_G, level: LEVEL_2, position: POSITION_FINAL, set: WORD_SET_CORE })
    AddWordCoding('snug', { sound: TARGET_SOUND_G, level: LEVEL_2, position: POSITION_FINAL, set: WORD_SET_CORE })
    AddWordCoding('bookbag', { sound: TARGET_SOUND_G, level: LEVEL_2, position: POSITION_FINAL, set: WORD_SET_CORE })
    AddWordCoding('jog', { sound: TARGET_SOUND_G, level: LEVEL_2, position: POSITION_FINAL, set: WORD_SET_CORE })
    AddWordCoding('brag', { sound: TARGET_SOUND_G, level: LEVEL_2, position: POSITION_FINAL, set: WORD_SET_CORE })
    AddWordCoding('drag', { sound: TARGET_SOUND_G, level: LEVEL_2, position: POSITION_FINAL, set: WORD_SET_CORE })
    AddWordCoding('stag', { sound: TARGET_SOUND_G, level: LEVEL_2, position: POSITION_FINAL, set: WORD_SET_CORE })
    AddWordCoding('ear plug', { sound: TARGET_SOUND_G, level: LEVEL_2, position: POSITION_FINAL, set: WORD_SET_CORE })

    AddWordCoding('dig', { sound: TARGET_SOUND_G, level: LEVEL_3, position: POSITION_FINAL, set: WORD_SET_CORE })
    AddWordCoding('dog', { sound: TARGET_SOUND_G, level: LEVEL_3, position: POSITION_FINAL, set: WORD_SET_CORE })
    AddWordCoding('French bulldog', { sound: TARGET_SOUND_G, level: LEVEL_3, position: POSITION_FINAL, set: WORD_SET_CORE })
    AddWordCoding('garbage bag', { sound: TARGET_SOUND_G, level: LEVEL_3, position: POSITION_FINAL, set: WORD_SET_CORE })
    AddWordCoding('hot dog', { sound: TARGET_SOUND_G, level: LEVEL_3, position: POSITION_FINAL, set: WORD_SET_CORE })
    AddWordCoding('ladybug', { sound: TARGET_SOUND_G, level: LEVEL_3, position: POSITION_FINAL, set: WORD_SET_CORE })
    AddWordCoding('lightning bug', { sound: TARGET_SOUND_G, level: LEVEL_3, position: POSITION_FINAL, set: WORD_SET_CORE })
    AddWordCoding('sled dog', { sound: TARGET_SOUND_G, level: LEVEL_3, position: POSITION_FINAL, set: WORD_SET_CORE })
    AddWordCoding('price tag', { sound: TARGET_SOUND_G, level: LEVEL_3, position: POSITION_FINAL, set: WORD_SET_CORE })
    AddWordCoding('Doug', { sound: TARGET_SOUND_G, level: LEVEL_3, position: POSITION_FINAL, set: WORD_SET_CORE })
    AddWordCoding('tote bag', { sound: TARGET_SOUND_G, level: LEVEL_3, position: POSITION_FINAL, set: WORD_SET_CORE })
    AddWordCoding('guinea pig', { sound: TARGET_SOUND_G, level: LEVEL_3, position: POSITION_FINAL, set: WORD_SET_CORE })

    // --------------------------------------------------------------------------------------------------
    //   Target sound J
    // --------------------------------------------------------------------------------------------------

    // Target sound J: Initial
    AddWordCoding('giant', { sound: TARGET_SOUND_J, level: LEVEL_1, position: POSITION_INITIAL, set: WORD_SET_CORE })
    AddWordCoding('gym', { sound: TARGET_SOUND_J, level: LEVEL_1, position: POSITION_INITIAL })
    AddWordCoding('jam', { sound: TARGET_SOUND_J, level: LEVEL_1, position: POSITION_INITIAL, set: WORD_SET_CORE })
    AddWordCoding('jar', { sound: TARGET_SOUND_J, level: LEVEL_1, position: POSITION_INITIAL, set: WORD_SET_CORE })
    AddWordCoding('jeep', { sound: TARGET_SOUND_J, level: LEVEL_1, position: POSITION_INITIAL, set: WORD_SET_CORE })
    AddWordCoding('jelly', { sound: TARGET_SOUND_J, level: LEVEL_1, position: POSITION_INITIAL, set: WORD_SET_CORE })
    AddWordCoding('jet', { sound: TARGET_SOUND_J, level: LEVEL_1, position: POSITION_INITIAL, set: WORD_SET_CORE })
    AddWordCoding('jog', { sound: TARGET_SOUND_J, level: LEVEL_1, position: POSITION_INITIAL })
    AddWordCoding('joke', { sound: TARGET_SOUND_J, level: LEVEL_1, position: POSITION_INITIAL, set: WORD_SET_CORE })
    AddWordCoding('joy', { sound: TARGET_SOUND_J, level: LEVEL_1, position: POSITION_INITIAL })
    AddWordCoding('jug', { sound: TARGET_SOUND_J, level: LEVEL_1, position: POSITION_INITIAL })
    AddWordCoding('July', { sound: TARGET_SOUND_J, level: LEVEL_1, position: POSITION_INITIAL, set: WORD_SET_CORE })
    AddWordCoding('jump', { sound: TARGET_SOUND_J, level: LEVEL_1, position: POSITION_INITIAL, set: WORD_SET_CORE })
    AddWordCoding('June', { sound: TARGET_SOUND_J, level: LEVEL_1, position: POSITION_INITIAL, set: WORD_SET_CORE })

    AddWordCoding('geography', { sound: TARGET_SOUND_J, level: LEVEL_2, position: POSITION_INITIAL })
    AddWordCoding('geometry', { sound: TARGET_SOUND_J, level: LEVEL_2, position: POSITION_INITIAL })
    AddWordCoding('gigantic', { sound: TARGET_SOUND_J, level: LEVEL_2, position: POSITION_INITIAL })
    AddWordCoding('giraffe', { sound: TARGET_SOUND_J, level: LEVEL_2, position: POSITION_INITIAL, set: WORD_SET_CORE })
    AddWordCoding('jack o lantern', { sound: TARGET_SOUND_J, level: LEVEL_2, position: POSITION_INITIAL, set: WORD_SET_CORE, text: "jack o'lantern" })
    AddWordCoding('jacket', { sound: TARGET_SOUND_J, level: LEVEL_2, position: POSITION_INITIAL, set: WORD_SET_CORE })
    AddWordCoding('jackrabbit', { sound: TARGET_SOUND_J, level: LEVEL_2, position: POSITION_INITIAL })
    AddWordCoding('jaguar', { sound: TARGET_SOUND_J, level: LEVEL_2, position: POSITION_INITIAL, set: WORD_SET_CORE })
    AddWordCoding('January', { sound: TARGET_SOUND_J, level: LEVEL_2, position: POSITION_INITIAL, set: WORD_SET_CORE })
    AddWordCoding('Japan', { sound: TARGET_SOUND_J, level: LEVEL_2, position: POSITION_INITIAL, set: WORD_SET_CORE })
    AddWordCoding('jogger', { sound: TARGET_SOUND_J, level: LEVEL_2, position: POSITION_INITIAL, set: WORD_SET_CORE })
    AddWordCoding('juggler', { sound: TARGET_SOUND_J, level: LEVEL_2, position: POSITION_INITIAL, set: WORD_SET_CORE })
    AddWordCoding('juggling', { sound: TARGET_SOUND_J, level: LEVEL_2, position: POSITION_INITIAL, set: WORD_SET_CORE })
    AddWordCoding('jungle', { sound: TARGET_SOUND_J, level: LEVEL_2, position: POSITION_INITIAL, set: WORD_SET_CORE })

    AddWordCoding('genius', { sound: TARGET_SOUND_J, level: LEVEL_3, position: POSITION_INITIAL })
    AddWordCoding('George', { sound: TARGET_SOUND_J, level: LEVEL_3, position: POSITION_INITIAL, set: WORD_SET_CORE })
    AddWordCoding('Georgia', { sound: TARGET_SOUND_J, level: LEVEL_3, position: POSITION_INITIAL, set: WORD_SET_CORE })
    AddWordCoding('germs', { sound: TARGET_SOUND_J, level: LEVEL_3, position: POSITION_INITIAL, set: WORD_SET_CORE })
    AddWordCoding('gingersnap', { sound: TARGET_SOUND_J, level: LEVEL_3, position: POSITION_INITIAL, set: WORD_SET_CORE })
    AddWordCoding('gymnastics', { sound: TARGET_SOUND_J, level: LEVEL_3, position: POSITION_INITIAL, set: WORD_SET_CORE })
    AddWordCoding('jack-in-the-box', { sound: TARGET_SOUND_J, level: LEVEL_3, position: POSITION_INITIAL, set: WORD_SET_CORE })
    AddWordCoding('Jason', { sound: TARGET_SOUND_J, level: LEVEL_3, position: POSITION_INITIAL, set: WORD_SET_CORE })
    AddWordCoding('jealous', { sound: TARGET_SOUND_J, level: LEVEL_3, position: POSITION_INITIAL })
    AddWordCoding('jealousy', { sound: TARGET_SOUND_J, level: LEVEL_3, position: POSITION_INITIAL })
    AddWordCoding('jeans', { sound: TARGET_SOUND_J, level: LEVEL_3, position: POSITION_INITIAL, set: WORD_SET_CORE })
    AddWordCoding('jellybeans', { sound: TARGET_SOUND_J, level: LEVEL_3, position: POSITION_INITIAL, set: WORD_SET_CORE })
    AddWordCoding('joyous', { sound: TARGET_SOUND_J, level: LEVEL_3, position: POSITION_INITIAL })
    AddWordCoding('juice', { sound: TARGET_SOUND_J, level: LEVEL_3, position: POSITION_INITIAL, set: WORD_SET_CORE })

    // Target sound J: Medial
    AddWordCoding('banjo', { sound: TARGET_SOUND_J, level: LEVEL_1, position: POSITION_MEDIAL })
    AddWordCoding('blue jay', { sound: TARGET_SOUND_J, level: LEVEL_1, position: POSITION_MEDIAL, set: WORD_SET_CORE })
    AddWordCoding('Egypt', { sound: TARGET_SOUND_J, level: LEVEL_1, position: POSITION_MEDIAL, set: WORD_SET_CORE })
    AddWordCoding('energy', { sound: TARGET_SOUND_J, level: LEVEL_1, position: POSITION_MEDIAL, set: WORD_SET_CORE })
    AddWordCoding('engine', { sound: TARGET_SOUND_J, level: LEVEL_1, position: POSITION_MEDIAL, set: WORD_SET_CORE })
    AddWordCoding('engineer', { sound: TARGET_SOUND_J, level: LEVEL_1, position: POSITION_MEDIAL, set: WORD_SET_CORE })
    AddWordCoding('hedgehog', { sound: TARGET_SOUND_J, level: LEVEL_1, position: POSITION_MEDIAL, set: WORD_SET_CORE })
    AddWordCoding('imagine', { sound: TARGET_SOUND_J, level: LEVEL_1, position: POSITION_MEDIAL, set: WORD_SET_CORE })
    AddWordCoding('injure', { sound: TARGET_SOUND_J, level: LEVEL_1, position: POSITION_MEDIAL })
    AddWordCoding('larger', { sound: TARGET_SOUND_J, level: LEVEL_1, position: POSITION_MEDIAL, set: WORD_SET_CORE })
    AddWordCoding('magenta', { sound: TARGET_SOUND_J, level: LEVEL_1, position: POSITION_MEDIAL })
    AddWordCoding('magic', { sound: TARGET_SOUND_J, level: LEVEL_1, position: POSITION_MEDIAL, set: WORD_SET_CORE })
    AddWordCoding('ninja', { sound: TARGET_SOUND_J, level: LEVEL_1, position: POSITION_MEDIAL })
    AddWordCoding('pigeon', { sound: TARGET_SOUND_J, level: LEVEL_1, position: POSITION_MEDIAL, set: WORD_SET_CORE })

    AddWordCoding('algebra', { sound: TARGET_SOUND_J, level: LEVEL_2, position: POSITION_MEDIAL })
    AddWordCoding('allergic', { sound: TARGET_SOUND_J, level: LEVEL_2, position: POSITION_MEDIAL, set: WORD_SET_CORE })
    AddWordCoding('cookie jar', { sound: TARGET_SOUND_J, level: LEVEL_2, position: POSITION_MEDIAL, set: WORD_SET_CORE })
    AddWordCoding('engineering', { sound: TARGET_SOUND_J, level: LEVEL_2, position: POSITION_MEDIAL })
    AddWordCoding('fire engine', { sound: TARGET_SOUND_J, level: LEVEL_2, position: POSITION_MEDIAL, set: WORD_SET_CORE })
    AddWordCoding('flapjack', { sound: TARGET_SOUND_J, level: LEVEL_2, position: POSITION_MEDIAL, set: WORD_SET_CORE })
    AddWordCoding('fragile', { sound: TARGET_SOUND_J, level: LEVEL_2, position: POSITION_MEDIAL })
    AddWordCoding('garbage bag', { sound: TARGET_SOUND_J, level: LEVEL_2, position: POSITION_MEDIAL, set: WORD_SET_CORE })
    AddWordCoding('intelligent', { sound: TARGET_SOUND_J, level: LEVEL_2, position: POSITION_MEDIAL, set: WORD_SET_CORE })
    AddWordCoding('lemon juice', { sound: TARGET_SOUND_J, level: LEVEL_2, position: POSITION_MEDIAL, set: WORD_SET_CORE })
    AddWordCoding('longitude', { sound: TARGET_SOUND_J, level: LEVEL_2, position: POSITION_MEDIAL })
    AddWordCoding('pickle jar', { sound: TARGET_SOUND_J, level: LEVEL_2, position: POSITION_MEDIAL, set: WORD_SET_CORE })
    AddWordCoding('tomato juice', { sound: TARGET_SOUND_J, level: LEVEL_2, position: POSITION_MEDIAL, set: WORD_SET_CORE })
    AddWordCoding('Virginia', { sound: TARGET_SOUND_J, level: LEVEL_2, position: POSITION_MEDIAL, set: WORD_SET_CORE })

    AddWordCoding('apologize', { sound: TARGET_SOUND_J, level: LEVEL_3, position: POSITION_MEDIAL, set: WORD_SET_CORE })
    AddWordCoding('apple juice', { sound: TARGET_SOUND_J, level: LEVEL_3, position: POSITION_MEDIAL, set: WORD_SET_CORE })
    AddWordCoding('contagious', { sound: TARGET_SOUND_J, level: LEVEL_3, position: POSITION_MEDIAL })
    AddWordCoding('cottage cheese', { sound: TARGET_SOUND_J, level: LEVEL_3, position: POSITION_MEDIAL })
    AddWordCoding('dangerous', { sound: TARGET_SOUND_J, level: LEVEL_3, position: POSITION_MEDIAL, set: WORD_SET_CORE })
    AddWordCoding('edges', { sound: TARGET_SOUND_J, level: LEVEL_3, position: POSITION_MEDIAL })
    AddWordCoding('largest', { sound: TARGET_SOUND_J, level: LEVEL_3, position: POSITION_MEDIAL, set: WORD_SET_CORE })
    AddWordCoding('mason jar', { sound: TARGET_SOUND_J, level: LEVEL_3, position: POSITION_MEDIAL, set: WORD_SET_CORE })
    AddWordCoding('messenger', { sound: TARGET_SOUND_J, level: LEVEL_3, position: POSITION_MEDIAL })
    AddWordCoding('orange juice', { sound: TARGET_SOUND_J, level: LEVEL_3, position: POSITION_MEDIAL, set: WORD_SET_CORE })
    AddWordCoding('pages', { sound: TARGET_SOUND_J, level: LEVEL_3, position: POSITION_MEDIAL, set: WORD_SET_CORE })
    AddWordCoding('passenger', { sound: TARGET_SOUND_J, level: LEVEL_3, position: POSITION_MEDIAL, set: WORD_SET_CORE })
    AddWordCoding('sledgehammer', { sound: TARGET_SOUND_J, level: LEVEL_3, position: POSITION_MEDIAL })
    AddWordCoding('vegetables', { sound: TARGET_SOUND_J, level: LEVEL_3, position: POSITION_MEDIAL, set: WORD_SET_CORE })
    AddWordCoding('West Virginia', { sound: TARGET_SOUND_J, level: LEVEL_3, position: POSITION_MEDIAL, set: WORD_SET_CORE })

    // Target sound J: Final
    AddWordCoding('badge', { sound: TARGET_SOUND_J, level: LEVEL_1, position: POSITION_FINAL, set: WORD_SET_CORE })
    AddWordCoding('bridge', { sound: TARGET_SOUND_J, level: LEVEL_1, position: POSITION_FINAL, set: WORD_SET_CORE })
    AddWordCoding('cage', { sound: TARGET_SOUND_J, level: LEVEL_1, position: POSITION_FINAL, set: WORD_SET_CORE })
    AddWordCoding('carriage', { sound: TARGET_SOUND_J, level: LEVEL_1, position: POSITION_FINAL, set: WORD_SET_CORE })
    AddWordCoding('cottage', { sound: TARGET_SOUND_J, level: LEVEL_1, position: POSITION_FINAL })
    AddWordCoding('courage', { sound: TARGET_SOUND_J, level: LEVEL_1, position: POSITION_FINAL, set: WORD_SET_CORE })
    AddWordCoding('damage', { sound: TARGET_SOUND_J, level: LEVEL_1, position: POSITION_FINAL })
    AddWordCoding('edge', { sound: TARGET_SOUND_J, level: LEVEL_1, position: POSITION_FINAL, set: WORD_SET_CORE })
    AddWordCoding('huge', { sound: TARGET_SOUND_J, level: LEVEL_1, position: POSITION_FINAL, set: WORD_SET_CORE })
    AddWordCoding('luggage', { sound: TARGET_SOUND_J, level: LEVEL_1, position: POSITION_FINAL, set: WORD_SET_CORE })
    AddWordCoding('merge', { sound: TARGET_SOUND_J, level: LEVEL_1, position: POSITION_FINAL })
    AddWordCoding('package', { sound: TARGET_SOUND_J, level: LEVEL_1, position: POSITION_FINAL, set: WORD_SET_CORE })
    AddWordCoding('page', { sound: TARGET_SOUND_J, level: LEVEL_1, position: POSITION_FINAL, set: WORD_SET_CORE })
    AddWordCoding('urge', { sound: TARGET_SOUND_J, level: LEVEL_1, position: POSITION_FINAL })

    AddWordCoding('Anchorage', { sound: TARGET_SOUND_J, level: LEVEL_2, position: POSITION_FINAL, set: WORD_SET_CORE })
    AddWordCoding('arrange', { sound: TARGET_SOUND_J, level: LEVEL_2, position: POSITION_FINAL })
    AddWordCoding('baggage', { sound: TARGET_SOUND_J, level: LEVEL_2, position: POSITION_FINAL, set: WORD_SET_CORE })
    AddWordCoding('bandage', { sound: TARGET_SOUND_J, level: LEVEL_2, position: POSITION_FINAL, set: WORD_SET_CORE })
    AddWordCoding('beverage', { sound: TARGET_SOUND_J, level: LEVEL_2, position: POSITION_FINAL, set: WORD_SET_CORE })
    AddWordCoding('cabbage', { sound: TARGET_SOUND_J, level: LEVEL_2, position: POSITION_FINAL, set: WORD_SET_CORE })
    AddWordCoding('fringe', { sound: TARGET_SOUND_J, level: LEVEL_2, position: POSITION_FINAL })
    AddWordCoding('garbage', { sound: TARGET_SOUND_J, level: LEVEL_2, position: POSITION_FINAL, set: WORD_SET_CORE })
    AddWordCoding('hinge', { sound: TARGET_SOUND_J, level: LEVEL_2, position: POSITION_FINAL })
    AddWordCoding('language', { sound: TARGET_SOUND_J, level: LEVEL_2, position: POSITION_FINAL, set: WORD_SET_CORE })
    AddWordCoding('London Bridge', { sound: TARGET_SOUND_J, level: LEVEL_2, position: POSITION_FINAL, set: WORD_SET_CORE })
    AddWordCoding('orange', { sound: TARGET_SOUND_J, level: LEVEL_2, position: POSITION_FINAL, set: WORD_SET_CORE })
    AddWordCoding('plunge', { sound: TARGET_SOUND_J, level: LEVEL_2, position: POSITION_FINAL, set: WORD_SET_CORE })
    AddWordCoding('rearrange', { sound: TARGET_SOUND_J, level: LEVEL_2, position: POSITION_FINAL })

    AddWordCoding('challenge', { sound: TARGET_SOUND_J, level: LEVEL_3, position: POSITION_FINAL, set: WORD_SET_CORE })
    AddWordCoding('charge', { sound: TARGET_SOUND_J, level: LEVEL_3, position: POSITION_FINAL, set: WORD_SET_CORE })
    AddWordCoding('drawbridge', { sound: TARGET_SOUND_J, level: LEVEL_3, position: POSITION_FINAL })
    AddWordCoding('exchange', { sound: TARGET_SOUND_J, level: LEVEL_3, position: POSITION_FINAL, set: WORD_SET_CORE })
    AddWordCoding('Golden Gate Bridge', { sound: TARGET_SOUND_J, level: LEVEL_3, position: POSITION_FINAL })
    AddWordCoding('Ice Age', { sound: TARGET_SOUND_J, level: LEVEL_3, position: POSITION_FINAL })
    AddWordCoding('message', { sound: TARGET_SOUND_J, level: LEVEL_3, position: POSITION_FINAL, set: WORD_SET_CORE })
    AddWordCoding('recharge', { sound: TARGET_SOUND_J, level: LEVEL_3, position: POSITION_FINAL })
    AddWordCoding('sausage', { sound: TARGET_SOUND_J, level: LEVEL_3, position: POSITION_FINAL, set: WORD_SET_CORE })
    AddWordCoding('smudge', { sound: TARGET_SOUND_J, level: LEVEL_3, position: POSITION_FINAL, set: WORD_SET_CORE })
    AddWordCoding('sponge', { sound: TARGET_SOUND_J, level: LEVEL_3, position: POSITION_FINAL, set: WORD_SET_CORE })
    AddWordCoding('stage', { sound: TARGET_SOUND_J, level: LEVEL_3, position: POSITION_FINAL, set: WORD_SET_CORE })
    AddWordCoding('storage', { sound: TARGET_SOUND_J, level: LEVEL_3, position: POSITION_FINAL, set: WORD_SET_CORE })
    AddWordCoding('strange', { sound: TARGET_SOUND_J, level: LEVEL_3, position: POSITION_FINAL, set: WORD_SET_CORE })

    // --------------------------------------------------------------------------------------------------
    //   Target sound K
    // --------------------------------------------------------------------------------------------------

    // Target sound K: Initial
    AddWordCoding('call', { sound: TARGET_SOUND_K, level: LEVEL_1, position: POSITION_INITIAL, set: WORD_SET_CORE })
    AddWordCoding('can', { sound: TARGET_SOUND_K, level: LEVEL_1, position: POSITION_INITIAL, set: WORD_SET_CORE })
    AddWordCoding('car', { sound: TARGET_SOUND_K, level: LEVEL_1, position: POSITION_INITIAL, set: WORD_SET_CORE })
    AddWordCoding('cave', { sound: TARGET_SOUND_K, level: LEVEL_1, position: POSITION_INITIAL, set: WORD_SET_CORE })
    AddWordCoding('coal', { sound: TARGET_SOUND_K, level: LEVEL_1, position: POSITION_INITIAL })
    AddWordCoding('coin', { sound: TARGET_SOUND_K, level: LEVEL_1, position: POSITION_INITIAL })
    AddWordCoding('comb', { sound: TARGET_SOUND_K, level: LEVEL_1, position: POSITION_INITIAL, set: WORD_SET_CORE })
    AddWordCoding('cone', { sound: TARGET_SOUND_K, level: LEVEL_1, position: POSITION_INITIAL })
    AddWordCoding('cough', { sound: TARGET_SOUND_K, level: LEVEL_1, position: POSITION_INITIAL, set: WORD_SET_CORE })
    AddWordCoding('cow', { sound: TARGET_SOUND_K, level: LEVEL_1, position: POSITION_INITIAL, set: WORD_SET_CORE })
    AddWordCoding('cub', { sound: TARGET_SOUND_K, level: LEVEL_1, position: POSITION_INITIAL, set: WORD_SET_CORE })
    AddWordCoding('cuff', { sound: TARGET_SOUND_K, level: LEVEL_1, position: POSITION_INITIAL })
    AddWordCoding('cup', { sound: TARGET_SOUND_K, level: LEVEL_1, position: POSITION_INITIAL, set: WORD_SET_CORE })
    AddWordCoding('keep', { sound: TARGET_SOUND_K, level: LEVEL_1, position: POSITION_INITIAL })
    AddWordCoding('key', { sound: TARGET_SOUND_K, level: LEVEL_1, position: POSITION_INITIAL, set: WORD_SET_CORE })
    AddWordCoding('kiss', { sound: TARGET_SOUND_K, level: LEVEL_1, position: POSITION_INITIAL, set: WORD_SET_CORE })
    AddWordCoding('cool', { sound: TARGET_SOUND_K, level: LEVEL_1, position: POSITION_INITIAL, set: WORD_SET_CORE })

    AddWordCoding('cabin', { sound: TARGET_SOUND_K, level: LEVEL_2, position: POSITION_INITIAL, set: WORD_SET_CORE })
    AddWordCoding('caboose', { sound: TARGET_SOUND_K, level: LEVEL_2, position: POSITION_INITIAL })
    AddWordCoding('calm', { sound: TARGET_SOUND_K, level: LEVEL_2, position: POSITION_INITIAL })
    AddWordCoding('camel', { sound: TARGET_SOUND_K, level: LEVEL_2, position: POSITION_INITIAL, set: WORD_SET_CORE })
    AddWordCoding('Camille', { sound: TARGET_SOUND_K, level: LEVEL_2, position: POSITION_INITIAL, set: WORD_SET_CORE })
    AddWordCoding('camp', { sound: TARGET_SOUND_K, level: LEVEL_2, position: POSITION_INITIAL, set: WORD_SET_CORE })
    AddWordCoding('camper', { sound: TARGET_SOUND_K, level: LEVEL_2, position: POSITION_INITIAL })
    AddWordCoding('carry', { sound: TARGET_SOUND_K, level: LEVEL_2, position: POSITION_INITIAL, set: WORD_SET_CORE })
    AddWordCoding('Casey', { sound: TARGET_SOUND_K, level: LEVEL_2, position: POSITION_INITIAL, set: WORD_SET_CORE })
    AddWordCoding('cashier', { sound: TARGET_SOUND_K, level: LEVEL_2, position: POSITION_INITIAL })
    AddWordCoding('castle', { sound: TARGET_SOUND_K, level: LEVEL_2, position: POSITION_INITIAL, set: WORD_SET_CORE })
    AddWordCoding('cobra', { sound: TARGET_SOUND_K, level: LEVEL_2, position: POSITION_INITIAL })
    AddWordCoding('coffee', { sound: TARGET_SOUND_K, level: LEVEL_2, position: POSITION_INITIAL })
    AddWordCoding('collar', { sound: TARGET_SOUND_K, level: LEVEL_2, position: POSITION_INITIAL, set: WORD_SET_CORE })
    AddWordCoding('color', { sound: TARGET_SOUND_K, level: LEVEL_2, position: POSITION_INITIAL, set: WORD_SET_CORE })
    AddWordCoding('corn', { sound: TARGET_SOUND_K, level: LEVEL_2, position: POSITION_INITIAL, set: WORD_SET_CORE })
    AddWordCoding('cousin', { sound: TARGET_SOUND_K, level: LEVEL_2, position: POSITION_INITIAL, set: WORD_SET_CORE })
    AddWordCoding('curl', { sound: TARGET_SOUND_K, level: LEVEL_2, position: POSITION_INITIAL })
    AddWordCoding('curve', { sound: TARGET_SOUND_K, level: LEVEL_2, position: POSITION_INITIAL })
    AddWordCoding('Kansas', { sound: TARGET_SOUND_K, level: LEVEL_2, position: POSITION_INITIAL, set: WORD_SET_CORE })

    AddWordCoding('cabbage', { sound: TARGET_SOUND_K, level: LEVEL_3, position: POSITION_INITIAL })
    AddWordCoding('cage', { sound: TARGET_SOUND_K, level: LEVEL_3, position: POSITION_INITIAL })
    AddWordCoding('cake', { sound: TARGET_SOUND_K, level: LEVEL_3, position: POSITION_INITIAL, set: WORD_SET_CORE })
    AddWordCoding('cant', { sound: TARGET_SOUND_K, level: LEVEL_3, position: POSITION_INITIAL, text: "can't" })
    AddWordCoding('Canada', { sound: TARGET_SOUND_K, level: LEVEL_3, position: POSITION_INITIAL })
    AddWordCoding('candy', { sound: TARGET_SOUND_K, level: LEVEL_3, position: POSITION_INITIAL, set: WORD_SET_CORE })
    AddWordCoding('carried', { sound: TARGET_SOUND_K, level: LEVEL_3, position: POSITION_INITIAL })
    AddWordCoding('carrot', { sound: TARGET_SOUND_K, level: LEVEL_3, position: POSITION_INITIAL, set: WORD_SET_CORE })
    AddWordCoding('cat', { sound: TARGET_SOUND_K, level: LEVEL_3, position: POSITION_INITIAL, set: WORD_SET_CORE })
    AddWordCoding('catch', { sound: TARGET_SOUND_K, level: LEVEL_3, position: POSITION_INITIAL })
    AddWordCoding('caterpillar', { sound: TARGET_SOUND_K, level: LEVEL_3, position: POSITION_INITIAL })
    AddWordCoding('coach', { sound: TARGET_SOUND_K, level: LEVEL_3, position: POSITION_INITIAL })
    AddWordCoding('cold', { sound: TARGET_SOUND_K, level: LEVEL_3, position: POSITION_INITIAL })
    AddWordCoding('Colorado', { sound: TARGET_SOUND_K, level: LEVEL_3, position: POSITION_INITIAL, set: WORD_SET_CORE })
    AddWordCoding('computer', { sound: TARGET_SOUND_K, level: LEVEL_3, position: POSITION_INITIAL })
    AddWordCoding('Connecticut', { sound: TARGET_SOUND_K, level: LEVEL_3, position: POSITION_INITIAL, set: WORD_SET_CORE })
    AddWordCoding('cookie jar', { sound: TARGET_SOUND_K, level: LEVEL_3, position: POSITION_INITIAL, set: WORD_SET_CORE })
    AddWordCoding('could', { sound: TARGET_SOUND_K, level: LEVEL_3, position: POSITION_INITIAL })
    AddWordCoding('cut', { sound: TARGET_SOUND_K, level: LEVEL_3, position: POSITION_INITIAL, set: WORD_SET_CORE })
    AddWordCoding('kangaroo', { sound: TARGET_SOUND_K, level: LEVEL_3, position: POSITION_INITIAL })
    AddWordCoding('karate', { sound: TARGET_SOUND_K, level: LEVEL_3, position: POSITION_INITIAL })
    AddWordCoding('Kate', { sound: TARGET_SOUND_K, level: LEVEL_3, position: POSITION_INITIAL, set: WORD_SET_CORE })
    AddWordCoding('Kentucky', { sound: TARGET_SOUND_K, level: LEVEL_3, position: POSITION_INITIAL, set: WORD_SET_CORE })
    AddWordCoding('ketchup', { sound: TARGET_SOUND_K, level: LEVEL_3, position: POSITION_INITIAL })
    AddWordCoding('kick', { sound: TARGET_SOUND_K, level: LEVEL_3, position: POSITION_INITIAL, set: WORD_SET_CORE })
    AddWordCoding('kitchen', { sound: TARGET_SOUND_K, level: LEVEL_3, position: POSITION_INITIAL, set: WORD_SET_CORE })
    AddWordCoding('kitten', { sound: TARGET_SOUND_K, level: LEVEL_3, position: POSITION_INITIAL })

    // Target sound K: Medial
    AddWordCoding('acorn', { sound: TARGET_SOUND_K, level: LEVEL_1, position: POSITION_MEDIAL, set: WORD_SET_CORE })
    AddWordCoding('bacon', { sound: TARGET_SOUND_K, level: LEVEL_1, position: POSITION_MEDIAL })
    AddWordCoding('baker', { sound: TARGET_SOUND_K, level: LEVEL_1, position: POSITION_MEDIAL, set: WORD_SET_CORE })
    AddWordCoding('bookcase', { sound: TARGET_SOUND_K, level: LEVEL_1, position: POSITION_MEDIAL })
    AddWordCoding('chicken', { sound: TARGET_SOUND_K, level: LEVEL_1, position: POSITION_MEDIAL, set: WORD_SET_CORE })
    AddWordCoding('circus', { sound: TARGET_SOUND_K, level: LEVEL_1, position: POSITION_MEDIAL, set: WORD_SET_CORE })
    AddWordCoding('hiking', { sound: TARGET_SOUND_K, level: LEVEL_1, position: POSITION_MEDIAL, set: WORD_SET_CORE })
    AddWordCoding('hockey', { sound: TARGET_SOUND_K, level: LEVEL_1, position: POSITION_MEDIAL, set: WORD_SET_CORE })
    AddWordCoding('icky', { sound: TARGET_SOUND_K, level: LEVEL_1, position: POSITION_MEDIAL })
    AddWordCoding('looking', { sound: TARGET_SOUND_K, level: LEVEL_1, position: POSITION_MEDIAL, set: WORD_SET_CORE })
    AddWordCoding('lucky', { sound: TARGET_SOUND_K, level: LEVEL_1, position: POSITION_MEDIAL })
    AddWordCoding('Nicky', { sound: TARGET_SOUND_K, level: LEVEL_1, position: POSITION_MEDIAL, set: WORD_SET_CORE })
    AddWordCoding('orca', { sound: TARGET_SOUND_K, level: LEVEL_1, position: POSITION_MEDIAL })
    AddWordCoding('package', { sound: TARGET_SOUND_K, level: LEVEL_1, position: POSITION_MEDIAL })
    AddWordCoding('pickup', { sound: TARGET_SOUND_K, level: LEVEL_1, position: POSITION_MEDIAL, set: WORD_SET_CORE })
    AddWordCoding('raccoon', { sound: TARGET_SOUND_K, level: LEVEL_1, position: POSITION_MEDIAL, set: WORD_SET_CORE })
    AddWordCoding('raking', { sound: TARGET_SOUND_K, level: LEVEL_1, position: POSITION_MEDIAL, set: WORD_SET_CORE })
    AddWordCoding('rocky', { sound: TARGET_SOUND_K, level: LEVEL_1, position: POSITION_MEDIAL })
    AddWordCoding('soccer', { sound: TARGET_SOUND_K, level: LEVEL_1, position: POSITION_MEDIAL, set: WORD_SET_CORE })

    AddWordCoding('Africa', { sound: TARGET_SOUND_K, level: LEVEL_2, position: POSITION_MEDIAL })
    AddWordCoding('bicycle', { sound: TARGET_SOUND_K, level: LEVEL_2, position: POSITION_MEDIAL, set: WORD_SET_CORE })
    AddWordCoding('black bear', { sound: TARGET_SOUND_K, level: LEVEL_2, position: POSITION_MEDIAL, set: WORD_SET_CORE })
    AddWordCoding('broken', { sound: TARGET_SOUND_K, level: LEVEL_2, position: POSITION_MEDIAL })
    AddWordCoding('bumper car', { sound: TARGET_SOUND_K, level: LEVEL_2, position: POSITION_MEDIAL })
    AddWordCoding('freckle', { sound: TARGET_SOUND_K, level: LEVEL_2, position: POSITION_MEDIAL })
    AddWordCoding('hurricane', { sound: TARGET_SOUND_K, level: LEVEL_2, position: POSITION_MEDIAL })
    AddWordCoding('licorice', { sound: TARGET_SOUND_K, level: LEVEL_2, position: POSITION_MEDIAL })
    AddWordCoding('marker', { sound: TARGET_SOUND_K, level: LEVEL_2, position: POSITION_MEDIAL, set: WORD_SET_CORE })
    AddWordCoding('peacock', { sound: TARGET_SOUND_K, level: LEVEL_2, position: POSITION_MEDIAL, set: WORD_SET_CORE })
    AddWordCoding('pelican', { sound: TARGET_SOUND_K, level: LEVEL_2, position: POSITION_MEDIAL })
    AddWordCoding('pillowcase', { sound: TARGET_SOUND_K, level: LEVEL_2, position: POSITION_MEDIAL })
    AddWordCoding('popcorn', { sound: TARGET_SOUND_K, level: LEVEL_2, position: POSITION_MEDIAL, set: WORD_SET_CORE })
    AddWordCoding('pumpkin', { sound: TARGET_SOUND_K, level: LEVEL_2, position: POSITION_MEDIAL, set: WORD_SET_CORE })
    AddWordCoding('Rebecca', { sound: TARGET_SOUND_K, level: LEVEL_2, position: POSITION_MEDIAL, set: WORD_SET_CORE })
    AddWordCoding('sneaker', { sound: TARGET_SOUND_K, level: LEVEL_2, position: POSITION_MEDIAL, set: WORD_SET_CORE })
    AddWordCoding('soccer ball', { sound: TARGET_SOUND_K, level: LEVEL_2, position: POSITION_MEDIAL, set: WORD_SET_CORE })
    AddWordCoding('spooky', { sound: TARGET_SOUND_K, level: LEVEL_2, position: POSITION_MEDIAL, set: WORD_SET_CORE })
    AddWordCoding('sticky', { sound: TARGET_SOUND_K, level: LEVEL_2, position: POSITION_MEDIAL, set: WORD_SET_CORE })
    AddWordCoding('Zachary', { sound: TARGET_SOUND_K, level: LEVEL_2, position: POSITION_MEDIAL, set: WORD_SET_CORE })

    AddWordCoding('accident', { sound: TARGET_SOUND_K, level: LEVEL_3, position: POSITION_MEDIAL })
    AddWordCoding('blackberry', { sound: TARGET_SOUND_K, level: LEVEL_3, position: POSITION_MEDIAL })
    AddWordCoding('blanket', { sound: TARGET_SOUND_K, level: LEVEL_3, position: POSITION_MEDIAL })
    AddWordCoding('boa constrictor', { sound: TARGET_SOUND_K, level: LEVEL_3, position: POSITION_MEDIAL })
    AddWordCoding('bookstore', { sound: TARGET_SOUND_K, level: LEVEL_3, position: POSITION_MEDIAL })
    AddWordCoding('breakfast', { sound: TARGET_SOUND_K, level: LEVEL_3, position: POSITION_MEDIAL, set: WORD_SET_CORE })
    AddWordCoding('broccoli', { sound: TARGET_SOUND_K, level: LEVEL_3, position: POSITION_MEDIAL })
    AddWordCoding('bucket', { sound: TARGET_SOUND_K, level: LEVEL_3, position: POSITION_MEDIAL })
    AddWordCoding('chocolate', { sound: TARGET_SOUND_K, level: LEVEL_3, position: POSITION_MEDIAL, set: WORD_SET_CORE })
    AddWordCoding('doctor', { sound: TARGET_SOUND_K, level: LEVEL_3, position: POSITION_MEDIAL, set: WORD_SET_CORE })
    AddWordCoding('dracula', { sound: TARGET_SOUND_K, level: LEVEL_3, position: POSITION_MEDIAL })
    AddWordCoding('helicopter', { sound: TARGET_SOUND_K, level: LEVEL_3, position: POSITION_MEDIAL, set: WORD_SET_CORE })
    AddWordCoding('jack o lantern', { sound: TARGET_SOUND_K, level: LEVEL_3, position: POSITION_MEDIAL, text: "jack o'lantern" })
    AddWordCoding('jacket', { sound: TARGET_SOUND_K, level: LEVEL_3, position: POSITION_MEDIAL, set: WORD_SET_CORE })
    AddWordCoding('Kentucky', { sound: TARGET_SOUND_K, level: LEVEL_3, position: POSITION_MEDIAL, set: WORD_SET_CORE })
    AddWordCoding('lakefront', { sound: TARGET_SOUND_K, level: LEVEL_3, position: POSITION_MEDIAL })
    AddWordCoding('macaroni', { sound: TARGET_SOUND_K, level: LEVEL_3, position: POSITION_MEDIAL })
    AddWordCoding('North Carolina', { sound: TARGET_SOUND_K, level: LEVEL_3, position: POSITION_MEDIAL, set: WORD_SET_CORE })
    AddWordCoding('North Dakota', { sound: TARGET_SOUND_K, level: LEVEL_3, position: POSITION_MEDIAL })
    AddWordCoding('parking lot', { sound: TARGET_SOUND_K, level: LEVEL_3, position: POSITION_MEDIAL, set: WORD_SET_CORE })
    AddWordCoding('pocket', { sound: TARGET_SOUND_K, level: LEVEL_3, position: POSITION_MEDIAL })
    AddWordCoding('raincoat', { sound: TARGET_SOUND_K, level: LEVEL_3, position: POSITION_MEDIAL, set: WORD_SET_CORE })
    AddWordCoding('rocket', { sound: TARGET_SOUND_K, level: LEVEL_3, position: POSITION_MEDIAL, set: WORD_SET_CORE })
    AddWordCoding('roller coaster', { sound: TARGET_SOUND_K, level: LEVEL_3, position: POSITION_MEDIAL, set: WORD_SET_CORE })
    AddWordCoding('taco', { sound: TARGET_SOUND_K, level: LEVEL_3, position: POSITION_MEDIAL, set: WORD_SET_CORE })
    AddWordCoding('trick or treat', { sound: TARGET_SOUND_K, level: LEVEL_3, position: POSITION_MEDIAL })
    AddWordCoding('turkey', { sound: TARGET_SOUND_K, level: LEVEL_3, position: POSITION_MEDIAL })

    // Target sound K: Final
    AddWordCoding('ache', { sound: TARGET_SOUND_K, level: LEVEL_1, position: POSITION_FINAL })
    AddWordCoding('arc', { sound: TARGET_SOUND_K, level: LEVEL_1, position: POSITION_FINAL })
    AddWordCoding('back', { sound: TARGET_SOUND_K, level: LEVEL_1, position: POSITION_FINAL, variant: 1 })  // direction
    AddWordCoding('back', { sound: TARGET_SOUND_K, level: LEVEL_1, position: POSITION_FINAL, variant: 2, set: WORD_SET_CORE })  // body
    AddWordCoding('bake', { sound: TARGET_SOUND_K, level: LEVEL_1, position: POSITION_FINAL, set: WORD_SET_CORE })
    AddWordCoding('bark', { sound: TARGET_SOUND_K, level: LEVEL_1, position: POSITION_FINAL, variant: 1, set: WORD_SET_CORE })  // dog
    AddWordCoding('bark', { sound: TARGET_SOUND_K, level: LEVEL_1, position: POSITION_FINAL, variant: 2 })  // tree
    AddWordCoding('knock', { sound: TARGET_SOUND_K, level: LEVEL_1, position: POSITION_FINAL, set: WORD_SET_CORE })
    AddWordCoding('lake', { sound: TARGET_SOUND_K, level: LEVEL_1, position: POSITION_FINAL, set: WORD_SET_CORE })
    AddWordCoding('leak', { sound: TARGET_SOUND_K, level: LEVEL_1, position: POSITION_FINAL })
    AddWordCoding('lick', { sound: TARGET_SOUND_K, level: LEVEL_1, position: POSITION_FINAL, set: WORD_SET_CORE })
    AddWordCoding('like', { sound: TARGET_SOUND_K, level: LEVEL_1, position: POSITION_FINAL, set: WORD_SET_CORE })
    AddWordCoding('look', { sound: TARGET_SOUND_K, level: LEVEL_1, position: POSITION_FINAL, set: WORD_SET_CORE })
    AddWordCoding('Luke', { sound: TARGET_SOUND_K, level: LEVEL_1, position: POSITION_FINAL, set: WORD_SET_CORE })
    AddWordCoding('Mark', { sound: TARGET_SOUND_K, level: LEVEL_1, position: POSITION_FINAL, variant: 1 })  // name
    AddWordCoding('mark', { sound: TARGET_SOUND_K, level: LEVEL_1, position: POSITION_FINAL, variant: 2 })  // spot
    AddWordCoding('park', { sound: TARGET_SOUND_K, level: LEVEL_1, position: POSITION_FINAL })
    AddWordCoding('rake', { sound: TARGET_SOUND_K, level: LEVEL_1, position: POSITION_FINAL })
    AddWordCoding('rock', { sound: TARGET_SOUND_K, level: LEVEL_1, position: POSITION_FINAL, set: WORD_SET_CORE })
    AddWordCoding('sick', { sound: TARGET_SOUND_K, level: LEVEL_1, position: POSITION_FINAL, set: WORD_SET_CORE })
    AddWordCoding('sock', { sound: TARGET_SOUND_K, level: LEVEL_1, position: POSITION_FINAL, set: WORD_SET_CORE })

    AddWordCoding('alike', { sound: TARGET_SOUND_K, level: LEVEL_2, position: POSITION_FINAL })
    AddWordCoding('black', { sound: TARGET_SOUND_K, level: LEVEL_2, position: POSITION_FINAL, set: WORD_SET_CORE })
    AddWordCoding('Blake', { sound: TARGET_SOUND_K, level: LEVEL_2, position: POSITION_FINAL, set: WORD_SET_CORE })
    AddWordCoding('block', { sound: TARGET_SOUND_K, level: LEVEL_2, position: POSITION_FINAL, variant: 1 })  // road
    AddWordCoding('block', { sound: TARGET_SOUND_K, level: LEVEL_2, position: POSITION_FINAL, variant: 2, set: WORD_SET_CORE })  // toy
    AddWordCoding('break', { sound: TARGET_SOUND_K, level: LEVEL_2, position: POSITION_FINAL })
    AddWordCoding('brick', { sound: TARGET_SOUND_K, level: LEVEL_2, position: POSITION_FINAL })
    AddWordCoding('earache', { sound: TARGET_SOUND_K, level: LEVEL_2, position: POSITION_FINAL, set: WORD_SET_CORE })
    AddWordCoding('fork', { sound: TARGET_SOUND_K, level: LEVEL_2, position: POSITION_FINAL, set: WORD_SET_CORE })
    AddWordCoding('Frank', { sound: TARGET_SOUND_K, level: LEVEL_2, position: POSITION_FINAL, set: WORD_SET_CORE })
    AddWordCoding('garlic', { sound: TARGET_SOUND_K, level: LEVEL_2, position: POSITION_FINAL })
    AddWordCoding('music', { sound: TARGET_SOUND_K, level: LEVEL_2, position: POSITION_FINAL, set: WORD_SET_CORE })
    AddWordCoding('smirk', { sound: TARGET_SOUND_K, level: LEVEL_2, position: POSITION_FINAL })
    AddWordCoding('smock', { sound: TARGET_SOUND_K, level: LEVEL_2, position: POSITION_FINAL })
    AddWordCoding('smoke', { sound: TARGET_SOUND_K, level: LEVEL_2, position: POSITION_FINAL, set: WORD_SET_CORE })
    AddWordCoding('snack', { sound: TARGET_SOUND_K, level: LEVEL_2, position: POSITION_FINAL, set: WORD_SET_CORE })
    AddWordCoding('snake', { sound: TARGET_SOUND_K, level: LEVEL_2, position: POSITION_FINAL, set: WORD_SET_CORE })
    AddWordCoding('sneak', { sound: TARGET_SOUND_K, level: LEVEL_2, position: POSITION_FINAL, set: WORD_SET_CORE })
    AddWordCoding('spark', { sound: TARGET_SOUND_K, level: LEVEL_2, position: POSITION_FINAL })
    AddWordCoding('speak', { sound: TARGET_SOUND_K, level: LEVEL_2, position: POSITION_FINAL, set: WORD_SET_CORE })

    AddWordCoding('bookmark', { sound: TARGET_SOUND_K, level: LEVEL_3, position: POSITION_FINAL, set: WORD_SET_CORE })
    AddWordCoding('dark', { sound: TARGET_SOUND_K, level: LEVEL_3, position: POSITION_FINAL, set: WORD_SET_CORE })
    AddWordCoding('desk', { sound: TARGET_SOUND_K, level: LEVEL_3, position: POSITION_FINAL, set: WORD_SET_CORE })
    AddWordCoding('fantastic', { sound: TARGET_SOUND_K, level: LEVEL_3, position: POSITION_FINAL })
    AddWordCoding('flapjack', { sound: TARGET_SOUND_K, level: LEVEL_3, position: POSITION_FINAL })
    AddWordCoding('glue stick', { sound: TARGET_SOUND_K, level: LEVEL_3, position: POSITION_FINAL, set: WORD_SET_CORE })
    AddWordCoding('grass snake', { sound: TARGET_SOUND_K, level: LEVEL_3, position: POSITION_FINAL })
    AddWordCoding('lipstick', { sound: TARGET_SOUND_K, level: LEVEL_3, position: POSITION_FINAL })
    AddWordCoding('mistake', { sound: TARGET_SOUND_K, level: LEVEL_3, position: POSITION_FINAL })
    AddWordCoding('New York', { sound: TARGET_SOUND_K, level: LEVEL_3, position: POSITION_FINAL, set: WORD_SET_CORE })
    AddWordCoding('plastic', { sound: TARGET_SOUND_K, level: LEVEL_3, position: POSITION_FINAL })
    AddWordCoding('railroad track', { sound: TARGET_SOUND_K, level: LEVEL_3, position: POSITION_FINAL, set: WORD_SET_CORE })
    AddWordCoding('sidewalk', { sound: TARGET_SOUND_K, level: LEVEL_3, position: POSITION_FINAL, set: WORD_SET_CORE })
    AddWordCoding('snowflake', { sound: TARGET_SOUND_K, level: LEVEL_3, position: POSITION_FINAL, set: WORD_SET_CORE })
    AddWordCoding('stalk', { sound: TARGET_SOUND_K, level: LEVEL_3, position: POSITION_FINAL })
    AddWordCoding('steak', { sound: TARGET_SOUND_K, level: LEVEL_3, position: POSITION_FINAL, set: WORD_SET_CORE })
    AddWordCoding('stick', { sound: TARGET_SOUND_K, level: LEVEL_3, position: POSITION_FINAL, set: WORD_SET_CORE })
    AddWordCoding('stork', { sound: TARGET_SOUND_K, level: LEVEL_3, position: POSITION_FINAL })
    AddWordCoding('strike', { sound: TARGET_SOUND_K, level: LEVEL_3, position: POSITION_FINAL })
    AddWordCoding('stuck', { sound: TARGET_SOUND_K, level: LEVEL_3, position: POSITION_FINAL, set: WORD_SET_CORE })
    AddWordCoding('truck', { sound: TARGET_SOUND_K, level: LEVEL_3, position: POSITION_FINAL, set: WORD_SET_CORE })

    // --------------------------------------------------------------------------------------------------
    //   Target sound L
    // --------------------------------------------------------------------------------------------------

    // Target sound L: Initial
    AddWordCoding('lake', { sound: TARGET_SOUND_L, level: LEVEL_1, position: POSITION_INITIAL, set: WORD_SET_CORE })
    AddWordCoding('land', { sound: TARGET_SOUND_L, level: LEVEL_1, position: POSITION_INITIAL })
    AddWordCoding('lane', { sound: TARGET_SOUND_L, level: LEVEL_1, position: POSITION_INITIAL })
    AddWordCoding('lash', { sound: TARGET_SOUND_L, level: LEVEL_1, position: POSITION_INITIAL })
    AddWordCoding('last', { sound: TARGET_SOUND_L, level: LEVEL_1, position: POSITION_INITIAL, set: WORD_SET_CORE })
    AddWordCoding('late', { sound: TARGET_SOUND_L, level: LEVEL_1, position: POSITION_INITIAL })
    AddWordCoding('lazy', { sound: TARGET_SOUND_L, level: LEVEL_1, position: POSITION_INITIAL, set: WORD_SET_CORE })
    AddWordCoding('leak', { sound: TARGET_SOUND_L, level: LEVEL_1, position: POSITION_INITIAL })
    AddWordCoding('leash', { sound: TARGET_SOUND_L, level: LEVEL_1, position: POSITION_INITIAL })
    AddWordCoding('leg', { sound: TARGET_SOUND_L, level: LEVEL_1, position: POSITION_INITIAL, set: WORD_SET_CORE })
    AddWordCoding('lend', { sound: TARGET_SOUND_L, level: LEVEL_1, position: POSITION_INITIAL })
    AddWordCoding('lick', { sound: TARGET_SOUND_L, level: LEVEL_1, position: POSITION_INITIAL, set: WORD_SET_CORE })
    AddWordCoding('lid', { sound: TARGET_SOUND_L, level: LEVEL_1, position: POSITION_INITIAL })
    AddWordCoding('light', { sound: TARGET_SOUND_L, level: LEVEL_1, position: POSITION_INITIAL, set: WORD_SET_CORE })
    AddWordCoding('like', { sound: TARGET_SOUND_L, level: LEVEL_1, position: POSITION_INITIAL, set: WORD_SET_CORE })
    AddWordCoding('line', { sound: TARGET_SOUND_L, level: LEVEL_1, position: POSITION_INITIAL, set: WORD_SET_CORE })
    AddWordCoding('link', { sound: TARGET_SOUND_L, level: LEVEL_1, position: POSITION_INITIAL })
    AddWordCoding('lock', { sound: TARGET_SOUND_L, level: LEVEL_1, position: POSITION_INITIAL, set: WORD_SET_CORE })
    AddWordCoding('log', { sound: TARGET_SOUND_L, level: LEVEL_1, position: POSITION_INITIAL, set: WORD_SET_CORE })
    AddWordCoding('long', { sound: TARGET_SOUND_L, level: LEVEL_1, position: POSITION_INITIAL, set: WORD_SET_CORE })
    AddWordCoding('look', { sound: TARGET_SOUND_L, level: LEVEL_1, position: POSITION_INITIAL, set: WORD_SET_CORE })
    AddWordCoding('lost', { sound: TARGET_SOUND_L, level: LEVEL_1, position: POSITION_INITIAL })

    AddWordCoding('lamb', { sound: TARGET_SOUND_L, level: LEVEL_2, position: POSITION_INITIAL, set: WORD_SET_CORE })
    AddWordCoding('lamp', { sound: TARGET_SOUND_L, level: LEVEL_2, position: POSITION_INITIAL, set: WORD_SET_CORE })
    AddWordCoding('laugh', { sound: TARGET_SOUND_L, level: LEVEL_2, position: POSITION_INITIAL, set: WORD_SET_CORE })
    AddWordCoding('lawn', { sound: TARGET_SOUND_L, level: LEVEL_2, position: POSITION_INITIAL })
    AddWordCoding('layer', { sound: TARGET_SOUND_L, level: LEVEL_2, position: POSITION_INITIAL })
    AddWordCoding('leaf', { sound: TARGET_SOUND_L, level: LEVEL_2, position: POSITION_INITIAL, set: WORD_SET_CORE })
    AddWordCoding('leap', { sound: TARGET_SOUND_L, level: LEVEL_2, position: POSITION_INITIAL })
    AddWordCoding('leave', { sound: TARGET_SOUND_L, level: LEVEL_2, position: POSITION_INITIAL })
    AddWordCoding('left', { sound: TARGET_SOUND_L, level: LEVEL_2, position: POSITION_INITIAL })
    AddWordCoding('lemon', { sound: TARGET_SOUND_L, level: LEVEL_2, position: POSITION_INITIAL, set: WORD_SET_CORE })
    AddWordCoding('length', { sound: TARGET_SOUND_L, level: LEVEL_2, position: POSITION_INITIAL })
    AddWordCoding('lettuce', { sound: TARGET_SOUND_L, level: LEVEL_2, position: POSITION_INITIAL })
    AddWordCoding('lift', { sound: TARGET_SOUND_L, level: LEVEL_2, position: POSITION_INITIAL })
    AddWordCoding('lightning', { sound: TARGET_SOUND_L, level: LEVEL_2, position: POSITION_INITIAL, set: WORD_SET_CORE })
    AddWordCoding('limb', { sound: TARGET_SOUND_L, level: LEVEL_2, position: POSITION_INITIAL })
    AddWordCoding('lime', { sound: TARGET_SOUND_L, level: LEVEL_2, position: POSITION_INITIAL })
    AddWordCoding('lion', { sound: TARGET_SOUND_L, level: LEVEL_2, position: POSITION_INITIAL, set: WORD_SET_CORE })
    AddWordCoding('lips', { sound: TARGET_SOUND_L, level: LEVEL_2, position: POSITION_INITIAL })
    AddWordCoding('list', { sound: TARGET_SOUND_L, level: LEVEL_2, position: POSITION_INITIAL })
    AddWordCoding('listen', { sound: TARGET_SOUND_L, level: LEVEL_2, position: POSITION_INITIAL, set: WORD_SET_CORE })
    AddWordCoding('lizard', { sound: TARGET_SOUND_L, level: LEVEL_2, position: POSITION_INITIAL })
    AddWordCoding('load', { sound: TARGET_SOUND_L, level: LEVEL_2, position: POSITION_INITIAL })
    AddWordCoding('loaf', { sound: TARGET_SOUND_L, level: LEVEL_2, position: POSITION_INITIAL })
    AddWordCoding('lobe', { sound: TARGET_SOUND_L, level: LEVEL_2, position: POSITION_INITIAL })
    AddWordCoding('loop', { sound: TARGET_SOUND_L, level: LEVEL_2, position: POSITION_INITIAL })
    AddWordCoding('loose', { sound: TARGET_SOUND_L, level: LEVEL_2, position: POSITION_INITIAL })
    AddWordCoding('loud', { sound: TARGET_SOUND_L, level: LEVEL_2, position: POSITION_INITIAL, set: WORD_SET_CORE })
    AddWordCoding('love', { sound: TARGET_SOUND_L, level: LEVEL_2, position: POSITION_INITIAL, set: WORD_SET_CORE })
    AddWordCoding('low', { sound: TARGET_SOUND_L, level: LEVEL_2, position: POSITION_INITIAL })
    AddWordCoding('lucky', { sound: TARGET_SOUND_L, level: LEVEL_2, position: POSITION_INITIAL })
    AddWordCoding('Luke', { sound: TARGET_SOUND_L, level: LEVEL_2, position: POSITION_INITIAL, set: WORD_SET_CORE })
    AddWordCoding('lump', { sound: TARGET_SOUND_L, level: LEVEL_2, position: POSITION_INITIAL })
    AddWordCoding('lunch', { sound: TARGET_SOUND_L, level: LEVEL_2, position: POSITION_INITIAL, set: WORD_SET_CORE })

    AddWordCoding('label', { sound: TARGET_SOUND_L, level: LEVEL_3, position: POSITION_INITIAL })
    AddWordCoding('ladder', { sound: TARGET_SOUND_L, level: LEVEL_3, position: POSITION_INITIAL, set: WORD_SET_CORE })
    AddWordCoding('ladle', { sound: TARGET_SOUND_L, level: LEVEL_3, position: POSITION_INITIAL })
    AddWordCoding('ladybug', { sound: TARGET_SOUND_L, level: LEVEL_3, position: POSITION_INITIAL, set: WORD_SET_CORE })
    AddWordCoding('lair', { sound: TARGET_SOUND_L, level: LEVEL_3, position: POSITION_INITIAL })
    AddWordCoding('lamplight', { sound: TARGET_SOUND_L, level: LEVEL_3, position: POSITION_INITIAL })
    AddWordCoding('lantern', { sound: TARGET_SOUND_L, level: LEVEL_3, position: POSITION_INITIAL })
    AddWordCoding('lapel', { sound: TARGET_SOUND_L, level: LEVEL_3, position: POSITION_INITIAL })
    AddWordCoding('large', { sound: TARGET_SOUND_L, level: LEVEL_3, position: POSITION_INITIAL, set: WORD_SET_CORE })
    AddWordCoding('Larry', { sound: TARGET_SOUND_L, level: LEVEL_3, position: POSITION_INITIAL })
    AddWordCoding('laundry', { sound: TARGET_SOUND_L, level: LEVEL_3, position: POSITION_INITIAL })
    AddWordCoding('lawnmower', { sound: TARGET_SOUND_L, level: LEVEL_3, position: POSITION_INITIAL })
    AddWordCoding('lawyer', { sound: TARGET_SOUND_L, level: LEVEL_3, position: POSITION_INITIAL })
    AddWordCoding('layer cake', { sound: TARGET_SOUND_L, level: LEVEL_3, position: POSITION_INITIAL })
    AddWordCoding('learn', { sound: TARGET_SOUND_L, level: LEVEL_3, position: POSITION_INITIAL, set: WORD_SET_CORE })
    AddWordCoding('left-handed', { sound: TARGET_SOUND_L, level: LEVEL_3, position: POSITION_INITIAL })
    AddWordCoding('lemon juice', { sound: TARGET_SOUND_L, level: LEVEL_3, position: POSITION_INITIAL })
    AddWordCoding('lemonade', { sound: TARGET_SOUND_L, level: LEVEL_3, position: POSITION_INITIAL, set: WORD_SET_CORE })
    AddWordCoding('letter', { sound: TARGET_SOUND_L, level: LEVEL_3, position: POSITION_INITIAL })
    AddWordCoding('level', { sound: TARGET_SOUND_L, level: LEVEL_3, position: POSITION_INITIAL })
    AddWordCoding('liar', { sound: TARGET_SOUND_L, level: LEVEL_3, position: POSITION_INITIAL })
    AddWordCoding('Liberty Bell', { sound: TARGET_SOUND_L, level: LEVEL_3, position: POSITION_INITIAL })
    AddWordCoding('library', { sound: TARGET_SOUND_L, level: LEVEL_3, position: POSITION_INITIAL, set: WORD_SET_CORE })
    AddWordCoding('licorice', { sound: TARGET_SOUND_L, level: LEVEL_3, position: POSITION_INITIAL })
    AddWordCoding('lifeguard', { sound: TARGET_SOUND_L, level: LEVEL_3, position: POSITION_INITIAL })
    AddWordCoding('lighthouse', { sound: TARGET_SOUND_L, level: LEVEL_3, position: POSITION_INITIAL, set: WORD_SET_CORE })
    AddWordCoding('lightning bug', { sound: TARGET_SOUND_L, level: LEVEL_3, position: POSITION_INITIAL, set: WORD_SET_CORE })
    AddWordCoding('limerick', { sound: TARGET_SOUND_L, level: LEVEL_3, position: POSITION_INITIAL })
    AddWordCoding('lipstick', { sound: TARGET_SOUND_L, level: LEVEL_3, position: POSITION_INITIAL })
    AddWordCoding('listening', { sound: TARGET_SOUND_L, level: LEVEL_3, position: POSITION_INITIAL, set: WORD_SET_CORE })
    AddWordCoding('litterbug', { sound: TARGET_SOUND_L, level: LEVEL_3, position: POSITION_INITIAL })
    AddWordCoding('little', { sound: TARGET_SOUND_L, level: LEVEL_3, position: POSITION_INITIAL, set: WORD_SET_CORE })
    AddWordCoding('lollipop', { sound: TARGET_SOUND_L, level: LEVEL_3, position: POSITION_INITIAL, set: WORD_SET_CORE })
    AddWordCoding('London Bridge', { sound: TARGET_SOUND_L, level: LEVEL_3, position: POSITION_INITIAL })
    AddWordCoding('long sleeves', { sound: TARGET_SOUND_L, level: LEVEL_3, position: POSITION_INITIAL })
    AddWordCoding('lottery', { sound: TARGET_SOUND_L, level: LEVEL_3, position: POSITION_INITIAL })
    AddWordCoding('Louisiana', { sound: TARGET_SOUND_L, level: LEVEL_3, position: POSITION_INITIAL, set: WORD_SET_CORE })
    AddWordCoding('lower case', { sound: TARGET_SOUND_L, level: LEVEL_3, position: POSITION_INITIAL })
    AddWordCoding('lyrics', { sound: TARGET_SOUND_L, level: LEVEL_3, position: POSITION_INITIAL })

    // Target sound L: Medial
    AddWordCoding('alike', { sound: TARGET_SOUND_L, level: LEVEL_1, position: POSITION_MEDIAL })
    AddWordCoding('alley', { sound: TARGET_SOUND_L, level: LEVEL_1, position: POSITION_MEDIAL })
    AddWordCoding('ceiling', { sound: TARGET_SOUND_L, level: LEVEL_1, position: POSITION_MEDIAL, set: WORD_SET_CORE })
    AddWordCoding('collie', { sound: TARGET_SOUND_L, level: LEVEL_1, position: POSITION_MEDIAL })
    AddWordCoding('Ella', { sound: TARGET_SOUND_L, level: LEVEL_1, position: POSITION_MEDIAL, set: WORD_SET_CORE })
    AddWordCoding('eyelash', { sound: TARGET_SOUND_L, level: LEVEL_1, position: POSITION_MEDIAL, set: WORD_SET_CORE })
    AddWordCoding('gallon', { sound: TARGET_SOUND_L, level: LEVEL_1, position: POSITION_MEDIAL })
    AddWordCoding('Hailey', { sound: TARGET_SOUND_L, level: LEVEL_1, position: POSITION_MEDIAL, set: WORD_SET_CORE })
    AddWordCoding('holly', { sound: TARGET_SOUND_L, level: LEVEL_1, position: POSITION_MEDIAL })
    AddWordCoding('island', { sound: TARGET_SOUND_L, level: LEVEL_1, position: POSITION_MEDIAL, set: WORD_SET_CORE })
    AddWordCoding('jelly', { sound: TARGET_SOUND_L, level: LEVEL_1, position: POSITION_MEDIAL, set: WORD_SET_CORE })
    AddWordCoding('July', { sound: TARGET_SOUND_L, level: LEVEL_1, position: POSITION_MEDIAL, set: WORD_SET_CORE })
    AddWordCoding('sailing', { sound: TARGET_SOUND_L, level: LEVEL_1, position: POSITION_MEDIAL, set: WORD_SET_CORE })
    AddWordCoding('salad', { sound: TARGET_SOUND_L, level: LEVEL_1, position: POSITION_MEDIAL, set: WORD_SET_CORE })
    AddWordCoding('sea lion', { sound: TARGET_SOUND_L, level: LEVEL_1, position: POSITION_MEDIAL, set: WORD_SET_CORE })
    AddWordCoding('silly', { sound: TARGET_SOUND_L, level: LEVEL_1, position: POSITION_MEDIAL, set: WORD_SET_CORE })
    AddWordCoding('Alex', { sound: TARGET_SOUND_L, level: LEVEL_1, position: POSITION_MEDIAL, set: WORD_SET_CORE })

    AddWordCoding('allow', { sound: TARGET_SOUND_L, level: LEVEL_2, position: POSITION_MEDIAL })
    AddWordCoding('alone', { sound: TARGET_SOUND_L, level: LEVEL_2, position: POSITION_MEDIAL })
    AddWordCoding('ballet', { sound: TARGET_SOUND_L, level: LEVEL_2, position: POSITION_MEDIAL })
    AddWordCoding('balloon', { sound: TARGET_SOUND_L, level: LEVEL_2, position: POSITION_MEDIAL, set: WORD_SET_CORE })
    AddWordCoding('belly', { sound: TARGET_SOUND_L, level: LEVEL_2, position: POSITION_MEDIAL, set: WORD_SET_CORE })
    AddWordCoding('belong', { sound: TARGET_SOUND_L, level: LEVEL_2, position: POSITION_MEDIAL })
    AddWordCoding('bowling', { sound: TARGET_SOUND_L, level: LEVEL_2, position: POSITION_MEDIAL })
    AddWordCoding('buffalo', { sound: TARGET_SOUND_L, level: LEVEL_2, position: POSITION_MEDIAL })
    AddWordCoding('caterpillar', { sound: TARGET_SOUND_L, level: LEVEL_2, position: POSITION_MEDIAL, set: WORD_SET_CORE })
    AddWordCoding('chocolate', { sound: TARGET_SOUND_L, level: LEVEL_2, position: POSITION_MEDIAL, set: WORD_SET_CORE })
    AddWordCoding('color', { sound: TARGET_SOUND_L, level: LEVEL_2, position: POSITION_MEDIAL, set: WORD_SET_CORE })
    AddWordCoding('delicious', { sound: TARGET_SOUND_L, level: LEVEL_2, position: POSITION_MEDIAL })
    AddWordCoding('deliver', { sound: TARGET_SOUND_L, level: LEVEL_2, position: POSITION_MEDIAL })
    AddWordCoding('elbow', { sound: TARGET_SOUND_L, level: LEVEL_2, position: POSITION_MEDIAL })
    AddWordCoding('elephant', { sound: TARGET_SOUND_L, level: LEVEL_2, position: POSITION_MEDIAL, set: WORD_SET_CORE })
    AddWordCoding('eleven', { sound: TARGET_SOUND_L, level: LEVEL_2, position: POSITION_MEDIAL, set: WORD_SET_CORE })
    AddWordCoding('envelope', { sound: TARGET_SOUND_L, level: LEVEL_2, position: POSITION_MEDIAL })
    AddWordCoding('fabulous', { sound: TARGET_SOUND_L, level: LEVEL_2, position: POSITION_MEDIAL })
    AddWordCoding('follow', { sound: TARGET_SOUND_L, level: LEVEL_2, position: POSITION_MEDIAL })
    AddWordCoding('hello', { sound: TARGET_SOUND_L, level: LEVEL_2, position: POSITION_MEDIAL, set: WORD_SET_CORE })
    AddWordCoding('jealous', { sound: TARGET_SOUND_L, level: LEVEL_2, position: POSITION_MEDIAL })
    AddWordCoding('jellybeans', { sound: TARGET_SOUND_L, level: LEVEL_2, position: POSITION_MEDIAL })
    AddWordCoding('melon', { sound: TARGET_SOUND_L, level: LEVEL_2, position: POSITION_MEDIAL })
    AddWordCoding('necklace', { sound: TARGET_SOUND_L, level: LEVEL_2, position: POSITION_MEDIAL })
    AddWordCoding('pillow', { sound: TARGET_SOUND_L, level: LEVEL_2, position: POSITION_MEDIAL, set: WORD_SET_CORE })
    AddWordCoding('pilot', { sound: TARGET_SOUND_L, level: LEVEL_2, position: POSITION_MEDIAL })
    AddWordCoding('polar bear', { sound: TARGET_SOUND_L, level: LEVEL_2, position: POSITION_MEDIAL, set: WORD_SET_CORE })
    AddWordCoding('polite', { sound: TARGET_SOUND_L, level: LEVEL_2, position: POSITION_MEDIAL })
    AddWordCoding('silo', { sound: TARGET_SOUND_L, level: LEVEL_2, position: POSITION_MEDIAL })
    AddWordCoding('skeleton', { sound: TARGET_SOUND_L, level: LEVEL_2, position: POSITION_MEDIAL })
    AddWordCoding('smelly', { sound: TARGET_SOUND_L, level: LEVEL_2, position: POSITION_MEDIAL })
    AddWordCoding('smiling', { sound: TARGET_SOUND_L, level: LEVEL_2, position: POSITION_MEDIAL })
    AddWordCoding('solo', { sound: TARGET_SOUND_L, level: LEVEL_2, position: POSITION_MEDIAL })
    AddWordCoding('toilet', { sound: TARGET_SOUND_L, level: LEVEL_2, position: POSITION_MEDIAL, set: WORD_SET_CORE })
    AddWordCoding('tulip', { sound: TARGET_SOUND_L, level: LEVEL_2, position: POSITION_MEDIAL })
    AddWordCoding('umbrella', { sound: TARGET_SOUND_L, level: LEVEL_2, position: POSITION_MEDIAL })
    AddWordCoding('valley', { sound: TARGET_SOUND_L, level: LEVEL_2, position: POSITION_MEDIAL })
    AddWordCoding('yellow', { sound: TARGET_SOUND_L, level: LEVEL_2, position: POSITION_MEDIAL, set: WORD_SET_CORE })

    AddWordCoding('actually', { sound: TARGET_SOUND_L, level: LEVEL_3, position: POSITION_MEDIAL })
    AddWordCoding('Alaska', { sound: TARGET_SOUND_L, level: LEVEL_3, position: POSITION_MEDIAL, set: WORD_SET_CORE })
    AddWordCoding('alligator', { sound: TARGET_SOUND_L, level: LEVEL_3, position: POSITION_MEDIAL, set: WORD_SET_CORE })
    AddWordCoding('always', { sound: TARGET_SOUND_L, level: LEVEL_3, position: POSITION_MEDIAL })
    AddWordCoding('armadillo', { sound: TARGET_SOUND_L, level: LEVEL_3, position: POSITION_MEDIAL })
    AddWordCoding('broccoli', { sound: TARGET_SOUND_L, level: LEVEL_3, position: POSITION_MEDIAL, set: WORD_SET_CORE })
    AddWordCoding('calendar', { sound: TARGET_SOUND_L, level: LEVEL_3, position: POSITION_MEDIAL, set: WORD_SET_CORE })
    AddWordCoding('canteloupe', { sound: TARGET_SOUND_L, level: LEVEL_3, position: POSITION_MEDIAL })
    AddWordCoding('celebration', { sound: TARGET_SOUND_L, level: LEVEL_3, position: POSITION_MEDIAL })
    AddWordCoding('celery', { sound: TARGET_SOUND_L, level: LEVEL_3, position: POSITION_MEDIAL })
    AddWordCoding('colorful', { sound: TARGET_SOUND_L, level: LEVEL_3, position: POSITION_MEDIAL })
    AddWordCoding('gorilla', { sound: TARGET_SOUND_L, level: LEVEL_3, position: POSITION_MEDIAL, set: WORD_SET_CORE })
    AddWordCoding('Halloween', { sound: TARGET_SOUND_L, level: LEVEL_3, position: POSITION_MEDIAL, set: WORD_SET_CORE })
    AddWordCoding('helicopter', { sound: TARGET_SOUND_L, level: LEVEL_3, position: POSITION_MEDIAL, set: WORD_SET_CORE })
    AddWordCoding('Hollywood', { sound: TARGET_SOUND_L, level: LEVEL_3, position: POSITION_MEDIAL })
    AddWordCoding('jack o lantern', { sound: TARGET_SOUND_L, level: LEVEL_3, position: POSITION_MEDIAL, text: "jack o' lantern" })
    AddWordCoding('koala', { sound: TARGET_SOUND_L, level: LEVEL_3, position: POSITION_MEDIAL, set: WORD_SET_CORE })
    AddWordCoding('lollipop', { sound: TARGET_SOUND_L, level: LEVEL_3, position: POSITION_MEDIAL, set: WORD_SET_CORE })
    AddWordCoding('marshmallow', { sound: TARGET_SOUND_L, level: LEVEL_3, position: POSITION_MEDIAL })
    AddWordCoding('really', { sound: TARGET_SOUND_L, level: LEVEL_3, position: POSITION_MEDIAL })
    AddWordCoding('relax', { sound: TARGET_SOUND_L, level: LEVEL_3, position: POSITION_MEDIAL })
    AddWordCoding('rolling pin', { sound: TARGET_SOUND_L, level: LEVEL_3, position: POSITION_MEDIAL })
    AddWordCoding('ruler', { sound: TARGET_SOUND_L, level: LEVEL_3, position: POSITION_MEDIAL, set: WORD_SET_CORE })
    AddWordCoding('sailboat', { sound: TARGET_SOUND_L, level: LEVEL_3, position: POSITION_MEDIAL })
    AddWordCoding('shoelace', { sound: TARGET_SOUND_L, level: LEVEL_3, position: POSITION_MEDIAL, set: WORD_SET_CORE })
    AddWordCoding('tarantula', { sound: TARGET_SOUND_L, level: LEVEL_3, position: POSITION_MEDIAL })
    AddWordCoding('trampoline', { sound: TARGET_SOUND_L, level: LEVEL_3, position: POSITION_MEDIAL })
    AddWordCoding('two-wheeler', { sound: TARGET_SOUND_L, level: LEVEL_3, position: POSITION_MEDIAL })
    AddWordCoding('wallaby', { sound: TARGET_SOUND_L, level: LEVEL_3, position: POSITION_MEDIAL })
    AddWordCoding('Wally', { sound: TARGET_SOUND_L, level: LEVEL_3, position: POSITION_MEDIAL })
    AddWordCoding('walrus', { sound: TARGET_SOUND_L, level: LEVEL_3, position: POSITION_MEDIAL })
    AddWordCoding('yelling', { sound: TARGET_SOUND_L, level: LEVEL_3, position: POSITION_MEDIAL, set: WORD_SET_CORE })

    // Target sound L: Final
    AddWordCoding('call', { sound: TARGET_SOUND_L, level: LEVEL_1, position: POSITION_FINAL, set: WORD_SET_CORE })
    AddWordCoding('doll', { sound: TARGET_SOUND_L, level: LEVEL_1, position: POSITION_FINAL, set: WORD_SET_CORE })
    AddWordCoding('eel', { sound: TARGET_SOUND_L, level: LEVEL_1, position: POSITION_FINAL, set: WORD_SET_CORE })
    AddWordCoding('hall', { sound: TARGET_SOUND_L, level: LEVEL_1, position: POSITION_FINAL, set: WORD_SET_CORE })
    AddWordCoding('hill', { sound: TARGET_SOUND_L, level: LEVEL_1, position: POSITION_FINAL, set: WORD_SET_CORE })
    AddWordCoding('shell', { sound: TARGET_SOUND_L, level: LEVEL_1, position: POSITION_FINAL, set: WORD_SET_CORE })
    AddWordCoding('ill', { sound: TARGET_SOUND_L, level: LEVEL_1, position: POSITION_FINAL, set: WORD_SET_CORE })
    AddWordCoding('kneel', { sound: TARGET_SOUND_L, level: LEVEL_1, position: POSITION_FINAL })
    AddWordCoding('nail', { sound: TARGET_SOUND_L, level: LEVEL_1, position: POSITION_FINAL, set: WORD_SET_CORE })
    AddWordCoding('sail', { sound: TARGET_SOUND_L, level: LEVEL_1, position: POSITION_FINAL, set: WORD_SET_CORE })
    AddWordCoding('sandal', { sound: TARGET_SOUND_L, level: LEVEL_1, position: POSITION_FINAL })
    AddWordCoding('sea gull', { sound: TARGET_SOUND_L, level: LEVEL_1, position: POSITION_FINAL })
    AddWordCoding('seal', { sound: TARGET_SOUND_L, level: LEVEL_1, position: POSITION_FINAL, set: WORD_SET_CORE })
    AddWordCoding('sell', { sound: TARGET_SOUND_L, level: LEVEL_1, position: POSITION_FINAL, set: WORD_SET_CORE })
    AddWordCoding('sill', { sound: TARGET_SOUND_L, level: LEVEL_1, position: POSITION_FINAL })
    AddWordCoding('snail', { sound: TARGET_SOUND_L, level: LEVEL_1, position: POSITION_FINAL })
    AddWordCoding('tall', { sound: TARGET_SOUND_L, level: LEVEL_1, position: POSITION_FINAL, set: WORD_SET_CORE })

    AddWordCoding('hole', { sound: TARGET_SOUND_L, level: LEVEL_2, position: POSITION_FINAL })
    AddWordCoding('April', { sound: TARGET_SOUND_L, level: LEVEL_2, position: POSITION_FINAL })
    AddWordCoding('ball', { sound: TARGET_SOUND_L, level: LEVEL_2, position: POSITION_FINAL, set: WORD_SET_CORE })
    AddWordCoding('baseball', { sound: TARGET_SOUND_L, level: LEVEL_2, position: POSITION_FINAL, set: WORD_SET_CORE })
    AddWordCoding('bell', { sound: TARGET_SOUND_L, level: LEVEL_2, position: POSITION_FINAL, set: WORD_SET_CORE })
    AddWordCoding('bicycle', { sound: TARGET_SOUND_L, level: LEVEL_2, position: POSITION_FINAL })
    AddWordCoding('camel', { sound: TARGET_SOUND_L, level: LEVEL_2, position: POSITION_FINAL })
    AddWordCoding('Camille', { sound: TARGET_SOUND_L, level: LEVEL_2, position: POSITION_FINAL })
    AddWordCoding('cool', { sound: TARGET_SOUND_L, level: LEVEL_2, position: POSITION_FINAL, set: WORD_SET_CORE })
    AddWordCoding('downhill', { sound: TARGET_SOUND_L, level: LEVEL_2, position: POSITION_FINAL })
    AddWordCoding('fall', { sound: TARGET_SOUND_L, level: LEVEL_2, position: POSITION_FINAL, set: WORD_SET_CORE })
    AddWordCoding('foil', { sound: TARGET_SOUND_L, level: LEVEL_2, position: POSITION_FINAL })
    AddWordCoding('fossil', { sound: TARGET_SOUND_L, level: LEVEL_2, position: POSITION_FINAL })
    AddWordCoding('full', { sound: TARGET_SOUND_L, level: LEVEL_2, position: POSITION_FINAL })
    AddWordCoding('hotel', { sound: TARGET_SOUND_L, level: LEVEL_2, position: POSITION_FINAL })
    AddWordCoding('mail', { sound: TARGET_SOUND_L, level: LEVEL_2, position: POSITION_FINAL })
    AddWordCoding('meatball', { sound: TARGET_SOUND_L, level: LEVEL_2, position: POSITION_FINAL, set: WORD_SET_CORE })
    AddWordCoding('mile', { sound: TARGET_SOUND_L, level: LEVEL_2, position: POSITION_FINAL })
    AddWordCoding('muscle', { sound: TARGET_SOUND_L, level: LEVEL_2, position: POSITION_FINAL })
    AddWordCoding('pail', { sound: TARGET_SOUND_L, level: LEVEL_2, position: POSITION_FINAL })
    AddWordCoding('peel', { sound: TARGET_SOUND_L, level: LEVEL_2, position: POSITION_FINAL })
    AddWordCoding('pencil', { sound: TARGET_SOUND_L, level: LEVEL_2, position: POSITION_FINAL, set: WORD_SET_CORE })
    AddWordCoding('pickle', { sound: TARGET_SOUND_L, level: LEVEL_2, position: POSITION_FINAL })
    AddWordCoding('pigtail', { sound: TARGET_SOUND_L, level: LEVEL_2, position: POSITION_FINAL })
    AddWordCoding('pile', { sound: TARGET_SOUND_L, level: LEVEL_2, position: POSITION_FINAL })
    AddWordCoding('pool', { sound: TARGET_SOUND_L, level: LEVEL_2, position: POSITION_FINAL, set: WORD_SET_CORE })
    AddWordCoding('school', { sound: TARGET_SOUND_L, level: LEVEL_2, position: POSITION_FINAL, set: WORD_SET_CORE })
    AddWordCoding('seashell', { sound: TARGET_SOUND_L, level: LEVEL_2, position: POSITION_FINAL })
    AddWordCoding('Seattle', { sound: TARGET_SOUND_L, level: LEVEL_2, position: POSITION_FINAL })
    AddWordCoding('sizzle', { sound: TARGET_SOUND_L, level: LEVEL_2, position: POSITION_FINAL })
    AddWordCoding('small', { sound: TARGET_SOUND_L, level: LEVEL_2, position: POSITION_FINAL, set: WORD_SET_CORE })
    AddWordCoding('smell', { sound: TARGET_SOUND_L, level: LEVEL_2, position: POSITION_FINAL, set: WORD_SET_CORE })
    AddWordCoding('smile', { sound: TARGET_SOUND_L, level: LEVEL_2, position: POSITION_FINAL, set: WORD_SET_CORE })
    AddWordCoding('softball', { sound: TARGET_SOUND_L, level: LEVEL_2, position: POSITION_FINAL })
    AddWordCoding('stool', { sound: TARGET_SOUND_L, level: LEVEL_2, position: POSITION_FINAL })
    AddWordCoding('tadpole', { sound: TARGET_SOUND_L, level: LEVEL_2, position: POSITION_FINAL })
    AddWordCoding('well', { sound: TARGET_SOUND_L, level: LEVEL_2, position: POSITION_FINAL })

    AddWordCoding('basketball', { sound: TARGET_SOUND_L, level: LEVEL_3, position: POSITION_FINAL, set: WORD_SET_CORE })
    AddWordCoding('cereal', { sound: TARGET_SOUND_L, level: LEVEL_3, position: POSITION_FINAL, set: WORD_SET_CORE })
    AddWordCoding('cheerful', { sound: TARGET_SOUND_L, level: LEVEL_3, position: POSITION_FINAL })
    AddWordCoding('circle', { sound: TARGET_SOUND_L, level: LEVEL_3, position: POSITION_FINAL, set: WORD_SET_CORE })
    AddWordCoding('crocodile', { sound: TARGET_SOUND_L, level: LEVEL_3, position: POSITION_FINAL, set: WORD_SET_CORE })
    AddWordCoding('fairy tale', { sound: TARGET_SOUND_L, level: LEVEL_3, position: POSITION_FINAL })
    AddWordCoding('ferris wheel', { sound: TARGET_SOUND_L, level: LEVEL_3, position: POSITION_FINAL })
    AddWordCoding('fragile', { sound: TARGET_SOUND_L, level: LEVEL_3, position: POSITION_FINAL })
    AddWordCoding('freckle', { sound: TARGET_SOUND_L, level: LEVEL_3, position: POSITION_FINAL })
    AddWordCoding('girl', { sound: TARGET_SOUND_L, level: LEVEL_3, position: POSITION_FINAL, set: WORD_SET_CORE })
    AddWordCoding('Liberty Bell', { sound: TARGET_SOUND_L, level: LEVEL_3, position: POSITION_FINAL })
    AddWordCoding('owl', { sound: TARGET_SOUND_L, level: LEVEL_3, position: POSITION_FINAL, set: WORD_SET_CORE })
    AddWordCoding('pinwheel', { sound: TARGET_SOUND_L, level: LEVEL_3, position: POSITION_FINAL })
    AddWordCoding('popsicle', { sound: TARGET_SOUND_L, level: LEVEL_3, position: POSITION_FINAL, set: WORD_SET_CORE })
    AddWordCoding('principal', { sound: TARGET_SOUND_L, level: LEVEL_3, position: POSITION_FINAL })
    AddWordCoding('rainfall', { sound: TARGET_SOUND_L, level: LEVEL_3, position: POSITION_FINAL })
    AddWordCoding('recycle', { sound: TARGET_SOUND_L, level: LEVEL_3, position: POSITION_FINAL })
    AddWordCoding('reptile', { sound: TARGET_SOUND_L, level: LEVEL_3, position: POSITION_FINAL, set: WORD_SET_CORE })
    AddWordCoding('sea turtle', { sound: TARGET_SOUND_L, level: LEVEL_3, position: POSITION_FINAL, set: WORD_SET_CORE })
    AddWordCoding('snowball', { sound: TARGET_SOUND_L, level: LEVEL_3, position: POSITION_FINAL, set: WORD_SET_CORE })
    AddWordCoding('soccer ball', { sound: TARGET_SOUND_L, level: LEVEL_3, position: POSITION_FINAL })
    AddWordCoding('triangle', { sound: TARGET_SOUND_L, level: LEVEL_3, position: POSITION_FINAL, set: WORD_SET_CORE })
    AddWordCoding('unicycle', { sound: TARGET_SOUND_L, level: LEVEL_3, position: POSITION_FINAL })
    AddWordCoding('whale', { sound: TARGET_SOUND_L, level: LEVEL_3, position: POSITION_FINAL, set: WORD_SET_CORE })
    AddWordCoding('whistle', { sound: TARGET_SOUND_L, level: LEVEL_3, position: POSITION_FINAL })
    AddWordCoding('windmill', { sound: TARGET_SOUND_L, level: LEVEL_3, position: POSITION_FINAL })

    // Target sound L: BL blend
    AddWordCoding('black', { sound: TARGET_SOUND_L, level: LEVEL_1, position: POSITION_BL_BLEND, set: WORD_SET_CORE })
    AddWordCoding('blade', { sound: TARGET_SOUND_L, level: LEVEL_1, position: POSITION_BL_BLEND, variant: 1, set: WORD_SET_CORE })  // grass
    AddWordCoding('blade', { sound: TARGET_SOUND_L, level: LEVEL_1, position: POSITION_BL_BLEND, variant: 2, set: WORD_SET_CORE })  // saw
    AddWordCoding('blade', { sound: TARGET_SOUND_L, level: LEVEL_1, position: POSITION_BL_BLEND, variant: 3, set: WORD_SET_CORE })  // skate
    AddWordCoding('blah', { sound: TARGET_SOUND_L, level: LEVEL_1, position: POSITION_BL_BLEND, set: WORD_SET_CORE })
    AddWordCoding('Blake', { sound: TARGET_SOUND_L, level: LEVEL_1, position: POSITION_BL_BLEND, set: WORD_SET_CORE })
    AddWordCoding('bland', { sound: TARGET_SOUND_L, level: LEVEL_1, position: POSITION_BL_BLEND })
    AddWordCoding('blank', { sound: TARGET_SOUND_L, level: LEVEL_1, position: POSITION_BL_BLEND })
    AddWordCoding('blanket', { sound: TARGET_SOUND_L, level: LEVEL_1, position: POSITION_BL_BLEND, set: WORD_SET_CORE })
    AddWordCoding('blaze', { sound: TARGET_SOUND_L, level: LEVEL_1, position: POSITION_BL_BLEND })
    AddWordCoding('bleach', { sound: TARGET_SOUND_L, level: LEVEL_1, position: POSITION_BL_BLEND })
    AddWordCoding('bleat', { sound: TARGET_SOUND_L, level: LEVEL_1, position: POSITION_BL_BLEND })
    AddWordCoding('block', { sound: TARGET_SOUND_L, level: LEVEL_1, position: POSITION_BL_BLEND, variant: 1, set: WORD_SET_CORE })  // road
    AddWordCoding('block', { sound: TARGET_SOUND_L, level: LEVEL_1, position: POSITION_BL_BLEND, variant: 2, set: WORD_SET_CORE })  // toy
    AddWordCoding('blocks', { sound: TARGET_SOUND_L, level: LEVEL_1, position: POSITION_BL_BLEND, set: WORD_SET_CORE })
    AddWordCoding('blush', { sound: TARGET_SOUND_L, level: LEVEL_1, position: POSITION_BL_BLEND })
    AddWordCoding('nibbling', { sound: TARGET_SOUND_L, level: LEVEL_1, position: POSITION_BL_BLEND })
    AddWordCoding('sibling', { sound: TARGET_SOUND_L, level: LEVEL_1, position: POSITION_BL_BLEND, set: WORD_SET_CORE })
    AddWordCoding('tablet', { sound: TARGET_SOUND_L, level: LEVEL_1, position: POSITION_BL_BLEND, set: WORD_SET_CORE })

    AddWordCoding('ablaze', { sound: TARGET_SOUND_L, level: LEVEL_2, position: POSITION_BL_BLEND })
    AddWordCoding('babbling', { sound: TARGET_SOUND_L, level: LEVEL_2, position: POSITION_BL_BLEND })
    AddWordCoding('blab', { sound: TARGET_SOUND_L, level: LEVEL_2, position: POSITION_BL_BLEND })
    AddWordCoding('black eye', { sound: TARGET_SOUND_L, level: LEVEL_2, position: POSITION_BL_BLEND, set: WORD_SET_CORE })
    AddWordCoding('black sheep', { sound: TARGET_SOUND_L, level: LEVEL_2, position: POSITION_BL_BLEND, set: WORD_SET_CORE })
    AddWordCoding('blacksmith', { sound: TARGET_SOUND_L, level: LEVEL_2, position: POSITION_BL_BLEND })
    AddWordCoding('blades', { sound: TARGET_SOUND_L, level: LEVEL_2, position: POSITION_BL_BLEND })
    AddWordCoding('blame', { sound: TARGET_SOUND_L, level: LEVEL_2, position: POSITION_BL_BLEND })
    AddWordCoding('blast', { sound: TARGET_SOUND_L, level: LEVEL_2, position: POSITION_BL_BLEND })
    AddWordCoding('blew', { sound: TARGET_SOUND_L, level: LEVEL_2, position: POSITION_BL_BLEND, set: WORD_SET_CORE })
    AddWordCoding('blimp', { sound: TARGET_SOUND_L, level: LEVEL_2, position: POSITION_BL_BLEND })
    AddWordCoding('blink', { sound: TARGET_SOUND_L, level: LEVEL_2, position: POSITION_BL_BLEND, set: WORD_SET_CORE })
    AddWordCoding('blip', { sound: TARGET_SOUND_L, level: LEVEL_2, position: POSITION_BL_BLEND })
    AddWordCoding('blob', { sound: TARGET_SOUND_L, level: LEVEL_2, position: POSITION_BL_BLEND, set: WORD_SET_CORE })
    AddWordCoding('blocked', { sound: TARGET_SOUND_L, level: LEVEL_2, position: POSITION_BL_BLEND })
    AddWordCoding('blond', { sound: TARGET_SOUND_L, level: LEVEL_2, position: POSITION_BL_BLEND, set: WORD_SET_CORE })
    AddWordCoding('bloodhound', { sound: TARGET_SOUND_L, level: LEVEL_2, position: POSITION_BL_BLEND })
    AddWordCoding('bloom', { sound: TARGET_SOUND_L, level: LEVEL_2, position: POSITION_BL_BLEND })
    AddWordCoding('bloomed', { sound: TARGET_SOUND_L, level: LEVEL_2, position: POSITION_BL_BLEND })
    AddWordCoding('blossom', { sound: TARGET_SOUND_L, level: LEVEL_2, position: POSITION_BL_BLEND })
    AddWordCoding('blow', { sound: TARGET_SOUND_L, level: LEVEL_2, position: POSITION_BL_BLEND, set: WORD_SET_CORE })
    AddWordCoding('blue', { sound: TARGET_SOUND_L, level: LEVEL_2, position: POSITION_BL_BLEND, set: WORD_SET_CORE })
    AddWordCoding('blue jay', { sound: TARGET_SOUND_L, level: LEVEL_2, position: POSITION_BL_BLEND, set: WORD_SET_CORE })
    AddWordCoding('blue moon', { sound: TARGET_SOUND_L, level: LEVEL_2, position: POSITION_BL_BLEND, set: WORD_SET_CORE })
    AddWordCoding('bluff', { sound: TARGET_SOUND_L, level: LEVEL_2, position: POSITION_BL_BLEND })
    AddWordCoding('blushed', { sound: TARGET_SOUND_L, level: LEVEL_2, position: POSITION_BL_BLEND, set: WORD_SET_CORE })
    AddWordCoding('goblet', { sound: TARGET_SOUND_L, level: LEVEL_2, position: POSITION_BL_BLEND })
    AddWordCoding('goblin', { sound: TARGET_SOUND_L, level: LEVEL_2, position: POSITION_BL_BLEND, set: WORD_SET_CORE })
    AddWordCoding('navy blue', { sound: TARGET_SOUND_L, level: LEVEL_2, position: POSITION_BL_BLEND })
    AddWordCoding('nosebleed', { sound: TARGET_SOUND_L, level: LEVEL_2, position: POSITION_BL_BLEND })
    AddWordCoding('public', { sound: TARGET_SOUND_L, level: LEVEL_2, position: POSITION_BL_BLEND })
    AddWordCoding('pueblo', { sound: TARGET_SOUND_L, level: LEVEL_2, position: POSITION_BL_BLEND })

    AddWordCoding('black bear', { sound: TARGET_SOUND_L, level: LEVEL_3, position: POSITION_BL_BLEND, set: WORD_SET_CORE })
    AddWordCoding('black belt', { sound: TARGET_SOUND_L, level: LEVEL_3, position: POSITION_BL_BLEND, set: WORD_SET_CORE })
    AddWordCoding('blackberry', { sound: TARGET_SOUND_L, level: LEVEL_3, position: POSITION_BL_BLEND, set: WORD_SET_CORE })
    AddWordCoding('blackbird', { sound: TARGET_SOUND_L, level: LEVEL_3, position: POSITION_BL_BLEND, set: WORD_SET_CORE })
    AddWordCoding('blare', { sound: TARGET_SOUND_L, level: LEVEL_3, position: POSITION_BL_BLEND })
    AddWordCoding('blender', { sound: TARGET_SOUND_L, level: LEVEL_3, position: POSITION_BL_BLEND, set: WORD_SET_CORE })
    AddWordCoding('blindfold', { sound: TARGET_SOUND_L, level: LEVEL_3, position: POSITION_BL_BLEND, set: WORD_SET_CORE })
    AddWordCoding('blister', { sound: TARGET_SOUND_L, level: LEVEL_3, position: POSITION_BL_BLEND })
    AddWordCoding('blizzard', { sound: TARGET_SOUND_L, level: LEVEL_3, position: POSITION_BL_BLEND, set: WORD_SET_CORE })
    AddWordCoding('blood pressure', { sound: TARGET_SOUND_L, level: LEVEL_3, position: POSITION_BL_BLEND })
    AddWordCoding('blowtorch', { sound: TARGET_SOUND_L, level: LEVEL_3, position: POSITION_BL_BLEND })
    AddWordCoding('bluebell', { sound: TARGET_SOUND_L, level: LEVEL_3, position: POSITION_BL_BLEND })
    AddWordCoding('blue ribbon', { sound: TARGET_SOUND_L, level: LEVEL_3, position: POSITION_BL_BLEND })
    AddWordCoding('blueberry', { sound: TARGET_SOUND_L, level: LEVEL_3, position: POSITION_BL_BLEND, set: WORD_SET_CORE })
    AddWordCoding('bluebird', { sound: TARGET_SOUND_L, level: LEVEL_3, position: POSITION_BL_BLEND })
    AddWordCoding('bluegrass', { sound: TARGET_SOUND_L, level: LEVEL_3, position: POSITION_BL_BLEND })
    AddWordCoding('blueprint', { sound: TARGET_SOUND_L, level: LEVEL_3, position: POSITION_BL_BLEND })
    AddWordCoding('blur', { sound: TARGET_SOUND_L, level: LEVEL_3, position: POSITION_BL_BLEND })
    AddWordCoding('blurt', { sound: TARGET_SOUND_L, level: LEVEL_3, position: POSITION_BL_BLEND, set: WORD_SET_CORE })
    AddWordCoding('dribbling', { sound: TARGET_SOUND_L, level: LEVEL_3, position: POSITION_BL_BLEND, set: WORD_SET_CORE })
    AddWordCoding('gobbler', { sound: TARGET_SOUND_L, level: LEVEL_3, position: POSITION_BL_BLEND })
    AddWordCoding('light blue', { sound: TARGET_SOUND_L, level: LEVEL_3, position: POSITION_BL_BLEND, set: WORD_SET_CORE })
    AddWordCoding('mumbling', { sound: TARGET_SOUND_L, level: LEVEL_3, position: POSITION_BL_BLEND })
    AddWordCoding('problem', { sound: TARGET_SOUND_L, level: LEVEL_3, position: POSITION_BL_BLEND, set: WORD_SET_CORE })
    AddWordCoding('snowblower', { sound: TARGET_SOUND_L, level: LEVEL_3, position: POSITION_BL_BLEND })

    // Target sound L: FL blend
    AddWordCoding('flag', { sound: TARGET_SOUND_L, level: LEVEL_1, position: POSITION_FL_BLEND, set: WORD_SET_CORE })
    AddWordCoding('flair', { sound: TARGET_SOUND_L, level: LEVEL_1, position: POSITION_FL_BLEND })
    AddWordCoding('flake', { sound: TARGET_SOUND_L, level: LEVEL_1, position: POSITION_FL_BLEND, set: WORD_SET_CORE })
    AddWordCoding('flash', { sound: TARGET_SOUND_L, level: LEVEL_1, position: POSITION_FL_BLEND, set: WORD_SET_CORE })
    AddWordCoding('flashy', { sound: TARGET_SOUND_L, level: LEVEL_1, position: POSITION_FL_BLEND })
    AddWordCoding('flat', { sound: TARGET_SOUND_L, level: LEVEL_1, position: POSITION_FL_BLEND, set: WORD_SET_CORE })
    AddWordCoding('flea', { sound: TARGET_SOUND_L, level: LEVEL_1, position: POSITION_FL_BLEND })
    AddWordCoding('fleck', { sound: TARGET_SOUND_L, level: LEVEL_1, position: POSITION_FL_BLEND })
    AddWordCoding('fleece', { sound: TARGET_SOUND_L, level: LEVEL_1, position: POSITION_FL_BLEND, variant: 1, set: WORD_SET_CORE })  // jacket
    AddWordCoding('fleece', { sound: TARGET_SOUND_L, level: LEVEL_1, position: POSITION_FL_BLEND, variant: 2, set: WORD_SET_CORE })  // wool
    AddWordCoding('fleet', { sound: TARGET_SOUND_L, level: LEVEL_1, position: POSITION_FL_BLEND })
    AddWordCoding('flight', { sound: TARGET_SOUND_L, level: LEVEL_1, position: POSITION_FL_BLEND, set: WORD_SET_CORE })
    AddWordCoding('flock', { sound: TARGET_SOUND_L, level: LEVEL_1, position: POSITION_FL_BLEND, set: WORD_SET_CORE })
    AddWordCoding('flood', { sound: TARGET_SOUND_L, level: LEVEL_1, position: POSITION_FL_BLEND, set: WORD_SET_CORE })
    AddWordCoding('floss', { sound: TARGET_SOUND_L, level: LEVEL_1, position: POSITION_FL_BLEND })
    AddWordCoding('flush', { sound: TARGET_SOUND_L, level: LEVEL_1, position: POSITION_FL_BLEND, set: WORD_SET_CORE })
    AddWordCoding('fly', { sound: TARGET_SOUND_L, level: LEVEL_1, position: POSITION_FL_BLEND, variant: 1, set: WORD_SET_CORE })  // action
    AddWordCoding('fly', { sound: TARGET_SOUND_L, level: LEVEL_1, position: POSITION_FL_BLEND, variant: 2, set: WORD_SET_CORE })  // insect

    AddWordCoding('flame', { sound: TARGET_SOUND_L, level: LEVEL_2, position: POSITION_FL_BLEND, set: WORD_SET_CORE })
    AddWordCoding('flap', { sound: TARGET_SOUND_L, level: LEVEL_2, position: POSITION_FL_BLEND })
    AddWordCoding('flare', { sound: TARGET_SOUND_L, level: LEVEL_2, position: POSITION_FL_BLEND, variant: 1 })  // jeans
    AddWordCoding('flare', { sound: TARGET_SOUND_L, level: LEVEL_2, position: POSITION_FL_BLEND, variant: 2 })  // light
    AddWordCoding('flip', { sound: TARGET_SOUND_L, level: LEVEL_2, position: POSITION_FL_BLEND })
    AddWordCoding('Flo', { sound: TARGET_SOUND_L, level: LEVEL_2, position: POSITION_FL_BLEND, set: WORD_SET_CORE })
    AddWordCoding('float', { sound: TARGET_SOUND_L, level: LEVEL_2, position: POSITION_FL_BLEND, set: WORD_SET_CORE })
    AddWordCoding('flew', { sound: TARGET_SOUND_L, level: LEVEL_2, position: POSITION_FL_BLEND, set: WORD_SET_CORE })
    AddWordCoding('flow', { sound: TARGET_SOUND_L, level: LEVEL_2, position: POSITION_FL_BLEND })
    AddWordCoding('flute', { sound: TARGET_SOUND_L, level: LEVEL_2, position: POSITION_FL_BLEND })
    AddWordCoding('flu', { sound: TARGET_SOUND_L, level: LEVEL_2, position: POSITION_FL_BLEND, set: WORD_SET_CORE })
    AddWordCoding('Flag Day', { sound: TARGET_SOUND_L, level: LEVEL_2, position: POSITION_FL_BLEND })
    AddWordCoding('flagpole', { sound: TARGET_SOUND_L, level: LEVEL_2, position: POSITION_FL_BLEND })
    AddWordCoding('flakes', { sound: TARGET_SOUND_L, level: LEVEL_2, position: POSITION_FL_BLEND })
    AddWordCoding('flaky', { sound: TARGET_SOUND_L, level: LEVEL_2, position: POSITION_FL_BLEND })
    AddWordCoding('flapping', { sound: TARGET_SOUND_L, level: LEVEL_2, position: POSITION_FL_BLEND })
    AddWordCoding('flashbulb', { sound: TARGET_SOUND_L, level: LEVEL_2, position: POSITION_FL_BLEND })
    AddWordCoding('fluid', { sound: TARGET_SOUND_L, level: LEVEL_2, position: POSITION_FL_BLEND })
    AddWordCoding('flunk', { sound: TARGET_SOUND_L, level: LEVEL_2, position: POSITION_FL_BLEND })
    AddWordCoding('flashcard', { sound: TARGET_SOUND_L, level: LEVEL_2, position: POSITION_FL_BLEND, set: WORD_SET_CORE })
    AddWordCoding('flatbed', { sound: TARGET_SOUND_L, level: LEVEL_2, position: POSITION_FL_BLEND })
    AddWordCoding('fleecy', { sound: TARGET_SOUND_L, level: LEVEL_2, position: POSITION_FL_BLEND })
    AddWordCoding('flees', { sound: TARGET_SOUND_L, level: LEVEL_2, position: POSITION_FL_BLEND })
    AddWordCoding('flicker', { sound: TARGET_SOUND_L, level: LEVEL_2, position: POSITION_FL_BLEND, set: WORD_SET_CORE })
    AddWordCoding('flies', { sound: TARGET_SOUND_L, level: LEVEL_2, position: POSITION_FL_BLEND, set: WORD_SET_CORE })
    AddWordCoding('flimsy', { sound: TARGET_SOUND_L, level: LEVEL_2, position: POSITION_FL_BLEND })
    AddWordCoding('flipper', { sound: TARGET_SOUND_L, level: LEVEL_2, position: POSITION_FL_BLEND })
    AddWordCoding('floated', { sound: TARGET_SOUND_L, level: LEVEL_2, position: POSITION_FL_BLEND })
    AddWordCoding('floating', { sound: TARGET_SOUND_L, level: LEVEL_2, position: POSITION_FL_BLEND, set: WORD_SET_CORE })
    AddWordCoding('floppy', { sound: TARGET_SOUND_L, level: LEVEL_2, position: POSITION_FL_BLEND, set: WORD_SET_CORE })
    AddWordCoding('inflate', { sound: TARGET_SOUND_L, level: LEVEL_2, position: POSITION_FL_BLEND, set: WORD_SET_CORE })
    AddWordCoding('sniffle', { sound: TARGET_SOUND_L, level: LEVEL_2, position: POSITION_FL_BLEND, set: WORD_SET_CORE })

    AddWordCoding('butterfly', { sound: TARGET_SOUND_L, level: LEVEL_3, position: POSITION_FL_BLEND, set: WORD_SET_CORE })
    AddWordCoding('camouflage', { sound: TARGET_SOUND_L, level: LEVEL_3, position: POSITION_FL_BLEND })
    AddWordCoding('cauliflower', { sound: TARGET_SOUND_L, level: LEVEL_3, position: POSITION_FL_BLEND })
    AddWordCoding('colorful', { sound: TARGET_SOUND_L, level: LEVEL_3, position: POSITION_FL_BLEND, set: WORD_SET_CORE })
    AddWordCoding('dragonfly', { sound: TARGET_SOUND_L, level: LEVEL_3, position: POSITION_FL_BLEND, set: WORD_SET_CORE })
    AddWordCoding('flamingo', { sound: TARGET_SOUND_L, level: LEVEL_3, position: POSITION_FL_BLEND, set: WORD_SET_CORE })
    AddWordCoding('flannel', { sound: TARGET_SOUND_L, level: LEVEL_3, position: POSITION_FL_BLEND })
    AddWordCoding('flapjack', { sound: TARGET_SOUND_L, level: LEVEL_3, position: POSITION_FL_BLEND })
    AddWordCoding('flashlight', { sound: TARGET_SOUND_L, level: LEVEL_3, position: POSITION_FL_BLEND, set: WORD_SET_CORE })
    AddWordCoding('flavor', { sound: TARGET_SOUND_L, level: LEVEL_3, position: POSITION_FL_BLEND, set: WORD_SET_CORE })
    AddWordCoding('flex', { sound: TARGET_SOUND_L, level: LEVEL_3, position: POSITION_FL_BLEND })
    AddWordCoding('flip-flops', { sound: TARGET_SOUND_L, level: LEVEL_3, position: POSITION_FL_BLEND })
    AddWordCoding('flippers', { sound: TARGET_SOUND_L, level: LEVEL_3, position: POSITION_FL_BLEND })
    AddWordCoding('floor', { sound: TARGET_SOUND_L, level: LEVEL_3, position: POSITION_FL_BLEND, set: WORD_SET_CORE })
    AddWordCoding('floor lamp', { sound: TARGET_SOUND_L, level: LEVEL_3, position: POSITION_FL_BLEND })
    AddWordCoding('Florida', { sound: TARGET_SOUND_L, level: LEVEL_3, position: POSITION_FL_BLEND, set: WORD_SET_CORE })
    AddWordCoding('fluffy', { sound: TARGET_SOUND_L, level: LEVEL_3, position: POSITION_FL_BLEND })
    AddWordCoding('flurries', { sound: TARGET_SOUND_L, level: LEVEL_3, position: POSITION_FL_BLEND, set: WORD_SET_CORE })
    AddWordCoding('inflatable', { sound: TARGET_SOUND_L, level: LEVEL_3, position: POSITION_FL_BLEND })
    AddWordCoding('muffler', { sound: TARGET_SOUND_L, level: LEVEL_3, position: POSITION_FL_BLEND })
    AddWordCoding('overflow', { sound: TARGET_SOUND_L, level: LEVEL_3, position: POSITION_FL_BLEND })
    AddWordCoding('reflection', { sound: TARGET_SOUND_L, level: LEVEL_3, position: POSITION_FL_BLEND })
    AddWordCoding('reflector', { sound: TARGET_SOUND_L, level: LEVEL_3, position: POSITION_FL_BLEND })
    AddWordCoding('reflex', { sound: TARGET_SOUND_L, level: LEVEL_3, position: POSITION_FL_BLEND })
    AddWordCoding('snowflake', { sound: TARGET_SOUND_L, level: LEVEL_3, position: POSITION_FL_BLEND, set: WORD_SET_CORE })
    AddWordCoding('spoonful', { sound: TARGET_SOUND_L, level: LEVEL_3, position: POSITION_FL_BLEND })
    AddWordCoding('sunflower', { sound: TARGET_SOUND_L, level: LEVEL_3, position: POSITION_FL_BLEND, set: WORD_SET_CORE })
    AddWordCoding('waffle', { sound: TARGET_SOUND_L, level: LEVEL_3, position: POSITION_FL_BLEND, set: WORD_SET_CORE })

    // Target sound L: GL blend
    AddWordCoding('eagle', { sound: TARGET_SOUND_L, level: LEVEL_1, position: POSITION_GL_BLEND, set: WORD_SET_CORE })
    AddWordCoding('eyeglass', { sound: TARGET_SOUND_L, level: LEVEL_1, position: POSITION_GL_BLEND, set: WORD_SET_CORE })
    AddWordCoding('giggling', { sound: TARGET_SOUND_L, level: LEVEL_1, position: POSITION_GL_BLEND, set: WORD_SET_CORE })
    AddWordCoding('giggly', { sound: TARGET_SOUND_L, level: LEVEL_1, position: POSITION_GL_BLEND })
    AddWordCoding('glad', { sound: TARGET_SOUND_L, level: LEVEL_1, position: POSITION_GL_BLEND, set: WORD_SET_CORE })
    AddWordCoding('glass', { sound: TARGET_SOUND_L, level: LEVEL_1, position: POSITION_GL_BLEND, set: WORD_SET_CORE })
    AddWordCoding('glasses', { sound: TARGET_SOUND_L, level: LEVEL_1, position: POSITION_GL_BLEND, set: WORD_SET_CORE })
    AddWordCoding('glaze', { sound: TARGET_SOUND_L, level: LEVEL_1, position: POSITION_GL_BLEND, set: WORD_SET_CORE })
    AddWordCoding('glazed', { sound: TARGET_SOUND_L, level: LEVEL_1, position: POSITION_GL_BLEND, set: WORD_SET_CORE })
    AddWordCoding('glee', { sound: TARGET_SOUND_L, level: LEVEL_1, position: POSITION_GL_BLEND })
    AddWordCoding('Glen', { sound: TARGET_SOUND_L, level: LEVEL_1, position: POSITION_GL_BLEND })
    AddWordCoding('glide', { sound: TARGET_SOUND_L, level: LEVEL_1, position: POSITION_GL_BLEND })
    AddWordCoding('glint', { sound: TARGET_SOUND_L, level: LEVEL_1, position: POSITION_GL_BLEND })
    AddWordCoding('glisten', { sound: TARGET_SOUND_L, level: LEVEL_1, position: POSITION_GL_BLEND, set: WORD_SET_CORE })
    AddWordCoding('gloss', { sound: TARGET_SOUND_L, level: LEVEL_1, position: POSITION_GL_BLEND })
    AddWordCoding('glug', { sound: TARGET_SOUND_L, level: LEVEL_1, position: POSITION_GL_BLEND })
    AddWordCoding('hang gliding', { sound: TARGET_SOUND_L, level: LEVEL_1, position: POSITION_GL_BLEND, set: WORD_SET_CORE })
    AddWordCoding('juggling', { sound: TARGET_SOUND_L, level: LEVEL_1, position: POSITION_GL_BLEND, set: WORD_SET_CORE })
    AddWordCoding('snuggle', { sound: TARGET_SOUND_L, level: LEVEL_1, position: POSITION_GL_BLEND })
    AddWordCoding('ugly', { sound: TARGET_SOUND_L, level: LEVEL_1, position: POSITION_GL_BLEND, set: WORD_SET_CORE })

    AddWordCoding('glue', { sound: TARGET_SOUND_L, level: LEVEL_2, position: POSITION_GL_BLEND, set: WORD_SET_CORE })
    AddWordCoding('glum', { sound: TARGET_SOUND_L, level: LEVEL_2, position: POSITION_GL_BLEND })
    AddWordCoding('beagle', { sound: TARGET_SOUND_L, level: LEVEL_2, position: POSITION_GL_BLEND })
    AddWordCoding('dangling', { sound: TARGET_SOUND_L, level: LEVEL_2, position: POSITION_GL_BLEND })
    AddWordCoding('glob', { sound: TARGET_SOUND_L, level: LEVEL_2, position: POSITION_GL_BLEND, set: WORD_SET_CORE })
    AddWordCoding('globe', { sound: TARGET_SOUND_L, level: LEVEL_2, position: POSITION_GL_BLEND, set: WORD_SET_CORE })
    AddWordCoding('glop', { sound: TARGET_SOUND_L, level: LEVEL_2, position: POSITION_GL_BLEND })
    AddWordCoding('glow', { sound: TARGET_SOUND_L, level: LEVEL_2, position: POSITION_GL_BLEND })
    AddWordCoding('glove', { sound: TARGET_SOUND_L, level: LEVEL_2, position: POSITION_GL_BLEND, set: WORD_SET_CORE })
    AddWordCoding('glacier', { sound: TARGET_SOUND_L, level: LEVEL_2, position: POSITION_GL_BLEND, set: WORD_SET_CORE })
    AddWordCoding('glamour', { sound: TARGET_SOUND_L, level: LEVEL_2, position: POSITION_GL_BLEND })
    AddWordCoding('glider', { sound: TARGET_SOUND_L, level: LEVEL_2, position: POSITION_GL_BLEND, set: WORD_SET_CORE })
    AddWordCoding('glimmer', { sound: TARGET_SOUND_L, level: LEVEL_2, position: POSITION_GL_BLEND })
    AddWordCoding('Glinda', { sound: TARGET_SOUND_L, level: LEVEL_2, position: POSITION_GL_BLEND })
    AddWordCoding('global', { sound: TARGET_SOUND_L, level: LEVEL_2, position: POSITION_GL_BLEND })
    AddWordCoding('gloomy', { sound: TARGET_SOUND_L, level: LEVEL_2, position: POSITION_GL_BLEND, set: WORD_SET_CORE })
    AddWordCoding('glossy', { sound: TARGET_SOUND_L, level: LEVEL_2, position: POSITION_GL_BLEND, set: WORD_SET_CORE })
    AddWordCoding('igloo', { sound: TARGET_SOUND_L, level: LEVEL_2, position: POSITION_GL_BLEND, set: WORD_SET_CORE })
    AddWordCoding('triangle', { sound: TARGET_SOUND_L, level: LEVEL_2, position: POSITION_GL_BLEND, set: WORD_SET_CORE })
    AddWordCoding('piglet', { sound: TARGET_SOUND_L, level: LEVEL_2, position: POSITION_GL_BLEND, set: WORD_SET_CORE })
    AddWordCoding('ringlet', { sound: TARGET_SOUND_L, level: LEVEL_2, position: POSITION_GL_BLEND })
    AddWordCoding('sunglasses', { sound: TARGET_SOUND_L, level: LEVEL_2, position: POSITION_GL_BLEND, set: WORD_SET_CORE })

    AddWordCoding('baseball glove', { sound: TARGET_SOUND_L, level: LEVEL_3, position: POSITION_GL_BLEND, set: WORD_SET_CORE })
    AddWordCoding('boxing glove', { sound: TARGET_SOUND_L, level: LEVEL_3, position: POSITION_GL_BLEND, set: WORD_SET_CORE })
    AddWordCoding('Everglades', { sound: TARGET_SOUND_L, level: LEVEL_3, position: POSITION_GL_BLEND, set: WORD_SET_CORE })
    AddWordCoding('gladiator', { sound: TARGET_SOUND_L, level: LEVEL_3, position: POSITION_GL_BLEND })
    AddWordCoding('glamorous', { sound: TARGET_SOUND_L, level: LEVEL_3, position: POSITION_GL_BLEND })
    AddWordCoding('gleeful', { sound: TARGET_SOUND_L, level: LEVEL_3, position: POSITION_GL_BLEND })
    AddWordCoding('glider plane', { sound: TARGET_SOUND_L, level: LEVEL_3, position: POSITION_GL_BLEND, set: WORD_SET_CORE })
    AddWordCoding('globeflower', { sound: TARGET_SOUND_L, level: LEVEL_3, position: POSITION_GL_BLEND })
    AddWordCoding('glossary', { sound: TARGET_SOUND_L, level: LEVEL_3, position: POSITION_GL_BLEND })
    AddWordCoding('glow worm', { sound: TARGET_SOUND_L, level: LEVEL_3, position: POSITION_GL_BLEND, set: WORD_SET_CORE })
    AddWordCoding('glue stick', { sound: TARGET_SOUND_L, level: LEVEL_3, position: POSITION_GL_BLEND, set: WORD_SET_CORE })
    AddWordCoding('hieroglyphic', { sound: TARGET_SOUND_L, level: LEVEL_3, position: POSITION_GL_BLEND, set: WORD_SET_CORE })
    AddWordCoding('hourglass', { sound: TARGET_SOUND_L, level: LEVEL_3, position: POSITION_GL_BLEND, set: WORD_SET_CORE })
    AddWordCoding('juggler', { sound: TARGET_SOUND_L, level: LEVEL_3, position: POSITION_GL_BLEND, set: WORD_SET_CORE })
    AddWordCoding('lip gloss', { sound: TARGET_SOUND_L, level: LEVEL_3, position: POSITION_GL_BLEND })
    AddWordCoding('snow globe', { sound: TARGET_SOUND_L, level: LEVEL_3, position: POSITION_GL_BLEND, set: WORD_SET_CORE })
    AddWordCoding('spyglass', { sound: TARGET_SOUND_L, level: LEVEL_3, position: POSITION_GL_BLEND })
    AddWordCoding('superglue', { sound: TARGET_SOUND_L, level: LEVEL_3, position: POSITION_GL_BLEND })
    AddWordCoding('ugli fruit', { sound: TARGET_SOUND_L, level: LEVEL_3, position: POSITION_GL_BLEND })
    AddWordCoding('wiggle', { sound: TARGET_SOUND_L, level: LEVEL_3, position: POSITION_GL_BLEND, set: WORD_SET_CORE })
    AddWordCoding('window glass', { sound: TARGET_SOUND_L, level: LEVEL_3, position: POSITION_GL_BLEND })
    AddWordCoding('work gloves', { sound: TARGET_SOUND_L, level: LEVEL_3, position: POSITION_GL_BLEND })
    AddWordCoding('wriggly', { sound: TARGET_SOUND_L, level: LEVEL_3, position: POSITION_GL_BLEND, set: WORD_SET_CORE })

    // Target sound L: KL blend
    AddWordCoding('chuckle', { sound: TARGET_SOUND_L, level: LEVEL_1, position: POSITION_KL_BLEND })
    AddWordCoding('clang', { sound: TARGET_SOUND_L, level: LEVEL_1, position: POSITION_KL_BLEND })
    AddWordCoding('class', { sound: TARGET_SOUND_L, level: LEVEL_1, position: POSITION_KL_BLEND, set: WORD_SET_CORE })
    AddWordCoding('clay', { sound: TARGET_SOUND_L, level: LEVEL_1, position: POSITION_KL_BLEND, set: WORD_SET_CORE })
    AddWordCoding('clean', { sound: TARGET_SOUND_L, level: LEVEL_1, position: POSITION_KL_BLEND, set: WORD_SET_CORE })
    AddWordCoding('cleaned', { sound: TARGET_SOUND_L, level: LEVEL_1, position: POSITION_KL_BLEND, set: WORD_SET_CORE })
    AddWordCoding('cleats', { sound: TARGET_SOUND_L, level: LEVEL_1, position: POSITION_KL_BLEND })
    AddWordCoding('click', { sound: TARGET_SOUND_L, level: LEVEL_1, position: POSITION_KL_BLEND, set: WORD_SET_CORE })
    AddWordCoding('cling', { sound: TARGET_SOUND_L, level: LEVEL_1, position: POSITION_KL_BLEND })
    AddWordCoding('clock', { sound: TARGET_SOUND_L, level: LEVEL_1, position: POSITION_KL_BLEND, set: WORD_SET_CORE })
    AddWordCoding('closet', { sound: TARGET_SOUND_L, level: LEVEL_1, position: POSITION_KL_BLEND, set: WORD_SET_CORE })
    AddWordCoding('cloth', { sound: TARGET_SOUND_L, level: LEVEL_1, position: POSITION_KL_BLEND })
    AddWordCoding('cluck', { sound: TARGET_SOUND_L, level: LEVEL_1, position: POSITION_KL_BLEND, set: WORD_SET_CORE })
    AddWordCoding('cycling', { sound: TARGET_SOUND_L, level: LEVEL_1, position: POSITION_KL_BLEND })
    AddWordCoding('duckling', { sound: TARGET_SOUND_L, level: LEVEL_1, position: POSITION_KL_BLEND, set: WORD_SET_CORE })
    AddWordCoding('necklace', { sound: TARGET_SOUND_L, level: LEVEL_1, position: POSITION_KL_BLEND, set: WORD_SET_CORE })

    AddWordCoding('anklet', { sound: TARGET_SOUND_L, level: LEVEL_2, position: POSITION_KL_BLEND })
    AddWordCoding('checklist', { sound: TARGET_SOUND_L, level: LEVEL_2, position: POSITION_KL_BLEND, set: WORD_SET_CORE })
    AddWordCoding('clam', { sound: TARGET_SOUND_L, level: LEVEL_2, position: POSITION_KL_BLEND })
    AddWordCoding('clamp', { sound: TARGET_SOUND_L, level: LEVEL_2, position: POSITION_KL_BLEND })
    AddWordCoding('clap', { sound: TARGET_SOUND_L, level: LEVEL_2, position: POSITION_KL_BLEND, set: WORD_SET_CORE })
    AddWordCoding('clapped', { sound: TARGET_SOUND_L, level: LEVEL_2, position: POSITION_KL_BLEND })
    AddWordCoding('clasp', { sound: TARGET_SOUND_L, level: LEVEL_2, position: POSITION_KL_BLEND })
    AddWordCoding('classmate', { sound: TARGET_SOUND_L, level: LEVEL_2, position: POSITION_KL_BLEND })
    AddWordCoding('claw', { sound: TARGET_SOUND_L, level: LEVEL_2, position: POSITION_KL_BLEND })
    AddWordCoding('clawed', { sound: TARGET_SOUND_L, level: LEVEL_2, position: POSITION_KL_BLEND })
    AddWordCoding('cleanup', { sound: TARGET_SOUND_L, level: LEVEL_2, position: POSITION_KL_BLEND, set: WORD_SET_CORE })
    AddWordCoding('Cleo', { sound: TARGET_SOUND_L, level: LEVEL_2, position: POSITION_KL_BLEND })
    AddWordCoding('cliff', { sound: TARGET_SOUND_L, level: LEVEL_2, position: POSITION_KL_BLEND })
    AddWordCoding('climb', { sound: TARGET_SOUND_L, level: LEVEL_2, position: POSITION_KL_BLEND, set: WORD_SET_CORE })
    AddWordCoding('climbed', { sound: TARGET_SOUND_L, level: LEVEL_2, position: POSITION_KL_BLEND })
    AddWordCoding('climbing', { sound: TARGET_SOUND_L, level: LEVEL_2, position: POSITION_KL_BLEND })
    AddWordCoding('clip', { sound: TARGET_SOUND_L, level: LEVEL_2, position: POSITION_KL_BLEND })
    AddWordCoding('clipboard', { sound: TARGET_SOUND_L, level: LEVEL_2, position: POSITION_KL_BLEND, set: WORD_SET_CORE })
    AddWordCoding('clipper', { sound: TARGET_SOUND_L, level: LEVEL_2, position: POSITION_KL_BLEND })
    AddWordCoding('clippers', { sound: TARGET_SOUND_L, level: LEVEL_2, position: POSITION_KL_BLEND, variant: 1 })  // hair
    AddWordCoding('clippers', { sound: TARGET_SOUND_L, level: LEVEL_2, position: POSITION_KL_BLEND, variant: 2 })  // nail
    AddWordCoding('close', { sound: TARGET_SOUND_L, level: LEVEL_2, position: POSITION_KL_BLEND, set: WORD_SET_CORE })
    AddWordCoding('close-up', { sound: TARGET_SOUND_L, level: LEVEL_2, position: POSITION_KL_BLEND })
    AddWordCoding('closed', { sound: TARGET_SOUND_L, level: LEVEL_2, position: POSITION_KL_BLEND })
    AddWordCoding('closing', { sound: TARGET_SOUND_L, level: LEVEL_2, position: POSITION_KL_BLEND })
    AddWordCoding('clothes', { sound: TARGET_SOUND_L, level: LEVEL_2, position: POSITION_KL_BLEND, set: WORD_SET_CORE })
    AddWordCoding('cloud', { sound: TARGET_SOUND_L, level: LEVEL_2, position: POSITION_KL_BLEND, set: WORD_SET_CORE })
    AddWordCoding('cloudy', { sound: TARGET_SOUND_L, level: LEVEL_2, position: POSITION_KL_BLEND })
    AddWordCoding('clown', { sound: TARGET_SOUND_L, level: LEVEL_2, position: POSITION_KL_BLEND, set: WORD_SET_CORE })
    AddWordCoding('club', { sound: TARGET_SOUND_L, level: LEVEL_2, position: POSITION_KL_BLEND })
    AddWordCoding('clue', { sound: TARGET_SOUND_L, level: LEVEL_2, position: POSITION_KL_BLEND, set: WORD_SET_CORE })
    AddWordCoding('cyclone', { sound: TARGET_SOUND_L, level: LEVEL_2, position: POSITION_KL_BLEND })
    AddWordCoding('eclipse', { sound: TARGET_SOUND_L, level: LEVEL_2, position: POSITION_KL_BLEND })
    AddWordCoding('klutz', { sound: TARGET_SOUND_L, level: LEVEL_2, position: POSITION_KL_BLEND })
    AddWordCoding('oclock', { sound: TARGET_SOUND_L, level: LEVEL_2, position: POSITION_KL_BLEND, text: "o'clock" })
    AddWordCoding('popsicle', { sound: TARGET_SOUND_L, level: LEVEL_2, position: POSITION_KL_BLEND })

    AddWordCoding('alarm clock', { sound: TARGET_SOUND_L, level: LEVEL_3, position: POSITION_KL_BLEND })
    AddWordCoding('chuckling', { sound: TARGET_SOUND_L, level: LEVEL_3, position: POSITION_KL_BLEND })
    AddWordCoding('classroom', { sound: TARGET_SOUND_L, level: LEVEL_3, position: POSITION_KL_BLEND, set: WORD_SET_CORE })
    AddWordCoding('clear', { sound: TARGET_SOUND_L, level: LEVEL_3, position: POSITION_KL_BLEND })
    AddWordCoding('climber', { sound: TARGET_SOUND_L, level: LEVEL_3, position: POSITION_KL_BLEND, set: WORD_SET_CORE })
    AddWordCoding('clover', { sound: TARGET_SOUND_L, level: LEVEL_3, position: POSITION_KL_BLEND })
    AddWordCoding('crackling', { sound: TARGET_SOUND_L, level: LEVEL_3, position: POSITION_KL_BLEND })
    AddWordCoding('dishcloth', { sound: TARGET_SOUND_L, level: LEVEL_3, position: POSITION_KL_BLEND })
    AddWordCoding('forklift', { sound: TARGET_SOUND_L, level: LEVEL_3, position: POSITION_KL_BLEND, set: WORD_SET_CORE })
    AddWordCoding('four leaf clover', { sound: TARGET_SOUND_L, level: LEVEL_3, position: POSITION_KL_BLEND, set: WORD_SET_CORE })
    AddWordCoding('freckle', { sound: TARGET_SOUND_L, level: LEVEL_3, position: POSITION_KL_BLEND })
    AddWordCoding('kleenex', { sound: TARGET_SOUND_L, level: LEVEL_3, position: POSITION_KL_BLEND, set: WORD_SET_CORE })
    AddWordCoding('motorcycle', { sound: TARGET_SOUND_L, level: LEVEL_3, position: POSITION_KL_BLEND })
    AddWordCoding('Oklahoma', { sound: TARGET_SOUND_L, level: LEVEL_3, position: POSITION_KL_BLEND, set: WORD_SET_CORE })
    AddWordCoding('paper clip', { sound: TARGET_SOUND_L, level: LEVEL_3, position: POSITION_KL_BLEND, set: WORD_SET_CORE })
    AddWordCoding('prickly', { sound: TARGET_SOUND_L, level: LEVEL_3, position: POSITION_KL_BLEND })
    AddWordCoding('quickly', { sound: TARGET_SOUND_L, level: LEVEL_3, position: POSITION_KL_BLEND })
    AddWordCoding('recycle', { sound: TARGET_SOUND_L, level: LEVEL_3, position: POSITION_KL_BLEND })
    AddWordCoding('snorkel', { sound: TARGET_SOUND_L, level: LEVEL_3, position: POSITION_KL_BLEND })
    AddWordCoding('sparkle', { sound: TARGET_SOUND_L, level: LEVEL_3, position: POSITION_KL_BLEND })
    AddWordCoding('sparkling', { sound: TARGET_SOUND_L, level: LEVEL_3, position: POSITION_KL_BLEND })
    AddWordCoding('sprinkler', { sound: TARGET_SOUND_L, level: LEVEL_3, position: POSITION_KL_BLEND, set: WORD_SET_CORE })
    AddWordCoding('tablecloth', { sound: TARGET_SOUND_L, level: LEVEL_3, position: POSITION_KL_BLEND, set: WORD_SET_CORE })
    AddWordCoding('truckload', { sound: TARGET_SOUND_L, level: LEVEL_3, position: POSITION_KL_BLEND })
    AddWordCoding('twinkling', { sound: TARGET_SOUND_L, level: LEVEL_3, position: POSITION_KL_BLEND })
    AddWordCoding('unicycle', { sound: TARGET_SOUND_L, level: LEVEL_3, position: POSITION_KL_BLEND })
    AddWordCoding('vacuum cleaner', { sound: TARGET_SOUND_L, level: LEVEL_3, position: POSITION_KL_BLEND, set: WORD_SET_CORE })
    AddWordCoding('weekly', { sound: TARGET_SOUND_L, level: LEVEL_3, position: POSITION_KL_BLEND })
    AddWordCoding('wrinkled', { sound: TARGET_SOUND_L, level: LEVEL_3, position: POSITION_KL_BLEND })

    // Target sound L: PL blend
    AddWordCoding('place', { sound: TARGET_SOUND_L, level: LEVEL_1, position: POSITION_PL_BLEND, set: WORD_SET_CORE })
    AddWordCoding('plain', { sound: TARGET_SOUND_L, level: LEVEL_1, position: POSITION_PL_BLEND, set: WORD_SET_CORE })
    AddWordCoding('plan', { sound: TARGET_SOUND_L, level: LEVEL_1, position: POSITION_PL_BLEND })
    AddWordCoding('plane', { sound: TARGET_SOUND_L, level: LEVEL_1, position: POSITION_PL_BLEND, set: WORD_SET_CORE })
    AddWordCoding('plant', { sound: TARGET_SOUND_L, level: LEVEL_1, position: POSITION_PL_BLEND, set: WORD_SET_CORE })
    AddWordCoding('plaque', { sound: TARGET_SOUND_L, level: LEVEL_1, position: POSITION_PL_BLEND, variant: 1 })  // award
    AddWordCoding('plaque', { sound: TARGET_SOUND_L, level: LEVEL_1, position: POSITION_PL_BLEND, variant: 2 })  // teeth
    AddWordCoding('plate', { sound: TARGET_SOUND_L, level: LEVEL_1, position: POSITION_PL_BLEND, set: WORD_SET_CORE })
    AddWordCoding('play', { sound: TARGET_SOUND_L, level: LEVEL_1, position: POSITION_PL_BLEND, variant: 1, set: WORD_SET_CORE })  // button
    AddWordCoding('play', { sound: TARGET_SOUND_L, level: LEVEL_1, position: POSITION_PL_BLEND, variant: 2, set: WORD_SET_CORE })  // drama
    AddWordCoding('play', { sound: TARGET_SOUND_L, level: LEVEL_1, position: POSITION_PL_BLEND, variant: 3, set: WORD_SET_CORE })  // game
    AddWordCoding('please', { sound: TARGET_SOUND_L, level: LEVEL_1, position: POSITION_PL_BLEND, set: WORD_SET_CORE })
    AddWordCoding('plot', { sound: TARGET_SOUND_L, level: LEVEL_1, position: POSITION_PL_BLEND })
    AddWordCoding('pluck', { sound: TARGET_SOUND_L, level: LEVEL_1, position: POSITION_PL_BLEND })
    AddWordCoding('plug', { sound: TARGET_SOUND_L, level: LEVEL_1, position: POSITION_PL_BLEND, set: WORD_SET_CORE })
    AddWordCoding('plum', { sound: TARGET_SOUND_L, level: LEVEL_1, position: POSITION_PL_BLEND, set: WORD_SET_CORE })
    AddWordCoding('plump', { sound: TARGET_SOUND_L, level: LEVEL_1, position: POSITION_PL_BLEND })
    AddWordCoding('plus', { sound: TARGET_SOUND_L, level: LEVEL_1, position: POSITION_PL_BLEND, set: WORD_SET_CORE })
    AddWordCoding('plush', { sound: TARGET_SOUND_L, level: LEVEL_1, position: POSITION_PL_BLEND })

    AddWordCoding('plow', { sound: TARGET_SOUND_L, level: LEVEL_2, position: POSITION_PL_BLEND })
    AddWordCoding('applause', { sound: TARGET_SOUND_L, level: LEVEL_2, position: POSITION_PL_BLEND, set: WORD_SET_CORE })
    AddWordCoding('apple', { sound: TARGET_SOUND_L, level: LEVEL_2, position: POSITION_PL_BLEND, set: WORD_SET_CORE })
    AddWordCoding('complain', { sound: TARGET_SOUND_L, level: LEVEL_2, position: POSITION_PL_BLEND })
    AddWordCoding('complete', { sound: TARGET_SOUND_L, level: LEVEL_2, position: POSITION_PL_BLEND })
    AddWordCoding('display', { sound: TARGET_SOUND_L, level: LEVEL_2, position: POSITION_PL_BLEND })
    AddWordCoding('eggplant', { sound: TARGET_SOUND_L, level: LEVEL_2, position: POSITION_PL_BLEND, set: WORD_SET_CORE })
    AddWordCoding('explode', { sound: TARGET_SOUND_L, level: LEVEL_2, position: POSITION_PL_BLEND, set: WORD_SET_CORE })
    AddWordCoding('maple', { sound: TARGET_SOUND_L, level: LEVEL_2, position: POSITION_PL_BLEND, set: WORD_SET_CORE })
    AddWordCoding('people', { sound: TARGET_SOUND_L, level: LEVEL_2, position: POSITION_PL_BLEND, set: WORD_SET_CORE })
    AddWordCoding('placemat', { sound: TARGET_SOUND_L, level: LEVEL_2, position: POSITION_PL_BLEND, set: WORD_SET_CORE })
    AddWordCoding('planet', { sound: TARGET_SOUND_L, level: LEVEL_2, position: POSITION_PL_BLEND, set: WORD_SET_CORE })
    AddWordCoding('plank', { sound: TARGET_SOUND_L, level: LEVEL_2, position: POSITION_PL_BLEND })
    AddWordCoding('plankton', { sound: TARGET_SOUND_L, level: LEVEL_2, position: POSITION_PL_BLEND })
    AddWordCoding('plastic', { sound: TARGET_SOUND_L, level: LEVEL_2, position: POSITION_PL_BLEND, set: WORD_SET_CORE })
    AddWordCoding('playful', { sound: TARGET_SOUND_L, level: LEVEL_2, position: POSITION_PL_BLEND, set: WORD_SET_CORE })
    AddWordCoding('pleasant', { sound: TARGET_SOUND_L, level: LEVEL_2, position: POSITION_PL_BLEND })
    AddWordCoding('plumber', { sound: TARGET_SOUND_L, level: LEVEL_2, position: POSITION_PL_BLEND, set: WORD_SET_CORE })
    AddWordCoding('plunge', { sound: TARGET_SOUND_L, level: LEVEL_2, position: POSITION_PL_BLEND })
    AddWordCoding('plural', { sound: TARGET_SOUND_L, level: LEVEL_2, position: POSITION_PL_BLEND })
    AddWordCoding('Pluto', { sound: TARGET_SOUND_L, level: LEVEL_2, position: POSITION_PL_BLEND, set: WORD_SET_CORE })
    AddWordCoding('sample', { sound: TARGET_SOUND_L, level: LEVEL_2, position: POSITION_PL_BLEND })
    AddWordCoding('seaplane', { sound: TARGET_SOUND_L, level: LEVEL_2, position: POSITION_PL_BLEND })

    AddWordCoding('airplane', { sound: TARGET_SOUND_L, level: LEVEL_3, position: POSITION_PL_BLEND, set: WORD_SET_CORE })
    AddWordCoding('apple juice', { sound: TARGET_SOUND_L, level: LEVEL_3, position: POSITION_PL_BLEND, set: WORD_SET_CORE })
    AddWordCoding('appliance', { sound: TARGET_SOUND_L, level: LEVEL_3, position: POSITION_PL_BLEND })
    AddWordCoding('compliment', { sound: TARGET_SOUND_L, level: LEVEL_3, position: POSITION_PL_BLEND })
    AddWordCoding('explore', { sound: TARGET_SOUND_L, level: LEVEL_3, position: POSITION_PL_BLEND })
    AddWordCoding('explosion', { sound: TARGET_SOUND_L, level: LEVEL_3, position: POSITION_PL_BLEND })
    AddWordCoding('fireplace', { sound: TARGET_SOUND_L, level: LEVEL_3, position: POSITION_PL_BLEND, set: WORD_SET_CORE })
    AddWordCoding('glider plane', { sound: TARGET_SOUND_L, level: LEVEL_3, position: POSITION_PL_BLEND })
    AddWordCoding('home plate', { sound: TARGET_SOUND_L, level: LEVEL_3, position: POSITION_PL_BLEND })
    AddWordCoding('houseplant', { sound: TARGET_SOUND_L, level: LEVEL_3, position: POSITION_PL_BLEND })
    AddWordCoding('license plate', { sound: TARGET_SOUND_L, level: LEVEL_3, position: POSITION_PL_BLEND })
    AddWordCoding('maple syrup', { sound: TARGET_SOUND_L, level: LEVEL_3, position: POSITION_PL_BLEND, set: WORD_SET_CORE })
    AddWordCoding('multiply', { sound: TARGET_SOUND_L, level: LEVEL_3, position: POSITION_PL_BLEND })
    AddWordCoding('pineapple', { sound: TARGET_SOUND_L, level: LEVEL_3, position: POSITION_PL_BLEND, set: WORD_SET_CORE })
    AddWordCoding('planetarium', { sound: TARGET_SOUND_L, level: LEVEL_3, position: POSITION_PL_BLEND })
    AddWordCoding('plastic baggie', { sound: TARGET_SOUND_L, level: LEVEL_3, position: POSITION_PL_BLEND, set: WORD_SET_CORE })
    AddWordCoding('platypus', { sound: TARGET_SOUND_L, level: LEVEL_3, position: POSITION_PL_BLEND })
    AddWordCoding('playground', { sound: TARGET_SOUND_L, level: LEVEL_3, position: POSITION_PL_BLEND, set: WORD_SET_CORE })
    AddWordCoding('playing card', { sound: TARGET_SOUND_L, level: LEVEL_3, position: POSITION_PL_BLEND })
    AddWordCoding('pliers', { sound: TARGET_SOUND_L, level: LEVEL_3, position: POSITION_PL_BLEND, set: WORD_SET_CORE })
    AddWordCoding('plugged', { sound: TARGET_SOUND_L, level: LEVEL_3, position: POSITION_PL_BLEND })
    AddWordCoding('principal', { sound: TARGET_SOUND_L, level: LEVEL_3, position: POSITION_PL_BLEND, set: WORD_SET_CORE })
    AddWordCoding('purple', { sound: TARGET_SOUND_L, level: LEVEL_3, position: POSITION_PL_BLEND, set: WORD_SET_CORE })
    AddWordCoding('quintuplets', { sound: TARGET_SOUND_L, level: LEVEL_3, position: POSITION_PL_BLEND })
    AddWordCoding('replay', { sound: TARGET_SOUND_L, level: LEVEL_3, position: POSITION_PL_BLEND })
    AddWordCoding('snowplow', { sound: TARGET_SOUND_L, level: LEVEL_3, position: POSITION_PL_BLEND, set: WORD_SET_CORE })
    AddWordCoding('stapler', { sound: TARGET_SOUND_L, level: LEVEL_3, position: POSITION_PL_BLEND })
    AddWordCoding('stoplight', { sound: TARGET_SOUND_L, level: LEVEL_3, position: POSITION_PL_BLEND, set: WORD_SET_CORE })

    // Target sound L: SL blend
    AddWordCoding('slam', { sound: TARGET_SOUND_L, level: LEVEL_1, position: POSITION_SL_BLEND, set: WORD_SET_CORE })
    AddWordCoding('sled', { sound: TARGET_SOUND_L, level: LEVEL_1, position: POSITION_SL_BLEND, set: WORD_SET_CORE })
    AddWordCoding('sleep', { sound: TARGET_SOUND_L, level: LEVEL_1, position: POSITION_SL_BLEND, set: WORD_SET_CORE })
    AddWordCoding('sleigh', { sound: TARGET_SOUND_L, level: LEVEL_1, position: POSITION_SL_BLEND, set: WORD_SET_CORE })
    AddWordCoding('slick', { sound: TARGET_SOUND_L, level: LEVEL_1, position: POSITION_SL_BLEND })
    AddWordCoding('slide', { sound: TARGET_SOUND_L, level: LEVEL_1, position: POSITION_SL_BLEND, set: WORD_SET_CORE })
    AddWordCoding('slime', { sound: TARGET_SOUND_L, level: LEVEL_1, position: POSITION_SL_BLEND, set: WORD_SET_CORE })
    AddWordCoding('sling', { sound: TARGET_SOUND_L, level: LEVEL_1, position: POSITION_SL_BLEND, set: WORD_SET_CORE })
    AddWordCoding('slip', { sound: TARGET_SOUND_L, level: LEVEL_1, position: POSITION_SL_BLEND, set: WORD_SET_CORE })
    AddWordCoding('slob', { sound: TARGET_SOUND_L, level: LEVEL_1, position: POSITION_SL_BLEND })
    AddWordCoding('slope', { sound: TARGET_SOUND_L, level: LEVEL_1, position: POSITION_SL_BLEND })
    AddWordCoding('slow', { sound: TARGET_SOUND_L, level: LEVEL_1, position: POSITION_SL_BLEND, set: WORD_SET_CORE })
    AddWordCoding('slug', { sound: TARGET_SOUND_L, level: LEVEL_1, position: POSITION_SL_BLEND, set: WORD_SET_CORE })

    AddWordCoding('asleep', { sound: TARGET_SOUND_L, level: LEVEL_2, position: POSITION_SL_BLEND, set: WORD_SET_CORE })
    AddWordCoding('slant', { sound: TARGET_SOUND_L, level: LEVEL_2, position: POSITION_SL_BLEND })
    AddWordCoding('sledding', { sound: TARGET_SOUND_L, level: LEVEL_2, position: POSITION_SL_BLEND, set: WORD_SET_CORE })
    AddWordCoding('sleeping', { sound: TARGET_SOUND_L, level: LEVEL_2, position: POSITION_SL_BLEND, set: WORD_SET_CORE })
    AddWordCoding('sleeting', { sound: TARGET_SOUND_L, level: LEVEL_2, position: POSITION_SL_BLEND, set: WORD_SET_CORE })
    AddWordCoding('slender', { sound: TARGET_SOUND_L, level: LEVEL_2, position: POSITION_SL_BLEND })
    AddWordCoding('sliding', { sound: TARGET_SOUND_L, level: LEVEL_2, position: POSITION_SL_BLEND, set: WORD_SET_CORE })
    AddWordCoding('slightly', { sound: TARGET_SOUND_L, level: LEVEL_2, position: POSITION_SL_BLEND })
    AddWordCoding('slimy', { sound: TARGET_SOUND_L, level: LEVEL_2, position: POSITION_SL_BLEND, set: WORD_SET_CORE })
    AddWordCoding('slipper', { sound: TARGET_SOUND_L, level: LEVEL_2, position: POSITION_SL_BLEND, set: WORD_SET_CORE })
    AddWordCoding('slogan', { sound: TARGET_SOUND_L, level: LEVEL_2, position: POSITION_SL_BLEND, set: WORD_SET_CORE })
    AddWordCoding('sloppy', { sound: TARGET_SOUND_L, level: LEVEL_2, position: POSITION_SL_BLEND, set: WORD_SET_CORE })
    AddWordCoding('sled dog', { sound: TARGET_SOUND_L, level: LEVEL_2, position: POSITION_SL_BLEND, set: WORD_SET_CORE })

    AddWordCoding('bracelet', { sound: TARGET_SOUND_L, level: LEVEL_3, position: POSITION_SL_BLEND, set: WORD_SET_CORE })
    AddWordCoding('bristly', { sound: TARGET_SOUND_L, level: LEVEL_3, position: POSITION_SL_BLEND })
    AddWordCoding('sledgehammer', { sound: TARGET_SOUND_L, level: LEVEL_3, position: POSITION_SL_BLEND })
    AddWordCoding('sleeping bag', { sound: TARGET_SOUND_L, level: LEVEL_3, position: POSITION_SL_BLEND, set: WORD_SET_CORE })
    AddWordCoding('sleepwalker', { sound: TARGET_SOUND_L, level: LEVEL_3, position: POSITION_SL_BLEND })
    AddWordCoding('sleeves', { sound: TARGET_SOUND_L, level: LEVEL_3, position: POSITION_SL_BLEND, set: WORD_SET_CORE })
    AddWordCoding('sleuth', { sound: TARGET_SOUND_L, level: LEVEL_3, position: POSITION_SL_BLEND, set: WORD_SET_CORE })
    AddWordCoding('slice', { sound: TARGET_SOUND_L, level: LEVEL_3, position: POSITION_SL_BLEND, set: WORD_SET_CORE })
    AddWordCoding('slingshot', { sound: TARGET_SOUND_L, level: LEVEL_3, position: POSITION_SL_BLEND, set: WORD_SET_CORE })
    AddWordCoding('slippery', { sound: TARGET_SOUND_L, level: LEVEL_3, position: POSITION_SL_BLEND, set: WORD_SET_CORE })
    AddWordCoding('slither', { sound: TARGET_SOUND_L, level: LEVEL_3, position: POSITION_SL_BLEND, set: WORD_SET_CORE })
    AddWordCoding('sliver', { sound: TARGET_SOUND_L, level: LEVEL_3, position: POSITION_SL_BLEND })
    AddWordCoding('sloth', { sound: TARGET_SOUND_L, level: LEVEL_3, position: POSITION_SL_BLEND, set: WORD_SET_CORE })
    AddWordCoding('slow motion', { sound: TARGET_SOUND_L, level: LEVEL_3, position: POSITION_SL_BLEND })
    AddWordCoding('wrestler', { sound: TARGET_SOUND_L, level: LEVEL_3, position: POSITION_SL_BLEND, set: WORD_SET_CORE })

    // --------------------------------------------------------------------------------------------------
    //   Target sound R
    // --------------------------------------------------------------------------------------------------

    // Target sound R: Initial
    AddWordCoding('race', { sound: TARGET_SOUND_R, level: LEVEL_1, position: POSITION_INITIAL, set: WORD_SET_CORE })
    AddWordCoding('rag', { sound: TARGET_SOUND_R, level: LEVEL_1, position: POSITION_INITIAL, set: WORD_SET_CORE })
    AddWordCoding('rain', { sound: TARGET_SOUND_R, level: LEVEL_1, position: POSITION_INITIAL, set: WORD_SET_CORE })
    AddWordCoding('rake', { sound: TARGET_SOUND_R, level: LEVEL_1, position: POSITION_INITIAL, set: WORD_SET_CORE })
    AddWordCoding('ran', { sound: TARGET_SOUND_R, level: LEVEL_1, position: POSITION_INITIAL })
    AddWordCoding('rang', { sound: TARGET_SOUND_R, level: LEVEL_1, position: POSITION_INITIAL })
    AddWordCoding('rat', { sound: TARGET_SOUND_R, level: LEVEL_1, position: POSITION_INITIAL, set: WORD_SET_CORE })
    AddWordCoding('ray', { sound: TARGET_SOUND_R, level: LEVEL_1, position: POSITION_INITIAL, set: WORD_SET_CORE })
    AddWordCoding('reach', { sound: TARGET_SOUND_R, level: LEVEL_1, position: POSITION_INITIAL })
    AddWordCoding('read', { sound: TARGET_SOUND_R, level: LEVEL_1, position: POSITION_INITIAL, set: WORD_SET_CORE })
    AddWordCoding('red', { sound: TARGET_SOUND_R, level: LEVEL_1, position: POSITION_INITIAL, set: WORD_SET_CORE })
    AddWordCoding('Reese', { sound: TARGET_SOUND_R, level: LEVEL_1, position: POSITION_INITIAL, set: WORD_SET_CORE })
    AddWordCoding('rent', { sound: TARGET_SOUND_R, level: LEVEL_1, position: POSITION_INITIAL })
    AddWordCoding('rest', { sound: TARGET_SOUND_R, level: LEVEL_1, position: POSITION_INITIAL })
    AddWordCoding('rice', { sound: TARGET_SOUND_R, level: LEVEL_1, position: POSITION_INITIAL })
    AddWordCoding('ride', { sound: TARGET_SOUND_R, level: LEVEL_1, position: POSITION_INITIAL })
    AddWordCoding('right', { sound: TARGET_SOUND_R, level: LEVEL_1, position: POSITION_INITIAL })
    AddWordCoding('rind', { sound: TARGET_SOUND_R, level: LEVEL_1, position: POSITION_INITIAL })
    AddWordCoding('ring', { sound: TARGET_SOUND_R, level: LEVEL_1, position: POSITION_INITIAL })
    AddWordCoding('rinse', { sound: TARGET_SOUND_R, level: LEVEL_1, position: POSITION_INITIAL })
    AddWordCoding('rise', { sound: TARGET_SOUND_R, level: LEVEL_1, position: POSITION_INITIAL })
    AddWordCoding('risk', { sound: TARGET_SOUND_R, level: LEVEL_1, position: POSITION_INITIAL })
    AddWordCoding('rock', { sound: TARGET_SOUND_R, level: LEVEL_1, position: POSITION_INITIAL, set: WORD_SET_CORE })
    AddWordCoding('rod', { sound: TARGET_SOUND_R, level: LEVEL_1, position: POSITION_INITIAL })
    AddWordCoding('rug', { sound: TARGET_SOUND_R, level: LEVEL_1, position: POSITION_INITIAL, set: WORD_SET_CORE })
    AddWordCoding('run', { sound: TARGET_SOUND_R, level: LEVEL_1, position: POSITION_INITIAL, set: WORD_SET_CORE })
    AddWordCoding('rung', { sound: TARGET_SOUND_R, level: LEVEL_1, position: POSITION_INITIAL })
    AddWordCoding('rush', { sound: TARGET_SOUND_R, level: LEVEL_1, position: POSITION_INITIAL })
    AddWordCoding('wrist', { sound: TARGET_SOUND_R, level: LEVEL_1, position: POSITION_INITIAL })
    AddWordCoding('write', { sound: TARGET_SOUND_R, level: LEVEL_1, position: POSITION_INITIAL })

    AddWordCoding('ram', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_INITIAL })
    AddWordCoding('raft', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_INITIAL })
    AddWordCoding('rabbit', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_INITIAL, set: WORD_SET_CORE })
    AddWordCoding('racing', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_INITIAL })
    AddWordCoding('racquet', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_INITIAL })
    AddWordCoding('rainy', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_INITIAL })
    AddWordCoding('raisin', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_INITIAL })
    AddWordCoding('ranch', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_INITIAL })
    AddWordCoding('raven', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_INITIAL })
    AddWordCoding('reading', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_INITIAL, set: WORD_SET_CORE })
    AddWordCoding('ready', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_INITIAL })
    AddWordCoding('receipt', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_INITIAL })
    AddWordCoding('recess', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_INITIAL, set: WORD_SET_CORE })
    AddWordCoding('recipe', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_INITIAL })
    AddWordCoding('reef', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_INITIAL, set: WORD_SET_CORE })
    AddWordCoding('repeat', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_INITIAL })
    AddWordCoding('rescue', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_INITIAL })
    AddWordCoding('rhino', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_INITIAL })
    AddWordCoding('rhyme', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_INITIAL, set: WORD_SET_CORE })
    AddWordCoding('rib', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_INITIAL })
    AddWordCoding('ribbon', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_INITIAL })
    AddWordCoding('road', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_INITIAL, set: WORD_SET_CORE })
    AddWordCoding('robin', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_INITIAL })
    AddWordCoding('robot', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_INITIAL, set: WORD_SET_CORE })
    AddWordCoding('rocket', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_INITIAL, set: WORD_SET_CORE })
    AddWordCoding('rocky', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_INITIAL })
    AddWordCoding('rodeo', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_INITIAL })
    AddWordCoding('roof', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_INITIAL })
    AddWordCoding('room', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_INITIAL, set: WORD_SET_CORE })
    AddWordCoding('root', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_INITIAL })
    AddWordCoding('rope', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_INITIAL })
    AddWordCoding('rose', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_INITIAL })
    AddWordCoding('rough', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_INITIAL, set: WORD_SET_CORE })
    AddWordCoding('round', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_INITIAL, set: WORD_SET_CORE })
    AddWordCoding('row', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_INITIAL, set: WORD_SET_CORE })
    AddWordCoding('Ruby', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_INITIAL })
    AddWordCoding('rude', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_INITIAL })
    AddWordCoding('runway', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_INITIAL })
    AddWordCoding('wrench', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_INITIAL })
    AddWordCoding('wrong', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_INITIAL })

    AddWordCoding('raccoon', { sound: TARGET_SOUND_R, level: LEVEL_3, position: POSITION_INITIAL, set: WORD_SET_CORE })
    AddWordCoding('radishes', { sound: TARGET_SOUND_R, level: LEVEL_3, position: POSITION_INITIAL })
    AddWordCoding('rainbow', { sound: TARGET_SOUND_R, level: LEVEL_3, position: POSITION_INITIAL, set: WORD_SET_CORE })
    AddWordCoding('raincoat', { sound: TARGET_SOUND_R, level: LEVEL_3, position: POSITION_INITIAL, set: WORD_SET_CORE })
    AddWordCoding('rainfall', { sound: TARGET_SOUND_R, level: LEVEL_3, position: POSITION_INITIAL, set: WORD_SET_CORE })
    AddWordCoding('rattlesnake', { sound: TARGET_SOUND_R, level: LEVEL_3, position: POSITION_INITIAL, set: WORD_SET_CORE })
    AddWordCoding('really', { sound: TARGET_SOUND_R, level: LEVEL_3, position: POSITION_INITIAL })
    AddWordCoding('Rebecca', { sound: TARGET_SOUND_R, level: LEVEL_3, position: POSITION_INITIAL, set: WORD_SET_CORE })
    AddWordCoding('rectangle', { sound: TARGET_SOUND_R, level: LEVEL_3, position: POSITION_INITIAL, set: WORD_SET_CORE })
    AddWordCoding('recycle', { sound: TARGET_SOUND_R, level: LEVEL_3, position: POSITION_INITIAL, set: WORD_SET_CORE })
    AddWordCoding('reflection', { sound: TARGET_SOUND_R, level: LEVEL_3, position: POSITION_INITIAL })
    AddWordCoding('reflex', { sound: TARGET_SOUND_R, level: LEVEL_3, position: POSITION_INITIAL })
    AddWordCoding('relative', { sound: TARGET_SOUND_R, level: LEVEL_3, position: POSITION_INITIAL, set: WORD_SET_CORE })
    AddWordCoding('relaxing', { sound: TARGET_SOUND_R, level: LEVEL_3, position: POSITION_INITIAL })
    AddWordCoding('reptile', { sound: TARGET_SOUND_R, level: LEVEL_3, position: POSITION_INITIAL, set: WORD_SET_CORE })
    AddWordCoding('review', { sound: TARGET_SOUND_R, level: LEVEL_3, position: POSITION_INITIAL, set: WORD_SET_CORE })
    AddWordCoding('ridiculous', { sound: TARGET_SOUND_R, level: LEVEL_3, position: POSITION_INITIAL })
    AddWordCoding('right-handed', { sound: TARGET_SOUND_R, level: LEVEL_3, position: POSITION_INITIAL })
    AddWordCoding('rolling pin', { sound: TARGET_SOUND_R, level: LEVEL_3, position: POSITION_INITIAL })
    AddWordCoding('romantic', { sound: TARGET_SOUND_R, level: LEVEL_3, position: POSITION_INITIAL })
    AddWordCoding('rowboat', { sound: TARGET_SOUND_R, level: LEVEL_3, position: POSITION_INITIAL, set: WORD_SET_CORE })
    AddWordCoding('ringlet', { sound: TARGET_SOUND_R, level: LEVEL_3, position: POSITION_INITIAL })

    // Target sound R: Recurrent
    AddWordCoding('barber', { sound: TARGET_SOUND_R, level: LEVEL_1, position: POSITION_RECURRENT })
    AddWordCoding('brother', { sound: TARGET_SOUND_R, level: LEVEL_1, position: POSITION_RECURRENT, set: WORD_SET_CORE })
    AddWordCoding('cracker', { sound: TARGET_SOUND_R, level: LEVEL_1, position: POSITION_RECURRENT, set: WORD_SET_CORE })
    AddWordCoding('drawer', { sound: TARGET_SOUND_R, level: LEVEL_1, position: POSITION_RECURRENT, set: WORD_SET_CORE })
    AddWordCoding('dresser', { sound: TARGET_SOUND_R, level: LEVEL_1, position: POSITION_RECURRENT })
    AddWordCoding('driver', { sound: TARGET_SOUND_R, level: LEVEL_1, position: POSITION_RECURRENT, set: WORD_SET_CORE })
    AddWordCoding('drummer', { sound: TARGET_SOUND_R, level: LEVEL_1, position: POSITION_RECURRENT, set: WORD_SET_CORE })
    AddWordCoding('dryer', { sound: TARGET_SOUND_R, level: LEVEL_1, position: POSITION_RECURRENT })
    AddWordCoding('eraser', { sound: TARGET_SOUND_R, level: LEVEL_1, position: POSITION_RECURRENT, set: WORD_SET_CORE })
    AddWordCoding('freezer', { sound: TARGET_SOUND_R, level: LEVEL_1, position: POSITION_RECURRENT })
    AddWordCoding('freight train', { sound: TARGET_SOUND_R, level: LEVEL_1, position: POSITION_RECURRENT })
    AddWordCoding('harbor', { sound: TARGET_SOUND_R, level: LEVEL_1, position: POSITION_RECURRENT })
    AddWordCoding('marker', { sound: TARGET_SOUND_R, level: LEVEL_1, position: POSITION_RECURRENT, set: WORD_SET_CORE })
    AddWordCoding('mirror', { sound: TARGET_SOUND_R, level: LEVEL_1, position: POSITION_RECURRENT, set: WORD_SET_CORE })
    AddWordCoding('racer', { sound: TARGET_SOUND_R, level: LEVEL_1, position: POSITION_RECURRENT })
    AddWordCoding('radar', { sound: TARGET_SOUND_R, level: LEVEL_1, position: POSITION_RECURRENT })
    AddWordCoding('railroad track', { sound: TARGET_SOUND_R, level: LEVEL_1, position: POSITION_RECURRENT })
    AddWordCoding('river', { sound: TARGET_SOUND_R, level: LEVEL_1, position: POSITION_RECURRENT })
    AddWordCoding('rooster', { sound: TARGET_SOUND_R, level: LEVEL_1, position: POSITION_RECURRENT })
    AddWordCoding('runner', { sound: TARGET_SOUND_R, level: LEVEL_1, position: POSITION_RECURRENT })
    AddWordCoding('sprayer', { sound: TARGET_SOUND_R, level: LEVEL_1, position: POSITION_RECURRENT })
    AddWordCoding('sprinkler', { sound: TARGET_SOUND_R, level: LEVEL_1, position: POSITION_RECURRENT })
    AddWordCoding('stroller', { sound: TARGET_SOUND_R, level: LEVEL_1, position: POSITION_RECURRENT })
    AddWordCoding('surprise', { sound: TARGET_SOUND_R, level: LEVEL_1, position: POSITION_RECURRENT })
    AddWordCoding('tractor', { sound: TARGET_SOUND_R, level: LEVEL_1, position: POSITION_RECURRENT, set: WORD_SET_CORE })
    AddWordCoding('wrestler', { sound: TARGET_SOUND_R, level: LEVEL_1, position: POSITION_RECURRENT })
    AddWordCoding('writer', { sound: TARGET_SOUND_R, level: LEVEL_1, position: POSITION_RECURRENT, set: WORD_SET_CORE })

    AddWordCoding('airport', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_RECURRENT })
    AddWordCoding('barber shop', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_RECURRENT })
    AddWordCoding('barnyard', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_RECURRENT })
    AddWordCoding('corner', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_RECURRENT, set: WORD_SET_CORE })
    AddWordCoding('drug store', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_RECURRENT })
    AddWordCoding('ear of corn', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_RECURRENT })
    AddWordCoding('fireworks', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_RECURRENT })
    AddWordCoding('first grade', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_RECURRENT, set: WORD_SET_CORE })
    AddWordCoding('fracture', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_RECURRENT })
    AddWordCoding('fragrance', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_RECURRENT })
    AddWordCoding('grapefruit', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_RECURRENT })
    AddWordCoding('grasshopper', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_RECURRENT, set: WORD_SET_CORE })
    AddWordCoding('groceries', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_RECURRENT })
    AddWordCoding('hair dryer', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_RECURRENT, set: WORD_SET_CORE })
    AddWordCoding('North Star', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_RECURRENT })
    AddWordCoding('polar bear', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_RECURRENT, set: WORD_SET_CORE })
    AddWordCoding('proofread', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_RECURRENT })
    AddWordCoding('Puerto Rico', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_RECURRENT })
    AddWordCoding('race car', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_RECURRENT, set: WORD_SET_CORE })
    AddWordCoding('raindrops', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_RECURRENT })
    AddWordCoding('raspberry', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_RECURRENT })
    AddWordCoding('referee', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_RECURRENT })
    AddWordCoding('reflector', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_RECURRENT })
    AddWordCoding('refresh', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_RECURRENT })
    AddWordCoding('refrigerate', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_RECURRENT })
    AddWordCoding('regular', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_RECURRENT })
    AddWordCoding('reindeer', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_RECURRENT, set: WORD_SET_CORE })
    AddWordCoding('remote control', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_RECURRENT, set: WORD_SET_CORE })
    AddWordCoding('restroom', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_RECURRENT })
    AddWordCoding('ruler', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_RECURRENT, set: WORD_SET_CORE })
    AddWordCoding('scarecrow', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_RECURRENT })
    AddWordCoding('sports car', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_RECURRENT, set: WORD_SET_CORE })
    AddWordCoding('surfboard', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_RECURRENT })
    AddWordCoding('taxi driver', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_RECURRENT })
    AddWordCoding('trick or treat', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_RECURRENT })

    AddWordCoding('apple orchard', { sound: TARGET_SOUND_R, level: LEVEL_3, position: POSITION_RECURRENT })
    AddWordCoding('barbed wire', { sound: TARGET_SOUND_R, level: LEVEL_3, position: POSITION_RECURRENT })
    AddWordCoding('birthday party', { sound: TARGET_SOUND_R, level: LEVEL_3, position: POSITION_RECURRENT, set: WORD_SET_CORE })
    AddWordCoding('bumper car', { sound: TARGET_SOUND_R, level: LEVEL_3, position: POSITION_RECURRENT })
    AddWordCoding('buried treasure', { sound: TARGET_SOUND_R, level: LEVEL_3, position: POSITION_RECURRENT })
    AddWordCoding('crossword puzzle', { sound: TARGET_SOUND_R, level: LEVEL_3, position: POSITION_RECURRENT })
    AddWordCoding('fire fighter', { sound: TARGET_SOUND_R, level: LEVEL_3, position: POSITION_RECURRENT, set: WORD_SET_CORE })
    AddWordCoding('fire hydrant', { sound: TARGET_SOUND_R, level: LEVEL_3, position: POSITION_RECURRENT, set: WORD_SET_CORE })
    AddWordCoding('four leaf clover', { sound: TARGET_SOUND_R, level: LEVEL_3, position: POSITION_RECURRENT })
    AddWordCoding('fourth grade', { sound: TARGET_SOUND_R, level: LEVEL_3, position: POSITION_RECURRENT, set: WORD_SET_CORE })
    AddWordCoding('French fries', { sound: TARGET_SOUND_R, level: LEVEL_3, position: POSITION_RECURRENT, set: WORD_SET_CORE })
    AddWordCoding('grandfather', { sound: TARGET_SOUND_R, level: LEVEL_3, position: POSITION_RECURRENT, set: WORD_SET_CORE })
    AddWordCoding('grandmother', { sound: TARGET_SOUND_R, level: LEVEL_3, position: POSITION_RECURRENT, set: WORD_SET_CORE })
    AddWordCoding('grizzly bear', { sound: TARGET_SOUND_R, level: LEVEL_3, position: POSITION_RECURRENT })
    AddWordCoding('hamburgers', { sound: TARGET_SOUND_R, level: LEVEL_3, position: POSITION_RECURRENT })
    AddWordCoding('hardware store', { sound: TARGET_SOUND_R, level: LEVEL_3, position: POSITION_RECURRENT })
    AddWordCoding('North Carolina', { sound: TARGET_SOUND_R, level: LEVEL_3, position: POSITION_RECURRENT, set: WORD_SET_CORE })
    AddWordCoding('refrigerator', { sound: TARGET_SOUND_R, level: LEVEL_3, position: POSITION_RECURRENT, set: WORD_SET_CORE })
    AddWordCoding('restaurant', { sound: TARGET_SOUND_R, level: LEVEL_3, position: POSITION_RECURRENT })
    AddWordCoding('rhinoceros', { sound: TARGET_SOUND_R, level: LEVEL_3, position: POSITION_RECURRENT })
    AddWordCoding('roller coaster', { sound: TARGET_SOUND_R, level: LEVEL_3, position: POSITION_RECURRENT })
    AddWordCoding('Roman Empire', { sound: TARGET_SOUND_R, level: LEVEL_3, position: POSITION_RECURRENT })
    AddWordCoding('screwdriver', { sound: TARGET_SOUND_R, level: LEVEL_3, position: POSITION_RECURRENT })
    AddWordCoding('skyscraper', { sound: TARGET_SOUND_R, level: LEVEL_3, position: POSITION_RECURRENT })
    AddWordCoding('strawberry', { sound: TARGET_SOUND_R, level: LEVEL_3, position: POSITION_RECURRENT, set: WORD_SET_CORE })
    AddWordCoding('temperature', { sound: TARGET_SOUND_R, level: LEVEL_3, position: POSITION_RECURRENT })
    AddWordCoding('thermometer', { sound: TARGET_SOUND_R, level: LEVEL_3, position: POSITION_RECURRENT })
    AddWordCoding('underwater', { sound: TARGET_SOUND_R, level: LEVEL_3, position: POSITION_RECURRENT })
    AddWordCoding('veterinarian', { sound: TARGET_SOUND_R, level: LEVEL_3, position: POSITION_RECURRENT })
    AddWordCoding('water tower', { sound: TARGET_SOUND_R, level: LEVEL_3, position: POSITION_RECURRENT })

    // Target sound R: BR blend
    AddWordCoding('brace', { sound: TARGET_SOUND_R, level: LEVEL_1, position: POSITION_BR_BLEND })
    AddWordCoding('braces', { sound: TARGET_SOUND_R, level: LEVEL_1, position: POSITION_BR_BLEND, set: WORD_SET_CORE })
    AddWordCoding('braid', { sound: TARGET_SOUND_R, level: LEVEL_1, position: POSITION_BR_BLEND, set: WORD_SET_CORE })
    AddWordCoding('brain', { sound: TARGET_SOUND_R, level: LEVEL_1, position: POSITION_BR_BLEND, set: WORD_SET_CORE })
    AddWordCoding('branch', { sound: TARGET_SOUND_R, level: LEVEL_1, position: POSITION_BR_BLEND, set: WORD_SET_CORE })
    AddWordCoding('brass', { sound: TARGET_SOUND_R, level: LEVEL_1, position: POSITION_BR_BLEND })
    AddWordCoding('bread', { sound: TARGET_SOUND_R, level: LEVEL_1, position: POSITION_BR_BLEND, set: WORD_SET_CORE })
    AddWordCoding('break', { sound: TARGET_SOUND_R, level: LEVEL_1, position: POSITION_BR_BLEND, set: WORD_SET_CORE })
    AddWordCoding('breezy', { sound: TARGET_SOUND_R, level: LEVEL_1, position: POSITION_BR_BLEND })
    AddWordCoding('brick', { sound: TARGET_SOUND_R, level: LEVEL_1, position: POSITION_BR_BLEND, set: WORD_SET_CORE })
    AddWordCoding('bridge', { sound: TARGET_SOUND_R, level: LEVEL_1, position: POSITION_BR_BLEND, set: WORD_SET_CORE })
    AddWordCoding('bright', { sound: TARGET_SOUND_R, level: LEVEL_1, position: POSITION_BR_BLEND })
    AddWordCoding('bring', { sound: TARGET_SOUND_R, level: LEVEL_1, position: POSITION_BR_BLEND, set: WORD_SET_CORE })
    AddWordCoding('British', { sound: TARGET_SOUND_R, level: LEVEL_1, position: POSITION_BR_BLEND })
    AddWordCoding('bronze', { sound: TARGET_SOUND_R, level: LEVEL_1, position: POSITION_BR_BLEND })
    AddWordCoding('Brooks', { sound: TARGET_SOUND_R, level: LEVEL_1, position: POSITION_BR_BLEND })
    AddWordCoding('broth', { sound: TARGET_SOUND_R, level: LEVEL_1, position: POSITION_BR_BLEND })
    AddWordCoding('brush', { sound: TARGET_SOUND_R, level: LEVEL_1, position: POSITION_BR_BLEND, set: WORD_SET_CORE })

    AddWordCoding('bracelet', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_BR_BLEND, set: WORD_SET_CORE })
    AddWordCoding('brand-new', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_BR_BLEND })
    AddWordCoding('brave', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_BR_BLEND, set: WORD_SET_CORE })
    AddWordCoding('bravo', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_BR_BLEND })
    AddWordCoding('Brazil', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_BR_BLEND })
    AddWordCoding('breakdown', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_BR_BLEND })
    AddWordCoding('breakfast', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_BR_BLEND, set: WORD_SET_CORE })
    AddWordCoding('Broadway', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_BR_BLEND })
    AddWordCoding('broccoli', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_BR_BLEND, set: WORD_SET_CORE })
    AddWordCoding('broken', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_BR_BLEND, set: WORD_SET_CORE })
    AddWordCoding('broom', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_BR_BLEND, set: WORD_SET_CORE })
    AddWordCoding('brown', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_BR_BLEND, set: WORD_SET_CORE })
    AddWordCoding('brownie', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_BR_BLEND, variant: 1, set: WORD_SET_CORE })
    AddWordCoding('Brownie', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_BR_BLEND, variant: 2 })
    AddWordCoding('bruise', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_BR_BLEND })
    AddWordCoding('celebrate', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_BR_BLEND })
    AddWordCoding('cobra', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_BR_BLEND, set: WORD_SET_CORE })
    AddWordCoding('daybreak', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_BR_BLEND })
    AddWordCoding('eyebrow', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_BR_BLEND, set: WORD_SET_CORE })

    AddWordCoding('briefcase', { sound: TARGET_SOUND_R, level: LEVEL_3, position: POSITION_BR_BLEND })
    AddWordCoding('bristly', { sound: TARGET_SOUND_R, level: LEVEL_3, position: POSITION_BR_BLEND })
    AddWordCoding('celebration', { sound: TARGET_SOUND_R, level: LEVEL_3, position: POSITION_BR_BLEND, set: WORD_SET_CORE })
    AddWordCoding('Golden Gate Bridge', { sound: TARGET_SOUND_R, level: LEVEL_3, position: POSITION_BR_BLEND, set: WORD_SET_CORE })
    AddWordCoding('London Bridge', { sound: TARGET_SOUND_R, level: LEVEL_3, position: POSITION_BR_BLEND, set: WORD_SET_CORE })
    AddWordCoding('Nebraska', { sound: TARGET_SOUND_R, level: LEVEL_3, position: POSITION_BR_BLEND, set: WORD_SET_CORE })
    AddWordCoding('umbrella', { sound: TARGET_SOUND_R, level: LEVEL_3, position: POSITION_BR_BLEND, set: WORD_SET_CORE })
    AddWordCoding('whole wheat bread', { sound: TARGET_SOUND_R, level: LEVEL_3, position: POSITION_BR_BLEND, set: WORD_SET_CORE })
    AddWordCoding('zebra', { sound: TARGET_SOUND_R, level: LEVEL_3, position: POSITION_BR_BLEND, set: WORD_SET_CORE })
    AddWordCoding('toothbrush', { sound: TARGET_SOUND_R, level: LEVEL_3, position: POSITION_BR_BLEND, set: WORD_SET_CORE })
    AddWordCoding('broomstick', { sound: TARGET_SOUND_R, level: LEVEL_3, position: POSITION_BR_BLEND, set: WORD_SET_CORE })
    AddWordCoding('paintbrush', { sound: TARGET_SOUND_R, level: LEVEL_3, position: POSITION_BR_BLEND, set: WORD_SET_CORE })

    // Target sound R: DR blend
    AddWordCoding('drain', { sound: TARGET_SOUND_R, level: LEVEL_1, position: POSITION_DR_BLEND })
    AddWordCoding('drake', { sound: TARGET_SOUND_R, level: LEVEL_1, position: POSITION_DR_BLEND })
    AddWordCoding('drama', { sound: TARGET_SOUND_R, level: LEVEL_1, position: POSITION_DR_BLEND })
    AddWordCoding('drank', { sound: TARGET_SOUND_R, level: LEVEL_1, position: POSITION_DR_BLEND, set: WORD_SET_CORE })
    AddWordCoding('draw', { sound: TARGET_SOUND_R, level: LEVEL_1, position: POSITION_DR_BLEND, set: WORD_SET_CORE })
    AddWordCoding('dream', { sound: TARGET_SOUND_R, level: LEVEL_1, position: POSITION_DR_BLEND, set: WORD_SET_CORE })
    AddWordCoding('dress', { sound: TARGET_SOUND_R, level: LEVEL_1, position: POSITION_DR_BLEND, set: WORD_SET_CORE })
    AddWordCoding('drew', { sound: TARGET_SOUND_R, level: LEVEL_1, position: POSITION_DR_BLEND, variant: 1, set: WORD_SET_CORE })
    AddWordCoding('Drew', { sound: TARGET_SOUND_R, level: LEVEL_1, position: POSITION_DR_BLEND, variant: 2 })
    AddWordCoding('drill', { sound: TARGET_SOUND_R, level: LEVEL_1, position: POSITION_DR_BLEND, set: WORD_SET_CORE })
    AddWordCoding('drinks', { sound: TARGET_SOUND_R, level: LEVEL_1, position: POSITION_DR_BLEND, set: WORD_SET_CORE })
    AddWordCoding('drip', { sound: TARGET_SOUND_R, level: LEVEL_1, position: POSITION_DR_BLEND })
    AddWordCoding('drive', { sound: TARGET_SOUND_R, level: LEVEL_1, position: POSITION_DR_BLEND, set: WORD_SET_CORE })
    AddWordCoding('drop', { sound: TARGET_SOUND_R, level: LEVEL_1, position: POSITION_DR_BLEND, set: WORD_SET_CORE })
    AddWordCoding('drought', { sound: TARGET_SOUND_R, level: LEVEL_1, position: POSITION_DR_BLEND })
    AddWordCoding('drum', { sound: TARGET_SOUND_R, level: LEVEL_1, position: POSITION_DR_BLEND, set: WORD_SET_CORE })
    AddWordCoding('dry', { sound: TARGET_SOUND_R, level: LEVEL_1, position: POSITION_DR_BLEND })

    AddWordCoding('address', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_DR_BLEND })
    AddWordCoding('Andrew', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_DR_BLEND })
    AddWordCoding('Audrey', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_DR_BLEND })
    AddWordCoding('children', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_DR_BLEND, set: WORD_SET_CORE })
    AddWordCoding('dragon', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_DR_BLEND, set: WORD_SET_CORE })
    AddWordCoding('drawing', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_DR_BLEND, set: WORD_SET_CORE })
    AddWordCoding('dreamed', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_DR_BLEND, set: WORD_SET_CORE })
    AddWordCoding('dribbling', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_DR_BLEND, set: WORD_SET_CORE })
    AddWordCoding('drilled', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_DR_BLEND })
    AddWordCoding('dripped', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_DR_BLEND, set: WORD_SET_CORE })
    AddWordCoding('drizzle', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_DR_BLEND })
    AddWordCoding('dropped', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_DR_BLEND, set: WORD_SET_CORE })
    AddWordCoding('drowsy', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_DR_BLEND })
    AddWordCoding('hundred', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_DR_BLEND, set: WORD_SET_CORE })
    AddWordCoding('hydrant', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_DR_BLEND, set: WORD_SET_CORE })
    AddWordCoding('laundry', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_DR_BLEND, set: WORD_SET_CORE })
    AddWordCoding('Madrid', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_DR_BLEND })

    AddWordCoding('bedroom', { sound: TARGET_SOUND_R, level: LEVEL_3, position: POSITION_DR_BLEND, set: WORD_SET_CORE })
    AddWordCoding('cough drops', { sound: TARGET_SOUND_R, level: LEVEL_3, position: POSITION_DR_BLEND, set: WORD_SET_CORE })
    AddWordCoding('Dracula', { sound: TARGET_SOUND_R, level: LEVEL_3, position: POSITION_DR_BLEND, set: WORD_SET_CORE })
    AddWordCoding('dragonfly', { sound: TARGET_SOUND_R, level: LEVEL_3, position: POSITION_DR_BLEND, set: WORD_SET_CORE })
    AddWordCoding('driveway', { sound: TARGET_SOUND_R, level: LEVEL_3, position: POSITION_DR_BLEND, set: WORD_SET_CORE })
    AddWordCoding('drumstick', { sound: TARGET_SOUND_R, level: LEVEL_3, position: POSITION_DR_BLEND, set: WORD_SET_CORE })
    AddWordCoding('eye drops', { sound: TARGET_SOUND_R, level: LEVEL_3, position: POSITION_DR_BLEND })
    AddWordCoding('gumdrops', { sound: TARGET_SOUND_R, level: LEVEL_3, position: POSITION_DR_BLEND, set: WORD_SET_CORE })
    AddWordCoding('laundry basket', { sound: TARGET_SOUND_R, level: LEVEL_3, position: POSITION_DR_BLEND, set: WORD_SET_CORE })
    AddWordCoding('pleasant dreams', { sound: TARGET_SOUND_R, level: LEVEL_3, position: POSITION_DR_BLEND })
    AddWordCoding('drool', { sound: TARGET_SOUND_R, level: LEVEL_3, position: POSITION_DR_BLEND, set: WORD_SET_CORE })
    AddWordCoding('salad dressing', { sound: TARGET_SOUND_R, level: LEVEL_3, position: POSITION_DR_BLEND, set: WORD_SET_CORE })

    // Target sound R: FR blend
    AddWordCoding('afraid', { sound: TARGET_SOUND_R, level: LEVEL_1, position: POSITION_FR_BLEND, set: WORD_SET_CORE })
    AddWordCoding('fraction', { sound: TARGET_SOUND_R, level: LEVEL_1, position: POSITION_FR_BLEND })
    AddWordCoding('frame', { sound: TARGET_SOUND_R, level: LEVEL_1, position: POSITION_FR_BLEND })
    AddWordCoding('France', { sound: TARGET_SOUND_R, level: LEVEL_1, position: POSITION_FR_BLEND, set: WORD_SET_CORE })
    AddWordCoding('Frank', { sound: TARGET_SOUND_R, level: LEVEL_1, position: POSITION_FR_BLEND })
    AddWordCoding('freaky', { sound: TARGET_SOUND_R, level: LEVEL_1, position: POSITION_FR_BLEND, set: WORD_SET_CORE })
    AddWordCoding('free', { sound: TARGET_SOUND_R, level: LEVEL_1, position: POSITION_FR_BLEND })
    AddWordCoding('fresh', { sound: TARGET_SOUND_R, level: LEVEL_1, position: POSITION_FR_BLEND })
    AddWordCoding('Friday', { sound: TARGET_SOUND_R, level: LEVEL_1, position: POSITION_FR_BLEND, set: WORD_SET_CORE })
    AddWordCoding('fridge', { sound: TARGET_SOUND_R, level: LEVEL_1, position: POSITION_FR_BLEND, set: WORD_SET_CORE })
    AddWordCoding('friend', { sound: TARGET_SOUND_R, level: LEVEL_1, position: POSITION_FR_BLEND, set: WORD_SET_CORE })
    AddWordCoding('fries', { sound: TARGET_SOUND_R, level: LEVEL_1, position: POSITION_FR_BLEND, set: WORD_SET_CORE })
    AddWordCoding('fright', { sound: TARGET_SOUND_R, level: LEVEL_1, position: POSITION_FR_BLEND })
    AddWordCoding('frog', { sound: TARGET_SOUND_R, level: LEVEL_1, position: POSITION_FR_BLEND, set: WORD_SET_CORE })
    AddWordCoding('from', { sound: TARGET_SOUND_R, level: LEVEL_1, position: POSITION_FR_BLEND, set: WORD_SET_CORE })
    AddWordCoding('front', { sound: TARGET_SOUND_R, level: LEVEL_1, position: POSITION_FR_BLEND })
    AddWordCoding('frost', { sound: TARGET_SOUND_R, level: LEVEL_1, position: POSITION_FR_BLEND })
    AddWordCoding('freeze', { sound: TARGET_SOUND_R, level: LEVEL_1, position: POSITION_FR_BLEND })
    AddWordCoding('fruit', { sound: TARGET_SOUND_R, level: LEVEL_1, position: POSITION_FR_BLEND, set: WORD_SET_CORE })
    AddWordCoding('fry', { sound: TARGET_SOUND_R, level: LEVEL_1, position: POSITION_FR_BLEND })

    AddWordCoding('Africa', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_FR_BLEND, set: WORD_SET_CORE })
    AddWordCoding('fragile', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_FR_BLEND, set: WORD_SET_CORE })
    AddWordCoding('frail', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_FR_BLEND })
    AddWordCoding('freckle', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_FR_BLEND, set: WORD_SET_CORE })
    AddWordCoding('freckled', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_FR_BLEND, set: WORD_SET_CORE })
    AddWordCoding('frequent', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_FR_BLEND })
    AddWordCoding('friendly', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_FR_BLEND, set: WORD_SET_CORE })
    AddWordCoding('frighten', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_FR_BLEND, set: WORD_SET_CORE })
    AddWordCoding('frisbee', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_FR_BLEND, set: WORD_SET_CORE })
    AddWordCoding('frosting', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_FR_BLEND, set: WORD_SET_CORE })
    AddWordCoding('frown', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_FR_BLEND, set: WORD_SET_CORE })
    AddWordCoding('frozen', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_FR_BLEND, set: WORD_SET_CORE })
    AddWordCoding('fruity', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_FR_BLEND })

    AddWordCoding('bedframe', { sound: TARGET_SOUND_R, level: LEVEL_3, position: POSITION_FR_BLEND })
    AddWordCoding('bullfrog', { sound: TARGET_SOUND_R, level: LEVEL_3, position: POSITION_FR_BLEND, set: WORD_SET_CORE })
    AddWordCoding('fettucini alfredo', { sound: TARGET_SOUND_R, level: LEVEL_3, position: POSITION_FR_BLEND })
    AddWordCoding('French bulldog', { sound: TARGET_SOUND_R, level: LEVEL_3, position: POSITION_FR_BLEND, set: WORD_SET_CORE })
    AddWordCoding('free wifi', { sound: TARGET_SOUND_R, level: LEVEL_3, position: POSITION_FR_BLEND })
    AddWordCoding('frightening', { sound: TARGET_SOUND_R, level: LEVEL_3, position: POSITION_FR_BLEND, set: WORD_SET_CORE })
    AddWordCoding('fruit salad', { sound: TARGET_SOUND_R, level: LEVEL_3, position: POSITION_FR_BLEND, set: WORD_SET_CORE })
    AddWordCoding('frying pan', { sound: TARGET_SOUND_R, level: LEVEL_3, position: POSITION_FR_BLEND, set: WORD_SET_CORE })
    AddWordCoding('kiwi fruit', { sound: TARGET_SOUND_R, level: LEVEL_3, position: POSITION_FR_BLEND, set: WORD_SET_CORE })
    AddWordCoding('lakefront', { sound: TARGET_SOUND_R, level: LEVEL_3, position: POSITION_FR_BLEND, set: WORD_SET_CORE })
    AddWordCoding('oceanfront', { sound: TARGET_SOUND_R, level: LEVEL_3, position: POSITION_FR_BLEND, set: WORD_SET_CORE })
    AddWordCoding('San Francisco', { sound: TARGET_SOUND_R, level: LEVEL_3, position: POSITION_FR_BLEND, set: WORD_SET_CORE })
    AddWordCoding('ugli fruit', { sound: TARGET_SOUND_R, level: LEVEL_3, position: POSITION_FR_BLEND, set: WORD_SET_CORE })
    AddWordCoding('unafraid', { sound: TARGET_SOUND_R, level: LEVEL_3, position: POSITION_FR_BLEND })
    AddWordCoding('unfriendly', { sound: TARGET_SOUND_R, level: LEVEL_3, position: POSITION_FR_BLEND })

    // Target sound R: GR blend
    AddWordCoding('agree', { sound: TARGET_SOUND_R, level: LEVEL_1, position: POSITION_GR_BLEND, set: WORD_SET_CORE })
    AddWordCoding('grade', { sound: TARGET_SOUND_R, level: LEVEL_1, position: POSITION_GR_BLEND, set: WORD_SET_CORE })
    AddWordCoding('grape', { sound: TARGET_SOUND_R, level: LEVEL_1, position: POSITION_GR_BLEND, set: WORD_SET_CORE })
    AddWordCoding('grass', { sound: TARGET_SOUND_R, level: LEVEL_1, position: POSITION_GR_BLEND, set: WORD_SET_CORE })
    AddWordCoding('gray', { sound: TARGET_SOUND_R, level: LEVEL_1, position: POSITION_GR_BLEND, set: WORD_SET_CORE })
    AddWordCoding('great', { sound: TARGET_SOUND_R, level: LEVEL_1, position: POSITION_GR_BLEND })
    AddWordCoding('green', { sound: TARGET_SOUND_R, level: LEVEL_1, position: POSITION_GR_BLEND, set: WORD_SET_CORE })
    AddWordCoding('greet', { sound: TARGET_SOUND_R, level: LEVEL_1, position: POSITION_GR_BLEND })
    AddWordCoding('grid', { sound: TARGET_SOUND_R, level: LEVEL_1, position: POSITION_GR_BLEND })
    AddWordCoding('grin', { sound: TARGET_SOUND_R, level: LEVEL_1, position: POSITION_GR_BLEND, set: WORD_SET_CORE })
    AddWordCoding('gross', { sound: TARGET_SOUND_R, level: LEVEL_1, position: POSITION_GR_BLEND, set: WORD_SET_CORE })
    AddWordCoding('degree', { sound: TARGET_SOUND_R, level: LEVEL_1, position: POSITION_GR_BLEND })
    AddWordCoding('degrees', { sound: TARGET_SOUND_R, level: LEVEL_1, position: POSITION_GR_BLEND })
    AddWordCoding('grassy', { sound: TARGET_SOUND_R, level: LEVEL_1, position: POSITION_GR_BLEND })
    AddWordCoding('greasy', { sound: TARGET_SOUND_R, level: LEVEL_1, position: POSITION_GR_BLEND })
    AddWordCoding('Greece', { sound: TARGET_SOUND_R, level: LEVEL_1, position: POSITION_GR_BLEND })
    AddWordCoding('grew', { sound: TARGET_SOUND_R, level: LEVEL_1, position: POSITION_GR_BLEND, set: WORD_SET_CORE })
    AddWordCoding('hungry', { sound: TARGET_SOUND_R, level: LEVEL_1, position: POSITION_GR_BLEND, set: WORD_SET_CORE })

    AddWordCoding('angry', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_GR_BLEND, set: WORD_SET_CORE })
    AddWordCoding('diagram', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_GR_BLEND })
    AddWordCoding('disagree', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_GR_BLEND })
    AddWordCoding('graduate', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_GR_BLEND, set: WORD_SET_CORE })
    AddWordCoding('granddad', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_GR_BLEND })
    AddWordCoding('grandma', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_GR_BLEND, set: WORD_SET_CORE })
    AddWordCoding('grandpa', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_GR_BLEND, set: WORD_SET_CORE })
    AddWordCoding('grapes', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_GR_BLEND, set: WORD_SET_CORE })
    AddWordCoding('Great Dane', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_GR_BLEND, set: WORD_SET_CORE })
    AddWordCoding('greetings', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_GR_BLEND })
    AddWordCoding('groovy', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_GR_BLEND })
    AddWordCoding('grouchy', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_GR_BLEND })
    AddWordCoding('growing', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_GR_BLEND, set: WORD_SET_CORE })
    AddWordCoding('grumpy', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_GR_BLEND, set: WORD_SET_CORE })
    AddWordCoding('kilogram', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_GR_BLEND })
    AddWordCoding('photograph', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_GR_BLEND })
    AddWordCoding('grill', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_GR_BLEND, set: WORD_SET_CORE })
    AddWordCoding('growl', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_GR_BLEND, set: WORD_SET_CORE })

    AddWordCoding('autograph', { sound: TARGET_SOUND_R, level: LEVEL_3, position: POSITION_GR_BLEND, set: WORD_SET_CORE })
    AddWordCoding('biography', { sound: TARGET_SOUND_R, level: LEVEL_3, position: POSITION_GR_BLEND, set: WORD_SET_CORE })
    AddWordCoding('blue-green', { sound: TARGET_SOUND_R, level: LEVEL_3, position: POSITION_GR_BLEND, set: WORD_SET_CORE })
    AddWordCoding('bluegrass', { sound: TARGET_SOUND_R, level: LEVEL_3, position: POSITION_GR_BLEND })
    AddWordCoding('congratulate', { sound: TARGET_SOUND_R, level: LEVEL_3, position: POSITION_GR_BLEND, set: WORD_SET_CORE })
    AddWordCoding('geography', { sound: TARGET_SOUND_R, level: LEVEL_3, position: POSITION_GR_BLEND, set: WORD_SET_CORE })
    AddWordCoding('Grand Canyon', { sound: TARGET_SOUND_R, level: LEVEL_3, position: POSITION_GR_BLEND, set: WORD_SET_CORE })
    AddWordCoding('grass snake', { sound: TARGET_SOUND_R, level: LEVEL_3, position: POSITION_GR_BLEND, set: WORD_SET_CORE })
    AddWordCoding('greenhouse', { sound: TARGET_SOUND_R, level: LEVEL_3, position: POSITION_GR_BLEND, set: WORD_SET_CORE })
    AddWordCoding('greyhound', { sound: TARGET_SOUND_R, level: LEVEL_3, position: POSITION_GR_BLEND, set: WORD_SET_CORE })
    AddWordCoding('ingredients', { sound: TARGET_SOUND_R, level: LEVEL_3, position: POSITION_GR_BLEND, set: WORD_SET_CORE })

    // Target sound R: KR blend
    AddWordCoding('crab', { sound: TARGET_SOUND_R, level: LEVEL_1, position: POSITION_KR_BLEND, set: WORD_SET_CORE })
    AddWordCoding('crack', { sound: TARGET_SOUND_R, level: LEVEL_1, position: POSITION_KR_BLEND, set: WORD_SET_CORE })
    AddWordCoding('crane', { sound: TARGET_SOUND_R, level: LEVEL_1, position: POSITION_KR_BLEND, variant: 1 })  // bird
    AddWordCoding('crane', { sound: TARGET_SOUND_R, level: LEVEL_1, position: POSITION_KR_BLEND, variant: 2, set: WORD_SET_CORE })  // construction
    AddWordCoding('crank', { sound: TARGET_SOUND_R, level: LEVEL_1, position: POSITION_KR_BLEND })
    AddWordCoding('crash', { sound: TARGET_SOUND_R, level: LEVEL_1, position: POSITION_KR_BLEND, set: WORD_SET_CORE })
    AddWordCoding('crate', { sound: TARGET_SOUND_R, level: LEVEL_1, position: POSITION_KR_BLEND })
    AddWordCoding('crayon', { sound: TARGET_SOUND_R, level: LEVEL_1, position: POSITION_KR_BLEND, set: WORD_SET_CORE })
    AddWordCoding('creek', { sound: TARGET_SOUND_R, level: LEVEL_1, position: POSITION_KR_BLEND, set: WORD_SET_CORE })
    AddWordCoding('crib', { sound: TARGET_SOUND_R, level: LEVEL_1, position: POSITION_KR_BLEND })
    AddWordCoding('cricket', { sound: TARGET_SOUND_R, level: LEVEL_1, position: POSITION_KR_BLEND, set: WORD_SET_CORE })
    AddWordCoding('crops', { sound: TARGET_SOUND_R, level: LEVEL_1, position: POSITION_KR_BLEND })
    AddWordCoding('cross', { sound: TARGET_SOUND_R, level: LEVEL_1, position: POSITION_KR_BLEND })
    AddWordCoding('crust', { sound: TARGET_SOUND_R, level: LEVEL_1, position: POSITION_KR_BLEND, set: WORD_SET_CORE })
    AddWordCoding('cry', { sound: TARGET_SOUND_R, level: LEVEL_1, position: POSITION_KR_BLEND, set: WORD_SET_CORE })
    AddWordCoding('secret', { sound: TARGET_SOUND_R, level: LEVEL_1, position: POSITION_KR_BLEND, set: WORD_SET_CORE })

    AddWordCoding('across', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_KR_BLEND, set: WORD_SET_CORE })
    AddWordCoding('crabby', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_KR_BLEND })
    AddWordCoding('cradle', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_KR_BLEND })
    AddWordCoding('craft', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_KR_BLEND, set: WORD_SET_CORE })
    AddWordCoding('crawl', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_KR_BLEND, set: WORD_SET_CORE })
    AddWordCoding('cream', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_KR_BLEND })
    AddWordCoding('creepy', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_KR_BLEND, set: WORD_SET_CORE })
    AddWordCoding('crescent', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_KR_BLEND })
    AddWordCoding('crispy', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_KR_BLEND, set: WORD_SET_CORE })
    AddWordCoding('croak', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_KR_BLEND })
    AddWordCoding('crocus', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_KR_BLEND })
    AddWordCoding('crossing', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_KR_BLEND, set: WORD_SET_CORE })
    AddWordCoding('crouton', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_KR_BLEND })
    AddWordCoding('crow', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_KR_BLEND, set: WORD_SET_CORE })
    AddWordCoding('crown', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_KR_BLEND, set: WORD_SET_CORE })
    AddWordCoding('crumbs', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_KR_BLEND })
    AddWordCoding('crunchy', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_KR_BLEND })
    AddWordCoding('crying', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_KR_BLEND, set: WORD_SET_CORE })
    AddWordCoding('crystal', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_KR_BLEND })
    AddWordCoding('decrease', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_KR_BLEND })
    AddWordCoding('increase', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_KR_BLEND })
    AddWordCoding('lacrosse', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_KR_BLEND, set: WORD_SET_CORE })
    AddWordCoding('okra', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_KR_BLEND })

    AddWordCoding('acrobat', { sound: TARGET_SOUND_R, level: LEVEL_3, position: POSITION_KR_BLEND })
    AddWordCoding('cockroach', { sound: TARGET_SOUND_R, level: LEVEL_3, position: POSITION_KR_BLEND, set: WORD_SET_CORE })
    AddWordCoding('crackling', { sound: TARGET_SOUND_R, level: LEVEL_3, position: POSITION_KR_BLEND })
    AddWordCoding('crayfish', { sound: TARGET_SOUND_R, level: LEVEL_3, position: POSITION_KR_BLEND })
    AddWordCoding('cream cheese', { sound: TARGET_SOUND_R, level: LEVEL_3, position: POSITION_KR_BLEND, set: WORD_SET_CORE })
    AddWordCoding('crocodile', { sound: TARGET_SOUND_R, level: LEVEL_3, position: POSITION_KR_BLEND, set: WORD_SET_CORE })
    AddWordCoding('crosswalk', { sound: TARGET_SOUND_R, level: LEVEL_3, position: POSITION_KR_BLEND, set: WORD_SET_CORE })
    AddWordCoding('crumbling', { sound: TARGET_SOUND_R, level: LEVEL_3, position: POSITION_KR_BLEND })
    AddWordCoding('ice cream', { sound: TARGET_SOUND_R, level: LEVEL_3, position: POSITION_KR_BLEND, set: WORD_SET_CORE })
    AddWordCoding('incredible', { sound: TARGET_SOUND_R, level: LEVEL_3, position: POSITION_KR_BLEND })
    AddWordCoding('jackrabbit', { sound: TARGET_SOUND_R, level: LEVEL_3, position: POSITION_KR_BLEND, set: WORD_SET_CORE })
    AddWordCoding('microphone', { sound: TARGET_SOUND_R, level: LEVEL_3, position: POSITION_KR_BLEND, set: WORD_SET_CORE })
    AddWordCoding('microscope', { sound: TARGET_SOUND_R, level: LEVEL_3, position: POSITION_KR_BLEND, set: WORD_SET_CORE })
    AddWordCoding('microwave', { sound: TARGET_SOUND_R, level: LEVEL_3, position: POSITION_KR_BLEND, set: WORD_SET_CORE })
    AddWordCoding('moose crossing', { sound: TARGET_SOUND_R, level: LEVEL_3, position: POSITION_KR_BLEND })
    AddWordCoding('muskrat', { sound: TARGET_SOUND_R, level: LEVEL_3, position: POSITION_KR_BLEND })
    AddWordCoding('spacecraft', { sound: TARGET_SOUND_R, level: LEVEL_3, position: POSITION_KR_BLEND })
    AddWordCoding('whipped cream', { sound: TARGET_SOUND_R, level: LEVEL_3, position: POSITION_KR_BLEND, set: WORD_SET_CORE })

    // Target sound R: PR blend
    AddWordCoding('apron', { sound: TARGET_SOUND_R, level: LEVEL_1, position: POSITION_PR_BLEND, set: WORD_SET_CORE })
    AddWordCoding('praise', { sound: TARGET_SOUND_R, level: LEVEL_1, position: POSITION_PR_BLEND, set: WORD_SET_CORE })
    AddWordCoding('prank', { sound: TARGET_SOUND_R, level: LEVEL_1, position: POSITION_PR_BLEND, set: WORD_SET_CORE })
    AddWordCoding('preheat', { sound: TARGET_SOUND_R, level: LEVEL_1, position: POSITION_PR_BLEND, set: WORD_SET_CORE })
    AddWordCoding('press', { sound: TARGET_SOUND_R, level: LEVEL_1, position: POSITION_PR_BLEND, set: WORD_SET_CORE })
    AddWordCoding('price', { sound: TARGET_SOUND_R, level: LEVEL_1, position: POSITION_PR_BLEND, set: WORD_SET_CORE })
    AddWordCoding('pry', { sound: TARGET_SOUND_R, level: LEVEL_1, position: POSITION_PR_BLEND, set: WORD_SET_CORE })
    AddWordCoding('prince', { sound: TARGET_SOUND_R, level: LEVEL_1, position: POSITION_PR_BLEND, set: WORD_SET_CORE })
    AddWordCoding('print', { sound: TARGET_SOUND_R, level: LEVEL_1, position: POSITION_PR_BLEND, set: WORD_SET_CORE })
    AddWordCoding('prize', { sound: TARGET_SOUND_R, level: LEVEL_1, position: POSITION_PR_BLEND, set: WORD_SET_CORE })

    AddWordCoding('approve', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_PR_BLEND })
    AddWordCoding('April', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_PR_BLEND, set: WORD_SET_CORE })
    AddWordCoding('cypress', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_PR_BLEND })
    AddWordCoding('Cyprus', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_PR_BLEND })
    AddWordCoding('improve', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_PR_BLEND, set: WORD_SET_CORE })
    AddWordCoding('osprey', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_PR_BLEND })
    AddWordCoding('paprika', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_PR_BLEND })
    AddWordCoding('practice', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_PR_BLEND, set: WORD_SET_CORE })
    AddWordCoding('precious', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_PR_BLEND, set: WORD_SET_CORE })
    AddWordCoding('present', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_PR_BLEND, set: WORD_SET_CORE })
    AddWordCoding('pretend', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_PR_BLEND, set: WORD_SET_CORE })
    AddWordCoding('pretty', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_PR_BLEND, set: WORD_SET_CORE })
    AddWordCoding('pretzel', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_PR_BLEND, set: WORD_SET_CORE })
    AddWordCoding('prevent', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_PR_BLEND })
    AddWordCoding('prickly', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_PR_BLEND, set: WORD_SET_CORE })
    AddWordCoding('primate', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_PR_BLEND })
    AddWordCoding('princess', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_PR_BLEND, set: WORD_SET_CORE })

    AddWordCoding('apricot', { sound: TARGET_SOUND_R, level: LEVEL_3, position: POSITION_PR_BLEND, set: WORD_SET_CORE })
    AddWordCoding('blueprint', { sound: TARGET_SOUND_R, level: LEVEL_3, position: POSITION_PR_BLEND, set: WORD_SET_CORE })
    AddWordCoding('footprints', { sound: TARGET_SOUND_R, level: LEVEL_3, position: POSITION_PR_BLEND, set: WORD_SET_CORE })
    AddWordCoding('praying mantis', { sound: TARGET_SOUND_R, level: LEVEL_3, position: POSITION_PR_BLEND, set: WORD_SET_CORE })
    AddWordCoding('preschool', { sound: TARGET_SOUND_R, level: LEVEL_3, position: POSITION_PR_BLEND, set: WORD_SET_CORE })
    AddWordCoding('principal', { sound: TARGET_SOUND_R, level: LEVEL_3, position: POSITION_PR_BLEND, set: WORD_SET_CORE })
    AddWordCoding('printer', { sound: TARGET_SOUND_R, level: LEVEL_3, position: POSITION_PR_BLEND, set: WORD_SET_CORE })
    AddWordCoding('probably', { sound: TARGET_SOUND_R, level: LEVEL_3, position: POSITION_PR_BLEND, set: WORD_SET_CORE })
    AddWordCoding('shipwreck', { sound: TARGET_SOUND_R, level: LEVEL_3, position: POSITION_PR_BLEND, set: WORD_SET_CORE })

    // Target sound R: TR blend
    AddWordCoding('track', { sound: TARGET_SOUND_R, level: LEVEL_1, position: POSITION_TR_BLEND })
    AddWordCoding('trade', { sound: TARGET_SOUND_R, level: LEVEL_1, position: POSITION_TR_BLEND })
    AddWordCoding('train', { sound: TARGET_SOUND_R, level: LEVEL_1, position: POSITION_TR_BLEND, set: WORD_SET_CORE })
    AddWordCoding('trash', { sound: TARGET_SOUND_R, level: LEVEL_1, position: POSITION_TR_BLEND, set: WORD_SET_CORE })
    AddWordCoding('tray', { sound: TARGET_SOUND_R, level: LEVEL_1, position: POSITION_TR_BLEND, set: WORD_SET_CORE })
    AddWordCoding('treat', { sound: TARGET_SOUND_R, level: LEVEL_1, position: POSITION_TR_BLEND, set: WORD_SET_CORE })
    AddWordCoding('tree', { sound: TARGET_SOUND_R, level: LEVEL_1, position: POSITION_TR_BLEND, set: WORD_SET_CORE })
    AddWordCoding('trick', { sound: TARGET_SOUND_R, level: LEVEL_1, position: POSITION_TR_BLEND })
    AddWordCoding('trip', { sound: TARGET_SOUND_R, level: LEVEL_1, position: POSITION_TR_BLEND, set: WORD_SET_CORE })
    AddWordCoding('truck', { sound: TARGET_SOUND_R, level: LEVEL_1, position: POSITION_TR_BLEND, set: WORD_SET_CORE })
    AddWordCoding('try', { sound: TARGET_SOUND_R, level: LEVEL_1, position: POSITION_TR_BLEND })
    AddWordCoding('trunk', { sound: TARGET_SOUND_R, level: LEVEL_1, position: POSITION_TR_BLEND, variant: 1, set: WORD_SET_CORE })
    AddWordCoding('trunk', { sound: TARGET_SOUND_R, level: LEVEL_1, position: POSITION_TR_BLEND, variant: 2, set: WORD_SET_CORE })
    AddWordCoding('trees', { sound: TARGET_SOUND_R, level: LEVEL_1, position: POSITION_TR_BLEND, set: WORD_SET_CORE })
    AddWordCoding('country', { sound: TARGET_SOUND_R, level: LEVEL_1, position: POSITION_TR_BLEND })

    AddWordCoding('control', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_TR_BLEND })
    AddWordCoding('Detroit', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_TR_BLEND })
    AddWordCoding('entrance', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_TR_BLEND })
    AddWordCoding('mattress', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_TR_BLEND, set: WORD_SET_CORE })
    AddWordCoding('subtract', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_TR_BLEND, set: WORD_SET_CORE })
    AddWordCoding('traffic', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_TR_BLEND, set: WORD_SET_CORE })
    AddWordCoding('trail', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_TR_BLEND, set: WORD_SET_CORE })
    AddWordCoding('trapeze', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_TR_BLEND, set: WORD_SET_CORE })
    AddWordCoding('travel', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_TR_BLEND, set: WORD_SET_CORE })
    AddWordCoding('triple', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_TR_BLEND, set: WORD_SET_CORE })
    AddWordCoding('troll', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_TR_BLEND, set: WORD_SET_CORE })
    AddWordCoding('trophy', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_TR_BLEND, set: WORD_SET_CORE })
    AddWordCoding('trouble', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_TR_BLEND })
    AddWordCoding('trumpet', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_TR_BLEND, set: WORD_SET_CORE })
    AddWordCoding('truth', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_TR_BLEND })
    AddWordCoding('wintry', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_TR_BLEND })

    AddWordCoding('countryside', { sound: TARGET_SOUND_R, level: LEVEL_3, position: POSITION_TR_BLEND, set: WORD_SET_CORE })
    AddWordCoding('electrician', { sound: TARGET_SOUND_R, level: LEVEL_3, position: POSITION_TR_BLEND })
    AddWordCoding('electricity', { sound: TARGET_SOUND_R, level: LEVEL_3, position: POSITION_TR_BLEND, set: WORD_SET_CORE })
    AddWordCoding('trampoline', { sound: TARGET_SOUND_R, level: LEVEL_3, position: POSITION_TR_BLEND, set: WORD_SET_CORE })
    AddWordCoding('trash can', { sound: TARGET_SOUND_R, level: LEVEL_3, position: POSITION_TR_BLEND, set: WORD_SET_CORE })
    AddWordCoding('tree house', { sound: TARGET_SOUND_R, level: LEVEL_3, position: POSITION_TR_BLEND, set: WORD_SET_CORE })
    AddWordCoding('truckload', { sound: TARGET_SOUND_R, level: LEVEL_3, position: POSITION_TR_BLEND, set: WORD_SET_CORE })
    AddWordCoding('triangle', { sound: TARGET_SOUND_R, level: LEVEL_3, position: POSITION_TR_BLEND, variant: 1, set: WORD_SET_CORE })
    AddWordCoding('triangle', { sound: TARGET_SOUND_R, level: LEVEL_3, position: POSITION_TR_BLEND, variant: 2, set: WORD_SET_CORE })
    AddWordCoding('tricycle', { sound: TARGET_SOUND_R, level: LEVEL_3, position: POSITION_TR_BLEND, set: WORD_SET_CORE })
    AddWordCoding('tropical', { sound: TARGET_SOUND_R, level: LEVEL_3, position: POSITION_TR_BLEND })
    AddWordCoding('poetry', { sound: TARGET_SOUND_R, level: LEVEL_3, position: POSITION_TR_BLEND, set: WORD_SET_CORE })

    // Target sound R: Vocalic AIR
    AddWordCoding('airy', { sound: TARGET_SOUND_R, level: LEVEL_1, position: POSITION_VOCALIC_AIR })
    AddWordCoding('area', { sound: TARGET_SOUND_R, level: LEVEL_1, position: POSITION_VOCALIC_AIR })
    AddWordCoding('err', { sound: TARGET_SOUND_R, level: LEVEL_1, position: POSITION_VOCALIC_AIR })
    AddWordCoding('hair', { sound: TARGET_SOUND_R, level: LEVEL_1, position: POSITION_VOCALIC_AIR })
    AddWordCoding('hare', { sound: TARGET_SOUND_R, level: LEVEL_1, position: POSITION_VOCALIC_AIR })
    AddWordCoding('caring', { sound: TARGET_SOUND_R, level: LEVEL_1, position: POSITION_VOCALIC_AIR, set: WORD_SET_CORE })
    AddWordCoding('Erin', { sound: TARGET_SOUND_R, level: LEVEL_1, position: POSITION_VOCALIC_AIR, set: WORD_SET_CORE })
    AddWordCoding('carried', { sound: TARGET_SOUND_R, level: LEVEL_1, position: POSITION_VOCALIC_AIR, set: WORD_SET_CORE })
    AddWordCoding('carrot', { sound: TARGET_SOUND_R, level: LEVEL_1, position: POSITION_VOCALIC_AIR, set: WORD_SET_CORE })
    AddWordCoding('carry', { sound: TARGET_SOUND_R, level: LEVEL_1, position: POSITION_VOCALIC_AIR, set: WORD_SET_CORE })
    AddWordCoding('cherry', { sound: TARGET_SOUND_R, level: LEVEL_1, position: POSITION_VOCALIC_AIR, set: WORD_SET_CORE })
    AddWordCoding('dairy', { sound: TARGET_SOUND_R, level: LEVEL_1, position: POSITION_VOCALIC_AIR })
    AddWordCoding('daring', { sound: TARGET_SOUND_R, level: LEVEL_1, position: POSITION_VOCALIC_AIR, set: WORD_SET_CORE })
    AddWordCoding('errand', { sound: TARGET_SOUND_R, level: LEVEL_1, position: POSITION_VOCALIC_AIR })
    AddWordCoding('erring', { sound: TARGET_SOUND_R, level: LEVEL_1, position: POSITION_VOCALIC_AIR })
    AddWordCoding('hairy', { sound: TARGET_SOUND_R, level: LEVEL_1, position: POSITION_VOCALIC_AIR, set: WORD_SET_CORE })
    AddWordCoding('Harry', { sound: TARGET_SOUND_R, level: LEVEL_1, position: POSITION_VOCALIC_AIR, set: WORD_SET_CORE })
    AddWordCoding('heron', { sound: TARGET_SOUND_R, level: LEVEL_1, position: POSITION_VOCALIC_AIR })
    AddWordCoding('necessary', { sound: TARGET_SOUND_R, level: LEVEL_1, position: POSITION_VOCALIC_AIR })
    AddWordCoding('scary', { sound: TARGET_SOUND_R, level: LEVEL_1, position: POSITION_VOCALIC_AIR, set: WORD_SET_CORE })
    AddWordCoding('sharing', { sound: TARGET_SOUND_R, level: LEVEL_1, position: POSITION_VOCALIC_AIR, set: WORD_SET_CORE })
    AddWordCoding('tearing', { sound: TARGET_SOUND_R, level: LEVEL_1, position: POSITION_VOCALIC_AIR })
    AddWordCoding('Sarah', { sound: TARGET_SOUND_R, level: LEVEL_1, position: POSITION_VOCALIC_AIR, set: WORD_SET_CORE })
    AddWordCoding('air', { sound: TARGET_SOUND_R, level: LEVEL_1, position: POSITION_VOCALIC_AIR })

    AddWordCoding('bare', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_VOCALIC_AIR })
    AddWordCoding('bear', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_VOCALIC_AIR, set: WORD_SET_CORE })
    AddWordCoding('care', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_VOCALIC_AIR })
    AddWordCoding('chair', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_VOCALIC_AIR, set: WORD_SET_CORE })
    AddWordCoding('dare', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_VOCALIC_AIR })
    AddWordCoding('parent', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_VOCALIC_AIR, set: WORD_SET_CORE })
    AddWordCoding('parrot', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_VOCALIC_AIR, set: WORD_SET_CORE })
    AddWordCoding('arrow', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_VOCALIC_AIR, set: WORD_SET_CORE })
    AddWordCoding('berry', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_VOCALIC_AIR, set: WORD_SET_CORE })
    AddWordCoding('beware', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_VOCALIC_AIR })
    AddWordCoding('merry', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_VOCALIC_AIR })
    AddWordCoding('pair', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_VOCALIC_AIR })
    AddWordCoding('pare', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_VOCALIC_AIR })
    AddWordCoding('pear', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_VOCALIC_AIR, set: WORD_SET_CORE })
    AddWordCoding('scare', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_VOCALIC_AIR })
    AddWordCoding('share', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_VOCALIC_AIR, set: WORD_SET_CORE })
    AddWordCoding('snare', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_VOCALIC_AIR })
    AddWordCoding('spare', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_VOCALIC_AIR })
    AddWordCoding('square', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_VOCALIC_AIR, set: WORD_SET_CORE })
    AddWordCoding('tear', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_VOCALIC_AIR })
    AddWordCoding('their', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_VOCALIC_AIR })
    AddWordCoding('there', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_VOCALIC_AIR })
    AddWordCoding('very', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_VOCALIC_AIR })
    AddWordCoding('wear', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_VOCALIC_AIR, set: WORD_SET_CORE })
    AddWordCoding('where', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_VOCALIC_AIR })
    AddWordCoding('Harrison', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_VOCALIC_AIR, set: WORD_SET_CORE })
    AddWordCoding('fairy', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_VOCALIC_AIR })
    AddWordCoding('ferry', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_VOCALIC_AIR })
    AddWordCoding('unfair', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_VOCALIC_AIR })
    AddWordCoding('stairs', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_VOCALIC_AIR, set: WORD_SET_CORE })

    AddWordCoding('anywhere', { sound: TARGET_SOUND_R, level: LEVEL_3, position: POSITION_VOCALIC_AIR })
    AddWordCoding('asparagus', { sound: TARGET_SOUND_R, level: LEVEL_3, position: POSITION_VOCALIC_AIR })
    AddWordCoding('blare', { sound: TARGET_SOUND_R, level: LEVEL_3, position: POSITION_VOCALIC_AIR })
    AddWordCoding('compare', { sound: TARGET_SOUND_R, level: LEVEL_3, position: POSITION_VOCALIC_AIR })
    AddWordCoding('Delaware', { sound: TARGET_SOUND_R, level: LEVEL_3, position: POSITION_VOCALIC_AIR, set: WORD_SET_CORE })
    AddWordCoding('downstairs', { sound: TARGET_SOUND_R, level: LEVEL_3, position: POSITION_VOCALIC_AIR, set: WORD_SET_CORE })
    AddWordCoding('embarrassed', { sound: TARGET_SOUND_R, level: LEVEL_3, position: POSITION_VOCALIC_AIR, set: WORD_SET_CORE })
    AddWordCoding('fair', { sound: TARGET_SOUND_R, level: LEVEL_3, position: POSITION_VOCALIC_AIR, set: WORD_SET_CORE })
    AddWordCoding('fare', { sound: TARGET_SOUND_R, level: LEVEL_3, position: POSITION_VOCALIC_AIR })
    AddWordCoding('ferris wheel', { sound: TARGET_SOUND_R, level: LEVEL_3, position: POSITION_VOCALIC_AIR, set: WORD_SET_CORE })
    AddWordCoding('flair', { sound: TARGET_SOUND_R, level: LEVEL_3, position: POSITION_VOCALIC_AIR })
    AddWordCoding('flare', { sound: TARGET_SOUND_R, level: LEVEL_3, position: POSITION_VOCALIC_AIR, variant: 1 })  // jeans
    AddWordCoding('flare', { sound: TARGET_SOUND_R, level: LEVEL_3, position: POSITION_VOCALIC_AIR, variant: 2 })  // light
    AddWordCoding('January', { sound: TARGET_SOUND_R, level: LEVEL_3, position: POSITION_VOCALIC_AIR, set: WORD_SET_CORE })
    AddWordCoding('lair', { sound: TARGET_SOUND_R, level: LEVEL_3, position: POSITION_VOCALIC_AIR })
    AddWordCoding('Larry', { sound: TARGET_SOUND_R, level: LEVEL_3, position: POSITION_VOCALIC_AIR, set: WORD_SET_CORE })
    AddWordCoding('marathon', { sound: TARGET_SOUND_R, level: LEVEL_3, position: POSITION_VOCALIC_AIR })
    AddWordCoding('mare', { sound: TARGET_SOUND_R, level: LEVEL_3, position: POSITION_VOCALIC_AIR })
    AddWordCoding('Maryland', { sound: TARGET_SOUND_R, level: LEVEL_3, position: POSITION_VOCALIC_AIR, set: WORD_SET_CORE })
    AddWordCoding('millionaire', { sound: TARGET_SOUND_R, level: LEVEL_3, position: POSITION_VOCALIC_AIR })
    AddWordCoding('nightmare', { sound: TARGET_SOUND_R, level: LEVEL_3, position: POSITION_VOCALIC_AIR, set: WORD_SET_CORE })
    AddWordCoding('nowhere', { sound: TARGET_SOUND_R, level: LEVEL_3, position: POSITION_VOCALIC_AIR })
    AddWordCoding('parachute', { sound: TARGET_SOUND_R, level: LEVEL_3, position: POSITION_VOCALIC_AIR, set: WORD_SET_CORE })
    AddWordCoding('periscope', { sound: TARGET_SOUND_R, level: LEVEL_3, position: POSITION_VOCALIC_AIR })
    AddWordCoding('somewhere', { sound: TARGET_SOUND_R, level: LEVEL_3, position: POSITION_VOCALIC_AIR, set: WORD_SET_CORE })
    AddWordCoding('sparrow', { sound: TARGET_SOUND_R, level: LEVEL_3, position: POSITION_VOCALIC_AIR })
    AddWordCoding('upstairs', { sound: TARGET_SOUND_R, level: LEVEL_3, position: POSITION_VOCALIC_AIR, set: WORD_SET_CORE })

    // Target sound R: Vocalic AR
    AddWordCoding('car', { sound: TARGET_SOUND_R, level: LEVEL_1, position: POSITION_VOCALIC_AR, set: WORD_SET_CORE })
    AddWordCoding('cart', { sound: TARGET_SOUND_R, level: LEVEL_1, position: POSITION_VOCALIC_AR, set: WORD_SET_CORE })
    AddWordCoding('charge', { sound: TARGET_SOUND_R, level: LEVEL_1, position: POSITION_VOCALIC_AR })
    AddWordCoding('hard', { sound: TARGET_SOUND_R, level: LEVEL_1, position: POSITION_VOCALIC_AR, set: WORD_SET_CORE })
    AddWordCoding('heart', { sound: TARGET_SOUND_R, level: LEVEL_1, position: POSITION_VOCALIC_AR, set: WORD_SET_CORE })
    AddWordCoding('jar', { sound: TARGET_SOUND_R, level: LEVEL_1, position: POSITION_VOCALIC_AR, set: WORD_SET_CORE })
    AddWordCoding('scar', { sound: TARGET_SOUND_R, level: LEVEL_1, position: POSITION_VOCALIC_AR, set: WORD_SET_CORE })
    AddWordCoding('card', { sound: TARGET_SOUND_R, level: LEVEL_1, position: POSITION_VOCALIC_AR, set: WORD_SET_CORE })
    AddWordCoding('art', { sound: TARGET_SOUND_R, level: LEVEL_1, position: POSITION_VOCALIC_AR, set: WORD_SET_CORE })
    AddWordCoding('Sorry', { sound: TARGET_SOUND_R, level: LEVEL_1, position: POSITION_VOCALIC_AR, variant: 2, set: WORD_SET_CORE })  // game
    AddWordCoding('sorry', { sound: TARGET_SOUND_R, level: LEVEL_1, position: POSITION_VOCALIC_AR, variant: 1 })  // apology
    AddWordCoding('yard', { sound: TARGET_SOUND_R, level: LEVEL_1, position: POSITION_VOCALIC_AR, variant: 1, set: WORD_SET_CORE })  // lawn
    AddWordCoding('yard', { sound: TARGET_SOUND_R, level: LEVEL_1, position: POSITION_VOCALIC_AR, variant: 2, set: WORD_SET_CORE })  // measure
    AddWordCoding('are', { sound: TARGET_SOUND_R, level: LEVEL_1, position: POSITION_VOCALIC_AR })
    AddWordCoding('arch', { sound: TARGET_SOUND_R, level: LEVEL_1, position: POSITION_VOCALIC_AR })
    AddWordCoding('dark', { sound: TARGET_SOUND_R, level: LEVEL_1, position: POSITION_VOCALIC_AR, set: WORD_SET_CORE })

    AddWordCoding('bar', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_VOCALIC_AR })
    AddWordCoding('barn', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_VOCALIC_AR, set: WORD_SET_CORE })
    AddWordCoding('far', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_VOCALIC_AR, set: WORD_SET_CORE })
    AddWordCoding('March', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_VOCALIC_AR, variant: 1, set: WORD_SET_CORE })  // month
    AddWordCoding('march', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_VOCALIC_AR, variant: 2 })  // parade
    AddWordCoding('Mark', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_VOCALIC_AR, variant: 1, set: WORD_SET_CORE })  // name
    AddWordCoding('mark', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_VOCALIC_AR, variant: 2 })  // spot
    AddWordCoding('Mars', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_VOCALIC_AR })
    AddWordCoding('park', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_VOCALIC_AR, set: WORD_SET_CORE })
    AddWordCoding('sharp', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_VOCALIC_AR })
    AddWordCoding('smart', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_VOCALIC_AR })
    AddWordCoding('spark', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_VOCALIC_AR })
    AddWordCoding('artist', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_VOCALIC_AR })
    AddWordCoding('bargain', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_VOCALIC_AR })
    AddWordCoding('bark', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_VOCALIC_AR, variant: 1, set: WORD_SET_CORE })  // dog
    AddWordCoding('bark', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_VOCALIC_AR, variant: 2 })  // tree
    AddWordCoding('candy bar', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_VOCALIC_AR })
    AddWordCoding('carpet', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_VOCALIC_AR })
    AddWordCoding('carve', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_VOCALIC_AR })
    AddWordCoding('cartoon', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_VOCALIC_AR })
    AddWordCoding('Charlie', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_VOCALIC_AR, set: WORD_SET_CORE })
    AddWordCoding('dart', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_VOCALIC_AR })
    AddWordCoding('farm', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_VOCALIC_AR })
    AddWordCoding('garbage', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_VOCALIC_AR, set: WORD_SET_CORE })
    AddWordCoding('garden', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_VOCALIC_AR })
    AddWordCoding('guitar', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_VOCALIC_AR })
    AddWordCoding('jaguar', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_VOCALIC_AR })
    AddWordCoding('large', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_VOCALIC_AR, set: WORD_SET_CORE })
    AddWordCoding('mason jar', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_VOCALIC_AR })
    AddWordCoding('parsnip', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_VOCALIC_AR })
    AddWordCoding('party', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_VOCALIC_AR, set: WORD_SET_CORE })
    AddWordCoding('postcard', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_VOCALIC_AR })
    AddWordCoding('scarf', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_VOCALIC_AR })
    AddWordCoding('smarty', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_VOCALIC_AR })
    AddWordCoding('snack bar', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_VOCALIC_AR })
    AddWordCoding('star', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_VOCALIC_AR, set: WORD_SET_CORE })
    AddWordCoding('start', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_VOCALIC_AR, set: WORD_SET_CORE })
    AddWordCoding('target', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_VOCALIC_AR })
    AddWordCoding('arcade', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_VOCALIC_AR })
    AddWordCoding('alarm', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_VOCALIC_AR })

    AddWordCoding('alarm clock', { sound: TARGET_SOUND_R, level: LEVEL_3, position: POSITION_VOCALIC_AR, set: WORD_SET_CORE })
    AddWordCoding('ant farm', { sound: TARGET_SOUND_R, level: LEVEL_3, position: POSITION_VOCALIC_AR })
    AddWordCoding('armadillo', { sound: TARGET_SOUND_R, level: LEVEL_3, position: POSITION_VOCALIC_AR })
    AddWordCoding('barbecue', { sound: TARGET_SOUND_R, level: LEVEL_3, position: POSITION_VOCALIC_AR, set: WORD_SET_CORE })
    AddWordCoding('barbells', { sound: TARGET_SOUND_R, level: LEVEL_3, position: POSITION_VOCALIC_AR, set: WORD_SET_CORE })
    AddWordCoding('bookmark', { sound: TARGET_SOUND_R, level: LEVEL_3, position: POSITION_VOCALIC_AR, set: WORD_SET_CORE })
    AddWordCoding('borrow', { sound: TARGET_SOUND_R, level: LEVEL_3, position: POSITION_VOCALIC_AR })
    AddWordCoding('cable car', { sound: TARGET_SOUND_R, level: LEVEL_3, position: POSITION_VOCALIC_AR })
    AddWordCoding('cookie jar', { sound: TARGET_SOUND_R, level: LEVEL_3, position: POSITION_VOCALIC_AR, set: WORD_SET_CORE })
    AddWordCoding('falling star', { sound: TARGET_SOUND_R, level: LEVEL_3, position: POSITION_VOCALIC_AR, set: WORD_SET_CORE })
    AddWordCoding('flashcard', { sound: TARGET_SOUND_R, level: LEVEL_3, position: POSITION_VOCALIC_AR, set: WORD_SET_CORE })
    AddWordCoding('garlic', { sound: TARGET_SOUND_R, level: LEVEL_3, position: POSITION_VOCALIC_AR })
    AddWordCoding('handlebar', { sound: TARGET_SOUND_R, level: LEVEL_3, position: POSITION_VOCALIC_AR })
    AddWordCoding('hard-boiled', { sound: TARGET_SOUND_R, level: LEVEL_3, position: POSITION_VOCALIC_AR })
    AddWordCoding('lifeguard', { sound: TARGET_SOUND_R, level: LEVEL_3, position: POSITION_VOCALIC_AR })
    AddWordCoding('marble', { sound: TARGET_SOUND_R, level: LEVEL_3, position: POSITION_VOCALIC_AR, set: WORD_SET_CORE })
    AddWordCoding('marshmallow', { sound: TARGET_SOUND_R, level: LEVEL_3, position: POSITION_VOCALIC_AR, set: WORD_SET_CORE })
    AddWordCoding('pharmacy', { sound: TARGET_SOUND_R, level: LEVEL_3, position: POSITION_VOCALIC_AR })
    AddWordCoding('pickle jar', { sound: TARGET_SOUND_R, level: LEVEL_3, position: POSITION_VOCALIC_AR })
    AddWordCoding('snarl', { sound: TARGET_SOUND_R, level: LEVEL_3, position: POSITION_VOCALIC_AR })
    AddWordCoding('space bar', { sound: TARGET_SOUND_R, level: LEVEL_3, position: POSITION_VOCALIC_AR })
    AddWordCoding('sparkle', { sound: TARGET_SOUND_R, level: LEVEL_3, position: POSITION_VOCALIC_AR, set: WORD_SET_CORE })
    AddWordCoding('sparkly', { sound: TARGET_SOUND_R, level: LEVEL_3, position: POSITION_VOCALIC_AR })
    AddWordCoding('starfish', { sound: TARGET_SOUND_R, level: LEVEL_3, position: POSITION_VOCALIC_AR, set: WORD_SET_CORE })
    AddWordCoding('sweetheart', { sound: TARGET_SOUND_R, level: LEVEL_3, position: POSITION_VOCALIC_AR })
    AddWordCoding('parking lot', { sound: TARGET_SOUND_R, level: LEVEL_3, position: POSITION_VOCALIC_AR, set: WORD_SET_CORE })

    // Target sound R: Vocalic EAR
    AddWordCoding('cheery', { sound: TARGET_SOUND_R, level: LEVEL_1, position: POSITION_VOCALIC_EAR, set: WORD_SET_CORE })
    AddWordCoding('dear', { sound: TARGET_SOUND_R, level: LEVEL_1, position: POSITION_VOCALIC_EAR, set: WORD_SET_CORE })
    AddWordCoding('ear', { sound: TARGET_SOUND_R, level: LEVEL_1, position: POSITION_VOCALIC_EAR, set: WORD_SET_CORE })
    AddWordCoding('earache', { sound: TARGET_SOUND_R, level: LEVEL_1, position: POSITION_VOCALIC_EAR, set: WORD_SET_CORE })
    AddWordCoding('earring', { sound: TARGET_SOUND_R, level: LEVEL_1, position: POSITION_VOCALIC_EAR, set: WORD_SET_CORE })
    AddWordCoding('eerie', { sound: TARGET_SOUND_R, level: LEVEL_1, position: POSITION_VOCALIC_EAR })
    AddWordCoding('Erie', { sound: TARGET_SOUND_R, level: LEVEL_1, position: POSITION_VOCALIC_EAR })
    AddWordCoding('gear', { sound: TARGET_SOUND_R, level: LEVEL_1, position: POSITION_VOCALIC_EAR })
    AddWordCoding('hear', { sound: TARGET_SOUND_R, level: LEVEL_1, position: POSITION_VOCALIC_EAR, set: WORD_SET_CORE })
    AddWordCoding('hearing', { sound: TARGET_SOUND_R, level: LEVEL_1, position: POSITION_VOCALIC_EAR, set: WORD_SET_CORE })
    AddWordCoding('here', { sound: TARGET_SOUND_R, level: LEVEL_1, position: POSITION_VOCALIC_EAR, set: WORD_SET_CORE })
    AddWordCoding('near', { sound: TARGET_SOUND_R, level: LEVEL_1, position: POSITION_VOCALIC_EAR, set: WORD_SET_CORE })
    AddWordCoding('nearing', { sound: TARGET_SOUND_R, level: LEVEL_1, position: POSITION_VOCALIC_EAR })
    AddWordCoding('series', { sound: TARGET_SOUND_R, level: LEVEL_1, position: POSITION_VOCALIC_EAR })
    AddWordCoding('serious', { sound: TARGET_SOUND_R, level: LEVEL_1, position: POSITION_VOCALIC_EAR })
    AddWordCoding('shearing', { sound: TARGET_SOUND_R, level: LEVEL_1, position: POSITION_VOCALIC_EAR })
    AddWordCoding('sneer', { sound: TARGET_SOUND_R, level: LEVEL_1, position: POSITION_VOCALIC_EAR })
    AddWordCoding('sneering', { sound: TARGET_SOUND_R, level: LEVEL_1, position: POSITION_VOCALIC_EAR })
    AddWordCoding('steering', { sound: TARGET_SOUND_R, level: LEVEL_1, position: POSITION_VOCALIC_EAR, set: WORD_SET_CORE })
    AddWordCoding('tear', { sound: TARGET_SOUND_R, level: LEVEL_1, position: POSITION_VOCALIC_EAR, set: WORD_SET_CORE })
    AddWordCoding('tearing', { sound: TARGET_SOUND_R, level: LEVEL_1, position: POSITION_VOCALIC_EAR })
    AddWordCoding('year', { sound: TARGET_SOUND_R, level: LEVEL_1, position: POSITION_VOCALIC_EAR, set: WORD_SET_CORE })
    AddWordCoding('year end', { sound: TARGET_SOUND_R, level: LEVEL_1, position: POSITION_VOCALIC_EAR })

    AddWordCoding('fearing', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_VOCALIC_EAR })
    AddWordCoding('smearing', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_VOCALIC_EAR })
    AddWordCoding('appear', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_VOCALIC_EAR, set: WORD_SET_CORE })
    AddWordCoding('beard', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_VOCALIC_EAR, set: WORD_SET_CORE })
    AddWordCoding('cashier', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_VOCALIC_EAR })
    AddWordCoding('cheer', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_VOCALIC_EAR, set: WORD_SET_CORE })
    AddWordCoding('clear', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_VOCALIC_EAR })
    AddWordCoding('earlobe', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_VOCALIC_EAR })
    AddWordCoding('fear', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_VOCALIC_EAR, set: WORD_SET_CORE })
    AddWordCoding('fierce', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_VOCALIC_EAR, set: WORD_SET_CORE })
    AddWordCoding('hero', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_VOCALIC_EAR, set: WORD_SET_CORE })
    AddWordCoding('irritate', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_VOCALIC_EAR })
    AddWordCoding('lyrics', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_VOCALIC_EAR })
    AddWordCoding('nearby', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_VOCALIC_EAR })
    AddWordCoding('peer', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_VOCALIC_EAR })
    AddWordCoding('period', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_VOCALIC_EAR, set: WORD_SET_CORE })
    AddWordCoding('pier', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_VOCALIC_EAR, set: WORD_SET_CORE })
    AddWordCoding('severe', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_VOCALIC_EAR })
    AddWordCoding('shears', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_VOCALIC_EAR })
    AddWordCoding('sheer', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_VOCALIC_EAR })
    AddWordCoding('smear', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_VOCALIC_EAR, set: WORD_SET_CORE })
    AddWordCoding('spear', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_VOCALIC_EAR })
    AddWordCoding('sphere', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_VOCALIC_EAR })
    AddWordCoding('syrup', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_VOCALIC_EAR, set: WORD_SET_CORE })
    AddWordCoding('veering', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_VOCALIC_EAR })
    AddWordCoding('weary', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_VOCALIC_EAR })
    AddWordCoding('weird', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_VOCALIC_EAR, set: WORD_SET_CORE })
    AddWordCoding('zero', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_VOCALIC_EAR, set: WORD_SET_CORE })

    AddWordCoding('cafeteria', { sound: TARGET_SOUND_R, level: LEVEL_3, position: POSITION_VOCALIC_EAR, set: WORD_SET_CORE })
    AddWordCoding('cereal', { sound: TARGET_SOUND_R, level: LEVEL_3, position: POSITION_VOCALIC_EAR, set: WORD_SET_CORE })
    AddWordCoding('cheerful', { sound: TARGET_SOUND_R, level: LEVEL_3, position: POSITION_VOCALIC_EAR, set: WORD_SET_CORE })
    AddWordCoding('disappear', { sound: TARGET_SOUND_R, level: LEVEL_3, position: POSITION_VOCALIC_EAR, set: WORD_SET_CORE })
    AddWordCoding('ear buds', { sound: TARGET_SOUND_R, level: LEVEL_3, position: POSITION_VOCALIC_EAR, set: WORD_SET_CORE })
    AddWordCoding('engineer', { sound: TARGET_SOUND_R, level: LEVEL_3, position: POSITION_VOCALIC_EAR })
    AddWordCoding('engineering', { sound: TARGET_SOUND_R, level: LEVEL_3, position: POSITION_VOCALIC_EAR })
    AddWordCoding('exterior', { sound: TARGET_SOUND_R, level: LEVEL_3, position: POSITION_VOCALIC_EAR })
    AddWordCoding('gondolier', { sound: TARGET_SOUND_R, level: LEVEL_3, position: POSITION_VOCALIC_EAR })
    AddWordCoding('hearing aid', { sound: TARGET_SOUND_R, level: LEVEL_3, position: POSITION_VOCALIC_EAR, set: WORD_SET_CORE })
    AddWordCoding('hemisphere', { sound: TARGET_SOUND_R, level: LEVEL_3, position: POSITION_VOCALIC_EAR, set: WORD_SET_CORE })
    AddWordCoding('irrigate', { sound: TARGET_SOUND_R, level: LEVEL_3, position: POSITION_VOCALIC_EAR })
    AddWordCoding('leap year', { sound: TARGET_SOUND_R, level: LEVEL_3, position: POSITION_VOCALIC_EAR })
    AddWordCoding('maple syrup', { sound: TARGET_SOUND_R, level: LEVEL_3, position: POSITION_VOCALIC_EAR })
    AddWordCoding('mountaineer', { sound: TARGET_SOUND_R, level: LEVEL_3, position: POSITION_VOCALIC_EAR })
    AddWordCoding('near-sighted', { sound: TARGET_SOUND_R, level: LEVEL_3, position: POSITION_VOCALIC_EAR })
    AddWordCoding('New Year', { sound: TARGET_SOUND_R, level: LEVEL_3, position: POSITION_VOCALIC_EAR, set: WORD_SET_CORE })
    AddWordCoding('pioneer', { sound: TARGET_SOUND_R, level: LEVEL_3, position: POSITION_VOCALIC_EAR })
    AddWordCoding('pyramid', { sound: TARGET_SOUND_R, level: LEVEL_3, position: POSITION_VOCALIC_EAR, set: WORD_SET_CORE })
    AddWordCoding('sincerely', { sound: TARGET_SOUND_R, level: LEVEL_3, position: POSITION_VOCALIC_EAR, set: WORD_SET_CORE })
    AddWordCoding('souvenir', { sound: TARGET_SOUND_R, level: LEVEL_3, position: POSITION_VOCALIC_EAR })
    AddWordCoding('spearmint', { sound: TARGET_SOUND_R, level: LEVEL_3, position: POSITION_VOCALIC_EAR })
    AddWordCoding('steering wheel', { sound: TARGET_SOUND_R, level: LEVEL_3, position: POSITION_VOCALIC_EAR, set: WORD_SET_CORE })
    AddWordCoding('yearbook', { sound: TARGET_SOUND_R, level: LEVEL_3, position: POSITION_VOCALIC_EAR, set: WORD_SET_CORE })

    // Target sound R: Vocalic ER
    AddWordCoding('buttering', { sound: TARGET_SOUND_R, level: LEVEL_1, position: POSITION_VOCALIC_ER })
    AddWordCoding('coloring', { sound: TARGET_SOUND_R, level: LEVEL_1, position: POSITION_VOCALIC_ER, set: WORD_SET_CORE })
    AddWordCoding('dancer', { sound: TARGET_SOUND_R, level: LEVEL_1, position: POSITION_VOCALIC_ER })
    AddWordCoding('dangerous', { sound: TARGET_SOUND_R, level: LEVEL_1, position: POSITION_VOCALIC_ER, set: WORD_SET_CORE })
    AddWordCoding('decorate', { sound: TARGET_SOUND_R, level: LEVEL_1, position: POSITION_VOCALIC_ER, set: WORD_SET_CORE })
    AddWordCoding('dinner', { sound: TARGET_SOUND_R, level: LEVEL_1, position: POSITION_VOCALIC_ER, set: WORD_SET_CORE })
    AddWordCoding('dirt', { sound: TARGET_SOUND_R, level: LEVEL_1, position: POSITION_VOCALIC_ER })
    AddWordCoding('doctor', { sound: TARGET_SOUND_R, level: LEVEL_1, position: POSITION_VOCALIC_ER, set: WORD_SET_CORE })
    AddWordCoding('evaporate', { sound: TARGET_SOUND_R, level: LEVEL_1, position: POSITION_VOCALIC_ER })
    AddWordCoding('flavoring', { sound: TARGET_SOUND_R, level: LEVEL_1, position: POSITION_VOCALIC_ER })
    AddWordCoding('generous', { sound: TARGET_SOUND_R, level: LEVEL_1, position: POSITION_VOCALIC_ER })
    AddWordCoding('hammering', { sound: TARGET_SOUND_R, level: LEVEL_1, position: POSITION_VOCALIC_ER, set: WORD_SET_CORE })
    AddWordCoding('herd', { sound: TARGET_SOUND_R, level: LEVEL_1, position: POSITION_VOCALIC_ER })
    AddWordCoding('horizon', { sound: TARGET_SOUND_R, level: LEVEL_1, position: POSITION_VOCALIC_ER })
    AddWordCoding('hurt', { sound: TARGET_SOUND_R, level: LEVEL_1, position: POSITION_VOCALIC_ER, set: WORD_SET_CORE })
    AddWordCoding('layering', { sound: TARGET_SOUND_R, level: LEVEL_1, position: POSITION_VOCALIC_ER })
    AddWordCoding('licorice', { sound: TARGET_SOUND_R, level: LEVEL_1, position: POSITION_VOCALIC_ER })
    AddWordCoding('lottery', { sound: TARGET_SOUND_R, level: LEVEL_1, position: POSITION_VOCALIC_ER })
    AddWordCoding('scootering', { sound: TARGET_SOUND_R, level: LEVEL_1, position: POSITION_VOCALIC_ER })
    AddWordCoding('skirt', { sound: TARGET_SOUND_R, level: LEVEL_1, position: POSITION_VOCALIC_ER, set: WORD_SET_CORE })
    AddWordCoding('snicker', { sound: TARGET_SOUND_R, level: LEVEL_1, position: POSITION_VOCALIC_ER })
    AddWordCoding('soccer', { sound: TARGET_SOUND_R, level: LEVEL_1, position: POSITION_VOCALIC_ER, set: WORD_SET_CORE })
    AddWordCoding('stir', { sound: TARGET_SOUND_R, level: LEVEL_1, position: POSITION_VOCALIC_ER })
    AddWordCoding('stirring', { sound: TARGET_SOUND_R, level: LEVEL_1, position: POSITION_VOCALIC_ER, set: WORD_SET_CORE })
    AddWordCoding('victory', { sound: TARGET_SOUND_R, level: LEVEL_1, position: POSITION_VOCALIC_ER })
    AddWordCoding('whispering', { sound: TARGET_SOUND_R, level: LEVEL_1, position: POSITION_VOCALIC_ER, set: WORD_SET_CORE })
    AddWordCoding('Zachary', { sound: TARGET_SOUND_R, level: LEVEL_1, position: POSITION_VOCALIC_ER, set: WORD_SET_CORE })

    AddWordCoding('butter', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_VOCALIC_ER })
    AddWordCoding('clippers', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_VOCALIC_ER, variant: 1 })  // hair
    AddWordCoding('clippers', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_VOCALIC_ER, variant: 2 })  // nail
    AddWordCoding('clipper', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_VOCALIC_ER })
    AddWordCoding('closer', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_VOCALIC_ER })
    AddWordCoding('color', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_VOCALIC_ER, set: WORD_SET_CORE })
    AddWordCoding('dollar', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_VOCALIC_ER })
    AddWordCoding('every', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_VOCALIC_ER })
    AddWordCoding('first', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_VOCALIC_ER })
    AddWordCoding('flavor', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_VOCALIC_ER })
    AddWordCoding('flippers', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_VOCALIC_ER })
    AddWordCoding('flower', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_VOCALIC_ER, set: WORD_SET_CORE })
    AddWordCoding('germs', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_VOCALIC_ER, set: WORD_SET_CORE })
    AddWordCoding('hammer', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_VOCALIC_ER, set: WORD_SET_CORE })
    AddWordCoding('juggler', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_VOCALIC_ER })
    AddWordCoding('ladder', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_VOCALIC_ER, set: WORD_SET_CORE })
    AddWordCoding('learn', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_VOCALIC_ER })
    AddWordCoding('letter', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_VOCALIC_ER })
    AddWordCoding('lizard', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_VOCALIC_ER, set: WORD_SET_CORE })
    AddWordCoding('person', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_VOCALIC_ER })
    AddWordCoding('Peru', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_VOCALIC_ER })
    AddWordCoding('Saturn', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_VOCALIC_ER, set: WORD_SET_CORE })
    AddWordCoding('singer', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_VOCALIC_ER, set: WORD_SET_CORE })
    AddWordCoding('sister', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_VOCALIC_ER, set: WORD_SET_CORE })
    AddWordCoding('slippery', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_VOCALIC_ER })
    AddWordCoding('smaller', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_VOCALIC_ER, set: WORD_SET_CORE })
    AddWordCoding('summer', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_VOCALIC_ER, set: WORD_SET_CORE })
    AddWordCoding('super', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_VOCALIC_ER })
    AddWordCoding('swimmer', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_VOCALIC_ER, set: WORD_SET_CORE })
    AddWordCoding('whiskers', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_VOCALIC_ER })
    AddWordCoding('whisper', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_VOCALIC_ER })

    AddWordCoding('Colorado', { sound: TARGET_SOUND_R, level: LEVEL_3, position: POSITION_VOCALIC_ER, set: WORD_SET_CORE })
    AddWordCoding('December', { sound: TARGET_SOUND_R, level: LEVEL_3, position: POSITION_VOCALIC_ER, set: WORD_SET_CORE })
    AddWordCoding('escalator', { sound: TARGET_SOUND_R, level: LEVEL_3, position: POSITION_VOCALIC_ER })
    AddWordCoding('girl', { sound: TARGET_SOUND_R, level: LEVEL_3, position: POSITION_VOCALIC_ER, set: WORD_SET_CORE })
    AddWordCoding('glow worm', { sound: TARGET_SOUND_R, level: LEVEL_3, position: POSITION_VOCALIC_ER })
    AddWordCoding('groceries', { sound: TARGET_SOUND_R, level: LEVEL_3, position: POSITION_VOCALIC_ER })
    AddWordCoding('helicopter', { sound: TARGET_SOUND_R, level: LEVEL_3, position: POSITION_VOCALIC_ER, set: WORD_SET_CORE })
    AddWordCoding('hourglass', { sound: TARGET_SOUND_R, level: LEVEL_3, position: POSITION_VOCALIC_ER })
    AddWordCoding('jack o lantern', { sound: TARGET_SOUND_R, level: LEVEL_3, position: POSITION_VOCALIC_ER, text: "jack o' lantern" })
    AddWordCoding('lawnmower', { sound: TARGET_SOUND_R, level: LEVEL_3, position: POSITION_VOCALIC_ER })
    AddWordCoding('Liberty Bell', { sound: TARGET_SOUND_R, level: LEVEL_3, position: POSITION_VOCALIC_ER })
    AddWordCoding('motorcycle', { sound: TARGET_SOUND_R, level: LEVEL_3, position: POSITION_VOCALIC_ER, set: WORD_SET_CORE })
    AddWordCoding('outer space', { sound: TARGET_SOUND_R, level: LEVEL_3, position: POSITION_VOCALIC_ER, set: WORD_SET_CORE })
    AddWordCoding('Saturday', { sound: TARGET_SOUND_R, level: LEVEL_3, position: POSITION_VOCALIC_ER, set: WORD_SET_CORE })
    AddWordCoding('scooter', { sound: TARGET_SOUND_R, level: LEVEL_3, position: POSITION_VOCALIC_ER, set: WORD_SET_CORE })
    AddWordCoding('sea turtle', { sound: TARGET_SOUND_R, level: LEVEL_3, position: POSITION_VOCALIC_ER, set: WORD_SET_CORE })
    AddWordCoding('September', { sound: TARGET_SOUND_R, level: LEVEL_3, position: POSITION_VOCALIC_ER, set: WORD_SET_CORE })
    AddWordCoding('sleepwalker', { sound: TARGET_SOUND_R, level: LEVEL_3, position: POSITION_VOCALIC_ER })
    AddWordCoding('snowblower', { sound: TARGET_SOUND_R, level: LEVEL_3, position: POSITION_VOCALIC_ER })
    AddWordCoding('soccer ball', { sound: TARGET_SOUND_R, level: LEVEL_3, position: POSITION_VOCALIC_ER })
    AddWordCoding('spider', { sound: TARGET_SOUND_R, level: LEVEL_3, position: POSITION_VOCALIC_ER, set: WORD_SET_CORE })
    AddWordCoding('spider web', { sound: TARGET_SOUND_R, level: LEVEL_3, position: POSITION_VOCALIC_ER, set: WORD_SET_CORE })
    AddWordCoding('sprinkler', { sound: TARGET_SOUND_R, level: LEVEL_3, position: POSITION_VOCALIC_ER })
    AddWordCoding('squirrel', { sound: TARGET_SOUND_R, level: LEVEL_3, position: POSITION_VOCALIC_ER })
    AddWordCoding('summertime', { sound: TARGET_SOUND_R, level: LEVEL_3, position: POSITION_VOCALIC_ER })
    AddWordCoding('sunflower', { sound: TARGET_SOUND_R, level: LEVEL_3, position: POSITION_VOCALIC_ER })
    AddWordCoding('suspenders', { sound: TARGET_SOUND_R, level: LEVEL_3, position: POSITION_VOCALIC_ER })
    AddWordCoding('swirl', { sound: TARGET_SOUND_R, level: LEVEL_3, position: POSITION_VOCALIC_ER })
    AddWordCoding('vacuum cleaner', { sound: TARGET_SOUND_R, level: LEVEL_3, position: POSITION_VOCALIC_ER })

    // Target sound R: Vocalic IRE
    AddWordCoding('attire', { sound: TARGET_SOUND_R, level: LEVEL_1, position: POSITION_VOCALIC_IRE })
    AddWordCoding('desire', { sound: TARGET_SOUND_R, level: LEVEL_1, position: POSITION_VOCALIC_IRE, set: WORD_SET_CORE })
    AddWordCoding('dire', { sound: TARGET_SOUND_R, level: LEVEL_1, position: POSITION_VOCALIC_IRE })
    AddWordCoding('entire', { sound: TARGET_SOUND_R, level: LEVEL_1, position: POSITION_VOCALIC_IRE, set: WORD_SET_CORE })
    AddWordCoding('higher', { sound: TARGET_SOUND_R, level: LEVEL_1, position: POSITION_VOCALIC_IRE, set: WORD_SET_CORE })
    AddWordCoding('hire', { sound: TARGET_SOUND_R, level: LEVEL_1, position: POSITION_VOCALIC_IRE, set: WORD_SET_CORE })
    AddWordCoding('hiring', { sound: TARGET_SOUND_R, level: LEVEL_1, position: POSITION_VOCALIC_IRE, set: WORD_SET_CORE })
    AddWordCoding('iris', { sound: TARGET_SOUND_R, level: LEVEL_1, position: POSITION_VOCALIC_IRE, variant: 1, set: WORD_SET_CORE })  // eye
    AddWordCoding('Iris', { sound: TARGET_SOUND_R, level: LEVEL_1, position: POSITION_VOCALIC_IRE, variant: 2, set: WORD_SET_CORE })  // name
    AddWordCoding('iris', { sound: TARGET_SOUND_R, level: LEVEL_1, position: POSITION_VOCALIC_IRE, variant: 3 })  // flower
    AddWordCoding('iron', { sound: TARGET_SOUND_R, level: LEVEL_1, position: POSITION_VOCALIC_IRE, set: WORD_SET_CORE })
    AddWordCoding('ironing', { sound: TARGET_SOUND_R, level: LEVEL_1, position: POSITION_VOCALIC_IRE, set: WORD_SET_CORE })
    AddWordCoding('siren', { sound: TARGET_SOUND_R, level: LEVEL_1, position: POSITION_VOCALIC_IRE, set: WORD_SET_CORE })
    AddWordCoding('tire', { sound: TARGET_SOUND_R, level: LEVEL_1, position: POSITION_VOCALIC_IRE, set: WORD_SET_CORE })
    AddWordCoding('tiring', { sound: TARGET_SOUND_R, level: LEVEL_1, position: POSITION_VOCALIC_IRE })
    AddWordCoding('untiring', { sound: TARGET_SOUND_R, level: LEVEL_1, position: POSITION_VOCALIC_IRE })
    AddWordCoding('wiring', { sound: TARGET_SOUND_R, level: LEVEL_1, position: POSITION_VOCALIC_IRE, set: WORD_SET_CORE })
    AddWordCoding('wiry', { sound: TARGET_SOUND_R, level: LEVEL_1, position: POSITION_VOCALIC_IRE })

    AddWordCoding('admire', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_VOCALIC_IRE, set: WORD_SET_CORE })
    AddWordCoding('bonfire', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_VOCALIC_IRE, set: WORD_SET_CORE })
    AddWordCoding('buyer', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_VOCALIC_IRE, set: WORD_SET_CORE })
    AddWordCoding('buyers', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_VOCALIC_IRE })
    AddWordCoding('choir', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_VOCALIC_IRE, set: WORD_SET_CORE })
    AddWordCoding('empire', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_VOCALIC_IRE })
    AddWordCoding('expire', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_VOCALIC_IRE })
    AddWordCoding('fire', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_VOCALIC_IRE, set: WORD_SET_CORE })
    AddWordCoding('fire engine', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_VOCALIC_IRE, set: WORD_SET_CORE })
    AddWordCoding('firehouse', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_VOCALIC_IRE, set: WORD_SET_CORE })
    AddWordCoding('firing', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_VOCALIC_IRE })
    AddWordCoding('flier', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_VOCALIC_IRE })
    AddWordCoding('flyer', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_VOCALIC_IRE })
    AddWordCoding('Ireland', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_VOCALIC_IRE, set: WORD_SET_CORE })
    AddWordCoding('liar', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_VOCALIC_IRE, set: WORD_SET_CORE })
    AddWordCoding('mire', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_VOCALIC_IRE })
    AddWordCoding('inquiring', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_VOCALIC_IRE })
    AddWordCoding('inspiring', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_VOCALIC_IRE })
    AddWordCoding('spire', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_VOCALIC_IRE })
    AddWordCoding('umpire', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_VOCALIC_IRE, set: WORD_SET_CORE })
    AddWordCoding('vampire', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_VOCALIC_IRE, set: WORD_SET_CORE })
    AddWordCoding('wire', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_VOCALIC_IRE, set: WORD_SET_CORE })

    AddWordCoding('Cairo', { sound: TARGET_SOUND_R, level: LEVEL_3, position: POSITION_VOCALIC_IRE, set: WORD_SET_CORE })
    AddWordCoding('campfire', { sound: TARGET_SOUND_R, level: LEVEL_3, position: POSITION_VOCALIC_IRE, set: WORD_SET_CORE })
    AddWordCoding('chicken wire', { sound: TARGET_SOUND_R, level: LEVEL_3, position: POSITION_VOCALIC_IRE })
    AddWordCoding('choirs', { sound: TARGET_SOUND_R, level: LEVEL_3, position: POSITION_VOCALIC_IRE })
    AddWordCoding('desirable', { sound: TARGET_SOUND_R, level: LEVEL_3, position: POSITION_VOCALIC_IRE })
    AddWordCoding('fire hose', { sound: TARGET_SOUND_R, level: LEVEL_3, position: POSITION_VOCALIC_IRE, set: WORD_SET_CORE })
    AddWordCoding('fire station', { sound: TARGET_SOUND_R, level: LEVEL_3, position: POSITION_VOCALIC_IRE, set: WORD_SET_CORE })
    AddWordCoding('fireball', { sound: TARGET_SOUND_R, level: LEVEL_3, position: POSITION_VOCALIC_IRE, set: WORD_SET_CORE })
    AddWordCoding('fired', { sound: TARGET_SOUND_R, level: LEVEL_3, position: POSITION_VOCALIC_IRE })
    AddWordCoding('fireplace', { sound: TARGET_SOUND_R, level: LEVEL_3, position: POSITION_VOCALIC_IRE, set: WORD_SET_CORE })
    AddWordCoding('flat tire', { sound: TARGET_SOUND_R, level: LEVEL_3, position: POSITION_VOCALIC_IRE, set: WORD_SET_CORE })
    AddWordCoding('fliers', { sound: TARGET_SOUND_R, level: LEVEL_3, position: POSITION_VOCALIC_IRE })
    AddWordCoding('hieroglyphic', { sound: TARGET_SOUND_R, level: LEVEL_3, position: POSITION_VOCALIC_IRE, set: WORD_SET_CORE })
    AddWordCoding('inquire', { sound: TARGET_SOUND_R, level: LEVEL_3, position: POSITION_VOCALIC_IRE, set: WORD_SET_CORE })
    AddWordCoding('Nairobi', { sound: TARGET_SOUND_R, level: LEVEL_3, position: POSITION_VOCALIC_IRE })
    AddWordCoding('papyrus', { sound: TARGET_SOUND_R, level: LEVEL_3, position: POSITION_VOCALIC_IRE })
    AddWordCoding('pliers', { sound: TARGET_SOUND_R, level: LEVEL_3, position: POSITION_VOCALIC_IRE, set: WORD_SET_CORE })
    AddWordCoding('Roman Empire', { sound: TARGET_SOUND_R, level: LEVEL_3, position: POSITION_VOCALIC_IRE, set: WORD_SET_CORE })
    AddWordCoding('waffle iron', { sound: TARGET_SOUND_R, level: LEVEL_3, position: POSITION_VOCALIC_IRE, set: WORD_SET_CORE })
    AddWordCoding('wild fires', { sound: TARGET_SOUND_R, level: LEVEL_3, position: POSITION_VOCALIC_IRE })

    // Target sound R: Vocalic OR
    AddWordCoding('acorn', { sound: TARGET_SOUND_R, level: LEVEL_1, position: POSITION_VOCALIC_OR, set: WORD_SET_CORE })
    AddWordCoding('adoring', { sound: TARGET_SOUND_R, level: LEVEL_1, position: POSITION_VOCALIC_OR })
    AddWordCoding('boring', { sound: TARGET_SOUND_R, level: LEVEL_1, position: POSITION_VOCALIC_OR, set: WORD_SET_CORE })
    AddWordCoding('chore', { sound: TARGET_SOUND_R, level: LEVEL_1, position: POSITION_VOCALIC_OR, set: WORD_SET_CORE })
    AddWordCoding('chorus', { sound: TARGET_SOUND_R, level: LEVEL_1, position: POSITION_VOCALIC_OR })
    AddWordCoding('coarse', { sound: TARGET_SOUND_R, level: LEVEL_1, position: POSITION_VOCALIC_OR })
    AddWordCoding('coring', { sound: TARGET_SOUND_R, level: LEVEL_1, position: POSITION_VOCALIC_OR })
    AddWordCoding('cork', { sound: TARGET_SOUND_R, level: LEVEL_1, position: POSITION_VOCALIC_OR })
    AddWordCoding('corn', { sound: TARGET_SOUND_R, level: LEVEL_1, position: POSITION_VOCALIC_OR, set: WORD_SET_CORE })
    AddWordCoding('court', { sound: TARGET_SOUND_R, level: LEVEL_1, position: POSITION_VOCALIC_OR })
    AddWordCoding('detouring', { sound: TARGET_SOUND_R, level: LEVEL_1, position: POSITION_VOCALIC_OR })
    AddWordCoding('door', { sound: TARGET_SOUND_R, level: LEVEL_1, position: POSITION_VOCALIC_OR, set: WORD_SET_CORE })
    AddWordCoding('horn', { sound: TARGET_SOUND_R, level: LEVEL_1, position: POSITION_VOCALIC_OR, set: WORD_SET_CORE })
    AddWordCoding('horse', { sound: TARGET_SOUND_R, level: LEVEL_1, position: POSITION_VOCALIC_OR, set: WORD_SET_CORE })
    AddWordCoding('oar', { sound: TARGET_SOUND_R, level: LEVEL_1, position: POSITION_VOCALIC_OR, set: WORD_SET_CORE })
    AddWordCoding('orca', { sound: TARGET_SOUND_R, level: LEVEL_1, position: POSITION_VOCALIC_OR, set: WORD_SET_CORE })
    AddWordCoding('pouring', { sound: TARGET_SOUND_R, level: LEVEL_1, position: POSITION_VOCALIC_OR })
    AddWordCoding('score', { sound: TARGET_SOUND_R, level: LEVEL_1, position: POSITION_VOCALIC_OR })
    AddWordCoding('scoring', { sound: TARGET_SOUND_R, level: LEVEL_1, position: POSITION_VOCALIC_OR })
    AddWordCoding('snoring', { sound: TARGET_SOUND_R, level: LEVEL_1, position: POSITION_VOCALIC_OR })
    AddWordCoding('snorted', { sound: TARGET_SOUND_R, level: LEVEL_1, position: POSITION_VOCALIC_OR })
    AddWordCoding('soaring', { sound: TARGET_SOUND_R, level: LEVEL_1, position: POSITION_VOCALIC_OR })
    AddWordCoding('sort', { sound: TARGET_SOUND_R, level: LEVEL_1, position: POSITION_VOCALIC_OR })
    AddWordCoding('sport', { sound: TARGET_SOUND_R, level: LEVEL_1, position: POSITION_VOCALIC_OR, set: WORD_SET_CORE })
    AddWordCoding('store', { sound: TARGET_SOUND_R, level: LEVEL_1, position: POSITION_VOCALIC_OR, set: WORD_SET_CORE })
    AddWordCoding('stories', { sound: TARGET_SOUND_R, level: LEVEL_1, position: POSITION_VOCALIC_OR })
    AddWordCoding('storing', { sound: TARGET_SOUND_R, level: LEVEL_1, position: POSITION_VOCALIC_OR, set: WORD_SET_CORE })
    AddWordCoding('story', { sound: TARGET_SOUND_R, level: LEVEL_1, position: POSITION_VOCALIC_OR })
    AddWordCoding('touring', { sound: TARGET_SOUND_R, level: LEVEL_1, position: POSITION_VOCALIC_OR })
    AddWordCoding('youre', { sound: TARGET_SOUND_R, level: LEVEL_1, position: POSITION_VOCALIC_OR, text: "you're" })
    AddWordCoding('your', { sound: TARGET_SOUND_R, level: LEVEL_1, position: POSITION_VOCALIC_OR })

    AddWordCoding('board', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_VOCALIC_OR })
    AddWordCoding('boarding', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_VOCALIC_OR })
    AddWordCoding('bored', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_VOCALIC_OR })
    AddWordCoding('dinosaur', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_VOCALIC_OR, set: WORD_SET_CORE })
    AddWordCoding('doorknob', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_VOCALIC_OR })
    AddWordCoding('enormous', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_VOCALIC_OR })
    AddWordCoding('explore', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_VOCALIC_OR })
    AddWordCoding('floor', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_VOCALIC_OR })
    AddWordCoding('Florida', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_VOCALIC_OR, set: WORD_SET_CORE })
    AddWordCoding('for sale', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_VOCALIC_OR })
    AddWordCoding('forest', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_VOCALIC_OR, set: WORD_SET_CORE })
    AddWordCoding('fork', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_VOCALIC_OR, set: WORD_SET_CORE })
    AddWordCoding('forty', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_VOCALIC_OR, set: WORD_SET_CORE })
    AddWordCoding('four', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_VOCALIC_OR, set: WORD_SET_CORE })
    AddWordCoding('fourteen', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_VOCALIC_OR, set: WORD_SET_CORE })
    AddWordCoding('fourth', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_VOCALIC_OR })
    AddWordCoding('Georgia', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_VOCALIC_OR, set: WORD_SET_CORE })
    AddWordCoding('ignore', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_VOCALIC_OR })
    AddWordCoding('ignoring', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_VOCALIC_OR })
    AddWordCoding('important', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_VOCALIC_OR })
    AddWordCoding('indoors', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_VOCALIC_OR })
    AddWordCoding('more', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_VOCALIC_OR, set: WORD_SET_CORE })
    AddWordCoding('morning', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_VOCALIC_OR })
    AddWordCoding('New York', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_VOCALIC_OR, set: WORD_SET_CORE })
    AddWordCoding('north', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_VOCALIC_OR })
    AddWordCoding('orange', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_VOCALIC_OR, set: WORD_SET_CORE })
    AddWordCoding('smore', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_VOCALIC_OR, text: "s'more" })
    AddWordCoding('scorpion', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_VOCALIC_OR })
    AddWordCoding('sea horse', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_VOCALIC_OR })
    AddWordCoding('seashore', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_VOCALIC_OR })
    AddWordCoding('skateboard', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_VOCALIC_OR, set: WORD_SET_CORE })
    AddWordCoding('sports', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_VOCALIC_OR })
    AddWordCoding('tornado', { sound: TARGET_SOUND_R, level: LEVEL_2, position: POSITION_VOCALIC_OR })

    AddWordCoding('apple core', { sound: TARGET_SOUND_R, level: LEVEL_3, position: POSITION_VOCALIC_OR, set: WORD_SET_CORE })
    AddWordCoding('basketball court', { sound: TARGET_SOUND_R, level: LEVEL_3, position: POSITION_VOCALIC_OR, set: WORD_SET_CORE })
    AddWordCoding('before', { sound: TARGET_SOUND_R, level: LEVEL_3, position: POSITION_VOCALIC_OR })
    AddWordCoding('blowtorch', { sound: TARGET_SOUND_R, level: LEVEL_3, position: POSITION_VOCALIC_OR })
    AddWordCoding('bookstore', { sound: TARGET_SOUND_R, level: LEVEL_3, position: POSITION_VOCALIC_OR, set: WORD_SET_CORE })
    AddWordCoding('clipboard', { sound: TARGET_SOUND_R, level: LEVEL_3, position: POSITION_VOCALIC_OR })
    AddWordCoding('corn stalk', { sound: TARGET_SOUND_R, level: LEVEL_3, position: POSITION_VOCALIC_OR })
    AddWordCoding('doorbell', { sound: TARGET_SOUND_R, level: LEVEL_3, position: POSITION_VOCALIC_OR, set: WORD_SET_CORE })
    AddWordCoding('Dorothy', { sound: TARGET_SOUND_R, level: LEVEL_3, position: POSITION_VOCALIC_OR })
    AddWordCoding('downpour', { sound: TARGET_SOUND_R, level: LEVEL_3, position: POSITION_VOCALIC_OR })
    AddWordCoding('exploring', { sound: TARGET_SOUND_R, level: LEVEL_3, position: POSITION_VOCALIC_OR })
    AddWordCoding('floor lamp', { sound: TARGET_SOUND_R, level: LEVEL_3, position: POSITION_VOCALIC_OR })
    AddWordCoding('forklift', { sound: TARGET_SOUND_R, level: LEVEL_3, position: POSITION_VOCALIC_OR, set: WORD_SET_CORE })
    AddWordCoding('fortune cookie', { sound: TARGET_SOUND_R, level: LEVEL_3, position: POSITION_VOCALIC_OR, set: WORD_SET_CORE })
    AddWordCoding('horrible', { sound: TARGET_SOUND_R, level: LEVEL_3, position: POSITION_VOCALIC_OR })
    AddWordCoding('North Dakota', { sound: TARGET_SOUND_R, level: LEVEL_3, position: POSITION_VOCALIC_OR, set: WORD_SET_CORE })
    AddWordCoding('North Pole', { sound: TARGET_SOUND_R, level: LEVEL_3, position: POSITION_VOCALIC_OR })
    AddWordCoding('orange juice', { sound: TARGET_SOUND_R, level: LEVEL_3, position: POSITION_VOCALIC_OR, set: WORD_SET_CORE })
    AddWordCoding('oriole', { sound: TARGET_SOUND_R, level: LEVEL_3, position: POSITION_VOCALIC_OR })
    AddWordCoding('orthodontist', { sound: TARGET_SOUND_R, level: LEVEL_3, position: POSITION_VOCALIC_OR })
    AddWordCoding('outdoors', { sound: TARGET_SOUND_R, level: LEVEL_3, position: POSITION_VOCALIC_OR })
    AddWordCoding('pitchfork', { sound: TARGET_SOUND_R, level: LEVEL_3, position: POSITION_VOCALIC_OR })
    AddWordCoding('popcorn', { sound: TARGET_SOUND_R, level: LEVEL_3, position: POSITION_VOCALIC_OR, set: WORD_SET_CORE })
    AddWordCoding('Singapore', { sound: TARGET_SOUND_R, level: LEVEL_3, position: POSITION_VOCALIC_OR })
    AddWordCoding('snowboard', { sound: TARGET_SOUND_R, level: LEVEL_3, position: POSITION_VOCALIC_OR, set: WORD_SET_CORE })
    AddWordCoding('snowstorm', { sound: TARGET_SOUND_R, level: LEVEL_3, position: POSITION_VOCALIC_OR, set: WORD_SET_CORE })
    AddWordCoding('storybook', { sound: TARGET_SOUND_R, level: LEVEL_3, position: POSITION_VOCALIC_OR })
    AddWordCoding('swinging door', { sound: TARGET_SOUND_R, level: LEVEL_3, position: POSITION_VOCALIC_OR })
    AddWordCoding('Victoria', { sound: TARGET_SOUND_R, level: LEVEL_3, position: POSITION_VOCALIC_OR, set: WORD_SET_CORE })
    AddWordCoding('wild boar', { sound: TARGET_SOUND_R, level: LEVEL_3, position: POSITION_VOCALIC_OR })

    // --------------------------------------------------------------------------------------------------
    //   Target sound S
    // --------------------------------------------------------------------------------------------------

    // Target sound S: Initial
    AddWordCoding('sad', { sound: TARGET_SOUND_S, level: LEVEL_1, position: POSITION_INITIAL, set: WORD_SET_CORE })
    AddWordCoding('salt', { sound: TARGET_SOUND_S, level: LEVEL_1, position: POSITION_INITIAL, set: WORD_SET_CORE })
    AddWordCoding('Sam', { sound: TARGET_SOUND_S, level: LEVEL_1, position: POSITION_INITIAL, set: WORD_SET_CORE })
    AddWordCoding('sand', { sound: TARGET_SOUND_S, level: LEVEL_1, position: POSITION_INITIAL, set: WORD_SET_CORE })
    AddWordCoding('say', { sound: TARGET_SOUND_S, level: LEVEL_1, position: POSITION_INITIAL })
    AddWordCoding('see', { sound: TARGET_SOUND_S, level: LEVEL_1, position: POSITION_INITIAL, set: WORD_SET_CORE })
    AddWordCoding('sell', { sound: TARGET_SOUND_S, level: LEVEL_1, position: POSITION_INITIAL })
    AddWordCoding('sew', { sound: TARGET_SOUND_S, level: LEVEL_1, position: POSITION_INITIAL })
    AddWordCoding('sick', { sound: TARGET_SOUND_S, level: LEVEL_1, position: POSITION_INITIAL, set: WORD_SET_CORE })
    AddWordCoding('sigh', { sound: TARGET_SOUND_S, level: LEVEL_1, position: POSITION_INITIAL })
    AddWordCoding('sign', { sound: TARGET_SOUND_S, level: LEVEL_1, position: POSITION_INITIAL })
    AddWordCoding('sing', { sound: TARGET_SOUND_S, level: LEVEL_1, position: POSITION_INITIAL, set: WORD_SET_CORE })
    AddWordCoding('sit', { sound: TARGET_SOUND_S, level: LEVEL_1, position: POSITION_INITIAL, set: WORD_SET_CORE })
    AddWordCoding('so', { sound: TARGET_SOUND_S, level: LEVEL_1, position: POSITION_INITIAL })
    AddWordCoding('soap', { sound: TARGET_SOUND_S, level: LEVEL_1, position: POSITION_INITIAL, set: WORD_SET_CORE })
    AddWordCoding('sock', { sound: TARGET_SOUND_S, level: LEVEL_1, position: POSITION_INITIAL, set: WORD_SET_CORE })
    AddWordCoding('soup', { sound: TARGET_SOUND_S, level: LEVEL_1, position: POSITION_INITIAL, set: WORD_SET_CORE })
    AddWordCoding('sub', { sound: TARGET_SOUND_S, level: LEVEL_1, position: POSITION_INITIAL })
    AddWordCoding('suit', { sound: TARGET_SOUND_S, level: LEVEL_1, position: POSITION_INITIAL })
    AddWordCoding('sum', { sound: TARGET_SOUND_S, level: LEVEL_1, position: POSITION_INITIAL })
    AddWordCoding('sun', { sound: TARGET_SOUND_S, level: LEVEL_1, position: POSITION_INITIAL, set: WORD_SET_CORE })

    AddWordCoding('ceiling', { sound: TARGET_SOUND_S, level: LEVEL_2, position: POSITION_INITIAL })
    AddWordCoding('circle', { sound: TARGET_SOUND_S, level: LEVEL_2, position: POSITION_INITIAL, set: WORD_SET_CORE })
    AddWordCoding('city', { sound: TARGET_SOUND_S, level: LEVEL_2, position: POSITION_INITIAL })
    AddWordCoding('cycle', { sound: TARGET_SOUND_S, level: LEVEL_2, position: POSITION_INITIAL })
    AddWordCoding('safe', { sound: TARGET_SOUND_S, level: LEVEL_2, position: POSITION_INITIAL })
    AddWordCoding('salad', { sound: TARGET_SOUND_S, level: LEVEL_2, position: POSITION_INITIAL, set: WORD_SET_CORE })
    AddWordCoding('salty', { sound: TARGET_SOUND_S, level: LEVEL_2, position: POSITION_INITIAL })
    AddWordCoding('salute', { sound: TARGET_SOUND_S, level: LEVEL_2, position: POSITION_INITIAL })
    AddWordCoding('sandal', { sound: TARGET_SOUND_S, level: LEVEL_2, position: POSITION_INITIAL, set: WORD_SET_CORE })
    AddWordCoding('Sarah', { sound: TARGET_SOUND_S, level: LEVEL_2, position: POSITION_INITIAL, set: WORD_SET_CORE })
    AddWordCoding('Saturn', { sound: TARGET_SOUND_S, level: LEVEL_2, position: POSITION_INITIAL })
    AddWordCoding('second', { sound: TARGET_SOUND_S, level: LEVEL_2, position: POSITION_INITIAL, set: WORD_SET_CORE })
    AddWordCoding('seven', { sound: TARGET_SOUND_S, level: LEVEL_2, position: POSITION_INITIAL, set: WORD_SET_CORE })
    AddWordCoding('silly', { sound: TARGET_SOUND_S, level: LEVEL_2, position: POSITION_INITIAL, set: WORD_SET_CORE })
    AddWordCoding('singer', { sound: TARGET_SOUND_S, level: LEVEL_2, position: POSITION_INITIAL })
    AddWordCoding('soccer', { sound: TARGET_SOUND_S, level: LEVEL_2, position: POSITION_INITIAL, set: WORD_SET_CORE })
    AddWordCoding('soda', { sound: TARGET_SOUND_S, level: LEVEL_2, position: POSITION_INITIAL, set: WORD_SET_CORE })
    AddWordCoding('sofa', { sound: TARGET_SOUND_S, level: LEVEL_2, position: POSITION_INITIAL, set: WORD_SET_CORE })
    AddWordCoding('solo', { sound: TARGET_SOUND_S, level: LEVEL_2, position: POSITION_INITIAL })
    AddWordCoding('sorry', { sound: TARGET_SOUND_S, level: LEVEL_2, position: POSITION_INITIAL })
    AddWordCoding('summer', { sound: TARGET_SOUND_S, level: LEVEL_2, position: POSITION_INITIAL, set: WORD_SET_CORE })
    AddWordCoding('sundae', { sound: TARGET_SOUND_S, level: LEVEL_2, position: POSITION_INITIAL })
    AddWordCoding('Sunday', { sound: TARGET_SOUND_S, level: LEVEL_2, position: POSITION_INITIAL, set: WORD_SET_CORE })
    AddWordCoding('super', { sound: TARGET_SOUND_S, level: LEVEL_2, position: POSITION_INITIAL })

    AddWordCoding('celebrate', { sound: TARGET_SOUND_S, level: LEVEL_3, position: POSITION_INITIAL })
    AddWordCoding('cereal', { sound: TARGET_SOUND_S, level: LEVEL_3, position: POSITION_INITIAL, set: WORD_SET_CORE })
    AddWordCoding('sailboat', { sound: TARGET_SOUND_S, level: LEVEL_3, position: POSITION_INITIAL, set: WORD_SET_CORE })
    AddWordCoding('satisfied', { sound: TARGET_SOUND_S, level: LEVEL_3, position: POSITION_INITIAL })
    AddWordCoding('Saturday', { sound: TARGET_SOUND_S, level: LEVEL_3, position: POSITION_INITIAL })
    AddWordCoding('sea turtle', { sound: TARGET_SOUND_S, level: LEVEL_3, position: POSITION_INITIAL, set: WORD_SET_CORE })
    AddWordCoding('seashell', { sound: TARGET_SOUND_S, level: LEVEL_3, position: POSITION_INITIAL, set: WORD_SET_CORE })
    AddWordCoding('seaweed', { sound: TARGET_SOUND_S, level: LEVEL_3, position: POSITION_INITIAL, set: WORD_SET_CORE })
    AddWordCoding('September', { sound: TARGET_SOUND_S, level: LEVEL_3, position: POSITION_INITIAL, set: WORD_SET_CORE })
    AddWordCoding('sidewalk', { sound: TARGET_SOUND_S, level: LEVEL_3, position: POSITION_INITIAL })
    AddWordCoding('sister', { sound: TARGET_SOUND_S, level: LEVEL_3, position: POSITION_INITIAL, set: WORD_SET_CORE })
    AddWordCoding('six', { sound: TARGET_SOUND_S, level: LEVEL_3, position: POSITION_INITIAL, set: WORD_SET_CORE })
    AddWordCoding('size', { sound: TARGET_SOUND_S, level: LEVEL_3, position: POSITION_INITIAL, set: WORD_SET_CORE })
    AddWordCoding('sizzle', { sound: TARGET_SOUND_S, level: LEVEL_3, position: POSITION_INITIAL })
    AddWordCoding('soothe', { sound: TARGET_SOUND_S, level: LEVEL_3, position: POSITION_INITIAL })
    AddWordCoding('south', { sound: TARGET_SOUND_S, level: LEVEL_3, position: POSITION_INITIAL })
    AddWordCoding('souvenir', { sound: TARGET_SOUND_S, level: LEVEL_3, position: POSITION_INITIAL })
    AddWordCoding('subway', { sound: TARGET_SOUND_S, level: LEVEL_3, position: POSITION_INITIAL })
    AddWordCoding('such', { sound: TARGET_SOUND_S, level: LEVEL_3, position: POSITION_INITIAL })
    AddWordCoding('summertime', { sound: TARGET_SOUND_S, level: LEVEL_3, position: POSITION_INITIAL })
    AddWordCoding('sunflower', { sound: TARGET_SOUND_S, level: LEVEL_3, position: POSITION_INITIAL, set: WORD_SET_CORE })
    AddWordCoding('sunshine', { sound: TARGET_SOUND_S, level: LEVEL_3, position: POSITION_INITIAL })
    AddWordCoding('surfboard', { sound: TARGET_SOUND_S, level: LEVEL_3, position: POSITION_INITIAL, set: WORD_SET_CORE })
    AddWordCoding('sushi', { sound: TARGET_SOUND_S, level: LEVEL_3, position: POSITION_INITIAL, set: WORD_SET_CORE })

    // Target sound S: Medial
    AddWordCoding('assign', { sound: TARGET_SOUND_S, level: LEVEL_1, position: POSITION_MEDIAL })
    AddWordCoding('awesome', { sound: TARGET_SOUND_S, level: LEVEL_1, position: POSITION_MEDIAL })
    AddWordCoding('basic', { sound: TARGET_SOUND_S, level: LEVEL_1, position: POSITION_MEDIAL })
    AddWordCoding('basin', { sound: TARGET_SOUND_S, level: LEVEL_1, position: POSITION_MEDIAL })
    AddWordCoding('bassoon', { sound: TARGET_SOUND_S, level: LEVEL_1, position: POSITION_MEDIAL })
    AddWordCoding('beside', { sound: TARGET_SOUND_S, level: LEVEL_1, position: POSITION_MEDIAL, set: WORD_SET_CORE })
    AddWordCoding('bison', { sound: TARGET_SOUND_S, level: LEVEL_1, position: POSITION_MEDIAL })
    AddWordCoding('bossy', { sound: TARGET_SOUND_S, level: LEVEL_1, position: POSITION_MEDIAL, set: WORD_SET_CORE })
    AddWordCoding('Casey', { sound: TARGET_SOUND_S, level: LEVEL_1, position: POSITION_MEDIAL, set: WORD_SET_CORE })
    AddWordCoding('castle', { sound: TARGET_SOUND_S, level: LEVEL_1, position: POSITION_MEDIAL, set: WORD_SET_CORE })
    AddWordCoding('gossip', { sound: TARGET_SOUND_S, level: LEVEL_1, position: POSITION_MEDIAL })
    AddWordCoding('guessing', { sound: TARGET_SOUND_S, level: LEVEL_1, position: POSITION_MEDIAL })
    AddWordCoding('hissing', { sound: TARGET_SOUND_S, level: LEVEL_1, position: POSITION_MEDIAL, set: WORD_SET_CORE })
    AddWordCoding('icing', { sound: TARGET_SOUND_S, level: LEVEL_1, position: POSITION_MEDIAL })
    AddWordCoding('icy', { sound: TARGET_SOUND_S, level: LEVEL_1, position: POSITION_MEDIAL })
    AddWordCoding('lesson', { sound: TARGET_SOUND_S, level: LEVEL_1, position: POSITION_MEDIAL })
    AddWordCoding('Lisa', { sound: TARGET_SOUND_S, level: LEVEL_1, position: POSITION_MEDIAL })
    AddWordCoding('listen', { sound: TARGET_SOUND_S, level: LEVEL_1, position: POSITION_MEDIAL })
    AddWordCoding('Lucy', { sound: TARGET_SOUND_S, level: LEVEL_1, position: POSITION_MEDIAL, set: WORD_SET_CORE })
    AddWordCoding('messy', { sound: TARGET_SOUND_S, level: LEVEL_1, position: POSITION_MEDIAL, set: WORD_SET_CORE })
    AddWordCoding('missing', { sound: TARGET_SOUND_S, level: LEVEL_1, position: POSITION_MEDIAL, set: WORD_SET_CORE })
    AddWordCoding('mossy', { sound: TARGET_SOUND_S, level: LEVEL_1, position: POSITION_MEDIAL })
    AddWordCoding('muscle', { sound: TARGET_SOUND_S, level: LEVEL_1, position: POSITION_MEDIAL, set: WORD_SET_CORE })
    AddWordCoding('mussel', { sound: TARGET_SOUND_S, level: LEVEL_1, position: POSITION_MEDIAL })
    AddWordCoding('pacing', { sound: TARGET_SOUND_S, level: LEVEL_1, position: POSITION_MEDIAL })
    AddWordCoding('possum', { sound: TARGET_SOUND_S, level: LEVEL_1, position: POSITION_MEDIAL, set: WORD_SET_CORE })
    AddWordCoding('racer', { sound: TARGET_SOUND_S, level: LEVEL_1, position: POSITION_MEDIAL })
    AddWordCoding('racing', { sound: TARGET_SOUND_S, level: LEVEL_1, position: POSITION_MEDIAL, set: WORD_SET_CORE })
    AddWordCoding('receipt', { sound: TARGET_SOUND_S, level: LEVEL_1, position: POSITION_MEDIAL })
    AddWordCoding('whistle', { sound: TARGET_SOUND_S, level: LEVEL_1, position: POSITION_MEDIAL, set: WORD_SET_CORE })

    AddWordCoding('baseball', { sound: TARGET_SOUND_S, level: LEVEL_2, position: POSITION_MEDIAL, set: WORD_SET_CORE })
    AddWordCoding('basement', { sound: TARGET_SOUND_S, level: LEVEL_2, position: POSITION_MEDIAL })
    AddWordCoding('Betsy', { sound: TARGET_SOUND_S, level: LEVEL_2, position: POSITION_MEDIAL })
    AddWordCoding('blossom', { sound: TARGET_SOUND_S, level: LEVEL_2, position: POSITION_MEDIAL })
    AddWordCoding('bracelet', { sound: TARGET_SOUND_S, level: LEVEL_2, position: POSITION_MEDIAL, set: WORD_SET_CORE })
    AddWordCoding('closer', { sound: TARGET_SOUND_S, level: LEVEL_2, position: POSITION_MEDIAL })
    AddWordCoding('crescent', { sound: TARGET_SOUND_S, level: LEVEL_2, position: POSITION_MEDIAL })
    AddWordCoding('crossing', { sound: TARGET_SOUND_S, level: LEVEL_2, position: POSITION_MEDIAL })
    AddWordCoding('dancer', { sound: TARGET_SOUND_S, level: LEVEL_2, position: POSITION_MEDIAL, set: WORD_SET_CORE })
    AddWordCoding('dresser', { sound: TARGET_SOUND_S, level: LEVEL_2, position: POSITION_MEDIAL })
    AddWordCoding('eraser', { sound: TARGET_SOUND_S, level: LEVEL_2, position: POSITION_MEDIAL, set: WORD_SET_CORE })
    AddWordCoding('eyesight', { sound: TARGET_SOUND_S, level: LEVEL_2, position: POSITION_MEDIAL })
    AddWordCoding('faces', { sound: TARGET_SOUND_S, level: LEVEL_2, position: POSITION_MEDIAL, set: WORD_SET_CORE })
    AddWordCoding('fancy', { sound: TARGET_SOUND_S, level: LEVEL_2, position: POSITION_MEDIAL, set: WORD_SET_CORE })
    AddWordCoding('flossing', { sound: TARGET_SOUND_S, level: LEVEL_2, position: POSITION_MEDIAL })
    AddWordCoding('fossil', { sound: TARGET_SOUND_S, level: LEVEL_2, position: POSITION_MEDIAL })
    AddWordCoding('fussy', { sound: TARGET_SOUND_S, level: LEVEL_2, position: POSITION_MEDIAL })
    AddWordCoding('grassy', { sound: TARGET_SOUND_S, level: LEVEL_2, position: POSITION_MEDIAL })
    AddWordCoding('greasy', { sound: TARGET_SOUND_S, level: LEVEL_2, position: POSITION_MEDIAL })
    AddWordCoding('ice cream', { sound: TARGET_SOUND_S, level: LEVEL_2, position: POSITION_MEDIAL, set: WORD_SET_CORE })
    AddWordCoding('iceberg', { sound: TARGET_SOUND_S, level: LEVEL_2, position: POSITION_MEDIAL })
    AddWordCoding('insect', { sound: TARGET_SOUND_S, level: LEVEL_2, position: POSITION_MEDIAL, set: WORD_SET_CORE })
    AddWordCoding('inside', { sound: TARGET_SOUND_S, level: LEVEL_2, position: POSITION_MEDIAL, set: WORD_SET_CORE })
    AddWordCoding('mistake', { sound: TARGET_SOUND_S, level: LEVEL_2, position: POSITION_MEDIAL })
    AddWordCoding('outside', { sound: TARGET_SOUND_S, level: LEVEL_2, position: POSITION_MEDIAL, set: WORD_SET_CORE })
    AddWordCoding('pencil', { sound: TARGET_SOUND_S, level: LEVEL_2, position: POSITION_MEDIAL, set: WORD_SET_CORE })
    AddWordCoding('person', { sound: TARGET_SOUND_S, level: LEVEL_2, position: POSITION_MEDIAL, set: WORD_SET_CORE })
    AddWordCoding('race car', { sound: TARGET_SOUND_S, level: LEVEL_2, position: POSITION_MEDIAL })

    AddWordCoding('bathing suit', { sound: TARGET_SOUND_S, level: LEVEL_3, position: POSITION_MEDIAL, set: WORD_SET_CORE })
    AddWordCoding('bicycle', { sound: TARGET_SOUND_S, level: LEVEL_3, position: POSITION_MEDIAL, set: WORD_SET_CORE })
    AddWordCoding('braces', { sound: TARGET_SOUND_S, level: LEVEL_3, position: POSITION_MEDIAL })
    AddWordCoding('chasing', { sound: TARGET_SOUND_S, level: LEVEL_3, position: POSITION_MEDIAL })
    AddWordCoding('December', { sound: TARGET_SOUND_S, level: LEVEL_3, position: POSITION_MEDIAL, set: WORD_SET_CORE })
    AddWordCoding('dinosaur', { sound: TARGET_SOUND_S, level: LEVEL_3, position: POSITION_MEDIAL, set: WORD_SET_CORE })
    AddWordCoding('disappear', { sound: TARGET_SOUND_S, level: LEVEL_3, position: POSITION_MEDIAL })
    AddWordCoding('faucet', { sound: TARGET_SOUND_S, level: LEVEL_3, position: POSITION_MEDIAL })
    AddWordCoding('ferris wheel', { sound: TARGET_SOUND_S, level: LEVEL_3, position: POSITION_MEDIAL, set: WORD_SET_CORE })
    AddWordCoding('gasoline', { sound: TARGET_SOUND_S, level: LEVEL_3, position: POSITION_MEDIAL })
    AddWordCoding('glasses', { sound: TARGET_SOUND_S, level: LEVEL_3, position: POSITION_MEDIAL })
    AddWordCoding('grasshopper', { sound: TARGET_SOUND_S, level: LEVEL_3, position: POSITION_MEDIAL, set: WORD_SET_CORE })
    AddWordCoding('groceries', { sound: TARGET_SOUND_S, level: LEVEL_3, position: POSITION_MEDIAL })
    AddWordCoding('Jason', { sound: TARGET_SOUND_S, level: LEVEL_3, position: POSITION_MEDIAL })
    AddWordCoding('jealousy', { sound: TARGET_SOUND_S, level: LEVEL_3, position: POSITION_MEDIAL })
    AddWordCoding('mason jar', { sound: TARGET_SOUND_S, level: LEVEL_3, position: POSITION_MEDIAL })
    AddWordCoding('massive', { sound: TARGET_SOUND_S, level: LEVEL_3, position: POSITION_MEDIAL })
    AddWordCoding('medicine', { sound: TARGET_SOUND_S, level: LEVEL_3, position: POSITION_MEDIAL, set: WORD_SET_CORE })
    AddWordCoding('message', { sound: TARGET_SOUND_S, level: LEVEL_3, position: POSITION_MEDIAL })
    AddWordCoding('messenger', { sound: TARGET_SOUND_S, level: LEVEL_3, position: POSITION_MEDIAL })
    AddWordCoding('Minnesota', { sound: TARGET_SOUND_S, level: LEVEL_3, position: POSITION_MEDIAL, set: WORD_SET_CORE })
    AddWordCoding('motorcycle', { sound: TARGET_SOUND_S, level: LEVEL_3, position: POSITION_MEDIAL })
    AddWordCoding('passenger', { sound: TARGET_SOUND_S, level: LEVEL_3, position: POSITION_MEDIAL })
    AddWordCoding('pharmacy', { sound: TARGET_SOUND_S, level: LEVEL_3, position: POSITION_MEDIAL })
    AddWordCoding('pieces', { sound: TARGET_SOUND_S, level: LEVEL_3, position: POSITION_MEDIAL })
    AddWordCoding('popsicle', { sound: TARGET_SOUND_S, level: LEVEL_3, position: POSITION_MEDIAL, set: WORD_SET_CORE })
    AddWordCoding('princess', { sound: TARGET_SOUND_S, level: LEVEL_3, position: POSITION_MEDIAL, set: WORD_SET_CORE })
    AddWordCoding('principal', { sound: TARGET_SOUND_S, level: LEVEL_3, position: POSITION_MEDIAL })
    AddWordCoding('professor', { sound: TARGET_SOUND_S, level: LEVEL_3, position: POSITION_MEDIAL })
    AddWordCoding('recipe', { sound: TARGET_SOUND_S, level: LEVEL_3, position: POSITION_MEDIAL })
    AddWordCoding('recycle', { sound: TARGET_SOUND_S, level: LEVEL_3, position: POSITION_MEDIAL, set: WORD_SET_CORE })
    AddWordCoding('rhinoceros', { sound: TARGET_SOUND_S, level: LEVEL_3, position: POSITION_MEDIAL })
    AddWordCoding('Tennessee', { sound: TARGET_SOUND_S, level: LEVEL_3, position: POSITION_MEDIAL, set: WORD_SET_CORE })
    AddWordCoding('unicycle', { sound: TARGET_SOUND_S, level: LEVEL_3, position: POSITION_MEDIAL })
    AddWordCoding('voices', { sound: TARGET_SOUND_S, level: LEVEL_3, position: POSITION_MEDIAL })

    // Target sound S: Final
    AddWordCoding('ace', { sound: TARGET_SOUND_S, level: LEVEL_1, position: POSITION_FINAL })
    AddWordCoding('base', { sound: TARGET_SOUND_S, level: LEVEL_1, position: POSITION_FINAL })
    AddWordCoding('bats', { sound: TARGET_SOUND_S, level: LEVEL_1, position: POSITION_FINAL, set: WORD_SET_CORE })
    AddWordCoding('bus', { sound: TARGET_SOUND_S, level: LEVEL_1, position: POSITION_FINAL, set: WORD_SET_CORE })
    AddWordCoding('cats', { sound: TARGET_SOUND_S, level: LEVEL_1, position: POSITION_FINAL, set: WORD_SET_CORE })
    AddWordCoding('cuts', { sound: TARGET_SOUND_S, level: LEVEL_1, position: POSITION_FINAL, set: WORD_SET_CORE })
    AddWordCoding('dice', { sound: TARGET_SOUND_S, level: LEVEL_1, position: POSITION_FINAL, set: WORD_SET_CORE })
    AddWordCoding('gas', { sound: TARGET_SOUND_S, level: LEVEL_1, position: POSITION_FINAL })
    AddWordCoding('geese', { sound: TARGET_SOUND_S, level: LEVEL_1, position: POSITION_FINAL })
    AddWordCoding('hats', { sound: TARGET_SOUND_S, level: LEVEL_1, position: POSITION_FINAL, set: WORD_SET_CORE })
    AddWordCoding('hiss', { sound: TARGET_SOUND_S, level: LEVEL_1, position: POSITION_FINAL })
    AddWordCoding('hits', { sound: TARGET_SOUND_S, level: LEVEL_1, position: POSITION_FINAL })
    AddWordCoding('horse', { sound: TARGET_SOUND_S, level: LEVEL_1, position: POSITION_FINAL, set: WORD_SET_CORE })
    AddWordCoding('house', { sound: TARGET_SOUND_S, level: LEVEL_1, position: POSITION_FINAL, set: WORD_SET_CORE })
    AddWordCoding('ice', { sound: TARGET_SOUND_S, level: LEVEL_1, position: POSITION_FINAL, set: WORD_SET_CORE })
    AddWordCoding('loose', { sound: TARGET_SOUND_S, level: LEVEL_1, position: POSITION_FINAL })
    AddWordCoding('loss', { sound: TARGET_SOUND_S, level: LEVEL_1, position: POSITION_FINAL })
    AddWordCoding('mice', { sound: TARGET_SOUND_S, level: LEVEL_1, position: POSITION_FINAL })
    AddWordCoding('moose', { sound: TARGET_SOUND_S, level: LEVEL_1, position: POSITION_FINAL, set: WORD_SET_CORE })
    AddWordCoding('mouse', { sound: TARGET_SOUND_S, level: LEVEL_1, position: POSITION_FINAL, set: WORD_SET_CORE })
    AddWordCoding('nice', { sound: TARGET_SOUND_S, level: LEVEL_1, position: POSITION_FINAL })
    AddWordCoding('nuts', { sound: TARGET_SOUND_S, level: LEVEL_1, position: POSITION_FINAL })
    AddWordCoding('pace', { sound: TARGET_SOUND_S, level: LEVEL_1, position: POSITION_FINAL })
    AddWordCoding('peace', { sound: TARGET_SOUND_S, level: LEVEL_1, position: POSITION_FINAL })
    AddWordCoding('pets', { sound: TARGET_SOUND_S, level: LEVEL_1, position: POSITION_FINAL })
    AddWordCoding('race', { sound: TARGET_SOUND_S, level: LEVEL_1, position: POSITION_FINAL })
    AddWordCoding('rice', { sound: TARGET_SOUND_S, level: LEVEL_1, position: POSITION_FINAL })
    AddWordCoding('toss', { sound: TARGET_SOUND_S, level: LEVEL_1, position: POSITION_FINAL })
    AddWordCoding('yes', { sound: TARGET_SOUND_S, level: LEVEL_1, position: POSITION_FINAL, set: WORD_SET_CORE })

    AddWordCoding('across', { sound: TARGET_SOUND_S, level: LEVEL_2, position: POSITION_FINAL })
    AddWordCoding('address', { sound: TARGET_SOUND_S, level: LEVEL_2, position: POSITION_FINAL })
    AddWordCoding('birdhouse', { sound: TARGET_SOUND_S, level: LEVEL_2, position: POSITION_FINAL, set: WORD_SET_CORE })
    AddWordCoding('bookcase', { sound: TARGET_SOUND_S, level: LEVEL_2, position: POSITION_FINAL, set: WORD_SET_CORE })
    AddWordCoding('caboose', { sound: TARGET_SOUND_S, level: LEVEL_2, position: POSITION_FINAL })
    AddWordCoding('cactus', { sound: TARGET_SOUND_S, level: LEVEL_2, position: POSITION_FINAL, set: WORD_SET_CORE })
    AddWordCoding('coarse', { sound: TARGET_SOUND_S, level: LEVEL_2, position: POSITION_FINAL })
    AddWordCoding('compass', { sound: TARGET_SOUND_S, level: LEVEL_2, position: POSITION_FINAL, set: WORD_SET_CORE })
    AddWordCoding('doghouse', { sound: TARGET_SOUND_S, level: LEVEL_2, position: POSITION_FINAL, set: WORD_SET_CORE })
    AddWordCoding('dress', { sound: TARGET_SOUND_S, level: LEVEL_2, position: POSITION_FINAL, set: WORD_SET_CORE })
    AddWordCoding('erase', { sound: TARGET_SOUND_S, level: LEVEL_2, position: POSITION_FINAL })
    AddWordCoding('face', { sound: TARGET_SOUND_S, level: LEVEL_2, position: POSITION_FINAL })
    AddWordCoding('famous', { sound: TARGET_SOUND_S, level: LEVEL_2, position: POSITION_FINAL })
    AddWordCoding('fence', { sound: TARGET_SOUND_S, level: LEVEL_2, position: POSITION_FINAL, set: WORD_SET_CORE })
    AddWordCoding('firehouse', { sound: TARGET_SOUND_S, level: LEVEL_2, position: POSITION_FINAL })
    AddWordCoding('fleece', { sound: TARGET_SOUND_S, level: LEVEL_2, position: POSITION_FINAL })  // jacket
    AddWordCoding('floss', { sound: TARGET_SOUND_S, level: LEVEL_2, position: POSITION_FINAL })
    AddWordCoding('glass', { sound: TARGET_SOUND_S, level: LEVEL_2, position: POSITION_FINAL })
    AddWordCoding('grapes', { sound: TARGET_SOUND_S, level: LEVEL_2, position: POSITION_FINAL, set: WORD_SET_CORE })
    AddWordCoding('grass', { sound: TARGET_SOUND_S, level: LEVEL_2, position: POSITION_FINAL })
    AddWordCoding('lettuce', { sound: TARGET_SOUND_S, level: LEVEL_2, position: POSITION_FINAL })
    AddWordCoding('lighthouse', { sound: TARGET_SOUND_S, level: LEVEL_2, position: POSITION_FINAL, set: WORD_SET_CORE })
    AddWordCoding('lips', { sound: TARGET_SOUND_S, level: LEVEL_2, position: POSITION_FINAL })
    AddWordCoding('minus', { sound: TARGET_SOUND_S, level: LEVEL_2, position: POSITION_FINAL })
    AddWordCoding('necklace', { sound: TARGET_SOUND_S, level: LEVEL_2, position: POSITION_FINAL, set: WORD_SET_CORE })
    AddWordCoding('Paris', { sound: TARGET_SOUND_S, level: LEVEL_2, position: POSITION_FINAL })
    AddWordCoding('practice', { sound: TARGET_SOUND_S, level: LEVEL_2, position: POSITION_FINAL })
    AddWordCoding('price', { sound: TARGET_SOUND_S, level: LEVEL_2, position: POSITION_FINAL })
    AddWordCoding('prince', { sound: TARGET_SOUND_S, level: LEVEL_2, position: POSITION_FINAL, set: WORD_SET_CORE })
    AddWordCoding('raindrops', { sound: TARGET_SOUND_S, level: LEVEL_2, position: POSITION_FINAL })
    AddWordCoding('tennis', { sound: TARGET_SOUND_S, level: LEVEL_2, position: POSITION_FINAL, set: WORD_SET_CORE })
    AddWordCoding('twice', { sound: TARGET_SOUND_S, level: LEVEL_2, position: POSITION_FINAL })
    AddWordCoding('six', { sound: TARGET_SOUND_S, level: LEVEL_2, position: POSITION_FINAL })
    AddWordCoding('vase', { sound: TARGET_SOUND_S, level: LEVEL_2, position: POSITION_FINAL })
    AddWordCoding('voice', { sound: TARGET_SOUND_S, level: LEVEL_2, position: POSITION_FINAL })
    AddWordCoding('walrus', { sound: TARGET_SOUND_S, level: LEVEL_2, position: POSITION_FINAL })

    AddWordCoding('apple juice', { sound: TARGET_SOUND_S, level: LEVEL_3, position: POSITION_FINAL, set: WORD_SET_CORE })
    AddWordCoding('asparagus', { sound: TARGET_SOUND_S, level: LEVEL_3, position: POSITION_FINAL })
    AddWordCoding('briefcase', { sound: TARGET_SOUND_S, level: LEVEL_3, position: POSITION_FINAL })
    AddWordCoding('brussels sprouts', { sound: TARGET_SOUND_S, level: LEVEL_3, position: POSITION_FINAL })
    AddWordCoding('chase', { sound: TARGET_SOUND_S, level: LEVEL_3, position: POSITION_FINAL, set: WORD_SET_CORE })
    AddWordCoding('chess', { sound: TARGET_SOUND_S, level: LEVEL_3, position: POSITION_FINAL })
    AddWordCoding('circus', { sound: TARGET_SOUND_S, level: LEVEL_3, position: POSITION_FINAL, set: WORD_SET_CORE })
    AddWordCoding('coffeehouse', { sound: TARGET_SOUND_S, level: LEVEL_3, position: POSITION_FINAL })
    AddWordCoding('contagious', { sound: TARGET_SOUND_S, level: LEVEL_3, position: POSITION_FINAL })
    AddWordCoding('dangerous', { sound: TARGET_SOUND_S, level: LEVEL_3, position: POSITION_FINAL, set: WORD_SET_CORE })
    AddWordCoding('delicious', { sound: TARGET_SOUND_S, level: LEVEL_3, position: POSITION_FINAL })
    AddWordCoding('device', { sound: TARGET_SOUND_S, level: LEVEL_3, position: POSITION_FINAL })
    AddWordCoding('enormous', { sound: TARGET_SOUND_S, level: LEVEL_3, position: POSITION_FINAL })
    AddWordCoding('fabulous', { sound: TARGET_SOUND_S, level: LEVEL_3, position: POSITION_FINAL })
    AddWordCoding('fitness', { sound: TARGET_SOUND_S, level: LEVEL_3, position: POSITION_FINAL })
    AddWordCoding('furious', { sound: TARGET_SOUND_S, level: LEVEL_3, position: POSITION_FINAL })
    AddWordCoding('generous', { sound: TARGET_SOUND_S, level: LEVEL_3, position: POSITION_FINAL })
    AddWordCoding('genius', { sound: TARGET_SOUND_S, level: LEVEL_3, position: POSITION_FINAL })
    AddWordCoding('hippopotamus', { sound: TARGET_SOUND_S, level: LEVEL_3, position: POSITION_FINAL, set: WORD_SET_CORE })
    AddWordCoding('ingredients', { sound: TARGET_SOUND_S, level: LEVEL_3, position: POSITION_FINAL })
    AddWordCoding('jealous', { sound: TARGET_SOUND_S, level: LEVEL_3, position: POSITION_FINAL })
    AddWordCoding('joyous', { sound: TARGET_SOUND_S, level: LEVEL_3, position: POSITION_FINAL })
    AddWordCoding('lemon juice', { sound: TARGET_SOUND_S, level: LEVEL_3, position: POSITION_FINAL })
    AddWordCoding('magnifying glass', { sound: TARGET_SOUND_S, level: LEVEL_3, position: POSITION_FINAL, set: WORD_SET_CORE })
    AddWordCoding('misplace', { sound: TARGET_SOUND_S, level: LEVEL_3, position: POSITION_FINAL })
    AddWordCoding('nervous', { sound: TARGET_SOUND_S, level: LEVEL_3, position: POSITION_FINAL })
    AddWordCoding('octopus', { sound: TARGET_SOUND_S, level: LEVEL_3, position: POSITION_FINAL, set: WORD_SET_CORE })
    AddWordCoding('office', { sound: TARGET_SOUND_S, level: LEVEL_3, position: POSITION_FINAL, set: WORD_SET_CORE })
    AddWordCoding('orange juice', { sound: TARGET_SOUND_S, level: LEVEL_3, position: POSITION_FINAL })
    AddWordCoding('plus', { sound: TARGET_SOUND_S, level: LEVEL_3, position: POSITION_FINAL })
    AddWordCoding('purchase', { sound: TARGET_SOUND_S, level: LEVEL_3, position: POSITION_FINAL })
    AddWordCoding('recess', { sound: TARGET_SOUND_S, level: LEVEL_3, position: POSITION_FINAL, set: WORD_SET_CORE })
    AddWordCoding('rhinoceros', { sound: TARGET_SOUND_S, level: LEVEL_3, position: POSITION_FINAL, set: WORD_SET_CORE })
    AddWordCoding('shoelace', { sound: TARGET_SOUND_S, level: LEVEL_3, position: POSITION_FINAL, set: WORD_SET_CORE })
    AddWordCoding('sports', { sound: TARGET_SOUND_S, level: LEVEL_3, position: POSITION_FINAL })
    AddWordCoding('sunglasses', { sound: TARGET_SOUND_S, level: LEVEL_3, position: POSITION_FINAL, set: WORD_SET_CORE })
    AddWordCoding('tomato juice', { sound: TARGET_SOUND_S, level: LEVEL_3, position: POSITION_FINAL })

    // Target sound S: SK blend
    AddWordCoding('scab', { sound: TARGET_SOUND_S, level: LEVEL_1, position: POSITION_SK_BLEND })
    AddWordCoding('scale', { sound: TARGET_SOUND_S, level: LEVEL_1, position: POSITION_SK_BLEND })
    AddWordCoding('scan', { sound: TARGET_SOUND_S, level: LEVEL_1, position: POSITION_SK_BLEND })
    AddWordCoding('scar', { sound: TARGET_SOUND_S, level: LEVEL_1, position: POSITION_SK_BLEND, set: WORD_SET_CORE })
    AddWordCoding('scare', { sound: TARGET_SOUND_S, level: LEVEL_1, position: POSITION_SK_BLEND, set: WORD_SET_CORE })
    AddWordCoding('scarf', { sound: TARGET_SOUND_S, level: LEVEL_1, position: POSITION_SK_BLEND, set: WORD_SET_CORE })
    AddWordCoding('school', { sound: TARGET_SOUND_S, level: LEVEL_1, position: POSITION_SK_BLEND, set: WORD_SET_CORE })
    AddWordCoding('scone', { sound: TARGET_SOUND_S, level: LEVEL_1, position: POSITION_SK_BLEND })
    AddWordCoding('scoop', { sound: TARGET_SOUND_S, level: LEVEL_1, position: POSITION_SK_BLEND, set: WORD_SET_CORE })
    AddWordCoding('scoot', { sound: TARGET_SOUND_S, level: LEVEL_1, position: POSITION_SK_BLEND })
    AddWordCoding('score', { sound: TARGET_SOUND_S, level: LEVEL_1, position: POSITION_SK_BLEND, set: WORD_SET_CORE })
    AddWordCoding('scowl', { sound: TARGET_SOUND_S, level: LEVEL_1, position: POSITION_SK_BLEND })
    AddWordCoding('skate', { sound: TARGET_SOUND_S, level: LEVEL_1, position: POSITION_SK_BLEND, set: WORD_SET_CORE })
    AddWordCoding('ski', { sound: TARGET_SOUND_S, level: LEVEL_1, position: POSITION_SK_BLEND, set: WORD_SET_CORE })
    AddWordCoding('skid', { sound: TARGET_SOUND_S, level: LEVEL_1, position: POSITION_SK_BLEND })
    AddWordCoding('skill', { sound: TARGET_SOUND_S, level: LEVEL_1, position: POSITION_SK_BLEND })
    AddWordCoding('skim', { sound: TARGET_SOUND_S, level: LEVEL_1, position: POSITION_SK_BLEND })
    AddWordCoding('skin', { sound: TARGET_SOUND_S, level: LEVEL_1, position: POSITION_SK_BLEND })
    AddWordCoding('skip', { sound: TARGET_SOUND_S, level: LEVEL_1, position: POSITION_SK_BLEND })
    AddWordCoding('skirt', { sound: TARGET_SOUND_S, level: LEVEL_1, position: POSITION_SK_BLEND })
    AddWordCoding('skull', { sound: TARGET_SOUND_S, level: LEVEL_1, position: POSITION_SK_BLEND, set: WORD_SET_CORE })
    AddWordCoding('sky', { sound: TARGET_SOUND_S, level: LEVEL_1, position: POSITION_SK_BLEND, set: WORD_SET_CORE })

    AddWordCoding('ask', { sound: TARGET_SOUND_S, level: LEVEL_2, position: POSITION_SK_BLEND, set: WORD_SET_CORE })
    AddWordCoding('basket', { sound: TARGET_SOUND_S, level: LEVEL_2, position: POSITION_SK_BLEND, set: WORD_SET_CORE })
    AddWordCoding('desk', { sound: TARGET_SOUND_S, level: LEVEL_2, position: POSITION_SK_BLEND, set: WORD_SET_CORE })
    AddWordCoding('disc', { sound: TARGET_SOUND_S, level: LEVEL_2, position: POSITION_SK_BLEND })
    AddWordCoding('dusk', { sound: TARGET_SOUND_S, level: LEVEL_2, position: POSITION_SK_BLEND })
    AddWordCoding('husk', { sound: TARGET_SOUND_S, level: LEVEL_2, position: POSITION_SK_BLEND })
    AddWordCoding('ice skate', { sound: TARGET_SOUND_S, level: LEVEL_2, position: POSITION_SK_BLEND, set: WORD_SET_CORE })
    AddWordCoding('mask', { sound: TARGET_SOUND_S, level: LEVEL_2, position: POSITION_SK_BLEND, set: WORD_SET_CORE })
    AddWordCoding('scary', { sound: TARGET_SOUND_S, level: LEVEL_2, position: POSITION_SK_BLEND })
    AddWordCoding('scooter', { sound: TARGET_SOUND_S, level: LEVEL_2, position: POSITION_SK_BLEND, set: WORD_SET_CORE })
    AddWordCoding('scoreboard', { sound: TARGET_SOUND_S, level: LEVEL_2, position: POSITION_SK_BLEND, set: WORD_SET_CORE })
    AddWordCoding('Scottie', { sound: TARGET_SOUND_S, level: LEVEL_2, position: POSITION_SK_BLEND })
    AddWordCoding('scuba', { sound: TARGET_SOUND_S, level: LEVEL_2, position: POSITION_SK_BLEND, set: WORD_SET_CORE })
    AddWordCoding('skateboard', { sound: TARGET_SOUND_S, level: LEVEL_2, position: POSITION_SK_BLEND, set: WORD_SET_CORE })
    AddWordCoding('skillet', { sound: TARGET_SOUND_S, level: LEVEL_2, position: POSITION_SK_BLEND })
    AddWordCoding('skunk', { sound: TARGET_SOUND_S, level: LEVEL_2, position: POSITION_SK_BLEND, set: WORD_SET_CORE })
    AddWordCoding('task', { sound: TARGET_SOUND_S, level: LEVEL_2, position: POSITION_SK_BLEND })
    AddWordCoding('tusk', { sound: TARGET_SOUND_S, level: LEVEL_2, position: POSITION_SK_BLEND })
    AddWordCoding('whisk', { sound: TARGET_SOUND_S, level: LEVEL_2, position: POSITION_SK_BLEND })

    AddWordCoding('basketball', { sound: TARGET_SOUND_S, level: LEVEL_3, position: POSITION_SK_BLEND, set: WORD_SET_CORE })
    AddWordCoding('escalator', { sound: TARGET_SOUND_S, level: LEVEL_3, position: POSITION_SK_BLEND })
    AddWordCoding('husky', { sound: TARGET_SOUND_S, level: LEVEL_3, position: POSITION_SK_BLEND })
    AddWordCoding('kaleidoscope', { sound: TARGET_SOUND_S, level: LEVEL_3, position: POSITION_SK_BLEND })
    AddWordCoding('microscope', { sound: TARGET_SOUND_S, level: LEVEL_3, position: POSITION_SK_BLEND, set: WORD_SET_CORE })
    AddWordCoding('mosquito', { sound: TARGET_SOUND_S, level: LEVEL_3, position: POSITION_SK_BLEND })
    AddWordCoding('Nebraska', { sound: TARGET_SOUND_S, level: LEVEL_3, position: POSITION_SK_BLEND, set: WORD_SET_CORE })
    AddWordCoding('periscope', { sound: TARGET_SOUND_S, level: LEVEL_3, position: POSITION_SK_BLEND })
    AddWordCoding('picnic basket', { sound: TARGET_SOUND_S, level: LEVEL_3, position: POSITION_SK_BLEND })
    AddWordCoding('rescue', { sound: TARGET_SOUND_S, level: LEVEL_3, position: POSITION_SK_BLEND })
    AddWordCoding('risky', { sound: TARGET_SOUND_S, level: LEVEL_3, position: POSITION_SK_BLEND })
    AddWordCoding('roller skate', { sound: TARGET_SOUND_S, level: LEVEL_3, position: POSITION_SK_BLEND })
    AddWordCoding('San Francisco', { sound: TARGET_SOUND_S, level: LEVEL_3, position: POSITION_SK_BLEND })
    AddWordCoding('scarecrow', { sound: TARGET_SOUND_S, level: LEVEL_3, position: POSITION_SK_BLEND, set: WORD_SET_CORE })
    AddWordCoding('school bus', { sound: TARGET_SOUND_S, level: LEVEL_3, position: POSITION_SK_BLEND, set: WORD_SET_CORE })
    AddWordCoding('scorpion', { sound: TARGET_SOUND_S, level: LEVEL_3, position: POSITION_SK_BLEND, set: WORD_SET_CORE })
    AddWordCoding('skeleton', { sound: TARGET_SOUND_S, level: LEVEL_3, position: POSITION_SK_BLEND, set: WORD_SET_CORE })
    AddWordCoding('sketch', { sound: TARGET_SOUND_S, level: LEVEL_3, position: POSITION_SK_BLEND })
    AddWordCoding('skyscraper', { sound: TARGET_SOUND_S, level: LEVEL_3, position: POSITION_SK_BLEND })
    AddWordCoding('swim mask', { sound: TARGET_SOUND_S, level: LEVEL_3, position: POSITION_SK_BLEND })
    AddWordCoding('telescope', { sound: TARGET_SOUND_S, level: LEVEL_3, position: POSITION_SK_BLEND, set: WORD_SET_CORE })
    AddWordCoding('whiskers', { sound: TARGET_SOUND_S, level: LEVEL_3, position: POSITION_SK_BLEND, set: WORD_SET_CORE })
    AddWordCoding('Wisconsin', { sound: TARGET_SOUND_S, level: LEVEL_3, position: POSITION_SK_BLEND, set: WORD_SET_CORE })

    // Target sound S: SL blend
    AddWordCoding('slam', { sound: TARGET_SOUND_S, level: LEVEL_1, position: POSITION_SL_BLEND, set: WORD_SET_CORE })
    AddWordCoding('sled', { sound: TARGET_SOUND_S, level: LEVEL_1, position: POSITION_SL_BLEND, set: WORD_SET_CORE })
    AddWordCoding('sleep', { sound: TARGET_SOUND_S, level: LEVEL_1, position: POSITION_SL_BLEND, set: WORD_SET_CORE })
    AddWordCoding('sleigh', { sound: TARGET_SOUND_S, level: LEVEL_1, position: POSITION_SL_BLEND, set: WORD_SET_CORE })
    AddWordCoding('slick', { sound: TARGET_SOUND_S, level: LEVEL_1, position: POSITION_SL_BLEND })
    AddWordCoding('slide', { sound: TARGET_SOUND_S, level: LEVEL_1, position: POSITION_SL_BLEND, set: WORD_SET_CORE })
    AddWordCoding('slime', { sound: TARGET_SOUND_S, level: LEVEL_1, position: POSITION_SL_BLEND, set: WORD_SET_CORE })
    AddWordCoding('sling', { sound: TARGET_SOUND_S, level: LEVEL_1, position: POSITION_SL_BLEND, set: WORD_SET_CORE })
    AddWordCoding('slip', { sound: TARGET_SOUND_S, level: LEVEL_1, position: POSITION_SL_BLEND, set: WORD_SET_CORE })
    AddWordCoding('slob', { sound: TARGET_SOUND_S, level: LEVEL_1, position: POSITION_SL_BLEND })
    AddWordCoding('slope', { sound: TARGET_SOUND_S, level: LEVEL_1, position: POSITION_SL_BLEND })
    AddWordCoding('slow', { sound: TARGET_SOUND_S, level: LEVEL_1, position: POSITION_SL_BLEND, set: WORD_SET_CORE })
    AddWordCoding('slug', { sound: TARGET_SOUND_S, level: LEVEL_1, position: POSITION_SL_BLEND, set: WORD_SET_CORE })

    AddWordCoding('asleep', { sound: TARGET_SOUND_S, level: LEVEL_2, position: POSITION_SL_BLEND, set: WORD_SET_CORE })
    AddWordCoding('slant', { sound: TARGET_SOUND_S, level: LEVEL_2, position: POSITION_SL_BLEND })
    AddWordCoding('sled dog', { sound: TARGET_SOUND_S, level: LEVEL_2, position: POSITION_SL_BLEND, set: WORD_SET_CORE })
    AddWordCoding('sledding', { sound: TARGET_SOUND_S, level: LEVEL_2, position: POSITION_SL_BLEND, set: WORD_SET_CORE })
    AddWordCoding('sleeping', { sound: TARGET_SOUND_S, level: LEVEL_2, position: POSITION_SL_BLEND, set: WORD_SET_CORE })
    AddWordCoding('sleeting', { sound: TARGET_SOUND_S, level: LEVEL_2, position: POSITION_SL_BLEND, set: WORD_SET_CORE })
    AddWordCoding('slender', { sound: TARGET_SOUND_S, level: LEVEL_2, position: POSITION_SL_BLEND })
    AddWordCoding('sliding', { sound: TARGET_SOUND_S, level: LEVEL_2, position: POSITION_SL_BLEND, set: WORD_SET_CORE })
    AddWordCoding('slightly', { sound: TARGET_SOUND_S, level: LEVEL_2, position: POSITION_SL_BLEND })
    AddWordCoding('slimy', { sound: TARGET_SOUND_S, level: LEVEL_2, position: POSITION_SL_BLEND, set: WORD_SET_CORE })
    AddWordCoding('slipper', { sound: TARGET_SOUND_S, level: LEVEL_2, position: POSITION_SL_BLEND, set: WORD_SET_CORE })
    AddWordCoding('slogan', { sound: TARGET_SOUND_S, level: LEVEL_2, position: POSITION_SL_BLEND, set: WORD_SET_CORE })
    AddWordCoding('sloppy', { sound: TARGET_SOUND_S, level: LEVEL_2, position: POSITION_SL_BLEND, set: WORD_SET_CORE })

    AddWordCoding('bracelet', { sound: TARGET_SOUND_S, level: LEVEL_3, position: POSITION_SL_BLEND, set: WORD_SET_CORE })
    AddWordCoding('bristly', { sound: TARGET_SOUND_S, level: LEVEL_3, position: POSITION_SL_BLEND })
    AddWordCoding('sledgehammer', { sound: TARGET_SOUND_S, level: LEVEL_3, position: POSITION_SL_BLEND })
    AddWordCoding('sleeping bag', { sound: TARGET_SOUND_S, level: LEVEL_3, position: POSITION_SL_BLEND, set: WORD_SET_CORE })
    AddWordCoding('sleepwalker', { sound: TARGET_SOUND_S, level: LEVEL_3, position: POSITION_SL_BLEND })
    AddWordCoding('sleeves', { sound: TARGET_SOUND_S, level: LEVEL_3, position: POSITION_SL_BLEND, set: WORD_SET_CORE })
    AddWordCoding('sleuth', { sound: TARGET_SOUND_S, level: LEVEL_3, position: POSITION_SL_BLEND, set: WORD_SET_CORE })
    AddWordCoding('slice', { sound: TARGET_SOUND_S, level: LEVEL_3, position: POSITION_SL_BLEND, set: WORD_SET_CORE })
    AddWordCoding('slingshot', { sound: TARGET_SOUND_S, level: LEVEL_3, position: POSITION_SL_BLEND, set: WORD_SET_CORE })
    AddWordCoding('slippery', { sound: TARGET_SOUND_S, level: LEVEL_3, position: POSITION_SL_BLEND, set: WORD_SET_CORE })
    AddWordCoding('slither', { sound: TARGET_SOUND_S, level: LEVEL_3, position: POSITION_SL_BLEND, set: WORD_SET_CORE })
    AddWordCoding('sliver', { sound: TARGET_SOUND_S, level: LEVEL_3, position: POSITION_SL_BLEND })
    AddWordCoding('sloth', { sound: TARGET_SOUND_S, level: LEVEL_3, position: POSITION_SL_BLEND, set: WORD_SET_CORE })
    AddWordCoding('slow motion', { sound: TARGET_SOUND_S, level: LEVEL_3, position: POSITION_SL_BLEND })
    AddWordCoding('wrestler', { sound: TARGET_SOUND_S, level: LEVEL_3, position: POSITION_SL_BLEND, set: WORD_SET_CORE })

    // Target sound S: SM blend
    AddWordCoding('smack', { sound: TARGET_SOUND_S, level: LEVEL_1, position: POSITION_SM_BLEND, set: WORD_SET_CORE })
    AddWordCoding('small', { sound: TARGET_SOUND_S, level: LEVEL_1, position: POSITION_SM_BLEND, set: WORD_SET_CORE })
    AddWordCoding('smash', { sound: TARGET_SOUND_S, level: LEVEL_1, position: POSITION_SM_BLEND, set: WORD_SET_CORE })
    AddWordCoding('smear', { sound: TARGET_SOUND_S, level: LEVEL_1, position: POSITION_SM_BLEND, set: WORD_SET_CORE })
    AddWordCoding('smell', { sound: TARGET_SOUND_S, level: LEVEL_1, position: POSITION_SM_BLEND, set: WORD_SET_CORE })
    AddWordCoding('smile', { sound: TARGET_SOUND_S, level: LEVEL_1, position: POSITION_SM_BLEND, set: WORD_SET_CORE })
    AddWordCoding('smock', { sound: TARGET_SOUND_S, level: LEVEL_1, position: POSITION_SM_BLEND, set: WORD_SET_CORE })
    AddWordCoding('smog', { sound: TARGET_SOUND_S, level: LEVEL_1, position: POSITION_SM_BLEND, set: WORD_SET_CORE })
    AddWordCoding('smoke', { sound: TARGET_SOUND_S, level: LEVEL_1, position: POSITION_SM_BLEND, set: WORD_SET_CORE })
    AddWordCoding('smore', { sound: TARGET_SOUND_S, level: LEVEL_1, position: POSITION_SM_BLEND, text: "s'more", set: WORD_SET_CORE })
    AddWordCoding('smug', { sound: TARGET_SOUND_S, level: LEVEL_1, position: POSITION_SM_BLEND })

    AddWordCoding('basement', { sound: TARGET_SOUND_S, level: LEVEL_2, position: POSITION_SM_BLEND, set: WORD_SET_CORE })
    AddWordCoding('placemat', { sound: TARGET_SOUND_S, level: LEVEL_2, position: POSITION_SM_BLEND, set: WORD_SET_CORE })
    AddWordCoding('smaller', { sound: TARGET_SOUND_S, level: LEVEL_2, position: POSITION_SM_BLEND, set: WORD_SET_CORE })
    AddWordCoding('smart', { sound: TARGET_SOUND_S, level: LEVEL_2, position: POSITION_SM_BLEND, set: WORD_SET_CORE })
    AddWordCoding('smarty', { sound: TARGET_SOUND_S, level: LEVEL_2, position: POSITION_SM_BLEND, set: WORD_SET_CORE })
    AddWordCoding('smelly', { sound: TARGET_SOUND_S, level: LEVEL_2, position: POSITION_SM_BLEND, set: WORD_SET_CORE })
    AddWordCoding('smiling', { sound: TARGET_SOUND_S, level: LEVEL_2, position: POSITION_SM_BLEND, set: WORD_SET_CORE })
    AddWordCoding('smirk', { sound: TARGET_SOUND_S, level: LEVEL_2, position: POSITION_SM_BLEND, set: WORD_SET_CORE })

    AddWordCoding('classmates', { sound: TARGET_SOUND_S, level: LEVEL_3, position: POSITION_SM_BLEND, set: WORD_SET_CORE })
    AddWordCoding('locksmith', { sound: TARGET_SOUND_S, level: LEVEL_3, position: POSITION_SM_BLEND, set: WORD_SET_CORE })
    AddWordCoding('smallest', { sound: TARGET_SOUND_S, level: LEVEL_3, position: POSITION_SM_BLEND, set: WORD_SET_CORE })
    AddWordCoding('smarter', { sound: TARGET_SOUND_S, level: LEVEL_3, position: POSITION_SM_BLEND, set: WORD_SET_CORE })
    AddWordCoding('smokestack', { sound: TARGET_SOUND_S, level: LEVEL_3, position: POSITION_SM_BLEND, set: WORD_SET_CORE })
    AddWordCoding('smooch', { sound: TARGET_SOUND_S, level: LEVEL_3, position: POSITION_SM_BLEND, set: WORD_SET_CORE })
    AddWordCoding('smooth', { sound: TARGET_SOUND_S, level: LEVEL_3, position: POSITION_SM_BLEND, set: WORD_SET_CORE })
    AddWordCoding('smoother', { sound: TARGET_SOUND_S, level: LEVEL_3, position: POSITION_SM_BLEND, set: WORD_SET_CORE })
    AddWordCoding('smoothie', { sound: TARGET_SOUND_S, level: LEVEL_3, position: POSITION_SM_BLEND, set: WORD_SET_CORE })
    AddWordCoding('smudge', { sound: TARGET_SOUND_S, level: LEVEL_3, position: POSITION_SM_BLEND, set: WORD_SET_CORE })

    // Target sound S: SN blend
    AddWordCoding('snack', { sound: TARGET_SOUND_S, level: LEVEL_1, position: POSITION_SN_BLEND, set: WORD_SET_CORE })
    AddWordCoding('snag', { sound: TARGET_SOUND_S, level: LEVEL_1, position: POSITION_SN_BLEND })
    AddWordCoding('snail', { sound: TARGET_SOUND_S, level: LEVEL_1, position: POSITION_SN_BLEND, set: WORD_SET_CORE })
    AddWordCoding('snake', { sound: TARGET_SOUND_S, level: LEVEL_1, position: POSITION_SN_BLEND, set: WORD_SET_CORE })
    AddWordCoding('snap', { sound: TARGET_SOUND_S, level: LEVEL_1, position: POSITION_SN_BLEND, set: WORD_SET_CORE })
    AddWordCoding('snare', { sound: TARGET_SOUND_S, level: LEVEL_1, position: POSITION_SN_BLEND })
    AddWordCoding('sneak', { sound: TARGET_SOUND_S, level: LEVEL_1, position: POSITION_SN_BLEND, set: WORD_SET_CORE })
    AddWordCoding('sneer', { sound: TARGET_SOUND_S, level: LEVEL_1, position: POSITION_SN_BLEND })
    AddWordCoding('snip', { sound: TARGET_SOUND_S, level: LEVEL_1, position: POSITION_SN_BLEND, set: WORD_SET_CORE })
    AddWordCoding('snob', { sound: TARGET_SOUND_S, level: LEVEL_1, position: POSITION_SN_BLEND })
    AddWordCoding('snoop', { sound: TARGET_SOUND_S, level: LEVEL_1, position: POSITION_SN_BLEND })
    AddWordCoding('snore', { sound: TARGET_SOUND_S, level: LEVEL_1, position: POSITION_SN_BLEND, set: WORD_SET_CORE })
    AddWordCoding('snot', { sound: TARGET_SOUND_S, level: LEVEL_1, position: POSITION_SN_BLEND })
    AddWordCoding('snout', { sound: TARGET_SOUND_S, level: LEVEL_1, position: POSITION_SN_BLEND, set: WORD_SET_CORE })
    AddWordCoding('snow', { sound: TARGET_SOUND_S, level: LEVEL_1, position: POSITION_SN_BLEND, set: WORD_SET_CORE })
    AddWordCoding('snub', { sound: TARGET_SOUND_S, level: LEVEL_1, position: POSITION_SN_BLEND })
    AddWordCoding('snug', { sound: TARGET_SOUND_S, level: LEVEL_1, position: POSITION_SN_BLEND, set: WORD_SET_CORE })

    AddWordCoding('snap pea', { sound: TARGET_SOUND_S, level: LEVEL_2, position: POSITION_SN_BLEND, set: WORD_SET_CORE })
    AddWordCoding('snapper', { sound: TARGET_SOUND_S, level: LEVEL_2, position: POSITION_SN_BLEND })
    AddWordCoding('snarl', { sound: TARGET_SOUND_S, level: LEVEL_2, position: POSITION_SN_BLEND, set: WORD_SET_CORE })
    AddWordCoding('sneaked', { sound: TARGET_SOUND_S, level: LEVEL_2, position: POSITION_SN_BLEND })
    AddWordCoding('sneaky', { sound: TARGET_SOUND_S, level: LEVEL_2, position: POSITION_SN_BLEND, set: WORD_SET_CORE })
    AddWordCoding('snicker', { sound: TARGET_SOUND_S, level: LEVEL_2, position: POSITION_SN_BLEND })
    AddWordCoding('snippy', { sound: TARGET_SOUND_S, level: LEVEL_2, position: POSITION_SN_BLEND, set: WORD_SET_CORE })
    AddWordCoding('snooping', { sound: TARGET_SOUND_S, level: LEVEL_2, position: POSITION_SN_BLEND, set: WORD_SET_CORE })
    AddWordCoding('snooty', { sound: TARGET_SOUND_S, level: LEVEL_2, position: POSITION_SN_BLEND })
    AddWordCoding('snowball', { sound: TARGET_SOUND_S, level: LEVEL_2, position: POSITION_SN_BLEND, set: WORD_SET_CORE })
    AddWordCoding('snowcap', { sound: TARGET_SOUND_S, level: LEVEL_2, position: POSITION_SN_BLEND, set: WORD_SET_CORE })
    AddWordCoding('snowcat', { sound: TARGET_SOUND_S, level: LEVEL_2, position: POSITION_SN_BLEND })
    AddWordCoding('snowman', { sound: TARGET_SOUND_S, level: LEVEL_2, position: POSITION_SN_BLEND, set: WORD_SET_CORE })
    AddWordCoding('snowy', { sound: TARGET_SOUND_S, level: LEVEL_2, position: POSITION_SN_BLEND, set: WORD_SET_CORE })
    AddWordCoding('snuggle', { sound: TARGET_SOUND_S, level: LEVEL_2, position: POSITION_SN_BLEND, set: WORD_SET_CORE })

    AddWordCoding('gingersnap', { sound: TARGET_SOUND_S, level: LEVEL_3, position: POSITION_SN_BLEND, set: WORD_SET_CORE })
    AddWordCoding('parsnip', { sound: TARGET_SOUND_S, level: LEVEL_3, position: POSITION_SN_BLEND })
    AddWordCoding('snack bar', { sound: TARGET_SOUND_S, level: LEVEL_3, position: POSITION_SN_BLEND, set: WORD_SET_CORE })
    AddWordCoding('snapping turtle', { sound: TARGET_SOUND_S, level: LEVEL_3, position: POSITION_SN_BLEND, set: WORD_SET_CORE })
    AddWordCoding('snare drum', { sound: TARGET_SOUND_S, level: LEVEL_3, position: POSITION_SN_BLEND, set: WORD_SET_CORE })
    AddWordCoding('snatch', { sound: TARGET_SOUND_S, level: LEVEL_3, position: POSITION_SN_BLEND })
    AddWordCoding('sneakers', { sound: TARGET_SOUND_S, level: LEVEL_3, position: POSITION_SN_BLEND, set: WORD_SET_CORE })
    AddWordCoding('sneeze', { sound: TARGET_SOUND_S, level: LEVEL_3, position: POSITION_SN_BLEND, set: WORD_SET_CORE })
    AddWordCoding('sneezy', { sound: TARGET_SOUND_S, level: LEVEL_3, position: POSITION_SN_BLEND })
    AddWordCoding('sniff', { sound: TARGET_SOUND_S, level: LEVEL_3, position: POSITION_SN_BLEND })
    AddWordCoding('sniffles', { sound: TARGET_SOUND_S, level: LEVEL_3, position: POSITION_SN_BLEND, set: WORD_SET_CORE })
    AddWordCoding('snitch', { sound: TARGET_SOUND_S, level: LEVEL_3, position: POSITION_SN_BLEND })
    AddWordCoding('snooze', { sound: TARGET_SOUND_S, level: LEVEL_3, position: POSITION_SN_BLEND, set: WORD_SET_CORE })
    AddWordCoding('snorkel', { sound: TARGET_SOUND_S, level: LEVEL_3, position: POSITION_SN_BLEND })
    AddWordCoding('snorted', { sound: TARGET_SOUND_S, level: LEVEL_3, position: POSITION_SN_BLEND })
    AddWordCoding('snow boots', { sound: TARGET_SOUND_S, level: LEVEL_3, position: POSITION_SN_BLEND })
    AddWordCoding('snow globe', { sound: TARGET_SOUND_S, level: LEVEL_3, position: POSITION_SN_BLEND, set: WORD_SET_CORE })
    AddWordCoding('snow-capped', { sound: TARGET_SOUND_S, level: LEVEL_3, position: POSITION_SN_BLEND })
    AddWordCoding('snowboard', { sound: TARGET_SOUND_S, level: LEVEL_3, position: POSITION_SN_BLEND })
    AddWordCoding('snowfall', { sound: TARGET_SOUND_S, level: LEVEL_3, position: POSITION_SN_BLEND })
    AddWordCoding('snowflake', { sound: TARGET_SOUND_S, level: LEVEL_3, position: POSITION_SN_BLEND, set: WORD_SET_CORE })
    AddWordCoding('snowplow', { sound: TARGET_SOUND_S, level: LEVEL_3, position: POSITION_SN_BLEND })
    AddWordCoding('snowshoes', { sound: TARGET_SOUND_S, level: LEVEL_3, position: POSITION_SN_BLEND })
    AddWordCoding('snowstorm', { sound: TARGET_SOUND_S, level: LEVEL_3, position: POSITION_SN_BLEND })
    AddWordCoding('snowy owl', { sound: TARGET_SOUND_S, level: LEVEL_3, position: POSITION_SN_BLEND })

    // Target sound S: SP blend
    AddWordCoding('spa', { sound: TARGET_SOUND_S, level: LEVEL_1, position: POSITION_SP_BLEND, set: WORD_SET_CORE })
    AddWordCoding('space', { sound: TARGET_SOUND_S, level: LEVEL_1, position: POSITION_SP_BLEND, set: WORD_SET_CORE })
    AddWordCoding('spade', { sound: TARGET_SOUND_S, level: LEVEL_1, position: POSITION_SP_BLEND })
    AddWordCoding('span', { sound: TARGET_SOUND_S, level: LEVEL_1, position: POSITION_SP_BLEND })
    AddWordCoding('spare', { sound: TARGET_SOUND_S, level: LEVEL_1, position: POSITION_SP_BLEND })
    AddWordCoding('speak', { sound: TARGET_SOUND_S, level: LEVEL_1, position: POSITION_SP_BLEND, set: WORD_SET_CORE })
    AddWordCoding('spear', { sound: TARGET_SOUND_S, level: LEVEL_1, position: POSITION_SP_BLEND })
    AddWordCoding('speck', { sound: TARGET_SOUND_S, level: LEVEL_1, position: POSITION_SP_BLEND })
    AddWordCoding('spell', { sound: TARGET_SOUND_S, level: LEVEL_1, position: POSITION_SP_BLEND })
    AddWordCoding('spike', { sound: TARGET_SOUND_S, level: LEVEL_1, position: POSITION_SP_BLEND })
    AddWordCoding('spill', { sound: TARGET_SOUND_S, level: LEVEL_1, position: POSITION_SP_BLEND, set: WORD_SET_CORE })
    AddWordCoding('spin', { sound: TARGET_SOUND_S, level: LEVEL_1, position: POSITION_SP_BLEND, set: WORD_SET_CORE })
    AddWordCoding('spine', { sound: TARGET_SOUND_S, level: LEVEL_1, position: POSITION_SP_BLEND })
    AddWordCoding('spire', { sound: TARGET_SOUND_S, level: LEVEL_1, position: POSITION_SP_BLEND })
    AddWordCoding('spool', { sound: TARGET_SOUND_S, level: LEVEL_1, position: POSITION_SP_BLEND })
    AddWordCoding('spoon', { sound: TARGET_SOUND_S, level: LEVEL_1, position: POSITION_SP_BLEND, set: WORD_SET_CORE })
    AddWordCoding('spot', { sound: TARGET_SOUND_S, level: LEVEL_1, position: POSITION_SP_BLEND, set: WORD_SET_CORE })
    AddWordCoding('spout', { sound: TARGET_SOUND_S, level: LEVEL_1, position: POSITION_SP_BLEND, set: WORD_SET_CORE })
    AddWordCoding('spud', { sound: TARGET_SOUND_S, level: LEVEL_1, position: POSITION_SP_BLEND, set: WORD_SET_CORE })
    AddWordCoding('spy', { sound: TARGET_SOUND_S, level: LEVEL_1, position: POSITION_SP_BLEND, set: WORD_SET_CORE })

    AddWordCoding('clasp', { sound: TARGET_SOUND_S, level: LEVEL_2, position: POSITION_SP_BLEND })
    AddWordCoding('crispy', { sound: TARGET_SOUND_S, level: LEVEL_2, position: POSITION_SP_BLEND, set: WORD_SET_CORE })
    AddWordCoding('dispose', { sound: TARGET_SOUND_S, level: LEVEL_2, position: POSITION_SP_BLEND })
    AddWordCoding('high-speed', { sound: TARGET_SOUND_S, level: LEVEL_2, position: POSITION_SP_BLEND })
    AddWordCoding('inspect', { sound: TARGET_SOUND_S, level: LEVEL_2, position: POSITION_SP_BLEND })
    AddWordCoding('misspell', { sound: TARGET_SOUND_S, level: LEVEL_2, position: POSITION_SP_BLEND })
    AddWordCoding('passport', { sound: TARGET_SOUND_S, level: LEVEL_2, position: POSITION_SP_BLEND })
    AddWordCoding('spaniel', { sound: TARGET_SOUND_S, level: LEVEL_2, position: POSITION_SP_BLEND })
    AddWordCoding('spark', { sound: TARGET_SOUND_S, level: LEVEL_2, position: POSITION_SP_BLEND })
    AddWordCoding('sparkle', { sound: TARGET_SOUND_S, level: LEVEL_2, position: POSITION_SP_BLEND, set: WORD_SET_CORE })
    AddWordCoding('sparrow', { sound: TARGET_SOUND_S, level: LEVEL_2, position: POSITION_SP_BLEND })
    AddWordCoding('speckle', { sound: TARGET_SOUND_S, level: LEVEL_2, position: POSITION_SP_BLEND, set: WORD_SET_CORE })
    AddWordCoding('spelled', { sound: TARGET_SOUND_S, level: LEVEL_2, position: POSITION_SP_BLEND, set: WORD_SET_CORE })
    AddWordCoding('spend', { sound: TARGET_SOUND_S, level: LEVEL_2, position: POSITION_SP_BLEND })
    AddWordCoding('spider', { sound: TARGET_SOUND_S, level: LEVEL_2, position: POSITION_SP_BLEND, set: WORD_SET_CORE })
    AddWordCoding('spiky', { sound: TARGET_SOUND_S, level: LEVEL_2, position: POSITION_SP_BLEND, set: WORD_SET_CORE })
    AddWordCoding('spilled', { sound: TARGET_SOUND_S, level: LEVEL_2, position: POSITION_SP_BLEND })
    AddWordCoding('spinner', { sound: TARGET_SOUND_S, level: LEVEL_2, position: POSITION_SP_BLEND, set: WORD_SET_CORE })
    AddWordCoding('spinning', { sound: TARGET_SOUND_S, level: LEVEL_2, position: POSITION_SP_BLEND })
    AddWordCoding('spiral', { sound: TARGET_SOUND_S, level: LEVEL_2, position: POSITION_SP_BLEND })
    AddWordCoding('spooky', { sound: TARGET_SOUND_S, level: LEVEL_2, position: POSITION_SP_BLEND, set: WORD_SET_CORE })
    AddWordCoding('sport', { sound: TARGET_SOUND_S, level: LEVEL_2, position: POSITION_SP_BLEND, set: WORD_SET_CORE })
    AddWordCoding('spying', { sound: TARGET_SOUND_S, level: LEVEL_2, position: POSITION_SP_BLEND })
    AddWordCoding('teaspoon', { sound: TARGET_SOUND_S, level: LEVEL_2, position: POSITION_SP_BLEND })
    AddWordCoding('wasp', { sound: TARGET_SOUND_S, level: LEVEL_2, position: POSITION_SP_BLEND })
    AddWordCoding('whisper', { sound: TARGET_SOUND_S, level: LEVEL_2, position: POSITION_SP_BLEND, set: WORD_SET_CORE })

    AddWordCoding('asparagus', { sound: TARGET_SOUND_S, level: LEVEL_3, position: POSITION_SP_BLEND })
    AddWordCoding('baby spoon', { sound: TARGET_SOUND_S, level: LEVEL_3, position: POSITION_SP_BLEND })
    AddWordCoding('hospital', { sound: TARGET_SOUND_S, level: LEVEL_3, position: POSITION_SP_BLEND, set: WORD_SET_CORE })
    AddWordCoding('loudspeaker', { sound: TARGET_SOUND_S, level: LEVEL_3, position: POSITION_SP_BLEND })
    AddWordCoding('outer space', { sound: TARGET_SOUND_S, level: LEVEL_3, position: POSITION_SP_BLEND, set: WORD_SET_CORE })
    AddWordCoding('space bar', { sound: TARGET_SOUND_S, level: LEVEL_3, position: POSITION_SP_BLEND })
    AddWordCoding('spacesuit', { sound: TARGET_SOUND_S, level: LEVEL_3, position: POSITION_SP_BLEND })
    AddWordCoding('spaceship', { sound: TARGET_SOUND_S, level: LEVEL_3, position: POSITION_SP_BLEND, set: WORD_SET_CORE })
    AddWordCoding('spaghetti', { sound: TARGET_SOUND_S, level: LEVEL_3, position: POSITION_SP_BLEND, set: WORD_SET_CORE })
    AddWordCoding('spatula', { sound: TARGET_SOUND_S, level: LEVEL_3, position: POSITION_SP_BLEND })
    AddWordCoding('speech', { sound: TARGET_SOUND_S, level: LEVEL_3, position: POSITION_SP_BLEND, set: WORD_SET_CORE })
    AddWordCoding('speed limit', { sound: TARGET_SOUND_S, level: LEVEL_3, position: POSITION_SP_BLEND })
    AddWordCoding('speedboat', { sound: TARGET_SOUND_S, level: LEVEL_3, position: POSITION_SP_BLEND, set: WORD_SET_CORE })
    AddWordCoding('speedway', { sound: TARGET_SOUND_S, level: LEVEL_3, position: POSITION_SP_BLEND })
    AddWordCoding('spice', { sound: TARGET_SOUND_S, level: LEVEL_3, position: POSITION_SP_BLEND })
    AddWordCoding('spicy', { sound: TARGET_SOUND_S, level: LEVEL_3, position: POSITION_SP_BLEND, set: WORD_SET_CORE })
    AddWordCoding('spider monkey', { sound: TARGET_SOUND_S, level: LEVEL_3, position: POSITION_SP_BLEND })
    AddWordCoding('spider web', { sound: TARGET_SOUND_S, level: LEVEL_3, position: POSITION_SP_BLEND, set: WORD_SET_CORE })
    AddWordCoding('spinach', { sound: TARGET_SOUND_S, level: LEVEL_3, position: POSITION_SP_BLEND })
    AddWordCoding('spokes', { sound: TARGET_SOUND_S, level: LEVEL_3, position: POSITION_SP_BLEND })
    AddWordCoding('sponge', { sound: TARGET_SOUND_S, level: LEVEL_3, position: POSITION_SP_BLEND, set: WORD_SET_CORE })
    AddWordCoding('sports', { sound: TARGET_SOUND_S, level: LEVEL_3, position: POSITION_SP_BLEND, set: WORD_SET_CORE })
    AddWordCoding('sports car', { sound: TARGET_SOUND_S, level: LEVEL_3, position: POSITION_SP_BLEND })
    AddWordCoding('spotlight', { sound: TARGET_SOUND_S, level: LEVEL_3, position: POSITION_SP_BLEND })
    AddWordCoding('spurs', { sound: TARGET_SOUND_S, level: LEVEL_3, position: POSITION_SP_BLEND })
    AddWordCoding('suspenders', { sound: TARGET_SOUND_S, level: LEVEL_3, position: POSITION_SP_BLEND })
    AddWordCoding('whispering', { sound: TARGET_SOUND_S, level: LEVEL_3, position: POSITION_SP_BLEND })

    // Target sound S: ST blend
    AddWordCoding('stage', { sound: TARGET_SOUND_S, level: LEVEL_1, position: POSITION_ST_BLEND })
    AddWordCoding('stain', { sound: TARGET_SOUND_S, level: LEVEL_1, position: POSITION_ST_BLEND })
    AddWordCoding('stamp', { sound: TARGET_SOUND_S, level: LEVEL_1, position: POSITION_ST_BLEND, set: WORD_SET_CORE })
    AddWordCoding('star', { sound: TARGET_SOUND_S, level: LEVEL_1, position: POSITION_ST_BLEND })
    AddWordCoding('steak', { sound: TARGET_SOUND_S, level: LEVEL_1, position: POSITION_ST_BLEND })
    AddWordCoding('steam', { sound: TARGET_SOUND_S, level: LEVEL_1, position: POSITION_ST_BLEND })
    AddWordCoding('steep', { sound: TARGET_SOUND_S, level: LEVEL_1, position: POSITION_ST_BLEND })
    AddWordCoding('steer', { sound: TARGET_SOUND_S, level: LEVEL_1, position: POSITION_ST_BLEND })
    AddWordCoding('stem', { sound: TARGET_SOUND_S, level: LEVEL_1, position: POSITION_ST_BLEND })
    AddWordCoding('step', { sound: TARGET_SOUND_S, level: LEVEL_1, position: POSITION_ST_BLEND })
    AddWordCoding('stick', { sound: TARGET_SOUND_S, level: LEVEL_1, position: POSITION_ST_BLEND, set: WORD_SET_CORE })
    AddWordCoding('sting', { sound: TARGET_SOUND_S, level: LEVEL_1, position: POSITION_ST_BLEND })
    AddWordCoding('stir', { sound: TARGET_SOUND_S, level: LEVEL_1, position: POSITION_ST_BLEND, set: WORD_SET_CORE })
    AddWordCoding('stone', { sound: TARGET_SOUND_S, level: LEVEL_1, position: POSITION_ST_BLEND })
    AddWordCoding('stool', { sound: TARGET_SOUND_S, level: LEVEL_1, position: POSITION_ST_BLEND, set: WORD_SET_CORE })
    AddWordCoding('stop', { sound: TARGET_SOUND_S, level: LEVEL_1, position: POSITION_ST_BLEND, set: WORD_SET_CORE })
    AddWordCoding('store', { sound: TARGET_SOUND_S, level: LEVEL_1, position: POSITION_ST_BLEND, set: WORD_SET_CORE })
    AddWordCoding('stork', { sound: TARGET_SOUND_S, level: LEVEL_1, position: POSITION_ST_BLEND })
    AddWordCoding('storm', { sound: TARGET_SOUND_S, level: LEVEL_1, position: POSITION_ST_BLEND, set: WORD_SET_CORE })
    AddWordCoding('stove', { sound: TARGET_SOUND_S, level: LEVEL_1, position: POSITION_ST_BLEND, set: WORD_SET_CORE })
    AddWordCoding('stuck', { sound: TARGET_SOUND_S, level: LEVEL_1, position: POSITION_ST_BLEND, set: WORD_SET_CORE })
    AddWordCoding('stump', { sound: TARGET_SOUND_S, level: LEVEL_1, position: POSITION_ST_BLEND, set: WORD_SET_CORE })

    AddWordCoding('coast', { sound: TARGET_SOUND_S, level: LEVEL_2, position: POSITION_ST_BLEND })
    AddWordCoding('east', { sound: TARGET_SOUND_S, level: LEVEL_2, position: POSITION_ST_BLEND })
    AddWordCoding('fast', { sound: TARGET_SOUND_S, level: LEVEL_2, position: POSITION_ST_BLEND })
    AddWordCoding('feast', { sound: TARGET_SOUND_S, level: LEVEL_2, position: POSITION_ST_BLEND })
    AddWordCoding('ghost', { sound: TARGET_SOUND_S, level: LEVEL_2, position: POSITION_ST_BLEND, set: WORD_SET_CORE })
    AddWordCoding('last', { sound: TARGET_SOUND_S, level: LEVEL_2, position: POSITION_ST_BLEND, set: WORD_SET_CORE })
    AddWordCoding('list', { sound: TARGET_SOUND_S, level: LEVEL_2, position: POSITION_ST_BLEND, set: WORD_SET_CORE })
    AddWordCoding('mast', { sound: TARGET_SOUND_S, level: LEVEL_2, position: POSITION_ST_BLEND })
    AddWordCoding('nest', { sound: TARGET_SOUND_S, level: LEVEL_2, position: POSITION_ST_BLEND, set: WORD_SET_CORE })
    AddWordCoding('stacks', { sound: TARGET_SOUND_S, level: LEVEL_2, position: POSITION_ST_BLEND, set: WORD_SET_CORE })
    AddWordCoding('stairs', { sound: TARGET_SOUND_S, level: LEVEL_2, position: POSITION_ST_BLEND, set: WORD_SET_CORE })
    AddWordCoding('stalk', { sound: TARGET_SOUND_S, level: LEVEL_2, position: POSITION_ST_BLEND })
    AddWordCoding('stand', { sound: TARGET_SOUND_S, level: LEVEL_2, position: POSITION_ST_BLEND })
    AddWordCoding('stapler', { sound: TARGET_SOUND_S, level: LEVEL_2, position: POSITION_ST_BLEND, set: WORD_SET_CORE })
    AddWordCoding('start', { sound: TARGET_SOUND_S, level: LEVEL_2, position: POSITION_ST_BLEND })
    AddWordCoding('statue', { sound: TARGET_SOUND_S, level: LEVEL_2, position: POSITION_ST_BLEND })
    AddWordCoding('sticky', { sound: TARGET_SOUND_S, level: LEVEL_2, position: POSITION_ST_BLEND, set: WORD_SET_CORE })
    AddWordCoding('stoplight', { sound: TARGET_SOUND_S, level: LEVEL_2, position: POSITION_ST_BLEND })
    AddWordCoding('stormy', { sound: TARGET_SOUND_S, level: LEVEL_2, position: POSITION_ST_BLEND })
    AddWordCoding('story', { sound: TARGET_SOUND_S, level: LEVEL_2, position: POSITION_ST_BLEND, set: WORD_SET_CORE })
    AddWordCoding('student', { sound: TARGET_SOUND_S, level: LEVEL_2, position: POSITION_ST_BLEND, set: WORD_SET_CORE })
    AddWordCoding('west', { sound: TARGET_SOUND_S, level: LEVEL_2, position: POSITION_ST_BLEND })
    AddWordCoding('wrist', { sound: TARGET_SOUND_S, level: LEVEL_2, position: POSITION_ST_BLEND })

    AddWordCoding('breakfast', { sound: TARGET_SOUND_S, level: LEVEL_3, position: POSITION_ST_BLEND, set: WORD_SET_CORE })
    AddWordCoding('bus stop', { sound: TARGET_SOUND_S, level: LEVEL_3, position: POSITION_ST_BLEND, set: WORD_SET_CORE })
    AddWordCoding('chopsticks', { sound: TARGET_SOUND_S, level: LEVEL_3, position: POSITION_ST_BLEND })
    AddWordCoding('crust', { sound: TARGET_SOUND_S, level: LEVEL_3, position: POSITION_ST_BLEND })
    AddWordCoding('disturb', { sound: TARGET_SOUND_S, level: LEVEL_3, position: POSITION_ST_BLEND })
    AddWordCoding('drugstore', { sound: TARGET_SOUND_S, level: LEVEL_3, position: POSITION_ST_BLEND })
    AddWordCoding('Einstein', { sound: TARGET_SOUND_S, level: LEVEL_3, position: POSITION_ST_BLEND })
    AddWordCoding('fantastic', { sound: TARGET_SOUND_S, level: LEVEL_3, position: POSITION_ST_BLEND })
    AddWordCoding('frosting', { sound: TARGET_SOUND_S, level: LEVEL_3, position: POSITION_ST_BLEND })
    AddWordCoding('gas station', { sound: TARGET_SOUND_S, level: LEVEL_3, position: POSITION_ST_BLEND })
    AddWordCoding('gymnastics', { sound: TARGET_SOUND_S, level: LEVEL_3, position: POSITION_ST_BLEND, set: WORD_SET_CORE })
    AddWordCoding('haystack', { sound: TARGET_SOUND_S, level: LEVEL_3, position: POSITION_ST_BLEND, set: WORD_SET_CORE })
    AddWordCoding('lipstick', { sound: TARGET_SOUND_S, level: LEVEL_3, position: POSITION_ST_BLEND })
    AddWordCoding('mistake', { sound: TARGET_SOUND_S, level: LEVEL_3, position: POSITION_ST_BLEND, set: WORD_SET_CORE })
    AddWordCoding('mustard', { sound: TARGET_SOUND_S, level: LEVEL_3, position: POSITION_ST_BLEND })
    AddWordCoding('postcard', { sound: TARGET_SOUND_S, level: LEVEL_3, position: POSITION_ST_BLEND })
    AddWordCoding('question', { sound: TARGET_SOUND_S, level: LEVEL_3, position: POSITION_ST_BLEND, set: WORD_SET_CORE })
    AddWordCoding('rooster', { sound: TARGET_SOUND_S, level: LEVEL_3, position: POSITION_ST_BLEND })
    AddWordCoding('shooting star', { sound: TARGET_SOUND_S, level: LEVEL_3, position: POSITION_ST_BLEND })
    AddWordCoding('solar system', { sound: TARGET_SOUND_S, level: LEVEL_3, position: POSITION_ST_BLEND })
    AddWordCoding('stadium', { sound: TARGET_SOUND_S, level: LEVEL_3, position: POSITION_ST_BLEND })
    AddWordCoding('starfish', { sound: TARGET_SOUND_S, level: LEVEL_3, position: POSITION_ST_BLEND, set: WORD_SET_CORE })
    AddWordCoding('states', { sound: TARGET_SOUND_S, level: LEVEL_3, position: POSITION_ST_BLEND })
    AddWordCoding('stepladder', { sound: TARGET_SOUND_S, level: LEVEL_3, position: POSITION_ST_BLEND })
    AddWordCoding('stethoscope', { sound: TARGET_SOUND_S, level: LEVEL_3, position: POSITION_ST_BLEND })
    AddWordCoding('stomach ache', { sound: TARGET_SOUND_S, level: LEVEL_3, position: POSITION_ST_BLEND, set: WORD_SET_CORE })
    AddWordCoding('stopwatch', { sound: TARGET_SOUND_S, level: LEVEL_3, position: POSITION_ST_BLEND })
    AddWordCoding('stories', { sound: TARGET_SOUND_S, level: LEVEL_3, position: POSITION_ST_BLEND })
    AddWordCoding('storybook', { sound: TARGET_SOUND_S, level: LEVEL_3, position: POSITION_ST_BLEND, set: WORD_SET_CORE })
    AddWordCoding('tasty', { sound: TARGET_SOUND_S, level: LEVEL_3, position: POSITION_ST_BLEND })
    AddWordCoding('United States', { sound: TARGET_SOUND_S, level: LEVEL_3, position: POSITION_ST_BLEND, set: WORD_SET_CORE })

    // Target sound S: SW blend
    AddWordCoding('swab', { sound: TARGET_SOUND_S, level: LEVEL_1, position: POSITION_SW_BLEND, set: WORD_SET_CORE })
    AddWordCoding('swam', { sound: TARGET_SOUND_S, level: LEVEL_1, position: POSITION_SW_BLEND })
    AddWordCoding('swan', { sound: TARGET_SOUND_S, level: LEVEL_1, position: POSITION_SW_BLEND, set: WORD_SET_CORE })
    AddWordCoding('swat', { sound: TARGET_SOUND_S, level: LEVEL_1, position: POSITION_SW_BLEND, set: WORD_SET_CORE })
    AddWordCoding('sweat', { sound: TARGET_SOUND_S, level: LEVEL_1, position: POSITION_SW_BLEND, set: WORD_SET_CORE })
    AddWordCoding('sweep', { sound: TARGET_SOUND_S, level: LEVEL_1, position: POSITION_SW_BLEND, set: WORD_SET_CORE })
    AddWordCoding('sweet', { sound: TARGET_SOUND_S, level: LEVEL_1, position: POSITION_SW_BLEND, set: WORD_SET_CORE })
    AddWordCoding('swig', { sound: TARGET_SOUND_S, level: LEVEL_1, position: POSITION_SW_BLEND })
    AddWordCoding('swim', { sound: TARGET_SOUND_S, level: LEVEL_1, position: POSITION_SW_BLEND, set: WORD_SET_CORE })
    AddWordCoding('swine', { sound: TARGET_SOUND_S, level: LEVEL_1, position: POSITION_SW_BLEND, set: WORD_SET_CORE })
    AddWordCoding('swing', { sound: TARGET_SOUND_S, level: LEVEL_1, position: POSITION_SW_BLEND, set: WORD_SET_CORE })
    AddWordCoding('swipe', { sound: TARGET_SOUND_S, level: LEVEL_1, position: POSITION_SW_BLEND, set: WORD_SET_CORE })

    AddWordCoding('swallow', { sound: TARGET_SOUND_S, level: LEVEL_2, position: POSITION_SW_BLEND, set: WORD_SET_CORE })
    AddWordCoding('swamp', { sound: TARGET_SOUND_S, level: LEVEL_2, position: POSITION_SW_BLEND, set: WORD_SET_CORE })
    AddWordCoding('swarm', { sound: TARGET_SOUND_S, level: LEVEL_2, position: POSITION_SW_BLEND, set: WORD_SET_CORE })
    AddWordCoding('sweater', { sound: TARGET_SOUND_S, level: LEVEL_2, position: POSITION_SW_BLEND, set: WORD_SET_CORE })
    AddWordCoding('Sweden', { sound: TARGET_SOUND_S, level: LEVEL_2, position: POSITION_SW_BLEND, set: WORD_SET_CORE })
    AddWordCoding('swept', { sound: TARGET_SOUND_S, level: LEVEL_2, position: POSITION_SW_BLEND, set: WORD_SET_CORE })
    AddWordCoding('swimmer', { sound: TARGET_SOUND_S, level: LEVEL_2, position: POSITION_SW_BLEND, set: WORD_SET_CORE })
    AddWordCoding('swimming', { sound: TARGET_SOUND_S, level: LEVEL_2, position: POSITION_SW_BLEND, set: WORD_SET_CORE })
    AddWordCoding('swirl', { sound: TARGET_SOUND_S, level: LEVEL_2, position: POSITION_SW_BLEND, set: WORD_SET_CORE })
    AddWordCoding('swollen', { sound: TARGET_SOUND_S, level: LEVEL_2, position: POSITION_SW_BLEND, set: WORD_SET_CORE })

    AddWordCoding('swanky', { sound: TARGET_SOUND_S, level: LEVEL_3, position: POSITION_SW_BLEND })
    AddWordCoding('sweatshirt', { sound: TARGET_SOUND_S, level: LEVEL_3, position: POSITION_SW_BLEND, set: WORD_SET_CORE })
    AddWordCoding('Swedish Fish', { sound: TARGET_SOUND_S, level: LEVEL_3, position: POSITION_SW_BLEND, set: WORD_SET_CORE })
    AddWordCoding('sweet corn', { sound: TARGET_SOUND_S, level: LEVEL_3, position: POSITION_SW_BLEND })
    AddWordCoding('sweet pea', { sound: TARGET_SOUND_S, level: LEVEL_3, position: POSITION_SW_BLEND })
    AddWordCoding('sweetheart', { sound: TARGET_SOUND_S, level: LEVEL_3, position: POSITION_SW_BLEND, set: WORD_SET_CORE })
    AddWordCoding('swerve', { sound: TARGET_SOUND_S, level: LEVEL_3, position: POSITION_SW_BLEND })
    AddWordCoding('swift', { sound: TARGET_SOUND_S, level: LEVEL_3, position: POSITION_SW_BLEND })
    AddWordCoding('swim fins', { sound: TARGET_SOUND_S, level: LEVEL_3, position: POSITION_SW_BLEND, set: WORD_SET_CORE })
    AddWordCoding('swim mask', { sound: TARGET_SOUND_S, level: LEVEL_3, position: POSITION_SW_BLEND })
    AddWordCoding('swimming pool', { sound: TARGET_SOUND_S, level: LEVEL_3, position: POSITION_SW_BLEND, set: WORD_SET_CORE })
    AddWordCoding('swimsuit', { sound: TARGET_SOUND_S, level: LEVEL_3, position: POSITION_SW_BLEND, set: WORD_SET_CORE })
    AddWordCoding('swinging', { sound: TARGET_SOUND_S, level: LEVEL_3, position: POSITION_SW_BLEND, set: WORD_SET_CORE })
    AddWordCoding('swinging door', { sound: TARGET_SOUND_S, level: LEVEL_3, position: POSITION_SW_BLEND })
    AddWordCoding('Swiss cheese', { sound: TARGET_SOUND_S, level: LEVEL_3, position: POSITION_SW_BLEND, set: WORD_SET_CORE })
    AddWordCoding('switch', { sound: TARGET_SOUND_S, level: LEVEL_3, position: POSITION_SW_BLEND, set: WORD_SET_CORE })
    AddWordCoding('swivel', { sound: TARGET_SOUND_S, level: LEVEL_3, position: POSITION_SW_BLEND, set: WORD_SET_CORE })

    // Target sound S: Complex blend
    AddWordCoding('scrape', { sound: TARGET_SOUND_S, level: LEVEL_1, position: POSITION_COMPLEX_BLEND })
    AddWordCoding('scream', { sound: TARGET_SOUND_S, level: LEVEL_1, position: POSITION_COMPLEX_BLEND })
    AddWordCoding('screen', { sound: TARGET_SOUND_S, level: LEVEL_1, position: POSITION_COMPLEX_BLEND, set: WORD_SET_CORE })
    AddWordCoding('screw', { sound: TARGET_SOUND_S, level: LEVEL_1, position: POSITION_COMPLEX_BLEND, set: WORD_SET_CORE })
    AddWordCoding('spray', { sound: TARGET_SOUND_S, level: LEVEL_1, position: POSITION_COMPLEX_BLEND, set: WORD_SET_CORE })
    AddWordCoding('spring', { sound: TARGET_SOUND_S, level: LEVEL_1, position: POSITION_COMPLEX_BLEND, set: WORD_SET_CORE })
    AddWordCoding('sprout', { sound: TARGET_SOUND_S, level: LEVEL_1, position: POSITION_COMPLEX_BLEND, set: WORD_SET_CORE })
    AddWordCoding('spruce', { sound: TARGET_SOUND_S, level: LEVEL_1, position: POSITION_COMPLEX_BLEND })
    AddWordCoding('square', { sound: TARGET_SOUND_S, level: LEVEL_1, position: POSITION_COMPLEX_BLEND, set: WORD_SET_CORE })
    AddWordCoding('squawk', { sound: TARGET_SOUND_S, level: LEVEL_1, position: POSITION_COMPLEX_BLEND })
    AddWordCoding('squeak', { sound: TARGET_SOUND_S, level: LEVEL_1, position: POSITION_COMPLEX_BLEND, set: WORD_SET_CORE })
    AddWordCoding('squid', { sound: TARGET_SOUND_S, level: LEVEL_1, position: POSITION_COMPLEX_BLEND })
    AddWordCoding('straight', { sound: TARGET_SOUND_S, level: LEVEL_1, position: POSITION_COMPLEX_BLEND })
    AddWordCoding('straw', { sound: TARGET_SOUND_S, level: LEVEL_1, position: POSITION_COMPLEX_BLEND, set: WORD_SET_CORE })
    AddWordCoding('stream', { sound: TARGET_SOUND_S, level: LEVEL_1, position: POSITION_COMPLEX_BLEND, set: WORD_SET_CORE })
    AddWordCoding('street', { sound: TARGET_SOUND_S, level: LEVEL_1, position: POSITION_COMPLEX_BLEND, set: WORD_SET_CORE })
    AddWordCoding('strike', { sound: TARGET_SOUND_S, level: LEVEL_1, position: POSITION_COMPLEX_BLEND })
    AddWordCoding('stripe', { sound: TARGET_SOUND_S, level: LEVEL_1, position: POSITION_COMPLEX_BLEND, set: WORD_SET_CORE })
    AddWordCoding('strong', { sound: TARGET_SOUND_S, level: LEVEL_1, position: POSITION_COMPLEX_BLEND, set: WORD_SET_CORE })
    AddWordCoding('strum', { sound: TARGET_SOUND_S, level: LEVEL_1, position: POSITION_COMPLEX_BLEND })

    AddWordCoding('bedspread', { sound: TARGET_SOUND_S, level: LEVEL_2, position: POSITION_COMPLEX_BLEND })
    AddWordCoding('hairspray', { sound: TARGET_SOUND_S, level: LEVEL_2, position: POSITION_COMPLEX_BLEND })
    AddWordCoding('ice cream', { sound: TARGET_SOUND_S, level: LEVEL_2, position: POSITION_COMPLEX_BLEND, set: WORD_SET_CORE })
    AddWordCoding('pastry', { sound: TARGET_SOUND_S, level: LEVEL_2, position: POSITION_COMPLEX_BLEND })
    AddWordCoding('scrapbook', { sound: TARGET_SOUND_S, level: LEVEL_2, position: POSITION_COMPLEX_BLEND, set: WORD_SET_CORE })
    AddWordCoding('scribble', { sound: TARGET_SOUND_S, level: LEVEL_2, position: POSITION_COMPLEX_BLEND, set: WORD_SET_CORE })
    AddWordCoding('sprayer', { sound: TARGET_SOUND_S, level: LEVEL_2, position: POSITION_COMPLEX_BLEND, set: WORD_SET_CORE })
    AddWordCoding('springtime', { sound: TARGET_SOUND_S, level: LEVEL_2, position: POSITION_COMPLEX_BLEND, set: WORD_SET_CORE })
    AddWordCoding('sprinkle', { sound: TARGET_SOUND_S, level: LEVEL_2, position: POSITION_COMPLEX_BLEND, set: WORD_SET_CORE })
    AddWordCoding('sprinkler', { sound: TARGET_SOUND_S, level: LEVEL_2, position: POSITION_COMPLEX_BLEND, set: WORD_SET_CORE })
    AddWordCoding('squeaky', { sound: TARGET_SOUND_S, level: LEVEL_2, position: POSITION_COMPLEX_BLEND, set: WORD_SET_CORE })
    AddWordCoding('squirrel', { sound: TARGET_SOUND_S, level: LEVEL_2, position: POSITION_COMPLEX_BLEND, set: WORD_SET_CORE })
    AddWordCoding('squirt', { sound: TARGET_SOUND_S, level: LEVEL_2, position: POSITION_COMPLEX_BLEND, set: WORD_SET_CORE })
    AddWordCoding('strawberry', { sound: TARGET_SOUND_S, level: LEVEL_2, position: POSITION_COMPLEX_BLEND, set: WORD_SET_CORE })
    AddWordCoding('streetcar', { sound: TARGET_SOUND_S, level: LEVEL_2, position: POSITION_COMPLEX_BLEND, set: WORD_SET_CORE })
    AddWordCoding('stroller', { sound: TARGET_SOUND_S, level: LEVEL_2, position: POSITION_COMPLEX_BLEND, set: WORD_SET_CORE })

    AddWordCoding('astronaut', { sound: TARGET_SOUND_S, level: LEVEL_3, position: POSITION_COMPLEX_BLEND, set: WORD_SET_CORE })
    AddWordCoding('astronomy', { sound: TARGET_SOUND_S, level: LEVEL_3, position: POSITION_COMPLEX_BLEND, set: WORD_SET_CORE })
    AddWordCoding('Australia', { sound: TARGET_SOUND_S, level: LEVEL_3, position: POSITION_COMPLEX_BLEND, set: WORD_SET_CORE })
    AddWordCoding('boa constrictor', { sound: TARGET_SOUND_S, level: LEVEL_3, position: POSITION_COMPLEX_BLEND, set: WORD_SET_CORE })
    AddWordCoding('brussels sprouts', { sound: TARGET_SOUND_S, level: LEVEL_3, position: POSITION_COMPLEX_BLEND })
    AddWordCoding('ice cream truck', { sound: TARGET_SOUND_S, level: LEVEL_3, position: POSITION_COMPLEX_BLEND, set: WORD_SET_CORE })
    AddWordCoding('instrument', { sound: TARGET_SOUND_S, level: LEVEL_3, position: POSITION_COMPLEX_BLEND, set: WORD_SET_CORE })
    AddWordCoding('misplace', { sound: TARGET_SOUND_S, level: LEVEL_3, position: POSITION_COMPLEX_BLEND })
    AddWordCoding('ostrich', { sound: TARGET_SOUND_S, level: LEVEL_3, position: POSITION_COMPLEX_BLEND, set: WORD_SET_CORE })
    AddWordCoding('scratch', { sound: TARGET_SOUND_S, level: LEVEL_3, position: POSITION_COMPLEX_BLEND, set: WORD_SET_CORE })
    AddWordCoding('screech owl', { sound: TARGET_SOUND_S, level: LEVEL_3, position: POSITION_COMPLEX_BLEND })
    AddWordCoding('screwdriver', { sound: TARGET_SOUND_S, level: LEVEL_3, position: POSITION_COMPLEX_BLEND, set: WORD_SET_CORE })
    AddWordCoding('scrub brush', { sound: TARGET_SOUND_S, level: LEVEL_3, position: POSITION_COMPLEX_BLEND, set: WORD_SET_CORE })
    AddWordCoding('skyscraper', { sound: TARGET_SOUND_S, level: LEVEL_3, position: POSITION_COMPLEX_BLEND, set: WORD_SET_CORE })
    AddWordCoding('splash', { sound: TARGET_SOUND_S, level: LEVEL_3, position: POSITION_COMPLEX_BLEND })
    AddWordCoding('splashdown', { sound: TARGET_SOUND_S, level: LEVEL_3, position: POSITION_COMPLEX_BLEND })
    AddWordCoding('splotch', { sound: TARGET_SOUND_S, level: LEVEL_3, position: POSITION_COMPLEX_BLEND })
    AddWordCoding('strange', { sound: TARGET_SOUND_S, level: LEVEL_3, position: POSITION_COMPLEX_BLEND })
    AddWordCoding('string beans', { sound: TARGET_SOUND_S, level: LEVEL_3, position: POSITION_COMPLEX_BLEND, set: WORD_SET_CORE })

    // --------------------------------------------------------------------------------------------------
    //   Target sound SH
    // --------------------------------------------------------------------------------------------------

    // Target sound SH: Initial
    AddWordCoding('shade', { sound: TARGET_SOUND_SH, level: LEVEL_1, position: POSITION_INITIAL, set: WORD_SET_CORE })
    AddWordCoding('shape', { sound: TARGET_SOUND_SH, level: LEVEL_1, position: POSITION_INITIAL, set: WORD_SET_CORE })
    AddWordCoding('share', { sound: TARGET_SOUND_SH, level: LEVEL_1, position: POSITION_INITIAL, set: WORD_SET_CORE })
    AddWordCoding('she', { sound: TARGET_SOUND_SH, level: LEVEL_1, position: POSITION_INITIAL, set: WORD_SET_CORE })
    AddWordCoding('shed', { sound: TARGET_SOUND_SH, level: LEVEL_1, position: POSITION_INITIAL, set: WORD_SET_CORE })
    AddWordCoding('sheep', { sound: TARGET_SOUND_SH, level: LEVEL_1, position: POSITION_INITIAL, set: WORD_SET_CORE })
    AddWordCoding('sheer', { sound: TARGET_SOUND_SH, level: LEVEL_1, position: POSITION_INITIAL })
    AddWordCoding('sheet', { sound: TARGET_SOUND_SH, level: LEVEL_1, position: POSITION_INITIAL })
    AddWordCoding('shell', { sound: TARGET_SOUND_SH, level: LEVEL_1, position: POSITION_INITIAL, set: WORD_SET_CORE })
    AddWordCoding('shin', { sound: TARGET_SOUND_SH, level: LEVEL_1, position: POSITION_INITIAL })
    AddWordCoding('shine', { sound: TARGET_SOUND_SH, level: LEVEL_1, position: POSITION_INITIAL, set: WORD_SET_CORE })
    AddWordCoding('ship', { sound: TARGET_SOUND_SH, level: LEVEL_1, position: POSITION_INITIAL, set: WORD_SET_CORE })
    AddWordCoding('shock', { sound: TARGET_SOUND_SH, level: LEVEL_1, position: POSITION_INITIAL, set: WORD_SET_CORE })
    AddWordCoding('shook', { sound: TARGET_SOUND_SH, level: LEVEL_1, position: POSITION_INITIAL })
    AddWordCoding('shop', { sound: TARGET_SOUND_SH, level: LEVEL_1, position: POSITION_INITIAL, set: WORD_SET_CORE })
    AddWordCoding('shore', { sound: TARGET_SOUND_SH, level: LEVEL_1, position: POSITION_INITIAL })
    AddWordCoding('should', { sound: TARGET_SOUND_SH, level: LEVEL_1, position: POSITION_INITIAL })
    AddWordCoding('shout', { sound: TARGET_SOUND_SH, level: LEVEL_1, position: POSITION_INITIAL })
    AddWordCoding('show', { sound: TARGET_SOUND_SH, level: LEVEL_1, position: POSITION_INITIAL })
    AddWordCoding('shut', { sound: TARGET_SOUND_SH, level: LEVEL_1, position: POSITION_INITIAL })
    AddWordCoding('shy', { sound: TARGET_SOUND_SH, level: LEVEL_1, position: POSITION_INITIAL })
    AddWordCoding('sure', { sound: TARGET_SOUND_SH, level: LEVEL_1, position: POSITION_INITIAL })

    AddWordCoding('shadow', { sound: TARGET_SOUND_SH, level: LEVEL_2, position: POSITION_INITIAL, set: WORD_SET_CORE })
    AddWordCoding('shakers', { sound: TARGET_SOUND_SH, level: LEVEL_2, position: POSITION_INITIAL })
    AddWordCoding('shaking', { sound: TARGET_SOUND_SH, level: LEVEL_2, position: POSITION_INITIAL, set: WORD_SET_CORE })
    AddWordCoding('shallow', { sound: TARGET_SOUND_SH, level: LEVEL_2, position: POSITION_INITIAL, set: WORD_SET_CORE })
    AddWordCoding('shampoo', { sound: TARGET_SOUND_SH, level: LEVEL_2, position: POSITION_INITIAL, set: WORD_SET_CORE })
    AddWordCoding('sharing', { sound: TARGET_SOUND_SH, level: LEVEL_2, position: POSITION_INITIAL, set: WORD_SET_CORE })
    AddWordCoding('shark', { sound: TARGET_SOUND_SH, level: LEVEL_2, position: POSITION_INITIAL, set: WORD_SET_CORE })
    AddWordCoding('sharp', { sound: TARGET_SOUND_SH, level: LEVEL_2, position: POSITION_INITIAL, set: WORD_SET_CORE })
    AddWordCoding('sharper', { sound: TARGET_SOUND_SH, level: LEVEL_2, position: POSITION_INITIAL })
    AddWordCoding('shearing', { sound: TARGET_SOUND_SH, level: LEVEL_2, position: POSITION_INITIAL })
    AddWordCoding('sherbet', { sound: TARGET_SOUND_SH, level: LEVEL_2, position: POSITION_INITIAL })
    AddWordCoding('shield', { sound: TARGET_SOUND_SH, level: LEVEL_2, position: POSITION_INITIAL, set: WORD_SET_CORE })
    AddWordCoding('shiny', { sound: TARGET_SOUND_SH, level: LEVEL_2, position: POSITION_INITIAL, set: WORD_SET_CORE })
    AddWordCoding('shirt', { sound: TARGET_SOUND_SH, level: LEVEL_2, position: POSITION_INITIAL, set: WORD_SET_CORE })
    AddWordCoding('shopped', { sound: TARGET_SOUND_SH, level: LEVEL_2, position: POSITION_INITIAL })
    AddWordCoding('shopper', { sound: TARGET_SOUND_SH, level: LEVEL_2, position: POSITION_INITIAL })
    AddWordCoding('shopping', { sound: TARGET_SOUND_SH, level: LEVEL_2, position: POSITION_INITIAL })
    AddWordCoding('short', { sound: TARGET_SOUND_SH, level: LEVEL_2, position: POSITION_INITIAL, set: WORD_SET_CORE })
    AddWordCoding('shower', { sound: TARGET_SOUND_SH, level: LEVEL_2, position: POSITION_INITIAL, set: WORD_SET_CORE })
    AddWordCoding('shutters', { sound: TARGET_SOUND_SH, level: LEVEL_2, position: POSITION_INITIAL })
    AddWordCoding('sugar', { sound: TARGET_SOUND_SH, level: LEVEL_2, position: POSITION_INITIAL })

    AddWordCoding('chef', { sound: TARGET_SOUND_SH, level: LEVEL_3, position: POSITION_INITIAL, set: WORD_SET_CORE })
    AddWordCoding('shamrock', { sound: TARGET_SOUND_SH, level: LEVEL_3, position: POSITION_INITIAL })
    AddWordCoding('sharpener', { sound: TARGET_SOUND_SH, level: LEVEL_3, position: POSITION_INITIAL, set: WORD_SET_CORE })
    AddWordCoding('shave', { sound: TARGET_SOUND_SH, level: LEVEL_3, position: POSITION_INITIAL, set: WORD_SET_CORE })
    AddWordCoding('shaving cream', { sound: TARGET_SOUND_SH, level: LEVEL_3, position: POSITION_INITIAL, set: WORD_SET_CORE })
    AddWordCoding('shears', { sound: TARGET_SOUND_SH, level: LEVEL_3, position: POSITION_INITIAL })
    AddWordCoding('sheepdog', { sound: TARGET_SOUND_SH, level: LEVEL_3, position: POSITION_INITIAL, set: WORD_SET_CORE })
    AddWordCoding('shelf', { sound: TARGET_SOUND_SH, level: LEVEL_3, position: POSITION_INITIAL, set: WORD_SET_CORE })
    AddWordCoding('shin guard', { sound: TARGET_SOUND_SH, level: LEVEL_3, position: POSITION_INITIAL })
    AddWordCoding('shoelace', { sound: TARGET_SOUND_SH, level: LEVEL_3, position: POSITION_INITIAL, set: WORD_SET_CORE })
    AddWordCoding('shooting star', { sound: TARGET_SOUND_SH, level: LEVEL_3, position: POSITION_INITIAL, set: WORD_SET_CORE })
    AddWordCoding('shopping bag', { sound: TARGET_SOUND_SH, level: LEVEL_3, position: POSITION_INITIAL, set: WORD_SET_CORE })
    AddWordCoding('shopping cart', { sound: TARGET_SOUND_SH, level: LEVEL_3, position: POSITION_INITIAL, set: WORD_SET_CORE })
    AddWordCoding('shoulder', { sound: TARGET_SOUND_SH, level: LEVEL_3, position: POSITION_INITIAL, set: WORD_SET_CORE })
    AddWordCoding('shovel', { sound: TARGET_SOUND_SH, level: LEVEL_3, position: POSITION_INITIAL, set: WORD_SET_CORE })
    AddWordCoding('shuffleboard', { sound: TARGET_SOUND_SH, level: LEVEL_3, position: POSITION_INITIAL })

    // Target sound SH: Medial
    AddWordCoding('cashier', { sound: TARGET_SOUND_SH, level: LEVEL_1, position: POSITION_MEDIAL, set: WORD_SET_CORE })
    AddWordCoding('lotion', { sound: TARGET_SOUND_SH, level: LEVEL_1, position: POSITION_MEDIAL, set: WORD_SET_CORE })
    AddWordCoding('machine', { sound: TARGET_SOUND_SH, level: LEVEL_1, position: POSITION_MEDIAL, set: WORD_SET_CORE })
    AddWordCoding('ocean', { sound: TARGET_SOUND_SH, level: LEVEL_1, position: POSITION_MEDIAL, set: WORD_SET_CORE })
    AddWordCoding('pushing', { sound: TARGET_SOUND_SH, level: LEVEL_1, position: POSITION_MEDIAL, set: WORD_SET_CORE })
    AddWordCoding('tissue', { sound: TARGET_SOUND_SH, level: LEVEL_1, position: POSITION_MEDIAL, set: WORD_SET_CORE })
    AddWordCoding('washer', { sound: TARGET_SOUND_SH, level: LEVEL_1, position: POSITION_MEDIAL, set: WORD_SET_CORE })
    AddWordCoding('cushion', { sound: TARGET_SOUND_SH, level: LEVEL_1, position: POSITION_MEDIAL, set: WORD_SET_CORE })
    AddWordCoding('wishing', { sound: TARGET_SOUND_SH, level: LEVEL_1, position: POSITION_MEDIAL, set: WORD_SET_CORE })
    AddWordCoding('mushy', { sound: TARGET_SOUND_SH, level: LEVEL_1, position: POSITION_MEDIAL, set: WORD_SET_CORE })
    AddWordCoding('gushing', { sound: TARGET_SOUND_SH, level: LEVEL_1, position: POSITION_MEDIAL })
    AddWordCoding('hushing', { sound: TARGET_SOUND_SH, level: LEVEL_1, position: POSITION_MEDIAL, set: WORD_SET_CORE })
    AddWordCoding('t-shirt', { sound: TARGET_SOUND_SH, level: LEVEL_1, position: POSITION_MEDIAL, set: WORD_SET_CORE })
    AddWordCoding('Russia', { sound: TARGET_SOUND_SH, level: LEVEL_1, position: POSITION_MEDIAL })

    AddWordCoding('flashy', { sound: TARGET_SOUND_SH, level: LEVEL_2, position: POSITION_MEDIAL })
    AddWordCoding('fashion', { sound: TARGET_SOUND_SH, level: LEVEL_2, position: POSITION_MEDIAL, set: WORD_SET_CORE })
    AddWordCoding('fiction', { sound: TARGET_SOUND_SH, level: LEVEL_2, position: POSITION_MEDIAL, set: WORD_SET_CORE })
    AddWordCoding('fishing', { sound: TARGET_SOUND_SH, level: LEVEL_2, position: POSITION_MEDIAL, set: WORD_SET_CORE })
    AddWordCoding('glacier', { sound: TARGET_SOUND_SH, level: LEVEL_2, position: POSITION_MEDIAL })
    AddWordCoding('mansion', { sound: TARGET_SOUND_SH, level: LEVEL_2, position: POSITION_MEDIAL })
    AddWordCoding('mushroom', { sound: TARGET_SOUND_SH, level: LEVEL_2, position: POSITION_MEDIAL, set: WORD_SET_CORE })
    AddWordCoding('addition', { sound: TARGET_SOUND_SH, level: LEVEL_2, position: POSITION_MEDIAL, set: WORD_SET_CORE })
    AddWordCoding('direction', { sound: TARGET_SOUND_SH, level: LEVEL_2, position: POSITION_MEDIAL, set: WORD_SET_CORE })
    AddWordCoding('eruption', { sound: TARGET_SOUND_SH, level: LEVEL_2, position: POSITION_MEDIAL })
    AddWordCoding('parachute', { sound: TARGET_SOUND_SH, level: LEVEL_2, position: POSITION_MEDIAL, set: WORD_SET_CORE })
    AddWordCoding('black sheep', { sound: TARGET_SOUND_SH, level: LEVEL_2, position: POSITION_MEDIAL, set: WORD_SET_CORE })
    AddWordCoding('rocket ship', { sound: TARGET_SOUND_SH, level: LEVEL_2, position: POSITION_MEDIAL, set: WORD_SET_CORE })
    AddWordCoding('dashboard', { sound: TARGET_SOUND_SH, level: LEVEL_2, position: POSITION_MEDIAL })
    AddWordCoding('milkshake', { sound: TARGET_SOUND_SH, level: LEVEL_2, position: POSITION_MEDIAL, set: WORD_SET_CORE })
    AddWordCoding('vacation', { sound: TARGET_SOUND_SH, level: LEVEL_2, position: POSITION_MEDIAL, set: WORD_SET_CORE })
    AddWordCoding('brushing', { sound: TARGET_SOUND_SH, level: LEVEL_2, position: POSITION_MEDIAL, set: WORD_SET_CORE })

    AddWordCoding('dishes', { sound: TARGET_SOUND_SH, level: LEVEL_3, position: POSITION_MEDIAL, set: WORD_SET_CORE })
    AddWordCoding('dishcloth', { sound: TARGET_SOUND_SH, level: LEVEL_3, position: POSITION_MEDIAL })
    AddWordCoding('delicious', { sound: TARGET_SOUND_SH, level: LEVEL_3, position: POSITION_MEDIAL, set: WORD_SET_CORE })
    AddWordCoding('flashcard', { sound: TARGET_SOUND_SH, level: LEVEL_3, position: POSITION_MEDIAL })
    AddWordCoding('flashlight', { sound: TARGET_SOUND_SH, level: LEVEL_3, position: POSITION_MEDIAL, set: WORD_SET_CORE })
    AddWordCoding('fraction', { sound: TARGET_SOUND_SH, level: LEVEL_3, position: POSITION_MEDIAL, set: WORD_SET_CORE })
    AddWordCoding('marshmallow', { sound: TARGET_SOUND_SH, level: LEVEL_3, position: POSITION_MEDIAL, set: WORD_SET_CORE })
    AddWordCoding('seashell', { sound: TARGET_SOUND_SH, level: LEVEL_3, position: POSITION_MEDIAL, set: WORD_SET_CORE })
    AddWordCoding('slingshot', { sound: TARGET_SOUND_SH, level: LEVEL_3, position: POSITION_MEDIAL })
    AddWordCoding('snowshoes', { sound: TARGET_SOUND_SH, level: LEVEL_3, position: POSITION_MEDIAL })
    AddWordCoding('sunshine', { sound: TARGET_SOUND_SH, level: LEVEL_3, position: POSITION_MEDIAL, set: WORD_SET_CORE })
    AddWordCoding('sushi', { sound: TARGET_SOUND_SH, level: LEVEL_3, position: POSITION_MEDIAL })
    AddWordCoding('sweatshirt', { sound: TARGET_SOUND_SH, level: LEVEL_3, position: POSITION_MEDIAL })
    AddWordCoding('Washington', { sound: TARGET_SOUND_SH, level: LEVEL_3, position: POSITION_MEDIAL, set: WORD_SET_CORE })
    AddWordCoding('celebration', { sound: TARGET_SOUND_SH, level: LEVEL_3, position: POSITION_MEDIAL })
    AddWordCoding('station', { sound: TARGET_SOUND_SH, level: LEVEL_3, position: POSITION_MEDIAL })
    AddWordCoding('reflection', { sound: TARGET_SOUND_SH, level: LEVEL_3, position: POSITION_MEDIAL })
    AddWordCoding('special', { sound: TARGET_SOUND_SH, level: LEVEL_3, position: POSITION_MEDIAL })
    AddWordCoding('dishwasher', { sound: TARGET_SOUND_SH, level: LEVEL_3, position: POSITION_MEDIAL, set: WORD_SET_CORE })
    AddWordCoding('dictionary', { sound: TARGET_SOUND_SH, level: LEVEL_3, position: POSITION_MEDIAL, set: WORD_SET_CORE })
    AddWordCoding('graduation', { sound: TARGET_SOUND_SH, level: LEVEL_3, position: POSITION_MEDIAL })
    AddWordCoding('transportation', { sound: TARGET_SOUND_SH, level: LEVEL_3, position: POSITION_MEDIAL })
    AddWordCoding('multiplication', { sound: TARGET_SOUND_SH, level: LEVEL_3, position: POSITION_MEDIAL, set: WORD_SET_CORE })
    AddWordCoding('New Hampshire', { sound: TARGET_SOUND_SH, level: LEVEL_3, position: POSITION_MEDIAL })
    AddWordCoding('mashed potatoes', { sound: TARGET_SOUND_SH, level: LEVEL_3, position: POSITION_MEDIAL })
    AddWordCoding('congratulations', { sound: TARGET_SOUND_SH, level: LEVEL_3, position: POSITION_MEDIAL })
    AddWordCoding('fishing rod', { sound: TARGET_SOUND_SH, level: LEVEL_3, position: POSITION_MEDIAL })
    AddWordCoding('fishbowl', { sound: TARGET_SOUND_SH, level: LEVEL_3, position: POSITION_MEDIAL, set: WORD_SET_CORE })

    // Target sound SH: Final
    AddWordCoding('lash', { sound: TARGET_SOUND_SH, level: LEVEL_1, position: POSITION_FINAL })
    AddWordCoding('leash', { sound: TARGET_SOUND_SH, level: LEVEL_1, position: POSITION_FINAL, set: WORD_SET_CORE })
    AddWordCoding('dish', { sound: TARGET_SOUND_SH, level: LEVEL_1, position: POSITION_FINAL, set: WORD_SET_CORE })
    AddWordCoding('fish', { sound: TARGET_SOUND_SH, level: LEVEL_1, position: POSITION_FINAL, set: WORD_SET_CORE })
    AddWordCoding('rash', { sound: TARGET_SOUND_SH, level: LEVEL_1, position: POSITION_FINAL, set: WORD_SET_CORE })
    AddWordCoding('rush', { sound: TARGET_SOUND_SH, level: LEVEL_1, position: POSITION_FINAL, set: WORD_SET_CORE })
    AddWordCoding('wish', { sound: TARGET_SOUND_SH, level: LEVEL_1, position: POSITION_FINAL, set: WORD_SET_CORE })
    AddWordCoding('hush', { sound: TARGET_SOUND_SH, level: LEVEL_1, position: POSITION_FINAL, set: WORD_SET_CORE })
    AddWordCoding('wash', { sound: TARGET_SOUND_SH, level: LEVEL_1, position: POSITION_FINAL, set: WORD_SET_CORE })
    AddWordCoding('bush', { sound: TARGET_SOUND_SH, level: LEVEL_1, position: POSITION_FINAL, set: WORD_SET_CORE })
    AddWordCoding('bash', { sound: TARGET_SOUND_SH, level: LEVEL_1, position: POSITION_FINAL, set: WORD_SET_CORE })

    AddWordCoding('blush', { sound: TARGET_SOUND_SH, level: LEVEL_2, position: POSITION_FINAL, set: WORD_SET_CORE })
    AddWordCoding('flash', { sound: TARGET_SOUND_SH, level: LEVEL_2, position: POSITION_FINAL, set: WORD_SET_CORE })
    AddWordCoding('flush', { sound: TARGET_SOUND_SH, level: LEVEL_2, position: POSITION_FINAL, set: WORD_SET_CORE })
    AddWordCoding('plush', { sound: TARGET_SOUND_SH, level: LEVEL_2, position: POSITION_FINAL, set: WORD_SET_CORE })
    AddWordCoding('brush', { sound: TARGET_SOUND_SH, level: LEVEL_2, position: POSITION_FINAL, set: WORD_SET_CORE })
    AddWordCoding('fresh', { sound: TARGET_SOUND_SH, level: LEVEL_2, position: POSITION_FINAL, set: WORD_SET_CORE })
    AddWordCoding('finish', { sound: TARGET_SOUND_SH, level: LEVEL_2, position: POSITION_FINAL, set: WORD_SET_CORE })
    AddWordCoding('radish', { sound: TARGET_SOUND_SH, level: LEVEL_2, position: POSITION_FINAL, set: WORD_SET_CORE })
    AddWordCoding('crash', { sound: TARGET_SOUND_SH, level: LEVEL_2, position: POSITION_FINAL, set: WORD_SET_CORE })
    AddWordCoding('eyelash', { sound: TARGET_SOUND_SH, level: LEVEL_2, position: POSITION_FINAL, set: WORD_SET_CORE })
    AddWordCoding('British', { sound: TARGET_SOUND_SH, level: LEVEL_2, position: POSITION_FINAL })

    AddWordCoding('goldfish', { sound: TARGET_SOUND_SH, level: LEVEL_3, position: POSITION_FINAL, set: WORD_SET_CORE })
    AddWordCoding('scrub brush', { sound: TARGET_SOUND_SH, level: LEVEL_3, position: POSITION_FINAL })
    AddWordCoding('smash', { sound: TARGET_SOUND_SH, level: LEVEL_3, position: POSITION_FINAL, set: WORD_SET_CORE })
    AddWordCoding('starfish', { sound: TARGET_SOUND_SH, level: LEVEL_3, position: POSITION_FINAL, set: WORD_SET_CORE })
    AddWordCoding('Swedish Fish', { sound: TARGET_SOUND_SH, level: LEVEL_3, position: POSITION_FINAL })
    AddWordCoding('licorice', { sound: TARGET_SOUND_SH, level: LEVEL_3, position: POSITION_FINAL, set: WORD_SET_CORE })
    AddWordCoding('shellfish', { sound: TARGET_SOUND_SH, level: LEVEL_3, position: POSITION_FINAL })
    AddWordCoding('splash', { sound: TARGET_SOUND_SH, level: LEVEL_3, position: POSITION_FINAL, set: WORD_SET_CORE })
    AddWordCoding('trash', { sound: TARGET_SOUND_SH, level: LEVEL_3, position: POSITION_FINAL, set: WORD_SET_CORE })
    AddWordCoding('car wash', { sound: TARGET_SOUND_SH, level: LEVEL_3, position: POSITION_FINAL, set: WORD_SET_CORE })
    AddWordCoding('mouthwash', { sound: TARGET_SOUND_SH, level: LEVEL_3, position: POSITION_FINAL })
    AddWordCoding('mustache', { sound: TARGET_SOUND_SH, level: LEVEL_3, position: POSITION_FINAL, set: WORD_SET_CORE })
    AddWordCoding('paintbrush', { sound: TARGET_SOUND_SH, level: LEVEL_3, position: POSITION_FINAL, set: WORD_SET_CORE })
    AddWordCoding('nail polish', { sound: TARGET_SOUND_SH, level: LEVEL_3, position: POSITION_FINAL })
    AddWordCoding('toothbrush', { sound: TARGET_SOUND_SH, level: LEVEL_3, position: POSITION_FINAL, set: WORD_SET_CORE })
    AddWordCoding('Spanish', { sound: TARGET_SOUND_SH, level: LEVEL_3, position: POSITION_FINAL })
    AddWordCoding('refresh', { sound: TARGET_SOUND_SH, level: LEVEL_3, position: POSITION_FINAL })

    // --------------------------------------------------------------------------------------------------
    //   Target sound TH
    // --------------------------------------------------------------------------------------------------

    // Target sound TH: Voiceless Initial
    AddWordCoding('thaw', { sound: TARGET_SOUND_TH, level: LEVEL_1, position: POSITION_VOICELESS_INITIAL, set: WORD_SET_CORE })
    AddWordCoding('thick', { sound: TARGET_SOUND_TH, level: LEVEL_1, position: POSITION_VOICELESS_INITIAL, set: WORD_SET_CORE })
    AddWordCoding('thigh', { sound: TARGET_SOUND_TH, level: LEVEL_1, position: POSITION_VOICELESS_INITIAL, set: WORD_SET_CORE })
    AddWordCoding('thin', { sound: TARGET_SOUND_TH, level: LEVEL_1, position: POSITION_VOICELESS_INITIAL, set: WORD_SET_CORE })
    AddWordCoding('thing', { sound: TARGET_SOUND_TH, level: LEVEL_1, position: POSITION_VOICELESS_INITIAL, set: WORD_SET_CORE })
    AddWordCoding('think', { sound: TARGET_SOUND_TH, level: LEVEL_1, position: POSITION_VOICELESS_INITIAL, set: WORD_SET_CORE })
    AddWordCoding('third', { sound: TARGET_SOUND_TH, level: LEVEL_1, position: POSITION_VOICELESS_INITIAL, set: WORD_SET_CORE })
    AddWordCoding('Thor', { sound: TARGET_SOUND_TH, level: LEVEL_1, position: POSITION_VOICELESS_INITIAL, set: WORD_SET_CORE })
    AddWordCoding('thorn', { sound: TARGET_SOUND_TH, level: LEVEL_1, position: POSITION_VOICELESS_INITIAL, set: WORD_SET_CORE })
    AddWordCoding('thought', { sound: TARGET_SOUND_TH, level: LEVEL_1, position: POSITION_VOICELESS_INITIAL, set: WORD_SET_CORE })
    AddWordCoding('thumb', { sound: TARGET_SOUND_TH, level: LEVEL_1, position: POSITION_VOICELESS_INITIAL, set: WORD_SET_CORE })
    AddWordCoding('thunder', { sound: TARGET_SOUND_TH, level: LEVEL_1, position: POSITION_VOICELESS_INITIAL, set: WORD_SET_CORE })

    AddWordCoding('thank you', { sound: TARGET_SOUND_TH, level: LEVEL_2, position: POSITION_VOICELESS_INITIAL, set: WORD_SET_CORE })
    AddWordCoding('thanks', { sound: TARGET_SOUND_TH, level: LEVEL_2, position: POSITION_VOICELESS_INITIAL })
    AddWordCoding('theater', { sound: TARGET_SOUND_TH, level: LEVEL_2, position: POSITION_VOICELESS_INITIAL })
    AddWordCoding('thermos', { sound: TARGET_SOUND_TH, level: LEVEL_2, position: POSITION_VOICELESS_INITIAL, set: WORD_SET_CORE })
    AddWordCoding('thinking', { sound: TARGET_SOUND_TH, level: LEVEL_2, position: POSITION_VOICELESS_INITIAL, set: WORD_SET_CORE })
    AddWordCoding('thirsty', { sound: TARGET_SOUND_TH, level: LEVEL_2, position: POSITION_VOICELESS_INITIAL, set: WORD_SET_CORE })
    AddWordCoding('thirteen', { sound: TARGET_SOUND_TH, level: LEVEL_2, position: POSITION_VOICELESS_INITIAL, set: WORD_SET_CORE })
    AddWordCoding('thirty', { sound: TARGET_SOUND_TH, level: LEVEL_2, position: POSITION_VOICELESS_INITIAL, set: WORD_SET_CORE })
    AddWordCoding('thousand', { sound: TARGET_SOUND_TH, level: LEVEL_2, position: POSITION_VOICELESS_INITIAL, set: WORD_SET_CORE })
    AddWordCoding('thread', { sound: TARGET_SOUND_TH, level: LEVEL_2, position: POSITION_VOICELESS_INITIAL })
    AddWordCoding('three', { sound: TARGET_SOUND_TH, level: LEVEL_2, position: POSITION_VOICELESS_INITIAL, set: WORD_SET_CORE })
    AddWordCoding('threw', { sound: TARGET_SOUND_TH, level: LEVEL_2, position: POSITION_VOICELESS_INITIAL })
    AddWordCoding('throat', { sound: TARGET_SOUND_TH, level: LEVEL_2, position: POSITION_VOICELESS_INITIAL, set: WORD_SET_CORE })
    AddWordCoding('throne', { sound: TARGET_SOUND_TH, level: LEVEL_2, position: POSITION_VOICELESS_INITIAL, set: WORD_SET_CORE })
    AddWordCoding('through', { sound: TARGET_SOUND_TH, level: LEVEL_2, position: POSITION_VOICELESS_INITIAL })
    AddWordCoding('throw', { sound: TARGET_SOUND_TH, level: LEVEL_2, position: POSITION_VOICELESS_INITIAL, set: WORD_SET_CORE })
    AddWordCoding('thumbtack', { sound: TARGET_SOUND_TH, level: LEVEL_2, position: POSITION_VOICELESS_INITIAL })
    AddWordCoding('Thursday', { sound: TARGET_SOUND_TH, level: LEVEL_2, position: POSITION_VOICELESS_INITIAL, set: WORD_SET_CORE })

    AddWordCoding('thank you note', { sound: TARGET_SOUND_TH, level: LEVEL_3, position: POSITION_VOICELESS_INITIAL, set: WORD_SET_CORE })
    AddWordCoding('thankful', { sound: TARGET_SOUND_TH, level: LEVEL_3, position: POSITION_VOICELESS_INITIAL, set: WORD_SET_CORE })
    AddWordCoding('thermometer', { sound: TARGET_SOUND_TH, level: LEVEL_3, position: POSITION_VOICELESS_INITIAL, set: WORD_SET_CORE })
    AddWordCoding('thermostat', { sound: TARGET_SOUND_TH, level: LEVEL_3, position: POSITION_VOICELESS_INITIAL, set: WORD_SET_CORE })
    AddWordCoding('thesaurus', { sound: TARGET_SOUND_TH, level: LEVEL_3, position: POSITION_VOICELESS_INITIAL })
    AddWordCoding('thief', { sound: TARGET_SOUND_TH, level: LEVEL_3, position: POSITION_VOICELESS_INITIAL, set: WORD_SET_CORE })
    AddWordCoding('third base', { sound: TARGET_SOUND_TH, level: LEVEL_3, position: POSITION_VOICELESS_INITIAL, set: WORD_SET_CORE })
    AddWordCoding('third baseman', { sound: TARGET_SOUND_TH, level: LEVEL_3, position: POSITION_VOICELESS_INITIAL, set: WORD_SET_CORE })
    AddWordCoding('thorax', { sound: TARGET_SOUND_TH, level: LEVEL_3, position: POSITION_VOICELESS_INITIAL, set: WORD_SET_CORE })
    AddWordCoding('three-quarters', { sound: TARGET_SOUND_TH, level: LEVEL_3, position: POSITION_VOICELESS_INITIAL, set: WORD_SET_CORE })
    AddWordCoding('throw rug', { sound: TARGET_SOUND_TH, level: LEVEL_3, position: POSITION_VOICELESS_INITIAL, set: WORD_SET_CORE })
    AddWordCoding('thruway', { sound: TARGET_SOUND_TH, level: LEVEL_3, position: POSITION_VOICELESS_INITIAL, set: WORD_SET_CORE })
    AddWordCoding('thunderstorm', { sound: TARGET_SOUND_TH, level: LEVEL_3, position: POSITION_VOICELESS_INITIAL, set: WORD_SET_CORE })

    // Target sound TH: Voiceless Medial
    AddWordCoding('Arthur', { sound: TARGET_SOUND_TH, level: LEVEL_1, position: POSITION_VOICELESS_MEDIAL, set: WORD_SET_CORE })
    AddWordCoding('author', { sound: TARGET_SOUND_TH, level: LEVEL_1, position: POSITION_VOICELESS_MEDIAL, set: WORD_SET_CORE })
    AddWordCoding('method', { sound: TARGET_SOUND_TH, level: LEVEL_1, position: POSITION_VOICELESS_MEDIAL, set: WORD_SET_CORE })
    AddWordCoding('nothing', { sound: TARGET_SOUND_TH, level: LEVEL_1, position: POSITION_VOICELESS_MEDIAL, set: WORD_SET_CORE })
    AddWordCoding('python', { sound: TARGET_SOUND_TH, level: LEVEL_1, position: POSITION_VOICELESS_MEDIAL, set: WORD_SET_CORE })
    AddWordCoding('without', { sound: TARGET_SOUND_TH, level: LEVEL_1, position: POSITION_VOICELESS_MEDIAL, set: WORD_SET_CORE })

    AddWordCoding('anything', { sound: TARGET_SOUND_TH, level: LEVEL_2, position: POSITION_VOICELESS_MEDIAL, set: WORD_SET_CORE })
    AddWordCoding('Athens', { sound: TARGET_SOUND_TH, level: LEVEL_2, position: POSITION_VOICELESS_MEDIAL, set: WORD_SET_CORE })
    AddWordCoding('athlete', { sound: TARGET_SOUND_TH, level: LEVEL_2, position: POSITION_VOICELESS_MEDIAL, set: WORD_SET_CORE })
    AddWordCoding('everything', { sound: TARGET_SOUND_TH, level: LEVEL_2, position: POSITION_VOICELESS_MEDIAL, set: WORD_SET_CORE })
    AddWordCoding('filthy', { sound: TARGET_SOUND_TH, level: LEVEL_2, position: POSITION_VOICELESS_MEDIAL, set: WORD_SET_CORE })
    AddWordCoding('healthy', { sound: TARGET_SOUND_TH, level: LEVEL_2, position: POSITION_VOICELESS_MEDIAL, set: WORD_SET_CORE })
    AddWordCoding('marathon', { sound: TARGET_SOUND_TH, level: LEVEL_2, position: POSITION_VOICELESS_MEDIAL, set: WORD_SET_CORE })
    AddWordCoding('monthly', { sound: TARGET_SOUND_TH, level: LEVEL_2, position: POSITION_VOICELESS_MEDIAL, set: WORD_SET_CORE })
    AddWordCoding('panther', { sound: TARGET_SOUND_TH, level: LEVEL_2, position: POSITION_VOICELESS_MEDIAL, set: WORD_SET_CORE })
    AddWordCoding('pathway', { sound: TARGET_SOUND_TH, level: LEVEL_2, position: POSITION_VOICELESS_MEDIAL, set: WORD_SET_CORE })
    AddWordCoding('rethink', { sound: TARGET_SOUND_TH, level: LEVEL_2, position: POSITION_VOICELESS_MEDIAL, set: WORD_SET_CORE })

    AddWordCoding('bathmat', { sound: TARGET_SOUND_TH, level: LEVEL_3, position: POSITION_VOICELESS_MEDIAL })
    AddWordCoding('bathrobe', { sound: TARGET_SOUND_TH, level: LEVEL_3, position: POSITION_VOICELESS_MEDIAL })
    AddWordCoding('bathroom', { sound: TARGET_SOUND_TH, level: LEVEL_3, position: POSITION_VOICELESS_MEDIAL, set: WORD_SET_CORE })
    AddWordCoding('bathtub', { sound: TARGET_SOUND_TH, level: LEVEL_3, position: POSITION_VOICELESS_MEDIAL, set: WORD_SET_CORE })
    AddWordCoding('birthday', { sound: TARGET_SOUND_TH, level: LEVEL_3, position: POSITION_VOICELESS_MEDIAL, set: WORD_SET_CORE })
    AddWordCoding('birthday party', { sound: TARGET_SOUND_TH, level: LEVEL_3, position: POSITION_VOICELESS_MEDIAL })
    AddWordCoding('earthquake', { sound: TARGET_SOUND_TH, level: LEVEL_3, position: POSITION_VOICELESS_MEDIAL })
    AddWordCoding('earthworm', { sound: TARGET_SOUND_TH, level: LEVEL_3, position: POSITION_VOICELESS_MEDIAL })
    AddWordCoding('fifth grade', { sound: TARGET_SOUND_TH, level: LEVEL_3, position: POSITION_VOICELESS_MEDIAL, set: WORD_SET_CORE })
    AddWordCoding('happy birthday', { sound: TARGET_SOUND_TH, level: LEVEL_3, position: POSITION_VOICELESS_MEDIAL, set: WORD_SET_CORE })
    AddWordCoding('math homework', { sound: TARGET_SOUND_TH, level: LEVEL_3, position: POSITION_VOICELESS_MEDIAL })
    AddWordCoding('mouthwash', { sound: TARGET_SOUND_TH, level: LEVEL_3, position: POSITION_VOICELESS_MEDIAL })
    AddWordCoding('North Dakota', { sound: TARGET_SOUND_TH, level: LEVEL_3, position: POSITION_VOICELESS_MEDIAL, set: WORD_SET_CORE })
    AddWordCoding('North Pole', { sound: TARGET_SOUND_TH, level: LEVEL_3, position: POSITION_VOICELESS_MEDIAL })
    AddWordCoding('North Star', { sound: TARGET_SOUND_TH, level: LEVEL_3, position: POSITION_VOICELESS_MEDIAL })
    AddWordCoding('orthodontist', { sound: TARGET_SOUND_TH, level: LEVEL_3, position: POSITION_VOICELESS_MEDIAL })
    AddWordCoding('sixth grade', { sound: TARGET_SOUND_TH, level: LEVEL_3, position: POSITION_VOICELESS_MEDIAL, set: WORD_SET_CORE })
    AddWordCoding('something', { sound: TARGET_SOUND_TH, level: LEVEL_3, position: POSITION_VOICELESS_MEDIAL })
    AddWordCoding('South Carolina', { sound: TARGET_SOUND_TH, level: LEVEL_3, position: POSITION_VOICELESS_MEDIAL, set: WORD_SET_CORE })
    AddWordCoding('South Dakota', { sound: TARGET_SOUND_TH, level: LEVEL_3, position: POSITION_VOICELESS_MEDIAL, set: WORD_SET_CORE })
    AddWordCoding('southeast', { sound: TARGET_SOUND_TH, level: LEVEL_3, position: POSITION_VOICELESS_MEDIAL })
    AddWordCoding('stethoscope', { sound: TARGET_SOUND_TH, level: LEVEL_3, position: POSITION_VOICELESS_MEDIAL })
    AddWordCoding('thirty-third', { sound: TARGET_SOUND_TH, level: LEVEL_3, position: POSITION_VOICELESS_MEDIAL })
    AddWordCoding('toothbrush', { sound: TARGET_SOUND_TH, level: LEVEL_3, position: POSITION_VOICELESS_MEDIAL, set: WORD_SET_CORE })
    AddWordCoding('toothpaste', { sound: TARGET_SOUND_TH, level: LEVEL_3, position: POSITION_VOICELESS_MEDIAL, set: WORD_SET_CORE })
    AddWordCoding('toothpick', { sound: TARGET_SOUND_TH, level: LEVEL_3, position: POSITION_VOICELESS_MEDIAL })
    AddWordCoding('two-thirty', { sound: TARGET_SOUND_TH, level: LEVEL_3, position: POSITION_VOICELESS_MEDIAL, set: WORD_SET_CORE })

    // Target sound TH: Voiceless Final
    AddWordCoding('bath', { sound: TARGET_SOUND_TH, level: LEVEL_1, position: POSITION_VOICELESS_FINAL, set: WORD_SET_CORE })
    AddWordCoding('booth', { sound: TARGET_SOUND_TH, level: LEVEL_1, position: POSITION_VOICELESS_FINAL, set: WORD_SET_CORE })
    AddWordCoding('both', { sound: TARGET_SOUND_TH, level: LEVEL_1, position: POSITION_VOICELESS_FINAL })
    AddWordCoding('breath', { sound: TARGET_SOUND_TH, level: LEVEL_1, position: POSITION_VOICELESS_FINAL, set: WORD_SET_CORE })
    AddWordCoding('broth', { sound: TARGET_SOUND_TH, level: LEVEL_1, position: POSITION_VOICELESS_FINAL })
    AddWordCoding('cloth', { sound: TARGET_SOUND_TH, level: LEVEL_1, position: POSITION_VOICELESS_FINAL, set: WORD_SET_CORE })
    AddWordCoding('earth', { sound: TARGET_SOUND_TH, level: LEVEL_1, position: POSITION_VOICELESS_FINAL, set: WORD_SET_CORE })
    AddWordCoding('eighth', { sound: TARGET_SOUND_TH, level: LEVEL_1, position: POSITION_VOICELESS_FINAL })
    AddWordCoding('math', { sound: TARGET_SOUND_TH, level: LEVEL_1, position: POSITION_VOICELESS_FINAL, set: WORD_SET_CORE })
    AddWordCoding('moth', { sound: TARGET_SOUND_TH, level: LEVEL_1, position: POSITION_VOICELESS_FINAL, set: WORD_SET_CORE })
    AddWordCoding('mouth', { sound: TARGET_SOUND_TH, level: LEVEL_1, position: POSITION_VOICELESS_FINAL, set: WORD_SET_CORE })
    AddWordCoding('north', { sound: TARGET_SOUND_TH, level: LEVEL_1, position: POSITION_VOICELESS_FINAL, set: WORD_SET_CORE })
    AddWordCoding('path', { sound: TARGET_SOUND_TH, level: LEVEL_1, position: POSITION_VOICELESS_FINAL, set: WORD_SET_CORE })
    AddWordCoding('teeth', { sound: TARGET_SOUND_TH, level: LEVEL_1, position: POSITION_VOICELESS_FINAL, set: WORD_SET_CORE })
    AddWordCoding('tooth', { sound: TARGET_SOUND_TH, level: LEVEL_1, position: POSITION_VOICELESS_FINAL, set: WORD_SET_CORE })
    AddWordCoding('with', { sound: TARGET_SOUND_TH, level: LEVEL_1, position: POSITION_VOICELESS_FINAL })

    AddWordCoding('baby tooth', { sound: TARGET_SOUND_TH, level: LEVEL_2, position: POSITION_VOICELESS_FINAL, set: WORD_SET_CORE })
    AddWordCoding('birdbath', { sound: TARGET_SOUND_TH, level: LEVEL_2, position: POSITION_VOICELESS_FINAL, set: WORD_SET_CORE })
    AddWordCoding('dishcloth', { sound: TARGET_SOUND_TH, level: LEVEL_2, position: POSITION_VOICELESS_FINAL, set: WORD_SET_CORE })
    AddWordCoding('eightieth', { sound: TARGET_SOUND_TH, level: LEVEL_2, position: POSITION_VOICELESS_FINAL, set: WORD_SET_CORE })
    AddWordCoding('length', { sound: TARGET_SOUND_TH, level: LEVEL_2, position: POSITION_VOICELESS_FINAL, set: WORD_SET_CORE })
    AddWordCoding('locksmith', { sound: TARGET_SOUND_TH, level: LEVEL_2, position: POSITION_VOICELESS_FINAL, set: WORD_SET_CORE })
    AddWordCoding('mammoth', { sound: TARGET_SOUND_TH, level: LEVEL_2, position: POSITION_VOICELESS_FINAL, set: WORD_SET_CORE })
    AddWordCoding('month', { sound: TARGET_SOUND_TH, level: LEVEL_2, position: POSITION_VOICELESS_FINAL, set: WORD_SET_CORE })
    AddWordCoding('ninth', { sound: TARGET_SOUND_TH, level: LEVEL_2, position: POSITION_VOICELESS_FINAL, set: WORD_SET_CORE })
    AddWordCoding('south', { sound: TARGET_SOUND_TH, level: LEVEL_2, position: POSITION_VOICELESS_FINAL, set: WORD_SET_CORE })
    AddWordCoding('tenth', { sound: TARGET_SOUND_TH, level: LEVEL_2, position: POSITION_VOICELESS_FINAL, set: WORD_SET_CORE })
    AddWordCoding('underneath', { sound: TARGET_SOUND_TH, level: LEVEL_2, position: POSITION_VOICELESS_FINAL, set: WORD_SET_CORE })

    AddWordCoding('blacksmith', { sound: TARGET_SOUND_TH, level: LEVEL_3, position: POSITION_VOICELESS_FINAL })
    AddWordCoding('bubble bath', { sound: TARGET_SOUND_TH, level: LEVEL_3, position: POSITION_VOICELESS_FINAL, set: WORD_SET_CORE })
    AddWordCoding('chicken broth', { sound: TARGET_SOUND_TH, level: LEVEL_3, position: POSITION_VOICELESS_FINAL, set: WORD_SET_CORE })
    AddWordCoding('fifth', { sound: TARGET_SOUND_TH, level: LEVEL_3, position: POSITION_VOICELESS_FINAL, set: WORD_SET_CORE })
    AddWordCoding('fiftieth', { sound: TARGET_SOUND_TH, level: LEVEL_3, position: POSITION_VOICELESS_FINAL })
    AddWordCoding('fourth', { sound: TARGET_SOUND_TH, level: LEVEL_3, position: POSITION_VOICELESS_FINAL, set: WORD_SET_CORE })
    AddWordCoding('ninetieth', { sound: TARGET_SOUND_TH, level: LEVEL_3, position: POSITION_VOICELESS_FINAL, set: WORD_SET_CORE })
    AddWordCoding('seventh', { sound: TARGET_SOUND_TH, level: LEVEL_3, position: POSITION_VOICELESS_FINAL, set: WORD_SET_CORE })
    AddWordCoding('sixtieth', { sound: TARGET_SOUND_TH, level: LEVEL_3, position: POSITION_VOICELESS_FINAL, set: WORD_SET_CORE })
    AddWordCoding('sleuth', { sound: TARGET_SOUND_TH, level: LEVEL_3, position: POSITION_VOICELESS_FINAL })
    AddWordCoding('sloth', { sound: TARGET_SOUND_TH, level: LEVEL_3, position: POSITION_VOICELESS_FINAL, set: WORD_SET_CORE })
    AddWordCoding('strength', { sound: TARGET_SOUND_TH, level: LEVEL_3, position: POSITION_VOICELESS_FINAL, set: WORD_SET_CORE })
    AddWordCoding('tablecloth', { sound: TARGET_SOUND_TH, level: LEVEL_3, position: POSITION_VOICELESS_FINAL, set: WORD_SET_CORE })
    AddWordCoding('thirtieth', { sound: TARGET_SOUND_TH, level: LEVEL_3, position: POSITION_VOICELESS_FINAL, set: WORD_SET_CORE })
    AddWordCoding('twentieth', { sound: TARGET_SOUND_TH, level: LEVEL_3, position: POSITION_VOICELESS_FINAL, set: WORD_SET_CORE })

    // Target sound TH: Voiced Initial
    AddWordCoding('than', { sound: TARGET_SOUND_TH, level: LEVEL_1, position: POSITION_VOICED_INITIAL, set: WORD_SET_CORE })
    AddWordCoding('that', { sound: TARGET_SOUND_TH, level: LEVEL_1, position: POSITION_VOICED_INITIAL, set: WORD_SET_CORE })
    AddWordCoding('the', { sound: TARGET_SOUND_TH, level: LEVEL_1, position: POSITION_VOICED_INITIAL, set: WORD_SET_CORE })
    AddWordCoding('them', { sound: TARGET_SOUND_TH, level: LEVEL_1, position: POSITION_VOICED_INITIAL, set: WORD_SET_CORE })
    AddWordCoding('they', { sound: TARGET_SOUND_TH, level: LEVEL_1, position: POSITION_VOICED_INITIAL, set: WORD_SET_CORE })
    AddWordCoding('though', { sound: TARGET_SOUND_TH, level: LEVEL_1, position: POSITION_VOICED_INITIAL, set: WORD_SET_CORE })

    AddWordCoding('theyd', { sound: TARGET_SOUND_TH, level: LEVEL_2, position: POSITION_VOICED_INITIAL, text: "they'd", set: WORD_SET_CORE })
    AddWordCoding('theyll', { sound: TARGET_SOUND_TH, level: LEVEL_2, position: POSITION_VOICED_INITIAL, text: "they'll", set: WORD_SET_CORE })
    AddWordCoding('theyre', { sound: TARGET_SOUND_TH, level: LEVEL_2, position: POSITION_VOICED_INITIAL, text: "they're", set: WORD_SET_CORE })
    AddWordCoding('this', { sound: TARGET_SOUND_TH, level: LEVEL_2, position: POSITION_VOICED_INITIAL, set: WORD_SET_CORE })
    AddWordCoding('those', { sound: TARGET_SOUND_TH, level: LEVEL_2, position: POSITION_VOICED_INITIAL, set: WORD_SET_CORE })

    AddWordCoding('theirs', { sound: TARGET_SOUND_TH, level: LEVEL_3, position: POSITION_VOICED_INITIAL, set: WORD_SET_CORE })
    AddWordCoding('these', { sound: TARGET_SOUND_TH, level: LEVEL_3, position: POSITION_VOICED_INITIAL, set: WORD_SET_CORE })
    AddWordCoding('themselves', { sound: TARGET_SOUND_TH, level: LEVEL_3, position: POSITION_VOICED_INITIAL, set: WORD_SET_CORE })
    AddWordCoding('theyve', { sound: TARGET_SOUND_TH, level: LEVEL_3, position: POSITION_VOICED_INITIAL, text: "they've", set: WORD_SET_CORE })

    // Target sound TH: Voiced Medial
    AddWordCoding('bathing', { sound: TARGET_SOUND_TH, level: LEVEL_1, position: POSITION_VOICED_MEDIAL, set: WORD_SET_CORE })
    AddWordCoding('either', { sound: TARGET_SOUND_TH, level: LEVEL_1, position: POSITION_VOICED_MEDIAL, set: WORD_SET_CORE })
    AddWordCoding('gather', { sound: TARGET_SOUND_TH, level: LEVEL_1, position: POSITION_VOICED_MEDIAL, set: WORD_SET_CORE })
    AddWordCoding('leather', { sound: TARGET_SOUND_TH, level: LEVEL_1, position: POSITION_VOICED_MEDIAL, set: WORD_SET_CORE })
    AddWordCoding('mother', { sound: TARGET_SOUND_TH, level: LEVEL_1, position: POSITION_VOICED_MEDIAL, set: WORD_SET_CORE })
    AddWordCoding('neither', { sound: TARGET_SOUND_TH, level: LEVEL_1, position: POSITION_VOICED_MEDIAL, set: WORD_SET_CORE })
    AddWordCoding('other', { sound: TARGET_SOUND_TH, level: LEVEL_1, position: POSITION_VOICED_MEDIAL, set: WORD_SET_CORE })
    AddWordCoding('weather', { sound: TARGET_SOUND_TH, level: LEVEL_1, position: POSITION_VOICED_MEDIAL, set: WORD_SET_CORE })

    AddWordCoding('breathing', { sound: TARGET_SOUND_TH, level: LEVEL_2, position: POSITION_VOICED_MEDIAL, set: WORD_SET_CORE })
    AddWordCoding('brother', { sound: TARGET_SOUND_TH, level: LEVEL_2, position: POSITION_VOICED_MEDIAL, set: WORD_SET_CORE })
    AddWordCoding('clothing', { sound: TARGET_SOUND_TH, level: LEVEL_2, position: POSITION_VOICED_MEDIAL, set: WORD_SET_CORE })
    AddWordCoding('father', { sound: TARGET_SOUND_TH, level: LEVEL_2, position: POSITION_VOICED_MEDIAL, set: WORD_SET_CORE })
    AddWordCoding('feather', { sound: TARGET_SOUND_TH, level: LEVEL_2, position: POSITION_VOICED_MEDIAL, set: WORD_SET_CORE })
    AddWordCoding('rather', { sound: TARGET_SOUND_TH, level: LEVEL_2, position: POSITION_VOICED_MEDIAL, set: WORD_SET_CORE })
    AddWordCoding('together', { sound: TARGET_SOUND_TH, level: LEVEL_2, position: POSITION_VOICED_MEDIAL, set: WORD_SET_CORE })
    AddWordCoding('another', { sound: TARGET_SOUND_TH, level: LEVEL_2, position: POSITION_VOICED_MEDIAL, set: WORD_SET_CORE })

    AddWordCoding('rhythm', { sound: TARGET_SOUND_TH, level: LEVEL_3, position: POSITION_VOICED_MEDIAL, set: WORD_SET_CORE })
    AddWordCoding('slither', { sound: TARGET_SOUND_TH, level: LEVEL_3, position: POSITION_VOICED_MEDIAL, set: WORD_SET_CORE })
    AddWordCoding('smoothest', { sound: TARGET_SOUND_TH, level: LEVEL_3, position: POSITION_VOICED_MEDIAL, set: WORD_SET_CORE })
    AddWordCoding('southern', { sound: TARGET_SOUND_TH, level: LEVEL_3, position: POSITION_VOICED_MEDIAL, set: WORD_SET_CORE })
    AddWordCoding('bathing suit', { sound: TARGET_SOUND_TH, level: LEVEL_3, position: POSITION_VOICED_MEDIAL, set: WORD_SET_CORE })
    AddWordCoding('grandfather', { sound: TARGET_SOUND_TH, level: LEVEL_3, position: POSITION_VOICED_MEDIAL, set: WORD_SET_CORE })
    AddWordCoding('grandmother', { sound: TARGET_SOUND_TH, level: LEVEL_3, position: POSITION_VOICED_MEDIAL, set: WORD_SET_CORE })
    AddWordCoding('stormy weather', { sound: TARGET_SOUND_TH, level: LEVEL_3, position: POSITION_VOICED_MEDIAL, set: WORD_SET_CORE })
    AddWordCoding('weather report', { sound: TARGET_SOUND_TH, level: LEVEL_3, position: POSITION_VOICED_MEDIAL, set: WORD_SET_CORE })
    AddWordCoding('smoothie', { sound: TARGET_SOUND_TH, level: LEVEL_3, position: POSITION_VOICED_MEDIAL, set: WORD_SET_CORE })

    // Target sound TH: Voiced Final
    AddWordCoding('teethe', { sound: TARGET_SOUND_TH, level: LEVEL_1, position: POSITION_VOICED_FINAL, set: WORD_SET_CORE })
    AddWordCoding('bathe', { sound: TARGET_SOUND_TH, level: LEVEL_1, position: POSITION_VOICED_FINAL, set: WORD_SET_CORE })

    AddWordCoding('breathe', { sound: TARGET_SOUND_TH, level: LEVEL_2, position: POSITION_VOICED_FINAL, set: WORD_SET_CORE })

    AddWordCoding('smooth', { sound: TARGET_SOUND_TH, level: LEVEL_3, position: POSITION_VOICED_FINAL, set: WORD_SET_CORE })
    AddWordCoding('soothe', { sound: TARGET_SOUND_TH, level: LEVEL_3, position: POSITION_VOICED_FINAL, set: WORD_SET_CORE })

    // --------------------------------------------------------------------------------------------------
    //   Target sound V
    // --------------------------------------------------------------------------------------------------

    // Target sound V: Initial
    AddWordCoding('valley', { sound: TARGET_SOUND_V, level: LEVEL_1, position: POSITION_INITIAL, set: WORD_SET_CORE })
    AddWordCoding('van', { sound: TARGET_SOUND_V, level: LEVEL_1, position: POSITION_INITIAL, set: WORD_SET_CORE })
    AddWordCoding('vanilla', { sound: TARGET_SOUND_V, level: LEVEL_1, position: POSITION_INITIAL, set: WORD_SET_CORE })
    AddWordCoding('veering', { sound: TARGET_SOUND_V, level: LEVEL_1, position: POSITION_INITIAL })
    AddWordCoding('vehicle', { sound: TARGET_SOUND_V, level: LEVEL_1, position: POSITION_INITIAL, set: WORD_SET_CORE })
    AddWordCoding('vent', { sound: TARGET_SOUND_V, level: LEVEL_1, position: POSITION_INITIAL })
    AddWordCoding('vertical', { sound: TARGET_SOUND_V, level: LEVEL_1, position: POSITION_INITIAL })
    AddWordCoding('very', { sound: TARGET_SOUND_V, level: LEVEL_1, position: POSITION_INITIAL, set: WORD_SET_CORE })
    AddWordCoding('Victoria', { sound: TARGET_SOUND_V, level: LEVEL_1, position: POSITION_INITIAL, set: WORD_SET_CORE })
    AddWordCoding('victory', { sound: TARGET_SOUND_V, level: LEVEL_1, position: POSITION_INITIAL, set: WORD_SET_CORE })
    AddWordCoding('Viking', { sound: TARGET_SOUND_V, level: LEVEL_1, position: POSITION_INITIAL })
    AddWordCoding('vine', { sound: TARGET_SOUND_V, level: LEVEL_1, position: POSITION_INITIAL, set: WORD_SET_CORE })
    AddWordCoding('violet', { sound: TARGET_SOUND_V, level: LEVEL_1, position: POSITION_INITIAL })
    AddWordCoding('violin', { sound: TARGET_SOUND_V, level: LEVEL_1, position: POSITION_INITIAL, set: WORD_SET_CORE })
    AddWordCoding('vulture', { sound: TARGET_SOUND_V, level: LEVEL_1, position: POSITION_INITIAL, set: WORD_SET_CORE })

    AddWordCoding('vacuum', { sound: TARGET_SOUND_V, level: LEVEL_2, position: POSITION_INITIAL, set: WORD_SET_CORE })
    AddWordCoding('vase', { sound: TARGET_SOUND_V, level: LEVEL_2, position: POSITION_INITIAL, set: WORD_SET_CORE })
    AddWordCoding('Venice', { sound: TARGET_SOUND_V, level: LEVEL_2, position: POSITION_INITIAL })
    AddWordCoding('Venus', { sound: TARGET_SOUND_V, level: LEVEL_2, position: POSITION_INITIAL, set: WORD_SET_CORE })
    AddWordCoding('Vermont', { sound: TARGET_SOUND_V, level: LEVEL_2, position: POSITION_INITIAL, set: WORD_SET_CORE })
    AddWordCoding('vest', { sound: TARGET_SOUND_V, level: LEVEL_2, position: POSITION_INITIAL })
    AddWordCoding('video', { sound: TARGET_SOUND_V, level: LEVEL_2, position: POSITION_INITIAL, set: WORD_SET_CORE })
    AddWordCoding('Virginia', { sound: TARGET_SOUND_V, level: LEVEL_2, position: POSITION_INITIAL, set: WORD_SET_CORE })
    AddWordCoding('vision', { sound: TARGET_SOUND_V, level: LEVEL_2, position: POSITION_INITIAL })
    AddWordCoding('visit', { sound: TARGET_SOUND_V, level: LEVEL_2, position: POSITION_INITIAL })
    AddWordCoding('vitamin', { sound: TARGET_SOUND_V, level: LEVEL_2, position: POSITION_INITIAL, set: WORD_SET_CORE })
    AddWordCoding('voice', { sound: TARGET_SOUND_V, level: LEVEL_2, position: POSITION_INITIAL, set: WORD_SET_CORE })
    AddWordCoding('volume', { sound: TARGET_SOUND_V, level: LEVEL_2, position: POSITION_INITIAL, set: WORD_SET_CORE })
    AddWordCoding('vote', { sound: TARGET_SOUND_V, level: LEVEL_2, position: POSITION_INITIAL })
    AddWordCoding('vowel', { sound: TARGET_SOUND_V, level: LEVEL_2, position: POSITION_INITIAL, set: WORD_SET_CORE })

    AddWordCoding('vacation', { sound: TARGET_SOUND_V, level: LEVEL_3, position: POSITION_INITIAL, set: WORD_SET_CORE })
    AddWordCoding('vacuum cleaner', { sound: TARGET_SOUND_V, level: LEVEL_3, position: POSITION_INITIAL, set: WORD_SET_CORE })
    AddWordCoding('valedictorian', { sound: TARGET_SOUND_V, level: LEVEL_3, position: POSITION_INITIAL })
    AddWordCoding('valentine', { sound: TARGET_SOUND_V, level: LEVEL_3, position: POSITION_INITIAL })
    AddWordCoding('Valentines Day', { sound: TARGET_SOUND_V, level: LEVEL_3, position: POSITION_INITIAL, set: WORD_SET_CORE, text: "Valentine's Day" })
    AddWordCoding('valuable', { sound: TARGET_SOUND_V, level: LEVEL_3, position: POSITION_INITIAL, set: WORD_SET_CORE })
    AddWordCoding('vampire', { sound: TARGET_SOUND_V, level: LEVEL_3, position: POSITION_INITIAL, set: WORD_SET_CORE })
    AddWordCoding('vegetable', { sound: TARGET_SOUND_V, level: LEVEL_3, position: POSITION_INITIAL, set: WORD_SET_CORE })
    AddWordCoding('venomous', { sound: TARGET_SOUND_V, level: LEVEL_3, position: POSITION_INITIAL })
    AddWordCoding('veterinarian', { sound: TARGET_SOUND_V, level: LEVEL_3, position: POSITION_INITIAL, set: WORD_SET_CORE })
    AddWordCoding('video game', { sound: TARGET_SOUND_V, level: LEVEL_3, position: POSITION_INITIAL, set: WORD_SET_CORE })
    AddWordCoding('vocabulary', { sound: TARGET_SOUND_V, level: LEVEL_3, position: POSITION_INITIAL })
    AddWordCoding('voices', { sound: TARGET_SOUND_V, level: LEVEL_3, position: POSITION_INITIAL })
    AddWordCoding('volcano', { sound: TARGET_SOUND_V, level: LEVEL_3, position: POSITION_INITIAL, set: WORD_SET_CORE })
    AddWordCoding('volleyball', { sound: TARGET_SOUND_V, level: LEVEL_3, position: POSITION_INITIAL, set: WORD_SET_CORE })

    // Target sound V: Medial
    AddWordCoding('adventure', { sound: TARGET_SOUND_V, level: LEVEL_1, position: POSITION_MEDIAL })
    AddWordCoding('carnival', { sound: TARGET_SOUND_V, level: LEVEL_1, position: POSITION_MEDIAL })
    AddWordCoding('deliver', { sound: TARGET_SOUND_V, level: LEVEL_1, position: POSITION_MEDIAL })
    AddWordCoding('device', { sound: TARGET_SOUND_V, level: LEVEL_1, position: POSITION_MEDIAL })
    AddWordCoding('divide', { sound: TARGET_SOUND_V, level: LEVEL_1, position: POSITION_MEDIAL, set: WORD_SET_CORE })
    AddWordCoding('driver', { sound: TARGET_SOUND_V, level: LEVEL_1, position: POSITION_MEDIAL, set: WORD_SET_CORE })
    AddWordCoding('elevator', { sound: TARGET_SOUND_V, level: LEVEL_1, position: POSITION_MEDIAL, set: WORD_SET_CORE })
    AddWordCoding('eleven', { sound: TARGET_SOUND_V, level: LEVEL_1, position: POSITION_MEDIAL, set: WORD_SET_CORE })
    AddWordCoding('every', { sound: TARGET_SOUND_V, level: LEVEL_1, position: POSITION_MEDIAL, set: WORD_SET_CORE })
    AddWordCoding('heavy', { sound: TARGET_SOUND_V, level: LEVEL_1, position: POSITION_MEDIAL, set: WORD_SET_CORE })
    AddWordCoding('lava', { sound: TARGET_SOUND_V, level: LEVEL_1, position: POSITION_MEDIAL, set: WORD_SET_CORE })
    AddWordCoding('Olivia', { sound: TARGET_SOUND_V, level: LEVEL_1, position: POSITION_MEDIAL })
    AddWordCoding('oven', { sound: TARGET_SOUND_V, level: LEVEL_1, position: POSITION_MEDIAL, set: WORD_SET_CORE })
    AddWordCoding('raven', { sound: TARGET_SOUND_V, level: LEVEL_1, position: POSITION_MEDIAL })
    AddWordCoding('river', { sound: TARGET_SOUND_V, level: LEVEL_1, position: POSITION_MEDIAL, set: WORD_SET_CORE })
    AddWordCoding('TV', { sound: TARGET_SOUND_V, level: LEVEL_1, position: POSITION_MEDIAL, set: WORD_SET_CORE })

    AddWordCoding('driveway', { sound: TARGET_SOUND_V, level: LEVEL_2, position: POSITION_MEDIAL, set: WORD_SET_CORE })
    AddWordCoding('everything', { sound: TARGET_SOUND_V, level: LEVEL_2, position: POSITION_MEDIAL })
    AddWordCoding('favorite', { sound: TARGET_SOUND_V, level: LEVEL_2, position: POSITION_MEDIAL, set: WORD_SET_CORE })
    AddWordCoding('fever', { sound: TARGET_SOUND_V, level: LEVEL_2, position: POSITION_MEDIAL, set: WORD_SET_CORE })
    AddWordCoding('flavor', { sound: TARGET_SOUND_V, level: LEVEL_2, position: POSITION_MEDIAL })
    AddWordCoding('navy blue', { sound: TARGET_SOUND_V, level: LEVEL_2, position: POSITION_MEDIAL })
    AddWordCoding('nervous', { sound: TARGET_SOUND_V, level: LEVEL_2, position: POSITION_MEDIAL, set: WORD_SET_CORE })
    AddWordCoding('November', { sound: TARGET_SOUND_V, level: LEVEL_2, position: POSITION_MEDIAL, set: WORD_SET_CORE })
    AddWordCoding('oval', { sound: TARGET_SOUND_V, level: LEVEL_2, position: POSITION_MEDIAL, set: WORD_SET_CORE })
    AddWordCoding('over', { sound: TARGET_SOUND_V, level: LEVEL_2, position: POSITION_MEDIAL, set: WORD_SET_CORE })
    AddWordCoding('seven', { sound: TARGET_SOUND_V, level: LEVEL_2, position: POSITION_MEDIAL, set: WORD_SET_CORE })
    AddWordCoding('seventy', { sound: TARGET_SOUND_V, level: LEVEL_2, position: POSITION_MEDIAL, set: WORD_SET_CORE })
    AddWordCoding('shovel', { sound: TARGET_SOUND_V, level: LEVEL_2, position: POSITION_MEDIAL, set: WORD_SET_CORE })
    AddWordCoding('silver', { sound: TARGET_SOUND_V, level: LEVEL_2, position: POSITION_MEDIAL })
    AddWordCoding('travel', { sound: TARGET_SOUND_V, level: LEVEL_2, position: POSITION_MEDIAL })

    AddWordCoding('beverage', { sound: TARGET_SOUND_V, level: LEVEL_3, position: POSITION_MEDIAL, set: WORD_SET_CORE })
    AddWordCoding('division', { sound: TARGET_SOUND_V, level: LEVEL_3, position: POSITION_MEDIAL, set: WORD_SET_CORE })
    AddWordCoding('four leaf clover', { sound: TARGET_SOUND_V, level: LEVEL_3, position: POSITION_MEDIAL, set: WORD_SET_CORE })
    AddWordCoding('long sleeves', { sound: TARGET_SOUND_V, level: LEVEL_3, position: POSITION_MEDIAL, set: WORD_SET_CORE })
    AddWordCoding('overflow', { sound: TARGET_SOUND_V, level: LEVEL_3, position: POSITION_MEDIAL })
    AddWordCoding('screwdriver', { sound: TARGET_SOUND_V, level: LEVEL_3, position: POSITION_MEDIAL, set: WORD_SET_CORE })
    AddWordCoding('scuba diver', { sound: TARGET_SOUND_V, level: LEVEL_3, position: POSITION_MEDIAL, set: WORD_SET_CORE })
    AddWordCoding('seventh', { sound: TARGET_SOUND_V, level: LEVEL_3, position: POSITION_MEDIAL, set: WORD_SET_CORE })
    AddWordCoding('shaving cream', { sound: TARGET_SOUND_V, level: LEVEL_3, position: POSITION_MEDIAL })
    AddWordCoding('silverware', { sound: TARGET_SOUND_V, level: LEVEL_3, position: POSITION_MEDIAL, set: WORD_SET_CORE })
    AddWordCoding('sleeves', { sound: TARGET_SOUND_V, level: LEVEL_3, position: POSITION_MEDIAL })
    AddWordCoding('souvenir', { sound: TARGET_SOUND_V, level: LEVEL_3, position: POSITION_MEDIAL })
    AddWordCoding('swivel', { sound: TARGET_SOUND_V, level: LEVEL_3, position: POSITION_MEDIAL })
    AddWordCoding('themselves', { sound: TARGET_SOUND_V, level: LEVEL_3, position: POSITION_MEDIAL, set: WORD_SET_CORE })
    AddWordCoding('work gloves', { sound: TARGET_SOUND_V, level: LEVEL_3, position: POSITION_MEDIAL, set: WORD_SET_CORE })

    // Target sound V: Final
    AddWordCoding('arrive', { sound: TARGET_SOUND_V, level: LEVEL_1, position: POSITION_FINAL })
    AddWordCoding('carve', { sound: TARGET_SOUND_V, level: LEVEL_1, position: POSITION_FINAL })
    AddWordCoding('cave', { sound: TARGET_SOUND_V, level: LEVEL_1, position: POSITION_FINAL, set: WORD_SET_CORE })
    AddWordCoding('curve', { sound: TARGET_SOUND_V, level: LEVEL_1, position: POSITION_FINAL })
    AddWordCoding('dove', { sound: TARGET_SOUND_V, level: LEVEL_1, position: POSITION_FINAL })
    AddWordCoding('drive', { sound: TARGET_SOUND_V, level: LEVEL_1, position: POSITION_FINAL, set: WORD_SET_CORE })
    AddWordCoding('give', { sound: TARGET_SOUND_V, level: LEVEL_1, position: POSITION_FINAL, set: WORD_SET_CORE })
    AddWordCoding('glove', { sound: TARGET_SOUND_V, level: LEVEL_1, position: POSITION_FINAL })
    AddWordCoding('have', { sound: TARGET_SOUND_V, level: LEVEL_1, position: POSITION_FINAL, set: WORD_SET_CORE })
    AddWordCoding('leave', { sound: TARGET_SOUND_V, level: LEVEL_1, position: POSITION_FINAL, set: WORD_SET_CORE })
    AddWordCoding('love', { sound: TARGET_SOUND_V, level: LEVEL_1, position: POSITION_FINAL, set: WORD_SET_CORE })
    AddWordCoding('olive', { sound: TARGET_SOUND_V, level: LEVEL_1, position: POSITION_FINAL })
    AddWordCoding('relative', { sound: TARGET_SOUND_V, level: LEVEL_1, position: POSITION_FINAL })
    AddWordCoding('talkative', { sound: TARGET_SOUND_V, level: LEVEL_1, position: POSITION_FINAL })
    AddWordCoding('theyve', { sound: TARGET_SOUND_V, level: LEVEL_1, position: POSITION_FINAL, text: "they've" })

    AddWordCoding('above', { sound: TARGET_SOUND_V, level: LEVEL_2, position: POSITION_FINAL })
    AddWordCoding('beehive', { sound: TARGET_SOUND_V, level: LEVEL_2, position: POSITION_FINAL })
    AddWordCoding('brave', { sound: TARGET_SOUND_V, level: LEVEL_2, position: POSITION_FINAL })
    AddWordCoding('cursive', { sound: TARGET_SOUND_V, level: LEVEL_2, position: POSITION_FINAL })
    AddWordCoding('five', { sound: TARGET_SOUND_V, level: LEVEL_2, position: POSITION_FINAL })
    AddWordCoding('improve', { sound: TARGET_SOUND_V, level: LEVEL_2, position: POSITION_FINAL })
    AddWordCoding('massive', { sound: TARGET_SOUND_V, level: LEVEL_2, position: POSITION_FINAL })
    AddWordCoding('shave', { sound: TARGET_SOUND_V, level: LEVEL_2, position: POSITION_FINAL })
    AddWordCoding('skydive', { sound: TARGET_SOUND_V, level: LEVEL_2, position: POSITION_FINAL })
    AddWordCoding('sleeve', { sound: TARGET_SOUND_V, level: LEVEL_2, position: POSITION_FINAL })
    AddWordCoding('stove', { sound: TARGET_SOUND_V, level: LEVEL_2, position: POSITION_FINAL })
    AddWordCoding('twelve', { sound: TARGET_SOUND_V, level: LEVEL_2, position: POSITION_FINAL })
    AddWordCoding('wave', { sound: TARGET_SOUND_V, level: LEVEL_2, variant: 1, position: POSITION_FINAL })  // hand
    AddWordCoding('wave', { sound: TARGET_SOUND_V, level: LEVEL_2, variant: 2, position: POSITION_FINAL })  // water

    AddWordCoding('approve', { sound: TARGET_SOUND_V, level: LEVEL_3, position: POSITION_FINAL })
    AddWordCoding('baseball glove', { sound: TARGET_SOUND_V, level: LEVEL_3, position: POSITION_FINAL })
    AddWordCoding('boxing glove', { sound: TARGET_SOUND_V, level: LEVEL_3, position: POSITION_FINAL })
    AddWordCoding('expensive', { sound: TARGET_SOUND_V, level: LEVEL_3, position: POSITION_FINAL })
    AddWordCoding('impressive', { sound: TARGET_SOUND_V, level: LEVEL_3, position: POSITION_FINAL })
    AddWordCoding('microwave', { sound: TARGET_SOUND_V, level: LEVEL_3, position: POSITION_FINAL })
    AddWordCoding('misbehave', { sound: TARGET_SOUND_V, level: LEVEL_3, position: POSITION_FINAL })
    AddWordCoding('negative', { sound: TARGET_SOUND_V, level: LEVEL_3, position: POSITION_FINAL })
    AddWordCoding('New Years Eve', { sound: TARGET_SOUND_V, level: LEVEL_3, position: POSITION_FINAL, text: "New Year's Eve" })
    AddWordCoding('observe', { sound: TARGET_SOUND_V, level: LEVEL_3, position: POSITION_FINAL })
    AddWordCoding('positive', { sound: TARGET_SOUND_V, level: LEVEL_3, position: POSITION_FINAL })
    AddWordCoding('swerve', { sound: TARGET_SOUND_V, level: LEVEL_3, position: POSITION_FINAL })
    AddWordCoding('tidal wave', { sound: TARGET_SOUND_V, level: LEVEL_3, position: POSITION_FINAL })

    // --------------------------------------------------------------------------------------------------
    //   Target sound Z
    // --------------------------------------------------------------------------------------------------

    // Target sound Z: Initial
    AddWordCoding('zany', { sound: TARGET_SOUND_Z, level: LEVEL_1, position: POSITION_INITIAL })
    AddWordCoding('zero', { sound: TARGET_SOUND_Z, level: LEVEL_1, position: POSITION_INITIAL, set: WORD_SET_CORE })
    AddWordCoding('zip', { sound: TARGET_SOUND_Z, level: LEVEL_1, position: POSITION_INITIAL, set: WORD_SET_CORE })
    AddWordCoding('zipper', { sound: TARGET_SOUND_Z, level: LEVEL_1, position: POSITION_INITIAL, set: WORD_SET_CORE })
    AddWordCoding('Zoe', { sound: TARGET_SOUND_Z, level: LEVEL_1, position: POSITION_INITIAL, set: WORD_SET_CORE })
    AddWordCoding('zone', { sound: TARGET_SOUND_Z, level: LEVEL_1, position: POSITION_INITIAL })
    AddWordCoding('zoo', { sound: TARGET_SOUND_Z, level: LEVEL_1, position: POSITION_INITIAL, set: WORD_SET_CORE })
    AddWordCoding('zoom', { sound: TARGET_SOUND_Z, level: LEVEL_1, position: POSITION_INITIAL })

    AddWordCoding('Zambia', { sound: TARGET_SOUND_Z, level: LEVEL_2, position: POSITION_INITIAL, set: WORD_SET_CORE })
    AddWordCoding('zebra', { sound: TARGET_SOUND_Z, level: LEVEL_2, position: POSITION_INITIAL, set: WORD_SET_CORE })
    AddWordCoding('zillion', { sound: TARGET_SOUND_Z, level: LEVEL_2, position: POSITION_INITIAL })
    AddWordCoding('zip code', { sound: TARGET_SOUND_Z, level: LEVEL_2, position: POSITION_INITIAL })
    AddWordCoding('zip line', { sound: TARGET_SOUND_Z, level: LEVEL_2, position: POSITION_INITIAL, set: WORD_SET_CORE })
    AddWordCoding('zombie', { sound: TARGET_SOUND_Z, level: LEVEL_2, position: POSITION_INITIAL, set: WORD_SET_CORE })
    AddWordCoding('zookeeper', { sound: TARGET_SOUND_Z, level: LEVEL_2, position: POSITION_INITIAL, set: WORD_SET_CORE })

    AddWordCoding('xylophone', { sound: TARGET_SOUND_Z, level: LEVEL_3, position: POSITION_INITIAL, set: WORD_SET_CORE })
    AddWordCoding('Zachary', { sound: TARGET_SOUND_Z, level: LEVEL_3, position: POSITION_INITIAL, set: WORD_SET_CORE })
    AddWordCoding('zesty', { sound: TARGET_SOUND_Z, level: LEVEL_3, position: POSITION_INITIAL })
    AddWordCoding('zigzag', { sound: TARGET_SOUND_Z, level: LEVEL_3, position: POSITION_INITIAL, set: WORD_SET_CORE })
    AddWordCoding('Zimbabwe', { sound: TARGET_SOUND_Z, level: LEVEL_3, position: POSITION_INITIAL, set: WORD_SET_CORE })
    AddWordCoding('zither', { sound: TARGET_SOUND_Z, level: LEVEL_3, position: POSITION_INITIAL })
    AddWordCoding('zucchini', { sound: TARGET_SOUND_Z, level: LEVEL_3, position: POSITION_INITIAL, set: WORD_SET_CORE })

    // Target sound Z: Medial
    AddWordCoding('busy', { sound: TARGET_SOUND_Z, level: LEVEL_1, position: POSITION_MEDIAL })
    AddWordCoding('cousin', { sound: TARGET_SOUND_Z, level: LEVEL_1, position: POSITION_MEDIAL, set: WORD_SET_CORE })
    AddWordCoding('cozy', { sound: TARGET_SOUND_Z, level: LEVEL_1, position: POSITION_MEDIAL, set: WORD_SET_CORE })
    AddWordCoding('daisy', { sound: TARGET_SOUND_Z, level: LEVEL_1, position: POSITION_MEDIAL, set: WORD_SET_CORE })
    AddWordCoding('dizzy', { sound: TARGET_SOUND_Z, level: LEVEL_1, position: POSITION_MEDIAL, set: WORD_SET_CORE })
    AddWordCoding('dozen', { sound: TARGET_SOUND_Z, level: LEVEL_1, position: POSITION_MEDIAL, set: WORD_SET_CORE })
    AddWordCoding('easy', { sound: TARGET_SOUND_Z, level: LEVEL_1, position: POSITION_MEDIAL, set: WORD_SET_CORE })
    AddWordCoding('Isaac', { sound: TARGET_SOUND_Z, level: LEVEL_1, position: POSITION_MEDIAL, set: WORD_SET_CORE })
    AddWordCoding('kazoo', { sound: TARGET_SOUND_Z, level: LEVEL_1, position: POSITION_MEDIAL })
    AddWordCoding('lazy', { sound: TARGET_SOUND_Z, level: LEVEL_1, position: POSITION_MEDIAL, set: WORD_SET_CORE })
    AddWordCoding('music', { sound: TARGET_SOUND_Z, level: LEVEL_1, position: POSITION_MEDIAL, set: WORD_SET_CORE })
    AddWordCoding('noisy', { sound: TARGET_SOUND_Z, level: LEVEL_1, position: POSITION_MEDIAL })
    AddWordCoding('puzzle', { sound: TARGET_SOUND_Z, level: LEVEL_1, position: POSITION_MEDIAL, set: WORD_SET_CORE })

    AddWordCoding('amazing', { sound: TARGET_SOUND_Z, level: LEVEL_2, position: POSITION_MEDIAL, set: WORD_SET_CORE })
    AddWordCoding('blizzard', { sound: TARGET_SOUND_Z, level: LEVEL_2, position: POSITION_MEDIAL })
    AddWordCoding('buzzard', { sound: TARGET_SOUND_Z, level: LEVEL_2, position: POSITION_MEDIAL })
    AddWordCoding('buzzer', { sound: TARGET_SOUND_Z, level: LEVEL_2, position: POSITION_MEDIAL, set: WORD_SET_CORE })
    AddWordCoding('closet', { sound: TARGET_SOUND_Z, level: LEVEL_2, position: POSITION_MEDIAL, set: WORD_SET_CORE })
    AddWordCoding('desert', { sound: TARGET_SOUND_Z, level: LEVEL_2, position: POSITION_MEDIAL, set: WORD_SET_CORE })
    AddWordCoding('dessert', { sound: TARGET_SOUND_Z, level: LEVEL_2, position: POSITION_MEDIAL, set: WORD_SET_CORE })
    AddWordCoding('drowsy', { sound: TARGET_SOUND_Z, level: LEVEL_2, position: POSITION_MEDIAL, set: WORD_SET_CORE })
    AddWordCoding('Isabel', { sound: TARGET_SOUND_Z, level: LEVEL_2, position: POSITION_MEDIAL, set: WORD_SET_CORE })
    AddWordCoding('lizard', { sound: TARGET_SOUND_Z, level: LEVEL_2, position: POSITION_MEDIAL, set: WORD_SET_CORE })
    AddWordCoding('nozzle', { sound: TARGET_SOUND_Z, level: LEVEL_2, position: POSITION_MEDIAL })
    AddWordCoding('pleasant', { sound: TARGET_SOUND_Z, level: LEVEL_2, position: POSITION_MEDIAL })
    AddWordCoding('raisin', { sound: TARGET_SOUND_Z, level: LEVEL_2, position: POSITION_MEDIAL, set: WORD_SET_CORE })
    AddWordCoding('Tuesday', { sound: TARGET_SOUND_Z, level: LEVEL_2, position: POSITION_MEDIAL, set: WORD_SET_CORE })
    AddWordCoding('wizard', { sound: TARGET_SOUND_Z, level: LEVEL_2, position: POSITION_MEDIAL })

    AddWordCoding('chimpanzee', { sound: TARGET_SOUND_Z, level: LEVEL_3, position: POSITION_MEDIAL, set: WORD_SET_CORE })
    AddWordCoding('Elizabeth', { sound: TARGET_SOUND_Z, level: LEVEL_3, position: POSITION_MEDIAL, set: WORD_SET_CORE })
    AddWordCoding('fizzy', { sound: TARGET_SOUND_Z, level: LEVEL_3, position: POSITION_MEDIAL })
    AddWordCoding('freezer', { sound: TARGET_SOUND_Z, level: LEVEL_3, position: POSITION_MEDIAL, set: WORD_SET_CORE })
    AddWordCoding('frozen', { sound: TARGET_SOUND_Z, level: LEVEL_3, position: POSITION_MEDIAL, set: WORD_SET_CORE })
    AddWordCoding('fuzzy', { sound: TARGET_SOUND_Z, level: LEVEL_3, position: POSITION_MEDIAL, set: WORD_SET_CORE })
    AddWordCoding('horizon', { sound: TARGET_SOUND_Z, level: LEVEL_3, position: POSITION_MEDIAL })
    AddWordCoding('invisible', { sound: TARGET_SOUND_Z, level: LEVEL_3, position: POSITION_MEDIAL })
    AddWordCoding('newspaper', { sound: TARGET_SOUND_Z, level: LEVEL_3, position: POSITION_MEDIAL, set: WORD_SET_CORE })
    AddWordCoding('pretzel', { sound: TARGET_SOUND_Z, level: LEVEL_3, position: POSITION_MEDIAL, set: WORD_SET_CORE })
    AddWordCoding('raspberry', { sound: TARGET_SOUND_Z, level: LEVEL_3, position: POSITION_MEDIAL, set: WORD_SET_CORE })
    AddWordCoding('sizzle', { sound: TARGET_SOUND_Z, level: LEVEL_3, position: POSITION_MEDIAL })
    AddWordCoding('thousand', { sound: TARGET_SOUND_Z, level: LEVEL_3, position: POSITION_MEDIAL, set: WORD_SET_CORE })
    AddWordCoding('Thursday', { sound: TARGET_SOUND_Z, level: LEVEL_3, position: POSITION_MEDIAL, set: WORD_SET_CORE })
    AddWordCoding('trapezoid', { sound: TARGET_SOUND_Z, level: LEVEL_3, position: POSITION_MEDIAL })
    AddWordCoding('visit', { sound: TARGET_SOUND_Z, level: LEVEL_3, position: POSITION_MEDIAL })

    // Target sound Z: Final
    AddWordCoding('bees', { sound: TARGET_SOUND_Z, level: LEVEL_1, position: POSITION_FINAL, set: WORD_SET_CORE })
    AddWordCoding('buzz', { sound: TARGET_SOUND_Z, level: LEVEL_1, position: POSITION_FINAL })
    AddWordCoding('days', { sound: TARGET_SOUND_Z, level: LEVEL_1, position: POSITION_FINAL, set: WORD_SET_CORE })
    AddWordCoding('doze', { sound: TARGET_SOUND_Z, level: LEVEL_1, position: POSITION_FINAL, set: WORD_SET_CORE })
    AddWordCoding('eyes', { sound: TARGET_SOUND_Z, level: LEVEL_1, position: POSITION_FINAL, set: WORD_SET_CORE })
    AddWordCoding('gaze', { sound: TARGET_SOUND_Z, level: LEVEL_1, position: POSITION_FINAL })
    AddWordCoding('his', { sound: TARGET_SOUND_Z, level: LEVEL_1, position: POSITION_FINAL, set: WORD_SET_CORE })
    AddWordCoding('keys', { sound: TARGET_SOUND_Z, level: LEVEL_1, position: POSITION_FINAL, set: WORD_SET_CORE })
    AddWordCoding('knees', { sound: TARGET_SOUND_Z, level: LEVEL_1, position: POSITION_FINAL, set: WORD_SET_CORE })
    AddWordCoding('maze', { sound: TARGET_SOUND_Z, level: LEVEL_1, position: POSITION_FINAL })
    AddWordCoding('nose', { sound: TARGET_SOUND_Z, level: LEVEL_1, position: POSITION_FINAL, set: WORD_SET_CORE })
    AddWordCoding('raise', { sound: TARGET_SOUND_Z, level: LEVEL_1, position: POSITION_FINAL })
    AddWordCoding('rose', { sound: TARGET_SOUND_Z, level: LEVEL_1, position: POSITION_FINAL, set: WORD_SET_CORE })
    AddWordCoding('tease', { sound: TARGET_SOUND_Z, level: LEVEL_1, position: POSITION_FINAL })
    AddWordCoding('toes', { sound: TARGET_SOUND_Z, level: LEVEL_1, position: POSITION_FINAL, set: WORD_SET_CORE })

    AddWordCoding('amaze', { sound: TARGET_SOUND_Z, level: LEVEL_2, position: POSITION_FINAL, set: WORD_SET_CORE })
    AddWordCoding('blaze', { sound: TARGET_SOUND_Z, level: LEVEL_2, position: POSITION_FINAL })
    AddWordCoding('breeze', { sound: TARGET_SOUND_Z, level: LEVEL_2, position: POSITION_FINAL, set: WORD_SET_CORE })
    AddWordCoding('bruise', { sound: TARGET_SOUND_Z, level: LEVEL_2, position: POSITION_FINAL, set: WORD_SET_CORE })
    AddWordCoding('cries', { sound: TARGET_SOUND_Z, level: LEVEL_2, position: POSITION_FINAL, set: WORD_SET_CORE })
    AddWordCoding('hers', { sound: TARGET_SOUND_Z, level: LEVEL_2, position: POSITION_FINAL, set: WORD_SET_CORE })
    AddWordCoding('plays', { sound: TARGET_SOUND_Z, level: LEVEL_2, position: POSITION_FINAL, set: WORD_SET_CORE })
    AddWordCoding('please', { sound: TARGET_SOUND_Z, level: LEVEL_2, position: POSITION_FINAL, set: WORD_SET_CORE })
    AddWordCoding('prize', { sound: TARGET_SOUND_Z, level: LEVEL_2, position: POSITION_FINAL, set: WORD_SET_CORE })
    AddWordCoding('quiz', { sound: TARGET_SOUND_Z, level: LEVEL_2, position: POSITION_FINAL })
    AddWordCoding('tools', { sound: TARGET_SOUND_Z, level: LEVEL_2, position: POSITION_FINAL, set: WORD_SET_CORE })
    AddWordCoding('woods', { sound: TARGET_SOUND_Z, level: LEVEL_2, position: POSITION_FINAL, set: WORD_SET_CORE })

    AddWordCoding('cheese', { sound: TARGET_SOUND_Z, level: LEVEL_3, position: POSITION_FINAL, set: WORD_SET_CORE })
    AddWordCoding('choose', { sound: TARGET_SOUND_Z, level: LEVEL_3, position: POSITION_FINAL, set: WORD_SET_CORE })
    AddWordCoding('clothes', { sound: TARGET_SOUND_Z, level: LEVEL_3, position: POSITION_FINAL, set: WORD_SET_CORE })
    AddWordCoding('freeze', { sound: TARGET_SOUND_Z, level: LEVEL_3, position: POSITION_FINAL, set: WORD_SET_CORE })
    AddWordCoding('french fries', { sound: TARGET_SOUND_Z, level: LEVEL_3, position: POSITION_FINAL, set: WORD_SET_CORE })
    AddWordCoding('glasses', { sound: TARGET_SOUND_Z, level: LEVEL_3, position: POSITION_FINAL, set: WORD_SET_CORE })
    AddWordCoding('mayonnaise', { sound: TARGET_SOUND_Z, level: LEVEL_3, position: POSITION_FINAL })
    AddWordCoding('shoes', { sound: TARGET_SOUND_Z, level: LEVEL_3, position: POSITION_FINAL, set: WORD_SET_CORE })
    AddWordCoding('size', { sound: TARGET_SOUND_Z, level: LEVEL_3, position: POSITION_FINAL, set: WORD_SET_CORE })
    AddWordCoding('sneeze', { sound: TARGET_SOUND_Z, level: LEVEL_3, position: POSITION_FINAL, set: WORD_SET_CORE })
    AddWordCoding('snooze', { sound: TARGET_SOUND_Z, level: LEVEL_3, position: POSITION_FINAL })
    AddWordCoding('squeeze', { sound: TARGET_SOUND_Z, level: LEVEL_3, position: POSITION_FINAL })
    AddWordCoding('surprise', { sound: TARGET_SOUND_Z, level: LEVEL_3, position: POSITION_FINAL, set: WORD_SET_CORE })
}
