import { useState, useEffect, useContext } from 'react'
import { Container, Form, Modal, ButtonGroup, Row, Col } from 'react-bootstrap'
import PropTypes from 'prop-types'
import { ListOperationActionAreaComponent, ListOperationWordsAreaComponent } from './ListOperationComponents'
import { WordCardComponent } from './WordCardComponent'
import { ActionButtonComponent } from './ActionButtonComponents'
import { ListEditContext } from '../context/ListEditContext'
import './ListOperationComponents.css'

export function ListOperationNameComponent({ tabRowContent, controlAreaContent, UpdateListName, actionAreaProps, modalProps }) {
    const {
        listName, listWords, HandleExit
    } = useContext(ListEditContext)

    const [newListName, SetNewListName] = useState(listName)

    useEffect(() => {
        SetNewListName(listName)
    }, [listName])

    const actionAreaContent =
        <ListOperationActionAreaComponent props={actionAreaProps} >
            <div className='d-flex justify-content-center align-items-center w-100 mb-4'>
                <div className='list-operation-name-container vstack mt-3 mx-auto'>
                    <Form.Group controlId='listName' className='w-100'>
                        <Form.Label className='list-operation-name-label'>Name</Form.Label>
                        <Form.Control
                            className='list-operation-name-box'
                            type='text'
                            value={newListName}
                            placeholder='Enter a list name'
                            autoComplete='off'
                            onChange={event => {
                                event.preventDefault()
                                SetNewListName(event.target.value)
                            }}
                        />
                    </Form.Group>
                    <div className='d-flex justify-content-center mx-auto my-0'>
                        <ButtonGroup className='flex-wrap mx-auto'>
                            <ActionButtonComponent
                                text='Update Name'
                                width='8rem'
                                margins='1rem 0.5rem 0 1rem'
                                isActive={(newListName.length > 0) && (newListName !== listName)}
                                DoAction={() => UpdateListName(newListName)}
                            />
                            <ActionButtonComponent
                                text='Exit'
                                width='6.5rem'
                                margins='1rem 0.5rem 0 1rem'
                                isActive={true}
                                DoAction={() => HandleExit()}
                            />
                        </ButtonGroup>
                    </div>
                </div>
            </div >
        </ListOperationActionAreaComponent>

    const wordCards = listWords.map((wordKey, index) => (
        <Col xs={6} sm={4} md={3} lg={2} key={index}>
            <WordCardComponent wordKey={wordKey} selectedWords={listWords} />
        </Col>
    ))

    return (
        <>
            <div className='list-operation-page-div'>
                <Container className='section-container bg-light w-100 pt-4 pb-4'>
                    {tabRowContent}
                    {actionAreaContent}
                </Container>
                {controlAreaContent}
            </div>
            <ListOperationWordsAreaComponent>
                <Row className='mt-0 g-2'>
                    {wordCards}
                </Row>
            </ListOperationWordsAreaComponent>
            <ListOperationNameModalComponent showModal={modalProps.showModal} HandleModalClose={modalProps.HandleModalClose} />
        </>
    )
}

ListOperationNameComponent.propTypes = {
    tabRowContent: PropTypes.object.isRequired,
    controlAreaContent: PropTypes.object.isRequired,
    UpdateListName: PropTypes.func.isRequired,
    actionAreaProps: PropTypes.object.isRequired,
    modalProps: PropTypes.object.isRequired,
}

function ListOperationNameModalComponent({ showModal, HandleModalClose }) {
    return (
        <Modal
            className='fade modal-lg'
            style={{ color: 'var(--dark-blue)' }}
            show={showModal}
            onHide={() => { HandleModalClose() }}
        >
            <Modal.Header className='bg-light py-3'>
                <Modal.Title className='text-center mx-auto'>
                    <h4 className='my-0'>My List</h4>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className='bg-light'>
                <div className='d-flex justify-content-center mx-auto'>
                    <div className='justify-content-left mx-5'>
                        <h5>Enter a unique name for your list. Click the Change Name button to save the name change.</h5>
                        <h5>Click Exit from any tab to return to the Saved List page.</h5>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer className='bg-light py-1' style={{ border: 'none' }}>
            </Modal.Footer>
        </Modal >
    )
}

ListOperationNameModalComponent.propTypes = {
    showModal: PropTypes.bool.isRequired,
    HandleModalClose: PropTypes.func.isRequired,
}
