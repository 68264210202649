import { Button, Dropdown } from 'react-bootstrap'
import PropTypes from 'prop-types'
import './ActionButtonComponents.css'

export function ActionButtonComponent({ text, buttonColor = 'var(--dark-blue)', width = '', margins = '0 0 0 0', isActive = true, DoAction }) {
    let buttonStyle = {
        textAlign: 'center',
        backgroundColor: buttonColor,
        margin: margins,
    }

    if (width !== '') {
        buttonStyle['width'] = width
        buttonStyle['maxWidth'] = width
        buttonStyle['paddingLeft'] = 0
        buttonStyle['paddingRight'] = 0
    }

    const buttonClass = 'action-button ' + (isActive ? 'action-button-active' : 'action-button-inactive')

    const HandleButtonClick = (event) => {
        event.preventDefault()
        DoAction()
    }

    return (
        <Button className={buttonClass} style={buttonStyle} disabled={!isActive} onClick={(event) => HandleButtonClick(event)}>
            {text}
        </Button>
    )
}

ActionButtonComponent.propTypes = {
    text: PropTypes.string.isRequired,
    buttonColor: PropTypes.string,
    margins: PropTypes.string,
    isActive: PropTypes.bool,
    DoAction: PropTypes.func.isRequired,
    width: PropTypes.string,
}

export function ActionDropdownComponent({ text, margins, isActive, DoAction, children }) {
    const buttonStyle = {
        margin: margins,
    }

    const toggleClass = 'action-dropdown-toggle ' + (isActive ? 'action-dropdown-toggle-active' : 'action-dropdown-toggle-inactive')

    return (
        <Dropdown
            disabled={!isActive}
            onSelect={(event) => {
                DoAction(event)
            }}
        >
            <Dropdown.Toggle className={toggleClass} style={buttonStyle} disabled={!isActive}>
                <span className='me-2'>{text}</span>
            </Dropdown.Toggle>

            <Dropdown.Menu>{children}</Dropdown.Menu>
        </Dropdown>
    )
}

ActionDropdownComponent.propTypes = {
    text: PropTypes.string.isRequired,
    margins: PropTypes.string,
    isActive: PropTypes.bool,
    DoAction: PropTypes.func.isRequired,
    children: PropTypes.node.isRequired,
}

ActionDropdownComponent.defaultProps = {
    margins: '0 0 0 0',
    isActive: true,
}

export function DangerButtonComponent({ text, width = '', margins = '0 0 0 0', isActive = true, DoAction }) {
    const buttonClass = 'danger-button ' + (isActive ? 'danger-button-active' : 'danger-button-inactive')

    let buttonStyle = {
        margin: margins,
    }

    if (width !== '') {
        buttonStyle['width'] = width
        buttonStyle['maxWidth'] = width
        buttonStyle['paddingLeft'] = 0
        buttonStyle['paddingRight'] = 0
    }

    const HandleButtonClick = (event) => {
        event.preventDefault()
        DoAction()
    }

    return (
        <Button className={buttonClass} style={buttonStyle} disabled={!isActive} onClick={(event) => HandleButtonClick(event)}>
            {text}
        </Button>
    )
}

DangerButtonComponent.propTypes = {
    text: PropTypes.string.isRequired,
    width: PropTypes.string,
    margins: PropTypes.string,
    isActive: PropTypes.bool,
    DoAction: PropTypes.func.isRequired,
}
