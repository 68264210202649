import { GetWordText } from './WordCodings'

function GetDelayPromise(delayMs) {
    return new Promise((resolve) => setTimeout(resolve, delayMs))
}

export async function Delay(delayMs) {
    await GetDelayPromise(delayMs)
}

export function GenerateRandomString(length) {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
    let randomString = ''

    for (let i = 0; i < length; ++i) {
        randomString += characters.charAt(Math.floor(Math.random() * characters.length))
    }

    return randomString
}

export function GenerateRandomInt(startInt, endInt) {
    let randomInt = startInt - 1

    while ((randomInt < startInt) || (randomInt > endInt)) {
        randomInt = startInt + Math.floor(Math.random() * (endInt - startInt + 1))
    }

    return randomInt
}

export function CreateIndexArray(size) {
    return Array.from({ length: size }, (v, i) => i)
}

export function ShuffleArray(array) {
    for (let i = array.length - 1; i > 0; i--) {
        let j = Math.floor(Math.random() * (i + 1))
        let temp = array[i]
        array[i] = array[j]
        array[j] = temp
    }
}

export function AddWordToList(list, wordKey) {
    let newList = [...list]

    if (!newList.includes(wordKey)) {
        newList.push(wordKey)
    }

    return newList
}

export function AddWordsToList(list, words) {
    let newList = [...list]

    for (const wordKey of words) {
        if (!newList.includes(wordKey)) {
            newList.push(wordKey)
        }
    }

    return newList
}

export function RemoveWordFromList(list, wordKey) {
    let newList = [...list]

    if (newList.includes(wordKey)) {
        const index = newList.indexOf(wordKey)

        if (index !== -1) {
            newList.splice(index, 1)
        }
    }

    return newList
}

export function RemoveWordsFromList(list, words) {
    let newList = [...list]

    for (const wordKey of words) {
        if (newList.includes(wordKey)) {
            const index = newList.indexOf(wordKey)

            if (index !== -1) {
                newList.splice(index, 1)
            }
        }
    }

    return newList
}

export function SortWords(wordKeys) {
    let sortedWordKeys = [...wordKeys]

    if (wordKeys.length > 1) {
        sortedWordKeys.sort((wordKey1, wordKey2) => {
            const wordText1 = GetWordText(wordKey1).toLowerCase()
            const wordText2 = GetWordText(wordKey2).toLowerCase()

            if (wordText1 < wordText2) {
                return -1
            } else if (wordText1 > wordText2) {
                return 1
            }

            return 0
        })
    }

    return sortedWordKeys
}

export function SortUserLists(lists) {
    let sortedLists = [...lists]

    if (lists.length > 1) {
        sortedLists.sort((list1, list2) => {
            const listName1 = list1.name.toLowerCase()
            const listName2 = list2.name.toLowerCase()

            if (listName1 < listName2) {
                return -1
            } else if (listName1 > listName2) {
                return 1
            }

            return 0
        })
    }

    return sortedLists
}

export function RemoveEmptyUserLists(lists) {
    let nonEmptyLists = [...lists]

    for (let i = lists.length - 1; i >= 0; --i) {
        if (nonEmptyLists[i].words.length === 0) {
            nonEmptyLists.splice(i, 1)
        }
    }

    return nonEmptyLists
}

export function IsEmailValid(email) {
    const num_amps = (email.match(/@/g) || []).length
    return num_amps === 1
}

export function IsPasswordValid(password) {
    return password !== ''
}
