import { useState, useEffect, useContext } from 'react'
import { Container, Row, Col, ButtonGroup, Modal } from 'react-bootstrap'
import PropTypes from 'prop-types'
import { AddWordToList, AddWordsToList, RemoveWordFromList } from '../project/Utils'
import { ListOperationActionAreaComponent, ListOperationWordsAreaComponent } from './ListOperationComponents'
import { WordCardComponent } from './WordCardComponent'
import { WordCheckboxComponent } from './WordCheckboxComponent'
import { SelectAllCheckboxComponent } from './SelectAllCheckboxComponent'
import { ActionButtonComponent } from './ActionButtonComponents'
import { ListEditContext } from '../context/ListEditContext'
import './ListOperationComponents.css'

export function ListOperationRemoveWordsComponent({ tabRowContent, controlAreaContent, UpdateListWords, actionAreaProps, modalProps }) {
    const {
        listName, listWords, HandleExit
    } = useContext(ListEditContext)

    const [wordsToKeep, SetWordsToKeep] = useState([...listWords])

    const actionAreaContent =
        <ListOperationActionAreaComponent props={actionAreaProps} >
            <div className='d-flex justify-content-center align-items-center w-100 mb-1'>
                <div className='list-operation-remove-words-container align-items-center mt-1 mb-3 mx-auto'>
                    <h4 className='text-center mx-auto mt-0 mb-2'>{listName}</h4>
                    <div className='d-flex justify-content-center mx-auto my-0'>
                        <ButtonGroup className='flex-wrap mx-auto'>
                            <ActionButtonComponent
                                text='Update Words'
                                width='10rem'
                                margins='1rem 0.5rem 0 1rem'
                                isActive={(listWords.length > 0) && (wordsToKeep.length < listWords.length)}
                                DoAction={() => UpdateListWords(wordsToKeep)}
                            />
                            <ActionButtonComponent
                                text='Exit'
                                width='6.5rem'
                                margins='1rem 0.5rem 0 1rem'
                                isActive={true}
                                DoAction={() => HandleExit()}
                            />
                        </ButtonGroup>
                    </div>
                </div>
            </div >
        </ListOperationActionAreaComponent>

    return (
        <>
            <div className='list-operation-page-div'>
                <Container className='section-container bg-light w-100 pt-4 pb-4'>
                    {tabRowContent}
                    {actionAreaContent}
                </Container>
                {controlAreaContent}
            </div>
            <ListOperationWordsAreaComponent>
                <RemoveWordsSelectionComponent
                    listWords={listWords}
                    wordsToKeep={wordsToKeep}
                    UpdateWordsToKeep={(newWordsToKeep) => { SetWordsToKeep([...newWordsToKeep]) }}
                />
            </ListOperationWordsAreaComponent>
            <ListOperationRemoveWordsModalComponent showModal={modalProps.showModal} HandleModalClose={modalProps.HandleModalClose} />
        </>
    )
}

ListOperationRemoveWordsComponent.propTypes = {
    tabRowContent: PropTypes.object.isRequired,
    controlAreaContent: PropTypes.object.isRequired,
    UpdateListWords: PropTypes.func.isRequired,
    actionAreaProps: PropTypes.object.isRequired,
    modalProps: PropTypes.object.isRequired,
}

function RemoveWordsSelectionComponent({ listWords, wordsToKeep, UpdateWordsToKeep }) {
    const [renderWordsSelectionComponentFlag, SetRenderWordsSelectionComponentFlag] = useState(false)

    useEffect(() => { }, [renderWordsSelectionComponentFlag])

    const wordCardsAndCheckboxes = listWords.map((wordKey, index) => (
        <Col xs={6} sm={4} md={3} lg={2} key={index}>
            <ButtonGroup className='vstack mt-0 mb-1 my-lg-1'>
                <WordCardComponent wordKey={wordKey} selectedWords={wordsToKeep}></WordCardComponent>
                <WordCheckboxComponent
                    wordKey={wordKey}
                    selectedWords={wordsToKeep}
                    SelectWord={(wordKey) => {
                        const newWordsToKeep = AddWordToList(wordsToKeep, wordKey)
                        UpdateWordsToKeep(newWordsToKeep)
                        SetRenderWordsSelectionComponentFlag(!renderWordsSelectionComponentFlag)
                    }}
                    DeselectWord={(wordKey) => {
                        const newWordsToKeep = RemoveWordFromList(wordsToKeep, wordKey)
                        UpdateWordsToKeep(newWordsToKeep)
                        SetRenderWordsSelectionComponentFlag(!renderWordsSelectionComponentFlag)
                    }}
                />
            </ButtonGroup>
        </Col>
    ))

    return (
        <>
            {listWords.length > 0 &&
                <>
                    <SelectAllCheckboxComponent
                        allWords={listWords}
                        selectedWords={wordsToKeep}
                        SelectAllWords={() => {
                            const newWordsToKeep = AddWordsToList(wordsToKeep, listWords)
                            UpdateWordsToKeep(newWordsToKeep)
                            SetRenderWordsSelectionComponentFlag(!renderWordsSelectionComponentFlag)
                        }}
                        DeselectAllWords={() => {
                            const newWordsToKeep = []
                            UpdateWordsToKeep(newWordsToKeep)
                            SetRenderWordsSelectionComponentFlag(!renderWordsSelectionComponentFlag)
                        }}
                    />
                    <Row className='mt-0 g-2'>{wordCardsAndCheckboxes}</Row>
                </>
            }
        </>
    )
}

RemoveWordsSelectionComponent.propTypes = {
    listWords: PropTypes.arrayOf(PropTypes.string).isRequired,
    wordsToKeep: PropTypes.arrayOf(PropTypes.string).isRequired,
    UpdateWordsToKeep: PropTypes.func.isRequired,
}

function ListOperationRemoveWordsModalComponent({ showModal, HandleModalClose }) {
    return (
        <Modal
            className='fade modal-lg'
            style={{ color: 'var(--dark-blue)' }}
            show={showModal}
            onHide={() => { HandleModalClose() }}
        >
            <Modal.Header className='bg-light py-3'>
                <Modal.Title className='text-center mx-auto'>
                    <h4 className='my-0'>Remove Words from the List</h4>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className='bg-light'>
                <div className='d-flex justify-content-center mx-auto'>
                    <div className='justify-content-left mx-5'>
                        <h5>Deselect the words you want to remove. When you are done, click Update Words to save the changes to your list.</h5>
                        <h5>Click Exit from any tab to return to the Saved List page.</h5>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer className='bg-light py-1' style={{ border: 'none' }}>
            </Modal.Footer>
        </Modal >
    )
}

ListOperationRemoveWordsModalComponent.propTypes = {
    showModal: PropTypes.bool.isRequired,
    HandleModalClose: PropTypes.func.isRequired,
}
