import { Dropdown, Image } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import PropTypes from 'prop-types'
import { GetActivityReturnPath } from '../state/ActivityState'
import { GetSiteImageUrl } from '../project/ProjectResources'
import './ActivityControlComponents.css'

export function ActivityControlIconComponent({ imageUrl, imageSize = '2.5rem', DoAction }) {
    const imageStyle = { 'width': imageSize }

    return <Image fluid className='activity-control-icon' style={imageStyle} src={imageUrl} onClick={() => DoAction()} />
}

ActivityControlIconComponent.propTypes = {
    imageUrl: PropTypes.string.isRequired,
    imageSize: PropTypes.string,
    DoAction: PropTypes.func.isRequired,
}

export function ExitControlIconComponent({ imageSize = '2.5rem', CleanUpActivity = () => { } }) {
    const navigate = useNavigate()

    return (
        <ActivityControlIconComponent
            imageUrl={GetSiteImageUrl('x-square-fill-blue.svg')}
            imageSize={imageSize}
            DoAction={() => {
                CleanUpActivity()
                navigate(GetActivityReturnPath())
            }}
        />
    )
}

ExitControlIconComponent.propTypes = {
    imageSize: PropTypes.string,
    CleanUpActivity: PropTypes.func,
}

export function RestartControlIconComponent({ RestartActivity = () => { } }) {
    return (
        <ActivityControlIconComponent
            imageUrl={GetSiteImageUrl('arrow-clockwise-square-fill-blue.svg')}
            DoAction={() => RestartActivity()}
        />
    )
}

RestartControlIconComponent.propTypes = {
    RestartActivity: PropTypes.func,
}

export function BackControlIconComponent({ BackAction = () => { } }) {
    return (
        <ActivityControlIconComponent
            imageUrl={GetSiteImageUrl('arrow-left-square-fill-blue.svg')}
            DoAction={() => BackAction()}
        />
    )
}

BackControlIconComponent.propTypes = {
    BackAction: PropTypes.func,
}

export function SoundControlIconComponent({ isSoundOn, SoundActivity = () => { } }) {
    return (
        <ActivityControlIconComponent
            imageUrl={isSoundOn ? GetSiteImageUrl('sound-on-square-fill-blue.svg') : GetSiteImageUrl('sound-off-square-fill-blue.svg')}
            DoAction={() => SoundActivity()}
        />
    )
}

SoundControlIconComponent.propTypes = {
    isSoundOn: PropTypes.bool,
    RestartActivity: PropTypes.func,
}

export function HelpControlIconComponent({ HelpAction = () => { } }) {
    return (
        <ActivityControlIconComponent
            imageUrl={GetSiteImageUrl('question-square-fill-dark-blue.svg')}
            DoAction={() => HelpAction()}
        />
    )
}

HelpControlIconComponent.propTypes = {
    HelpAction: PropTypes.func,
}

export function ActivityControlIconDropdownComponent({ imageUrl, imageSize = '2.5rem', SelectAction, children }) {
    const dropdownMenuPopperConfig = { modifiers: [{ name: 'offset', enabled: true, options: { offset: [-25, 10] } }] }
    const imageStyle = { 'width': imageSize }

    return (
        <Dropdown
            onSelect={(event) => {
                SelectAction(event)
            }}
        >
            <Dropdown.Toggle className='activity-control-icon' as={Image} style={imageStyle} src={GetSiteImageUrl(imageUrl)}></Dropdown.Toggle>
            <Dropdown.Menu className='activity-control-dropdown-icon-menu' popperConfig={dropdownMenuPopperConfig}>
                {children}
            </Dropdown.Menu>
        </Dropdown>
    )
}

ActivityControlIconDropdownComponent.propTypes = {
    imageUrl: PropTypes.string.isRequired,
    imageSize: PropTypes.string,
    SelectAction: PropTypes.func.isRequired,
}
