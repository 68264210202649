import { useState, useEffect, createContext, useContext } from 'react'

export const MemoryMatchContext = createContext()

export const MemoryMatchProvider = ({ children }) => {
    const [memoryMatchSourceWords, SetMemoryMatchSourceWords] = useState([])
    const [memoryMatchCards, SetMemoryMatchCards] = useState([])
    const [showMemoryMatchCardMatchResult, SetShowMemoryMatchCardMatchResult] = useState(false)
    const [showMemoryMatchCardMismatchResult, SetShowMemoryMatchCardMismatchResult] = useState(false)
    const [showMemoryMatchEndOfGameResult, SetShowMemoryMatchEndOfGameResult] = useState(false)

    const ClearShowMemoryMatchResults = () => {
        SetShowMemoryMatchCardMatchResult(false)
        SetShowMemoryMatchCardMismatchResult(false)
        SetShowMemoryMatchEndOfGameResult(false)
    }

    return (
        <MemoryMatchContext.Provider
            value={{
                memoryMatchSourceWords, SetMemoryMatchSourceWords,
                memoryMatchCards, SetMemoryMatchCards,
                showMemoryMatchCardMatchResult, SetShowMemoryMatchCardMatchResult,
                showMemoryMatchCardMismatchResult, SetShowMemoryMatchCardMismatchResult,
                showMemoryMatchEndOfGameResult, SetShowMemoryMatchEndOfGameResult,
                ClearShowMemoryMatchResults
            }}
        >
            {children}
        </MemoryMatchContext.Provider>
    )
}

export function MemoryMatchListener({ SendContextChangeNotification }) {
    const context = useContext(MemoryMatchContext)
    const [stringifiedContext, SetStringifiedContext] = useState('')

    useEffect(() => {
        SendContextChangeNotification(stringifiedContext)
    }, [stringifiedContext, SendContextChangeNotification])

    useEffect(() => {
        SetStringifiedContext(JSON.stringify(context))
    }, [context])

    return <></>
}
