import { useState, useEffect, useCallback } from 'react'
import { Container, Row, Col, ButtonGroup } from 'react-bootstrap'
import { useNavigate, useLocation } from 'react-router-dom'
import PropTypes from 'prop-types'
import { LOGIN_SIGNUP_PATH, START_PATH, SUBSCRIPTION_PATH } from '../App'
import { BACKEND_STATUS_SUCCESS } from '../project/BackendResources'
import { DeleteBackendList } from '../project/BackendListOperations'
import { Header } from '../components/Header'
import { Footer } from '../components/Footer'
import { ListOperationTabRowComponent, ListOperationTabComponent, ListOperationActionAreaComponent } from '../components/ListOperationComponents'
import { ActionButtonComponent, DangerButtonComponent } from '../components/ActionButtonComponents'
import { SuccessMessageComponent, ErrorMessageComponent } from '../components/MessageComponents'
import { UserAuthStateChangeListener, IsUserLoggedIn } from '../state/UserAuthState'
import { IsUserSubscribed } from '../state/SubscriptionState'
import { ClearSelectedListId } from '../state/ListsState'

export function ListDeletePage() {
    const { state } = useLocation()
    const [listId, SetListId] = useState('')
    const [listName, SetListName] = useState('unnamed list')

    const MESSAGE_TYPE_NONE = 'ListDeleteMessageType/None'
    const MESSAGE_TYPE_SUCCESS = 'ListDeleteMessageType/Success'
    const MESSAGE_TYPE_ERROR = 'ListDeleteMessageType/Error'

    const [messageType, SetMessageType] = useState(MESSAGE_TYPE_NONE)
    const [message, SetMessage] = useState('')

    useEffect(() => {
        if (state && state['listId']) {
            SetListId(state['listId'])

            if (state['listName']) {
                SetListName(state['listName'])
            }
        }
        else {
            SetMessageType(MESSAGE_TYPE_ERROR)
            SetMessage('The list information is not valid. None of your saved lists will be deleted.')
        }
    }, [state])

    const navigate = useNavigate()

    const [userStateChangeString, SetUserStateChangeString] = useState('')

    useEffect(() => {
        if (!IsUserLoggedIn()) {
            navigate(LOGIN_SIGNUP_PATH)
        }
        else if (!IsUserSubscribed()) {
            navigate(SUBSCRIPTION_PATH)
        }
    }, [userStateChangeString, navigate])

    const HandleUserStateChange = useCallback((listenerString) => {
        SetUserStateChangeString(listenerString)
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const HandleDeleteListResponse = useCallback(
        (status, _response) => {
            if (status === BACKEND_STATUS_SUCCESS) {
                ClearSelectedListId()
                navigate(START_PATH)
            }

            SetMessageType(MESSAGE_TYPE_ERROR)
            SetMessage('A failure occurred. The list was not deleted.')
        },
        [navigate]
    )

    const HandleDeleteListOperation = () => {
        DeleteBackendList(listId, HandleDeleteListResponse)
    }

    const HandleCancelOperation = () => {
        navigate(START_PATH)
    }

    let actionAreaContent = <></>

    if (messageType === MESSAGE_TYPE_ERROR) {
        actionAreaContent =
            <div className='mt-0 mb-3'>
                <ErrorMessageComponent
                    DismissAlert={() => {
                        navigate(START_PATH)
                    }}
                    messageWidth='90%'
                >
                    {message}
                </ErrorMessageComponent>
            </div>
    }
    else if (messageType === MESSAGE_TYPE_SUCCESS) {
        actionAreaContent =
            <div className='mt-0 mb-3'>
                <SuccessMessageComponent
                    DismissAlert={() => {
                        SetMessageType(MESSAGE_TYPE_NONE)
                        SetMessage('')
                    }}
                    messageWidth='90%'
                >
                    {message}
                </SuccessMessageComponent>
            </div>
    }
    else {
        actionAreaContent =
            <>
                <h3 className='mt-2 mb-0 ms-5' style={{ color: 'var(--dark-blue' }}>Are you sure you want to delete <strong>{listName}</strong>?</h3>
                <DeleteListActionComponent
                    DeleteList={() => { HandleDeleteListOperation() }}
                    CancelOperation={() => { HandleCancelOperation() }}
                />
            </>
    }

    return (
        <>
            <UserAuthStateChangeListener callback={HandleUserStateChange} />
            <Header />
            <Container className='section-container bg-light pt-4 pb-4'>
                <ListOperationTabRowComponent>
                    <ListOperationTabComponent
                        color='dark-red'
                        text='Delete List'
                        isActive={true}
                        ClickAction={() => { }}
                    />
                </ListOperationTabRowComponent>
                <ListOperationActionAreaComponent props={{ color: 'dark-red' }} >
                    {actionAreaContent}
                </ListOperationActionAreaComponent>
            </Container>
            <Footer />
        </>
    )
}

function DeleteListActionComponent({ DeleteList, CancelOperation }) {
    const buttonMargins = '0 0.5rem 1rem 0.5rem'

    return (
        <>
            <Row>
                <Col xs={12} sm={11} className='align-self-center px-4'>
                    <ButtonGroup className='flex-wrap ps-5 pt-4 pb-3'>
                        <DangerButtonComponent
                            text='Delete List'
                            margins={buttonMargins}
                            DoAction={() => { DeleteList() }}
                        />
                        <ActionButtonComponent
                            text='Cancel'
                            margins={buttonMargins}
                            DoAction={() => { CancelOperation() }}
                        />
                    </ButtonGroup>
                </Col>
            </Row>
        </>
    )
}

DeleteListActionComponent.propTypes = {
    DeleteList: PropTypes.func.isRequired,
    CancelOperation: PropTypes.func.isRequired,
}
