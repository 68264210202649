import { useState, useEffect } from 'react'
import { createReducer, createAction } from '@reduxjs/toolkit'
import PropTypes from 'prop-types'
import {
    DispatchAction,
    GetReducerState,
    USER_ACCOUNT_REDUCER,
    SubscribeActionListener,
    GetStringifiedState,
    DEFAULT_STRINGIFIED_STATE,
} from '../state/ReduxStore'
import {
    GetBackendUserAccount,
    GetBackendTokenizedUserAccount,
    CreateBackendUserAccount,
    UpdateBackendUserAccount,
    ResetBackendUserAccountPassword,
    SendBackendUserAccountEmail
} from '../project/BackendUserOperations'
import { BACKEND_STATUS_SUCCESS, BACKEND_STATUS_FAILURE } from '../project/BackendResources'

// -------------------------------------------------------------------------------------------------
//  Internal state management
// -------------------------------------------------------------------------------------------------

const ACCOUNT_STATUS = 'UserAccountState/AccountStatus'
const ACCOUNT_INFO = 'UserAccountState/AccountInfo'
const ACCOUNT_OPERATION = 'UserAccountState/Operation'
const ACCOUNT_EMAIL_STATUS = 'UserAccountState/EmailStatus'

const ACCOUNT_STATUS_NOT_LOADED = 'UserAccountState/AccountStatus/NotLoaded'
const ACCOUNT_STATUS_INACTIVE = 'UserAccountState/AccountStatus/Inactive'
const ACCOUNT_STATUS_ACTIVE = 'UserAccountState/AccountStatus/Active'
const ACCOUNT_STATUS_NOT_FOUND = 'UserAccountState/AccountStatus/NotFound'
const ACCOUNT_STATUS_NOT_CREATED = 'UserAccountState/AccountStatus/NotCreated'
const ACCOUNT_STATUS_NOT_UPDATED = 'UserAccountState/AccountStatus/NotUpdated'

const ACCOUNT_INFO_INVALID = ''

const ACCOUNT_EMAIL_STATUS_NONE = 'UserAccountState/EmailStatus/None'
const ACCOUNT_EMAIL_STATUS_SENDING = 'UserAccountState/EmailStatus/Sending'
const ACCOUNT_EMAIL_STATUS_SUCCESS = 'UserAccountState/EmailStatus/Success'
const ACCOUNT_EMAIL_STATUS_FAILURE = 'UserAccountState/EmailStatus/Failure'

const ACCOUNT_OPERATION_NONE = 'UserAccountState/Operation/None'
const ACCOUNT_OPERATION_LOADING = 'UserAccountState/Operation/Loading'
const ACCOUNT_OPERATION_CREATING = 'UserAccountState/Operation/Creating'
const ACCOUNT_OPERATION_UPDATING = 'UserAccountState/Operation/Updating'
const ACCOUNT_OPERATION_RESETTING_PASSWORD = 'UserAccountState/Operation/ResettingPassword'
const ACCOUNT_OPERATION_SENDING_EMAIL = 'UserAccountState/Operation/SendingEmail'

const defaultState = {
    [ACCOUNT_STATUS]: ACCOUNT_STATUS_NOT_LOADED,
    [ACCOUNT_INFO]: ACCOUNT_INFO_INVALID,
    [ACCOUNT_OPERATION]: ACCOUNT_OPERATION_NONE,
    [ACCOUNT_EMAIL_STATUS]: ACCOUNT_EMAIL_STATUS_NONE,
}

const GetAccountStatus = () => {
    return GetReducerState(USER_ACCOUNT_REDUCER)[ACCOUNT_STATUS]
}

const GetAccountInfo = () => {
    return GetReducerState(USER_ACCOUNT_REDUCER)[ACCOUNT_INFO]
}

const GetAccountOperation = () => {
    return GetReducerState(USER_ACCOUNT_REDUCER)[ACCOUNT_OPERATION]
}

const GetAccountEmailStatus = () => {
    return GetReducerState(USER_ACCOUNT_REDUCER)[ACCOUNT_EMAIL_STATUS]
}

// -------------------------------------------------------------------------------------------------
//  External state access
// -------------------------------------------------------------------------------------------------

export const USER_ACCOUNT_STATUS_NOT_LOADED = ACCOUNT_STATUS_NOT_LOADED
export const USER_ACCOUNT_STATUS_INACTIVE = ACCOUNT_STATUS_INACTIVE
export const USER_ACCOUNT_STATUS_ACTIVE = ACCOUNT_STATUS_ACTIVE
export const USER_ACCOUNT_STATUS_NOT_FOUND = ACCOUNT_STATUS_NOT_FOUND
export const USER_ACCOUNT_STATUS_NOT_CREATED = ACCOUNT_STATUS_NOT_CREATED
export const USER_ACCOUNT_STATUS_NOT_UPDATED = ACCOUNT_STATUS_NOT_UPDATED

export const USER_ACCOUNT_INFO_INVALID = ACCOUNT_INFO_INVALID

export const USER_ACCOUNT_OPERATION_NONE = ACCOUNT_OPERATION_NONE

export const USER_ACCOUNT_EMAIL_STATUS_SENDING = ACCOUNT_EMAIL_STATUS_SENDING
export const USER_ACCOUNT_EMAIL_STATUS_SUCCESS = ACCOUNT_EMAIL_STATUS_SUCCESS
export const USER_ACCOUNT_EMAIL_STATUS_FAILURE = ACCOUNT_EMAIL_STATUS_FAILURE

export const GetInitialUserAccountState = () => {
    return defaultState
}

export function UserAccountStateChangeListener({ callback }) {
    const [stringifiedState, SetStringifiedState] = useState(DEFAULT_STRINGIFIED_STATE)

    useEffect(() => {
        callback(stringifiedState)
    }, [stringifiedState, callback])

    const HandleActionNotification = () => {
        SetStringifiedState(GetStringifiedState(USER_ACCOUNT_REDUCER))
    }

    useEffect(() => {
        SubscribeActionListener(HandleActionNotification)
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    return <></>
}

UserAccountStateChangeListener.propTypes = {
    callback: PropTypes.func.isRequired,
}

export const GetUserAccountStatus = () => {
    return GetAccountStatus()
}

export const GetUserAccountInfo = () => {
    return GetAccountInfo()
}

export const GetUserAccountOperation = () => {
    return GetAccountOperation()
}

export const GetUserAccountEmailStatus = () => {
    return GetAccountEmailStatus()
}

export const IsUserAccountLoaded = () => {
    return (GetAccountStatus() !== ACCOUNT_STATUS_NOT_LOADED) && (GetAccountStatus() !== ACCOUNT_STATUS_NOT_FOUND)
}

export const IsUserAccountActive = () => {
    return GetAccountStatus() === ACCOUNT_STATUS_ACTIVE
}

// -------------------------------------------------------------------------------------------------
//  Internal action management
// -------------------------------------------------------------------------------------------------

const LOAD_ACCOUNT_REQUEST = 'UserAccountAction/LoadAccountRequest'
const LOAD_ACCOUNT_SUCCESS = 'UserAccountAction/LoadAccountSuccess'
const LOAD_ACCOUNT_FAILURE = 'UserAccountAction/LoadAccountFailure'
const LOAD_TOKENIZED_ACCOUNT_REQUEST = 'UserAccountAction/LoadTokenizedAccountRequest'
const CREATE_ACCOUNT_REQUEST = 'UserAccountAction/CreateAccountRequest'
const CREATE_ACCOUNT_SUCCESS = 'UserAccountAction/CreateAccountSuccess'
const CREATE_ACCOUNT_FAILURE = 'UserAccountAction/CreateAccountFailure'
const UPDATE_ACCOUNT_REQUEST = 'UserAccountAction/UpdateAccountRequest'
const UPDATE_ACCOUNT_SUCCESS = 'UserAccountAction/UpdateAccountSuccess'
const UPDATE_ACCOUNT_FAILURE = 'UserAccountAction/UpdateAccountFailure'
const RESET_ACCOUNT_PASSWORD_REQUEST = 'UserAccountAction/ResetAccountPasswordRequest'
const RESET_ACCOUNT_PASSWORD_SUCCESS = 'UserAccountAction/ResetAccountPasswordSuccess'
const RESET_ACCOUNT_PASSWORD_FAILURE = 'UserAccountAction/ResetAccountPasswordFailure'
const SEND_EMAIL_REQUEST = 'UserAccountAction/SendEmailRequest'
const SEND_EMAIL_SUCCESS = 'UserAccountAction/SendEmailSuccess'
const SEND_EMAIL_FAILURE = 'UserAccountAction/SendEmailFailure'

const loadAccountRequestAction = createAction(LOAD_ACCOUNT_REQUEST)
const loadAccountSuccessAction = createAction(LOAD_ACCOUNT_SUCCESS)
const loadAccountFailureAction = createAction(LOAD_ACCOUNT_FAILURE)
const loadTokenizedAccountRequestAction = createAction(LOAD_TOKENIZED_ACCOUNT_REQUEST)
const createAccountRequestAction = createAction(CREATE_ACCOUNT_REQUEST)
const createAccountSuccessAction = createAction(CREATE_ACCOUNT_SUCCESS)
const createAccountFailureAction = createAction(CREATE_ACCOUNT_FAILURE)
const updateAccountRequestAction = createAction(UPDATE_ACCOUNT_REQUEST)
const updateAccountSuccessAction = createAction(UPDATE_ACCOUNT_SUCCESS)
const updateAccountFailureAction = createAction(UPDATE_ACCOUNT_FAILURE)
const resetAccountPasswordRequestAction = createAction(RESET_ACCOUNT_PASSWORD_REQUEST)
const resetAccountPasswordSuccessAction = createAction(RESET_ACCOUNT_PASSWORD_SUCCESS)
const resetAccountPasswordFailureAction = createAction(RESET_ACCOUNT_PASSWORD_FAILURE)
const sendEmailRequestAction = createAction(SEND_EMAIL_REQUEST)
const sendEmailSuccessAction = createAction(SEND_EMAIL_SUCCESS)
const sendEmailFailureAction = createAction(SEND_EMAIL_FAILURE)

// -------------------------------------------------------------------------------------------------
//  External action access
// -------------------------------------------------------------------------------------------------

export const LoadUserAccount = (username) => {
    DispatchAction(loadAccountRequestAction, username)
}

export const LoadTokenizedUserAccount = (userKey, token) => {
    DispatchAction(loadTokenizedAccountRequestAction, { userKey: userKey, token: token })
}

export const CreateUserAccount = (name, email, password) => {
    const credentials = { name: name, username: email, email: email, password: password }
    DispatchAction(createAccountRequestAction, { credentials: credentials })
}

export const SendUserAccountEmail = (email, subject, template, name, subscriptionType) => {
    DispatchAction(sendEmailRequestAction, { username: email, subject: subject, template: template, name: name, subscriptionType: subscriptionType })
}

export const UpdateUserAccount = (email, fields) => {
    DispatchAction(updateAccountRequestAction, { username: email, fields: fields })
}

export const ResetUserAccountPassword = (email, password, token) => {
    DispatchAction(resetAccountPasswordRequestAction, { username: email, password: password, token: token })
}

// -------------------------------------------------------------------------------------------------
//  Internal reducer management
// -------------------------------------------------------------------------------------------------

const ResetAccountState = (state) => {
    state[ACCOUNT_STATUS] = ACCOUNT_STATUS_NOT_LOADED
    state[ACCOUNT_INFO] = ACCOUNT_INFO_INVALID
    state[ACCOUNT_OPERATION] = ACCOUNT_OPERATION_NONE
    state[ACCOUNT_EMAIL_STATUS] = ACCOUNT_EMAIL_STATUS_NONE
}

const UpdateAccountState = (state, status, info, operation) => {
    state[ACCOUNT_INFO] = info
    state[ACCOUNT_OPERATION] = operation
    state[ACCOUNT_STATUS] = status
}

const HandleLoadAccountRequest = (state, action) => {
    ResetAccountState(state)
    state[ACCOUNT_OPERATION] = ACCOUNT_OPERATION_LOADING
    GetBackendUserAccount(action.payload, HandleBackendLoadAccountResponse)
}

const HandleLoadTokenizedAccountRequest = (state, action) => {
    ResetAccountState(state)
    state[ACCOUNT_OPERATION] = ACCOUNT_OPERATION_LOADING
    GetBackendTokenizedUserAccount(action.payload, HandleBackendLoadAccountResponse)
}

const HandleBackendLoadAccountResponse = (backendStatus, httpCode, response) => {
    if (backendStatus === BACKEND_STATUS_SUCCESS) {
        DispatchAction(loadAccountSuccessAction, response)
    }
    else if (backendStatus === BACKEND_STATUS_FAILURE) {
        DispatchAction(loadAccountFailureAction, httpCode)
    }
}

const HandleLoadAccountSuccess = (state, action) => {
    const accountInfo = action.payload
    const accountStatus = accountInfo['isActive'] ? ACCOUNT_STATUS_ACTIVE : ACCOUNT_STATUS_INACTIVE
    UpdateAccountState(state, accountStatus, accountInfo, ACCOUNT_OPERATION_NONE)
}

const HandleLoadAccountFailure = (state) => {
    UpdateAccountState(state, ACCOUNT_STATUS_NOT_FOUND, ACCOUNT_INFO_INVALID, ACCOUNT_OPERATION_NONE)
}

const HandleCreateAccountRequest = (state, action) => {
    ResetAccountState(state)
    state[ACCOUNT_OPERATION] = ACCOUNT_OPERATION_CREATING
    CreateBackendUserAccount(action.payload, HandleBackendCreateAccountResponse)
}

const HandleBackendCreateAccountResponse = (backendStatus, httpCode, response) => {
    if (backendStatus === BACKEND_STATUS_SUCCESS) {
        DispatchAction(createAccountSuccessAction, response)
    }
    else if (backendStatus === BACKEND_STATUS_FAILURE) {
        DispatchAction(createAccountFailureAction, httpCode)
    }
}

const HandleCreateAccountSuccess = (state, action) => {
    const accountInfo = action.payload
    const accountStatus = accountInfo['isActive'] ? ACCOUNT_STATUS_ACTIVE : ACCOUNT_STATUS_INACTIVE
    UpdateAccountState(state, accountStatus, accountInfo, ACCOUNT_OPERATION_NONE)
}

const HandleCreateAccountFailure = (state) => {
    UpdateAccountState(state, ACCOUNT_STATUS_NOT_CREATED, ACCOUNT_INFO_INVALID, ACCOUNT_OPERATION_NONE)
}

const HandleUpdateAccountRequest = (state, action) => {
    ResetAccountState(state)
    state[ACCOUNT_OPERATION] = ACCOUNT_OPERATION_UPDATING
    UpdateBackendUserAccount(action.payload, HandleBackendUpdateAccountResponse)
}

const HandleBackendUpdateAccountResponse = (backendStatus, httpCode, response) => {
    if (backendStatus === BACKEND_STATUS_SUCCESS) {
        DispatchAction(updateAccountSuccessAction, response)
    }
    else if (backendStatus === BACKEND_STATUS_FAILURE) {
        DispatchAction(updateAccountFailureAction, httpCode)
    }
}

const HandleUpdateAccountSuccess = (state, action) => {
    const accountInfo = action.payload
    const accountStatus = accountInfo['isActive'] ? ACCOUNT_STATUS_ACTIVE : ACCOUNT_STATUS_INACTIVE
    UpdateAccountState(state, accountStatus, accountInfo, ACCOUNT_OPERATION_NONE)
}

const HandleUpdateAccountFailure = (state) => {
    UpdateAccountState(state, ACCOUNT_STATUS_NOT_UPDATED, ACCOUNT_INFO_INVALID, ACCOUNT_OPERATION_NONE)
}

const HandleResetAccountPasswordRequest = (state, action) => {
    ResetAccountState(state)
    state[ACCOUNT_OPERATION] = ACCOUNT_OPERATION_RESETTING_PASSWORD
    ResetBackendUserAccountPassword(action.payload, HandleBackendResetAccountPasswordResponse)
}

const HandleBackendResetAccountPasswordResponse = (backendStatus, httpCode, response) => {
    if (backendStatus === BACKEND_STATUS_SUCCESS) {
        DispatchAction(resetAccountPasswordSuccessAction, response)
    }
    else if (backendStatus === BACKEND_STATUS_FAILURE) {
        DispatchAction(resetAccountPasswordFailureAction, httpCode)
    }
}

const HandleResetAccountPasswordSuccess = (state, action) => {
    const accountInfo = action.payload
    const accountStatus = accountInfo['isActive'] ? ACCOUNT_STATUS_ACTIVE : ACCOUNT_STATUS_INACTIVE
    UpdateAccountState(state, accountStatus, accountInfo, ACCOUNT_OPERATION_NONE)
}

const HandleResetAccountPasswordFailure = (state) => {
    UpdateAccountState(state, ACCOUNT_STATUS_NOT_UPDATED, ACCOUNT_INFO_INVALID, ACCOUNT_OPERATION_NONE)
}

const HandleSendAccountEmailRequest = (state, action) => {
    SendBackendUserAccountEmail(action.payload, HandleBackendSendAccountEmailResponse)
    state[ACCOUNT_OPERATION] = ACCOUNT_OPERATION_SENDING_EMAIL
    state[ACCOUNT_EMAIL_STATUS] = ACCOUNT_EMAIL_STATUS_SENDING
}

const HandleBackendSendAccountEmailResponse = (backendStatus, httpCode, response) => {
    if (backendStatus === BACKEND_STATUS_SUCCESS) {
        DispatchAction(sendEmailSuccessAction, response)
    }
    else if (backendStatus === BACKEND_STATUS_FAILURE) {
        DispatchAction(sendEmailFailureAction, httpCode)
    }
}

const HandleSendAccountEmailSuccess = (state) => {
    state[ACCOUNT_OPERATION] = ACCOUNT_OPERATION_NONE
    state[ACCOUNT_EMAIL_STATUS] = ACCOUNT_EMAIL_STATUS_SUCCESS
}

const HandleSendAccountEmailFailure = (state) => {
    state[ACCOUNT_OPERATION] = ACCOUNT_OPERATION_NONE
    state[ACCOUNT_EMAIL_STATUS] = ACCOUNT_EMAIL_STATUS_FAILURE
}

const userAccountReducer = createReducer(defaultState, (builder) => {
    builder.addCase(loadAccountRequestAction, (state, action) => {
        HandleLoadAccountRequest(state, action)
    })

    builder.addCase(loadAccountSuccessAction, (state, action) => {
        HandleLoadAccountSuccess(state, action)
    })

    builder.addCase(loadAccountFailureAction, (state, action) => {
        HandleLoadAccountFailure(state, action)
    })

    builder.addCase(loadTokenizedAccountRequestAction, (state, action) => {
        HandleLoadTokenizedAccountRequest(state, action)
    })

    builder.addCase(createAccountRequestAction, (state, action) => {
        HandleCreateAccountRequest(state, action)
    })

    builder.addCase(createAccountSuccessAction, (state, action) => {
        HandleCreateAccountSuccess(state, action)
    })

    builder.addCase(createAccountFailureAction, (state, action) => {
        HandleCreateAccountFailure(state, action)
    })

    builder.addCase(updateAccountRequestAction, (state, action) => {
        HandleUpdateAccountRequest(state, action)
    })

    builder.addCase(updateAccountSuccessAction, (state, action) => {
        HandleUpdateAccountSuccess(state, action)
    })

    builder.addCase(updateAccountFailureAction, (state, action) => {
        HandleUpdateAccountFailure(state, action)
    })

    builder.addCase(resetAccountPasswordRequestAction, (state, action) => {
        HandleResetAccountPasswordRequest(state, action)
    })

    builder.addCase(resetAccountPasswordSuccessAction, (state, action) => {
        HandleResetAccountPasswordSuccess(state, action)
    })

    builder.addCase(resetAccountPasswordFailureAction, (state, action) => {
        HandleResetAccountPasswordFailure(state, action)
    })

    builder.addCase(sendEmailRequestAction, (state, action) => {
        HandleSendAccountEmailRequest(state, action)
    })

    builder.addCase(sendEmailSuccessAction, (state, action) => {
        HandleSendAccountEmailSuccess(state, action)
    })

    builder.addCase(sendEmailFailureAction, (state, action) => {
        HandleSendAccountEmailFailure(state, action)
    })

    builder.addDefaultCase(() => { })
})

// -------------------------------------------------------------------------------------------------
//  External reducer access
// -------------------------------------------------------------------------------------------------

export const GetUserAccountReducer = () => {
    return userAccountReducer
}
