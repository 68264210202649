import { InitWordCodings } from './WordCodings'

export function InitProjectResources() {
    InitWordCodings()
}

export function GetSiteImageUrl(fileName) {
    return '/static/site-images/' + fileName
}

export function GetWordImageUrl(wordKey) {
    return '/static/word-images/word-image-' + wordKey + '.svg'
}

export function GetWordPhrasesFileUrl(wordKey) {
    return '/static/word-phrases/word-phrases-' + wordKey + '.psv'
}

export function GetColorCode(color) {
    switch (color) {
        case 'light-aqua':
            return '#83ccc0'

        case 'dark-aqua':
            return '#16929e'

        case 'dark-orange':
            return '#f3783d'

        case 'light-green':
            return '#bbca76'

        case 'dark-blue':
        default:
            return '#2d7592'
    }
}
